// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './NonexistentInstance.module.scss';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { SUBDOMAIN_FOR_DEFAULT_COMPANY_ID } from 'utils/configuration';
import { getInstancePageUrl } from 'features/framework/utils/domain';

// STORE
import { useSelector } from 'react-redux';
import { selectError } from 'features/framework/storeNext/configurationSlice';

// COMPONENT: NonexistentInstance
const NonexistentInstance = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const error = useSelector(selectError);

  // Redirect to main page if company exists
  useEffect(() => {
    if (!error?.nonexistent) {
      history.push('/');
    }
  }, [ error, history ]);

  // RENDER: NonexistentInstance
  return (
    <div className={styles.nonexistentInstance}>
      <div className={styles.container}>
        <div className={styles.title}>
          { translate('nonexistent_instance_title') }
        </div>
        <div>
          { translate('nonexistent_instance_description_1') }
        </div>
        <div>
          { translate('nonexistent_instance_description_2') }
        </div>
        <div className={styles.button}>
          <Button
            onClick={() => {
              window.location.href = getInstancePageUrl(SUBDOMAIN_FOR_DEFAULT_COMPANY_ID);
            }}
          >
            { translate('nonexistent_instance_btn') }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NonexistentInstance;
