// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './ShareLink.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Link, Modal, Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: ShareLink
const ShareLink = ({ link }) => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ showModal, setShowModal ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ShareLink
  return (
    <div className={styles.shareLink}>
      <Link onClick={() => {
        if (navigator.share) {
          navigator.share({
            url: link,
            title: translate('share_results'),
          });
        } else {
          setShowModal(true);
        }
      }}
      >
        <IconsSvg.Share />
        { translate('share_results') }
      </Link>

      { /* FLOATERS */ }
      { showModal && (
        <Modal
          header={translate('share_results')}
          primaryButtonTitle={translate('close_lbl')}
          onConfirm={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
        >
          <span>
            { translate('share_results_modal_description') }
          </span>
          <div className={styles.linkContainter}>
            <Link
              to={link}
              openInNewTab
            >
              { link }
            </Link>
            <Button
              size='S'
              looks='secondary'
              leadingIcon={IconsSvg.Copy}
              onClick={() => navigator.clipboard?.writeText(link)}
            >
              { translate('copy_lbl') }
            </Button>
          </div>
        </Modal>
      ) }
    </div>
  );
};

export default ShareLink;
