/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  deleteCoachHubParams,
  getIsExternalSystemIntegrationSupported,
} from 'features/+coachHub/utils/localStorage';

// UTILS
import * as api from 'api';
import { apiNext } from 'apiNext';
import {
  COMPANY_ID,
  IS_ADMIN_FRONTEND,
  CONFIGURATION,
  CONFIGURATION_OPTIONS,
  SUBDOMAIN_FOR_DEFAULT_COMPANY_ID,
} from 'utils/configuration';
import { CAPABILITIES_SWITCHES } from 'utils/configuration/const/capabilities';
import { LANGUAGES } from 'utils/configuration/const/languages';
// eslint-disable-next-line import/no-cycle, import/no-named-as-default
import getAllConfiguredRoutes, {
  applyStandaloneRoutesFilter,
  applyModalRoutesFilter,
  applyMainNavRoutesFilter,
  applyCoachHubRoutesFilter,
  applyNestedRoutesFilter,
} from 'features/framework/utils/routeNext';
import * as localStorage from 'utils/localStorage';
import { fetchStaticValues } from './staticValuesSlice';


const initialState = {
  initialised: false,
  initialisedExtended: false,
  currentLanguage: null,
  isCurrentLanguageFetching: false,
  loading: false,
  loadingExtended: false,
  error: null,

  instance: {
    initialised: false,
    flavor: 'bluquist', // 'bluquist', 'adminFrontend', 'balancedYou', 'bluquistAndBalancedYou', 'coachHub'
  },
  company: {
    // tbd:
    companyId: '',
    customLogoUrl: '',
    hasExternalLegalAgreements: false,
    dashboards: [],
    dashboardsSupersetUrl: '',
    // api spec:
    // "invitationDomainLimit": "string",
    // "openRegistrationDomains": [
    // null
    // ],
    // "wellbeingMails": true,
    // "assessmentReminderMails": true,
    // "externalDataProcessingContext": true,
    // "custom": { }
  },
  plan: {
    id: '',
    type: '', // 'trial', 'active', 'restricted', ...
    trialExpiration: 0, // in ms, 0 for expired or paid plan
    required: false, // true: for stripe payment handling,
    subscriptionCanceled: false,
    subscriptionExpiration: 0, // timestamp value
    billingPeriod: '', // 'yearly', 'monthly'
    switchToPlanBillingPeriod: '', // 'yearly', 'monthly'
    paymentCard: {
      last4: '',
      expirationMonth: 0,
      expirationYear: 0,
      status: '', // 'active', 'expired'
    },
  },
  token: {
    id: '',
    expiration: '',
  },
  settings: {
    language: LANGUAGES.EN,
    languages: [],
    translations: {},
    translationsUpdateFlag: undefined,
  },
  user: {
    userId: '',
    userGroup: '', // jobShare, profileShare, candidate, employee, leader, assessmentManager, admin, bluquistAdmin
    profileCompleted: false,
    firstName: '',
    lastName: '',
    onboardingCompleted: false,
  },
  features: {
    employees: false,
    candidates: false,
    roles: false,
    teams: false,
    wellbeing: false,
    balancedYou: false,
  },
  assessments: {
    balancedYou: false,
    big5: false,
    keyComp: false,
    leadComp: false,
    potential: false,
    workPref: false,
    nineLevels: false,
    rmp: false,
  },
  capabilities: CAPABILITIES_SWITCHES.reduce((acc, el) => {
    acc[el] = false;
    return acc;
  }, {}),
  switches: [],
  stateExtras: {
    vacancyHasCandidates: false,
    candidateHasRoleFitPage: false,
  },
};

// selectors
export const selectConfiguration = (state) => state.configuration;
export const selectLoading = createSelector(
  selectConfiguration,
  (configuration) => configuration.loading,
);
export const selectInitialised = createSelector(
  selectConfiguration,
  (configuration) => configuration.initialised,
);
export const selectLoadingExtended = createSelector(
  selectConfiguration,
  (configuration) => configuration.loadingExtended,
);
// error
export const selectError = createSelector(
  selectConfiguration,
  (configuration) => configuration.error,
);
// instance selectors
export const selectInstance = createSelector(
  selectConfiguration,
  (configuration) => configuration.instance,
);
export const selectInstanceInitialised = createSelector(
  selectInstance,
  (instance) => instance.initialised,
);
export const selectInstanceFlavor = createSelector(
  selectInstance,
  (instance) => instance.flavor,
);
export const selectInstanceIsAdminFrontend = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'adminFrontend',
);
export const selectInstanceIsCoachHub = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'coachHub',
);
export const selectInstanceIsBluquist = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'bluquist',
);
export const selectInstanceIsBalancedYou = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'balancedYou',
);
export const selectInstanceContainsBluquistFeatures = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'bluquist' || flavor === 'bluquistAndBalancedYou',
);
export const selectInstanceContainsBalancedYouFeatures = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'balancedYou' || flavor === 'bluquistAndBalancedYou',
);
// company selectors
export const selectCompany = createSelector(
  selectConfiguration,
  (configuration) => configuration.company,
);
export const selectCompanyHasExternalLegalAgreements = createSelector(
  selectCompany,
  (company) => company.hasExternalLegalAgreements,
);
export const selectCompanyId = createSelector(
  selectCompany,
  (company) => company.companyId,
);
export const selectCompanyDashboards = createSelector(
  selectCompany,
  (company) => company.dashboards,
);
export const selectCompanyDashboardsSupersetUrl = createSelector(
  selectCompany,
  (company) => company.dashboardsSupersetUrl,
);
// plan selectors
export const selectPlan = createSelector(
  selectConfiguration,
  (configuration) => configuration.plan,
);
export const selectPlanId = createSelector(
  selectPlan,
  (plan) => plan.id,
);
export const selectPlanType = createSelector(
  selectPlan,
  (plan) => plan.type,
);
export const selectPlanIsTrial = createSelector(
  selectPlanType,
  (type) => type === 'trial',
);
export const selectPlanIsNonBilling = createSelector(
  selectPlanType,
  (type) => type === 'nonBilling',
);
export const selectPlanTrialExpiration = createSelector(
  selectPlan,
  (plan) => plan.trialExpiration,
);
export const selectPlanRestricted = createSelector(
  selectPlanType,
  (type) => type === 'restricted',
);
export const selectPlanSubscriptionCanceled = createSelector(
  selectPlan,
  (plan) => plan.subscriptionCanceled,
);
export const selectPlanBillingPeriod = createSelector(
  selectPlan,
  (plan) => plan.billingPeriod,
);
export const selectPlanSwitchToPlanBillingPeriod = createSelector(
  selectPlan,
  (plan) => plan.switchToPlanBillingPeriod,
);
// payment card selectors
export const selectPlanPaymentCard = createSelector(
  selectPlan,
  (plan) => plan.paymentCard,
);
// token selectors
const selectToken = createSelector(
  selectConfiguration,
  (configuration) => configuration.token,
);
export const selectIsLoggedIn = createSelector(
  selectToken,
  (token) => token.id && token.expiration,
);
export const selectInstanceIsNonexistent = createSelector(
  selectConfiguration,
  (configuration) => Boolean(configuration?.error?.nonexistent),
);
const selectSettings = createSelector(
  selectConfiguration,
  (configuration) => configuration.settings,
);
export const selectSettingsLanguage = createSelector(
  selectSettings,
  (settings) => settings.language,
);
export const selectSettingsLanguages = createSelector(
  selectSettings,
  (settings) => settings.languages,
);
export const selectSettingsTranslations = createSelector(
  selectSettings,
  (settings) => settings.translations,
);
export const selectSettingsTranslationsUpdateFlag = createSelector(
  selectSettings,
  (settings) => settings.translationsUpdateFlag,
);
export const selectActiveTranslations = createSelector(
  selectSettingsLanguage,
  selectSettingsTranslations,
  (language, translations) => translations[language],
);
// user selectors
export const selectUser = createSelector(
  selectConfiguration,
  (configuration) => configuration.user,
);
export const selectUserId = createSelector(
  selectUser,
  (user) => user.userId,
);
export const selectUserProfileCompleted = createSelector(
  selectUser,
  (user) => user.profileCompleted,
);
// user: userGroup
export const selectUserGroup = createSelector(
  selectUser,
  (user) => user.userGroup,
);
export const selectUserIsAdmin = createSelector(
  selectUser,
  (user) => user.userGroup === 'admin',
);
export const selectUserFirstName = createSelector(
  selectUser,
  (user) => user.firstName,
);
export const selectUserLastName = createSelector(
  selectUser,
  (user) => user.lastName,
);
export const selectUserOnboardingCompleted = createSelector(
  selectUser,
  (user) => user.onboardingCompleted,
);
export const selectUserSettings = createSelector(
  selectUser,
  (user) => user.settings,
);
// features selectors
export const selectFeatures = createSelector(
  selectConfiguration,
  (configuration) => configuration.features,
);
const isFeatureActive = (feature) => (!!feature);
export const selectFeature = createSelector(
  [
    selectFeatures,
    (state, featureId) => featureId,
  ],
  (features, featureId) => isFeatureActive(features[featureId]),
);
// assessments selectors
export const selectAssessments = createSelector(
  selectConfiguration,
  (configuration) => configuration.assessments,
);
export const selectAssessment = createSelector(
  [
    selectAssessments,
    (state, assessmentId) => assessmentId,
  ],
  (assessments, assessmentId) => isFeatureActive(assessments[assessmentId]),
);
// capabilities selectors
export const selectCapabilities = createSelector(
  selectConfiguration,
  (configuration) => configuration.capabilities,
);
export const selectConfigSwitches = createSelector(
  selectConfiguration,
  (configuration) => configuration.switches,
);
const isCapabilityValid = (capability) => (!!capability);
export const selectCapability = createSelector(
  [
    selectCapabilities,
    (state, capability) => capability,
  ],
  (capabilities, capability) => isCapabilityValid(capabilities[capability]),
);

// state extras
export const selectStateExtras = createSelector(
  selectConfiguration,
  (configuration) => configuration.stateExtras,
);
// routing selectors
export const selectRoutesFromConfiguration = (configuration) => {
  // routes should only be based on configuration
  // but we pass in a fake state object so we can use selectors
  const allRoutes = getAllConfiguredRoutes({ configuration });
  const standaloneRoutes = applyStandaloneRoutesFilter(allRoutes);
  const mainNavRoutes = applyMainNavRoutesFilter(allRoutes);
  const modalRoutes = applyModalRoutesFilter(allRoutes);
  const nestedRoutes = applyNestedRoutesFilter(allRoutes);

  return {
    allRoutes,
    standaloneRoutes,
    mainNavRoutes,
    modalRoutes,
    nestedRoutes,
  };
};
// todo: memoize this selector
export const selectRoutes = createSelector(
  selectConfiguration,
  selectRoutesFromConfiguration,
);

export const selectRoutesFromConfigurationAndStateExtras = (configuration, stateExtras) => {
  // routes should only be based on configuration
  // but we pass in a fake state object so we can use selectors
  const allRoutes = getAllConfiguredRoutes({ configuration }, stateExtras);
  const standaloneRoutes = applyStandaloneRoutesFilter(allRoutes);
  const mainNavRoutes = applyMainNavRoutesFilter(allRoutes);
  const modalRoutes = applyModalRoutesFilter(allRoutes);
  const coachHubRoutes = applyCoachHubRoutesFilter(allRoutes);
  const nestedRoutes = applyNestedRoutesFilter(allRoutes);

  return {
    allRoutes,
    standaloneRoutes,
    mainNavRoutes,
    modalRoutes,
    coachHubRoutes,
    nestedRoutes,
  };
};

export const selectRoutesWithStateExtras = createSelector(
  selectConfiguration,
  selectStateExtras,
  selectRoutesFromConfigurationAndStateExtras,
);

export const fetchCompanyDashboards = () => (dispatch) => {
  api.get('/core/company/dashboards').then(({ ok, data }) => {
    if (ok) {
      dispatch(setCompanyDashboards(data));
    }
  });
};

// basic thunk
export const fetchConfiguration = (planId = '', features = {}) => async (dispatch, getState) => {
  const loading = selectLoading(getState());

  // prevent repeated calls
  if (loading) return;

  // for admin front-end it is enough to have a flavor within 'instance'
  // no need to fetch the company settings and logo
  if (IS_ADMIN_FRONTEND) {
    return dispatch(configurationReceived({
      instance: {
        flavor: 'adminFrontend',
      },
    }));
  }

  // Nuke Coachhub params when heading towards /action-link
  if (getIsExternalSystemIntegrationSupported() && window.location.pathname === '/action-link') {
    deleteCoachHubParams();
  }

  const promises = [
    api.get(`core/company/settings?company=${COMPANY_ID}`),
    api.get(`core/company/${COMPANY_ID}/logo`),
  ];

  // If token is missing, also check whether company exists
  const isDefault = COMPANY_ID === SUBDOMAIN_FOR_DEFAULT_COMPANY_ID;
  if (!localStorage.getItem('token') && !isDefault) {
    promises.push(api.get(`core/company/exists/${COMPANY_ID}`));
  }

  dispatch(configurationLoading());
  Promise.all(promises)
  .then(([ companySettings, customLogo, companyExists ]) => {
    if (companyExists?.data?.exists === false) {
      return dispatch(configurationFailed({
        ok: false,
        nonexistent: true,
        problem: 'Company does not exist',
      }));
    }

    if (companySettings.ok) {
      const companyId = COMPANY_ID;
      const customLogoUrl = customLogo.ok && api.getCustomLogoUrl();
      const hasExternalLegalAgreements = companySettings.data.externalDataProcessingContext;
      let flavor = 'bluquist';

      if (CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB) flavor = 'coachHub';
      if (IS_ADMIN_FRONTEND) flavor = 'adminFrontend';
      if (planId?.startsWith('balanced_you')) flavor = 'balancedYou';
      if (!planId?.startsWith('balanced_you') && features.balancedYou) flavor = 'bluquistAndBalancedYou';

      const instance = {
        flavor,
      };

      const company = {
        ...companySettings.data,
        companyId,
        customLogoUrl,
        hasExternalLegalAgreements,
      };
      return dispatch(configurationReceived({
        instance,
        company,
      }));
    }

    const {
      ok,
      status,
      data,
      problem,
    } = companySettings;
    return dispatch(configurationFailed({
      ok,
      status,
      data,
      problem,
    }));
  });
};

export const setCompanyCustomProfileSettingsApi = (payload) => async (dispatch, getState) => {
  const state = getState();
  const company = selectCompany(state);

  const response = await api.patch('core/company/settings', {
    custom: {
      ...company.custom,
      profileSettings: {
        ...company.custom?.profileSettings,
        ...payload,
      },
    },
  });

  if (response.ok) {
    dispatch(setCompanyCustomProfileSettings(payload));
  }
};

export const setOnboardingCompletedApi = (payload) => async (dispatch, getState) => {
  api.patch('core/user/settings', {
    custom: {
      onboardingCompleted: true,
    },
  });
};

export const mapSubscriptionData = (subscription = {}, paymentData = {}) => {
  let planType = 'active';
  const { required } = subscription;
  let subscriptionCanceled = false;
  let subscriptionExpiration = 0;
  const { billingPeriod } = subscription;
  const switchToPlanBillingPeriod = subscription.switchToPlan?.billingPeriod?.cycle;

  if (subscription.trial) {
    planType = 'trial';
  }
  if (!subscription.required) {
    planType = 'nonBilling';
  }
  if (subscription.access === 'restricted') {
    planType = 'restricted';
  }
  // canceledTo - timestamp value when the subscription is planned to be canceled on Stripe
  if (subscription.canceledTo && subscription.active) {
    subscriptionCanceled = true;
    subscriptionExpiration = subscription.canceledTo;
  }
  if (subscription.status === 'canceled' && !subscription.active) {
    planType = 'restricted';
    subscriptionCanceled = true;
  }

  // PAYMENT CARD
  const paymentCard = {
    last4: paymentData.card?.last4,
    expirationMonth: paymentData.card?.expirationMonth,
    expirationYear: paymentData.card?.expirationYear,
  };

  let status;
  if (paymentData.status === 'active') {
    status = 'active';
  } else if (paymentData.status !== 'active' && paymentData.errorReason) {
    status = 'expired';
  }

  paymentCard.status = status;

  return {
    planType,
    required,
    subscriptionCanceled,
    subscriptionExpiration,
    billingPeriod,
    switchToPlanBillingPeriod,
    paymentCard,
  };
};

export const fetchConfigurationExtended = () => async (dispatch, getState) => {
  const state = getState();
  const loadingExtended = selectLoadingExtended(state);
  const userGroup = selectUserGroup(state);

  // prevent repeated calls
  if (loadingExtended) return;

  dispatch(configurationExtendedLoading());

  let paymentData = {};
  let planData = {};
  if (userGroup === 'admin') {
    // payment data can be unavailable if there is no linked payment card
    const { ok, status, data } = await api.get('core/subscription/payment');
    if (ok && status === 200) {
      paymentData = data;
    }

    // plan can be unavailable if trial is expired and there is no subscription yet
    const planResponse = await api.get('core/company/billing/plan');
    if (planResponse.ok) {
      planData = planResponse.data;
    }
  }

  const getCalls = () => {
    // No setup calls for global admin
    if (userGroup === 'bluquistAdmin') {
      return [];
    }

    const calls = [
      'core/subscription/trial',
      'core/company/settings',
    ];

    if ([ 'admin', 'leader', 'assessmentManager' ].includes(userGroup)) {
      calls.push('core/subscription?expand=plan');
    }

    return calls;
  };

  Promise.all(getCalls().map((url) => apiNext.get(url)))
  .then(([ trial = {}, companySettings = {}, subscription ]) => {
    const initialised = !!companySettings.custom?.instanceInitialised;
    const hasExternalLegalAgreements = companySettings.externalDataProcessingContext;
    const instance = {
      initialised,
    };
    const company = {
      ...companySettings,
      hasExternalLegalAgreements,
    };
    const trialExpiration = (trial.remainingTime || 0) * 1000;

    if (!subscription) { // for non-admin users
      return dispatch(configurationExtendedReceived({
        planType: trial.trial ? 'trial' : 'active',
        // non admins can't login for expired plan
        trialExpiration,
        required: true, // no impact for non-admin users
        subscriptionCanceled: false, // no impact
        subscriptionExpiration: 0, // no impact
        billingPeriod: '', // no impact
        instance, // no impact
        company,
      }));
    }

    const {
      planType, required,
      subscriptionCanceled, subscriptionExpiration,
      billingPeriod, switchToPlanBillingPeriod,
      paymentCard,
    } = mapSubscriptionData(subscription, paymentData);

    return dispatch(configurationExtendedReceived({
      planId: planData?.id,
      planType,
      trialExpiration,
      required,
      subscriptionCanceled,
      subscriptionExpiration,
      billingPeriod,
      switchToPlanBillingPeriod,
      instance,
      company,
      paymentCard,
    }));
  })
  .catch((error) => {
    if (!error?.response?.json) {
      dispatch(configurationFailed({
        message: 'unknown error',
        body: error,
      }));
      return;
    }
    error.response.json()
    .then((json) => {
      error.resolve();
      dispatch(configurationFailed({
        message: error.message,
        body: json,
      }));
    });
  });
};

export const setInstanceInitialisedApi = (payload) => async (dispatch, getState) => {
  api.patch('core/company/settings', {
    custom: { instanceInitialised: true },
  });
};

export const setSettingsLanguageApi = (payload) => async (dispatch, getState) => {
  const { language, reFetchStaticValues } = payload;
  localStorage.setItem('selectedLanguage', language);

  try {
    dispatch(setSettingsLanguage(language));

    if (reFetchStaticValues) {
      dispatch(fetchStaticValues(true));
    }

    const translations = await apiNext.get(`core/language/${language}`, { company: COMPANY_ID });
    dispatch(setSettingsTranslations({ translations, translationsUpdated: true }));
  } catch (error) {
    console.error(error.message);
  }
};

export const setUserSettingsApi = (payload = {}) => async (dispatch, getState) => {
  try {
    await apiNext.patch('core/user/settings', payload);
    dispatch(setUserSettings(payload));

    // if language has been changed ->
    // all language/translations related values should be re-fetched
    if (payload.language) {
      dispatch(setSettingsLanguageApi({
        language: payload.language,
        reFetchStaticValues: true,
      }));
    } else {
      localStorage.setItem('overrideLanguage', false);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    configurationLoading: (state, action) => {
      state.loading = true;
    },
    configurationReceived: (state, action) => {
      const { instance, company } = action.payload;

      state.loading = false;
      state.initialised = true;

      state.instance = {
        ...state.instance,
        ...instance,
      };
      state.company = {
        ...state.company,
        ...company,
      };
    },
    setInstanceInitialised: (state, action) => {
      state.instance.initialised = true;
    },
    setCompanyCustomProfileSettings: (state, action) => {
      state.company = {
        ...state.company,
        custom: {
          ...state.company.custom,
          profileSettings: {
            ...state.company.custom?.profileSettings,
            ...action.payload,
          },
        },
      };
    },
    configurationFailed: (state, action) => {
      const error = action.payload;

      state.loading = false;
      state.initialised = false;
      state.error = error;
    },
    login: (state, action) => {
      const {
        tokenId, tokenExpiration,
        userId, userGroup,
        profileCompleted,
        userFirstName, userLastName,
        onboardingCompleted,
      } = action.payload;
      state.token = {
        id: tokenId,
        expiration: tokenExpiration,
      };
      state.user.userId = userId;
      state.user.userGroup = userGroup;
      state.user.profileCompleted = profileCompleted;
      state.user.firstName = userFirstName;
      state.user.lastName = userLastName;
      state.user.onboardingCompleted = onboardingCompleted;
    },
    logout: (state) => initialState,
    reset: (state) => initialState,
    setInitialisedExtended: (state, action) => {
      state.initialisedExtended = action.payload;
    },
    configurationExtendedLoading: (state, action) => {
      state.loadingExtended = true;
    },
    configurationExtendedReceived: (state, action) => {
      const {
        planId,
        planType, trialExpiration,
        required,
        subscriptionCanceled, subscriptionExpiration,
        billingPeriod,
        switchToPlanBillingPeriod,
        company,
        instance,
        paymentCard,
      } = action.payload;

      state.loadingExtended = false;
      state.plan.id = planId;
      state.plan.type = planType;
      state.plan.trialExpiration = trialExpiration;
      state.plan.required = required;
      state.plan.subscriptionCanceled = subscriptionCanceled;
      state.plan.subscriptionExpiration = subscriptionExpiration;
      state.plan.billingPeriod = billingPeriod;
      state.plan.switchToPlanBillingPeriod = switchToPlanBillingPeriod;
      state.plan.paymentCard = { ...state.plan.paymentCard, ...paymentCard };
      state.company = { ...state.company, ...company };
      state.instance = { ...state.instance, ...instance };
    },
    updateSubscription: (state, action) => {
      const { subscriptionCanceled, subscriptionExpiration, billingPeriod } = action.payload;
      state.plan.subscriptionCanceled = subscriptionCanceled;

      if (subscriptionExpiration) {
        state.plan.subscriptionExpiration = subscriptionExpiration;
      }

      if (billingPeriod) {
        state.plan.billingPeriod = billingPeriod;
      }
    },
    updatePaymentCard: (state, action) => {
      state.plan.paymentCard = { ...action.payload };
    },
    setInstance: (state, action) => {
      state.instance = action.payload;
    },
    setProductFlavor: (state, action) => {
      const flavor = action.payload;
      state.instance.flavor = flavor;
    },
    setUserGroup: (state, action) => {
      const userGroup = action.payload;
      state.user.userGroup = userGroup;
    },
    setUserSettings: (state, action) => {
      const userSettings = action.payload;
      state.user.settings = {
        ...state.user.settings,
        ...userSettings,
      };
    },
    setCurrentUserData: (state, action) => {
      const currentUserData = action.payload;
      state.user = { ...state.user, ...currentUserData };
    },
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setIsCurrentLanguageFetching: (state, action) => {
      state.isCurrentLanguageFetching = action.payload;
    },
    setSettingsLanguage: (state, action) => {
      state.settings.language = action.payload;
    },
    setSettingsLanguages: (state, action) => {
      state.settings.languages = action.payload;
    },
    setSettingsTranslations: (state, action) => {
      const { translations, translationsUpdated } = action.payload;
      const { translationsUpdateFlag } = state.settings;

      state.settings.translations = translations;
      if (translationsUpdated) {
        state.settings.translationsUpdateFlag = translationsUpdateFlag === undefined ? true : !translationsUpdateFlag;
      }
    },
    setUserProfileCompleted: (state, action) => {
      state.user.profileCompleted = true;
    },
    setCapabilities: (state, action) => {
      const capabilities = action.payload;
      state.capabilities = capabilities;
    },
    setConfigSwitches: (state, action) => {
      const switches = action.payload;
      state.switches = switches;
    },
    setFeatures: (state, action) => {
      const features = action.payload;
      state.features = features;
    },
    setAssessments: (state, action) => {
      const assessments = action.payload;
      state.assessments = assessments;
    },
    setStateExtras: (state, action) => {
      const stateExtras = action.payload;
      state.stateExtras = stateExtras;
    },
    setCompanyDashboards: (state, action) => {
      const { dashboards, supersetUrl } = action.payload;
      state.company.dashboards = dashboards;
      state.company.dashboardsSupersetUrl = supersetUrl;
    },
  },
});

export default configurationSlice.reducer;


export const {
  reset,
  configurationLoading, configurationReceived, configurationFailed,
  setInstance,
  setInitialisedExtended,
  setCurrentLanguage,
  setIsCurrentLanguageFetching,
  setSettingsLanguage,
  setSettingsLanguages,
  setSettingsTranslations,
  login, logout,
  configurationExtendedLoading, configurationExtendedReceived,
  setProductFlavor, setFeatures, setAssessments,
  setUserGroup, setUserSettings,
  setUserProfileCompleted, setCapabilities, setConfigSwitches,
  setStateExtras,
  setCompanyCustomProfileSettings,
  updateSubscription,
  updatePaymentCard,
  setCurrentUserData,
  setCompanyDashboards,
} = configurationSlice.actions;
