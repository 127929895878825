import * as fromActionTypes from 'features/+admin/store/actionTypes/planOverview';

export const getPlans = () => ({
  type: fromActionTypes.GET_PLANS,
  payload: {},
});

export const getPlansFulfilled = ({ plans }) => ({
  type: fromActionTypes.GET_PLANS_FULFILLED,
  payload: { plans },
});

export const getPlansRejected = ({ error }) => ({
  type: fromActionTypes.GET_PLANS_REJECTED,
  payload: { error },
});
