import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* getAssessmentAnswers(id) {
  try {
    const { status, ok, data } = yield call(get, `/core/assessments/${id}/1/answers`);
    if (ok && status === 200) {
      yield put(action.getAssessmentAnswersFulfilled({ id, data }));
    } else {
      yield put(action.getAssessmentAnswersRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.getAssessmentAnswersRejected({ id, error }));
  }
}
export default function* watchGetAssessmentAnswersRequest() {
  while (true) {
    let id;
    try {
      const { payload } = yield take(action.GET_ASSESSMENT_ANSWERS);
      id = payload;
      yield call(getAssessmentAnswers, payload);
    } catch (error) {
      yield put(action.getAssessmentAnswersRejected({ id, error }));
    }
  }
}
