import React, { useMemo, useState } from 'react';
import styles from './WorkPreferencesResult.module.scss';

import WorkPreferencesResultItem from './WorkPreferencesResultItem';
import WorkPreferencesResultSkeleton from './WorkPreferencesResultSkeleton';
import { InfoTitle, Modal, ShowResultsButton } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { filterHiddenResults } from 'utils/assessment';

import { DownloadResultButton } from '../DownloadResultButton';


const WorkPreferencesResult = (props) => {
  const {
    results,
    noDataText,
    footerText,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  const translate = useTranslate();

  const resultsFiltered = useMemo(() => filterHiddenResults(results), [ results ]);

  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState();

  if (!resultsFiltered) {
    return (
      <div className={styles.workPreferencesResult}>
        <WorkPreferencesResultSkeleton />
      </div>
    );
  }

  if (resultsFiltered.length === 0) {
    return (
      <div className={styles.workPreferencesResult}>
        <div className={styles.container}>
          <div className={styles.mainHeader}>
            { (exportView || !showInfoTitle)
              ? (
                <span className='bluTypeLabelL'>
                  { translate('workpreferences_results_title') }
                </span>
              )
              : (
                <InfoTitle
                  title={translate('workpreferences_results_title')}
                  onClick={() => setInfoPageIsVisible(true)}
                />
              ) }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.workPreferencesResult}>
      <div className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle)
            ? (
              <span className='bluTypeLabelL'>
                { translate('workpreferences_results_title') }
              </span>
            )
            : (
              <InfoTitle
                title={translate('workpreferences_results_title')}
                onClick={() => setInfoPageIsVisible(true)}
              />
            ) }

          { (!exportView && showExportIcon) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { managerControlled && (
          <ShowResultsButton
            userId={userId}
            assessmentId={ASSESSMENT_TYPES.WORK_PREFERENCES}
            sharedWithUser={sharedWithUser}
          />
        ) }

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { !noDataText && (
          <>
            <div className={styles.rows}>
              { resultsFiltered.map((result) => (
                <WorkPreferencesResultItem
                  key={result.id}
                  id={result.id}
                  score={result.result}
                  range={result.range}
                  name={result.name}
                />
              )) }
            </div>

            { footerText && <div className={styles.footerText}>{ footerText }</div> }
          </>
        ) }
      </div>

      { infoPageIsVisible && (
        <Modal
          header={translate('workpreferences_results_info_title')}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onConfirm={() => setInfoPageIsVisible(false)}
          onClose={() => setInfoPageIsVisible(false)}
        >
          { translate('workpreferences_results_info_description') }
        </Modal>
      ) }
    </div>
  );
};

export default WorkPreferencesResult;
