// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef } from 'react';
import styles from './DownloadResultButton.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BluCSSTransition } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { handleRipple } from 'utils/ripple';


// COMPONENT: DownloadResultButton
const DownloadResultButton = (props) => {
  // PROPS
  const {
    onExport,
    downloadHandles,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const { isXs } = useBreakpoint();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ menuIsOpen, setMenuIsOpen ] = useState(false);
  const menuRef = useRef();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: DownloadResultButton
  if (isXs) {
    return null;
  }

  return (
    <div
      className={styles.downloadResultButton}
      tabIndex='0'
      role='button'
      onBlur={(event) => {
        if (event.relatedTarget !== menuRef.current) {
          setMenuIsOpen(false);
        }
      }}
    >
      <IconsSvg.DownloadSvg
        onClick={() => {
          if (downloadHandles) {
            setMenuIsOpen(true);
          } else {
            onExport();
          }
        }}
      />

      { /* FLYOUT */ }
      <BluCSSTransition
        nodeRef={menuRef}
        in={menuIsOpen}
        classNames={{ ...styles }}
      >
        <div
          ref={menuRef}
          className={styles.menu}
        >
          { [
            {
              key: 'export_pdf_report',
              fn: downloadHandles?.handleDownload,
              disabled: downloadHandles?.downloading,
            },
            {
              key: 'export_assessments_results',
              fn: onExport,
            },
          ].map(({ key, fn, disabled }) => (
            <div
              key={key}
              className={classNames(styles.menuItem, { [styles.disabled]: disabled })}
              role='presentation'
              onClick={(event) => {
                fn();
                handleRipple(event, styles.colorPrimary3);
                setMenuIsOpen(false);
              }}
            >
              <span>
                { translate(key) }
              </span>
            </div>
          )) }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default DownloadResultButton;
