// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './BookModal.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// OTHER COMPONENTS
import { Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// CONFIG & DATA


// COMPONENT: BookModal
const BookModal = (props) => {
  // PROPS
  const {
    type = 'coaching-booking', // coaching-booking, upselling
    link = '',
    showShortText = false,
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const email = localStorage.getItem('mail') || '';

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: BookModal
  return (
    <Modal
      header={translate(`ist_result_book_${type}_title`)}
      primaryButtonTitle={(
        <div className={styles.primaryButton}>
          <IconsSvg.ExternalLink />
          { translate('ist_result_book_coaching_btn') }
        </div>
      )}
      secondaryButtonTitle={translate('cancel_lbl')}
      onConfirm={() => window.open(link, '_blank')}
      onClose={onClose}
    >
      { showShortText
        ? translate('ist_result_book_coaching_not_free', [
          '{{email}}', email.replace('@', '&commat;'),
        ])
        : translate('ist_result_book_coaching_free', [
          '{{email}}', email.replace('@', '&commat;'),
        ]) }
    </Modal>
  );
};

export default BookModal;
