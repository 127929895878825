// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './CallHelp.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: CallHelp
const CallHelp = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: CallHelp
  return (
    <div className={styles.callHelp}>
      <div className={styles.content}>
        <IconsSvg.CallHelp />
        { translate('ist_result_call_help') }
      </div>
    </div>
  );
};

export default CallHelp;
