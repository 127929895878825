// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionLocation.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext, DropDownSearchable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import { selectCountries } from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
const preSelectedValue = 'de';

// COMPONENT: QuestionLocation
const QuestionLocation = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const checkAnswers = useCallback(({ country, city }) => {
    const isValid = !!country;
    const thisAnswer = { country, city, isValid };

    onAnswer(thisAnswer);
  }, [ onAnswer ]);

  // COMPONENT/UI STATE and REFS
  const countries = useSelector(selectCountries);

  const [ country, setCountry ] = useState(preSelectedValue);
  const [ city, setCity ] = useState('');
  useEffect(() => {
    if (!answer.country) {
      checkAnswers({ country: preSelectedValue });
    } else {
      setCountry(answer.country);
    }
  }, [ answer.country, checkAnswers ]);
  useEffect(() => {
    setCity(answer.city);
  }, [ answer.city ]);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleCountry = (countryParam) => {
    setCountry(countryParam);
    checkAnswers({ country: countryParam, city });
  };

  const handleCity = (cityParam) => {
    setCity(cityParam);
    checkAnswers({ country, city: cityParam });
  };

  // HELPERS

  // RENDERS

  // RENDER: QuestionLocation
  return (
    <div className={classNames(styles.questionLocation)}>

      { /* FORM */ }
      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('please_select_lbl') || 'Bitte wählen'}
          filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
          activeValue={answer.country || preSelectedValue}
          options={countries}
          onChange={(option) => {
            handleCountry(option?.value ?? '');
          }}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={answer.city}
          label={translate('cp_city_placeholder') || 'Stadt (optional)'}
          onChange={handleCity}
        />
      </div>

    </div>
  );
};

export default QuestionLocation;
