// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { Loading } from 'ui/basic';
import { Peer360Setup } from '../Peer360Setup';
import { Peer360Manage } from '../Peer360Manage';

import { apiNext } from 'apiNext';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { listAssessments } from 'store/actions';

// UTILS
import { getFullName } from 'utils/users';
import {
  getIsExternalSystemIntegrationSupported,
  replaceCoachHubTarget,
} from 'features/+coachHub/utils/localStorage';

// CONFIG & DATA

// COMPONENT: Peer360Wrapper
const Peer360Wrapper = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const employeeId = history.location.search.split('?user=')[1];
  const { customAssessmentId } = params;

  // SELECTORS
  const externalSystemSupported = getIsExternalSystemIntegrationSupported();

  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ employeeInfoLoading, setEmployeeInfoLoading ] = useState(false);
  const [ employeeInfo, setEmployeeInfo ] = useState();
  const [ assessment, setAssessment ] = useState();

  const fetchAssessment = useCallback(async () => {
    if (!customAssessmentId) {
      return;
    }

    try {
      const data = await apiNext.get(`/core/assessments/${customAssessmentId}`, { user: employeeId });
      if (externalSystemSupported && data.completed) {
        replaceCoachHubTarget('profile', '/my-profile');
        return;
      }

      setAssessment(data);
    } catch (e) {
      if (!externalSystemSupported) {
        history.push('/');
      }
    }
  }, [
    customAssessmentId,
    employeeId,
    externalSystemSupported,
    history,
  ]);

  const fetchEmployeeData = useCallback(async () => {
    if (!employeeId) {
      return;
    }
    setEmployeeInfoLoading(true);
    try {
      const info = await apiNext.get('/core/user/info', { id: employeeId });
      setEmployeeInfo({ ...info, name: getFullName(info) });
    } catch (error) {
      console.error(error.message);
    } finally {
      setEmployeeInfoLoading(false);
    }
  }, [ employeeId ]);

  const handleFetch = useCallback(() => {
    fetchAssessment();
    if (employeeId) {
      fetchEmployeeData();
    } else {
      dispatch(listAssessments());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    employeeId,
    fetchAssessment,
    fetchEmployeeData,
    currentLanguage, // fetch assessment again when language changes
  ]);

  // Initialization hook
  useEffect(handleFetch, [ handleFetch ]);

  // RENDER: Peer360Wrapper
  if (!assessment || employeeInfoLoading) {
    return <Loading visible />;
  }

  const props = {
    assessment,
    employee: employeeInfo,
    viewIsModal: !externalSystemSupported,
    allowInviteInternalUsers: !externalSystemSupported,
    onSetup: handleFetch,
  };
  return assessment?.peerProgress
    ? <Peer360Manage {...props} />
    : <Peer360Setup {...props} />;
};

export default Peer360Wrapper;
