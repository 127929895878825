// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionGender.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentRadioButtonGroup } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionGender
const QuestionGender = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const options = [
    {
      label: translate('cp_gender_male'),
      value: 'm',
    },
    {
      label: translate('cp_gender_female'),
      value: 'w',
    },
    {
      label: translate('cp_gender_diverse'),
      value: 'd',
    },
  ];

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // EVENT HANDLES
  const handleGender = (genderParam) => {
    onAnswer({
      value: genderParam,
      name: 'gender',
      isValid: !!genderParam,
    });
  };

  // RENDER: QuestionGender
  return (
    <div className={classNames(styles.questionGender)}>

      { /* FORM */ }
      <div className={classNames(styles.formRow, styles.radioButtonGroupContainer)}>
        <div className={styles.assessmentRadioButtonGroup}>
          <AssessmentRadioButtonGroup
            name='radio-button-group'
            selectedValue={answer.value}
            items={options}
            deselectable
            onChange={handleGender}
          />
        </div>
      </div>

    </div>
  );
};

export default QuestionGender;
