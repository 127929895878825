import * as fromActionTypes from 'store/actionTypes/profile';

export const initProfile = () => ({
  type: fromActionTypes.INIT_PROFILE,
  payload: {},
});

export const initProfileResults = () => ({
  type: fromActionTypes.INIT_PROFILE_RESULTS,
  payload: {},
});

export const getProfile = (userId, includeDeactivated) => ({
  type: fromActionTypes.GET_PROFILE,
  payload: { userId, includeDeactivated },
});

export const getProfileFulfilled = ({ profile }) => ({
  type: fromActionTypes.GET_PROFILE_FULFILLED,
  payload: { profile },
});

export const getProfileRejected = ({ error }) => ({
  type: fromActionTypes.GET_PROFILE_REJECTED,
  payload: { error },
});

export const getResultFromReportFulfilled = (payload) => ({
  type: fromActionTypes.GET_PROFILE_RESULT_FROM_REPORT_FULFILLED,
  payload,
});

export const getProfileReportNotesFulfilled = (payload) => ({
  type: fromActionTypes.GET_PROFILE_REPORT_NOTES_FULFILLED,
  payload,
});

export const getProfileHistoryFulfilled = (payload) => ({
  type: fromActionTypes.GET_PROFILE_HISTORY_FULFILLED,
  payload,
});
