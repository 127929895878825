import { translate } from './translator/translator';

export const DIMENSION_BLOCKS = [
  {
    label: '9levels_report__level__signature_values',
    altLabel: 'Kennzeichnende Werte',
    description: '9levels_report_*_signature_values',
    altDescription: 'Kennzeichnende Werte',
  },
  {
    label: '9levels_report__level__characteristics',
    altLabel: 'Charaktermerkmale',
    description: '9levels_report_*_characteristics',
    altDescription: 'Charaktermerkmale',
  },
  {
    label: '9levels_report__level__groups_strengths',
    altLabel: 'Stärken in Gruppen und Organisationen',
    description: '9levels_report_*_group_strengths',
    altDescription: 'Stärken in Gruppen und Organisationen',
  },
  {
    label: '9levels_report__level__ideal_work_env',
    altLabel: 'Ideales Arbeitsumfeld',
    description: '9levels_report_*_ideal_work_env',
    altDescription: 'Ideales Arbeitsumfeld',
  },
  {
    label: '9levels_report__level__leadership',
    altLabel: 'Führung und Machtdistanz',
    description: '9levels_report_*_leadership',
    altDescription: 'Führung und Machtdistanz',
  },
  {
    label: '9levels_report__level__org_forms',
    altLabel: 'Organisationsformen',
    description: '9levels_report_*_org_forms',
    altDescription: 'Organisationsformen',
  },
];


const LOW_LIMIT = 24;
const MODERATE_LIMIT = 49;
const HIGH_LIMIT = 74;
export const getSubHeaderLong = (dimension, userName) => {
  const { dimensionName, dimensionValue } = dimension;

  if (dimensionValue >= 0 && dimensionValue <= LOW_LIMIT) {
    return translate(
      'ninelevels_dimension_low',
      [ '{{dimension}}', dimensionName, '{{username}}', userName ],
    )
      || `Das Merkmal ${dimensionName} ist sehr gering ausgeprägt.`;
  }

  if (dimensionValue > LOW_LIMIT && dimensionValue <= MODERATE_LIMIT) {
    return translate(
      'ninelevels_dimension_moderate',
      [ '{{dimension}}', dimensionName, '{{username}}', userName ],
    )
      || `Das Merkmal ${dimensionName} ist gering ausgeprägt.`;
  }

  if (dimensionValue > MODERATE_LIMIT && dimensionValue <= HIGH_LIMIT) {
    return translate(
      'ninelevels_dimension_high',
      [ '{{dimension}}', dimensionName, '{{username}}', userName ],
    )
      || `Das Merkmal ${dimensionName} ist mittel ausgeprägt.`;
  }

  return translate(
    'ninelevels_dimension_very_high',
    [ '{{dimension}}', dimensionName, '{{username}}', userName ],
  )
    || `Das Merkmal ${dimensionName} ist hoch ausgeprägt.`;
};


export const getSubHeaderShort = (dimensionName, dimensionValue) => {
  if (dimensionValue >= 0 && dimensionValue <= LOW_LIMIT) {
    return translate(
      'ninelevels_dimension_low__short_descr',
      [ '{{dimension}}', dimensionName ],
    )
      || `Das Merkmal ${dimensionName} ist sehr gering ausgeprägt.`;
  }

  if (dimensionValue > LOW_LIMIT && dimensionValue <= MODERATE_LIMIT) {
    return translate(
      'ninelevels_dimension_moderate__short_descr',
      [ '{{dimension}}', dimensionName ],
    )
      || `Das Merkmal ${dimensionName} ist gering ausgeprägt.`;
  }

  if (dimensionValue > MODERATE_LIMIT && dimensionValue <= HIGH_LIMIT) {
    return translate(
      'ninelevels_dimension_high__short_descr',
      [ '{{dimension}}', dimensionName ],
    )
      || `Das Merkmal ${dimensionName} ist mittel ausgeprägt.`;
  }

  return translate(
    'ninelevels_dimension_very_high__short_descr',
    [ '{{dimension}}', dimensionName ],
  )
    || `Das Merkmal ${dimensionName} ist hoch ausgeprägt.`;
};

const RESISTANCE_HIGH_VALUE = 60;
const RESISTANCE_LOW_VALUE = 40;
export const getResistanceText = (value) => {
  const absValue = Math.abs(value);

  if (absValue > RESISTANCE_HIGH_VALUE) {
    return '9levels_report_level_resistance_high';
  } if (absValue <= RESISTANCE_HIGH_VALUE && absValue >= RESISTANCE_LOW_VALUE) {
    return '9levels_report_level_resistance_medium';
  }
  return '9levels_report_level_resistance_low';
};
