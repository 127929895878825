export const UPDATE_REGISTRATION_DOMAINS = 'UPDATE_REGISTRATION_DOMAINS';
export const UPDATE_REGISTRATION_DOMAINS_FULFILLED = 'UPDATE_REGISTRATION_DOMAINS_FULFILLED';
export const UPDATE_REGISTRATION_DOMAINS_REJECTED = 'UPDATE_REGISTRATION_DOMAINS_REJECTED';

export const GET_REGISTRATION_DOMAINS = 'GET_REGISTRATION_DOMAINS';
export const GET_REGISTRATION_DOMAINS_FULFILLED = 'GET_REGISTRATION_DOMAINS_FULFILLED';
export const GET_REGISTRATION_DOMAINS_REJECTED = 'GET_REGISTRATION_DOMAINS_REJECTED';

export const GET_ALL_FEATURES = 'GET_ALL_FEATURES';
export const GET_ALL_FEATURES_FULFILLED = 'GET_ALL_FEATURES_FULFILLED';
export const GET_ALL_FEATURES_REJECTED = 'GET_ALL_FEATURES_REJECTED';

export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_SETTINGS_FULFILLED = 'GET_USER_SETTINGS_FULFILLED';
export const GET_USER_SETTINGS_REJECTED = 'GET_USER_SETTINGS_REJECTED';

export const INIT_UPDATE_USER_SETTINGS = 'INIT_UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_FULFILLED = 'UPDATE_USER_SETTINGS_FULFILLED';
export const UPDATE_USER_SETTINGS_REJECTED = 'UPDATE_USER_SETTINGS_REJECTED';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_FULFILLED = 'GET_COMPANY_SETTINGS_FULFILLED';
export const GET_COMPANY_SETTINGS_REJECTED = 'GET_COMPANY_SETTINGS_REJECTED';

export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const UPDATE_COMPANY_SETTINGS_FULFILLED = 'UPDATE_COMPANY_SETTINGS_FULFILLED';
export const UPDATE_COMPANY_SETTINGS_REJECTED = 'UPDATE_COMPANY_SETTINGS_REJECTED';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_FULFILLED = 'UPDATE_SETTINGS_FULFILLED';
export const UPDATE_SETTINGS_REJECTED = 'UPDATE_SETTINGS_REJECTED';
