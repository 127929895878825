import * as fromActionTypes from 'store/actionTypes/employeeOverview';

export const initEmployee = () => ({
  type: fromActionTypes.INIT_EMPLOYEE,
  payload: {},
});

export const getEmployee = (employeeId) => ({
  type: fromActionTypes.GET_EMPLOYEE,
  payload: { employeeId },
});

export const getEmployeeFulfilled = ({ employee }) => ({
  type: fromActionTypes.GET_EMPLOYEE_FULFILLED,
  payload: { employee },
});

export const getEmployeeRejected = ({ error }) => ({
  type: fromActionTypes.GET_EMPLOYEE_REJECTED,
  payload: { error },
});

