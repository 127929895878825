// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './VacancyExternalSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { ProfileSummary } from 'features/+candidates/pages/VacancySummary/ProfileSummary';

import { useTranslate } from 'utils/translator';

// OTHER COMPONENTS
import { Button, CollapsibleNext, TextEditor } from 'ui/basic';
import { ListNext } from 'ui/basic/molecules/ListNext';
import { mapCandidatesData } from 'ui/basic/molecules/ListNext/ListNext.data';
import { CandidateQuickView } from 'features/+candidates/pages/VacancySummary/CandidateQuickView';

// UTILS
import { getMappedCandidate } from 'utils/users';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};

// COMPONENT: VacancyExternalSummary
const VacancyExternalSummary = (props) => {
  // PROPS
  const { vacancy, token, onMatchingClick } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // VACANCY CANDIDATES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidates, setCandidates ] = useState([]);
  const [ candidatesLoading, setCandidatesLoading ] = useState();
  useEffect(() => {
    setCandidatesLoading(true);
    api.get(
      `/recruiting/jobs/${vacancy.id}/candidates`,
      { expand: 'user' },
      { Authorization: `Bearer ${token}` },
    )
    .then(({ ok, status, data }) => {
      setCandidatesLoading(false);

      if (ok && status === 200) {
        setCandidates(data.users);
      }
    });
  }, [ token, vacancy ]);

  // CANDIDATE QUICK VIEW: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidateForQuickView, setCandidateForQuickView ] = useState();
  const [ candidateQuickViewPanelVisible, setCandidateQuickViewPanelVisible ] = useState();

  // RENDER ROLE LOADING SKELETON
  const renderCandidatesLoadingSkeleton = () => {
    const rows = [];

    for (let i = 0; i < 11; i += 1) {
      rows.push(
        <div key={i} className={styles.contentBlock}>
          <div className={styles.row} />
          <div className={styles.row} />
        </div>,
      );
    }

    return (
      <div className={styles.candidatesLoadingSkeleton}>
        <div className={styles.top} />
        { rows }
      </div>
    );
  };

  // RENDER: VacancyExternalSummary
  return (
    <div className={classNames(styles.vacancyExternalSummary)}>
      <div className={styles.gridContainer}>
        { /* LEFT CONTENT */ }
        <div className={styles.leftContent}>
          <ProfileSummary vacancyBaseInfo={vacancy} showControls={false} teamClickable={false} />
        </div>

        { /* RIGHT CONTENT */ }
        <div className={styles.rightContent}>
          <div className='bluTypeXxs'>{ translate('candidate_summary') }</div>

          { candidatesLoading && renderCandidatesLoadingSkeleton() }

          { !candidatesLoading && (
            <>
              { (candidates && candidates.length > 0) && (
                <div className={styles.candidatesList}>
                  <div className={styles.header}>
                    <div className='bluTypeLabel'>
                      { translate('candidates_for_vacancy') }
                    </div>
                    <Button
                      size='S'
                      onClick={onMatchingClick}
                    >
                      { translate('vacancy_subnav_matching') }
                    </Button>
                  </div>
                  <ListNext
                    listItems={mapCandidatesData(candidates)}
                    dataType='candidates'
                    showArrow={false}
                    options={[ { value: 'quickViewProfile', label: translate('candidate_profile_quick_view') } ]}
                    disableItem={() => true}
                    onOptionClick={(optionValue, itemId) => {
                      switch (optionValue) {
                        case 'quickViewProfile':
                          if (candidates) {
                            const thisCandidate = candidates
                            .find((candidate) => candidate.user.id === itemId);
                            setCandidateForQuickView(getMappedCandidate(thisCandidate.user));
                          }

                          setCandidateQuickViewPanelVisible(true);
                          break;
                        default:
                      }
                    }}
                  />
                </div>
              ) }

              { (!candidates || candidates.length === 0) && (
                <div className={styles.noCandidates}>
                  { translate('vacancy_no_candidates_copy') }
                </div>
              ) }
            </>
          ) }

          { vacancy.role && (
            <div className={styles.roleDescription}>
              <CollapsibleNext
                header={(
                  <span className='bluTypeLabel'>
                    { `${translate('vacancy_role_description')} - ${vacancy.role.name}` }
                  </span>
                )}
                size='L'
                withBorders
              >
                <>
                  { Boolean(vacancy.role?.description) && (
                    <div>
                      <TextEditor content={vacancy.role.description} disabled />
                    </div>
                  ) }

                  { Boolean(vacancy.role?.details) && (
                    <div>
                      <TextEditor content={vacancy.role.details} disabled />
                    </div>
                  ) }
                </>
              </CollapsibleNext>
            </div>
          ) }

          { vacancy.description && (
            <div className={styles.jobPosting}>
              <CollapsibleNext
                header={<span className='bluTypeLabel'>{ translate('vacancy_description') }</span>}
                size='L'
                withBorders
              >
                <TextEditor content={vacancy.description} disabled />
              </CollapsibleNext>
            </div>
          ) }

        </div>
      </div>

      { /* CANDIDATE QUICK VIEW PANEL VISIBLE */ }
      { candidateQuickViewPanelVisible && createPortal(
        <CandidateQuickView
          token={token}
          candidate={candidateForQuickView}
          vacancyId={vacancy.id}
          role={vacancy.role}
          showToggle={vacancy.role && candidateForQuickView.profileCreated}
          onClose={() => setCandidateQuickViewPanelVisible(false)}
        />,
        document.body,
      ) }
    </div>
  );
};

export default VacancyExternalSummary;
