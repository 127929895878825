import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* getAssessmentQuestions(payload) {
  const { id, stage = 1 } = payload;

  try {
    yield put(action.loading());
    const { status, ok, data } = yield call(
      get,
      `/core/assessments/${id}/${stage}/questions`,
    );
    if (ok && status === 200) {
      yield put(action.getAssessmentQuestionsFulfilled({ id, data }));
    } else {
      yield put(action.getAssessmentQuestionsRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.getAssessmentQuestionsRejected({ id, error }));
  } finally {
    yield put(action.loadingFulFilled());
  }
}
export default function* watchGetAssessmentQuestionsRequest() {
  while (true) {
    let id;
    try {
      const { payload } = yield take(action.GET_ASSESSMENT_QUESTIONS);
      id = payload;
      yield call(getAssessmentQuestions, payload);
    } catch (error) {
      yield put(action.getAssessmentQuestionsRejected({ id, error }));
    }
  }
}

