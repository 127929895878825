import React, { useCallback, useEffect, useState } from 'react';
import styles from './ExternalProfile.module.scss';

import * as api from 'api';

import { useHistory } from 'react-router';

import { useTranslate } from 'utils/translator';
import { COMPANY_ID } from 'utils/configuration';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { getFullName } from 'utils/users';

import UserAssessmentsResults from './UserAssessmentsResults/UserAssessmentsResults';
import { CandidateExternalProfile } from './CandidateExternalProfile';
import { Button, InputPassword } from 'ui/basic';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLanguage } from 'store/selectors/localisation';
import { getProfileFulfilled } from 'store/actions';
import * as fromProfileSelector from 'store/selectors/profile';


// COMPONENT: ExternalProfile
const ExternalProfile = () => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const [ token, setToken ] = useState();
  const [ linkExpired, setLinkExpired ] = useState(false);
  const [ tokenIsInvalid, setTokenIsInvalid ] = useState(false);

  const [ password, setPassword ] = useState();
  const [ passwordProtected, setPasswordProtected ] = useState();

  const [ user, setUser ] = useState();

  const [ loginProcessing, setLoginProcessing ] = useState(false);
  const [ loginError, setLoginError ] = useState();
  const [ reLogin, setReLogin ] = useState(false);

  const [ , shareToken ] = history.location.pathname.split('/share/');

  const currentLanguage = useSelector(selectCurrentLanguage);
  const storeProfile = useSelector(fromProfileSelector.getProfile);
  const results = storeProfile?.assessmentResults;

  const logout = useCallback((status, data) => {
    if (status === 404) {
      setLinkExpired(true);
    } else if (status === 403 && data.error?.errorCode === 1103) {
      setTokenIsInvalid(true);
      setReLogin(true);
    }

    setToken(null);
    localStorage.removeItem(`shareToken${shareToken}`);
  }, [ shareToken ]);

  const getUserInfo = useCallback(async () => {
    try {
      const { ok, status, data } = await api.get('/core/user/info');
      if (ok && status === 200) {
        setUser({
          ...data,
          name: getFullName(data),
        });
      } else {
        logout(status, data);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [ logout ]);

  const getUserProfile = useCallback(async () => {
    try {
      const { ok, status, data } = await api.get('/core/user/profile');
      if (ok && status === 200) {
        const { profile } = data;

        // Special case: IST needs an extra call
        // https://blueexcellence.atlassian.net/browse/BQDQ-1334?focusedCommentId=21709
        const ist = profile.find(({ assessment }) => assessment === ASSESSMENT_TYPES.IST);
        if (ist) {
          const { data: istData } = await api.get('/core/assessments/ist/result');
          ist.result = istData;
        }

        dispatch(getProfileFulfilled({ profile: { assessmentResults: profile } }));
      } else {
        logout(status, data);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [ dispatch, logout ]);

  // Fetch profile
  useEffect(() => {
    if (!token) {
      return;
    }
    getUserInfo();
    getUserProfile();
  }, [ token, currentLanguage, getUserInfo, getUserProfile ]);

  useEffect(() => {
    let externalProfileToken;
    if (shareToken) {
      externalProfileToken = localStorage.getItem(`shareToken${shareToken}`);
    }

    // if there is a token in local storage -> use this token for fetching the user info/profile
    if (externalProfileToken && !reLogin) {
      setToken(externalProfileToken);
      setPasswordProtected(false);
      return;
    }

    // some external links are not password protected.
    // for that case first try to login without a password.
    // If it succeeds fetch user info and user profile
    api.post('/core/user/login', {
      shareToken,
      company: COMPANY_ID,
    })
    .then(({ ok, status, data }) => {
      if (status === 403 && data.error && data.error.errorCode === 1209) {
        setPasswordProtected(false);
        setLinkExpired(true);
        return;
      }

      if (ok && status === 200) {
        setPasswordProtected(false);
        setToken(data.token);
        localStorage.setItem(`shareToken${shareToken}`, data.token);
      } else {
        setPasswordProtected(true);
      }
    })
    .catch((error) => {
      setLoginProcessing(false);
      console.error(error.message);
    });
  }, [ reLogin, shareToken, getUserInfo, getUserProfile ]);


  if (passwordProtected === undefined) {
    return null;
  }

  // RENDER: content
  const renderContent = () => {
    if (!token || !user) {
      return null;
    }

    // If user is a candidate
    if (user?.shareTokenType === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE) {
      return (
        <CandidateExternalProfile
          id={user.id}
          token={token}
          results={results?.filter((resultItem) => resultItem?.result)}
        />
      );
    }

    return (
      <UserAssessmentsResults
        user={user}
        results={results?.filter((resultItem) => resultItem?.result)}
      />
    );
  };

  // RENDER: ExternalProfile
  return (
    <>
      { !token && (
        <div className={styles.externalProfile}>
          <div className={styles.loginContainer}>
            { linkExpired && (
              <>
                <div className={styles.mTitle}>
                  { translate('share_profile_link_expired_title') }
                </div>
                <div className={styles.copy}>
                  { translate('share_profile_link_expired_copy') }
                </div>
                <Button size='L' onClick={() => history.push('/')}>
                  { translate('share_profile_link_expired_btn') }
                </Button>
              </>
            ) }

            { (!linkExpired && !tokenIsInvalid) && (
              <>
                <div className={styles.mTitle}>
                  { translate('') }
                </div>
                <div className={styles.copyStrong}>
                  { translate('share_profile_login_copy') }
                </div>

                <form onSubmit={(event) => {
                  event.preventDefault();

                  setLoginProcessing(true);
                  api.post('/core/user/login', {
                    shareToken,
                    company: COMPANY_ID,
                    password,
                  })
                  .then(({ ok, status, data }) => {
                    setLoginProcessing(false);

                    if (status === 403 && data.error && data.error.errorCode === 1209) {
                      setLinkExpired(true);
                      return;
                    }

                    if (ok && status === 200) {
                      setToken(data.token);
                      localStorage.setItem(`shareToken${shareToken}`, data.token);
                    } else {
                      setLoginError(data.error.errorMessage);
                    }
                  })
                  .catch((error) => {
                    setLoginProcessing(false);
                    console.error(error.message);
                  });
                }}
                >
                  <div className={styles.password}>
                    <InputPassword
                      placeHolder={translate('share_profile_login_password_placeholder')}
                      error={loginError}
                      onInputChanged={(value) => setPassword(value)}
                    />
                  </div>

                  <Button
                    size='L'
                    disabled={!password || loginProcessing}
                    type='submit'
                  >
                    { translate('continue_lbl') }
                  </Button>
                </form>
              </>
            ) }
          </div>
        </div>
      ) }
      { renderContent() }
    </>
  );
};

export default ExternalProfile;
