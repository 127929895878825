// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CollapsibleMenu.module.scss';

// ASSETS
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENT: CollapsibleMenu
const CollapsibleMenu = (props) => {
  // PROPS
  const {
    content = [],
    fullyExpanded = false,
    selectedNodeIndex = 0,
    selectedSection,
  } = props;

  // Add node-like properties to each listItem to ease state update
  const addTreeProperties = (nodeList, parent = null) => {
    nodeList.forEach((node, index) => {
      // eslint-disable-next-line no-param-reassign
      node.parent = parent;

      let { id } = node;
      if (!id) {
        const parentId = parent?.id ?? 'li';
        id = `${parentId}-${index}`;
        // eslint-disable-next-line no-param-reassign
        node.id = id;
      }

      if (node.list) {
        addTreeProperties(node.list, node);
      }
    });
  };
  addTreeProperties(content);

  // List of nested indices of open sections.
  // It stores the "coordinates" for the currently selected item, going top-bottom.
  const [ selectedNode, setSelectedNode ] = useState(content[selectedNodeIndex]);
  const [ expandedNodes, setExpandedNodes ] = useState([]);

  useEffect(() => {
    const listItem = content[selectedNodeIndex];
    setSelectedNode(listItem);
    setExpandedNodes((state) => [ ...state, listItem ]);
  }, [ selectedNodeIndex, content ]);

  // RENDER: renderList
  const renderList = (list = []) => {
    // HELPERS: renderList
    const hasNestedList = (item) => Boolean(item.list?.length);
    const isExpanded = (item = {}) => fullyExpanded || Boolean(expandedNodes.find((el) => el.id === item.id));
    const findAncestor = (item = {}) => (item.parent ? findAncestor(item.parent) : item);

    if (!list?.length) {
      return null;
    }

    const handleClick = (listItem, isArrowClick = false) => (event) => {
      // Prevent event from bubbling up
      event.stopPropagation();

      // Select item and trigger onClick
      if (!isArrowClick) {
        setSelectedNode(findAncestor(listItem));
        if (listItem.onClick) {
          listItem.onClick(event, findAncestor(listItem));
        }
      }

      // Update list of expanded nodes
      if (hasNestedList(listItem)) {
        if (!isExpanded(listItem)) {
          // Add
          setExpandedNodes((state) => [ ...state, listItem ]);
        } else if (isArrowClick) {
          // Remove
          setExpandedNodes((state) => [ ...state ].filter((el) => el.id !== listItem.id));
        }
      }
    };

    return (
      <ul>
        { list.map((listItem) => (
          <li key={listItem.id}>
            { /* Render element */ }
            <div
              role='presentation'
              className={classNames({
                [styles.listItem]: true,
                [styles.isSelected]: selectedNode?.id === listItem.id
                || (selectedSection === listItem.id && findAncestor(listItem)?.id === selectedNode?.id),
                [styles.isExpanded]: isExpanded(listItem),
              })}
              onClick={handleClick(listItem)}
            >
              { listItem.name }
              { !fullyExpanded && hasNestedList(listItem) && (
                <div
                  role='presentation'
                  className={styles.arrow}
                  onClick={handleClick(listItem, true)}
                >
                  <ArrowDown />
                </div>
              ) }
            </div>

            { /* Render nestedList if present */ }
            { isExpanded(listItem) && renderList(listItem.list) }
          </li>
        )) }
      </ul>
    );
  };

  // RENDER: CollapsibleMenu
  return (
    <div className={classNames(styles.collapsibleMenu)}>
      { renderList(content) }
    </div>
  );
};

export default CollapsibleMenu;
