import React, { memo } from 'react';
import { ReactComponent as InfoBlue } from 'assets/icons/icn_info_blue.svg';
import classNames from 'classnames';
import styles from './InfoTitle.module.scss';

const InfoTitle = memo((props) => {
  const {
    title = '',
    type = 'bold',
    size = 'S',
    fullWidth = false,
    className = '',
    onClick = () => {},
  } = props;

  return (
    <div
      data-test='InfoTitle'
      role='presentation'
      className={classNames(
        styles.infoTitle,
        styles[type],
        styles[`size${size}`],
        { [styles.fullWidth]: fullWidth },
        className,
      )}
      onClick={onClick}
    >
      <span>{ title }</span>
      <InfoBlue onClick={onClick} />
    </div>
  );
});

export default InfoTitle;
