// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from '../QuestionMultipleChoice.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { breakpoints, useBreakpoint } from 'utils/hooks';
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// COMPONENT: SingleChoiceOptions
const SingleChoiceBlock = (props) => {
  // PROPS
  const {
    question,
    selectedValue,
    hint = '',
    clickBlock,
    onHelp = () => {},
    onAnswer = () => {},
  } = props;

  const { hideLabelLight, answerOptions } = question;

  // SPECIAL HOOKS
  const bp = useBreakpoint();
  const translate = useTranslate();

  const [ answered, setAnswered ] = useState(false);
  const [ internalIndex, setInternalIndex ] = useState();

  useEffect(() => {
    const selectedOptionIndex = answerOptions.findIndex((option) => option.id === selectedValue?.[0]);
    if (isValid(selectedOptionIndex)) {
      setInternalIndex(selectedOptionIndex);
    }
  }, [ selectedValue, answerOptions ]);

  const handleAnswer = useCallback((index) => {
    if (clickBlock || answered) return;

    const selectedOption = answerOptions[index];
    const { id } = selectedOption;

    setInternalIndex(index);
    setAnswered(true);

    onAnswer([ id ]);
  }, [
    clickBlock,
    answered,
    answerOptions,
    onAnswer,
  ]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    const selectedOption = Number(event.key);
    if (selectedOption <= answerOptions.length) {
      event.preventDefault();

      // since the 10th option is linked to key 0 we need to set the index to 9 separately
      const indexInternal = selectedOption === 0 ? 9 : selectedOption - 1;
      handleAnswer(indexInternal);
    }
  }, [ handleAnswer, answerOptions ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  const options = [ ...answerOptions ];
  if (question.skipAnswer) {
    options.push({
      id: 'skipAnswerOption',
      label: translate('question_can_not_answer_label'),
      value: null,
    });
  }

  // RENDER: SingleChoiceOptions
  return (
    <div className={classNames(styles.singleChoiceBlock)}>
      <div className={styles.answerOptions}>
        <div>
          { options.map((answerOption, index) => (
            <div
              key={answerOption.id}
              className={classNames(styles.option, {
                [styles.skipAnswerOption]: answerOption.id === 'skipAnswerOption',
                [styles.selected]: index === internalIndex
                || (answerOption.id === 'skipAnswerOption' && selectedValue === null),
              })}
              onClick={() => {
                if (answerOption.id === 'skipAnswerOption') {
                  onAnswer(null);
                  setInternalIndex(answerOptions.length);
                } else {
                  handleAnswer(index);
                }
              }}
              role='presentation'
              data-test='Option'
            >

              { /* CHECKBOX */ }
              <div className={styles.checkbox} />

              { /* LABEL LIGHT */ }
              { answerOption.labelLight && !hideLabelLight && bp.bpWidth > breakpoints.S.bpWidth && (
                <div className={styles.labelLight}>
                  { answerOption.labelLight }
                </div>
              ) }

              { /* LABEL */ }
              <div className={styles.label}>
                { translate(answerOption.label || answerOption.translationKey || answerOption.translationFallback) }
              </div>
            </div>
          )) }
        </div>
      </div>

      { !question.hideHelp && (
        <div className={styles.helpButton}>
          <Button
            size='S'
            looks='tertiary'
            onClick={onHelp}
          >
            { translate('assessment_help_button') }
          </Button>
        </div>
      ) }

      { /* HINT */ }
      { bp.bpWidth > breakpoints.S.bpWidth && (
        <div className={styles.hint}>
          <div className={styles.line2}>
            { translate('assmnt_hint_use_numbers_on_keyboard') }
          </div>

          <div className={styles.line2}>{ hint }</div>
        </div>
      ) }
    </div>
  );
};

export default SingleChoiceBlock;
