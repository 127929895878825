import React from 'react';
import styles from './WellBeingMeasurement.module.scss';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { executeAction } from 'store/actions';

import { useTranslate } from 'utils/translator';

import { Icons } from 'assets/icons';

import { Button, Icon } from 'ui/basic';

import ErrorScreen from './ErrorScreen';

const WB_ALREADY_TRACKED_ERROR_CODE = 1481;

let actionExecuted = false;

const WbMeasurementParent = (props) => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <div className={styles.wellBeingMeasurementParent}>
      <div className={styles.header}>
        <div className={styles.title}>
          { translate('wellbeing_ind_result_title') || 'Well-being' }
        </div>

        <div className={styles.close} onClick={() => history.push('/my-profile')}>
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>
      { props.children }
    </div>
  );
};

const WellBeingMeasurement = () => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);
  const executedActionInfo = useSelector((state) => state.bqActions && state.bqActions.executedActionInfo);
  const actionError = useSelector((state) => state.bqActions && state.bqActions.error);

  let actionId;
  let vote;
  if (window.location.search.includes('actionId') && window.location.search.includes('vote')) {
    const splittedValues = window.location.search.split('&vote=');
    actionId = splittedValues[0].split('?actionId=')[1];
    vote = splittedValues[1];
  }

  if (!actionExecuted && !executedActionInfo && !actionError && actionId && vote) {
    actionExecuted = true;

    dispatch(executeAction({
      actionId,
      params: {
        vote,
      },
    }));
  }

  if (!executedActionInfo && !actionError) {
    return null;
  }

  // if an error occurs for NOT LOGGED-IN users
  // all errors except 'well-being already tracked' error
  if (actionError && actionError.errorCode !== WB_ALREADY_TRACKED_ERROR_CODE && currentUser && currentUser.error) {
    return (
      <div className={styles.wellbeingError}>
        <ErrorScreen error={actionError} />
      </div>
    );
  }

  // if an error occurs for LOGGED-IN users
  // all errors except 'well-being already tracked' error
  if (actionError && actionError.errorCode !== WB_ALREADY_TRACKED_ERROR_CODE && currentUser && !currentUser.error) {
    return (
      <WbMeasurementParent>
        <ErrorScreen error={actionError} />
      </WbMeasurementParent>
    );
  }

  // if the action was successfully executed for LOGGED-IN users or
  // if error occurred with error code 1481
  if ((executedActionInfo || (actionError && actionError.errorCode === WB_ALREADY_TRACKED_ERROR_CODE))
    && currentUser && !currentUser.error
  ) {
    return (
      <WbMeasurementParent>
        <div className={styles.wellBeingMeasurement}>
          <div className={styles.content}>
            <span role='img' aria-label='emoji'>🙏</span>
            <div className={styles.title}>
              { (executedActionInfo && executedActionInfo.success)
                ? (translate('wb_measure_success_title') || 'Deine Angaben wurden gespeichert.')
                : (translate('wb_measure_already_tracked_title') || 'Dein Wohlbefinden wurde heute bereits erfasst.') }
            </div>
            <Button size='L' onClick={() => history.push('/my-profile')}>
              Continue
            </Button>
          </div>
        </div>
      </WbMeasurementParent>
    );
  }

  return (
    <div className={styles.wellBeingMeasurement}>
      <div className={styles.content}>
        <span role='img' aria-label='emoji'>🙏</span>
        <div className={styles.title}>
          { (executedActionInfo && executedActionInfo.success)
            ? (translate('wb_measure_success_title') || 'Deine Angaben wurden gespeichert.')
            : (translate('wb_measure_already_tracked_title') || 'Dein Wohlbefinden wurde heute bereits erfasst.') }
        </div>
        <div className={styles.description}>
          { translate('wb_measure_description') || 'Melde dich an um deine Ergebnisse zu sehen.' }
        </div>
        <Button size='L' onClick={() => history.push('/')}>
          { translate('wb_measure_btn') || 'Anmelden' }
        </Button>
      </div>
    </div>
  );
};

export default WellBeingMeasurement;
