import { SET_MENU_ITEM, SET_MENU_ITEM_FULFILLED, SET_MENU_ITEM_REJECTED } from '../actions';

const initialState = {
  currentMenuItem: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_ITEM:
      return state;
    case SET_MENU_ITEM_REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case SET_MENU_ITEM_FULFILLED:
      return {
        ...state,
        currentMenuItem: action.payload,
      };
    default:
      return state;
  }
};
