// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './BalancedYou.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InfoCard } from 'ui/basic';
import { ExportAssessmentResult, MindfulnessJourneyResult } from 'ui/molecules';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import { RecurringAssessmentControl } from './components/RecurringAssessmentControl';

// UTILS
import { ASSESSMENT_TYPES, MIND_JOURNEY_ORDER } from 'utils/configuration/const/assessment-types';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentHistory } from 'store/actions';
import { getFirstName, getLastName } from 'store/selectors/currentUser';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';


// COMPONENT: BalancedYou
const BalancedYou = () => {
  const [ historyIndex, setHistoryIndex ] = useState(0);
  const [ showExportModal, setShowExportModal ] = useState(false);
  const [ diagramRenderProcessing, setDiagramRenderProcessing ] = useState(false);

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const assessment = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.BALANCED_YOU]);
  const recommendations = useSelector(selectRecommendationsUnarchived);

  // EXPORT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const results = assessment?.results;
  const mappedResults = Object.keys(MIND_JOURNEY_ORDER)
  .map((order) => {
    const value = results?.find(({ id }) => id === order) ?? {};
    return { id: order, label: value.name, ...value };
  })
  .filter(({ label }) => label);
  const lineValues = mappedResults.map(({ result }) => Number(result).toFixed(2));
  const score = results?.[results.length - 1]?.result;
  const prevResults = historyIndex ? assessment?.history?.results[historyIndex] : null;

  useEffect(() => {
    dispatch(getAssessmentHistory(ASSESSMENT_TYPES.BALANCED_YOU));
  }, [ dispatch ]);

  const openExportModal = () => {
    setShowExportModal(true);
    setDiagramRenderProcessing(true);
    setTimeout(() => {
      setDiagramRenderProcessing(false);
    }, 1000);
  };


  // RENDER: BalancedYou
  return (
    <div className={classNames(styles.balancedYou)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          <MindfulnessJourneyResult
            dimensions={mappedResults}
            lineValues={lineValues}
            secondaryLineValues={Boolean(prevResults) && {
              date: prevResults.date,
              results: prevResults.results.map((r) => r.result),
            }}
            showDimensionInfo
            showHistory
            score={score}
            historyIsVisible={Boolean(prevResults)}
            onExport={openExportModal}
          />
        </div>

        <div className={styles.right}>
          <RecurringAssessmentControl
            assessment={assessment}
            historyIndex={historyIndex}
            onHistoryChange={setHistoryIndex}
          />

          <InfoCard
            title={translate('by_info_title')}
            content={translate('mind_journey_ind_result_info_description')}
          />

          { recommendations
          .filter((el) => el.module === ASSESSMENT_TYPES.BALANCED_YOU)
          .map((el) => <RecommendationCard key={el.id} {...el} />) }
        </div>
      </div>

      { showExportModal && (
        <ExportAssessmentResult
          diagramType={ASSESSMENT_TYPES.BALANCED_YOU}
          user={{
            firstName,
            lastName,
          }}
          diagramRenderProcessing={diagramRenderProcessing}
          onClose={() => setShowExportModal(false)}
        >
          <div id={`export-${ASSESSMENT_TYPES.BALANCED_YOU}`}>
            <MindfulnessJourneyResult
              exportView
              dimensions={mappedResults}
              lineValues={lineValues}
              showDimensionInfo
              showHistory
              score={score}
              onExport={openExportModal}
            />
          </div>
        </ExportAssessmentResult>
      ) }
    </div>
  );
};

export default BalancedYou;
