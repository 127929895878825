// REACT, STYLE, STORIES & COMPONENT
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styles from './QuestionBubbles.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentRadioButtonGroup } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';
import {
  useBreakpoint,
  useDebounce,
  useWindowWidth,
} from 'utils/hooks';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  xsSmallGap: 64,
  xsLargeGap: 100,

  nonXsSmallGap: 40,
  nonXsMediumGap: 74,
  nonXsLargeGap: 182,
};

// COMPONENT: QuestionBubbles
const QuestionBubbles = (props) => {
  // PROPS
  const {
    question = {},
    range = [],
    value,
    error = '',
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();
  const ref = useRef();

  const { questionText, description } = question;

  let rangeInternal = useMemo(() => [], []);
  if (question.useAnswerOptions && question.answerOptions?.length) {
    rangeInternal = question.answerOptions.map((answerOption) => ({
      id: answerOption.id,
      value: answerOption.value,
      translation: translate(answerOption.label),
    }));
  } else {
    rangeInternal = range.map((r) => ({
      ...r,
      translation: translate(r.translationKey || r.translationFallback),
    }));
  }

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);
  const [ gap, setGap ] = useState(0);
  useEffect(() => {
    if (!rangeInternal) {
      return;
    }
    const clientWidth = ref.current?.clientWidth;
    let gapInternal = (clientWidth - (rangeInternal.length * 32)) / (rangeInternal.length - 1);

    if (bp.isXs) {
      if (rangeInternal.length >= 3) {
        gapInternal = gapInternal > Config.xsSmallGap ? Config.xsSmallGap : gapInternal;
      } else {
        gapInternal = gapInternal > Config.xsLargeGap ? Config.xsLargeGap : gapInternal;
      }

      setGap(gapInternal);
      return;
    }

    if (rangeInternal.length >= 4) {
      gapInternal = gapInternal > Config.nonXsSmallGap ? Config.nonXsSmallGap : gapInternal;
    } else if (rangeInternal.length === 3) {
      gapInternal = gapInternal > Config.nonXsMediumGap ? Config.nonXsMediumGap : gapInternal;
    } else {
      gapInternal = gapInternal > Config.nonXsLargeGap ? Config.nonXsLargeGap : gapInternal;
    }

    setGap(gapInternal);
  }, [ bp, debouncedWindowWidth, rangeInternal ]);

  const [ hoveredRadioButtonTitle, setHoveredRadioButtonTitle ] = useState('');

  // RENDER: QuestionBubbles
  return (
    <div className={classNames(styles.questionBubbles)} ref={ref}>
      <div className='bluTypeLabelL'>{ questionText }</div>

      { error && (
        <div className={classNames('error', 'marginTopXs')}>{ error }</div>
      ) }

      { /* RADIO BUTTON GROUP */ }
      <div className={styles.radioButtonGroup}>
        <div className={styles.hints}>
          <span>
            { rangeInternal[0]?.translation }
          </span>
          <span>
            { rangeInternal[rangeInternal.length - 1]?.translation }
          </span>
        </div>

        <AssessmentRadioButtonGroup
          items={rangeInternal}
          style={{ gap }}
          size='S'
          layout='horizontal'
          selectedValue={value}
          keyPressEnabled={false}
          onSingleButtonHover={(valueParam) => {
            const thisItem = rangeInternal.find((r) => r.value === valueParam);
            setHoveredRadioButtonTitle(thisItem?.translation || '');
          }}
          onChange={(valueParam) => {
            onAnswer({
              question: question.id,
              content: valueParam,
            });
          }}
        />

        <div className={classNames(styles.dynamicHint, {
          [styles.visible]: Boolean(isValid(value) || hoveredRadioButtonTitle),
        })}
        >
          { hoveredRadioButtonTitle
            || rangeInternal.find((r) => r.value === value)?.translation }
        </div>
      </div>

      { description && (
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate(description) }
        </div>
      ) }
    </div>
  );
};

export default QuestionBubbles;
