import React, { useEffect, useState } from 'react';
import styles from './AssessmentTeaserNextSkeleton.module.scss';

const AssessmentTeaserNextSkeleton = () => {
  const [ skeleton, setSkeleton ] = useState();

  const getSubItems = () => {
    const subItems = [];
    for (let i = 0; i < 3; i++) {
      subItems.push(
        <div key={`index-${i}`} className={styles.subItemsGroup}>
          <div className={styles.subItem1} />
          <div className={styles.subItem2} />
        </div>,
      );
    }
    return subItems;
  };

  const getSkeleton = () => (
    <div id='container-id' className={styles.skeletonContainer}>
      <div className={styles.skeletonBlock}>
        <div className={styles.left} />
        <div className={styles.right}>
          <div className={styles.header} />
          <div className={styles.subItems}>{ getSubItems() }</div>
        </div>
      </div>
    </div>
  );

  const handleResize = () => {
    setSkeleton(getSkeleton());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return skeleton || getSkeleton();
};

export default AssessmentTeaserNextSkeleton;
