export const disableScrollingOnBody = () => {
  const { scrollY } = window;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
};
export const enableScrollingOnBody = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo({
    left: 0,
    top: parseInt(scrollY || '0', 10) * -1,
    behavior: 'instant',
  });
};

export const scrollIntoView = (
  elementId,
  options = {}, // WARNING: options only officially supported for safari 16+
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
  // https://caniuse.com/?search=scrollintoview
  // but we do have the smoothscroll-polyfill
) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      ...options,
    });
  }
};
