export const INIT_STAFFING_EMPLOYEE = 'INIT_STAFFING_EMPLOYEE';
export const GET_STAFFING_EMPLOYEE = 'GET_STAFFING_EMPLOYEE';
export const GET_STAFFING_EMPLOYEE_FULFILLED = 'GET_STAFFING_EMPLOYEE_FULFILLED';
export const GET_STAFFING_EMPLOYEE_REJECTED = 'GET_STAFFING_EMPLOYEE_REJECTED';

export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_ROLE_FULFILLED = 'SET_USER_ROLE_FULFILLED';
export const SET_USER_ROLE_REJECTED = 'SET_USER_ROLE_REJECTED';

export const INIT_DELETE_USER_ROLE = 'INIT_DELETE_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const DELETE_USER_ROLE_FULFILLED = 'DELETE_USER_ROLE_FULFILLED';
export const DELETE_USER_ROLE_REJECTED = 'DELETE_USER_ROLE_REJECTED';
