import React, { useEffect, useRef, useState } from 'react';
import styles from './LoginForm.module.scss';

// 3RD PARTY
import { useHistory } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button, Callout, Checkbox, InputNext, InputPassword, Link, BluCSSTransition,
} from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { validateEmail } from 'utils/validator';
import { COMPANY_ID } from 'utils/configuration';

// import {CONFIGURATION, CONFIGURATION_OPTIONS} from 'utils/configuration';

const EMAIL_WRONG_FORMAT_ERROR = 'login_form_email_error_hint_format';

const LoginForm = (props) => {
  const {
    isBalancedYou = false,
    ssoProvider,
    error = '',
    onSubmit = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState('');
  const [ inputError, setInputError ] = useState();
  const [ extendedSession, setExtendedSession ] = useState(false);
  const transitionRef = useRef();

  useEffect(() => {
    let emailInternal = localStorage.getItem('mail');

    if (history.location.search) {
      [ , emailInternal ] = history.location.search.split('?email=');
    }

    setEmail(emailInternal || '');
  }, [ history.location.search ]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    if (!trimmedEmail || !trimmedPassword) {
      return;
    }

    if (!validateEmail(trimmedEmail)) {
      setInputError(translate(EMAIL_WRONG_FORMAT_ERROR));
    } else if (onSubmit) {
      onSubmit(trimmedEmail, trimmedPassword, extendedSession);
    }
  };

  const handleInputPassword = (passwordParam) => {
    setPassword(passwordParam);
  };

  const title = translate(`${isBalancedYou ? 'by_' : ''}login_title`);
  const subTitle = translate('login_copy');
  const emailLabel = translate('login_form_email_label');
  const passwordLabel = translate('login_form_password_label');
  const buttonCaption = translate('login_button_login');
  const linkResetPassword = translate('login_link_reset_password');
  const linkCreateAccount = translate('login_link_create_account');

  return (
    <div className={styles.loginForm}>
      <div className={styles.title} data-test='LoginTitle'>{ title }</div>
      <div className={styles.subTitle}>{ subTitle }</div>

      <form onSubmit={handleSubmit}>
        <div className={styles.inputs} data-test='LoginInputs'>
          <InputNext
            label={emailLabel}
            value={email}
            autofocus
            errorMessage={inputError || error}
            onChange={(value) => {
              setInputError(null);
              setEmail(value);
            }}
          />

          <InputPassword
            type='password'
            placeHolder={passwordLabel}
            className={styles.passwordInput}
            name='password'
            value={password}
            onInputChanged={(passwordParam) => handleInputPassword(passwordParam)}
          />

          <Checkbox
            name={translate('login_extended_session')}
            checked={extendedSession}
            onChange={setExtendedSession}
          />
          <BluCSSTransition
            nodeRef={transitionRef}
            in={extendedSession}
            classNames={{ ...styles }}
          >
            <Callout
              ref={transitionRef}
              triangleOnTop
              trianglePosition='left'
              shortMargin
            >
              <div className={styles.calloutContent}>
                { translate('login_extended_session_alert') }
              </div>
            </Callout>
          </BluCSSTransition>
        </div>

        <div className={styles.bottom}>
          <div className={styles.links}>
            <Link to='/recovery-password'>{ linkResetPassword }</Link>
            <Link to='/new-account'>{ linkCreateAccount }</Link>
          </div>

          <Button disabled={!email || !password} type='submit'>
            { buttonCaption }
          </Button>
        </div>
      </form>

      { ssoProvider && (
        <form
          method='get'
          action={`${api.getBaseUrl()}/core/user/sso/login`}
        >
          <div className={styles.sso}>
            <input type='hidden' name='company' value={COMPANY_ID} />

            <div className={styles.line} />
            <div className={classNames('bluTypeCopyStrong', 'marginTopS')}>
              { translate('sso_login_copy', [
                '{{provider}}', ssoProvider,
              ]) }
            </div>
            <Button
              size='S'
              looks='secondary'
              type='submit'
            >
              { translate('sso_login_btn', [
                '{{provider}}', ssoProvider,
              ]) }
            </Button>
          </div>
        </form>
      ) }
    </div>
  );
};

export default LoginForm;
