// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './IstRangeDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';

// CONFIG & DATA
const Config = {
  scoreBarWidth: 3,
};


// COMPONENT: IstRangeDiagram
const IstRangeDiagram = (props) => {
  // PROPS
  const {
    label = '',
    score,
    min = 0,
    max = 10,
    cutoff = 0,
    showDecimal = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // RENDER: IstRangeDiagram
  return (
    <div className={styles.istRangeDiagram}>
      <svg>
        <rect
          width='100%'
          className={styles.container}
        />
        { cutoff > min && (
          <rect
            width={`${((cutoff - min) / (max - min)) * 100}%`}
            className={styles.range}
          />
        ) }
        <rect
          width={Config.scoreBarWidth}
          x={`${((score - min) / (max - min)) * 100}%`}
          // Subtract bar width from x coord to prevent overflow when score is 100%
          transform={`translate(${score === max ? -Config.scoreBarWidth : 0}, 0)`}
          className={classNames(
            styles.score,
            { [styles.danger]: cutoff && score > cutoff },
          )}
        />
      </svg>

      { label && (
        <div className={styles.label}>
          { label }
          <div className={styles.score}>
            { translate(
              'ist_result_range_diagram_ratio',
              [
                '{{score}}', setDecimalSeparator(score, Number(showDecimal)),
                '{{total}}', max,
              ],
            ) }
          </div>
        </div>
      ) }
    </div>
  );
};

export default IstRangeDiagram;
