// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LegalText.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectPlan } from 'features/framework/storeNext/configurationSlice';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: LegalText
const LegalText = () => {
  // PROPS

  // SPECIAL HOOKS
  const translate = useTranslate();

  const plan = useSelector(selectPlan);
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LegalText
  return (
    <div className={classNames(styles.legalText)}>
      { plan.required && translate('invite_users_stripe_billing_legal_text') }

      { (!plan.required && plan.type !== 'trial') && translate('invite_users_external_billing_legal_text') }
    </div>
  );
};

export default LegalText;
