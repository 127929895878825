export const GET_COUNTRIES = '[STATIC] GET_COUNTRIES';
export const getCountries = () => ({
  type: GET_COUNTRIES,
  payload: {},
});

export const GET_COUNTRIES_SUCCESS = '[STATIC] GET_COUNTRIES_SUCCESS';
export const getCountriesSuccess = ({ data }) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: { data },
});

export const GET_COUNTRIES_ERROR = '[STATIC] GET_COUNTRIES_ERROR';
export const getCountriesError = ({ error }) => ({
  type: GET_COUNTRIES_ERROR,
  payload: { error },
});

// GET EDUCATION
export const GET_EDUCATION = '[STATIC] GET_EDUCATION';
export const getEducation = () => ({
  type: GET_EDUCATION,
  payload: {},
});

export const GET_EDUCATION_SUCCESS = '[STATIC] GET_EDUCATION_SUCCESS';
export const getEducationSuccess = ({ data }) => ({
  type: GET_EDUCATION_SUCCESS,
  payload: { data },
});

export const GET_EDUCATION_ERROR = '[STATIC] GET_EDUCATION_ERROR';
export const getEducationError = ({ error }) => ({
  type: GET_EDUCATION_ERROR,
  payload: { error },
});

// GET INDUSTRIES
export const GET_INDUSTRIES = '[STATIC] GET_INDUSTRIES';
export const getIndustries = () => ({
  type: GET_INDUSTRIES,
  payload: {},
});

export const GET_INDUSTRIES_SUCCESS = '[STATIC] GET_INDUSTRIES_SUCCESS';
export const getIndustriesSuccess = ({ data }) => ({
  type: GET_INDUSTRIES_SUCCESS,
  payload: { data },
});

export const GET_INDUSTRIES_ERROR = '[STATIC] GET_INDUSTRIES_ERROR';
export const getIndustriesError = ({ error }) => ({
  type: GET_INDUSTRIES_ERROR,
  payload: { error },
});

// GET CURRENCIES
export const GET_CURRENCIES = '[STATIC] GET_CURRENCIES';
export const getCurrencies = () => ({
  type: GET_CURRENCIES,
  payload: {},
});

export const GET_CURRENCIES_SUCCESS = '[STATIC] GET_CURRENCIES_SUCCESS';
export const getCurrenciesSuccess = ({ data }) => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: { data },
});

export const GET_CURRENCIES_ERROR = '[STATIC] GET_CURRENCIES_ERROR';
export const getCurrenciesError = ({ error }) => ({
  type: GET_CURRENCIES_ERROR,
  payload: { error },
});

// GET CAREER LEVELS
export const GET_CAREER_LEVELS = '[STATIC] GET_CAREER_LEVELS';
export const getCareerLevels = () => ({
  type: GET_CAREER_LEVELS,
  payload: {},
});

export const GET_CAREER_LEVELS_SUCCESS = '[STATIC] GET_CAREER_LEVELS_SUCCESS';
export const getCareerLevelsSuccess = ({ data }) => ({
  type: GET_CAREER_LEVELS_SUCCESS,
  payload: { data },
});

export const GET_CAREER_LEVELS_ERROR = '[STATIC] GET_CAREER_LEVELS_ERROR';
export const getCareerLevelsError = ({ error }) => ({
  type: GET_CAREER_LEVELS_ERROR,
  payload: { error },
});

// GET JOB FAMILIES
export const GET_JOB_FAMILIES = '[STATIC] GET_JOB_FAMILIES';
export const getJobFamilies = () => ({
  type: GET_JOB_FAMILIES,
  payload: {},
});

export const GET_JOB_FAMILIES_SUCCESS = '[STATIC] GET_JOB_FAMILIES_SUCCESS';
export const getJobFamiliesSuccess = ({ data }) => ({
  type: GET_JOB_FAMILIES_SUCCESS,
  payload: { data },
});

export const GET_JOB_FAMILIES_ERROR = '[STATIC] GET_JOB_FAMILIES_ERROR';
export const getJobFamiliesError = ({ error }) => ({
  type: GET_JOB_FAMILIES_ERROR,
  payload: { error },
});
