import React, {
  useEffect, useRef, useState,
} from 'react';
import styles from './ResizableTextArea.module.scss';
import classNames from 'classnames';

const Config = {
  minRows: 2,
  maxRows: 10,
};

const ResizableTextArea = (props) => {
  const {
    value,
    label,
    placeholder,
    hint,
    minRows = Config.minRows,
    maxRows = Config.maxRows,
    disabled,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    onConfirm = () => {},
    invertEnterControl = false,
  } = props;

  const textareaRef = useRef();

  const [ valueInternal, setValueInternal ] = useState('');
  const [ rows, setRows ] = useState(minRows);
  const [ smallLabel, setSmallLabel ] = useState(false);
  const [ hideLabel, setHideLabel ] = useState(false);

  // Calculate rows amount based on the applied CSS style
  const calcCurrentRows = (domElement) => {
    if (!domElement) {
      return undefined;
    }

    let { lineHeight, paddingTop, paddingBottom } = window.getComputedStyle(domElement);
    lineHeight = parseInt(lineHeight, 10);
    paddingTop = parseInt(paddingTop, 10);
    paddingBottom = parseInt(paddingBottom, 10);

    return Math.floor((domElement.scrollHeight - (paddingTop + paddingBottom)) / lineHeight);
  };

  // Update internal value, scroll position, and recalculate the number of rows
  const updateValue = (event) => {
    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = calcCurrentRows(event.target);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setValueInternal(event.target.value);
    setRows(currentRows < maxRows ? currentRows : maxRows);

    onChange(event.target.value);
  };

  // Reset if value is set to empty string
  useEffect(() => {
    if (value === '') {
      setValueInternal(value);
      setRows(minRows);
    }
  }, [ value, minRows ]);

  useEffect(() => {
    if (value && !valueInternal) {
      setValueInternal(value);
      setSmallLabel(true);

      // restoring the textarea height
      // can be the case for assessments by going forth and back between questions
      setTimeout(() => {
        const textareaElem = textareaRef?.current;
        if (textareaElem && textareaElem.scrollHeight > textareaElem.clientHeight) {
          const currentRows = calcCurrentRows(textareaElem);
          textareaElem.rows = Math.min(currentRows, maxRows);
        }
      });
    }
  }, [ value, valueInternal, maxRows ]);

  return (
    <div className={styles.resizableTextarea}>
      { (label && !placeholder && !hideLabel) && (
        <label
          htmlFor='textarea'
          className={
            classNames(
              styles.label,
              { [styles.small]: smallLabel },
              { [styles.disabled]: disabled },
            )
          }
        >
          { label }
        </label>
      ) }

      <textarea
        ref={textareaRef}
        placeholder={placeholder}
        disabled={disabled}
        value={valueInternal}
        rows={rows}
        onChange={(event) => {
          if (invertEnterControl && event.nativeEvent.inputType === 'insertLineBreak') {
            return;
          }
          updateValue(event);
        }}
        onFocus={() => {
          setSmallLabel(true);
          onFocus();
        }}
        onBlur={() => {
          setSmallLabel(!!valueInternal);
          onBlur();
        }}
        onScroll={(event) => {
          const { scrollTop } = event.target;
          setHideLabel(scrollTop > 0);
        }}
        onKeyDown={(event) => {
          if (event.key !== 'Enter') {
            return;
          }

          // By default, enter goes on a new line, and ctrl+enter triggers onConfirm.
          // 'invertEnterControl' flips this behavior.
          if (invertEnterControl) {
            if (event.ctrlKey) {
              // add newline where the caret or selection is, and update internal value
              const { selectionStart, selectionEnd } = event.target;
              const newValue = [ ...event.target.value ];
              newValue.splice(selectionStart, selectionEnd - selectionStart, '\n');
              event.target.value = newValue.join('');
              updateValue(event);
              setTimeout(() => textareaRef.current.setSelectionRange(selectionStart + 1, selectionStart + 1), 0);
            } else {
              // ignore newline insertion and confirm
              event.target.value = valueInternal;
              onConfirm();
            }
          } else if (event.ctrlKey) {
            onConfirm();
          }
        }}
      />

      { hint && (
        <div className={classNames('bluTypeHint', 'marginTopXxs')}>{ hint }</div>
      ) }

    </div>
  );
};

export default ResizableTextArea;
