import { createSelector } from '@reduxjs/toolkit';

export const selectVacancy = (state) => state.vacancy;

export const selectVacancyBaseInfo = createSelector(
  selectVacancy,
  (vacancy) => vacancy.baseInfo,
);

export const selectVacancyCandidates = createSelector(
  selectVacancyBaseInfo,
  (vacancyBaseInfo) => vacancyBaseInfo.candidates || [],
);

export const selectVacancyHasCandidates = createSelector(
  selectVacancyCandidates,
  (vacancyCandidates) => Boolean(vacancyCandidates.length),
);

export const selectVacancyHasRole = createSelector(
  selectVacancyBaseInfo,
  (vacancyBaseInfo) => Boolean(vacancyBaseInfo?.role),
);

export const selectVacancyMatches = (state) => state.vacancy.matching;

export const selectVacancyProfile = (state) => state.vacancy.profile;
