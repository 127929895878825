// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useRef } from 'react';
import styles from './SliderSingle.module.scss';

// ASSETS
import { ReactComponent as ArrowLeft } from 'assets/icons/icn_arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/icn_arrow_right.svg';


// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useAutoReset } from 'utils/hooks';


// COMPONENT: SliderSingle
const SliderSingle = (props) => {
  // PROPS
  const {
    value = null,
    from = 0,
    to = 5,
    step = 1,
    onChange = () => {},
  } = props;

  const [ knobValue, setKnobValue ] = useState(value);
  const [ transitioning, setTransitioning ] = useAutoReset(false, Number(styles.animationDurationMs));
  const node = useRef();

  // Propagate new value after rounding it to nearest step
  useEffect(() => {
    let roundedValue = null;
    if (Number.isFinite(knobValue)) {
      roundedValue = Math.round(Math.abs(knobValue) / step) * step;
      if (roundedValue && knobValue < 0) {
        roundedValue *= -1;
      }
    }
    onChange(roundedValue);
  }, [ onChange, step, knobValue ]);

  // EVENT HANDLES
  const handleMove = (event) => {
    const { x: sliderX, width: sliderWidth } = node.current.getBoundingClientRect();
    const eventX = event.pageX ?? event.touches[0].pageX;
    const newKnobX = Math.min(Math.max(eventX - sliderX, 0), sliderWidth);
    let newKnobValue = ((newKnobX / sliderWidth) * (to - from)) + from;
    newKnobValue = Math.round(newKnobValue * 100) / 100;
    setKnobValue(newKnobValue);
  };

  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('touchmove', handleMove);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchend', handleDragEnd);
  };

  const handleDragStart = () => {
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleClick = (event) => {
    setTransitioning(true);
    handleMove(event);
  };

  const handleDoubleClick = () => {
    setTransitioning(true);
    setKnobValue(null);
  };

  const handleDoubleClickStop = (event) => {
    event.stopPropagation();
  };


  // RENDER: SliderSingle
  return (
    <div
      role='presentation'
      ref={(ref) => { node.current = ref; }}
      className={classNames(styles.sliderSingle, { [styles.transitioning]: transitioning })}
      onClick={handleClick}
    >
      { /* LINE */ }
      <div className={styles.lineLayer}>
        <div className={styles.line} />
      </div>

      { /* KNOB */ }
      <div
        className={classNames(styles.knobLayer, { [styles.touched]: Number.isFinite(knobValue) })}
        style={{ left: `${(knobValue / to) * 100}%` }}
      >
        <div
          role='presentation'
          className={styles.knob}
          onClick={handleDoubleClickStop}
          onDoubleClick={handleDoubleClick}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        >
          <div className={styles.knobBackground}>
            <div className={styles.knobArrows}>
              <ArrowLeft />
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderSingle;
