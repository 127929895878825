import { PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { isValid } from './numbers';
import { ASSESSMENT_TITLES, ASSESSMENT_TYPES } from './configuration/const/assessment-types';
import { setDecimalSeparator } from './userpreference';

const ALLOWED_PLATFORM_ROLES = [
  PLATFORM_ROLES.COMPANY_USER,
  PLATFORM_ROLES.CANDIDATE,
  PLATFORM_ROLES.COMPANY_LEADER_CUSTOM,
  PLATFORM_ROLES.ASSESSMENT_MANAGER,
  PLATFORM_ROLES.COMPANY_ADMIN,
];

const BALANCED_YOU_ALLOWED_PLATFORM_ROLES = [
  PLATFORM_ROLES.COMPANY_USER,
  PLATFORM_ROLES.COMPANY_LEADER_CUSTOM,
  PLATFORM_ROLES.COMPANY_ADMIN,
];

export const getAssessmentRange = (assessmentId, minValue, maxValue) => {
  const ASSESSMENT_MIN_VALUES = {
    [ASSESSMENT_TYPES.BIG5]: 1,
    [ASSESSMENT_TYPES.POTENTIAL]: 1,
    [ASSESSMENT_TYPES.WORK_PREFERENCES]: -5,
    [ASSESSMENT_TYPES.RMP]: -2,
    [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 1,
    [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 1,
  };

  const ASSESSMENT_MAX_VALUES = {
    [ASSESSMENT_TYPES.BIG5]: 1,
    [ASSESSMENT_TYPES.POTENTIAL]: 10,
    [ASSESSMENT_TYPES.WORK_PREFERENCES]: 5,
    [ASSESSMENT_TYPES.RMP]: 2,
    [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 5,
    [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 5,
  };

  let minValueInternal;
  let maxValueInternal;

  if (isValid(minValue) && isValid(maxValue)) {
    minValueInternal = minValue;
    maxValueInternal = maxValue;
  }

  if (!isValid(minValue) && isValid(maxValue)) {
    minValueInternal = ASSESSMENT_MIN_VALUES[assessmentId];
    maxValueInternal = maxValue;
  }

  if (isValid(minValue) && !isValid(maxValue)) {
    minValueInternal = minValue;
    maxValueInternal = ASSESSMENT_MAX_VALUES[assessmentId];
  }

  // if min value is equal to max value -> subtract 0.1 so that the range is shown on diagram
  if (minValueInternal === maxValueInternal) {
    minValueInternal -= 0.1;
  }

  return [ minValueInternal, maxValueInternal ];
};

export const getFilteredRoles = (allRoles, bqFeaturesIncluded) => {
  const allMappedRoles = allRoles.map((r) => r.role);
  const allowedRoles = bqFeaturesIncluded
    ? ALLOWED_PLATFORM_ROLES
    : BALANCED_YOU_ALLOWED_PLATFORM_ROLES;

  if (allRoles.length === 0) {
    return allRoles;
  }

  return allowedRoles.map((role) => {
    const basicRole = role.split('_CUSTOM')[0];

    if (allMappedRoles.includes(role)) {
      return role;
    }
    // if no custom role found, basic role will be added to array
    return basicRole;
  });
};

export const getRoleMatchSummaryLinks = (translate, assessmentsFromStore, matchingDetails) => {
  if (!matchingDetails || !assessmentsFromStore) {
    return undefined;
  }

  return assessmentsFromStore
  .map(({ id, title }) => {
    const { score } = matchingDetails.assessments.find((a) => a.assessmentId === id) ?? {};
    const clickable = Boolean(score);
    let label = translate(ASSESSMENT_TITLES[id]) || title;
    if (clickable) {
      label += ` - ${setDecimalSeparator(score)} %`;
    }
    return { id, label, clickable };
  })
  .sort((a, b) => (a.clickable <= b.clickable ? 1 : -1));
};
