// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionSlider.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { SliderSingle, Button } from 'ui/basic';


// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
  defaultAnswer: 0,
  defaultRange: {
    answerFrom: 0,
    answerStep: 1,
    answerTo: 5,
  },
};

// COMPONENT: QuestionSlider
const QuestionSlider = (props) => {
  // PROPS
  const {
    question,
    range = CONFIG.defaultRange,
    selectedValue,
    clickBlock,
    localBlock,
    setLocalBlock = () => {},
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {},
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [ value, setValue ] = useState(selectedValue);
  const [ answered, setAnswered ] = useState(false);
  const [ answer, setAnswer ] = useState(CONFIG.defaultAnswer);
  useEffect(() => {
    if (!answered && !Number.isNaN(selectedValue)) { // don't update the value anymore when answered
      setValue(selectedValue);
      setAnswer(selectedValue);
    }
  }, [ selectedValue, answered ]);

  // SPECIAL HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    setAnswer((selectedValue || range.answerFrom));
  }, [ selectedValue, range ]);

  // STORE HOOKS

  // METHODS
  const handleAnswer = useCallback((cbAnswer) => {
    if (clickBlock || localBlock?.current || answered) return;
    if (Number.isNaN(cbAnswer)) return;

    setLocalBlock();
    setAnswered(true);
    onAnimation();
    setTimeout(() => {
      onAnswer(cbAnswer, CONFIG.answerDelay);
    }, CONFIG.answerDelay);
  }, [
    clickBlock,
    localBlock,
    setLocalBlock,
    answered,
    onAnswer,
    onAnimation,
  ]);

  // EVENT HANDLES

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAnswer(answer);
    }
  }, [ handleAnswer, answer ]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  // HELPERS

  // RENDERS

  // RENDER: QuestionSlider
  if (!question) {
    return null;
  }
  return (
    <div
      data-test='QuestionSlider'
      className={classNames(styles.questionSlider, {
        [styles.closing]: answered,
      })}
    >
      <div className={styles.container}>
        <div className={styles.questionContainer}>
          <div className={styles.question}>
            { (question && question.question) || translate('9levels_ass_dnd_title') }
          </div>
          { question.description && (
            <div className={styles.questionDescription}>
              { translate(question.description) }
            </div>
          ) }
          <div className={styles.sliderBlock}>
            <div className={styles.sliderWrapper}>
              <SliderSingle
                value={value}
                from={range.answerFrom}
                step={range.answerStep}
                to={range.answerTo}
                onChange={(val) => setAnswer(val ?? CONFIG.defaultAnswer)}
              />
            </div>
            <div className={styles.labels}>
              <span>{ translate('9levels_ass_slider_disagree') }</span>
              <span>{ translate('9levels_ass_slider_agree') }</span>
            </div>
          </div>

          { /* ANSWER BUTTON */ }
          <Button
            size='M'
            onClick={() => {
              handleAnswer(answer);
            }}
          >
            { translate('9levels_ass_continue_btn') }
          </Button>

          { /* HELP BUTTON */ }
          { !question.hideHelp && (
            <div className={styles.helpButton}>
              <Button
                size='S'
                looks='tertiary'
                onClick={onHelp}
              >
                { translate('assessment_help_button') }
              </Button>
            </div>
          ) }

        </div>
      </div>
    </div>
  );
};

export default QuestionSlider;
