import React, { useState, useEffect, useMemo } from 'react';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { setDecimalSeparator } from 'utils/userpreference';
import { filterHiddenTeamResults } from 'utils/assessment';

import {
  ImgCircle,
  InfoTitle,
  LineDiagram,
  Card,
  Modal,
  Callout,
  TeamDiagramBarsNext,
} from 'ui/basic';

import styles from './TeamBig5Result.module.scss';


const TeamBig5Result = (props) => {
  // PROPS
  const { profile, referenceProfile, showAverage } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  // STATE
  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalHeaderPosition, setModalHeaderPosition ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalPreHeader, setModalPreHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalData, setModalData ] = useState();

  const resultsFiltered = useMemo(() => (
    filterHiddenTeamResults(profile?.results)
  ), [ profile?.results ]);

  // METHODS
  const getDimensionsData = () => {
    if (!resultsFiltered) {
      return [];
    }

    return resultsFiltered.map((big5Result) => {
      // Range mapping
      const getRange = () => {
        if (referenceProfile?.results) {
          const thisResult = referenceProfile.results.find((r) => r.dimensionId === big5Result.id);
          if (thisResult) {
            return [ thisResult.minValue, thisResult.maxValue ];
          }

          // For auto generated roles there are no range values for dimension itself,
          // it should be calculated based on sub dimensions values (if present)
          const ids = big5Result.subDimensions?.map(({ id }) => id) ?? [];
          const ranges = referenceProfile.results.filter(({ dimensionId }) => ids.includes(dimensionId));
          if (ranges.length) {
            return [
              ranges.reduce((total, { minValue }) => total + minValue, 0) / ranges.length,
              ranges.reduce((total, { maxValue }) => total + maxValue, 0) / ranges.length,
            ];
          }
        }

        return null;
      };

      // Dimensions data mapping
      return ({
        id: big5Result.id,
        label: big5Result.name,
        range: getRange(),
        data: big5Result.values.map(({ user, value }) => ({
          id: user.id,
          label: user.name,
          img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
          value,
        })),
      });
    });
  };

  const getLabelDescription = (value, username, dimensionName) => {
    if (value >= 1 && value <= 2) {
      return translate(
        'big5_dimension_very_low',
        [ '{{dimension}}', dimensionName, '{{username}}', username ],
      );
    }

    if (value > 2 && value <= 4) {
      return translate(
        'big5_dimension_low',
        [ '{{dimension}}', dimensionName, '{{username}}', username ],
      );
    }

    if (value > 4 && value <= 6) {
      return translate(
        'big5_dimension_medium',
        [ '{{dimension}}', dimensionName, '{{username}}', username ],
      );
    }

    if (value > 6 && value <= 8) {
      return translate(
        'big5_dimension_high',
        [ '{{dimension}}', dimensionName, '{{username}}', username ],
      );
    }

    return translate(
      'big5_dimension_very_high',
      [ '{{dimension}}', dimensionName, '{{username}}', username ],
    );
  };

  const getCalloutText = (value, dimensionId) => {
    if (value >= 1 && value <= 2) {
      return translate(`big5_report_dim_${dimensionId}_callout_very_low`);
    }
    if (value > 2 && value <= 4) {
      return translate(`big5_report_dim_${dimensionId}_callout_low`);
    }
    if (value > 4 && value <= 6) {
      return translate(`big5_report_dim_${dimensionId}_callout_medium`);
    }
    if (value > 6 && value <= 8) {
      return translate(`big5_report_dim_${dimensionId}_callout_high`);
    }
    return translate(`big5_report_dim_${dimensionId}_callout_very_high`);
  };

  const renderModalContent = (resultItem = {}) => {
    const { totalValue, dimensionId, subDimensions } = resultItem;

    return (
      <div className={styles.infoModalResult}>
        <div className={styles.reportDiagram}>
          <div className={styles.header}>
            <span>{ translate('big5_report_facet_result') }</span>
            <span>{ setDecimalSeparator(totalValue) }</span>
          </div>

          <div className='marginTopXs'>
            <LineDiagram
              score={totalValue}
            />
          </div>
        </div>

        <Callout>
          { getCalloutText(totalValue, dimensionId) }
        </Callout>

        <div className={styles.facets}>
          <div className={styles.title}>
            { translate(`big5_report_dim_${dimensionId}_facets`) }
          </div>

          { subDimensions.map((subDimension) => (
            <div className={styles.facet} key={`subDimension-${subDimension.name}`}>
              <div className={styles.facetHeader}>
                <span>{ subDimension.name }</span>
                <span>{ setDecimalSeparator(subDimension.result) }</span>
              </div>

              <div className='marginTopXs'>
                <LineDiagram
                  score={subDimension.result}
                />
              </div>

              <div className={styles.facetFooter}>
                <span>{ translate('big5_report_resut_hint1') }</span>
                <span>{ translate('big5_report_resut_hint2') }</span>
              </div>
            </div>
          )) }
        </div>
      </div>
    );
  };

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData?.dimensionId) {
      return;
    }

    setModalContent(renderModalContent(modalData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.dimensionId ]);

  return (
    <Card>
      <InfoTitle
        title={translate('big5_results_title')}
        onClick={() => {
          setModalHeader(translate('big5_results_info_title'));
          setModalHeaderPosition('left');
          setModalPreHeader(null);
          setModalSubHeader(null);
          setModalContent(translate('big5_results_info_description'));
          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBarsNext
          labelAverage={translate('team_profile_average_title')}
          showAverage={showAverage}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: `)}
          onItemClick={(item) => {
            const userId = item.id;
            const userName = item.label;
            const userImage = item.img;
            const totalValue = item.value;
            const { dimensionId } = item;
            const { dimensionName } = item;

            const big5Dimension = resultsFiltered.find((result) => result.id === dimensionId);
            const subDimensions = big5Dimension.subDimensions.map(({ name, values }) => {
              const userValue = values.find(({ user }) => (user?.id ?? user) === userId);
              return {
                name,
                result: userValue?.value ?? 0,
              };
            });

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getLabelDescription(totalValue, userName, dimensionName));
            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size='M'
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />,
            );

            setModalData({ totalValue, dimensionId, subDimensions });
            setShowModal(true);
          }}
        />
      </div>

      { showModal && (
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          subHeader={modalSubHeader}
          subHeaderPosition='center'
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onClose={() => {
            setShowModal(false);
            setModalData();
          }}
          onConfirm={() => {
            setShowModal(false);
            setModalData();
          }}
        >
          { modalContent }
        </Modal>
      ) }

    </Card>
  );
};

export default TeamBig5Result;
