// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Teams.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { VideoInstructional } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Teams
const Teams = (props) => {
  // PROPS
  const {
    controls = {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Teams
  return (
    <div className={classNames(styles.teams)}>
      <div className='bluContent686'>
        <div className={styles.formRow}>
          <VideoInstructional src='https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm' />
        </div>
      </div>

      <div className='bluContent466'>
        <div className='bluTypeS'>
          { translate('onboarding_admin_teams_title') }
        </div>
        <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
          { translate('onboarding_admin_teams_description') }
        </div>

        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        ) }
      </div>
    </div>
  );
};

export default Teams;
