// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ResultsInDetail.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { PeerBreakdown } from 'ui/molecules/CustomReport/Blocks';


// COMPONENT: ResultsInDetail
const ResultsInDetail = (props) => {
  // SPECIAL HOOKS
  const translate = useTranslate();


  // RENDER: ResultsInDetail
  return (
    <div className={classNames(styles.resultsInDetail)}>
      <span className={classNames('bluTypeS', styles.blue)}>
        { translate('peer_360_report_detailed_results') }
      </span>
      <div className={classNames('bluTypeCopy', 'marginTopM')}>
        { translate('peer_360_report_detailed_results_copy') }
      </div>
      <PeerBreakdown
        configuration={{ mode: 'dimension-breakdown' }}
        {...props}
      />
    </div>
  );
};

export default ResultsInDetail;
