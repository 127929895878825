import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import * as action from 'store/actions';
import {
  all, call, put, take,
} from 'redux-saga/effects';
import { getAssessmentResult } from './getAssessmentResult';
import { getAssessment } from './getAssessment';
import { listAssessments } from './listAssessments';

const Potential = ASSESSMENT_TYPES.POTENTIAL;
const Big5 = ASSESSMENT_TYPES.BIG5;
const WP = ASSESSMENT_TYPES.WORK_PREFERENCES;
const KeyComp = ASSESSMENT_TYPES.KEY_COMPETENCIES;
const LeadComp = ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES;
const { RMP } = ASSESSMENT_TYPES;
const NineLevels = ASSESSMENT_TYPES.NINE_LEVELS;

export default function* watchRefreshAllAssessmentsRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.GET_ALL_ASSESSMENTS);
      const id = payload || null;
      let allCalls;
      switch (id) {
        case Big5:
          allCalls = [
            call(listAssessments),
            call(getAssessment, Big5),
            call(getAssessmentResult, Big5),
          ];
          break;
        case WP:
          allCalls = [
            call(listAssessments),
            call(getAssessment, WP),
            call(getAssessmentResult, WP),
          ];
          break;
        case Potential:
          allCalls = [
            call(listAssessments),
            call(getAssessment, Potential),
            call(getAssessmentResult, Potential),
          ];
          break;
        default:
          allCalls = [
            call(listAssessments),
            call(getAssessmentResult, Potential),
            call(getAssessmentResult, Big5),
            call(getAssessmentResult, WP),
            call(getAssessmentResult, KeyComp),
            call(getAssessmentResult, LeadComp),
            call(getAssessmentResult, RMP),
            call(getAssessmentResult, NineLevels),
          ];
      }

      yield all(allCalls);
      yield put(action.getAllAssessmentsFulfilled());
    } catch (error) {
      console.warn('ERROR ', error);
      yield put(action.getAllAssessmentsRejected(error));
    }
  }
}
