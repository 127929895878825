// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ListNext.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import ListNextItem from './ListNextItem';

// STORE

// CONFIG & DATA

// COMPONENT: ListNext
const ListNext = (props) => {
  // PROPS
  const {
    listItems = [],
    layout = 'layout3Cells', // or 'layout5Cells'
    layout5CellsCompactMode,
    showAvatar = true,
    showArrow = true,

    options = [],
    onOptionClick = () => {},

    listItemsMoreContent = [],
    showMore = false,

    onItemClick = () => {},
    disableItem = () => false,
    hideEmptyItemIdentifierSublabel,
    hasTooltips,
    cellLastXsBehavior,
    // default (undefined):
    //    - moves the last cell up to itemIdentifier on Xs
    // 'addCellLastXs':
    //    - adds extra .cellLastXs and moves
    //      that up to itemIdentifier
    // all:
    //    - if no label is set: centers last cell value horizontally
    //      and aligns it to right for .cell3, .cell5 and .cellLastXs

  } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: ListNext
  return (
    <div className={classNames(styles.listNext)}>
      { /* LISTITEMS */ }
      { listItems.map((item, index) => (
        <ListNextItem
          key={index + item.id}
          item={item}
          layout={layout}
          disabled={disableItem(index)}
          showAvatar={showAvatar}
          showArrow={showArrow}
          layout5CellsCompactMode={layout5CellsCompactMode}
          options={options}
          onOptionClick={onOptionClick}
          showMore={showMore}
          moreContent={listItemsMoreContent.find((c) => c.id === item.id) && listItemsMoreContent.find((c) => c.id === item.id).content}
          onItemClick={() => onItemClick(index, disableItem(index))}
          disableItem={() => disableItem(index)}
          hideEmptyItemIdentifierSublabel={hideEmptyItemIdentifierSublabel}
          hasTooltips={hasTooltips}
          cellLastXsBehavior={cellLastXsBehavior}
        />
      )) }

    </div>
  );
};

export default ListNext;
