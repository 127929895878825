export const RESET = '[CANDIDATE] RESET';
export const reset = () => ({ type: RESET });


export const GET_CANDIDATE = '[CANDIDATE] GET_CANDIDATE';
export const getCandidate = ({ id, showLoading = true }) => ({
  type: GET_CANDIDATE,
  payload: { id, showLoading },
});

export const GET_CANDIDATE_SUCCESS = '[CANDIDATE] GET_CANDIDATE_SUCCESS';
export const getCandidateSuccess = ({ data }) => ({
  type: GET_CANDIDATE_SUCCESS,
  payload: { data },
});

export const GET_CANDIDATE_FAIL = '[CANDIDATE] GET_CANDIDATE_FAIL';
export const getCandidateFail = ({ error }) => ({
  type: GET_CANDIDATE_FAIL,
  payload: { error },
});


export const GET_CANDIDATE_ASSESSMENTS = '[CANDIDATE] GET_CANDIDATE_ASSESSMENTS';
export const getCandidateAssessments = ({ id, showLoading = true }) => ({
  type: GET_CANDIDATE_ASSESSMENTS,
  payload: { id, showLoading },
});

export const GET_CANDIDATE_ASSESSMENTS_SUCCESS = '[CANDIDATE] GET_CANDIDATE_ASSESSMENTS_SUCCESS';
export const getCandidateAssessmentsSuccess = ({ data }) => ({
  type: GET_CANDIDATE_ASSESSMENTS_SUCCESS,
  payload: { data },
});

export const GET_CANDIDATE_ASSESSMENTS_FAIL = '[CANDIDATE] GET_CANDIDATE_ASSESSMENTS_FAIL';
export const getCandidateAssessmentsFail = ({ error }) => ({
  type: GET_CANDIDATE_ASSESSMENTS_FAIL,
  payload: { error },
});

export const GET_CANDIDATE_SUMMARY = '[CANDIDATE] GET_CANDIDATE_SUMMARY';
export const GET_CANDIDATE_SUMMARY_SUCCESS = '[CANDIDATE] GET_CANDIDATE_SUMMARY_SUCESS';
export const GET_CANDIDATE_SUMMARY_FAIL = '[CANDIDATE] GET_CANDIDATE_SUMMARY_FAIL';

export const GET_CANDIDATE_ROLEFIT = '[CANDIDATE] GET_CANDIDATE_ROLEFIT';
export const GET_CANDIDATE_ROLEFIT_SUCCESS = '[CANDIDATE] GET_CANDIDATE_ROLEFIT_SUCCESS';
export const GET_CANDIDATE_ROLEFIT_FAIL = '[CANDIDATE] GET_CANDIDATE_ROLEFIT_FAIL';
