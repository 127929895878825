import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

// CONFIG & DATA
import { ENABLED_LANGUAGES, COMPANY_ID } from 'utils/configuration';
import { LANGUAGES } from 'utils/configuration/const/languages';


function* getLanguages() {
  try {
    const { status, ok, data } = yield call(
      get,
      'core/language',
      { company: COMPANY_ID },
    );

    if (ok && status === 200) {
      // Select and sort languages codes alphabetically (but place English at the top)
      const languages = (ENABLED_LANGUAGES || data.languages).sort();
      const enIndex = languages.indexOf(LANGUAGES.EN);
      if (enIndex > 0) {
        languages.splice(enIndex, 1);
        languages.unshift(LANGUAGES.EN);
      }

      yield put(action.getLanguagesFulfilled({ languages }));
    } else {
      yield put(action.getLanguagesRejected(data));
    }
  } catch (error) {
    console.warn('getLanguages error', error);
    yield put(action.getLanguagesRejected(error));
  }
}

export default function* watchGetLanguagesRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.GET_LANGUAGES);

      yield call(getLanguages, payload);
    } catch (error) {
      yield put(action.getLanguagesRejected(error));
    }
  }
}
