import { createSelector } from '@reduxjs/toolkit';

const selectLocalisation = (state) => state.localisation;

export const selectCurrentLanguage = createSelector(
  selectLocalisation,
  (localization) => localization.currentLanguage,
);
export const selectLanguages = createSelector(
  selectLocalisation,
  (localization) => localization.languages || [],
);
