// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionExplanatory.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionExplanatory
const QuestionExplanatory = (props) => {
  // PROPS
  const { question = {} } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const { label, questionText, description } = question;

  // RENDER: QuestionExplanatory
  return (
    <div className={styles.questionExplanatory}>
      { label && <div className={styles.label}>{ label }</div> }

      <div className='bluTypeXs'>
        { questionText }
      </div>

      { description && (
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate(description) }
        </div>
      ) }
    </div>
  );
};

export default QuestionExplanatory;
