// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './RunTable.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { timestampToFullDate } from 'utils/dateTools';
import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { useSelector } from 'react-redux';
import { Button, Card, Pagination } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  PAGINATION: 4,
};

// COMPONENT: RunTable
const RunTable = (props) => {
  // PROPS
  const {
    entity,
    selectedRun,
    setSelectedRunId,
    runs = [],
    isSurvey = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ pageIndex, setPageIndex ] = useState();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  useEffect(() => {
    const index = runs.findIndex(({ id }) => id === selectedRun?.id);
    setPageIndex(Math.floor(index / Config.PAGINATION));
  }, [ runs, selectedRun?.id ]);

  // RENDER: row
  const renderRow = (run) => {
    const highlight = run.id === selectedRun.id;
    return (
      <div
        key={run.id}
        className={classNames([ styles.row, styles.selectable ])}
        onClick={() => setSelectedRunId(run.id)}
        role='presentation'
      >
        <div className={classNames({ [styles.highlight]: highlight })}>
          { !run.closedAt && (
            <div className={styles.activeDot} />
          ) }
        </div>
        <div
          className={classNames(styles.rowDescription, { [styles.highlight]: highlight })}
        >
          { `${timestampToFullDate(run.createdAt)} ${run.description ? `(${run.description})` : ''}` }
        </div>
        <div
          className={classNames(styles.right, { [styles.highlight]: highlight })}
        >
          { [ run.scope.amount, run.scope.type ].join(' ') }
        </div>
        <div
          className={classNames(styles.right, { [styles.highlight]: highlight })}
        >
          { `${run.participation.completedUsers} / ${run.participation.includedUsers}` }
        </div>
        <div
          className={classNames({ [styles.highlight]: highlight })}
        >
          <div className={classNames(styles.arrow)}>
            <IconsSvg.ArrowRight />
          </div>
        </div>
      </div>
    );
  };

  // RENDER: RunTable
  if (!selectedRun) {
    return null;
  }

  return (
    <div className={classNames(styles.runTable)}>
      <Card>
        <div className={styles.title}>
          <div className='bluTypeXxs'>
            { translate('run_table_title') }
          </div>
          { runs.every(({ closedAt }) => Boolean(closedAt)) && (
            <div>
              <Button
                size='S'
                looks='primary'
                onClick={() => history.push({
                  pathname: `/${isSurvey ? 'surveys' : 'assessments'}/${entity.id}/new-run`,
                  state: { survey: entity },
                })}
              >
                { translate('run_table_new_iteration') }
              </Button>
            </div>
          ) }
        </div>
        <div className={classNames(styles.table)}>
          <div className={styles.row}>
            <div />
            <div className={classNames(styles.rowDescription, styles.bold)}>
              { translate('run_table_header_iteration') }
            </div>
            <div className={classNames(styles.right, styles.bold)}>
              { translate('run_table_header_scope') }
            </div>
            <div className={classNames(styles.right, styles.bold)}>
              { translate('run_table_header_participation') }
            </div>
            <div />
          </div>
          { runs
          .filter((_, index) => Math.floor(index / Config.PAGINATION) === pageIndex)
          .map(renderRow) }
        </div>

        { (runs.length / Config.PAGINATION) > 1 && (
          <Pagination
            pageCount={Math.ceil(runs.length / Config.PAGINATION)}
            pagePosition={pageIndex}
            showPagerButtons
            showNumbers
            onPagePositionUpdate={setPageIndex}
          />
        ) }
      </Card>
    </div>
  );
};

export default RunTable;
