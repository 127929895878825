import React, { memo, useEffect, useState } from 'react';
import styles from './AssessmentRadioButtonGroup.module.scss';

import classNames from 'classnames';

import { AssessmentRadioButton } from 'ui/basic/assessments/AssessmentRadioButton';

const AssessmentRadioButtonGroup = memo((props) => {
  const {
    name,
    items = [],
    selectedValue: selectedValueProp,
    size,
    layout = 'vertical',
    style = {},
    deselectable = false,
    keyPressEnabled = true,
    showNumbers = false,
    showAnimation = false,
    onChange = () => {},
    onSingleButtonHover = () => {},
  } = props;
  const [ selectedValue, setSelectedValue ] = useState(selectedValueProp);

  useEffect(() => {
    if (!keyPressEnabled) {
      return undefined;
    }

    const handleKeyPress = (event) => {
      const match = items.find(({ value }) => String(value) === event.key);
      if (match) {
        setSelectedValue(match.value);
        onChange(match.value);
      }
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [ onChange, items, keyPressEnabled ]);


  // RENDER: AssessmentRadioButtonGroup
  return (
    <div
      className={classNames(styles.assessmentRadioButtonGroup, styles[layout])}
      style={style}
    >
      { items.map((item, index) => (
        <AssessmentRadioButton
          key={item.value}
          name={name}
          title={item.label}
          style={{ marginBottom: '17px' }}
          size={size}
          checked={selectedValue === item.value}
          showAnimation={showAnimation}
          value={item.value}
          number={showNumbers && index + 1}
          onDeselect={!deselectable ? undefined : () => {
            setSelectedValue();
            onChange();
          }}
          onChange={(value) => {
            setSelectedValue(value);
            onChange(value);
          }}
          onHover={onSingleButtonHover}
        />
      )) }
    </div>
  );
});

export default AssessmentRadioButtonGroup;
