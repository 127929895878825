import React from 'react';

// INTERMISSIONS

// OTHER COMPONENTS
import { Button } from 'ui/basic';

export const exampleMixedConfig = {
  id: 'mixed',
  title: 'Mixed',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  loadingPage: {
    render: (state) => (
      <>
        <h5>
          { state.title }
        </h5>
        <br />
        <br />
        <br />
        <Button disabled>
          Start
        </Button>
      </>
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <>
          <h5>
            { state.title }
          </h5>
          <br />
          <br />
          <br />
          <Button
            // disabled={state.questionIndex === state.pages.length - 1}
            onClick={() => {
              next();
            }}
          >
            Start
          </Button>
        </>
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => {
        const { finishable, handleFinish, errorEnd } = extras;
        return (
          <>
            <h5>End</h5>
            <br />
            <br />
            <br />
            <Button
              disabled={!finishable}
              onClick={() => {
                handleFinish();
              }}
            >
              Finish
            </Button>
            { JSON.stringify(errorEnd, null, 2) }
          </>
        );
      },
    },
  ],
};
