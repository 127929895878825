import * as fromActionTypes from 'features/+admin/store/actionTypes/instanceOverview';

// GET INDUSTRIES
export const getIndustries = () => ({
  type: fromActionTypes.GET_INDUSTRIES,
  payload: {},
});

export const getIndustriesFulfilled = ({ industries }) => ({
  type: fromActionTypes.GET_INDUSTRIES_FULFILLED,
  payload: { industries },
});

export const getIndustriesRejected = ({ error }) => ({
  type: fromActionTypes.GET_INDUSTRIES_REJECTED,
  payload: { error },
});

// GET INSTANCES
export const initGetInstances = () => ({
  type: fromActionTypes.INIT_GET_INSTANCES,
  payload: {},
});

export const getInstances = (requestDto) => ({
  type: fromActionTypes.GET_INSTANCES,
  payload: { requestDto },
});

export const getInstancesFulfilled = ({
  instances, instanceType, infiniteLoad, totalCount, noDataAtAll,
}) => ({
  type: fromActionTypes.GET_INSTANCES_FULFILLED,
  payload: {
    instances, instanceType, infiniteLoad, totalCount, noDataAtAll,
  },
});

export const getInstancesRejected = ({ error }) => ({
  type: fromActionTypes.GET_INSTANCES_REJECTED,
  payload: { error },
});

// GET INSTANCE
export const initGetInstance = () => ({
  type: fromActionTypes.INIT_GET_INSTANCE,
  payload: {},
});

export const getInstance = (instanceId) => ({
  type: fromActionTypes.GET_INSTANCE,
  payload: { instanceId },
});

export const getInstanceFulfilled = ({ instance }) => ({
  type: fromActionTypes.GET_INSTANCE_FULFILLED,
  payload: { instance },
});

export const getInstanceRejected = ({ error }) => ({
  type: fromActionTypes.GET_INSTANCE_REJECTED,
  payload: { error },
});

// GET INSTANCE REGISTRATION DOMAINS
export const initGetInstanceRegistrationDomains = () => ({
  type: fromActionTypes.INIT_GET_INSTANCE_REG_DOMAINS,
  payload: {},
});

export const getInstanceRegistrationDomains = (instanceId) => ({
  type: fromActionTypes.GET_INSTANCE_REG_DOMAINS,
  payload: { instanceId },
});

export const getInstanceRegistrationDomainsFulfilled = ({ registrationDomains }) => ({
  type: fromActionTypes.GET_INSTANCE_REG_DOMAINS_FULFILLED,
  payload: { registrationDomains },
});

export const getInstanceRegistrationDomainsRejected = ({ error }) => ({
  type: fromActionTypes.GET_INSTANCE_REG_DOMAINS_REJECTED,
  payload: { error },
});

// UPDATE INSTANCE REGISTRATION DOMAINS
export const updateInstanceRegistrationDomains = (instanceId, domains) => ({
  type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS,
  payload: { instanceId, domains },
});

export const updateInstanceRegistrationDomainsFulfilled = () => ({
  type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_FULFILLED,
  payload: {},
});

export const updateInstanceRegistrationDomainsRejected = ({ error }) => ({
  type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_REJECTED,
  payload: { error },
});

// GET USER STATISTICS
export const getUserStatistics = () => ({
  type: fromActionTypes.GET_USER_STATISTICS,
  payload: {},
});

export const getUserStatisticsFulfilled = ({ statistics }) => ({
  type: fromActionTypes.GET_USER_STATISTICS_FULFILLED,
  payload: { statistics },
});

export const getUserStatisticsRejected = ({ error }) => ({
  type: fromActionTypes.GET_USER_STATISTICS_REJECTED,
  payload: { error },
});

// REBUILD CACHE
export const initRebuildCache = () => ({
  type: fromActionTypes.INIT_REBUILD_CACHE,
  payload: {},
});

export const rebuildCache = (companyId) => ({
  type: fromActionTypes.REBUILD_CACHE,
  payload: { companyId },
});

export const rebuildCacheFulfilled = () => ({
  type: fromActionTypes.REBUILD_CACHE_FULFILLED,
  payload: {},
});

export const rebuildCacheRejected = ({ error }) => ({
  type: fromActionTypes.REBUILD_CACHE_REJECTED,
  payload: { error },
});
