// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './Info.module.scss';

// ASSETS

// STORE
import { useSelector } from 'react-redux';
import { selectUser } from 'features/framework/storeNext/configurationSlice';

// 3RD PARTY

// OTHER COMPONENTS
import {
  CollapsibleNext, Card, Button, TextArea, Toast,
} from 'ui/basic';

// UTILS
import { get, getLocal } from 'api';
import * as configuration from 'utils/configuration';
import { emptyDash } from 'utils/textTools';

// CONFIG & DATA

// COMPONENT: Info
const Info = () => {
  // COMPONENT/UI STATE and REFS
  const [ versionInfo, setVersionInfo ] = useState({});
  const [ backendInfo, setBackendInfo ] = useState({});

  const [ showToast, setShowToast ] = useState(false);
  const [ clipboardError, setClipboardError ] = useState(false);

  useEffect(() => {
    getLocal('version.json').then((result) => {
      const { data } = result;
      setVersionInfo(data);
    });
    get('/core/static/info').then((result) => {
      setBackendInfo(result.data);
    });
  }, []);

  useEffect(() => {
    document.title = 'bluquist - Info';
  }, []);

  // STORE HOOKS
  const currentUser = useSelector(selectUser);

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: Info
  return (
    <div className={`${styles.info}`}>
      <div className={styles.grid}>
        <div className={styles.content}>

          <Card>
            <h4>Info</h4>

            <br />
            <br />
            <h5>Executive Summary:</h5>
            <br />
            <table>
              <tbody>
                <tr>
                  <td>
                    Frontend Version
                  </td>
                  <td>
                    { versionInfo.version }
                  </td>
                </tr>
                <tr>
                  <td>
                    Frontend Short Build
                  </td>
                  <td>
                    { versionInfo.shortBuild }
                  </td>
                </tr>
                <tr>
                  <td>
                    Frontend Build Detail
                  </td>
                  <td>
                    { versionInfo.buildDetail }
                  </td>
                </tr>
                <tr>
                  <td>
                    Instance
                  </td>
                  <td>
                    { window.location.hostname }
                  </td>
                </tr>
                <tr>
                  <td>
                    CONFIGURATION
                  </td>
                  <td>
                    { configuration.CONFIGURATION }
                  </td>
                </tr>
                <tr>
                  <td>
                    currentUser.role
                  </td>
                  <td>
                    { emptyDash(currentUser.role, '-') }
                  </td>
                </tr>
                <tr>
                  <td>
                    API
                  </td>
                  <td>
                    { configuration.REACT_APP_API_BASE_URL }
                  </td>
                </tr>
                <tr>
                  <td>
                    Backend Version
                  </td>
                  <td>
                    { emptyDash(backendInfo.release) }
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <br />
            <h5>Debug Info</h5>
            <br />
            <TextArea value={JSON.stringify({
              date: new Date(),
              instance: window.location.hostname,
              version: versionInfo.version,
              configuration: configuration.CONFIGURATION,
              userRole: (currentUser && currentUser.role) || null,
              api: configuration.REACT_APP_API_BASE_URL,
              configurationjs: configuration,
              postBuildConfig: configuration.getPostBuildConfig(),
              versionInfo,
              backendInfo,
              currentUser,
            }, null, 2)}
            />
            <br />
            <div style={{ display: 'flex', alignItems: 'right' }}>
              <Button
                size='S'
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify({
                    date: new Date(),
                    instance: window.location.hostname,
                    version: versionInfo.version,
                    configuration: configuration.CONFIGURATION,
                    userRole: (currentUser && currentUser.role) || null,
                    api: configuration.REACT_APP_API_BASE_URL,
                    configurationjs: configuration,
                    postBuildConfig: configuration.getPostBuildConfig(),
                    versionInfo,
                    backendInfo,
                    currentUser,
                  }, null, 2))
                  .then(
                    () => {
                      setShowToast(true);
                    },
                    (error) => {
                      setShowToast(true);
                      setClipboardError(error);
                    },
                  );
                }}
              >
                Copy All Info
              </Button>
            </div>


            <br />
            <br />
            <h5>Configuration:</h5>
            <br />
            <CollapsibleNext header='configuration.js' showTopBorder>
              <pre>
                { JSON.stringify(configuration, null, 2) }
              </pre>
            </CollapsibleNext>
            <CollapsibleNext header='post-build config'>
              <pre>
                { JSON.stringify(configuration.getPostBuildConfig(), null, 2) }
              </pre>
            </CollapsibleNext>
            <CollapsibleNext header='process.env'>
              <pre>
                { JSON.stringify(process.env, null, 2) }
              </pre>
            </CollapsibleNext>


            <br />
            <br />
            <h5>Backend Info:</h5>
            <br />
            <CollapsibleNext header='static/info' showTopBorder>
              <pre>
                { JSON.stringify(backendInfo, null, 2) }
              </pre>
            </CollapsibleNext>


            <br />
            <br />
            <h5>currentUser:</h5>
            <br />
            <CollapsibleNext header='currentUser' showTopBorder>
              <pre>
                { JSON.stringify(currentUser, null, 2) }
              </pre>
            </CollapsibleNext>

            <br />
            <br />
            <h5>
              Frontend version:
              { versionInfo && versionInfo.version }
            </h5>
            <br />
            <CollapsibleNext header='version.json' showTopBorder>
              <pre>
                { JSON.stringify(versionInfo, null, 2) }
              </pre>
            </CollapsibleNext>
          </Card>

          { showToast
            && (
              <Toast onClose={() => {
                setShowToast(false);
                setClipboardError(undefined);
              }}
              >
                <div className={styles.toastTitle}>
                  Debug Info
                </div>
                <div className={styles.toastDescription}>
                  { clipboardError || 'Copied to clipboard successfully' }
                </div>
              </Toast>
            ) }
        </div>
      </div>
    </div>
  );
};

export default Info;
