// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './SimpleLineDiagram.module.scss';

// 3RD PARTY
import { GarminBarDiagram } from 'features/+garmin/ui/basic/GarminBarDiagram';
import { LineDiagram, SegmentedLineDiagram } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';

// CONFIG & DATA
const Config = {
  mode: {
    LINE: 'line',
    SEGMENTS: 'segments',
    BAR: 'bar',
  },
};


// COMPONENT: SimpleLineDiagram
const SimpleLineDiagram = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    assessmentResults,
    segmentsCount = 10,
    renderMin,
  } = props;

  const {
    mode,
    color,
    showResultValue = true,
  } = configuration;
  const {
    dimension: dimensionId,
  } = content;

  const flatAssessmentResults = [
    ...assessmentResults,
    ...assessmentResults.map(({ subDimensions = [] }) => subDimensions),
  ].flat();

  const dimension = flatAssessmentResults.find(({ id }) => id === dimensionId);

  // RENDER: Bar
  const renderBar = () => {
    const looks = color ? `block-${color}` : undefined;
    switch (mode) {
      case Config.mode.SEGMENTS: {
        return (
          <SegmentedLineDiagram
            score={dimension.result}
            segmentsCount={segmentsCount}
            looks={looks}
          />
        );
      }
      case Config.mode.LINE: {
        return (
          <LineDiagram
            segmentsCount={segmentsCount}
            renderMin={renderMin}
            score={dimension.result}
            style={looks && { score: looks }}
          />
        );
      }
      case Config.mode.BAR: {
        return (
          <GarminBarDiagram
            value={(dimension.result / segmentsCount) * 100}
            looks={looks}
          />
        );
      }
      default:
    }
    return null;
  };

  // RENDER: SimpleLineDiagram
  if (!dimension) {
    return null;
  }

  return (
    <div className={styles.simpleLineDiagram}>
      <div>
        { renderBar() }
      </div>
      <div className={styles.textContent}>
        { showResultValue && (
          <div className={styles.blue}>
            { setDecimalSeparator(dimension.result) }
          </div>
        ) }
        <div>
          { dimension.name }
        </div>
      </div>
    </div>
  );
};

export default SimpleLineDiagram;
