import React from 'react';
import styles from './ResetPasswordSuccessForm.module.scss';
import { useHistory } from 'react-router-dom';

import { useTranslate } from 'utils/translator';

import Button from 'ui/basic/forms/Button';

const ResetPasswordSuccessForm = () => {
  const translate = useTranslate();
  const history = useHistory();

  return (
    <div className={styles.resetPasswordSuccessForm}>
      { translate('resetpwsetsuc_title') }
      <div className={styles.paragraph}>
        { translate('resetpwsetsuc_copy') }
      </div>
      <Button onClick={() => history.push('/')}>{ translate('resetpwsetsuc_buton_login') }</Button>
    </div>
  );
};

export default ResetPasswordSuccessForm;
