// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './StripeWidget.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// OTHER COMPONENTS
import StripePaymentElement from './StripePaymentElement/StripePaymentElement';

// UTILS

// STORE NEXT
import * as api from 'api';
import { useSelector } from 'react-redux';
import { selectSettingsLanguage } from 'features/framework/storeNext/configurationSlice';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};
import { STRIPE_ELEMENT_PUBLIC_KEY } from 'features/+adminUG/config/payment.config';

const stripePromise = loadStripe(STRIPE_ELEMENT_PUBLIC_KEY);

const APPEARANCE = {
  variables: {
    fontFamily: styles.typeFamily,
    fontSizeBase: styles.typeSizeCopy,
    fontSizeSm: styles.typeSizeCopy,
    spacingUnit: styles.spaceXxs,
    spacingGridRow: styles.spaceXs,
    spacingGridColumn: styles.spaceXs,
    colorDanger: styles.colorDanger1,
  },
  rules: {
    '.Input': {
      height: '52px',
      padding: styles.spaceXs,
    },
    '.Input:hover': {
      boxShadow: styles.shadowDark,
      borderColor: styles.colorWhite,
      backgroundColor: styles.colorWhite,
    },
    '.Input:focus': {
      boxShadow: styles.shadowDark,
      borderBottomColor: styles.colorPrimary1,
      backgroundColor: styles.colorWhite,
    },
  },
};


// COMPONENT: StripeWidget
const StripeWidget = (props) => {
  // PROPS
  const {
    bottomElements,
    controls,
    onSubmit = () => {},
  } = props;

  // SPECIAL HOOKS

  const settingsLanguage = useSelector(selectSettingsLanguage);

  const [ options, setOptions ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  useEffect(() => {
    if (options) {
      return;
    }

    api.post('/core/subscription/payment/setup')
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setOptions({
          appearance: APPEARANCE,
          fonts: [ {
            family: 'Montserrat',
            src: 'url(https://app.bluquist.app/unsupported/fonts/montserrat-v15-latin-regular.woff)',
            weight: '400',
          } ],
          locale: settingsLanguage?.split('_')?.[0],
          loader: 'always',
          clientSecret: data.clientSecret,
        });
      } else {
        setErrorMessage(data?.error?.errorMessage);
      }
    })
    .catch((error) => {
      setErrorMessage(error.message);
      console.error(error.message);
    });
  }, [ options, settingsLanguage ]);

  // RENDER: Stripe
  return (
    <div className={classNames(styles.stripeWidget)}>
      { errorMessage
        && <div className={classNames('error', 'marginTopXs')}>{ errorMessage }</div> }

      { options
        && (
          <div className='marginTopS'>
            <Elements
              stripe={stripePromise}
              options={options}
            >
              <StripePaymentElement
                bottomElements={bottomElements}
                controls={controls}
                onSubmit={onSubmit}
              />
            </Elements>
          </div>
        ) }
    </div>
  );
};

export default StripeWidget;
