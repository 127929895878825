import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import { Peer360AssessmentEnd } from '../intermissions/Peer360AssessmentEnd';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { translate } from 'utils/translator/translator';

// OTHER COMPONENTS


export const peer360Config = {
  id: 'peer-360',
  title: 'Peer360',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  // modalHelpContentTranslationKey: 'peer_360_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: () => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => {
        const { peerFor, title, description } = state;

        return (
          <AssessmentIntro
            assessmentType={ASSESSMENT_TYPES.PEER_360}
            title={title}
            description={peerFor
              ? translate(
                state.customContent?.peer_360_asmnt_peer_intro_copy ?? 'peer_360_asmnt_peer_intro_copy',
                [ '{{username}}', peerFor ],
              )
              : translate(description)}
            onClickNext={next}
          />
        );
      },
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.PEER_360}
          onClickNext={next}
          content={translate(state.guidanceContent)}
        />
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <Peer360AssessmentEnd
          {...extras}
          peerFor={state.peerFor}
          hasBqAccount={state.hasBqAccount}
        />
      ),
    },
  ],
};
