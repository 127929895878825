import React, { lazy } from 'react';

// OTHER COMPONENTS
const StaticMain = lazy(() => import('./pages/StaticMain' /* webpackChunkName: "adminUG" */));

export const routesConfigStatic = {
  routes: [
    {
      path: '/sso-login',
      pageType: 'standalone',
      PageComponent: () => <StaticMain />,
      LazyPageComponent: 'SsoLogin',
      show: {
        needsLogout: true,
        forFlavor: [ 'bluquist', 'balancedYou' ],
      },
      tools: {
        browserTitleKey: 'login_button_login',
      },
    },
    {
      path: '/sso-redirect',
      pageType: 'standalone',
      PageComponent: () => <StaticMain />,
      LazyPageComponent: 'SsoRedirect',
      show: {
        needsLogout: true,
        forFlavor: [ 'bluquist', 'balancedYou' ],
      },
      tools: {
        browserTitleKey: 'login_button_login',
      },
    },
    {
      path: '/trial-signup',
      pageType: 'modal',
      pageWrapper: { instantBackground: true, noAnimate: true },
      PageComponent: () => <StaticMain />,
      LazyPageComponent: 'PlanSignupTrial',
      useStoreNext: true,
      show: {
        needsLogout: true,
      },
    },
    {
      path: '/select-instance',
      pageType: 'standalone',
      PageComponent: () => <StaticMain />,
      LazyPageComponent: 'SelectInstance',
      show: {
        needsLogin: false,
      },
    },
  ],
};
