const REGEXES = {
  /* pattern based on but only match beginning but allow uppercase: https://regexr.com/3au3g */
  DOMAIN: /^(?:[A-z0-9](?:[A-z0-9-]{0,61}[A-z0-9])?\.)+[A-z0-9][A-z0-9-]{0,61}[A-z0-9]/,
  DOMAIN_FULL: /^(https?:\/\/)(?:[A-z0-9](?:[A-z0-9-]{0,61}[A-z0-9])?\.)+[A-z0-9][A-z0-9-]{0,61}[A-z0-9]/,
  SUBDOMAIN: /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$/i,
  EMAIL: /^[\w-\\.\\+]+@([\w-]+\.)+[\w-]{2,}$/,
  DATE: /(^(((0[1-9]|1[0-9]|2[0-8])[.](0[1-9]|1[012]))|((29|30|31)[.](0[13578]|1[02]))|((29|30)[.](0[4,6,9]|11)))[.]\d{4}$)|(^29[.]02[.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
  DIGITS: /^[0-9]+$/,
  FLOATING_NUMBERS: /^\d*(?:[.,]?\d+)?$/,
};

export default REGEXES;
