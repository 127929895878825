// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './DialogHeader.module.scss';

// ASSETS
import { ReactComponent as Close } from 'assets/icons/icn_close_big.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import DropDownSubtle from 'ui/basic/menus/DropDownSubtle';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSettingsLanguages, setSettingsLanguageApi,
} from 'features/framework/storeNext/configurationSlice';
import { Logo } from 'features/framework/components/MainLayout/Logo';
import { useBreakpoint } from 'utils/hooks';


// COMPONENT: DialogHeader
const DialogHeader = (props) => {
  // PROPS

  const {
    title, // shows logo if falsy

    showLanguageToggle,
    showCloseAsX = true,
    preventClose = false,
    smallHeader,
    actionLabel,

    onClose, // shows close button if truthy
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { isXs } = useBreakpoint();

  // STORE: LANGUAGE
  const allLanguages = useSelector(selectSettingsLanguages);
  const currentLanguage = localStorage.getItem('selectedLanguage');

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleLanguageChange = (index) => {
    dispatch(setSettingsLanguageApi({
      language: allLanguages[index],
      reFetchStaticValues: true,
    }));
  };

  // HELPERS

  // RENDERS

  // RENDER: DialogHeader
  return (
    <div className={classNames(styles.dialogHeader, {
      [styles.smallHeader]: smallHeader,
    })}
    >
      <div className={styles.left}>

        { /* TITLE */ }
        { title && (
          <div className={styles.title}>
            { title }
          </div>
        ) }

        { /* LOGO */ }
        { !title && (
          <div className={styles.logo}>
            <Logo
              size={isXs ? 'M' : 'L'}
              compensatePadding
            />
          </div>
        ) }

      </div>
      <div className={styles.right}>

        { /* LANGUAGE TOGGLE */ }
        { showLanguageToggle && (
          <DropDownSubtle
            className='alignRight'
            options={allLanguages.map((language) => ({
              value: language,
              label: translate(`langswitch_menu_${language}`),
            }))}
            activeIndex={allLanguages.indexOf(currentLanguage)}
            onChange={(index) => handleLanguageChange(index)}
          />
        ) }

        { /* CLOSE BUTTON */ }
        { !showLanguageToggle && onClose && !preventClose && (
          <>
            { showCloseAsX && (
              <div
                role='presentation'
                className={styles.closeButton}
                onClick={onClose}
              >
                <Close />
              </div>
            ) }
            { !showCloseAsX && (
              <div
                role='presentation'
                className={styles.close}
                onClick={onClose}
              >
                { actionLabel }
              </div>
            ) }
          </>
        ) }
      </div>
    </div>
  );
};

export default DialogHeader;
