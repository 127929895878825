export const LIST_RECOMMENDATIONS = 'LIST_RECOMMENDATIONS';
export const LIST_RECOMMENDATIONS_FULFILLED = 'LIST_RECOMMENDATIONS_FULFILLED';
export const LIST_RECOMMENDATIONS_REJECTED = 'LIST_RECOMMENDATIONS_REJECTED';
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';
export const GET_RECOMMENDATION_FULFILLED = 'GET_RECOMMENDATION_FULFILLED';
export const GET_RECOMMENDATION_REJECTED = 'GET_RECOMMENDATION_REJECTED';
export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION';
export const UPDATE_RECOMMENDATION_FULFILLED = 'UPDATE_RECOMMENDATION_FULFILLED';
export const UPDATE_RECOMMENDATION_REJECTED = 'UPDATE_RECOMMENDATION_REJECTED';


export const listRecommendations = () => ({
  type: LIST_RECOMMENDATIONS,
  payload: null,
});

export const listRecommendationsFulfilled = (payload) => ({
  type: LIST_RECOMMENDATIONS_FULFILLED,
  payload,
});

export const listRecommendationsRejected = (payload) => ({
  type: LIST_RECOMMENDATIONS_REJECTED,
  payload,
});


export const getRecommendation = (payload) => ({
  type: GET_RECOMMENDATION,
  payload,
});

export const getRecommendationFulfilled = (payload) => ({
  type: GET_RECOMMENDATION_FULFILLED,
  payload,
});

export const getRecommendationRejected = (payload) => ({
  type: GET_RECOMMENDATION_REJECTED,
  payload,
});


export const updateRecommendation = (payload) => ({
  type: UPDATE_RECOMMENDATION,
  payload,
});

export const updateRecommendationFulfilled = (payload) => ({
  type: UPDATE_RECOMMENDATION_FULFILLED,
  payload,
});

export const updateRecommendationRejected = (payload) => ({
  type: UPDATE_RECOMMENDATION_REJECTED,
  payload,
});
