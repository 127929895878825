// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AssessmentsList.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button,
  ResetAssessmentHandle,
  Checkbox,
  Toggle,
} from 'ui/basic';

// UTILS
import { getPriceFromCents } from 'features/+adminUG/config/payment.config';
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_DESCRIPTIONS, ASSESSMENT_TITLES } from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectPlan,
  selectPlanIsNonBilling,
  selectPlanIsTrial,
} from 'features/framework/storeNext/configurationSlice';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: AssessmentsList
const AssessmentsList = (props) => {
  // PROPS
  const {
    userId,
    profile,
    onResetConfirm,
    assessments = [],
    prices = [],
    togglesDisabled = false,
    premiumAssessmentsCanBePurchased = false,
    showGrantManagementAccess = false,
    assessmentsIdsForManaging = [],
    onAssessmentChange = () => {},
    onAssessmentBuy = () => {},
    showAssessmentResetHandle = false,
    onAssessmentAccessScopeChange = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const plan = useSelector(selectPlan);
  const planIsTrial = useSelector(selectPlanIsTrial);
  const planIsNonBilling = useSelector(selectPlanIsNonBilling);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: AssessmentsList
  return (
    <div className={classNames(styles.assessmentsList)}>
      { assessments.map((assessment) => {
        const assessmentFromProfile = profile?.assessmentResults
        ?.find(({ assessment: aId }) => aId === assessment.id);
        return (
          <div
            className={styles.row}
            key={assessment.id}
          >
            <div className={styles.row1}>
              <span className='bluTypeLabelL'>
                { translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title }
              </span>
              { (assessment.active || assessment.purchasedAt || planIsTrial || planIsNonBilling) && (
                <Toggle
                  id={`${assessment.id}_employeeProfile`}
                  checked={assessment.active && !planIsTrial}
                  disabled={togglesDisabled || planIsTrial}
                  onChange={(value) => onAssessmentChange(assessment, value)}
                />
              ) }
            </div>

            <div className={styles.copy}>
              { translate(ASSESSMENT_DESCRIPTIONS[assessment.id]) || translate(assessment.description) }
            </div>

            { showAssessmentResetHandle && (
              <div className={styles.rowReset}>
                <ResetAssessmentHandle
                  userId={userId}
                  assessmentId={assessment.id}
                  assessmentProtected={Boolean(assessment.self_only)}
                  assessmentProgress={assessmentFromProfile?.progress}
                  onConfirm={onResetConfirm}
                />
              </div>
            ) }
            { showGrantManagementAccess && (
              <div className='marginTopXs'>
                <Checkbox
                  name={translate('grant_management_access_lbl')}
                  checked={assessmentsIdsForManaging.includes(assessment.id)}
                  disabled={togglesDisabled}
                  onChange={(value) => {
                    onAssessmentAccessScopeChange(value, assessment.id);
                  }}
                />
              </div>
            ) }

            { (plan.required && !assessment.active && !assessment.purchasedAt && plan.type !== 'trial') && (
              <>
                <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                  { translate('chargeable_assessments_price', [
                    '{{price}}',
                    getPriceFromCents(
                      prices[assessment.id]?.pricePerUnit,
                      prices[assessment.id]?.currency,
                    ),
                  ]) }
                </div>

                <Button
                  size='S'
                  disabled={!premiumAssessmentsCanBePurchased}
                  onClick={() => {
                    onAssessmentBuy(assessment.id);
                  }}
                >
                  { translate('buy_lbl') }
                </Button>
              </>
            ) }
          </div>
        );
      }) }
    </div>
  );
};

export default AssessmentsList;
