export const GET_ASSESSMENT_REPORT = 'GET_ASSESSMENT_REPORT';
export const GET_ASSESSMENT_REPORT_FULFILLED = 'GET_ASSESSMENT_REPORT_FULFILLED';
export const GET_ASSESSMENT_REPORT_REJECTED = 'GET_ASSESSMENT_REPORT_REJECTED';
export const GET_ASSESSMENT_REPORT_PDF = 'GET_ASSESSMENT_REPORT_PDF';
export const GET_ASSESSMENT_REPORT_PDF_FULFILLED = 'GET_ASSESSMENT_REPORT_PDF_FULFILLED';
export const GET_ASSESSMENT_REPORT_PDF_REJECTED = 'GET_ASSESSMENT_REPORT_PDF_REJECTED';


export const getAssessmentReport = (payload) => ({
  type: GET_ASSESSMENT_REPORT,
  payload,
});

export const getAssessmentReportFulfilled = (payload) => ({
  type: GET_ASSESSMENT_REPORT_FULFILLED,
  payload,
});

export const getAssessmentReportRejected = (payload) => ({
  type: GET_ASSESSMENT_REPORT_REJECTED,
  payload,
});

export const getAssessmentReportPdf = (payload) => ({
  type: GET_ASSESSMENT_REPORT_PDF,
  payload,
});

export const getAssessmentReportPdfFulfilled = (payload) => ({
  type: GET_ASSESSMENT_REPORT_PDF_FULFILLED,
  payload,
});

export const getAssessmentReportPdfRejected = (payload) => ({
  type: GET_ASSESSMENT_REPORT_PDF_REJECTED,
  payload,
});
