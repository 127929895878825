export const CAPABILITIES = {
  TEAM_LIST: 'CORE|TEAM|LIST',
  TEAM_GET_MEMBERS: 'CORE|TEAM|GET_MEMBERS',
  TEAM_CREATE: 'CORE|TEAM|CREATE',
  TEAM_GET: 'CORE|TEAM|GET',
  TEAM_UPDATE: 'CORE|TEAM|UPDATE',
  TEAM_SET_ACCESS: 'CORE|TEAM|SET_ACCESS',
  TEAM_GET_ACCESS: 'CORE|TEAM|GET_ACCESS',
  TEAM_REMOVE_MEMBER: 'CORE|TEAM|REMOVE_MEMBER',

  COMPANY_GET_MEMBERS: 'CORE|COMPANY|GET_MEMBERS',

  PROFILE_GET: 'CORE|PROFILE|GET',
  PROFILE_CREATE: 'CORE|PROFILE|CREATE',
  PROFILE_UPDATE: 'CORE|PROFILE|UPDATE',

  USER_PROFILE_GET: 'CORE|USER|GET_PROFILE',

  USER_SHOW: 'CORE|USER|SHOW',
  USER_INVITE: 'CORE|USER|INVITE',

  CANDIDATE_INVITE: 'RECRUITING|CANDIDATE|INVITE',
  CANDIDATES_LIST: 'RECRUITING|CANDIDATE|LIST',

  JOBS_LIST: 'RECRUITING|JOB|LIST',
  JOB_GET: 'RECRUITING|JOB|GET',
  JOB_CREATE: 'RECRUITING|JOB|CREATE',

  COMPANY_SET_ACCESS: 'CORE|COMPANY|SET_ACCESS',

  ACCESS_LIST_ROLE: 'CORE|ACCESS|LIST_ROLE',
  ACCESS_GET_ROLE: 'CORE|ACCESS|GET_ROLE',
  ACCESS_CREATE_ROLE: 'CORE|ACCESS|CREATE_ROLE',
  ACCESS_EDIT_ROLE: 'CORE|ACCESS|EDIT_ROLE',
  ACCESS_DELETE_ROLE: 'CORE|ACCESS|DELETE_ROLE',

  ROLEMAPPING_GET_ROLE: 'ROLEMAPPING|ROLE|GET',
  ROLEMAPPING_CREATE_ROLE: 'ROLEMAPPING|ROLE|CREATE',
  ROLEMAPPING_EDIT_ROLE: 'ROLEMAPPING|ROLE|EDIT',
  ROLEMAPPING_DELETE_ROLE: 'ROLEMAPPING|ROLE|DELETE',

  ROLEMAPPING_ROLE_MATCH: 'ROLEMAPPING|ROLE|MATCH',
  ROLEMAPPING_ROLE_MATCH_USER: 'ROLEMAPPING|ROLE|MATCH_USER',
  ROLEMAPPING_USER_MATCH: 'ROLEMAPPING|USER|MATCH',
  ROLEMAPPING_ROLE_SET_USER: 'ROLEMAPPING|ROLE|SET_USER',

  TEAMMAPPING_POSITION_FILL: 'TEAMMAPPING|POSITION|FILL',

  ASSESSMENT_GET: 'CORE|ASSESSMENT|GET',
  ASSESSMENT_ANSWER: 'CORE|ASSESSMENT|ANSWER',
  ASSESSMENT_GET_RESULTS: 'CORE|ASSESSMENT|GET_RESULTS',
};

export const VIEW_ROLES_CAPABILITIES = [
  CAPABILITIES.ROLEMAPPING_GET_ROLE,
];

export const CREATE_ROLE_CAPABILITIES = [
  CAPABILITIES.PROFILE_CREATE,
  CAPABILITIES.PROFILE_UPDATE,
  CAPABILITIES.ROLEMAPPING_CREATE_ROLE,
  CAPABILITIES.ROLEMAPPING_EDIT_ROLE,
  CAPABILITIES.ROLEMAPPING_DELETE_ROLE,
];

export const INVITE_USER_CAPABILITIES = [
  CAPABILITIES.USER_INVITE,
  CAPABILITIES.COMPANY_SET_ACCESS,
];

export const INVITE_CANDIDATE_CAPABILITIES = [
  CAPABILITIES.CANDIDATE_INVITE,
];

export const VIEW_CANDIDATES_CAPABILITIES = [
  CAPABILITIES.CANDIDATES_LIST,
];

export const VIEW_VACANCIES_CAPABILITIES = [
  CAPABILITIES.JOBS_LIST,
];

export const CREATE_VACANCY_CAPABILITIES = [
  CAPABILITIES.JOB_CREATE,
];

export const VIEW_USERS_CAPABILITIES = [
  CAPABILITIES.COMPANY_GET_MEMBERS,
  CAPABILITIES.USER_PROFILE_GET,
];

export const MATCH_ROLES_CAPABILITIES = [
  CAPABILITIES.ROLEMAPPING_ROLE_MATCH,
  CAPABILITIES.ROLEMAPPING_ROLE_MATCH_USER,
  CAPABILITIES.ROLEMAPPING_USER_MATCH,
];

export const VIEW_TEAMS_CAPABILITIES = [
  CAPABILITIES.TEAM_LIST,
  CAPABILITIES.TEAM_GET,
  CAPABILITIES.TEAM_UPDATE,
  CAPABILITIES.TEAM_REMOVE_MEMBER,
];

export const CREATE_TEAM_CAPABILITIES = [
  CAPABILITIES.TEAM_CREATE,
];

export const STAFF_TEAM_CAPABILITIES = [
  CAPABILITIES.TEAMMAPPING_POSITION_FILL,
  CAPABILITIES.ROLEMAPPING_ROLE_SET_USER,
];

export const CAPABILITIES_SWITCHES = [
  'employeesView',
  'employeesInvite',
  'candidatesView',
  'candidatesInvite',
  'vacanciesView',
  'vacanciesCreate',
  'rolesView',
  'rolesCreate',
  'rolesMatch',
  'teamsView',
  'teamsCreate',
  'teamsStaff',
  'premiumAssessmentBooking',
  'surveysView',
  'surveysCreate',
];
