import React from 'react';
import styles from './AssessmentsResultsSkeleton.module.scss';

const AssessmentsResultsSkeleton = () => (
  <div className={styles.assessmentsResultsSkeleton}>
    { /* TOP BLOCK */ }
    <div className={styles.bigHeader} />
    <div className={styles.bigBlock} />
    <div className={styles.smallBlock} />

    { new Array(3).fill(1).map((item, index) => (
      <div key={index}>
        <div className={styles.smallHeader} />
        <div className={styles.smallBlock} />
        <div className={styles.bigBlock} />
        <div className={styles.smallBlock} />
        <div className={styles.bigBlock} />
      </div>
    )) }
  </div>
);

export default AssessmentsResultsSkeleton;
