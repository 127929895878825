import * as actions from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/assessments';
import { RECOMMENDATION_STATE } from 'features/framework/pages/MyProfileRecommendations/Recommendations.config';
import { preprocessAssessment, serialiseAssessmentReportPdfParams } from 'utils/assessment';


const initialState = {
  saveAnswersSuccess: false,
  saveAnswersProcessing: false,
  saveAnswerProcessing: false,

  table: {}, // assessments are stored here, mapped by id
  loading: false,
  downloading: {},
  error: null,
  recommendations: null,
};

const patchTable = (state, id, data) => ({
  ...state.table,
  [id]: {
    ...state.table[id],
    ...data,
  },
});


export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.GET_ALL_ASSESSMENTS:
    case actions.LIST_ASSESSMENTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ALL_ASSESSMENTS_FULFILLED:
    case actions.GET_ALL_ASSESSMENTS_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_ASSESSMENT_FULFILLED: {
      const { id, data = {} } = action.payload;

      return {
        ...state,
        table: {
          ...state.table,
          [id]: preprocessAssessment({ ...state.table[id], ...data }),
          error: null,
        },
        loading: false,
        error: null,
      };
    }

    case actions.GET_ASSESSMENT_RESULT_FULFILLED: {
      const { id, data } = action.payload;

      const newData = {
        ...data,
        resultsAvailable: true,
        resultsRequestProcessed: true,
        resultsRequestError: null,
        resultsPending: false,
      };

      return {
        ...state,
        table: patchTable(state, id, newData),
      };
    }

    case actions.GET_ASSESSMENT_REPORT_REJECTED:
    case actions.GET_ASSESSMENT_RESULT_REJECTED: {
      const { id, error } = action.payload;

      const newData = {
        resultsRequestProcessed: true,
        resultsRequestError: error.errorMessage,
        resultsPending: error.errorCode === 1406,
      };

      return {
        ...state,
        table: patchTable(state, id, newData),
      };
    }

    case actions.GET_ASSESSMENT_REPORT_FULFILLED: {
      const { id, reportStructure } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, { reportStructure }),
      };
    }

    case actions.GET_ASSESSMENT_REPORT_NOTES_FULFILLED: {
      const { id, reportNotes } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, { reportNotes }),
      };
    }

    case actions.GET_EXTRA_CARDS_FULFILLED: {
      const { id, cards } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, { extraCards: cards }),
      };
    }
    case actions.LIST_ASSESSMENTS_FULFILLED: {
      const { assessments = [] } = action.payload;

      const newAssessments = assessments
      .map((el) => preprocessAssessment({ ...state.table[el.id], ...el }))
      .reduce((acc, el) => {
        acc[el.id] = el;
        return acc;
      }, {});

      return {
        ...state,
        table: newAssessments,
        loading: false,
        error: null,
      };
    }

    case actions.GET_ASSESSMENT_REPORT_PDF:
    case actions.GET_ASSESSMENT_REPORT_PDF_FULFILLED:
    case actions.GET_ASSESSMENT_REPORT_PDF_REJECTED: {
      const downloadingKey = serialiseAssessmentReportPdfParams(action.payload);
      return {
        ...state,
        downloading: {
          ...state.downloading,
          [downloadingKey]: action.type === actions.GET_ASSESSMENT_REPORT_PDF,
        },
      };
    }

    case actions.INIT_GET_ASSESSMENT_QUESTIONS: {
      const { id } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, { questions: undefined }),
      };
    }
    case actions.GET_ASSESSMENT_QUESTIONS_FULFILLED: {
      const { id, data } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, data),
        error: null,
      };
    }
    case actions.GET_ASSESSMENT_ANSWERS_FULFILLED: {
      const { id, data } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, data),
      };
    }
    case actions.GET_LOCKED_FOR_VALUE_FULFILLED: {
      const { id, lockedFor } = action.payload;
      return {
        ...state,
        table: patchTable(state, id, { lockedFor }),
      };
    }
    case actions.GET_ASSESSMENT_QUESTIONS_REJECTED:
    case actions.GET_ASSESSMENT_REJECTED: {
      const { id, error } = action.payload;

      return {
        ...state,
        table: patchTable(state, id, { error }),
        loading: false,
      };
    }
    case actions.LIST_ASSESSMENTS_REJECTED: {
      const { id, error } = action.payload;

      if (!id) {
        return {
          ...state,
          error,
          loading: false,
        };
      }

      return {
        ...state,
        table: patchTable(state, id, { error }),
        loading: false,
      };
    }
    case actions.INIT_SAVE_ASSESSMENT_ANSWERS:
      return {
        ...state,
        saveAnswersProcessing: false,
        saveAnswersSuccess: false,
      };
    case actions.SAVE_ASSESSMENT_ANSWERS:
      return {
        ...state,
        saveAnswersProcessing: true,
        saveAnswersSuccess: false,
      };
    case actions.SAVE_ASSESSMENT_ANSWERS_FULFILLED:
      return {
        ...state,
        saveAnswersProcessing: false,
        saveAnswersSuccess: true,
        error: null,
      };
    case actions.SAVE_ASSESSMENT_ANSWERS_REJECTED:
      return {
        ...state,
        saveAnswersProcessing: false,
        saveAnswersSuccess: false,
        error: action.payload.error,
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER:
      return {
        ...state,
        saveAnswerProcessing: true,
        error: null,
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER_FULFILLED:
      return {
        ...state,
        saveAnswerProcessing: false,
        error: null,
      };
    case fromActionTypes.SAVE_ASSESSMENT_ANSWER_REJECTED:
      return {
        ...state,
        saveAnswerProcessing: false,
        error: action.payload.error.error,
      };
    case actions.LIST_RECOMMENDATIONS_FULFILLED:
      return {
        ...state,
        recommendations: action.payload,
      };
    case actions.GET_ASSESSMENT_HISTORY_FULFILLED: {
      const { id, history, offset } = action.payload;

      const thisAssessment = state.table[id];
      const newResults = history.results;
      const oldResults = thisAssessment.history?.results ?? [];
      const results = newResults.concat(oldResults.slice(0, offset)).sort((itemA, itemB) => itemB.date - itemA.date);

      const newData = {
        history: { ...history, results },
      };
      // Update assessment results too
      if (results.length) {
        newData.results = results[0].results;
        newData.date = results[0].date;
      }

      return {
        ...state,
        table: patchTable(state, id, newData),
      };
    }
    case actions.GET_RECOMMENDATION_FULFILLED: {
      const { id } = action.payload;
      const recommendations = JSON.parse(JSON.stringify(state.recommendations));
      const index = recommendations.findIndex((el) => el.id === id);
      recommendations[index] = { ...recommendations[index], ...action.payload };
      return {
        ...state,
        recommendations,
      };
    }
    case actions.UPDATE_RECOMMENDATION_FULFILLED: {
      const { id, state: recommendationState } = action.payload;
      let recommendations = JSON.parse(JSON.stringify(state.recommendations));
      const index = recommendations.findIndex((el) => el.id === id);
      recommendations[index].state = recommendationState;
      recommendations = recommendations.filter((el) => el.state !== RECOMMENDATION_STATE.INACTIVE);
      return {
        ...state,
        recommendations,
      };
    }

    default:
      return state;
  }
};
