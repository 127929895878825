// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './NextSteps.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { RecommendationCarousel } from 'ui/molecules';


// COMPONENT: NextSteps
const NextSteps = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: NextSteps
  return (
    <div className={styles.nextSteps}>
      <h2 className={classNames('bluTypeS')}>
        { translate('peer_360_report_next_steps') }
      </h2>
      <div className='marginTopM'>
        { translate('ist_report_next_steps_copy') }
      </div>
      <div className='marginTopL'>
        <RecommendationCarousel assessmentId={ASSESSMENT_TYPES.IST} />
      </div>
    </div>
  );
};

export default NextSteps;
