import * as fromActionTypes from 'store/actionTypes/survey';

// SUBMIT CONTENT FEEDBACK
export const initSubmitContentFeedback = () => ({
  type: fromActionTypes.INIT_SUBMIT_CONTENT_FEEDBACK,
  payload: {},
});

export const submitContentFeedback = (feedbackDto) => ({
  type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK,
  payload: { feedbackDto },
});

export const submitContentFeedbackFulfilled = () => ({
  type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK_FULFILLED,
  payload: {},
});

export const submitContentFeedbackRejected = ({ error }) => ({
  type: fromActionTypes.SUBMIT_CONTENT_FEEDBACK_REJECTED,
  payload: { error },
});

// GET CONTENT FEEDBACK
export const getContentFeedback = (requestDto) => ({
  type: fromActionTypes.GET_CONTENT_FEEDBACK,
  payload: { requestDto },
});

export const getContentFeedbackFulfilled = ({ contentFeedback, totalCount }) => ({
  type: fromActionTypes.GET_CONTENT_FEEDBACK_FULFILLED,
  payload: { contentFeedback, totalCount },
});

export const getContentFeedbackRejected = ({ error }) => ({
  type: fromActionTypes.GET_CONTENT_FEEDBACK_REJECTED,
  payload: { error },
});
