// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ButtonCircular.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';


// OTHER COMPONENTS
import { IconsSvg } from 'assets/icons';

// UTILS

// CONFIG & DATA

// COMPONENT: ButtonCircular
const ButtonCircular = (props) => {
  // PROPS
  const {
    icon,
    disabled,
    active,
    size = 'M',
    looks,
    onClick,
  } = props;
  const Icon = IconsSvg[icon];

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: ButtonCircular
  return (
    <div
      className={classnames(
        styles.buttonCircular,
        styles[looks],
        styles[`size${size.toUpperCase()}`],
        { [styles.disabled]: disabled, [styles.active]: active, [styles[icon]]: icon },
      )}
      onClick={(event) => {
        !disabled && onClick && onClick(event);
      }}
    >
      { Icon && <Icon /> }
    </div>
  );
};

export default ButtonCircular;
