// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PeerIconsLine.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENT: PeerIconsLine
const PeerIconsLine = ({
  total = 0,
  min = 0,
  max = Infinity,
}) => {
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const peerStyles = [];

  // Define the style (color) of each peer icon to display
  const getIcons = (quantity, style) => {
    if (quantity > 0 && quantity < Infinity) {
      peerStyles.push(...new Array(quantity).fill(style));
    }
  };

  let realMax = max ?? Infinity;
  if (min > realMax) {
    realMax = min;
  }
  getIcons(Math.min(total, realMax), styles.invited);
  getIcons(min - peerStyles.length, styles.missing);
  getIcons(realMax - peerStyles.length, styles.optional);
  getIcons(total - realMax, styles.error);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: PeerIconsLine
  return (
    <div className={classNames(styles.peerIconsLine)}>
      { peerStyles.map((style, index) => (
        <IconsSvg.People
          key={index} // eslint-disable-line react/no-array-index-key
          className={style}
        />
      )) }
      { realMax === Infinity && (
        <IconsSvg.Plus
          className={classNames([ styles.plus, styles.missing ])}
        />
      ) }
    </div>
  );
};

export default PeerIconsLine;
