import * as actiontype from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/settings';

const initialState = {
  processing: false,
  registrationDomains: [],
  allFeatures: [],

  userSettings: {},
  userSettingsLoaded: false,
  userSettingsError: null,
  updateUserSettingsProcessing: false,
  updateUserSettingsSuccess: false,
  languageChanged: false,

  companySettings: {},
  companySettingsError: null,
  updateCompanySettingsProcessing: false,

  updateCompanyFeaturesProcessing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actiontype.GET_SETTINGS:
      return {
        ...state,
        processing: true,
      };
    case actiontype.GET_SETTINGS_FULFILLED:
      return {
        ...state,
        processing: false,
        ...action.payload,
      };
    case actiontype.GET_SETTINGS_REJECTED:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case fromActionTypes.GET_REGISTRATION_DOMAINS:
      return {
        ...state,
        processing: true,
      };
    case fromActionTypes.GET_REGISTRATION_DOMAINS_FULFILLED:
      const { domains } = action.payload;
      return {
        ...state,
        processing: false,
        registrationDomains: domains,
      };
    case fromActionTypes.GET_REGISTRATION_DOMAINS_REJECTED:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case fromActionTypes.GET_ALL_FEATURES:
      return {
        ...state,
        processing: true,
      };
    case fromActionTypes.GET_ALL_FEATURES_FULFILLED:
      const { features } = action.payload;
      return {
        ...state,
        allFeatures: features,
        processing: false,
      };
    case fromActionTypes.GET_ALL_FEATURES_REJECTED:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case fromActionTypes.GET_USER_SETTINGS:
      return {
        ...state,
        processing: true,
      };
    case fromActionTypes.GET_USER_SETTINGS_FULFILLED:
      const { userSettings } = action.payload;
      return {
        ...state,
        userSettings,
        userSettingsLoaded: true,
        processing: false,
        userSettingsError: null,
      };
    case fromActionTypes.GET_USER_SETTINGS_REJECTED:
      return {
        ...state,
        processing: false,
        userSettingsError: action.payload.error,
      };
    case fromActionTypes.INIT_UPDATE_USER_SETTINGS:
      return {
        ...state,
        userSettingsError: null,
        updateUserSettingsProcessing: false,
        updateUserSettingsSuccess: false,
        languageChanged: false,
      };
    case fromActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        updateUserSettingsProcessing: true,
        updateUserSettingsSuccess: false,
      };
    case fromActionTypes.UPDATE_USER_SETTINGS_FULFILLED:
      const { languageChanged } = action.payload;

      return {
        ...state,
        updateUserSettingsProcessing: false,
        userSettingsError: null,
        updateUserSettingsSuccess: true,
        languageChanged,
      };
    case fromActionTypes.UPDATE_USER_SETTINGS_REJECTED:
      return {
        ...state,
        updateUserSettingsProcessing: false,
        userSettingsError: action.payload.error,
        updateUserSettingsSuccess: false,
      };
    case fromActionTypes.GET_COMPANY_SETTINGS:
      return {
        ...state,
      };
    case fromActionTypes.GET_COMPANY_SETTINGS_FULFILLED:
      const { companySettings } = action.payload;

      return {
        ...state,
        companySettings,
        companySettingsError: null,
      };
    case fromActionTypes.GET_COMPANY_SETTINGS_REJECTED:
      return {
        ...state,
        companySettingsError: action.payload.error,
      };
    case fromActionTypes.UPDATE_COMPANY_SETTINGS:
      return {
        ...state,
        updateCompanySettingsProcessing: true,
      };
    case fromActionTypes.UPDATE_COMPANY_SETTINGS_FULFILLED:
      return {
        ...state,
        companySettingsError: null,
        updateCompanySettingsProcessing: false,
      };
    case fromActionTypes.UPDATE_COMPANY_SETTINGS_REJECTED:
      return {
        ...state,
        companySettingsError: action.payload.error,
        updateCompanySettingsProcessing: false,
      };
    case fromActionTypes.UPDATE_SETTINGS:
      return {
        ...state,
        updateCompanyFeaturesProcessing: true,
      };
    case fromActionTypes.UPDATE_SETTINGS_FULFILLED:
      return {
        ...state,
        updateCompanyFeaturesProcessing: false,
      };
    case fromActionTypes.UPDATE_SETTINGS_REJECTED:
      return {
        ...state,
        updateCompanyFeaturesProcessing: false,
      };
    default:
      return state;
  }
};
