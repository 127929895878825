import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Callout.module.scss';


const Callout = (props, ref) => {
  const {
    trianglePosition = 'center',
    triangleOnTop = true,
    triangleOnBottom = false,
    shortMargin = false,
    children,
  } = props;

  return (
    <div className={styles.callout} ref={ref}>
      { triangleOnTop && (
        <div className={classNames(
          styles.triangle,
          styles.triangleOnTop,
          styles[trianglePosition],
          shortMargin && styles.short,
        )}
        />
      ) }

      <div className={styles.text} data-test='CalloutContent'>
        { children }
      </div>

      { triangleOnBottom && (
        <div className={classNames(
          styles.triangle,
          styles.triangleOnBottom,
          styles[trianglePosition],
          shortMargin && styles.short,
        )}
        />
      ) }
    </div>
  );
};

export default forwardRef(Callout);
