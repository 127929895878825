// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CliftonResult.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card } from 'ui/basic';
import SkillsResultSkeleton from 'ui/molecules/SkillsResult/SkillsResultSkeleton';

// UTILS
import { isValid } from 'utils/numbers';
import { translate } from 'utils/translator/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: CliftonResult
const CliftonResult = (props) => {
  // PROPS
  const {
    strengths = [],
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // STRENGTHS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ strengthsInternal, setStrengthsInternal ] = useState([]);
  useEffect(() => {
    if (!strengths || strengths.length === 0) {
      return;
    }

    const mappedStrengths = strengths
    .filter((s) => s.parentDimension && s.result && isValid(s.result.globalRank))
    .sort((itemA, itemB) => (itemA.result.globalRank > itemB.result.globalRank ? 1 : (itemA.result.globalRank < itemB.result.globalRank ? -1 : 0)))
    .slice(0, 10) // top 10 strengths should be shown
    .map((s) => {
      const splitArray = s.parentDimension.split('_');
      let d;
      if (splitArray && splitArray.length >= 3) {
        d = splitArray[2];
      }

      return {
        label: translate(s.id),
        dimension: d || s.parentDimension,
        rank: s.result?.globalRank,
      };
    });

    setStrengthsInternal(mappedStrengths);
  }, [ strengths ]);

  // LEGEND: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  if (!strengthsInternal || strengthsInternal.length === 0) {
    return (
      <div className={styles.cliftonResult}>
        <SkillsResultSkeleton />
      </div>
    );
  }

  // RENDER: CliftonResult
  return (
    <div className={classNames(styles.cliftonResult)}>
      <Card hasBreakout='S'>
        <div className={styles.title}>
          { translate('clifton_ass_title') }
        </div>
        <div className={styles.strengths}>
          { strengthsInternal.map(({ label, dimension, rank }, index) => (
            <div key={index} className={classNames(styles.strength, styles[dimension])}>
              <span className={styles.index}>
                { ' ' }
                { rank }
                { ' ' }
              </span>
              <span className={styles.label}>
                { ' ' }
                { label }
                { ' ' }
              </span>
            </div>
          )) }
        </div>
        <div className={styles.legend}>
          <div className={classNames(styles.item, styles.executing)}>
            { translate('clifton_dim_executing') }
          </div>
          <div className={classNames(styles.item, styles.influencing)}>
            { translate('clifton_dim_influencing') }
          </div>
          <div className={classNames(styles.item, styles.relationship)}>
            { translate('clifton_dim_relationship_building') }
          </div>
          <div className={classNames(styles.item, styles.strategic)}>
            { translate('clifton_dim_strategic_thinking') }
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CliftonResult;
