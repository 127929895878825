export const throttle = (callback, delay = 1000) => {
  let lastCall = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastCall >= delay) {
      callback(...args);
      lastCall = now;
    }
  };
};

export const debounce = (callback, delay = 1000) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const sleep = (time = 1000) => new Promise((resolve) => {
  setTimeout(resolve, time);
});
