import { post } from 'api';
import * as action from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/resetPassword';

import { call, put, take } from 'redux-saga/effects';

import { IS_ADMIN_FRONTEND } from 'utils/configuration';


function* requestResetPassword({ mail, companyId }) {
  try {
    // HEADER ITEM FOR ADMIN MODULE
    const headerItems = {};
    if (IS_ADMIN_FRONTEND && companyId) {
      headerItems['x-admin-company'] = companyId;
    }

    const payload = { mail };
    if (!IS_ADMIN_FRONTEND && companyId) {
      Object.assign(payload, { companyId });
    }

    const { ok, data } = yield call(
      post,
      '/core/user/passwordreset/request',
      payload,
      headerItems,
    );

    if (ok) {
      yield put(action.resetPasswordRequestFulFilled());
    } else {
      yield put(action.resetPasswordRequestRejected(data));
    }
  } catch (error) {
    yield put(action.resetPasswordRequestRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

function* resetPassword({ password, token }) {
  try {
    yield put(action.loading());
    const { status, data } = yield call(
      post,
      '/core/user/passwordreset/set',
      {
        password, token,
      },
    );

    if (status === 200) {
      yield put(action.resetPasswordFulFilled());
    } else {
      yield put(action.resetPasswordRejected(data));
    }
  } catch (error) {
    yield put(action.resetPasswordRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

export function* watchRequestResetPasswordRequest() {
  while (true) {
    try {
      const { payload } = yield take(fromActionTypes.RESET_PASSWORD_REQUEST);
      yield call(requestResetPassword, payload);
    } catch (error) {
      yield put(action.resetPasswordRequestRejected(error));
    }
  }
}

export function* watchResetPasswordRequest() {
  while (true) {
    try {
      const { payload } = yield take(fromActionTypes.RESET_PASSWORD);
      yield call(resetPassword, payload);
    } catch (error) {
      yield put(action.resetPasswordRejected(error));
    }
  }
}
