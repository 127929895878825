export const LOADING = 'LOADING';
export const LOADING_FULFILLED = 'LOADING_FULFILLED';

export const loading = () => ({
  type: LOADING,
  payload: true,
});

export const loadingFulFilled = () => ({
  type: LOADING_FULFILLED,
  payload: false,
});
