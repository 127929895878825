// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './InstanceCreation.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { ProgressBarMock } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator/translator';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};

// COMPONENT: InstanceCreation
const InstanceCreation = (props) => {
  // PROPS
  const {
    extras = {},
    onNext = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ requestSent, setRequestSent ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  useEffect(() => {
    if (requestSent) {
      return;
    }

    setRequestSent(true);
    const { answers } = extras;

    setTimeout(() => {
      api.post('/core/subscription/register/confirm', {
        companyId: answers[2]?.domain,
        confirmationCode: answers[4].code,
        adminPassword: answers[5].password,
      })
      .then(({ ok, status, data }) => {
        if (ok && status === 200) {
          onNext({ isValid: true });
        } else {
          setErrorMessage(data?.error?.errorMessage);
        }
      })
      .catch((error) => {
        console.error(error.message);
        setErrorMessage(error.message);
      });
    }, 5000);
  }, [ extras.answers, requestSent, onNext ]);

  // RENDER: InstanceCreation
  return (
    <div className={classNames(styles.instanceCreation)}>
      <div className={styles.title}>
        { translate('trial_signup_instance_creation_title') }
      </div>

      <div className={styles.content}>
        { errorMessage && <div className={styles.errorMessage}>{ errorMessage }</div> }

        <div className={styles.description}>
          { translate('trial_signup_instance_creation_copy') }
        </div>

        <ProgressBarMock />
      </div>
    </div>
  );
};

export default InstanceCreation;
