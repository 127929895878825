// redux-sage
import { call, put, takeLatest } from 'redux-saga/effects';

// candidates
import * as fromActions from './candidate.actions';

// api
import * as api from 'api';

import { getMappedCandidate } from 'utils/users';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { getMappedNineLevelsResults } from 'utils/assessment';

function* getCandidate(action) {
  const candidateId = action.payload.id;

  const { status, ok, data } = yield call(api.get, `recruiting/candidates/${candidateId}`, { expand: 'jobs' });

  if (ok && status === 200) {
    yield put(fromActions.getCandidateSuccess({
      data: getMappedCandidate(data),
    }));
  } else {
    yield put(fromActions.getCandidateFail({ error: data.error }));
  }
}

export function* watchGetCandidate() {
  yield takeLatest(fromActions.GET_CANDIDATE, getCandidate);
}


function* getCandidateAssessments(action) {
  const candidateId = action.payload.id;

  const { status, ok, data } = yield call(api.get, 'core/user/profile', { id: candidateId });

  if (ok && status === 200) {
    // change structure of 9levels assessment result to have negative and positive values for each level;
    const nineLevelsAssessment = data.profile.find((p) => p.assessment === ASSESSMENT_TYPES.NINE_LEVELS);
    if (nineLevelsAssessment?.result) {
      const [ mappedResults, signatureValues ] = getMappedNineLevelsResults(nineLevelsAssessment.result.results);
      nineLevelsAssessment.result.results = mappedResults;
      nineLevelsAssessment.result.signatureValues = signatureValues;
    }

    const unorderedCoreAssessments = [];
    const orderedCoreAssessments = [];
    const customAssessments = [];

    data.profile.forEach((p) => {
      if (Object.values(ASSESSMENT_TYPES).includes(p.assessment)) {
        unorderedCoreAssessments.push(p);
      } else {
        customAssessments.push(p);
      }
    });

    // order core assessments
    Object.values(ASSESSMENT_TYPES).forEach((assessmentId) => {
      const thisAssessment = unorderedCoreAssessments.find((a) => a.assessment === assessmentId);
      if (thisAssessment) {
        orderedCoreAssessments.push(thisAssessment);
      }
    });

    yield put(fromActions.getCandidateAssessmentsSuccess({
      data: [ ...orderedCoreAssessments, ...customAssessments ],
    }));
  } else {
    yield put(fromActions.getCandidateAssessmentsFail({ error: data.error }));
  }
}

export function* watchGetCandidateAssessments() {
  yield takeLatest(fromActions.GET_CANDIDATE_ASSESSMENTS, getCandidateAssessments);
}
