// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
// import styles from './QuestionDemo.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext, VideoInstructional } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectUserFirstName,
  selectUserLastName,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
};


// COMPONENT: QuestionDemo
const QuestionDemo = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
    controls,
    extras = {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();


  // COMPONENT/UI STATE and REFS
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ nameWasEdited, setNameWasEdited ] = useState(false);

  // ANSWER & VALIDATION
  // handlers
  const handleFirstName = (firstName) => {
    setNameWasEdited(true);
    setFirstName(firstName);
    checkAnswers({ firstName, lastName });
  };
  const handleLastName = (lastName) => {
    setNameWasEdited(true);
    setLastName(lastName);
    checkAnswers({ firstName, lastName });
  };
  const checkAnswers = useCallback(({ firstName, lastName }) => {
    const isValid = firstName && lastName
      && firstName.length >= Config.minLengthFirstName
      && lastName.length >= Config.minLengthLastName;

    const answer = { firstName, lastName, isValid };

    onAnswer(answer);
  }, [ onAnswer ]);

  // ACCESS ANSWER & STORE NEXT
  const firstNameStore = useSelector(selectUserFirstName);
  const lastNameStore = useSelector(selectUserLastName);
  const [ nameSetFromStore, setNameSetFromStore ] = useState();
  useEffect(() => {
    if (nameWasEdited) return;
    const firstName = answer.firstName || firstNameStore;
    const lastName = answer.lastName || lastNameStore;
    setFirstName(firstName);
    setLastName(lastName);
    if (!nameSetFromStore && (firstName || lastName)) {
      setNameSetFromStore(true);
      checkAnswers({ firstName, lastName });
    }
  }, [ nameWasEdited, answer, firstNameStore, lastNameStore, nameSetFromStore, checkAnswers ]);


  // API REQUESTS & SET LOADING of DialogSequence during requests
  useEffect(() => {
    const interval = setInterval(() => {
      extras.setLoading(true);
      api.get('/core/user/heartbeat')
      .then((response) => {
        extras.setLoading(false);
        // console.log('hearbeat response', response);
      });
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [ extras ]);

  // RENDER: QuestionDemo
  return (
    <div className={classNames('bluBasicGridNoMargins')}>

      { /* CONTENT */ }
      <div className='bluContent664 bluTypeS bluTypeCenter'>
        Demo Question text: which can be quite long and will break line if too long!
      </div>
      <div className='bluContent bluTypeCopyStrong marginTopS'>
        <strong>Content/Content444:</strong>
        { ' ' }
        Über zahlreiche Assessments erhälst du
        umfassende Erkenntnisse über die individuellen Fähigkeiten und Potenziale
        von Mitarbeitenden und Kandidat:innen.
      </div>

      <div className='bluContent464 bluTypeCopyStrong marginTopS'>
        <strong>Content464:</strong>
        { ' ' }
        Über zahlreiche Assessments erhälst du
        umfassende Erkenntnisse über die individuellen Fähigkeiten und Potenziale
        von Mitarbeitenden und Kandidat:innen.
      </div>

      { /* WIDE CONTENT */ }
      <div className='bluContent664'>
        <br />
        <strong>content664</strong>
        <br />
        This is wide content paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros,
        pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus.
        Donec scelerisque sollicitudin enim eu venenatis. Duis tincidunt laoreet ex,
        in pretium orci vestibulum eget. Class aptent taciti sociosqu ad litora torquent
        per conubia nostra, per inceptos himenaeos. Duis pharetra luctus lacus ut
        vestibulum. Maecenas ipsum lacus, lacinia quis posuere ut, pulvinar vitae dolor.
        Integer eu nibh at nisi ullamcorper sagittis id vel leo. Integer feugiat
        faucibus libero, at maximus nisl suscipit posuere. Morbi nec enim nunc.
        Phasellus bibendum turpis ut ipsum egestas, sed sollicitudin elit convallis.
        Cras pharetra mi tristique sapien vestibulum lobortis. Nam eget bibendum met
      </div>

      { /* EXTRA WIDE CONTENT */ }
      <div className='bluContent686'>
        <div className='marginTopXs'>
          <strong>content686</strong>
          <div className='marginTopXs'>
            <VideoInstructional src='https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm' />
          </div>
        </div>
      </div>

      { /* CONTENT */ }
      <div className='bluContent466'>
        <br />
        <strong>content466</strong>
        { /* FORM */ }
        <div className='marginTopXs'>
          <InputNext
            size={size}
            value={firstName}
            label={translate('cp_firstname_placeholder') || 'Dein Vorname'}
            onChange={handleFirstName}
          />
        </div>
        <div className='marginTopXs'>
          <InputNext
            size={size}
            value={lastName}
            label={translate('cp_lastname_placeholder') || 'Dein Nachname'}
            onChange={handleLastName}
          />
        </div>

        <div className='marginTopXs'>
          <VideoInstructional src='https://file-examples.com/storage/fe4b3699ad626269a487fc2/2017/04/file_example_MP4_1280_10MG.mp4' />
        </div>
        <div className='marginTopXs'>
          <VideoInstructional src='https://file-examples.com/storage/fe4b3699ad626269a487fc2/2017/04/file_example_MP4_1280_10MG.mp4' />
        </div>

      </div>

      { /* CONTROLS */ }
      { controls && (
        <div className='bluContent664'>
          <div className='bluDialogControls'>
            { controls }
          </div>
        </div>
      ) }
    </div>
  );
};

export default QuestionDemo;
