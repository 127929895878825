// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CandidateCompleteProfile.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { DialogSequence } from 'ui/molecules/DialogSequence';
import {
  QuestionProfileCompleted,
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionProfileCompleted';
import { StoreNextTranslations } from 'ui/basic/utils/StoreNextTranslations';

// UTILS
import * as api from 'api';
import * as localStorage from 'utils/localStorage';

// STORE
import { useDispatch, useSelector } from 'react-redux';

// CONFIG & DATA
import { candidateProfileCompletionConfig } from 'ui/molecules/DialogSequence/config';

import {
  selectUserId,
  selectUserGroup,
  selectCompany,
  setCurrentUserData,
} from 'features/framework/storeNext/configurationSlice';
import { fetchStaticValues } from 'features/framework/storeNext/staticValuesSlice';

const Config = {
  waitAtEnd: 1500,
};


// COMPONENT: CandidateCompleteProfile
const CandidateCompleteProfile = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(selectUserId);
  const userGroup = useSelector(selectUserGroup);

  const company = useSelector(selectCompany);

  const [ userIdInternal, setUserIdInternal ] = useState();
  // IMPORTANT: set this via state
  // otherwise some store updates will trigger an update for userId
  // and rerender and reset DialogSequence
  useEffect(() => {
    if (userId && userId !== userIdInternal) {
      setUserIdInternal(userId);
    }
  }, [ userId, userIdInternal ]);

  const [ staticValuesRequested, setStaticValuesRequested ] = useState(false);
  useEffect(() => {
    if (staticValuesRequested) {
      return;
    }

    setStaticValuesRequested(true);
    dispatch(fetchStaticValues());
  }, [ dispatch, staticValuesRequested ]);

  // PAGES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ pages, setPages ] = useState([]);
  useEffect(() => {
    if (!company || Object.keys(company).length === 0) {
      return;
    }

    const configPages = candidateProfileCompletionConfig.pages;
    if (!userGroup || !company.custom?.profileSettings?.[userGroup]) {
      setPages(configPages);
      return;
    }

    const settings = company.custom.profileSettings[userGroup];
    const filteredPages = configPages.filter((page) => {
      if (!page.id || settings[page.id] === undefined) {
        return true;
      }

      return !!settings[page.id];
    });

    setPages(filteredPages);
  }, [ userGroup, company ]);

  const [ finalRequestSubmitted, setFinalRequestSubmitted ] = useState(false);
  const [ finalRequestSuccessful, setFinalRequestSuccessful ] = useState(false);
  useEffect(() => {
    if (finalRequestSuccessful) {
      // page reload is needed for fetching the new data
      // instead of setting it from new store to old store
      // temp solution for migration phase
      window.location.href = '/';

      // timeout to let StoreNext & QuestionProfileCompleted perform
      // setTimeout(() => {
      //   history.push('/');
      // }, Config.waitAtEnd);
    }
  }, [ history, finalRequestSuccessful ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLERS
  const handleAnswer = () => {
    api.get('core/user/heartbeat');
  };

  const getPayload = (answers) => {
    // PAYLOAD
    const payload = {
      firstName: answers.username.firstName,
      lastName: answers.username.lastName,
    };

    const profileData = {
      country: answers.location?.country,
    };

    // LOCATION
    profileData.city = answers.location?.city;
    if (answers.nationality?.value) {
      profileData.nationality = [ answers.nationality.value ];
    }

    // GENDER
    profileData.gender = answers.gender?.value;

    // AGE
    if (answers.age?.value) {
      profileData.yearOfBirth = Number(answers.age.value);
    }

    profileData.education = answers.schoolDegree?.value;
    profileData.careerLevel = answers.careerLevel?.value;
    profileData.currentPosition = answers.position?.value;
    profileData.currentJobFamily = answers.jobFamily?.value;
    profileData.industryExperience = answers.experience?.value.map((answer) => ({
      industry: answer.name,
      experience: answer.experience,
    }));

    // JOB REQUIREMENTS
    if (answers.jobRequirements && answers.jobRequirements.isValid) {
      const { selectedOptions } = answers.jobRequirements.value;
      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach((selectedOption) => {
          switch (selectedOption.name) {
            case 'partTime': {
              if (selectedOption.selected) {
                profileData.desiredWeeklyHours = Number(selectedOption.value);
              }
              break;
            }
            case 'salarySpecific': {
              if (selectedOption.selected) {
                profileData.desiredSalary = Number(selectedOption.value[0]);
                // eslint-disable-next-line prefer-destructuring
                profileData.currency = selectedOption.value[1];
              }
              break;
            }
            case 'remote': {
              if (selectedOption.selected) {
                profileData.remotePreferred = selectedOption.value;
              }
              break;
            }
            default:
          }
        });
      }

      const customReqs = answers.jobRequirements.value.customRequirements;
      if (customReqs && customReqs.length > 0) {
        profileData.additionalRequirements = customReqs;
      }
    }

    // MOTIVATION
    if (answers.jobMotivations && answers.jobMotivations.isValid) {
      const { selectedOptions } = answers.jobMotivations.value;
      if (selectedOptions && selectedOptions.length > 0) {
        profileData.motivation = selectedOptions
        .filter((selectedOption) => selectedOption.selected)
        .map((selectedOption) => selectedOption.name);
      }

      const { customAspects } = answers.jobMotivations.value;
      if (customAspects && customAspects.length > 0) {
        if (profileData.motivation && Array.isArray(profileData.motivation)) {
          profileData.motivation = [ ...profileData.motivation, ...customAspects ];
        } else {
          profileData.motivation = customAspects;
        }
      }
    }

    // START DATE
    if (answers.jobStartDate && answers.jobStartDate.isValid) {
      const { value } = answers.jobStartDate;
      if (value.radioValue === 'now') {
        profileData.earliestStart = 'now';
      } else if (value.dateValue) {
        profileData.earliestStart = value.dateValue;
      }
    }

    payload.profileData = profileData;
    payload.settings = { custom: { profileCompleted: true } };

    return payload;
  };

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    if (finalRequestSubmitted) {
      return;
    }

    const payload = getPayload(answers);

    setFinalRequestSubmitted(true);
    api.post('core/user/update', payload)
    .then(({ ok, status }) => {
      if (ok && status === 200) {
        const params = new URLSearchParams();
        params.append('expand', 'roles');
        params.append('expand', 'organizationalRoles');
        params.append('include', 'settings');

        api.get('core/user/info', params)
        .then((currentUserResponse) => {
          if (currentUserResponse.ok) {
            dispatch(setCurrentUserData(currentUserResponse.data));

            // give user request some time, since profileCompleted update
            // will trigger a new page load
            setTimeout(() => {
              setFinishable(true);
              setFinalRequestSuccessful(true);
            }, Config.waitAtEnd);
          }
        });
      }
    })
    .catch((error) => {
      console.error(error.message);
      setErrorEnd(error.message);
    });
  };

  const handleFinish = () => {
    // history.push('/');
  };

  const handleCancel = () => {
    localStorage.logout();
    window.location.href = '/logged-out';
  };

  if (pages.length === 0) {
    return null;
  }

  // RENDER: CandidateCompleteProfile
  return (
    <div className={classNames(styles.candidateCompleteProfile)}>
      { /* this component is used for updating the translations in old store from new store;
          this is a temporary solution until we migrate the data completely;
      */ }
      <StoreNextTranslations />

      { finalRequestSuccessful && (
        <QuestionProfileCompleted />
      ) }

      <DialogSequence
        type='candidateProfileCompletion'
        userId={userIdInternal}
        configOverride={{ pages }}
        showLanguageToggle
        onAnswer={handleAnswer}
        onAllAnswers={handleAllAnswers}
        onFinish={handleFinish}
        onCancel={handleCancel}
        onError={() => {}}
      />
    </div>
  );
};

export default CandidateCompleteProfile;
