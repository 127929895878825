// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './QuestionSegments.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { SegmentedChooser } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionSegments
const QuestionSegments = (props) => {
  // PROPS
  const {
    question = {},
    range = [],
    value,
    error = '',
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const {
    questionText, label, description, answerOptions, useAnswerOptions,
  } = question;

  let upperText;
  let parallelText;
  if (questionText && label) {
    upperText = questionText;
    parallelText = label;
  } else if (label && !questionText) {
    parallelText = label;
  } else if (!label && questionText) {
    upperText = label;
  }

  let rangeInternal = [];
  if (useAnswerOptions && answerOptions?.length) {
    rangeInternal = question.answerOptions.map((answerOption) => ({
      id: answerOption.id,
      value: answerOption.value,
      translation: translate(answerOption.label),
    }));
  } else {
    rangeInternal = range.map((r) => ({
      ...r,
      translation: translate(r.translationKey || r.translationFallback),
    }));
  }

  const [ hoveredSegmentLabel, setHoveredSegmentLabel ] = useState('');

  // RENDER: QuestionSegments
  return (
    <div className={classNames(styles.questionSegments)}>
      { upperText && (
        <span className='bluTypeLabelL'>{ upperText }</span>
      ) }

      { error && (
        <div className={classNames('error', 'marginTopXs')}>{ error }</div>
      ) }

      <div className={styles.segmentedChooserParent}>
        <div className={styles.segmentedChooser}>
          <SegmentedChooser
            size='S'
            segmentsCount={rangeInternal.length}
            value={value}
            onChange={(valueParam) => {
              onAnswer({
                question: question.id,
                content: valueParam,
              });
            }}
            onHover={(index) => {
              setHoveredSegmentLabel(rangeInternal[index - 1]?.translation);
            }}
          />
        </div>
        { parallelText && (
          <span className={upperText ? 'bluTypeCopy' : 'bluTypeLabelL'}>
            { parallelText }
          </span>
        ) }
      </div>

      <div className={classNames(styles.dynamicHint, {
        [styles.visible]: Boolean(hoveredSegmentLabel || isValid(value)),
      })}
      >
        { hoveredSegmentLabel
          || rangeInternal.find((r) => r.value === value)?.translation }
      </div>

      { description && (
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate(description) }
        </div>
      ) }
    </div>
  );
};

export default QuestionSegments;
