import React from 'react';

// OTHER COMPONENTS
import { Button } from 'ui/basic';
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import AssessmentRegistration from '../intermissions/AssessmentRegistration';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { translate } from 'utils/translator/translator';

import RmpAssessmentEnd from '../intermissions/RmpAssessmentEnd';

export const rmpConfig = {
  id: 'rmp',
  title: 'RMP',

  // config
  storageValidityWindow: 0, // no storage use possible
  inactivityTimeout: 5 * 60 * 1000, // 5 minutes
  // inactivityTimeout: 30 * 1000, // 30 seconds
  // modalHurryDelay: 10 * 1000, // 10 seconds
  manualEnd: true,
  modalHelpContentTranslationKey: 'rmp_assessment_help_info_content',

  languageOptions: [],
  genderOptions: [],
  countryOptions: [],

  // navigation
  allowBackNavigation: true,
  allowForwardNavigation: true,
  skippedQuestionsPage: {
    showCopyright: true,
    render: (next) => (
      <div>
        <h5>
          { translate('assessment_skipped_questions_title') }
        </h5>
        <br />
        <br />
        <div className='bluTypeCopyStrong'>
          { translate('assessment_skipped_questions_content') }
        </div>
        <br />
        <br />
        <Button onClick={() => {
          next();
        }}
        >
          { translate('continue_lbl') }
        </Button>
        <br />
        <br />
        <br />
      </div>
    ),
  },
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.RMP}
          onClickNext={() => next()}
        />
      ),
    },
    // REGISTRATION
    {
      insertAtIndex: 1,
      showProgressBar: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => (
        <AssessmentRegistration
          assessmentType={ASSESSMENT_TYPES.RMP}
          nextButtonText='Start'
          skipRegistration={state.skipRegistration}
          registrationPendingExternal={state.registrationPendingExternal}
          languageDefault={state.languageDefault}
          languageOptions={state.languageOptions}
          genderOptions={state.genderOptions}
          countryOptions={state.countryOptions}
          extras={extras}
          onClickNext={() => { next(); }}
        />
      ),
    },
    // GUIDANCE
    {
      insertAtIndex: 2,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.RMP}
          showCopyright
          onClickNext={() => next()}
        />
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      showBackArrow: true,
      showCopyright: true,
      render: (next, prev, state, extras) => <RmpAssessmentEnd {...extras} />,
    },
  ],
};
