import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';

// ASSETS
import { ReactComponent as Checkmark } from 'assets/icons/icn_check_big.svg';

// 3RD PARTY
import classNames from 'classnames';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  initUpdateUserSettings,
  logoutFulfilled,
  updateUserSettings,
  resetUpdateCurrentUser,
  updateCurrentUser,
  initSignUp,
  getAssessment,
  getCompanySettings,
  listAssessments,
} from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import {
  selectCompanyHasExternalLegalAgreements,
  selectInstanceContainsBluquistFeatures,
} from 'store/selectors/configuration';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';

// OTHER COMPONENTS
import {
  TextArea, Toggle, Toast, Button, InputPassword, Link, Checkbox, Card, Modal,
} from 'ui/basic';

// UTILS
import * as api from 'api';
import * as localStorage from 'utils/localStorage';
import { useTranslate } from 'utils/translator';
import { handleRipple } from 'utils/ripple';
import { matchPasswords } from 'utils/validator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { scrollIntoView } from 'utils/scrolling';
import { HELP_LINK, SUPPORT_LINK } from 'utils/configuration';


const SETTINGS = [
  { id: 'languages', label: 'gen_settings_language' },
  { id: 'notifications', label: 'gen_settings_notifications' },
  { id: 'change-password', label: 'gen_settings_change_password' },
  { id: 'delete-profile', label: 'gen_settings_delete_profile' },
];

const Config = {
  assessmentsWithSubDimensions: [
    ASSESSMENT_TYPES.BIG5,
    ASSESSMENT_TYPES.KEY_COMPETENCIES,
    ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES,
  ],
};

const Settings = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  // change password
  const [ oldPassword, setOldPassword ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ newPasswordIsCorrect, setNewPasswordIsCorrect ] = useState('');
  const [ confirmedPassword, setConfirmedPassword ] = useState('');
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);

  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);
  const languages = useSelector((state) => state.localisation?.languages)
  .map((id) => ({ id, name: translate(`langswitch_menu_${id}`) }));

  const containsBluquistFeatures = useSelector(selectInstanceContainsBluquistFeatures);

  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const passwordIsUpdated = useSelector((state) => state.currentUser
  && !!state.currentUser.success);
  const userSettings = useSelector(fromSettingsSelectors.getUserSettings);
  const userSettingsError = useSelector(fromSettingsSelectors.getUserSettingsError);
  const updateUserSettingsProcessing = useSelector(
    fromSettingsSelectors.getUpdateUserSettingsProcessing,
  );
  const userSettingsUpdateSuccess = useSelector(fromSettingsSelectors.getUpdateUserSettingsSuccess);

  const languageIsChanged = useSelector(fromSettingsSelectors.selectLanguageIsChanged);

  // delete profile
  const [ deleteMyProfileProcessing, setDeleteMyProfileProcessing ] = useState();
  const [ deleteMyProfileErrorMessage, setDeleteMyProfileErrorMessage ] = useState();
  const [ deleteMyProfileModal1Visible, setDeleteMyProfileModal1Visible ] = useState();
  const [ deleteMyProfileModal2Visible, setDeleteMyProfileModal2Visible ] = useState();
  const [ deleteMyProfileFeedback, setDeleteMyProfileFeedback ] = useState();
  const [ deleteMyProfilePassword, setDeleteMyProfilePassword ] = useState();

  const companyHasExternalLegalAgreements = useSelector(selectCompanyHasExternalLegalAgreements);

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);
  useEffect(() => {
    if (!companySettings || Object.keys(companySettings).length === 0) {
      dispatch(getCompanySettings());
    }
  }, [ dispatch, companySettings ]);

  useEffect(() => {
    setOldPassword('');
    setNewPassword('');
    setConfirmedPassword('');
    setShowSuccessMessage(passwordIsUpdated);
  }, [ passwordIsUpdated ]);

  useEffect(() => {
    dispatch(resetUpdateCurrentUser());
  }, [ dispatch ]);

  useEffect(() => {
    if (userSettingsUpdateSuccess) {
      if (languageIsChanged && assessmentsFromStore) {
        const coreAssessmentsIds = Object.values(ASSESSMENT_TYPES);
        assessmentsFromStore.forEach((assessment) => {
          if (coreAssessmentsIds.includes(assessment.id)
            && !Config.assessmentsWithSubDimensions.includes(assessment.id)) {
            return;
          }

          setTimeout(() => dispatch(getAssessment(assessment.id)), 1);
        });

        setTimeout(() => dispatch(listAssessments()));
      }

      // set user settings values in store to initial values
      dispatch(initUpdateUserSettings());
    }
  }, [ dispatch, userSettingsUpdateSuccess, assessmentsFromStore, languageIsChanged, translate ]);


  // HANDLES
  const handleHelpClick = () => {
    window.open(HELP_LINK, '_blank');
  };

  const handleSupportClick = () => {
    window.open(SUPPORT_LINK, '_blank');
  };

  // RENDER Content (settings anchors)
  const renderContent = () => {
    const settings = [ ...SETTINGS ];
    if (companyHasExternalLegalAgreements) {
      // Remove delete profile anchor
      const idx = settings.findIndex((el) => el.id === 'delete-profile');
      settings.splice(idx, 1);
    }

    return settings.map((s, index) => (
      <Link
        key={`settings-${index}`}
        type='anchor'
        onClick={() => scrollIntoView(s.id)}
      >
        { translate(s.label) || s.altLabel }
      </Link>
    ));
  };

  // RENDER Settings
  return (
    <div className={styles.settings}>
      <div className={styles.main}>
        <div className={styles.title}>
          { translate('gen_settings_title') }
        </div>
        <div className={styles.description}>
          { translate('gen_settings_description') }
        </div>
        <div className={styles.content}>
          { renderContent() }
        </div>

        <div className={styles.languages} id='languages'>
          <div className={styles.title}>
            { translate('gen_settings_choose_language') }
          </div>

          <div className={styles.rows}>
            { languages.map((language) => (
              <div
                key={`language-${language.id}`}
                role='presentation'
                className={classNames(
                  styles.language,
                  { [styles.active]: currentLanguage === language.id },
                )}
                onClick={(event) => {
                  handleRipple(event, styles.colorPrimary3);
                  setTimeout(() => {
                    dispatch(updateUserSettings({
                      language: language.id,
                      assessmentReminderMails: userSettings.assessmentReminderMails,
                      newFeatureMails: userSettings.newFeatureMails,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport,
                    }));
                  });
                }}
              >
                <span>{ language.name }</span>
                { currentLanguage === language.id && <Checkmark /> }
              </div>
            )) }
          </div>
        </div>

        <div className={styles.notifications} id='notifications'>
          <div className={styles.title}>
            { translate('gen_settings_notifications') }
          </div>
          <div className={styles.description}>
            { translate('gen_settings_notifications_descr') }
          </div>

          { userSettingsError && (
            <div className={styles.error}>
              { userSettingsError.errorMessage }
            </div>
          ) }

          <div className={styles.rows}>
            <div className={styles.row}>
              <div className={styles.row1}>
                <span className='bluTypeLabel'>{ translate('notification_settings__assessments_title') }</span>
                <Toggle
                  id='toggle1'
                  // company settings have higher priority.
                  // If setting is deactivated for company,
                  // it should also be shown as deactivated for user settings
                  checked={(companySettings && !companySettings.assessmentReminderMails)
                    ? false
                    : userSettings.assessmentReminderMails}
                  disabled={(companySettings && !companySettings.assessmentReminderMails)
                    || updateUserSettingsProcessing}
                  onChange={(value) => {
                    dispatch(updateUserSettings({
                      assessmentReminderMails: value,
                      language: userSettings.language,
                      newFeatureMails: userSettings.newFeatureMails,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport,
                    }));
                  }}
                />
              </div>
              <div className={styles.row2}>
                { translate('notification_settings__assessments_descr') }
              </div>
            </div>

            { me.canAccessWellBeing && (
              <div className={styles.row}>
                <div className={styles.row1}>
                  <span className='bluTypeLabel'>
                    { translate('notification_settings__wellbeing_title') }
                  </span>
                  <Toggle
                    id='toggle2'
                    checked={(companySettings && !companySettings.wellbeingMails)
                      ? false
                      : userSettings.wellbeingMails}
                    disabled={(companySettings && !companySettings.wellbeingMails)
                      || updateUserSettingsProcessing}
                    onChange={(value) => {
                      dispatch(updateUserSettings({
                        wellbeingMails: value,
                        assessmentReminderMails: userSettings.assessmentReminderMails,
                        language: userSettings.language,
                        newFeatureMails: userSettings.newFeatureMails,
                        wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                        wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport,
                      }));
                    }}
                  />
                </div>
                <div className={styles.row2}>
                  { translate('notification_settings__wellbeing_descr') }
                </div>
                <Checkbox
                  name={translate('notification_settings__wellbeing_checkbox')}
                  checked={!!userSettings.wellbeingOnlyWeekdays}
                  disabled={(companySettings && !companySettings.wellbeingMails)
                    || updateUserSettingsProcessing}
                  onChange={(value) => {
                    dispatch(updateUserSettings({
                      wellbeingOnlyWeekdays: value,
                      assessmentReminderMails: userSettings.assessmentReminderMails,
                      language: userSettings.language,
                      newFeatureMails: userSettings.newFeatureMails,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport,
                    }));
                  }}
                />
              </div>
            ) }

            <div className={styles.row}>
              <div className={styles.row1}>
                <span className='bluTypeLabel'>{ translate('gen_settings_notifications_new_functions') }</span>
                <Toggle
                  id='toggle3'
                  checked={(companySettings && companySettings.newFeatureMails === false)
                    ? false
                    : userSettings.newFeatureMails}
                  disabled={(companySettings && companySettings.newFeatureMails === false)
                    || updateUserSettingsProcessing}
                  onChange={(value) => {
                    dispatch(updateUserSettings({
                      newFeatureMails: value,
                      assessmentReminderMails: userSettings.assessmentReminderMails,
                      language: userSettings.language,
                      wellbeingOnlyWeekdays: userSettings.wellbeingOnlyWeekdays,
                      wellbeingMails: userSettings.wellbeingMails,
                      wellbeingWeeklyReport: userSettings.wellbeingWeeklyReport,
                    }));
                  }}
                />
              </div>
              <div className={styles.row2}>
                { translate('gen_settings_notifications_new_functions_descr') }
              </div>
            </div>
          </div>
        </div>

        { /* CHANGE PASSWORD */ }
        <form
          className={styles.changePassword}
          id='change-password'
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(updateCurrentUser({
              password: newPassword,
              passwordOld: oldPassword,
            }));
          }}
        >
          <div className={styles.title}>
            { translate('gen_settings_change_password') }
          </div>
          <div className={styles.description}>
            { translate('gen_settings_change_password_desc') }
          </div>
          <div className={styles.inputs}>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input1_placeholder')}
                value={oldPassword}
                onChange={(element, value) => setOldPassword(value)}
              />
            </div>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input2_placeholder')}
                value={newPassword}
                showCriteria
                onInputChanged={(value, correct) => {
                  setNewPassword(value);
                  setNewPasswordIsCorrect(correct);
                }}
              />
            </div>
            <div className={styles.input}>
              <InputPassword
                placeHolder={translate('gen_settings_change_password_input3_placeholder')}
                value={confirmedPassword}
                onInputChanged={(value) => {
                  setConfirmedPassword(value);
                }}
                hint={matchPasswords(newPassword, confirmedPassword) || translate('signup_form_password_error_hint_notmatching')}
              />
            </div>
          </div>
          <Button
            size='S'
            type='submit'
            disabled={!oldPassword || !newPasswordIsCorrect
              || !matchPasswords(newPassword, confirmedPassword)}
            onClick={() => {
              dispatch(updateCurrentUser({
                password: newPassword,
                passwordOld: oldPassword,
              }));
            }}
          >
            { translate('gen_settings_change_password_btn') }
          </Button>
        </form>

        { /* DELETE MY PROFILE */ }
        { !companyHasExternalLegalAgreements && (
          <div className={styles.deleteMyProfile} id='delete-profile'>
            <div className={styles.label}>
              { translate('delete_my_profile_title') }
            </div>
            <div className={styles.copy}>
              { translate('delete_my_profile_copy', [ '{{instance_url}}', window.location.host ]) }
            </div>
            <Button
              looks='cancel'
              size='S'
              onClick={() => setDeleteMyProfileModal1Visible(true)}
            >
              { translate('delete_my_profile_btn') }
            </Button>
          </div>
        ) }

      </div>

      <div className={styles.other}>
        <div className={styles.xxsTitle}>{ translate('gen_settings_support_and_help') }</div>

        <Card
          hasPaddingsSmall
          hasHover
          onClick={handleHelpClick}
        >
          <div className={styles.label}>{ translate('gen_settings_help_label') }</div>
          <div className={styles.copy}>
            { translate('gen_settings_help_copy') }
          </div>

          <Link onClick={handleHelpClick}>
            { translate('gen_settings_help_link') }
          </Link>
        </Card>

        <Card
          hasPaddingsSmall
          hasHover
          onClick={handleSupportClick}
        >
          <div className={styles.label}>
            { translate('gen_settings_support_title') }
          </div>
          <div className={styles.copy}>
            { translate('gen_settings_support_descr') }
          </div>

          <Link onClick={handleSupportClick}>
            { translate(containsBluquistFeatures
              ? 'by_gen_settings_support_copy'
              : 'by_gen_settings_support_learnmore') }
          </Link>
        </Card>

      </div>

      { showSuccessMessage && (
        <Toast onClose={() => setShowSuccessMessage(false)}>
          <>
            <div className={styles.toastTitle}>
              { translate('gen_settings_change_password_success_title') }
            </div>
            <div className={styles.toastDescription}>
              { translate('gen_settings_change_password_success_descr') }
            </div>
          </>
        </Toast>
      ) }

      { /* DELETE MY PROFILE MODAL 1 */ }
      { deleteMyProfileModal1Visible && (
        <Modal
          header={translate('delete_account_feedback')}
          primaryButtonTitle={translate('delete_account_btn')}
          ignoredKeys='Enter'
          onClose={() => setDeleteMyProfileModal1Visible(false)}
          onConfirm={() => {
            setDeleteMyProfileModal1Visible(false);
            setDeleteMyProfileModal2Visible(true);
          }}
        >
          <div className='marginTopS'>
            <TextArea
              placeholder={translate('survey_page_header_title')}
              onChange={(value) => setDeleteMyProfileFeedback(value)}
            />
          </div>
        </Modal>
      ) }

      { /* DELETE MY PROFILE MODAL 2 */ }
      { deleteMyProfileModal2Visible && (
        <Modal
          header={translate('delete_my_profile_modal_header')}
          redButtonTitle={translate('delete_lbl')}
          redButtonDisabled={deleteMyProfileProcessing}
          secondaryButtonTitle={translate('cancel_lbl')}
          closeOnConfirm={false}
          onClose={() => setDeleteMyProfileModal2Visible(false)}
          onConfirm={() => {
            setDeleteMyProfileProcessing(true);

            // sending feedback
            if (deleteMyProfileFeedback) {
              api.post('/core/platform/feedback', { accountDeletionFeedback: deleteMyProfileFeedback });
            }

            api.post('/core/user/delete', { password: deleteMyProfilePassword })
            .then(({ ok, status, data }) => {
              setDeleteMyProfileProcessing(false);

              if (ok && status === 200) {
                localStorage.logout();
                dispatch(logoutFulfilled());
                // 'registeredSuccessfully' needs to be set to false for newly signed-up users
                dispatch(initSignUp());
                window.location.href = '/profile-deleted';
              } else {
                setDeleteMyProfileErrorMessage(data.error.errorMessage);
              }
            })
            .catch((error) => {
              console.error(error.message);
              setDeleteMyProfileProcessing(false);
              setDeleteMyProfileErrorMessage(error.message);
            });
          }}
        >

          <div className={styles.deleteMyProfileModalContent}>
            { translate('delete_my_profile_modal_content', [ '{{instance_url}}', window.location.host ]) }

            { /* CONFIRM PASSWORD */ }
            <InputPassword
              placeHolder={translate('share_profile_login_password_placeholder')}
              onInputChanged={(value) => setDeleteMyProfilePassword(value)}
            />

            { /* ERROR HINT */ }
            { deleteMyProfileErrorMessage && (
              <div className={styles.error}>
                { deleteMyProfileErrorMessage }
              </div>
            ) }

          </div>
        </Modal>
      ) }

    </div>
  );
};

export default Settings;
