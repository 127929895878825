import * as action from 'store/actions';
import * as fromStaticValueActions from 'store/actions/staticValues';
import * as localStorage from 'utils/localStorage';
import { put, takeEvery } from 'redux-saga/effects';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';

function* changeLanguage({ payload }) {
  try {
    const { language, refetchStaticValues = false } = payload;

    localStorage.setItem('selectedLanguage', language);
    yield put(action.getTranslations(language));
    yield put(action.changeLanguageFulfilled(language));

    if (refetchStaticValues) {
      yield put(fromStaticValueActions.getCountries());
      yield put(fromStaticValueActions.getEducation());
      yield put(fromStaticValueActions.getIndustries());
      yield put(fromStaticValueActions.getJobFamilies());
      yield put(fromStaticValueActions.getCareerLevels());

      if (!getIsExternalSystemIntegrationSupported()) {
        yield put(action.getWellBeingIndex());
      }
    }
  } catch (error) {
    yield put(action.changeLanguageRejected(error));
  }
}

export default function* watchChangeLanguageRequest() {
  yield takeEvery(action.CHANGE_LANGUAGE, changeLanguage);
}
