import {
  INIT_UPDATE_USER_SETTINGS,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS_FULFILLED,
  UPDATE_COMPANY_SETTINGS_REJECTED,
  UPDATE_REGISTRATION_DOMAINS,
  UPDATE_REGISTRATION_DOMAINS_FULFILLED,
  UPDATE_REGISTRATION_DOMAINS_REJECTED,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_FULFILLED,
  UPDATE_SETTINGS_REJECTED,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FULFILLED,
  UPDATE_USER_SETTINGS_REJECTED,
} from 'store/actionTypes/settings';

export const updateSettings = (settingsDto) => ({
  type: UPDATE_SETTINGS,
  payload: settingsDto,
});

export const updateSettingsFulfilled = (data) => ({
  type: UPDATE_SETTINGS_FULFILLED,
  payload: data,
});

export const updateSettingsRejected = ({ error }) => ({
  type: UPDATE_SETTINGS_REJECTED,
  payload: { error },
});

// UPDATE REGISTRATION DOMAINS
export const updateRegistrationDomains = (domains) => ({
  type: UPDATE_REGISTRATION_DOMAINS,
  payload: { domains },
});

export const updateRegistrationDomainsFulfilled = () => ({
  type: UPDATE_REGISTRATION_DOMAINS_FULFILLED,
  payload: {},
});

export const updateRegistrationDomainsRejected = ({ error }) => ({
  type: UPDATE_REGISTRATION_DOMAINS_REJECTED,
  payload: { error },
});

// UPDATE USER SETTINGS
export const initUpdateUserSettings = () => ({
  type: INIT_UPDATE_USER_SETTINGS,
  payload: {},
});


export const updateUserSettings = (userSettingsDto, showToast = true) => ({
  type: UPDATE_USER_SETTINGS,
  payload: {
    userSettingsDto,
    showToast,
  },
});

export const updateUserSettingsFulfilled = (languageChanged) => ({
  type: UPDATE_USER_SETTINGS_FULFILLED,
  payload: { languageChanged },
});

export const updateUserSettingsRejected = ({ error }) => ({
  type: UPDATE_USER_SETTINGS_REJECTED,
  payload: { error },
});

// UPDATE COMPANY SETTINGS
export const updateCompanySettings = (companySettingsDto, companyId) => ({
  type: UPDATE_COMPANY_SETTINGS,
  payload: { companySettingsDto, companyId },
});

export const updateCompanySettingsFulfilled = () => ({
  type: UPDATE_COMPANY_SETTINGS_FULFILLED,
  payload: {},
});

export const updateCompanySettingsRejected = ({ error }) => ({
  type: UPDATE_COMPANY_SETTINGS_REJECTED,
  payload: { error },
});
