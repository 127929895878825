export const RECOMMENDATION_STATE = {
  ACTIVE: 'active',
  BOOKMARKED: 'bookmarked',
  ARCHIVED: 'archived',
  INACTIVE: 'inactive',
};

export const RECOMMENDATION_CONTENT_TYPE = {
  TEXT: 'text',
  AUDIO: 'audio',
  VIDEO: 'video',
};

export const RECOMMENDATION_CONTENT_SOURCE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};
