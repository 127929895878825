import * as fromActionTypes from 'store/actionTypes/surveys';

export const listSurveyParticipations = () => ({
  type: fromActionTypes.LIST_SURVEY_PARTICIPATIONS,
  payload: {},
});

export const listSurveyParticipationsFulfilled = (surveys) => ({
  type: fromActionTypes.LIST_SURVEY_PARTICIPATIONS_FULFILLED,
  payload: surveys,
});

export const listSurveyParticipationsRejected = ({ error }) => ({
  type: fromActionTypes.LIST_SURVEY_PARTICIPATIONS_REJECTED,
  payload: { error },
});

