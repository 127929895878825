import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import AssessmentEnd from '../intermissions/AssessmentEnd';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// CONFIG

// OTHER COMPONENTS

export const balancedYouConfig = {
  id: 'balancedYou',
  title: 'Balanced You',
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'by_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.BALANCED_YOU}
          onClickNext={() => next()}
        />
      ),
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.BALANCED_YOU}
          onClickNext={() => next()}
        />
      ),
    },
    {
      insertAtIndex: 1 + 1,
      explanatory: true,
      question: 'mjs_pa',
      description: 'mjs_pa_descr',
    },
    {
      insertAtIndex: 2 + 4,
      explanatory: true,
      question: 'mjs_sb',
      description: 'mjs_sb_descr',
    },
    {
      insertAtIndex: 3 + 7,
      explanatory: true,
      question: 'mjs_mf',
      description: 'mjs_mf_descr',
    },
    {
      insertAtIndex: 4 + 10,
      explanatory: true,
      question: 'mjs_rs',
      description: 'mjs_rs_descr',
    },
    {
      insertAtIndex: 5 + 13,
      explanatory: true,
      question: 'mjs_gh',
      description: 'mjs_gh_descr',
    },
    {
      insertAtIndex: 6 + 18,
      explanatory: true,
      question: 'mjs_em',
      description: 'mjs_em_descr',
    },
    {
      insertAtIndex: 7 + 22,
      explanatory: true,
      question: 'mjs_eb',
      description: 'mjs_eb_descr',
    },
    // BREATHE is set in BalancedYouAssessmentPage
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <AssessmentEnd
          {...extras}
          assessmentType={ASSESSMENT_TYPES.BALANCED_YOU}
        />
      ),
    },
  ],
};
