import { isValid } from './numbers';
import { sortAlphabetically } from 'utils/strings';

export const RMP_FIRST_MARKER_POSITION = 0.84;
export const RMP_THIRD_MARKER_POSITION = 2;

export const resultIsStrong = (result) => (result && result > RMP_FIRST_MARKER_POSITION && result <= RMP_THIRD_MARKER_POSITION);

export const resultIsAverage = (result) => (isValid(result) && result >= -RMP_FIRST_MARKER_POSITION && result <= RMP_FIRST_MARKER_POSITION);

export const resultIsWeak = (result) => (result && result < -RMP_FIRST_MARKER_POSITION && result >= -RMP_THIRD_MARKER_POSITION);

// strong results
export const getStrongResults = (results = []) => results
.filter((resultItem) => resultIsStrong(resultItem.result))
.sort((a, b) => sortAlphabetically(a.result, b.result, true)); // sort descending

// weak results
export const getWeakResults = (results = []) => results
.filter((resultItem) => resultIsWeak(resultItem.result))
.sort((a, b) => sortAlphabetically(a.result, b.result)); // sort ascending

// average results
export const getAverageResults = (results = []) => {
  const filteredAverageMotives = results.filter((resultItem) => resultIsAverage(resultItem.result));
  const positiveAverageMotives = filteredAverageMotives
  .filter((motive) => motive.result >= 0)
  .sort((a, b) => sortAlphabetically(a.result, b.result, true)); // sort descending

  const negativeAverageMotives = filteredAverageMotives
  .filter((motive) => motive.result < 0)
  .sort((a, b) => sortAlphabetically(a.result, b.result)); // sort ascending

  return positiveAverageMotives.concat(negativeAverageMotives);
};
