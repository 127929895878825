// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './WellBeingTeaserNext.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import {
  Button, Card, IstIndicator, ProgressCircle,
} from 'ui/basic';
import { GarminIcon } from 'features/+garmin/ui/basic/GarminIcon';

// UTILS
import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';
import { numberWithForcedDecimals } from 'utils/numberFormatting';
import { isValid } from 'utils/numbers';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  wellBeingMax: 5,
  balancedYouMax: 10,
};

// COMPONENT: WellBeingTeaserNext
const WellBeingTeaserNext = (props) => {
  // PROPS
  const {
    bodyBattery,
    wellBeing,
    balancedYou,
    ist,
  } = props;

  // SPECIAL HOOKS
  const history = useHistory();
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  if (!bodyBattery && !wellBeing && !balancedYou && !ist) {
    return null;
  }
  // RENDER: WellBeingTeaserNext
  return (
    <div className={classNames(styles.wellBeingTeaserNext)}>
      <Card>
        <span className='bluTypeLabelL'>{ translate('wellbeing_ind_result_title') }</span>

        { bodyBattery?.score && (
          <div className={styles.block}>
            <GarminIcon
              size='S'
              looks='body-battery'
              scoreClass={bodyBattery.score.class}
            />
            <span>{ translate(`body_battery_${bodyBattery.score.class}_title`) }</span>
          </div>
        ) }

        { isValid(wellBeing) && (
          <div className={styles.block}>
            <ProgressCircle
              size='Ms'
              progress={Math.ceil((wellBeing * 100) / Config.wellBeingMax)}
              showWellBeingIcon
            />
            <span>
              { translate('wb_teaser_wb', [
                '{{value}}', !wellBeing ? '-' : setDecimalSeparator(wellBeing),
                '{{maxValue}}', Config.wellBeingMax,
              ]) }
            </span>
          </div>
        ) }

        { isValid(balancedYou) && (
          <div className={styles.block}>
            <ProgressCircle
              size='Ms'
              progress={Math.ceil((balancedYou * 100) / Config.balancedYouMax)}
              showWellBeingIcon
            />
            <span>
              { translate('wb_teaser_balancedyou', [
                '{{value}}', numberWithForcedDecimals(balancedYou),
                '{{maxValue}}', Config.balancedYouMax,
              ]) }
            </span>
          </div>
        ) }

        { ist && (
          <div className={styles.block}>
            <IstIndicator
              size='S'
              color={ist.vulnerable ? 'red' : 'light'}
            />
            <span>
              { ist.vulnerable
                ? translate('wb_teaser_ist_vulnerable')
                : translate('wb_teaser_ist_not_vulnerable') }
            </span>
          </div>
        ) }

        <Button
          size='S'
          looks='secondary'
          onClick={() => history.push('/my-profile/well-being')}
        >
          { translate('learn_more') }
        </Button>
      </Card>
    </div>
  );
};

export default WellBeingTeaserNext;
