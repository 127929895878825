// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './PersonalNotes.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { SkeletonFlexible, TextEditor } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { IconsSvg } from 'assets/icons';

// CONFIG & DATA
const Config = {
  maxLength: 5000,
};


// COMPONENT: PersonalNotes
const PersonalNotes = (props) => {
  // PROPS
  const {
    notes,
    configuration = {},
    content = {},
    setNote,
  } = props;

  const {
    maxLength = Config.maxLength,
  } = configuration;
  const {
    noteId = '',
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ text, setText ] = useState();
  const [ show, setShow ] = useState(false);
  const [ focusOnEdit, setFocusOnEdit ] = useState(false);

  const isExternal = window.location.pathname.includes('/share/');

  const storeText = notes?.[noteId];
  useEffect(() => {
    setText(storeText);
  }, [ storeText ]);

  // RENDER: PersonalNotes
  if (!noteId || (isExternal && !text)) {
    return null;
  }

  if (!notes) {
    return (
      <SkeletonFlexible
        repeat={1}
        noHeader
      />
    );
  }

  return (
    <div className={classNames(styles.personalNotes)}>
      { !show && !text ? (
        <div
          role='button'
          tabIndex={0}
          className={styles.add}
          onClick={() => setShow(true)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShow(true);
            }
          }}
        >
          <IconsSvg.Plus />
          <span>
            { translate('custom_report_notes_add') }
          </span>
        </div>
      ) : (
        <>
          <div className={styles.yourNotes}>
            <div className='bluTypeXxs'>
              { translate('custom_report_notes_title') }
            </div>
            { !isExternal && (
              <div
                role='presentation'
                className={styles.btn}
                onClick={() => setFocusOnEdit(true)}
                onKeyDown={() => setFocusOnEdit(true)}
              >
                <IconsSvg.Edit />
              </div>
            ) }
          </div>
          <div className='marginTopXxs'>
            <TextEditor
              disabled={isExternal}
              content={text}
              placeholder={translate('custom_report_notes_placeholder')}
              maxLength={maxLength}
              forceFocus={!text || focusOnEdit}
              onSave={async (newTextEditorContent, newPlainText) => {
                let newText = newTextEditorContent;
                if (!newPlainText?.length) {
                  newText = null;
                  setShow(false);
                }
                setNote(noteId, newText);
                setText(newText);
                setFocusOnEdit(false);
              }}
              onCancel={() => {
                setShow(false);
                setFocusOnEdit(false);
              }}
            />
          </div>
        </>
      ) }
    </div>
  );
};

export default PersonalNotes;
