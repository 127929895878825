import React, { useState } from 'react';
import styles from './Introduction.module.scss';
import { useHistory } from 'react-router';
import { Button, Icon } from 'ui/basic';
import { Icons } from 'assets/icons';
import Image1 from 'assets/keyvisuals/create_team.svg';
import AssessmentScreenshotImage from 'assets/keyvisuals/assessment-screenshot.svg';
import FeaturesImage from 'assets/keyvisuals/features.svg';
import FeedbackImage from 'assets/keyvisuals/feedback.svg';

import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';

// STORE
import { useSelector } from 'react-redux';
import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice';

// OTHER COMPONENTS
import {
  QuestionOnboardingCompleted,
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionOnboardingCompleted';

const SCREENS = [
  {
    id: 1,
    image: Image1,
    smallImage: false,
    hideDescription: true,
  },
  {
    id: 2,
    image: AssessmentScreenshotImage,
    smallImage: false,
  },
  {
    id: 3,
    image: Image1,
    smallImage: true,
  },
  {
    id: 4,
    image: FeaturesImage,
    smallImage: true,
  },
  {
    id: 5,
    image: FeedbackImage,
    smallImage: true,
  },
];

const Introduction = () => {
  const translate = useTranslate();
  const history = useHistory();

  const [ screen, setScreen ] = useState(1);

  const firstName = useSelector(selectUserFirstName);

  const getComponent = () => {
    const component = SCREENS[screen - 1];
    const title = translate(`introduction_screen${component.id}_title`, [ '{{username}}', firstName ]);
    const description = translate(`introduction_screen${component.id}_description`);

    const isLastPage = screen === SCREENS.length;

    return (
      <>
        { isLastPage && (
          <QuestionOnboardingCompleted />
        ) }
        <div className={component.smallImage ? styles.smallImage : styles.image}>
          <img alt='introduction-screen' src={component.image} />
        </div>
        <div className={component.smallImage ? styles.bigText : styles.text}>
          <div className={styles.title}>
            { title }
          </div>
          { !component.hideDescription && (
            <div className={styles.description}>
              { markdown(description) }
            </div>
          ) }
          <Button
            size='M'
            onClick={() => {
              // set screen to null first to have animation working
              setScreen(null);

              setTimeout(() => {
                if (component.id === SCREENS.length) {
                  history.push('/');
                  return;
                }
                setScreen(component.id + 1);
              });
            }}
          >
            { translate(`introduction_screen${component.id}_btn`) }
          </Button>
        </div>
      </>
    );
  };

  if (!screen) {
    return null;
  }

  return (
    <div className={styles.introduction}>
      { /* HEADER */ }
      <div className={styles.header}>
        <span>{ translate('introduction_header_title') }</span>
        <div
          role='presentation'
          className={styles.close}
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>

      { /* CONTENT */ }
      <div className={styles.content}>
        <div className={styles.gridContent}>
          { getComponent() }
        </div>
      </div>
    </div>
  );
};

export default Introduction;
