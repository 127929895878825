// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionCompanyDetails.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import { selectIndustries } from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
const Config = {
  minLengthCompanyName: 3,
};


// COMPONENT: QuestionCompanyDetails
const QuestionCompanyDetails = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ industry, setIndustry ] = useState();
  const [ companyName, setCompanyName ] = useState();
  const [ employeesNumber, setEmployeesNumber ] = useState();
  const industries = useSelector(selectIndustries);
  const employeesNumberOptions = [
    { value: 1, label: '1 - 30' },
    { value: 2, label: '31 - 50' },
    { value: 3, label: '51 - 200' },
    { value: 4, label: '201 - 500' },
    { value: 5, label: '501 - 5000' },
    { value: 6, label: '5.001 - 10.000' },
    { value: 7, label: '10.000+' },
  ];

  // HELPERS
  const checkAnswers = ({ companyName, industry, employeesNumber }) => {
    const isValid = !!companyName && companyName.length >= Config.minLengthCompanyName
      && !!industry && !!employeesNumber;

    onAnswer({
      companyName,
      industry,
      employeesNumber,
      isValid,
    });
  };

  // EVENT HANDLES
  const handleCompanyName = (companyName) => {
    setCompanyName(companyName);
    checkAnswers({ companyName, industry, employeesNumber });
  };

  const handleIndustry = (industry) => {
    setIndustry(industry);
    checkAnswers({ companyName, industry, employeesNumber });
  };

  const handleEmployeesNumber = (employeesNumber) => {
    setEmployeesNumber(employeesNumber);
    checkAnswers({ companyName, industry, employeesNumber });
  };

  // HOOKS
  useEffect(() => {
    setCompanyName(answer.companyName);
  }, [ answer.companyName ]);

  useEffect(() => {
    setEmployeesNumber(answer.employeesNumber);
  }, [ answer.employeesNumber ]);

  useEffect(() => {
    if (industries.length === 0) {
      return;
    }

    setIndustry(answer.industry);
  }, [ answer.industry, industries ]);

  // RENDER: QuestionCompanyDetails
  return (
    <div className={classNames(styles.questionCompanyDetails)}>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          placeholder={translate('company_name')}
          value={companyName}
          onChange={handleCompanyName}
        />
      </div>

      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('cp_industry_placeholder')}
          filterPlaceholder={translate('search_lbl')}
          activeValue={industry && industry.value}
          options={industries}
          onChange={handleIndustry}
        />
      </div>

      <div className={styles.formRow}>
        <DropDownSearchable
          size={size}
          placeholder={translate('employees_number')}
          activeValue={employeesNumber && employeesNumber.value}
          options={employeesNumberOptions}
          onChange={handleEmployeesNumber}
        />
      </div>
    </div>
  );
};

export default QuestionCompanyDetails;
