// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef } from 'react';
import styles from './Recommendations.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  CollapsibleNext,
  Scrollable,
  ButtonCircular,
  SkeletonFlexible,
} from 'ui/basic';
import { DynamicFilter } from './DynamicFilter';

// UTILS
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import { RECOMMENDATION_STATE } from './Recommendations.config';

// STORE
import { useSelector } from 'react-redux';
import { selectRecommendations } from 'store/selectors/recommendations';
import { ASSESSMENT_TITLES } from 'utils/configuration/const/assessment-types';

// CONFIG & DATA
const Config = {
  cardsPerPage: 3,
  moduleTitles: {
    ...ASSESSMENT_TITLES,
    garmin: 'garmin_connect',
    'well-being': 'wellbeing_ind_result_title',
  },
};

// COMPONENT: Recommendations
const Recommendations = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const recommendations = useSelector(selectRecommendations);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const scrollableRef = useRef();
  const [ filterModules, setFilterModules ] = useState(new Set());
  const [ filterTypes, setFilterTypes ] = useState(new Set());
  const [ filterContents, setFilterContents ] = useState(new Set());
  const [ scrollableCurrentPage, setScrollableCurrentPage ] = useState(0);
  const { cardsPerPage } = Config;

  const getRecommendationsByState = (state) => {
    const applySearchFilters = (({ goals, module, contentType }) => (
      (!filterModules.size || filterModules.has(module))
      && (!filterTypes.size || [ ...filterTypes ].some((el) => goals.includes(el)))
      && (!filterContents.size || filterContents.has(contentType))));
    const list = (recommendations ?? []).filter((el) => el.state === state);
    return [ list, list.filter(applySearchFilters) ];
  };

  const [ activeList, filteredActiveList ] = getRecommendationsByState(RECOMMENDATION_STATE.ACTIVE);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: new recommendations
  const renderNewRecommendations = () => {
    const pages = [];
    for (let i = 0; i < filteredActiveList.length; i += cardsPerPage) {
      pages.push(
        <div key={i} className={styles.recommendationPage}>
          { new Array(cardsPerPage).fill().map((_, j) => {
            const card = filteredActiveList[i + j];
            if (!card) {
              return null;
            }
            return (
              <React.Fragment key={card.id}>
                <RecommendationCard {...card} />
                { (filteredActiveList.length < activeList.length) && (i + j + 1) === filteredActiveList.length && (
                  <div className={styles.missing}>
                    { translate('myprofile_recommendation_restore') }
                  </div>
                ) }
              </React.Fragment>
            );
          }) }
        </div>,
      );
    }
    return pages;
  };

  // RENDER: right section
  const renderRightSection = (state) => {
    const [ list, filteredList ] = getRecommendationsByState(state);
    const res = [];

    if (!filteredList.length) {
      res.push(
        <div className={classNames(styles.missing, 'marginTopXs')} key='none'>
          { translate('myprofile_recommendation_none') }
        </div>,
      );
    } else {
      res.push(filteredList.map((el) => (
        <RecommendationCard
          key={el.id}
          isBlue={false}
          {...el}
        />
      )));
    }

    if (filteredList.length < list.length) {
      res.push(
        <div className={styles.missing} key='restore'>
          { translate('myprofile_recommendation_restore') }
        </div>,
      );
    }

    return <div className={styles.cardListing}>{ res }</div>;
  };

  // RENDER: Recommendations
  return (
    <div className={styles.recommendations}>
      <h6>
        { translate('myprofile_recommendation_title') }
      </h6>
      { !recommendations
        ? (
          <div className={classNames(styles.skeleton)}>
            <SkeletonFlexible repeat={9} />
            <SkeletonFlexible repeat={9} />
          </div>
        ) : (
          <div className={styles.container}>
            { /* SAVED RECOMMENDATIONS COLUMN */ }
            <div className={styles.saved}>
              <div className={styles.sectionTitle}>
                { translate('myprofile_recommendation_saved_title') }
              </div>
              <div className={styles.savedIntro}>
                { translate('myprofile_recommendation_saved_intro') }
              </div>

              <DynamicFilter
                filterItems={[
                  {
                    id: 'module',
                    label: translate('myprofile_recommendation_filter_module'),
                    options: Array.from(recommendations.reduce((acc, el) => acc.add(el.module), new Set()))
                      .sort()
                      .map((el) => ({
                        value: el,
                        label: translate(Config.moduleTitles[el]),
                      })),
                    selected: filterModules,
                    onChange: setFilterModules,
                  },
                  {
                    id: 'type',
                    label: translate('myprofile_recommendation_filter_type'),
                    options: Array.from(recommendations.reduce((acc, el) => new Set([ ...acc, ...el.goals ]), []))
                      .sort()
                      .map((el) => ({
                        value: el,
                        label: capitalise(el),
                      })),
                    selected: filterTypes,
                    onChange: setFilterTypes,
                  },
                  {
                    id: 'content',
                    label: translate('myprofile_recommendation_filter_content'),
                    options: Array.from(recommendations.reduce((acc, el) => acc.add(el.contentType), new Set()))
                      .sort()
                      .map((el) => ({
                        value: el,
                        label: translate(`myprofile_recommendation_card_content_${el}`),
                      })),
                    selected: filterContents,
                    onChange: setFilterContents,
                  }
                ]}
              />

              { /* Card sections */ }
              { renderRightSection(RECOMMENDATION_STATE.BOOKMARKED) }

              <div className={styles.savedArchived}>
                <CollapsibleNext
                  header={translate('myprofile_recommendation_archive')}
                  isOpenOverride
                  noPaddings
                  noBackground
                  bottomless
                >
                  { renderRightSection(RECOMMENDATION_STATE.ARCHIVED) }
                </CollapsibleNext>
              </div>
            </div>

            { /* NEW RECOMMENDATIONS COLUMN */ }
            <div className={styles.new}>
              <div className={styles.sectionTitle}>
                { translate('myprofile_recommendation_new_title') }
              </div>
              { !filteredActiveList.length
                ? (
                  <>
                    <div className={classNames(styles.missing, 'marginTopXs')}>
                      { translate('myprofile_recommendation_none') }
                    </div>
                    { Boolean(activeList.length) && (
                      <div className={classNames(styles.missing, 'marginTopXs')}>
                        { translate('myprofile_recommendation_restore') }
                      </div>
                    ) }
                  </>
                ) : (
                  <div>
                    <Scrollable
                      ref={scrollableRef}
                      pagination
                      drag
                      refresh={filteredActiveList.length}
                      showPaginationBubbles
                      pageMargin={16}
                      onPaginationAvailabilities={(availabilities) => {
                        setScrollableCurrentPage(availabilities[2]);
                      }}
                    >
                      { renderNewRecommendations() }
                    </Scrollable>
                    <div className={styles.scrollingButtons}>
                      <ButtonCircular
                        icon='ArrowLeft'
                        looks='primary'
                        disabled={scrollableCurrentPage <= 0}
                        onClick={() => { scrollableRef.current.pageLeft(); }}
                      />
                      <ButtonCircular
                        icon='ArrowRight'
                        looks='primary'
                        disabled={scrollableCurrentPage >= Math.floor((filteredActiveList.length - 1) / cardsPerPage)}
                        onClick={() => { scrollableRef.current.pageRight(); }}
                      />
                    </div>
                  </div>
                ) }
            </div>
          </div>
        ) }
    </div>
  );
};

export default Recommendations;
