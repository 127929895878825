export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_RESET = 'UPDATE_CURRENT_USER_RESET';
export const UPDATE_CURRENT_USER_PENDING = 'UPDATE_CURRENT_USER_PENDING';
export const UPDATE_CURRENT_USER_FULFILLED = 'UPDATE_CURRENT_USER_FULFILLED';
export const UPDATE_CURRENT_USER_REJECTED = 'UPDATE_CURRENT_USER_REJECTED';

export const PROLONG_SESSION = 'PROLONG_SESSION';

export const updateCurrentUser = (data) => ({
  type: UPDATE_CURRENT_USER,
  payload: data,
});

export const resetUpdateCurrentUser = () => ({
  type: UPDATE_CURRENT_USER_RESET,
});

export const updateCurrentUserFulfilled = (data) => ({
  type: UPDATE_CURRENT_USER_FULFILLED,
  payload: data,
});

export const updateCurrentUserRejected = ({ error }) => ({
  type: UPDATE_CURRENT_USER_REJECTED,
  payload: { error },
});

export const prolongSession = () => ({
  type: PROLONG_SESSION,
  payload: { },
});
