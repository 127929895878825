import React, { useEffect, useRef, useState } from 'react';
import styles from './AssessmentTeaserNext.module.scss';

import classNames from 'classnames';

import Big5Image from 'assets/assessments/big5.svg';
import PotentialImage from 'assets/assessments/potential.svg';
import WorkPreferenceImage from 'assets/assessments/work-preference.svg';
import KeyCompetenciesImage from 'assets/assessments/key-competencies.svg';
import LeadershipCompetenciesImage from 'assets/assessments/leadership-competencies.svg';
import RmpImage from 'assets/assessments/rmp.svg';
import NineLevelsImage from 'assets/assessments/9levels.svg';
import CustomSkillsImage from 'assets/assessments/custom-skills.svg';
import BalancedYouImage from 'assets/assessments/balanced-you.svg';
import Peer360Image from 'assets/assessments/peer-360.svg';
import SurveyImage from 'assets/assessments/survey.svg';
import IstImage from 'assets/assessments/ist.svg';
import GarminImage from 'assets/balanced-you/garmin.svg';
import ProgressCircle from 'ui/basic/micro-ui/ProgressCircle';

import { getTranslationIds } from './translationIds';

import { useTranslate } from 'utils/translator';
import { useDebounce, useWindowWidth } from 'utils/hooks';
import { getImageHeight } from 'utils/imageSize';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// eslint-disable-next-line import/no-cycle
import { Button, Card, Modal } from 'ui/basic';

// ASSETS
import { IconsSvg } from 'assets/icons';


const AssessmentTeaserNext = (props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.BIG5,
    title,
    description,
    scope,
    details,
    fullWidth,
    started,
    onModal, // passes learn more modal to this function on button click
    onModalClose,
    peerAssessment,
    showLoopIcon,
    peerProgress,
    isSurvey,
    primaryButton = {},
    secondaryButton,
    tertiaryButton,
  } = props;

  const translate = useTranslate();
  const ref = useRef();

  // STATE
  const [ showModal, setShowModal ] = useState(false);

  const translationIds = getTranslationIds(assessmentType);

  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);

  const [ imageHeight, setImageHeight ] = useState();
  useEffect(() => {
    if (ref && ref.current) {
      setImageHeight(getImageHeight(ref.current.clientWidth));
    }
  }, [ debouncedWindowWidth ]);

  const getImage = () => {
    if (isSurvey) {
      return SurveyImage;
    }

    if (peerAssessment) {
      return Peer360Image;
    }

    const map = {
      [ASSESSMENT_TYPES.BIG5]: Big5Image,
      [ASSESSMENT_TYPES.POTENTIAL]: PotentialImage,
      [ASSESSMENT_TYPES.WORK_PREFERENCES]: WorkPreferenceImage,
      [ASSESSMENT_TYPES.KEY_COMPETENCIES]: KeyCompetenciesImage,
      [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: LeadershipCompetenciesImage,
      [ASSESSMENT_TYPES.RMP]: RmpImage,
      [ASSESSMENT_TYPES.NINE_LEVELS]: NineLevelsImage,
      [ASSESSMENT_TYPES.IST]: IstImage,
      [ASSESSMENT_TYPES.BALANCED_YOU]: BalancedYouImage,
      garmin: GarminImage,
    };

    return map[assessmentType] ?? CustomSkillsImage;
  };

  const translateWithFallback = (key, prop) => translate(translationIds[key] || prop) || prop;

  // RENDER: modal
  const renderModal = () => (
    <Modal
      header={translateWithFallback('infoTitle', title)}
      primaryButtonTitle={started
        ? translate('resume_assessment_lbl')
        : translate('assessment_reminder_md_start_btn')}
      secondaryButtonTitle={translate('close_lbl')}
      onConfirm={primaryButton.onClick}
      onClose={() => {
        setShowModal(false);
        if (onModalClose) {
          onModalClose();
        }
      }}
    >
      { translateWithFallback('infoDetails', details) }
    </Modal>
  );

  return (
    <div
      className={classNames(styles.assessmentTeaser, { [styles.fullWidth]: fullWidth })}
      data-test='AssessmentTeaserNext'
    >
      <Card hasNoPaddings>
        <div className={styles.content}>
          { /* IMAGE */ }
          <div className={classNames(styles.imageBlock, styles[assessmentType])} ref={ref}>
            <img
              alt='teaser'
              height={assessmentType === ASSESSMENT_TYPES.BALANCED_YOU ? imageHeight : '100%'}
              src={getImage()}
            />
          </div>

          { /* TEXT CONTENT */ }
          <div className={styles.textBlock}>
            <div className={classNames(styles.xxsTitle)}>
              { showLoopIcon && <IconsSvg.Loop /> }
              { translateWithFallback('title', title) }
            </div>
            <div className={styles.copy}>
              { translateWithFallback('description', description) }
            </div>

            { /* Progression for Peer assessments */ }
            { peerProgress && (
              <div className={styles.peerProgress}>
                { Object.entries(peerProgress)
                .filter(([ , value ]) => value && value?.invited !== 0)
                .map(([ key, {
                  invited, completed, progress, label, completedAt,
                } ]) => {
                  let progressValue = progress;
                  if (key === 'self') {
                    progressValue = progress === 100 && !completedAt ? 99 : progress;
                  }
                  return (
                    <div key={key}>
                      <ProgressCircle progress={progressValue} />
                      { Boolean(invited) && `${completed}/${invited} ` }
                      { label }
                    </div>
                  );
                }) }
              </div>
            ) }

            <div className={styles.buttons}>
              { /* Primary button */ }
              <Button
                size='S'
                looks='primary'
                onClick={primaryButton.onClick}
              >
                { translate(primaryButton.label) }
              </Button>

              { /* Secondary button */ }
              { secondaryButton && (
                <Button
                  size='S'
                  looks='secondary'
                  onClick={() => {
                    if (secondaryButton.onClick) {
                      secondaryButton.onClick();
                      return;
                    }
                    if (onModal) {
                      onModal(renderModal());
                    } else {
                      setShowModal(true);
                    }
                  }}
                >
                  { translate(secondaryButton.label) }
                </Button>
              ) }

              { /* Tertiary button */ }
              { tertiaryButton && (
                <Button
                  size='S'
                  looks='tertiary'
                  onClick={tertiaryButton.onClick}
                >
                  { translate(tertiaryButton.label) }
                </Button>
              ) }
            </div>

            { /* SCOPE DETAILS */ }
            { !started && (
              <div className={styles.label}>
                { translateWithFallback('scope', scope) }
              </div>
            ) }

          </div>
        </div>
      </Card>

      { showModal && renderModal() }
    </div>
  );
};

export default AssessmentTeaserNext;
