// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionInCompanySince.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputMasked } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { dateIsValid, isTimestampInFuture } from 'utils/dateTools';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionInCompanySince
const QuestionInCompanySince = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ errorMessage, setErrorMessage ] = useState();
  const [ isBlurred, setIsBlurred ] = useState(true);

  const [ inCompanySince, setInCompanySince ] = useState('');
  useEffect(() => {
    setInCompanySince(answer.inCompanySince);
  }, [ answer.inCompanySince ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // EVENT HANDLES
  const handleInCompanySince = (value, valueTimestamp, isValid) => {
    setInCompanySince(value);
    onAnswer({ inCompanySince: value, inCompanySinceTimestamp: valueTimestamp, isValid });
  };

  // RENDER: QuestionGender
  return (
    <div className={classNames(styles.questionInCompanySince)}>

      { /* FORM */ }
      <div className={classNames(styles.formRow)}>
        <InputMasked
          label={translate('cp_subheadline_timeincomp')}
          mask='99/9999'
          value={inCompanySince}
          errorMessage={isBlurred ? errorMessage : undefined}
          placeholder={!isBlurred ? translate('date_mask_monthly') : undefined}
          onFocus={() => setIsBlurred(false)}
          onBlur={() => setIsBlurred(true)}
          onChange={(value) => {
            const splitArray = value.split('/');
            const day = 1;
            const month = splitArray[0] && Number(splitArray[0]);
            const year = splitArray[1] && Number(splitArray[1]);

            let thisErrorMessage = '';
            // -1 for month because month numeration starts with 0
            // 0 - January, 11 - December
            const dateTimestamp = new Date(year, month - 1, day).getTime() / 1000;
            if (!dateIsValid({ month, year })) {
              thisErrorMessage = translate('date_invalid_error_msg');
            } else if (isTimestampInFuture(dateTimestamp)) {
              thisErrorMessage = translate('date_in_future_error_msg');
            } else if (year < 1970) {
              thisErrorMessage = translate('date_old_error_msg');
            }
            setErrorMessage(thisErrorMessage);

            handleInCompanySince(value, dateTimestamp, !thisErrorMessage);
          }}
        />
      </div>

    </div>
  );
};

export default QuestionInCompanySince;
