import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

export const getCompetencyName = (assessmentType, questionIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_${questionIndex}`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `lscomp_${questionIndex}`;
    default:
      return '';
  }
};

export const getCompetencyDescription = (assessmentType, questionIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_descr_${questionIndex}`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `lscomp_descr_${questionIndex}`;
    default:
      return '';
  }
};
