import React, { useState, useEffect, useRef } from 'react';
import styles from './UploadImage.module.scss';

// ASSETS
import { ReactComponent as Plus } from 'assets/icons/plus.svg';

// COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import * as api from 'api';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { uploadUserProfilePicture } from 'store/actions';

// CONFIG
const Config = {
  maxSize: 3145728, // 3145728 bytes = 3mb
  minSide: 100,
  acceptedFormats: 'image/png, image/jpeg, image/bmp, image/gif',
};


// COMPONENT: UploadImage
const UploadImage = (props) => {
  // PROPS
  const {
    altImageText = '',
    text = '',
    handleWithoutStore = false,
    handleWithoutStoreSetLoading = () => {},
  } = props;

  const inputRef = useRef();

  const [ localPicture, setLocalPicture ] = useState();
  const [ showErrorModal, setShowErrorModal ] = useState(false);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const statePicture = useSelector((state) => state.currentUser && state.currentUser.picture);
  useEffect(() => {
    setLocalPicture(statePicture);
  }, [ statePicture ]);

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (file.size > Config.maxSize || !file.type || !Config.acceptedFormats.includes(file.type)) {
      setShowErrorModal(true);
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = (e) => {
      const targetImage = e.target;

      if (targetImage.width < Config.minSide || targetImage.height < Config.minSide) {
        setShowErrorModal(true);
        return;
      }

      const data = new FormData();
      data.append('test', file);
      if (handleWithoutStore) {
        handleWithoutStoreSetLoading(true);
        api.post_binary('core/user/picture', data)
        .then(() => api.get_base64('core/user/picture'))
        .then(({ data: pictureData }) => {
          setLocalPicture(`data:image/jpg;base64,${pictureData}`);
        })
        .finally(() => {
          handleWithoutStoreSetLoading(false);
        });
      } else {
        dispatch(uploadUserProfilePicture(data));
      }
    };
  };

  // RENDER: UploadIMage
  return (
    <div className={styles.uploadImage}>
      <div className={styles.imageContainer}>
        <label htmlFor='upload-image'>
          { localPicture
            ? <img src={localPicture} className={styles.image} alt='user' />
            : <div className={styles.noImage}>{ altImageText.toUpperCase() }</div> }
        </label>
      </div>
      <div className={styles.upload}>
        <label htmlFor='upload-image'>
          <Plus />
          <span>{ text }</span>
        </label>
        <input
          id='upload-image'
          type='file'
          ref={inputRef}
          onChange={handleInputChange}
          onClick={() => {
            const input = inputRef.current;
            if (input) {
              input.value = '';
            }
          }}
          accept={Config.acceptedFormats}
        />
        <div className={styles.validationMessage}>
          { translate('edp_link_edit_profile_picture_validation_msg') }
        </div>
      </div>
      { showErrorModal && (
        <Modal
          secondaryButtonTitle={translate('okay_lbl')}
          header={translate('edp_link_edit_profile_picture_validation_msg_error_title')}
          onClose={() => setShowErrorModal(false)}
        >
          { translate('edp_link_edit_profile_picture_validation_msg_error_content') }
        </Modal>
      ) }
    </div>
  );
};

export default UploadImage;
