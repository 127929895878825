import React, { useCallback, useEffect, useState } from 'react';
import styles from './ShareProfileLinkModal.module.scss';

import * as api from 'api';

import classNames from 'classnames';

import { showToast } from 'store/actions';
import { useDispatch } from 'react-redux';

import { useTranslate } from 'utils/translator';
import { dateHasValidFormat, isTimestampInPast, timestampToFullDate } from 'utils/dateTools';

import { IconsSvg } from 'assets/icons';
import {
  Button, Checkbox, InputMasked, InputNext, InputPassword, Link, Modal, PillGroup,
} from 'ui/basic';

const VIEW_TYPES = {
  CREATE_LINK: 'create link',
  MANAGE_LINKS: 'manage links',
};

const TrashIcon = IconsSvg.Trash;


const ShareProfileLinkModal = (props) => {
  const {
    userId,
    isCandidate,
    passwordRequired,
    passwordNeedsValidation,
    showPersonalLinks,
    showHealthAssessments,
    onClose,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const shareTokenApi = isCandidate ? `recruiting/candidates/${userId}/share` : '/core/user/profile/share';

  const [ view, setView ] = useState(VIEW_TYPES.CREATE_LINK);

  const [ password, setPassword ] = useState();
  const [ passwordCorrect, setPasswordCorrect ] = useState();

  const [ validTo, setValidTo ] = useState();
  const [ validToErrorMessage, setValidToErrorMessage ] = useState();

  const [ description, setDescription ] = useState();
  const [ isAnonymized, setIsAnonymized ] = useState();
  const [ includeProtectedResults, setIncludeProtectedResults ] = useState();

  const [ createProfileLinkProcessing, setCreateProfileLinkProcessing ] = useState();

  const [ deleteModalVisible, setDeleteModalVisible ] = useState();
  const [ linkIdToDelete, setLinkIdToDelete ] = useState();
  const [ deleteProcessing, setDeleteProcessing ] = useState();
  const [ profileLinks, setProfileLinks ] = useState([]);

  const getUserProfileLinks = useCallback(() => {
    api.get(shareTokenApi, { user: userId })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setProfileLinks(data.tokens
        .filter((token) => token.isPersonal === showPersonalLinks)
        .sort((itemA, itemB) => itemB.creationDate - itemA.creationDate)
        .map((token) => ({
          ...token,
          expired: token.validUntil !== -1 && isTimestampInPast(token.validUntil),
          validIndefinitely: token.validUntil === -1,
        })));
      }
    })
    .catch((error) => console.error(error.message));
  }, [ userId, showPersonalLinks, shareTokenApi ]);

  useEffect(() => {
    getUserProfileLinks();
  }, [ getUserProfileLinks ]);

  const handleCandidateShareTokenCreate = () => {
    setCreateProfileLinkProcessing(true);

    let validUntil;
    if (validTo) {
      const splitArray = validTo.split('.');
      // -1 for month because month numeration starts with 0
      // 0 - January, 11 - December
      validUntil = (new Date(splitArray[2], splitArray[1] - 1, splitArray[0], 23, 59, 59, 0).getTime()) / 1000;
    } else {
      validUntil = -1;
    }

    const queryParams = userId ? `user=${userId}` : '';

    api.post(`${shareTokenApi}?${queryParams}`, {
      password,
      validUntil,
      description,
      anonymous: !!isAnonymized,
    })
    .then(({ ok, status, data }) => {
      setCreateProfileLinkProcessing(false);

      if (ok && status === 200) {
        setView(VIEW_TYPES.MANAGE_LINKS);
        getUserProfileLinks();
        navigator.clipboard.writeText(`${window.location.origin}/share/${data.id}`);

        const toastHeader = translate('share_profile_link_created_toast_header');
        const toastContent = translate('share_profile_link_created_toast_content');
        dispatch(showToast(toastHeader, toastContent));
      }
    })
    .catch((error) => {
      setCreateProfileLinkProcessing(false);
      console.error(error.message);
    });
  };

  const handleEmployeeShareTokenCreate = () => {
    setCreateProfileLinkProcessing(true);

    let validUntil;
    if (validTo) {
      const splitArray = validTo.split('.');
      // -1 for month because month numeration starts with 0
      // 0 - January, 11 - December
      validUntil = (new Date(splitArray[2], splitArray[1] - 1, splitArray[0], 23, 59, 59, 0).getTime()) / 1000;
    } else {
      validUntil = -1;
    }

    const queryParams = userId ? `user=${userId}` : '';

    api.put(`${shareTokenApi}?${queryParams}`, {
      password,
      validUntil,
      description,
      anonymous: !!isAnonymized,
      includeProtectedResults,
    })
    .then(({ ok, status, data }) => {
      setCreateProfileLinkProcessing(false);

      if (ok && status === 200) {
        setView(VIEW_TYPES.MANAGE_LINKS);
        getUserProfileLinks();
        navigator.clipboard.writeText(`${window.location.origin}/share/${data.id}`);

        const toastHeader = translate('share_profile_link_created_toast_header');
        const toastContent = translate('share_profile_link_created_toast_content');
        dispatch(showToast(toastHeader, toastContent));
      }
    })
    .catch((error) => {
      setCreateProfileLinkProcessing(false);
      console.error(error.message);
    });
  };

  return (
    <div>
      <Modal
        header={view === VIEW_TYPES.CREATE_LINK
          ? (translate('share_profile_create_link_title'))
          : (translate('share_profile_manage_links_title'))}
        preHeader={(
          <PillGroup
            pillItems={[
              { id: VIEW_TYPES.CREATE_LINK, label: (translate('share_profile_create_link_title')) },
              { id: VIEW_TYPES.MANAGE_LINKS, label: (translate('share_profile_manage_links_title')) },
            ]}
            activeIndex={view === VIEW_TYPES.CREATE_LINK ? 0 : 1}
            onPillClick={(pillItem) => {
              if (view === pillItem.id) {
                return;
              }

              setPassword(undefined);
              setValidTo(undefined);
              setDescription(undefined);
              setIsAnonymized(undefined);
              setIncludeProtectedResults(undefined);

              setView(pillItem.id);
            }}
          />
        )}
        primaryButtonTitle={view === VIEW_TYPES.CREATE_LINK
        && (translate('share_profile_create_link_btn'))}
        primaryButtonDisabled={(passwordRequired && (!password || !passwordCorrect))
        || ((validTo && validTo.length < 10) || validToErrorMessage) || createProfileLinkProcessing}
        secondaryButtonTitle={translate('close_lbl')}
        closeOnConfirm={false}
        onClose={onClose}
        onConfirm={isCandidate ? handleCandidateShareTokenCreate : handleEmployeeShareTokenCreate}
      >
        <div className={styles.shareProfileLinkModalContent}>
          { /* CREATE EXTERNAL PROFILE LINK */ }
          { view === VIEW_TYPES.CREATE_LINK && (
            <>
              <div className={styles.copy}>
                { translate('share_profile_create_link_copy') }
              </div>

              <div className={styles.label}>
                { translate('share_profile_create_link_label') }
              </div>

              <div className={styles.inputs}>
                <InputPassword
                  placeHolder={passwordRequired ? (translate('share_profile_mandatory_password_placeholder'))
                    : (translate('share_profile_optional_password_placeholder'))}
                  showCriteria={!!passwordNeedsValidation}
                  hideCriteriaOnBlur={false}
                  onInputChanged={(value, correct) => {
                    setPassword(value || undefined);
                    setPasswordCorrect(correct);
                  }}
                />
                <InputMasked
                  label={translate('share_profile_validto_placeholder')}
                  hint={translate('share_profile_validto_hint')}
                  mask='99.99.9999'
                  errorMessage={validToErrorMessage}
                  onChange={(value) => {
                    if (!value) {
                      setValidTo(undefined);
                      setValidToErrorMessage('');
                      return;
                    }

                    const splitArray = value.split('.');
                    const day = splitArray[0] && Number(splitArray[0]);
                    const month = splitArray[1] && Number(splitArray[1]);
                    const year = splitArray[2] && Number(splitArray[2]);

                    if (!dateHasValidFormat(value)) {
                      setValidToErrorMessage(translate('share_profile_validto_invalid_format_msg'));
                    // -1 for month because month numeration starts with 0
                    // 0 - January, 11 - December
                    } else if (isTimestampInPast(new Date(year, month - 1, day).getTime() / 1000)) {
                      setValidToErrorMessage(translate('share_profile_validto_date_in_past_msg'));
                    } else {
                      setValidToErrorMessage('');
                    }

                    setValidTo(value);
                  }}
                />
                <InputNext
                  label={translate('share_profile_description_placeholder')}
                  onChange={(value) => setDescription(value)}
                />
                <Checkbox
                  checked={isAnonymized}
                  name={translate('share_profile_anonymize_checkbox_name')}
                  onChange={(value) => setIsAnonymized(value)}
                  disabled={includeProtectedResults}
                />
                { showHealthAssessments && (
                  <Checkbox
                    name={translate('share_profile_protected_checkbox_name')}
                    onChange={(value) => {
                      setIncludeProtectedResults(value);
                      if (value) {
                        setIsAnonymized(value);
                      }
                    }}
                  />
                ) }
              </div>
            </>
          ) }

          { /* MANAGE PROFILE LINKS */ }
          { view === VIEW_TYPES.MANAGE_LINKS && (
            <div className={styles.rows}>
              { profileLinks.length === 0 && (translate('share_profile_no_links')) }

              { profileLinks.length > 0 && profileLinks.map((profileLink) => (
                <div className={styles.row} key={profileLink.id}>
                  <div className={styles.line1}>
                    <Link
                      to={`${window.location.origin}/share/${profileLink.id}`}
                      openInNewTab
                      active={!profileLink.expired}
                    >
                      { `${window.location.origin}/share/${profileLink.id} ${
                        profileLink.expired
                          ? `(${(translate('expired_lbl'))})`
                          : ''}` }
                    </Link>

                    <div
                      role='presentation'
                      className={styles.trashIcon}
                      onClick={() => {
                        setLinkIdToDelete(profileLink.id);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <TrashIcon />
                    </div>
                    <Button
                      size='S'
                      looks='secondary'
                      disabled={profileLink.expired}
                      onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}/share/${profileLink.id}`);
                      }}
                    >
                      { translate('copy_lbl') }
                    </Button>
                  </div>

                  <div className={styles.line2}>
                    <span>
                      { translate('share_profile_created_at') }
                      { ' ' }
                      { timestampToFullDate(profileLink.creationDate) }
                    </span>
                    { !profileLink.validIndefinitely && (
                      <span className={classNames({ [styles.expired]: profileLink.expired })}>
                        { translate('share_profile_valid_until') }
                        { ' ' }
                        { timestampToFullDate(profileLink.validUntil) }
                      </span>
                    ) }
                    { profileLink.isPasswordProtected && (
                      <span>{ translate('share_profile_pwd_protected') }</span>
                    ) }
                    <span>
                      { translate('share_profile_anonymization') }
                      { profileLink.isAnonymous
                        ? translate('active_lbl')
                        : translate('inactive_lbl') }
                    </span>

                    { profileLink.description && (
                      <span>
                        { translate('description_lbl') }
                        { ' ' }
                        { profileLink.description }
                      </span>
                    ) }

                    { profileLink.includeProtectedResults && (
                      <span>
                        { translate('share_profile_protected') }
                      </span>
                    ) }
                  </div>
                </div>
              )) }

            </div>
          ) }

        </div>
      </Modal>

      { deleteModalVisible && (
        <Modal
          header={translate('share_profile_delete_link_modal_header')}
          secondaryButtonTitle={translate('cancel_lbl')}
          redButtonTitle={translate('delete_lbl')}
          redButtonDisabled={deleteProcessing}
          closeOnConfirm={false}
          onClose={() => setDeleteModalVisible(false)}
          onConfirm={() => {
            setDeleteProcessing(true);

            api._delete(`${shareTokenApi}/${linkIdToDelete}`)
            .then(({ ok, status }) => {
              setDeleteProcessing(false);

              if (ok && status === 200) {
                setDeleteModalVisible(false);
                getUserProfileLinks();

                const toastHeader = translate('share_profile_link_deleted_toast_header');
                const toastContent = translate('share_profile_link_deleted_toast_content');
                dispatch(showToast(toastHeader, toastContent));
              }
            })
            .catch((error) => {
              setDeleteProcessing(false);
              console.error(error.message);
            });
          }}
        >
          { translate('share_profile_delete_link_modal_content') }
        </Modal>
      ) }
    </div>
  );
};

export default ShareProfileLinkModal;
