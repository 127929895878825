import React from 'react';
import './index.scss';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';

import { Main } from 'features/framework/components/Main';

const App = () => (
  <Router>
    <Provider store={store}>
      <Main />
    </Provider>
  </Router>
);

export default App;
