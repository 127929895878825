// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PlanExpired.module.scss';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: PlanExpired
const PlanExpired = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();


  // RENDER: PlanExpired
  return (
    <div className={styles.planExpired}>
      <div className='bluContent'>
        <h4>{ translate('access_deactivated') }</h4>
        <div className='bluTypeCopyStrong marginTopXs'>
          { translate('access_deactivated_copy') }
        </div>
      </div>
    </div>
  );
};

export default PlanExpired;
