// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AttachmentsCard.module.scss';
import { CollapsibleNext, Button, DetailsPageSkeleton } from 'ui/basic';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from "classnames";

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: CandidateAttachmentsCard
const AttachmentsCard = (props) => {
  // PROPS
  const {
    attachments,
    isLoading = false,
    readOnly = false,
    onFileClick = () => {},
    onAddEditClick = () => {},
  } = props;

  // SPECIAL HOOKS

  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const onAttachmentClick = (e, attachment) => {
    if (attachment.type === 'file') {
      onFileClick(attachment);
      e.preventDefault();
    }
  };

  const renderItem = (attachment) => {
    const { type } = attachment;
    let Icon;

    if (type === 'file') {
      Icon = IconsSvg.Document;
    } else if (type === 'link') {
      Icon = IconsSvg.ExternalLink;
    }

    return (
      <li className={styles[type]} key={attachment.id}>
        <a
          href={attachment.url || `/download/${attachment.id}`}
          target='_blank'
          rel='noreferrer'
          title={attachment.name || attachment.url}
          onClick={(e) => onAttachmentClick(e, attachment)}
        >
          <Icon />
          <span>{ attachment.name || attachment.url }</span>
        </a>
      </li>
    );
  };

  const renderList = () => {
    if (isLoading) {
      return <DetailsPageSkeleton />;
    }

    if (!attachments?.length) {
      return (
        <div>
          { translate('candidate_attachments_nothing_added') }
        </div>
      );
    }

    return (
      <ul className={styles.list}>
        { attachments
        .filter((a) => a.type === 'link')
        .map((link) => renderItem(link)) }

        { attachments.filter((a) => a.type === 'file').map((doc) => renderItem(doc)) }
      </ul>
    );
  };

  if (readOnly && !attachments?.length) {
    return null;
  }

  // RENDER: AttachmentsCard
  return (
    <div className={styles.attachmentsCard}>
      <CollapsibleNext
        header={translate('candidate_attachments_card_header')}
        headerClassName={styles.header}
        withBorders
        isOpenOverride
      >
        { renderList() }

        { !readOnly && (
          <div className={styles.addButton}>
            <Button
              looks='secondary-transparent'
              size='s'
              onClick={onAddEditClick}
            >
              { translate('candidate_attachments_add_edit') }
            </Button>
          </div>
        ) }

      </CollapsibleNext>
    </div>
  );
};

export default AttachmentsCard;
