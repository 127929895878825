// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './GarminIcon.module.scss';

// ASSETS
import { ReactComponent as BodyBattery } from 'assets/balanced-you/garmin/body-battery.svg';
import { ReactComponent as Sleep } from 'assets/balanced-you/garmin/sleep.svg';
import { ReactComponent as Stress } from 'assets/balanced-you/garmin/stress.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminIcon
const GarminIcon = (props) => {
  // PROPS
  const { size = 'L', looks, scoreClass } = props;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const renderIcon = () => {
    let color;
    if (scoreClass === 'bad') {
      color = 'red';
    } else if (scoreClass === 'medium-bad') {
      color = 'lightRed';
    } else if (scoreClass === 'medium-good') {
      color = 'lightBlue';
    } else if (scoreClass === 'good') {
      color = 'blue';
    } else {
      color = 'gray';
    }

    switch (looks) {
      case 'body-battery':
        return (
          <BodyBattery className={classNames(styles.garminIcon, styles[`size${size.toUpperCase()}`], styles[color])} />
        );
      case 'sleep':
        return (
          <Sleep className={classNames(styles.garminIcon, styles[`size${size.toUpperCase()}`], styles[color])} />
        );
      case 'stress':
        return (
          <Stress className={classNames(styles.garminIcon, styles[`size${size.toUpperCase()}`], styles[color])} />
        );
      default:
    }
  };

  // RENDER: GarminIcon
  return renderIcon();
};

export default GarminIcon;
