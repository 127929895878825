// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './ParticipantsModal.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  DropDownSubtle,
  ImgCircle,
  InputNext, Modal, Pagination, SkeletonFlexible,
} from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { MIN_SEARCH_LENGTH } from 'api';
import { useDebounce, useLatestCall } from 'utils/hooks';

// STORE
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions';

// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  paginationLimit: 5,
};

// COMPONENT: ParticipantsModal
const ParticipantsModal = (props) => {
  // PROPS
  const {
    entity = {},
    selectedRun = {},
    isSurvey = false,
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const basePath = isSurvey ? '/surveys' : '/core/assessments';

  const [ pageIndex, setPageIndex ] = useState(0);
  const [ sortBy, setSortBy ] = useState('status');
  const [ sortDirection, setSortDirection ] = useState('asc');
  const [ sortIndex, setSortIndex ] = useState(0);
  const [ searchString, setSearchString ] = useState('');
  const debouncedSearchString = useDebounce(searchString, 300);

  const sortOptions = [
    {
      value: 'status_asc',
      label: translate('iteration_scope_sort_completed_asc'),
      sortBy: 'status',
      sortDirection: 'asc',
    },
    {
      value: 'status_desc',
      label: translate('iteration_scope_sort_completed_desc'),
      sortBy: 'status',
      sortDirection: 'desc',
    },
    {
      value: 'name_asc',
      label: translate('iteration_scope_sort_name_asc'),
      sortBy: 'name',
      sortDirection: 'asc',
    },
    {
      value: 'name_desc',
      label: translate('iteration_scope_sort_name_desc'),
      sortBy: 'name',
      sortDirection: 'desc',
    },
  ];

  const [
    participantsSearchLoading,
    participantsSearchResults = {},
    setParticipantsSearchString,
    participantsCount,
  ] = useLatestCall(
    `${basePath}/${entity.id}/run/${selectedRun.id}/participation`,
    useCallback((response) => response, []),
    {
      expand: 'user',
      limit: Config.paginationLimit,
      offset: pageIndex * Config.paginationLimit,
      search: debouncedSearchString,
      sort: sortBy,
      sortOrder: sortDirection,
    },
    false,
  );

  const { participants = [] } = participantsSearchResults;

  useEffect(() => {
    if (debouncedSearchString.length >= MIN_SEARCH_LENGTH || debouncedSearchString === '') {
      setParticipantsSearchString(debouncedSearchString);
      setPageIndex(0);
    }
  }, [
    debouncedSearchString,
    setParticipantsSearchString,
  ]);

  const [ reminderSentTo, setReminderSentTo ] = useState({});
  const [ reminderProcessing, setReminderProcessing ] = useState(false);
  const handleSendReminder = (target) => {
    setReminderProcessing(true);

    const payload = isSurvey
      ? { participants: target ? [ target ] : 'all' }
      : { users: target ? [ target ] : 'all' };

    api.post(`${basePath}/${entity.id}/run/${selectedRun.id}/invite`, payload)
    .then(({ ok, data = {} }) => {
      if (ok) {
        const reminderSentToInternal = { ...reminderSentTo };
        reminderSentToInternal[target || 'all'] = true;
        setReminderSentTo(reminderSentToInternal);

        const toastTitle = translate('iteration_scope_reminder_sent_title');
        const toastContent = translate('iteration_scope_reminder_sent_copy');
        dispatch(showToast(toastTitle, toastContent));
      } else {
        console.error(data.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
    })
    .finally(() => {
      setReminderProcessing(false);
    });
  };

  // RENDER: ParticipantsModal
  return (
    <Modal
      header={translate('iteration_scope')}
      primaryButtonTitle={translate('peer_360_group_manage_send_reminder_all')}
      primaryButtonIcon={IconsSvg.Send}
      primaryButtonDisabled={reminderProcessing || Boolean(reminderSentTo.all)}
      secondaryButtonTitle={translate('close_lbl')}
      onClose={onClose}
      onConfirm={handleSendReminder}
      closeOnConfirm={false}
      actionOnEnter={false}
    >
      <div className={classNames(styles.participantsModal)}>
        <span className='bluTypeCopy'>
          { translate(`iteration_scope_${isSurvey ? 'survey' : 'assessment'}_copy`) }
        </span>

        <div className={styles.controls}>
          <InputNext
            placeholder={translate('iteration_scope_search_participant')}
            icon='SearchBig'
            onChange={setSearchString}
          />

          <div className='marginTopXxs'>
            <DropDownSubtle
              className='alignRight'
              options={sortOptions}
              activeIndex={sortIndex}
              fullWidthForXs={false}
              onChange={(newIndex) => {
                const sortItem = sortOptions[newIndex];
                setSortBy(sortItem.sortBy);
                setSortDirection(sortItem.sortDirection);
                setSortIndex(newIndex);
              }}
            >
              { translate('table_sort_by') }
            </DropDownSubtle>
          </div>
        </div>

        { participantsSearchLoading && (
          <SkeletonFlexible noHeader repeat={5} />
        ) }

        { (searchString && participants.length === 0) && (
          <div className='bluTypeCopy'>{ translate('iteration_scope_no_participants_found') }</div>
        ) }

        { (!participantsSearchLoading && participants.length > 0) && (
          <div className={styles.list}>
            { participants.map((participant) => {
              const {
                user,
                email,
                completed,
                participationId,
              } = participant;
              const target = participationId || user?.mail || email;

              return (
                <div
                  key={user?.id || email}
                  className={styles.listItem}
                >
                  <div className={styles.left}>
                    { user && (
                      <>
                        <ImgCircle
                          fullLabel={getFullName(user)}
                          src={api.getUserImageUrl(user.id)}
                          size='S'
                        />
                        <div className={styles.textContent}>
                          <div>{ getFullName(user) }</div>
                          <div>{ user.position || '-' }</div>
                        </div>
                      </>
                    ) }

                    { !user && (
                      <span className='bluTypeLabelL'>
                        { email }
                      </span>
                    ) }
                  </div>

                  <div className={classNames(styles.right, { [styles.internalUser]: !!user })}>
                    <div className={completed ? 'bluTypeCopy' : styles.notCompleted}>
                      { completed
                        ? translate('table_status_completed')
                        : translate('not_completed') }
                    </div>

                    { !completed && (
                      <div
                        role='presentation'
                        className={classNames(styles.reminder, {
                          [styles.disabled]: reminderProcessing
                          || Boolean(reminderSentTo[target])
                          || Boolean(reminderSentTo.all),
                        })}
                        onClick={() => handleSendReminder(target)}
                      >
                        <IconsSvg.Send />
                        <span>{ translate('peer_360_group_manage_send_reminder') }</span>
                      </div>
                    ) }
                  </div>
                </div>
              );
            }) }
          </div>
        ) }

        { /* PAGINATION */ }
        { participantsCount > Config.paginationLimit && (
          <Pagination
            pageCount={Math.ceil(participantsCount / Config.paginationLimit)}
            pagePosition={pageIndex}
            showPagerButtons
            showNumbers
            onPagePositionUpdate={setPageIndex}
          />
        ) }

      </div>
    </Modal>
  );
};

export default ParticipantsModal;
