// REACT, STYLE, STORIES & COMPONENT
import React from 'react';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// COMPONENT: Paragraph
const Paragraph = (props) => {
  // PROPS
  const { content = '', replacements = {} } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const replacementsList = Object.entries(replacements)
  .map(([ key, value ]) => [ `{{${key}}}`, value ])
  .flat();
  const translatedString = translate(content, replacementsList);

  const mappedString = typeof translatedString === 'string'
    ? `[blu-markdown] ${translatedString}`
    : translatedString;

  // RENDER: Paragraph
  return (
    <>
      { markdown(mappedString) }
    </>
  );
};

export default Paragraph;
