// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './OverlayModal.module.scss';

// ASSETS
import { Icons } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Icon } from 'ui/basic';

// UTILS
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: OverlayModal
const OverlayModal = (props) => {
  // PROPS
  const {
    header,
    children,
    footer,
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    };
  }, []);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: OverlayModal
  return (
    <div className={classNames(styles.overlayModal)}>
      { /* HEADER */ }
      <div className={styles.header}>
        <span>{ header }</span>
        <div
          role='presentation'
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>

      { /* BODY */ }
      <div
        id='overlay-modal-scrollable-content'
        className={classNames(styles.scrollableContent, {
          [styles.hasFooter]: Boolean(footer),
        })}
      >
        { children }
      </div>

      { /* FOOTER */ }
      { footer && (
        <div className={styles.footer}>
          { footer }
        </div>
      ) }

    </div>
  );
};

export default OverlayModal;
