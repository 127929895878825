import * as fromActionTypes from 'store/actionTypes/signUp';

const initialState = {
  registeredSuccessfully: false,
  registrationEmailSentSuccessfully: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.INIT_SIGN_UP:
      return {
        ...state,
        registeredSuccessfully: false,
        error: null,
      };
    case fromActionTypes.SIGN_UP:
      return {
        ...state,
      };
    case fromActionTypes.SIGN_UP_FULFILLED:
      return {
        ...state,
        registeredSuccessfully: true,
        error: null,
      };
    case fromActionTypes.SIGN_UP_REJECTED:
      return {
        ...state,
        ...action.payload,
      };
    case fromActionTypes.SEND_SIGN_UP_EMAIL_FULFILLED:
      return {
        ...state,
        registrationEmailSentSuccessfully: true,
        error: null,
      };
    default:
      return state;
  }
};
