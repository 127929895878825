// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useCallback,
  forwardRef, useImperativeHandle,
} from 'react';
import styles from './Pagination.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';

// OTHER COMPONENTS
import { ButtonCircular } from 'ui/basic/forms/ButtonCircular';

// UTILS
import { pxToNumber } from 'utils/styleTools';

// CONFIG & DATA

// COMPONENT: Pagination
const Pagination = (props, ref) => {
  // PROPS
  const {
    showPaginationBubbles,
    showPagerButtons, showNumbers, extraMargins, disabled,
    pageCount,
    pagePosition,
    onPagePositionUpdate = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ paginationLeftAvailable, setPaginationLeftAvailable ] = useState(true);
  const [ paginationRightAvailable, setPaginationRightAvailable ] = useState(true);

  // STORE HOOKS

  // EFFECT HOOKS
  const pagePositionUpdate = useCallback((newPagePosition, pageToLeft) => {
    let paginationLeftAvailableInternal = true;
    let paginationRightAvailableInternal = true;
    let thisPagePosition = newPagePosition;

    if (newPagePosition <= 0) {
      thisPagePosition = 0;
      paginationLeftAvailableInternal = false;
    }

    if (newPagePosition >= pageCount - 1) {
      thisPagePosition = pageCount - 1;
      paginationRightAvailableInternal = false;
    }
    setPaginationLeftAvailable(paginationLeftAvailableInternal);
    setPaginationRightAvailable(paginationRightAvailableInternal);

    onPagePositionUpdate(
      thisPagePosition,
      paginationLeftAvailableInternal,
      paginationRightAvailableInternal,
      pageToLeft,
    );
  }, [ pageCount, onPagePositionUpdate ]);

  // update pageAvailabilities based on pagePosition (& pageCount via pagePositionUpdate function)
  // pagePositionUpdate(pagePosition);
  useEffect(() => {
    // console.log('effect:pagePosition', pagePosition);
    pagePositionUpdate(pagePosition);
  }, [ pagePosition, pagePositionUpdate ]);

  // OTHER HOOKS
  useImperativeHandle(ref, () => ({
    pagePositionUpdate,
  }));

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS
  const renderPaginationBubbles = () => {
    const bubbles = [];

    for (let i = 0; i < pageCount; i += 1) {
      const indexDistance = Math.abs(pagePosition - i);
      bubbles.push(
        <div
          key={i}
          className={classnames({
            [styles.bubbleBox]: !showNumbers,
            [styles.numberBox]: showNumbers,
          })}
          role='presentation'
          onClick={() => pagePositionUpdate(i, i < pagePosition)}
        >
          { /* bubbles with overflow */ }
          <div className={classnames({
            [styles.bubble]: !showNumbers,
            [styles.number]: showNumbers,
            [styles.active]: pagePosition === i,
            [styles.medium]: showNumbers
              ? indexDistance === 1
              : indexDistance <= 3 && indexDistance >= 1,
            [styles.small]: showNumbers
              ? indexDistance === 2
              : indexDistance === 4,
            [styles.tiny]: showNumbers
              ? indexDistance === 3
              : indexDistance === 5,
          })}
          >
            { ' ' }
            { showNumbers && i + 1 }
          </div>
        </div>,
      );
    }

    const bubbleWidth = showNumbers ? styles.numberWidth : styles.bubbleWidth;
    const scrollOffset = pxToNumber(styles.bubbleContainerWidth) / 2
      - (pxToNumber(bubbleWidth) / 2)
      - (pagePosition * pxToNumber(bubbleWidth)); /* start offset + dynamic offset */

    return (
      <div className={styles.bubblesContainer}>
        { /* scroll container for bubble overflow */ }
        <div
          className={classnames(styles.bubblesScroll, {
            [styles.hidden]: pageCount <= 1 || disabled,
          })}
          style={{ transform: `translateX(${scrollOffset}px)` }}
        >
          { bubbles }
        </div>
      </div>
    );
  };

  // RENDER: Pagination
  return (
    <div className={`${styles.pagination}`}>

      { /* PAGER BUTTON LEFT */ }
      { showPagerButtons && (
        <div
          className={styles.pagerButtonLeft}
          style={{ paddingLeft: `${extraMargins - 40 / 2 /* ButtonCircular / 2 */}px` }}
        >
          <ButtonCircular
            looks='primary'
            icon='ArrowLeft'
            onClick={() => { pagePositionUpdate(pagePosition - 1, true); }}
            disabled={!paginationLeftAvailable}
          />
        </div>
      ) }

      { /* BUBBLES */ }
      { (showPaginationBubbles || showNumbers) && (
        <div className={styles.bubbles}>
          { renderPaginationBubbles() }
        </div>
      ) }

      { /* PAGER BUTTON RIGHT */ }
      { showPagerButtons && (
        <div
          className={styles.pagerButtonRight}
          style={{ paddingRight: `${extraMargins - 40 / 2 /* ButtonCircular / 2 */}px` }}
        >
          <ButtonCircular
            looks='primary'
            icon='ArrowRight'
            onClick={() => { pagePositionUpdate(pagePosition + 1); }}
            disabled={!paginationRightAvailable}
          />
        </div>
      ) }

    </div>
  );
};

export default forwardRef(Pagination);
