import React, { memo, useEffect, useState } from 'react';
import './DropDown.scss';
import Select from 'react-select';
import classnames from 'classnames';

const DropDown = memo((props) => {
  const {
    size = 'S',
    selectedOption,
    placeholder = 'Choose an option',
    options,
    disabled = false,
    hint,
    errorMessage,
    optionLabel = 'label',
    optionValue = 'value',
    onChange,
  } = props;

  const [ selectedValue, setSelectedValue ] = useState(selectedOption);
  const classNames = classnames(
    'react-select__root',
    `size${size.toUpperCase()}`,
    { disabled },
    { error: errorMessage },
  );

  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [ selectedOption ]);

  const handleChange = (option) => {
    setSelectedValue(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className={classNames}>
      <Select
        className='react-select-outer-component'
        isSearchable={false}
        placeholder={placeholder}
        classNamePrefix='react-select'
        options={options}
        value={selectedValue}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionValue={(option) => option[optionValue]}
        onChange={handleChange}
      />
      { (hint && !errorMessage) && <div className='hint'>{ hint }</div> }
      { errorMessage && <span className='error-message'>{ errorMessage }</span> }
    </div>
  );
});

export default DropDown;
