import React, { useEffect, useMemo, useState } from 'react';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { setDecimalSeparator } from 'utils/userpreference';
import {
  resultIsStrong,
  resultIsWeak,
  RMP_FIRST_MARKER_POSITION,
} from 'utils/rmp';
import { filterHiddenTeamResults } from 'utils/assessment';

import CompetenciesResultSkeleton from 'ui/molecules/CompetenciesResult/CompetenciesResultSkeleton';
import RmpResultTable from 'ui/molecules/RmpResult/RmpResultTable';
import {
  Callout, Card, ImgCircle, InfoTitle, Modal, BipolarDiagram, TeamDiagramBipolarNext,
} from 'ui/basic';
import styles from './TeamRmpResult.module.scss';


const TeamRmpResult = (props) => {
  // PROPS
  const { profile, referenceProfile } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const resultsFiltered = useMemo(() => (
    filterHiddenTeamResults(profile?.results)
  ), [ profile?.results ]);

  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalHeaderPosition, setModalHeaderPosition ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalPreHeader, setModalPreHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalData, setModalData ] = useState();

  const [ currentDimension, setCurrentDimension ] = useState({ id: 'wp_1', name: 'test dimension' });
  const [ assessmentDimensionResults, setAssessmentDimensionResults ] = useState();

  // METHODS
  const getDimensionsData = () => {
    if (!resultsFiltered) {
      return [];
    }

    const rmpDimensions = [];

    resultsFiltered.forEach((rmpResult) => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find((r) => r.dimensionId === rmpResult.id);
        if (thisResult) {
          range = [ thisResult.minValue, thisResult.maxValue ];
        }
      }

      rmpDimensions.push({
        id: rmpResult.id,
        label: rmpResult.name,
        range,
        data: rmpResult.values.map((userValue) => {
          const { user, value } = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value,
          };
        }),
      });
    });

    return rmpDimensions;
  };

  const getSubHeader = (value, userName, dimensionName) => {
    if (resultIsStrong(value)) {
      return translate(
        'rmp_dimension_strong',
        [ '{{dimension}}', dimensionName, '{{username}}', userName ],
      );
    } if (resultIsWeak(value)) {
      return translate(
        'rmp_dimension_weak',
        [ '{{dimension}}', dimensionName, '{{username}}', userName ],
      );
    }
    return translate(
      'rmp_dimension_average',
      [ '{{dimension}}', dimensionName, '{{username}}', userName ],
    );
  };

  const getCalloutText = (value, dimensionId) => {
    if (resultIsStrong(value)) {
      return translate(`rmp_report_${dimensionId}_strong_details`);
    } if (resultIsWeak(value)) {
      return translate(`rmp_report_${dimensionId}_weak_details`);
    }
    return translate(`rmp_report_${dimensionId}_average_details`);
  };

  useEffect(() => {
    if (!resultsFiltered) {
      return;
    }

    setCurrentDimension({ id: resultsFiltered[0].id, name: resultsFiltered[0].name });
  }, [ resultsFiltered ]);

  useEffect(() => {
    if (!resultsFiltered) {
      return;
    }

    setAssessmentDimensionResults(resultsFiltered.find((r) => r.id === currentDimension.id));
  }, [ profile, currentDimension ]);

  const renderModalContent = (resultItem) => {
    const { value, dimensionId } = resultItem;

    return (
      <div className={styles.infoModalResult}>
        <div className={styles.reportDiagram}>
          <div className={styles.header}>
            <span>{ translate('big5_report_facet_result') }</span>
            <span>{ setDecimalSeparator(value, 2) }</span>
          </div>

          <BipolarDiagram
            score={value}
            showMarkers
          />

          <div className={styles.footer}>
            <span className={value < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
              { translate('rmp_results_scale_min') }
            </span>
            <span className={value > RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
              { translate('rmp_results_scale_max') }
            </span>
          </div>
        </div>

        <div className={styles.callout}>
          <Callout trianglePosition='center'>
            { getCalloutText(value, dimensionId) }
          </Callout>
        </div>

        <RmpResultTable
          dimensionId={dimensionId}
          score={value}
        />
      </div>
    );
  };

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData?.value) {
      return;
    }

    setModalContent(renderModalContent(modalData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.value ]);

  if (!assessmentDimensionResults) {
    return <CompetenciesResultSkeleton />;
  }

  return (
    <Card>
      <InfoTitle
        title={translate('rmp_results_title')}
        onClick={() => {
          setModalHeader(translate('rmp_results_info_title'));
          setModalHeaderPosition('left');
          setModalSubHeader(null);
          setModalPreHeader(null);
          setModalContent(translate('rmp_results_info_description'));

          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBipolarNext
          labelLeft={translate('rmp_report_chart_tl_l')}
          labelCenter={translate('rmp_report_chart_tl_c')}
          labelRight={translate('rmp_report_chart_tl_r')}
          grid={[ -1.7, -0.84, 0.84, 1.7 ]}
          valueRange={[ -2, 2 ]}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: `)}
          onItemClick={(item) => {
            const userName = item.label;
            const userImage = item.img;
            const { value } = item;
            const { dimensionId } = item;
            const { dimensionName } = item;

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeader(value, userName, dimensionName));
            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size='M'
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />,
            );

            setModalData({ value, dimensionId });
            setShowModal(true);
          }}
        />
      </div>

      <div className={styles.copyright}>
        <span
          role='presentation'
          onClick={() => {
            setModalHeader(translate('rmp_results_info_title_copyright'));
            setModalSubHeader(null);
            setModalPreHeader(null);
            setModalContent(translate('rmp_results_info_description_copyright'));

            setShowModal(true);
          }}
        >
          { translate('rmp_results_copyrightlink') }
        </span>
      </div>

      { showModal && (
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          subHeader={modalSubHeader}
          subHeaderPosition='center'
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onClose={() => {
            setShowModal(false);
            setModalData();
          }}
          onConfirm={() => {
            setShowModal(false);
            setModalData();
          }}
        >
          { modalContent }
        </Modal>
      ) }
    </Card>
  );
};

export default TeamRmpResult;
