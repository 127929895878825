import React, { memo } from 'react';
import styles from './Link.module.scss';
import classnames from 'classnames';
import { ReactComponent as Arrow } from 'assets/icons/icn_arrow_anchor.svg';

const Link = memo((props) => {
  const {
    children, to, type, active = true, openInNewTab, onClick,
  } = props;

  const classNames = classnames(
    styles.link,
    styles[type],
    { [styles.inactive]: !active },
  );

  const linkProps = {
    href: to,
    className: classNames,
    onClick,
  };

  if (openInNewTab) {
    Object.assign(linkProps, { target: '_blank' });
  }

  return (
    <a {...linkProps}>
      <span>{ children }</span>
      { type === 'anchor' && <Arrow /> }
    </a>
  );
});

export default Link;
