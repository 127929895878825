// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './BodyBatteryInsights.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card, SkeletonFlexible } from 'ui/basic';
import { GarminTrend } from 'features/+garmin/ui/basic/GarminTrend';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: BodyBatteryInsights
const BodyBatteryInsights = (props) => {
  // PROPS
  const { userScore } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const bodyBatteryClass = userScore?.bodyBattery?.score?.class;
  const sleepClass = userScore?.sleep?.score?.class;
  const stressClass = userScore?.stress?.score?.class;

  // RENDERS
  const renderInsightBox = () => (
    <>
      <span className='bluTypeLabel'>
        { translate(`body_battery_insight_${bodyBatteryClass}`) }
      </span>
      { (sleepClass || stressClass) && (
        <>
          <div className={classNames('bluTypeLabel', 'marginTopXxs')}>
            { translate('garmin_insights_label') }
          </div>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { sleepClass && <div>{ translate(`sleep_insight_${sleepClass}_bullets`) }</div> }
            { stressClass && <div>{ translate(`stress_insight_${stressClass}_bullets`) }</div> }
          </div>
        </>
      ) }
    </>
  );

  if (!userScore) {
    return <SkeletonFlexible />;
  }

  // RENDER: BodyBatteryInsights
  return (
    <div className={classNames(styles.bodyBatteryInsights)}>
      <Card>
        <span className='bluTypeXxs'>
          { translate('garmin_insights_title') }
        </span>

        { (userScore.bodyBattery.trend?.weekly && userScore.bodyBattery.trend?.monthly) && (
          <div className={styles.trends}>
            <GarminTrend
              label={translate('garmin_week_trend')}
              direction={userScore.bodyBattery.trend.weekly?.direction}
            />

            <GarminTrend
              label={translate('garmin_month_trend')}
              direction={userScore.bodyBattery.trend.monthly?.direction}
            />
          </div>
        ) }

        <div className={classNames(
          styles.insight,
          { [styles.blue]: bodyBatteryClass === 'good' || bodyBatteryClass === 'medium-good' },
          { [styles.red]: bodyBatteryClass === 'bad' || bodyBatteryClass === 'medium-bad' },
        )}
        >
          { renderInsightBox() }
        </div>
      </Card>
    </div>
  );
};

export default BodyBatteryInsights;
