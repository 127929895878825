import * as fromActionTypes from 'store/actionTypes/bqActions';

const initialState = {
  actionInfo: null,
  executedActionInfo: null,
  loading: false,
  error: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.GET_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.GET_ACTION_FULFILLED: {
      const { actionInfo } = action.payload;
      return {
        ...state,
        actionInfo,
        loading: false,
        error: null,
      };
    }
    case fromActionTypes.GET_ACTION_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case fromActionTypes.EXECUTE_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.EXECUTE_ACTION_FULFILLED: {
      const { executedActionInfo } = action.payload;
      return {
        ...state,
        executedActionInfo,
        loading: false,
        error: null,
      };
    }
    case fromActionTypes.EXECUTE_ACTION_REJECTED: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }
    default: {
      return state;
    }
  }
};

