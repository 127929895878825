// PLATFORM ROLES
export const GET_PLATFORM_ROLES = 'GET_PLATFORM_ROLES';
export const GET_PLATFORM_ROLES_FULFILLED = 'GET_PLATFORM_ROLES_FULFILLED';
export const GET_PLATFORM_ROLES_REJECTED = 'GET_PLATFORM_ROLES_REJECTED';

export const CREATE_PLATFORM_ROLE = 'CREATE_PLATFORM_ROLE';
export const CREATE_PLATFORM_ROLE_FULFILLED = 'CREATE_PLATFORM_ROLE_FULFILLED';
export const CREATE_PLATFORM_ROLE_REJECTED = 'CREATE_PLATFORM_ROLE_REJECTED';

export const UPDATE_PLATFORM_ROLE = 'UPDATE_PLATFORM_ROLE';
export const UPDATE_PLATFORM_ROLE_FULFILLED = 'UPDATE_PLATFORM_ROLE_FULFILLED';
export const UPDATE_PLATFORM_ROLE_REJECTED = 'UPDATE_PLATFORM_ROLE_REJECTED';

// ORGANISATIONAL ROLES
export const GET_ORG_ROLES = 'GET_ORG_ROLES';
export const GET_ORG_ROLES_FULFILLED = 'GET_ORG_ROLES_FULFILLED';
export const GET_ORG_ROLES_REJECTED = 'GET_ORG_ROLES_REJECTED';

export const INIT_GET_ORG_ROLE = 'INIT_GET_ORG_ROLE';
export const GET_ORG_ROLE = 'GET_ORG_ROLE';
export const GET_ORG_ROLE_FULFILLED = 'GET_ORG_ROLE_FULFILLED';
export const GET_ORG_ROLE_REJECTED = 'GET_ORG_ROLE_REJECTED';

export const INIT_CREATE_ORG_ROLE = 'INIT_CREATE_ORG_ROLE';
export const CREATE_ORG_ROLE = 'CREATE_ORG_ROLE';
export const CREATE_ORG_ROLE_FULFILLED = 'CREATE_ORG_ROLE_FULFILLED';
export const CREATE_ORG_ROLE_REJECTED = 'CREATE_ORG_ROLE_REJECTED';

export const INIT_UPDATE_ORG_ROLE = 'INIT_UPDATE_ORG_ROLE';
export const UPDATE_ORG_ROLE = 'UPDATE_ORG_ROLE';
export const UPDATE_ORG_ROLE_FULFILLED = 'UPDATE_ORG_ROLE_FULFILLED';
export const UPDATE_ORG_ROLE_REJECTED = 'UPDATE_ORG_ROLE_REJECTED';

export const INIT_DELETE_ORG_ROLE = 'INIT_DELETE_ORG_ROLE';
export const DELETE_ORG_ROLE = 'DELETE_ORG_ROLE';
export const DELETE_ORG_ROLE_FULFILLED = 'DELETE_ORG_ROLE_FULFILLED';
export const DELETE_ORG_ROLE_REJECTED = 'DELETE_ORG_ROLE_REJECTED';

export const GET_REFERENCES_PROFILES = 'GET_REFERENCES_PROFILES';
export const GET_REFERENCES_PROFILES_FULFILLED = 'GET_REFERENCES_PROFILES_FULFILLED';
export const GET_REFERENCES_PROFILES_REJECTED = 'GET_REFERENCES_PROFILES_REJECTED';

export const GET_EXPANDED_REFERENCES = 'GET_EXPANDED_REFERENCES';
export const GET_EXPANDED_REFERENCES_FULFILLED = 'GET_EXPANDED_REFERENCES_FULFILLED';
export const GET_EXPANDED_REFERENCES_REJECTED = 'GET_EXPANDED_REFERENCES_REJECTED';

export const INIT_ROLE_TEMPLATE = 'INIT_ROLE_TEMPLATE';
export const GET_ROLE_TEMPLATE = 'GET_ROLE_TEMPLATE';
export const GET_ROLE_TEMPLATE_FULFILLED = 'GET_ROLE_TEMPLATE_FULFILLED';
export const GET_ROLE_TEMPLATE_REJECTED = 'GET_ROLE_TEMPLATE_REJECTED';

