// Utilities for operations on strings

/**
 * Given two strings a and b, return a comparison value for the .sort() function,
 * so that a list can be sorted alphabetically (A-Z).
 *
 * @param {String} a first string to compare
 * @param {String} b second string to compare
 * @param {Boolean} [reverse] whether the order should be reversed (Z-A)
 * @returns {Number} value for .sort() function
 */
export const sortAlphabetically = (a, b, reverse = false) => {
  const direction = reverse ? -1 : 1;
  if (a > b) {
    return direction;
  }
  if (a < b) {
    return -direction;
  }
  return 0;
};
