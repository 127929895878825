// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './QuestionLanguage.module.scss';

// ASSETS
import { ReactComponent as Checkmark } from 'assets/icons/icn_check_big.svg';

// 3RD PARTY
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { handleRipple } from 'utils/ripple';

// STORE
import {
  setUserSettingsApi,
  selectSettingsLanguages,
} from 'features/framework/storeNext/configurationSlice';


// COMPONENT: QuestionLanguage
const QuestionLanguage = (props) => {
  // PROPS
  const { onNext = () => {} } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const currentLanguage = localStorage.getItem('selectedLanguage');
  const languages = useSelector(selectSettingsLanguages);

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleNext = () => {
    onNext();
  };

  const handleLanguageChange = (event, languageId) => {
    handleRipple(event, styles.colorPrimary3);

    setTimeout(() => {
      dispatch(setUserSettingsApi({ language: languageId }));
    });
  };

  // HELPERS

  // RENDERS

  // RENDER: QuestionIntro
  return (
    <div className={classNames(styles.questionLanguage)}>
      { /* BREATHE */ }
      <div className={styles.breatheContainer}>
        <div className={styles.breathe} />
      </div>

      { /* CONTENT */ }
      <div className={styles.content}>

        { /* TITLE */ }
        <div className={styles.title}>
          { translate('compete_profile_intro_title') }
        </div>

        { /* LANGUAGE SWITCH */ }
        <div className={styles.languages}>
          <div className='bluTypeLabel'>
            { translate('gen_settings_choose_language') }
          </div>

          <div className={styles.rows}>
            { languages.map((id) => (
              <div
                key={`language-${id}`}
                role='presentation'
                className={classNames(styles.language, { [styles.active]: currentLanguage === id })}
                onClick={(event) => handleLanguageChange(event, id)}
              >
                <span>
                  { translate(`langswitch_menu_${id}`) }
                </span>
                { currentLanguage === id && <Checkmark /> }
              </div>
            )) }
          </div>
        </div>

        <Button onClick={handleNext}>
          { translate('cp_intro_btn') }
        </Button>
      </div>

    </div>
  );
};

export default QuestionLanguage;
