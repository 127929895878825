// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionAspects.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { MultiSelect, Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

export const cultureAspectOption = {
  label: 'cp_motivation_culture_question',
  name: 'culture',
  value: 'Gute Arbeitsumgebung und Kultur',
};

export const opportunitiesAspectOption = {
  label: 'cp_motivation_opportunities_question',
  name: 'opportunities',
  value: 'Gute Entwicklungsmöglichkeiten',
};
export const salaryAspectOption = {
  label: 'cp_motivation_salary_question',
  name: 'salary',
  value: 'Verbessertes Gehalt',
};

const ASPECT_OPTIONS = [
  cultureAspectOption,
  opportunitiesAspectOption,
  salaryAspectOption,
];

// CONFIG


// COMPONENT: QuestionAspects
const QuestionAspects = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  const TrashIcon = IconsSvg.Trash;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // MULTI SELECT
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  useEffect(() => {
    if (answer.value && answer.value.selectedOptions) {
      setSelectedOptions(answer.value.selectedOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [ answer.value ]);

  const [ aspectOptions, setAspectOptions ] = useState(ASPECT_OPTIONS);
  useEffect(() => {
    const aspectOptionsInternal = [ ...ASPECT_OPTIONS ];

    if (selectedOptions.length > 0) {
      const culture = selectedOptions.find((selectedOption) => selectedOption.name === 'culture');
      if (culture) {
        aspectOptionsInternal[0] = culture;
      }

      const opportunities = selectedOptions.find((selectedOption) => selectedOption.name === 'opportunities');
      if (opportunities) {
        aspectOptionsInternal[1] = opportunities;
      }

      const salary = selectedOptions.find((selectedOption) => selectedOption.name === 'salary');
      if (salary) {
        aspectOptionsInternal[2] = salary;
      }

      setAspectOptions(aspectOptionsInternal);
    }
  }, [ selectedOptions ]);

  const handleSelect = (selectedOptions) => {
    publishNewAnswer(selectedOptions, customAspects);
  };

  // HELPERS
  const publishNewAnswer = (selectedOptions, customAspects) => {
    setSelectedOptions(selectedOptions);
    setCustomAspects(customAspects);

    const isValid = true;

    const newAnswer = {
      value: {
        selectedOptions,
        customAspects,
      },
      name: 'aspects',
      isValid,
    };

    onAnswer(newAnswer);
    // console.log('newAnswer', newAnswer);
  };

  // CUSTOM ASPECTS
  const [ customAspects, setCustomAspects ] = useState([]);
  useEffect(() => {
    if (answer.value && answer.value.customAspects) {
      setCustomAspects(answer.value.customAspects);
    } else {
      setCustomAspects([]);
    }
  }, [ answer.value ]);


  const handleAdd = () => {
    setCustomAspects([ ...customAspects, '' ]);
  };
  const setCustomAspect = (index, newAspect) => {
    customAspects[index] = newAspect;
    const newCustomAspects = [ ...customAspects ];
    setCustomAspects(newCustomAspects);

    publishNewAnswer(selectedOptions, customAspects);
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: QuestionAspects
  return (
    <div className={classNames(styles.questionAspects)}>

      { /* MULTI SELECT */ }
      <div className={classNames(styles.formRow, styles.bigMargin)}>
        <MultiSelect
          size={size}
          options={aspectOptions}
          onChange={handleSelect}
        />
      </div>

      { /* CUSTOM */ }
      { customAspects.length > 0 && (
        <div className={classNames(styles.formRow, styles.biggerMargin)}>
          { customAspects.map((aspect, index) => (
            <div key={index} className={styles.formRow}>
              <div className={styles.customInput}>
                <InputNext
                  size={size}
                  placeholder={translate('cp_motivation_add_input_placeholder') || 'Motivation für Jobwechsel hinzufügen'}
                  value={aspect}
                  onChange={(value) => setCustomAspect(index, value)}
                />
                <div
                  className={styles.deleteInput}
                  onClick={() => {
                    const customAspectsInternal = [ ...customAspects ];
                    customAspectsInternal.splice(index, 1);
                    setCustomAspects(customAspectsInternal);

                    publishNewAnswer(selectedOptions, customAspectsInternal);
                  }}
                >
                  <TrashIcon />
                </div>
              </div>
            </div>
          )) }
        </div>
      ) }

      { /* ADD CUSTOM */ }
      <div className={classNames(styles.formRow, styles.biggerMargin, styles.center)}>
        <Button
          looks='tertiary'
          size={size === 'responsive' ? 'L' : 'S'}
          leadingIcon={IconsSvg.Plus}
          onClick={handleAdd}
        >
          { translate('cp_motivation_add_btn') || 'Weitere Motivation hinzufügen' }
        </Button>
      </div>

    </div>
  );
};

export default QuestionAspects;
