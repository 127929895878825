export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_TRANSLATIONS_PENDING = 'GET_TRANSLATIONS_PENDING';
export const GET_TRANSLATIONS_FULFILLED = 'GET_TRANSLATIONS_FULFILLED';
export const GET_TRANSLATIONS_REJECTED = 'GET_TRANSLATIONS_REJECTED';

export const getTranslations = (language) => ({
  type: GET_TRANSLATIONS,
  payload: language,
});

export const getTranslationsFulfilled = (translations) => ({
  type: GET_TRANSLATIONS_FULFILLED,
  payload: translations,
});
export const getTranslationsRejected = ({ error }) => ({
  type: GET_TRANSLATIONS_REJECTED,
  payload: { error },
});

