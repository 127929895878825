// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CodeInputGroup.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { CodeInput } from './CodeInput';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

const MAX_NUMBER_OF_DIGITS = 10;

// COMPONENT: CodeInputGroup
const CodeInputGroup = (props) => {
  // PROPS
  const {
    numberOfDigits = 4,
    codeIsInvalid,
    onValidate = () => {},
    forceUpperCase,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ cellIndexWithFocus, setCellIndexWithFocus ] = useState(0);
  const [ codeArray, setCodeArray ] = useState([]);
  useEffect(() => {
    if (numberOfDigits) {
      setCodeArray(new Array(numberOfDigits > MAX_NUMBER_OF_DIGITS ? MAX_NUMBER_OF_DIGITS : numberOfDigits).fill(''));
    }
  }, [ numberOfDigits ]);

  const handleOnChange = (value, index) => {
    const thisCode = [ ...codeArray ];
    thisCode[index] = value;
    setCodeArray(thisCode);

    // change the focus to the next or prev cell depending on value
    // by entering the value
    if (value) {
      setCellIndexWithFocus(index === codeArray.length - 1 ? index : index + 1);
    } else {
      // by deleting the value
      setCellIndexWithFocus(index === 0 ? 0 : index - 1);
    }

    const allDigitsAvailable = thisCode.every((codeValue) => !!codeValue);
    if (allDigitsAvailable) {
      onValidate(thisCode.join(''));
    }
  };

  const [ codeIsInvalidInternal, setCodeIsInvalidInternal ] = useState();
  useEffect(() => {
    setCodeIsInvalidInternal(codeIsInvalid);
  }, [ codeIsInvalid ]);


  // RENDER: CodeInputGroup
  return (
    <div className={classNames(styles.codeInputGroup)}>
      <div className={styles.flexContainer}>
        { codeArray.map((_, index) => (
          <CodeInput
            key={index}
            autofocus={cellIndexWithFocus === index}
            error={codeIsInvalidInternal}
            onChange={(value) => {
              handleOnChange(value, index);
            }}
            forceUpperCase={forceUpperCase}
          />
        )) }
      </div>

      { /* ERROR MESSAGE */ }
      { codeIsInvalidInternal && (
        <div className={styles.hintParent}>
          <div className={styles.hint}>
            { translate('code_incorrect_error') }
          </div>
        </div>
      ) }
    </div>
  );
};

export default CodeInputGroup;
