import * as fromActionTypes from 'store/actionTypes/survey';

import { ITEMS_PER_PAGE } from 'api';

const initialState = {
  success: false,
  loading: false,
  contentFeedback: null,
  contentFeedbackPageCount: 0,
  error: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.INIT_SUBMIT_CONTENT_FEEDBACK: {
      return {
        ...state,
        success: false,
      };
    }
    case fromActionTypes.SUBMIT_CONTENT_FEEDBACK: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.SUBMIT_CONTENT_FEEDBACK_FULFILLED: {
      return {
        success: true,
        loading: false,
        error: null,
      };
    }
    case fromActionTypes.SUBMIT_CONTENT_FEEDBACK_REJECTED: {
      const { error } = action.payload;
      return {
        success: false,
        loading: false,
        error,
      };
    }
    case fromActionTypes.GET_CONTENT_FEEDBACK: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.GET_CONTENT_FEEDBACK_FULFILLED: {
      const { contentFeedback, totalCount } = action.payload;
      const contentFeedbackPageCount = Math.ceil(totalCount / ITEMS_PER_PAGE);
      return {
        success: true,
        loading: false,
        contentFeedback,
        contentFeedbackPageCount,
        error: null,
      };
    }
    case fromActionTypes.GET_CONTENT_FEEDBACK_REJECTED: {
      const { error } = action.payload;
      return {
        success: false,
        loading: false,
        error,
      };
    }
    default: {
      return state;
    }
  }
};
