// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './NextAction.module.scss';

// ASSETS
import { ReactComponent as Arrow } from 'assets/icons/icn_arrow_right.svg';
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { HELP_LINK } from 'utils/configuration';

// STORE NEXT
import { useSelector, useDispatch } from 'react-redux';
import {
  selectInstanceInitialised,
  setInstanceInitialisedApi,
  setOnboardingCompletedApi,
} from 'features/framework/storeNext/configurationSlice';


// CONFIG & DATA
// const Config = {};
const ACTIONS = [
  {
    title: 'onboarding_admin_start_assessment',
    description: 'onboarding_admin_start_assessment_description',
    link: '/my-profile',
  },
  {
    title: 'onboarding_admin_invite_user',
    description: 'onboarding_admin_invite_user_description',
    link: '/user/invite',
  },
  {
    title: 'onboarding_admin_configure_application',
    description: 'onboarding_admin_configure_application_description',
    link: '/settings/organisation',
  },
];


// COMPONENT: NextAction
const NextAction = (props) => {
  // PROPS
  const {
    controls = {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  // ACTIONS CONFIG BASED ON INSTANCE INITIALISED
  const [ actions, setActions ] = useState(ACTIONS);
  const instanceInitialised = useSelector(selectInstanceInitialised);
  useEffect(() => {
    if (instanceInitialised) {
      setActions(ACTIONS.slice(0, ACTIONS.length - 1));
    } else {
      dispatch(setInstanceInitialisedApi());
    }
  }, [ dispatch, instanceInitialised ]);

  // MARK ONBOARDING COMPLETED
  useEffect(() => {
    dispatch(setOnboardingCompletedApi());
  }, [ dispatch ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLES
  const handleHelpClick = () => {
    window.open(HELP_LINK, '_blank');
  };

  // RENDER: NextAction
  return (
    <div className={classNames(styles.nextAction)}>
      <div className='bluContent466'>
        <div className='bluTypeS'>
          { translate('onboarding_admin_next_action_title') }
        </div>

        <div className={styles.actions}>
          { actions.map((action) => (
            <div
              key={action.link}
              role='presentation'
              className={styles.action}
              onClick={() => history.push(action.link)}
            >
              <div className={styles.left}>
                <div className='bluTypeLabelL'>
                  { translate(action.title) }
                </div>
                <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                  { translate(action.description) }
                </div>
              </div>

              <div className={styles.right}>
                <Arrow />
              </div>
            </div>
          )) }
        </div>

        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        ) }
        <div className={classNames('bluTypeXxs', 'marginTopM')}>
          { translate('onboarding_candidate_start_assessment_text') }
        </div>

        <div className={styles.helpButton}>
          <Button
            looks='secondary'
            leadingIcon={IconsSvg.BqContact}
            onClick={handleHelpClick}
          >
            { translate('onboarding_candidate_start_assessment_help_center') || 'Zum Help-Center' }
          </Button>

        </div>

      </div>
    </div>
  );
};

export default NextAction;
