// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StandaloneWrapper.module.scss';

// OTHER COMPONENTS
import { StandaloneHeader } from './StandaloneHeader';
import { Footer } from '../Footer';

// STORE OLD
import store from 'store';
import { Provider } from 'react-redux';


// COMPONENT: StandaloneWrapper
const StandaloneWrapper = (props) => {
  // PROPS
  const {
    children,
    noHeaderLogoLink = false,
  } = props;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: StandaloneWrapper
  return (
    <div className={styles.standaloneWrapper}>
      <div className={styles.header}>
        <Provider store={store}>
          <StandaloneHeader noHeaderLogoLink={noHeaderLogoLink} />
        </Provider>
      </div>
      <div className={styles.page}>
        <div className={styles.content}>
          { children }
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StandaloneWrapper;
