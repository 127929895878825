import React from 'react';
import styles from './ProgressCircle.module.scss';

import classNames from 'classnames';

import { IconsSvg } from 'assets/icons';

import WellBeingImage from 'assets/icons/icn_wbi.svg';

const { CheckSmall } = IconsSvg;
const { Block } = IconsSvg;

const ProgressCircle = (props) => {
  const {
    size,
    progress: progressProp,
    showProgress,
    showWellBeingIcon,
    looks = 'blue',
    yellowDiff,
    loading,
  } = props;

  const progress = Number(progressProp);

  return (
    <div
      className={classNames(styles.progressCircle, styles[looks], {
        [styles[`size${size}`]]: true,
        [styles.loading]: loading,
        [styles.yellowDiff]: yellowDiff,
      })}
      data-progress={Number.isFinite(progress) ? Math.round(progress) : 0}
    >
      <div className={styles.circle}>
        <div className={`${styles.mask} ${styles.full}`}>
          <div className={styles.fill} />
        </div>
        <div className={`${styles.mask} ${styles.half}`}>
          <div className={styles.fill} />
          <div className={`${styles.fill} ${styles.fix}`} />
        </div>
      </div>
      <div className={styles.inset}>
        { showProgress && `${progress}%` }
        { showWellBeingIcon && (
          <img
            alt='well-being'
            src={WellBeingImage}
            className={styles.wellBeing}
          />
        ) }

        { (!showProgress && !showWellBeingIcon) && (
          <>
            { progress === 100 && <CheckSmall /> }
            { progress === -1 && <Block /> }
          </>
        ) }
      </div>
    </div>
  );
};

export default ProgressCircle;
