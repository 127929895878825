import React, { useEffect, useState } from 'react';
import styles from './BasicUserGroup.module.scss';

// UTILS
import { useTranslate } from 'utils/translator';
import { USER_GROUP_PROFILE_SETTINGS_ITEMS } from 'features/framework/utils/configuration';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Checkbox } from 'ui/basic';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompany,
  setCompanyCustomProfileSettingsApi,
} from 'features/framework/storeNext/configurationSlice';

const DESCRIPTIONS = [
  { id: 'descr1', label: 'usergroup_settings_basic_descr1' },
  { id: 'descr2', label: 'usergroup_settings_basic_descr2' },
  { id: 'descr3', label: 'usergroup_settings_basic_descr3' },
];

const BasicUserGroup = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const company = useSelector(selectCompany);

  // PROFILE SETTINGS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ profileSettingsItems, setProfileSettingsItems ] = useState([]);
  useEffect(() => {
    if (profileSettingsItems.length) {
      return;
    }

    setProfileSettingsItems(USER_GROUP_PROFILE_SETTINGS_ITEMS
    .filter((item) => {
      if (!item.excludeFor) {
        return true;
      }

      return !item.excludeFor.includes('employee');
    })
    .map((item) => {
      let checked;
      if (item.alwaysChecked) {
        checked = true;
      } else {
        checked = company.custom?.profileSettings?.employee?.[item.id] === undefined
          ? true
          : !!company.custom.profileSettings.employee[item.id];
      }

      return {
        ...item,
        checked,
      };
    }));
  }, [ company, profileSettingsItems ]);

  const handleProfileSettingsChange = (value, targetItem) => {
    const payload = {};
    profileSettingsItems.forEach((itemInternal) => {
      if (targetItem.id === itemInternal.id) {
        payload[itemInternal.id] = value;
      } else {
        payload[itemInternal.id] = itemInternal.checked;
      }
    });

    dispatch(setCompanyCustomProfileSettingsApi({
      employee: payload,
    }));
  };

  return (
    <div className={styles.basicUserGroup}>
      <div className={styles.main}>
        <div className={styles.title}>
          { translate('usergroup_settings_basic') }
        </div>

        <div className={styles.description}>
          { DESCRIPTIONS.map((description) => (
            <div className={styles.descRow} key={description.id}>
              <span>-</span>
              <span>{ translate(description.label) }</span>
            </div>
          )) }
        </div>

        { /* PROFILE SETTINGS */ }
        <div className={styles.profileSettings}>
          <span className='bluTypeLabelL'>
            { translate('profile_settings_configuration_title') }
          </span>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('profile_settings_configuration_copy') }
          </div>
          <div className={styles.checkBoxes}>
            { profileSettingsItems.map((item) => (
              <Checkbox
                key={item.id}
                name={translate(item.name)}
                checked={item.checked}
                disabled={item.disabled}
                onChange={(checked) => {
                  const profileSettingsItemsInternal = JSON.parse(JSON.stringify(profileSettingsItems));
                  const thisItem = profileSettingsItemsInternal.find((psi) => psi.id === item.id);
                  if (thisItem) {
                    thisItem.checked = checked;
                    setProfileSettingsItems(profileSettingsItemsInternal);

                    handleProfileSettingsChange(checked, item);
                  }
                }}
              />
            )) }
          </div>
        </div>

      </div>
    </div>
  );
};

export default BasicUserGroup;
