import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

export const getResultInfoTitle = (assessmentType, dimensionIndex, facetIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_results_area${dimensionIndex}_comp${facetIndex}_info_title`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `leadershipcomp_results_area${dimensionIndex}_comp${facetIndex}_info_title`;
    default:
      return '';
  }
};

export const getResultInfoDescription = (assessmentType, dimensionIndex, facetIndex) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return `keycomp_results_area${dimensionIndex}_comp${facetIndex}_info_description`;
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return `leadershipcomp_results_area${dimensionIndex}_comp${facetIndex}_info_description`;
    default:
      return '';
  }
};

export const getTranslationIds = (assessmentType) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return {
        resultTitle: 'keycomp_results_title',
        resultInfoTitle: 'keycomp_results_info_title',
        resultInfoDescription: 'keycomp_results_info_description',
        resultInfoButton: 'keycomp_results_info_button_label',
      };
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return {
        resultTitle: 'leadershipcomp_results_title',
        resultInfoTitle: 'leadershipcomp_results_info_title',
        resultInfoDescription: 'leadershipcomp_results_info_description',
        resultInfoButton: 'leadershipcomp_results_info_button_label',
      };
    default:
      return {};
  }
};
