// import React from 'react';

// INTERMISSIONS
import { Prices } from '../components/trial-upgrade/Prices';
import { BillingData } from '../components/trial-upgrade/BillingData';
import { StripeForm } from '../components/trial-upgrade/StripeForm';
import { Review } from '../components/trial-upgrade/Review';
import { Success } from '../components/trial-upgrade/Success';

// OTHER COMPONENTS

export const trialUpgradeConfig = {
  id: 'trialUpgrade',
  title: 'upgrade_to_pp_header',
  cancelText: 'upgrade_to_pp_modal_copy',
  cancelButtonText: 'abort',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    // PRICES
    {
      noPrev: true,
      customLayout: true,
      customControls: true,
      Component: Prices,
    },
    // BILLING DATA
    {
      customLayout: true,
      customControls: true,
      Component: BillingData,
    },
    // STRIPE FORM
    {
      customLayout: true,
      customControls: true,
      Component: StripeForm,
    },
    // REVIEW
    {
      customLayout: true,
      customControls: true,
      Component: Review,
    },
    // SUCCESS
    {
      isIntermission: true,
      Component: Success,
    },
  ],
};
