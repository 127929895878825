// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './RecommendationCard.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button,
  Card,
  Modal,
  SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';
import {
  RECOMMENDATION_STATE,
  RECOMMENDATION_CONTENT_TYPE,
  RECOMMENDATION_CONTENT_SOURCE,
} from 'features/framework/pages/MyProfileRecommendations/Recommendations.config';

// STORE
import { useDispatch } from 'react-redux';
import { updateRecommendation, getRecommendation } from 'store/actions/recommendations';

// CONFIG & DATA
const Config = {
  content: {
    [RECOMMENDATION_CONTENT_TYPE.AUDIO]: <IconsSvg.ContentAudio />,
    [RECOMMENDATION_CONTENT_TYPE.VIDEO]: <IconsSvg.ContentVideo />,
    [RECOMMENDATION_CONTENT_TYPE.TEXT]: <IconsSvg.ContentText />,
  },
};

// COMPONENT: RecommendationCard
const RecommendationCard = (props) => {
  // PROPS
  const {
    id = '',
    title = '',
    state = RECOMMENDATION_STATE.ACTIVE,
    isBlue = true,
    contentType = RECOMMENDATION_CONTENT_TYPE.TEXT,
    goals = [],
    requiresAttribution = false,
    sourceCitation = '',
    contentSource = RECOMMENDATION_CONTENT_SOURCE.INTERNAL,
    url = '',
    content = '',
  } = props;

  const bookmarked = state === RECOMMENDATION_STATE.BOOKMARKED;
  const archived = state === RECOMMENDATION_STATE.ARCHIVED;
  const hasUrl = contentSource === RECOMMENDATION_CONTENT_SOURCE.EXTERNAL && Boolean(url);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ showInfoModal, setShowInfoModal ] = useState(false);
  const [ showDeletionModal, setShowDeletionModal ] = useState(false);
  const [ bookmarkedInternal, setBookmarkedInternal ] = useState(bookmarked);
  const [ processing, setProcessing ] = useState(false);

  // Update internal state after recommendation update
  useEffect(() => {
    setBookmarkedInternal(state === RECOMMENDATION_STATE.BOOKMARKED);
    setProcessing(false);
    setShowDeletionModal(false);
  }, [ state ]);

  // Fetch recommendation content
  useEffect(() => {
    if (content || !showInfoModal) {
      return;
    }
    dispatch(getRecommendation({ id }));
  }, [ dispatch, id, content, showInfoModal ]);

  // Update recommendation state
  const updateCardState = (newState) => {
    setProcessing(true);
    dispatch(updateRecommendation({ id, state: newState }));
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // Render goals
  const renderGoals = (light = false) => (
    <div className={styles.types}>
      { goals.map((el) => (
        <div
          key={el}
          className={classNames(styles.typePill, {
            [styles.light]: light,
            [styles.archived]: !light && archived,
          })}
        >
          { el }
        </div>
      )) }
    </div>
  );

  // Do not render deleted cards
  if (state === RECOMMENDATION_STATE.INACTIVE) {
    return null;
  }

  // RENDER: RecommendationCard
  return (
    <div className={styles.recommendationCard}>
      <Card
        hasBackgroundGrey={archived}
        hasBackgroundBlue={isBlue}
        hasFullHeight
      >
        <div className={styles.container}>
          { /* Card content except for right arrow */ }
          <div>
            <header>
              <div>
                <div className={styles.content}>
                  { Config.content[contentType] }
                  { translate(`myprofile_recommendation_card_content_${contentType}`) }
                </div>
                <span
                  className={classNames({ [styles.archived]: archived })}
                  role='presentation'
                  onClick={() => setShowInfoModal(true)}
                >
                  { title }
                </span>
              </div>
              { isBlue && (
                <div>
                  <Button
                    looks={bookmarked ? 'primary' : 'secondary'}
                    size='S'
                    primaryIcon={bookmarked ? IconsSvg.BannerNo : IconsSvg.Banner}
                    onClick={() => updateCardState(bookmarked
                      ? RECOMMENDATION_STATE.ACTIVE
                      : RECOMMENDATION_STATE.BOOKMARKED)}
                    disabled={processing}
                  />
                </div>
              ) }
            </header>

            <div className={styles.groups}>
              { renderGoals(isBlue) }
              { !isBlue && (
                <div className={styles.buttons}>
                  <Button
                    looks={archived ? 'secondary' : 'primary'}
                    size='S'
                    onClick={() => setShowInfoModal(true)}
                  >
                    { translate('learn_more') }
                  </Button>
                  { archived && (
                    <Button
                      looks='secondary'
                      size='S'
                      primaryIcon={IconsSvg.Trash}
                      onClick={() => setShowDeletionModal(true)}
                      disabled={processing}
                    />
                  ) }
                  <Button
                    looks={archived ? 'primary' : 'secondary'}
                    size='S'
                    primaryIcon={IconsSvg.CheckBig}
                    onClick={() => updateCardState(archived
                      ? RECOMMENDATION_STATE.BOOKMARKED
                      : RECOMMENDATION_STATE.ARCHIVED)}
                    disabled={processing}
                  />
                </div>
              ) }
            </div>
          </div>

          { isBlue && (
            <div
              className={styles.arrowRight}
              role='presentation'
              onClick={() => setShowInfoModal(true)}
            >
              <IconsSvg.ArrowRight />
            </div>
          ) }
        </div>
      </Card>

      { /* FLOATERS */ }
      { showInfoModal && (
        <Modal
          header={(
            <div className={styles.infoModalHeader}>
              { title }
              { !archived && (
                <div
                  className={styles.banner}
                  role='presentation'
                  onClick={() => {
                    if (!processing) {
                      setBookmarkedInternal((value) => !value);
                    }
                  }}
                >
                  { bookmarkedInternal
                    ? <IconsSvg.BannerNo />
                    : <IconsSvg.Banner /> }
                </div>
              ) }
            </div>
          )}
          primaryButtonTitle={hasUrl && translate('myprofile_recommendation_card_external_open')}
          primaryButtonIcon={IconsSvg.ExternalLink}
          secondaryButtonTitle={translate('close_lbl')}
          closeOnConfirm={false}
          onClose={() => {
            setShowInfoModal(false);
            if (bookmarkedInternal !== bookmarked) {
              updateCardState(bookmarked
                ? RECOMMENDATION_STATE.ACTIVE
                : RECOMMENDATION_STATE.BOOKMARKED);
            }
          }}
          onConfirm={() => window.open(url, '_blank')}
        >
          { renderGoals(true) }

          <div className={styles.infoModalContent}>
            { content
              ? (
                <>
                  <div className={styles.infoModalContent}>
                    { markdown(content) }
                  </div>
                  <div className={styles.infoModalFooter}>
                    { requiresAttribution && (
                      <div>
                        { sourceCitation }
                      </div>
                    ) }
                    { hasUrl && (
                      <div>
                        { translate('myprofile_recommendation_card_external', [ '{{link}}', url ]) }
                      </div>
                    ) }
                  </div>
                </>
              )
              : <SkeletonFlexible noHeader repeat={3} /> }
          </div>
        </Modal>
      ) }

      { showDeletionModal && (
        <Modal
          header={translate('myprofile_recommendation_card_delete_title')}
          onClose={() => setShowDeletionModal(false)}
          redButtonTitle={translate('delete_lbl')}
          redButtonDisabled={processing}
          secondaryButtonTitle={translate('cancel_lbl')}
          closeOnConfirm={false}
          onConfirm={() => updateCardState(RECOMMENDATION_STATE.INACTIVE)}
        >
          { translate('myprofile_recommendation_card_delete') }
        </Modal>
      ) }
    </div>
  );
};

export default RecommendationCard;
