import React, { lazy } from 'react';

// OTHER COMPONENTS
const AdminUGMain = lazy(() => import('./pages/AdminUGMain' /* webpackChunkName: "adminUG" */));

export const routesConfigAdminUG = {
  show: {
    forUserGroup: 'admin',
  },
  routes: [
    {
      path: '/upgrade-from-trial',
      pageType: 'modal',
      pageWrapper: { instantBackground: true, noAnimate: true },
      PageComponent: () => <AdminUGMain />,
      LazyPageComponent: 'PlanUpgradeTrial',
      useStoreNext: true,
      show: {
        forPlan: [ 'trial', 'restricted' ],
        needsProfileCompleted: 'ignore',
      },
    },
    {
      path: '/update-payment-method',
      pageType: 'modal',
      pageWrapper: { instantBackground: true, noAnimate: true },
      PageComponent: () => <AdminUGMain />,
      LazyPageComponent: 'PaymentMethodUpdate',
      useStoreNext: true,
      show: {
        forPlan: 'restricted',
        needsProfileCompleted: 'ignore',
      },
    },
  ],

};
