// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionStartDate.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  AssessmentRadioButton, InputNext, InputMasked, RadioButton,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { dateHasValidFormat } from 'utils/dateTools';
import { capitalise } from 'utils/textTools';

// STORE

// CONFIG & DATA
// const Config = {};


// COMPONENT: QuestionStartDate
const QuestionStartDate = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  const translate = useTranslate();
  const options = [
    {
      label: translate('cp_start_now_question') || 'Ab sofort',
      value: 'now',
    },
    {
      label: translate('cp_start_date_question') || 'Ich habe ein Wunschdatum',
      value: 'preferredDate',
    },
    {
      label: translate('cp_start_notice_period_question') || 'Gemäß Kündigungsfrist',
      value: 'noticePeriod',
    },
  ];

  const [ errorMessage, setErrorMessage ] = useState();
  const [ radioValue, setRadioValue ] = useState();
  const [ dateValue, setDateValue ] = useState(); // Actually the value of input field
  useEffect(() => {
    if (answer.value) {
      setRadioValue(answer.value.radioValue);
      setDateValue(answer.value.dateValue);
    }
  }, [ answer.value ]);

  const publishNewAnswer = (radioValue, dateValue, error = '') => {
    const isValid = !error && (radioValue === 'now' || Boolean(dateValue));

    const newAnswer = {
      value: {
        radioValue,
        dateValue,
      },
      name: 'startDate',
      isValid,
    };

    onAnswer(newAnswer);
  };

  const handleRadio = (radioValue) => {
    setRadioValue(radioValue);
    setDateValue();
    publishNewAnswer(radioValue);
  };

  const handleFieldOption = (preferredDate) => {
    let error = '';
    if (radioValue === 'preferredDate' && !dateHasValidFormat(preferredDate)) {
      error = translate('share_profile_validto_invalid_format_msg');
    } else {
      setDateValue(preferredDate);
    }
    setErrorMessage(error);
    publishNewAnswer(radioValue, preferredDate, error);
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionStartDate
  return (
    <div className={classNames(styles.questionStartDate, styles[`size${capitalise(size)}`])}>

      <div className={classNames(styles.formRow, styles.assessmentRadioButtonGroup)}>
        { options.map((option, index) => (
          <React.Fragment key={index}>
            { size === 'responsive'
            && (
              <AssessmentRadioButton
                title={option.label}
                value={option.value}
                checked={radioValue === option.value}
                onDeselect={handleRadio}
                onChange={handleRadio}
              />
            ) }

            { size !== 'responsive'
            && (
              <RadioButton
                title={option.label}
                checked={radioValue === option.value}
                value={option.value}
                onChange={handleRadio}
              />
            ) }

            { option.value === 'preferredDate' && radioValue === 'preferredDate' && (
              <div className={styles.inputContainer}>
                <InputMasked
                  size={size}
                  label={translate('cp_start_date_placeholder') || 'Wunschdatum'}
                  mask='99.99.9999'
                  errorMessage={errorMessage}
                  value={dateValue}
                  onChange={handleFieldOption}
                />
              </div>
            ) }
            { option.value === 'noticePeriod' && radioValue === 'noticePeriod' && (
              <div className={styles.inputContainer}>
                <InputNext
                  size={size}
                  label={translate('cp_start_notice_period_placeholder') || 'Angaben zur Kündigungsfrist'}
                  value={dateValue}
                  onChange={handleFieldOption}
                />
              </div>
            ) }
          </React.Fragment>
        )) }
      </div>

    </div>
  );
};

export default QuestionStartDate;
