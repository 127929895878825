// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionPassword.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputPassword } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Password
const QuestionPassword = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ password, setPassword ] = useState();
  const [ passwordValid, setPasswordValid ] = useState();
  const [ confirmedPassword, setConfirmedPassword ] = useState();

  useEffect(() => {
    setPassword(answer.password);
  }, [ answer.password ]);

  const handlePassword = (value, correct) => {
    setPassword(value);
    setPasswordValid(correct);

    const isValid = correct && value === confirmedPassword;
    onAnswer({ password, isValid });
  };

  const handleConfirmedPassword = (value) => {
    setConfirmedPassword(value);

    const isValid = passwordValid && password === value;
    onAnswer({ password, isValid });
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Password
  return (
    <div className={classNames(styles.questionPassword)}>
      <div className={styles.description}>
        { translate('trial_signup_password_copy') }
      </div>

      <div className={styles.inputs}>
        <InputPassword
          type='password'
          placeHolder={translate('signup_form_password_label') || 'Passwort'}
          name='password'
          value={password}
          showCriteria
          onInputChanged={handlePassword}
        />

        <InputPassword
          type='password'
          placeHolder={translate('signup_form_password_repeat_label') || 'Passwort wiederholen'}
          name='confirmedPassword'
          value={confirmedPassword}
          error={password !== confirmedPassword
            && ((translate('signup_form_password_error_hint_notmatching') || 'Eingegebene Passworte sind nicht ähnlich'))}
          onInputChanged={(value) => {
            handleConfirmedPassword(value);
          }}
        />
      </div>
    </div>
  );
};

export default QuestionPassword;
