// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ExtraCard.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Card } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: ExtraCard
const ExtraCard = (props) => {
  // PROPS
  const {
    data,
    dateOfBirthProvided = false,
    onBook,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const isUpselling = data?.type === 'upselling';
  const showButton = (!isUpselling
      || (data?.state === 'open' && dateOfBirthProvided))
    && data?.url;

  let title = `ist_card_${data?.type}`;
  let description = `ist_card_${data?.type}_copy`;
  if (isUpselling) {
    title += `_${data?.state?.split(' ').join('-')}`;
    description += `_${data?.state?.split(' ').join('-')}`;
  }

  // RENDER: UpsellingCard
  return (
    <Card hasNoPaddings>
      <div className={classNames(styles.extraCard, styles[data?.type])}>
        <div className={styles.content}>
          <span className='bluTypeLabelL'>{ translate(title) }</span>
          <div className={classNames('bluTypeCopy', 'marginTopXs')}>
            { translate(description) }
          </div>
        </div>

        { showButton && (
          <Button
            size='S'
            onClick={() => {
              if (onBook) {
                onBook();
              }
            }}
          >
            { translate(`ist_${data?.type}_book_btn`) }
          </Button>
        ) }
      </div>
    </Card>
  );
};

export default ExtraCard;
