import { call, put, takeEvery } from 'redux-saga/effects';

// STORE
import * as fromActions from 'store/actions/staticValues';

// UTILS
import * as api from 'api';


// GET COUNTRIES
function* getCountries() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/profile/countries');

    if (ok && status === 200) {
      yield put(fromActions.getCountriesSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getCountriesError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getCountriesError({ error }));
  }
}

export function* watchGetCountries() {
  yield takeEvery(fromActions.GET_COUNTRIES, getCountries);
}


// GET EDUCATION
function* getEducation() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/profile/education');

    if (ok && status === 200) {
      yield put(fromActions.getEducationSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getEducationError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getEducationError({ error }));
  }
}

export function* watchGetEducation() {
  yield takeEvery(fromActions.GET_EDUCATION, getEducation);
}

// GET INDUSTRIES
function* getIndustries() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/company/industries');

    if (ok && status === 200) {
      yield put(fromActions.getIndustriesSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getIndustriesError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getIndustriesError({ error }));
  }
}

export function* watchGetCompanyIndustries() {
  yield takeEvery(fromActions.GET_INDUSTRIES, getIndustries);
}

// GET CURRENCIES
function* getCurrencies() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/profile/currencies');

    if (ok && status === 200) {
      yield put(fromActions.getCurrenciesSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getCurrenciesError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getCurrenciesError({ error }));
  }
}

export function* watchGetCurrencies() {
  yield takeEvery(fromActions.GET_CURRENCIES, getCurrencies);
}


// GET CURRENCIES
function* getCareerLevels() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/profile/careerLevels');

    if (ok && status === 200) {
      yield put(fromActions.getCareerLevelsSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getCareerLevelsError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getCareerLevelsError({ error }));
  }
}

export function* watchGetCareerLevels() {
  yield takeEvery(fromActions.GET_CAREER_LEVELS, getCareerLevels);
}


// GET JOB FAMILIES
function* getJobFamilies() {
  try {
    const { ok, status, data } = yield call(api.get, 'core/config/job/families');

    if (ok && status === 200) {
      yield put(fromActions.getJobFamiliesSuccess({ data: data.content }));
    } else {
      yield put(fromActions.getJobFamiliesError({ ok, status, error: data }));
    }
  } catch (error) {
    yield put(fromActions.getJobFamiliesError({ error }));
  }
}

export function* watchGetJobFamilies() {
  yield takeEvery(fromActions.GET_JOB_FAMILIES, getJobFamilies);
}
