// REACT, STYLE, STORIES & COMPONENT
import React, { useRef } from 'react';
import styles from './ProfileCircle.module.scss';
import transitions from './transitions.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { BluCSSTransition, ImgCircle } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE OLD
import { useSelector } from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

// CONFIG & DATA
// const Config = {};

// COMPONENT: ProfileCircle
const ProfileCircle = () => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const transitionRef = useRef();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ProfileCircle
  return (
    <div className={classNames(styles.profileCircle)}>
      <BluCSSTransition
        nodeRef={transitionRef}
        in={Boolean(me?.mail)}
        className={{ ...transitions }}
      >
        <ImgCircle
          ref={transitionRef}
          src={me.picture}
          size='Header'
          className='hasHover'
          fullLabel={me.firstName || me.lastName
            ? `${me.firstName} ${me.lastName}`
            : (me.mail && me.mail.substring(0, 1)) || ''}
        />
      </BluCSSTransition>
    </div>
  );
};

export default ProfileCircle;
