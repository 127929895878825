// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Dashboards.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { PillGroup } from 'ui/basic';
import { Diagram } from './Diagram';

// UTILS

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectCompanyDashboards,
  selectCompanyDashboardsSupersetUrl,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: Dashboards
const Dashboards = () => {
  // SPECIAL HOOKS

  const dashboards = useSelector(selectCompanyDashboards);
  const supersetUrl = useSelector(selectCompanyDashboardsSupersetUrl);

  const [ dashboardEmbeddedId, setDashboardEmbeddedId ] = useState(dashboards[0]?.embeddedId);

  // RENDER: Dashboards
  return (
    <div className={styles.dashboardsWrapper}>
      <div className={classNames(styles.dashboards, { [styles.withNavBar]: dashboards?.length > 1 })}>
        { dashboards?.length > 1 && (
          <div className={styles.navBar}>
            <PillGroup
              pillItems={dashboards?.map((d) => ({
                id: d.embeddedId,
                label: d.name,
              }))}
              looks='primaryAndWhite'
              onPillClick={(pillItem) => setDashboardEmbeddedId(pillItem.id)}
            />
          </div>
        ) }

        <Diagram
          supersetUrl={supersetUrl}
          dashboardEmbeddedId={dashboardEmbeddedId}
        />
      </div>
    </div>
  );
};

export default Dashboards;
