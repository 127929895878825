import React, { useEffect } from 'react';
import styles from './SignUp.module.scss';

import classNames from 'classnames';

import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { getRegistrationDomains, signUp } from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';

import { useTranslate } from 'utils/translator';

import PoweredBy from 'ui/basic/micro-ui/PoweredBy';
import SignUpSuccessForm from 'features/framework/components/SignUpSuccessForm';
import SignUpForm from 'features/framework/components/SignUpForm';

const SignUp = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const emailSentSuccessfully = useSelector((state) => state.signUp && state.signUp.registrationEmailSentSuccessfully);
  const error = useSelector((state) => state.signUp && state.signUp.error);

  const isBalancedYou = useSelector(fromSettingsSelectors.getCompanyIsBalancedYou);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  useEffect(() => {
    dispatch(getRegistrationDomains());
  }, [ dispatch ]);

  const handleSignUp = (signUpDto) => {
    dispatch(signUp(signUpDto));
  };

  return (
    <div className={styles.signUp}>
      <div className={classNames(styles.container, { [styles.balancedYou]: isBalancedYou })}>
        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }

        { (emailSentSuccessfully && !history.location.search.includes('invitationId'))
          ? (
            <SignUpSuccessForm
              title={translate(isBalancedYou ? 'by_signup__email_sent_title' : 'signup_title')}
              description={translate('by_signup__email_sent_descr')}
              buttonTitle={translate('by_signup__email_sent_btn')}
              onButtonClick={() => history.push('/')}
            />
          )
          : (
            <SignUpForm
              isBalancedYou={isBalancedYou}
              error={error && error.errorMessage}
              onSignUp={handleSignUp}
            />
          ) }
      </div>
    </div>
  );
};

export default SignUp;
