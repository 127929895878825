// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
import { useHistory } from 'react-router-dom';


// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { login, configurationExtendedReceived } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: RedirectNext
const RedirectNext = (props) => {
  // PROPS
  const {
    restricted = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (restricted) {
      dispatch(login({
        tokenId: '',
        tokenExpiration: 0,
        userId: '',
        userGroup: 'employee',
        userFirstName: '',
        userLastName: '',
        profileCompleted: false,
        onboardingCompleted: false,
      }));

      dispatch(configurationExtendedReceived({
        planType: 'restricted',
        trialExpiration: 0,
        required: true,
      }));
      window.setTimeout(() => {
        history.push('/restricted');
      }, 3);
    }
  }, [ dispatch, history, restricted ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: RedirectNext
  return null;
};

export default RedirectNext;
