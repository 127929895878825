// REACT, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { MetricCard } from 'ui/basic';
import { SkeletonFlexible } from 'ui/basic/micro-ui/SkeletonFlexible';

// UTILS
import { useTranslate } from 'utils/translator';
import { numberWithForcedDecimals } from 'utils/numberFormatting';

// STORE NEXT
import * as api from 'api';
import { useSelector } from 'react-redux';
import { selectCapabilitiesNext, selectFeaturesNext } from 'store/selectors/configuration';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

// STYLE
import styles from './OrganizationOverview.module.scss';

// CONFIG & DATA
// const Config = {};

// COMPONENT: OrganizationOverview
const OrganizationOverview = ({ titleKey = 'main_navigation_item_9' }) => {
  // SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const featuresNext = useSelector(selectFeaturesNext);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ organizationStats, setOrganizationStats ] = useState();
  useEffect(() => {
    api.get('/core/company/statistics')
    .then(({ ok, data }) => {
      if (!ok) {
        return;
      }

      const res = [];

      /**
       * Calculate the percentage of difference from previous week/month.
       *
       * @param {Number} curr Current period's value
       * @param {Number} prev Previous period's value
       * @param {String} [zeroSentence] Sentence to display in case previous period's value is 0
       * @param {String} [lackSentence] Sentence to display in case there's no current period's value yet
       * @param {Boolean} [isWeek] Whether the period is a week (or a month)
       * @returns {String} The sentence to display (eg. "+13% over the last month")
       */
      const renderDiffPercentage = (
        curr,
        prev,
        zeroSentence = '',
        lackSentence = '',
        isWeek = false,
      ) => {
        // Do not divide by zero
        if (!prev) {
          return zeroSentence;
        }

        // Show placeholder if current value is missing
        if (curr === null) {
          return lackSentence;
        }

        const percentage = numberWithForcedDecimals(
          Math.round(((curr / prev) - 1) * 100),
          { decimals: 0, addPlus: true },
        );

        const periodKey = isWeek ? 'org_stats_weekly' : 'org_stats_monthly';
        return `${percentage}% ${translate(periodKey)}`;
      };

      /**
       * Show ratio over max score, with the divider being smaller in font size.
       *
       * @param {Number} value value to render
       * @param {Number} max The max value on the scale
       * @param {Object} options Options for numberWithForcedDecimals function
       * @returns {JSX} The resulting ratio (eg. "2.3 / 5")
       */
      const renderRatioValue = (value, max, options) => {
        if (!value) {
          return <span>-</span>;
        }

        return (
          <>
            <span>{ numberWithForcedDecimals(value, options) }</span>
            <span className={styles.smallRatio}>{ ` / ${max}` }</span>
          </>
        );
      };

      /* All users */

      // Card 1
      // Show only if the well-being module is active
      if (featuresNext.wellbeing) {
        res.push({
          id: 1,
          label: translate('wellbeing_org_result_body_text'),
          value: renderRatioValue(data.wellbeing.currentIndex, 5, { decimals: 2 }),
          hint: renderDiffPercentage(
            data.wellbeing.currentIndex,
            data.wellbeing.previousWeek,
            translate('org_stats_wellbeing_zero'),
            translate('org_stats_not_enough_data'),
            true,
          ),
        });
      }

      // Card 2
      // Show only if the balanced-you module and assessment are active
      if (featuresNext.balancedYou) {
        res.push({
          id: 2,
          label: translate('org_stats_balancedyou_title'),
          value: renderRatioValue(data.balancedYou.currentScore, 10, { decimals: 2 }),
          hint: renderDiffPercentage(
            data.balancedYou.currentScore,
            data.balancedYou.previousScore,
            translate('org_stats_balancedyou_zero'),
            translate('org_stats_not_enough_data'),
          ),
        });
      }

      // Card 3
      res.push({
        id: 3,
        label: translate('org_stats_users_title'),
        value: data.users.total,
        hint: `${data.users.newThisMonth} ${translate('org_stats_monthly_new')}`,
      });

      /* Only company leaders / assessment managers / admins */
      if ([ 'admin', 'leader', 'assessmentManager' ].includes(me.roleNext)) {
        // Card 4
        res.push({
          id: 4,
          label: translate('apm_completed_assessments_title'),
          value: data.assessments.completedTotal,
          hint: `${data.assessments.completedThisMonth} ${translate('org_stats_monthly_new')}`,
        });

        // Card 5
        // The card is not shown, if the roles module is deactivated for the company.
        if (featuresNext.roles) {
          res.push({
            id: 5,
            label: translate('main_navigation_item_3'),
            value: data.roles.total,
            hint: `${data.roles.newThisMonth} ${translate('org_stats_monthly_new')}`,
          });
        }

        // Card 6
        // The card is not shown, if the teams module is deactivated for the company.
        if (featuresNext.teams) {
          res.push({
            id: 6,
            label: translate('main_navigation_item_4'),
            value: data.teams.total,
            hint: `${data.teams.newThisMonth} ${translate('org_stats_monthly_new')}`,
          });
        }

        if (featuresNext.candidates) {
          // Card 7
          // The card is not shown, if the recruiting module is deactivated for the company.
          // For user group leader, the card is not shown,
          // if capability “View vacancies” is deactivated.
          if (capabilitiesNext.vacanciesView) {
            res.push({
              id: 7,
              label: translate('org_stats_jobs_title'),
              value: data.jobs.total,
              hint: renderDiffPercentage(
                data.jobs.newThisMonth,
                data.jobs.newLastMonth,
                translate('org_stats_jobs_zero'),
              ),
            });
          }

          // Card 8
          // The card is not shown, if the recruiting module is deactivated for the company.
          // For user group leader, the card is not shown,
          // if capability “View candidate profiles” is deactivated.
          if (capabilitiesNext.candidatesView) {
            res.push({
              id: 8,
              label: translate('org_stats_candidates_title'),
              value: data.candidates.newThisMonth,
              hint: renderDiffPercentage(
                data.candidates.newThisMonth,
                data.candidates.newLastMonth,
                translate('org_stats_candidates_zero'),
              ),
            });
          }
        }
      }

      setOrganizationStats(res);
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ capabilitiesNext, featuresNext, me.roleNext, translate ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: OrganizationOverview
  return (
    <div className={classNames(styles.organizationOverview)}>
      { !organizationStats
        ? <SkeletonFlexible xxsMargins />
        : (
          <div className={classNames(styles.orgDevelopmentItems)}>
            <div className={styles.title}>
              { translate(titleKey) }
            </div>
            <div className={styles.copy}>
              { translate('org_stats_subtitle') }
            </div>
            <div className={styles.cards}>
              { organizationStats.map((item) => (
                <div
                  className={styles.card}
                  key={`key-metric-${item.id}`}
                >
                  <MetricCard items={[ item ]} />
                </div>
              )) }
            </div>
          </div>
        ) }
    </div>
  );
};

export default OrganizationOverview;
