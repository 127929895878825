export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const AUTH_TOKEN_FETCHED = 'AUTH_TOKEN_FETCHED';
export const SET_EXTENDED_SESSION_DURATION = 'SET_EXTENDED_SESSION_DURATION';

export const login = (payload) => ({
  type: LOGIN,
  payload,
});

export const loginFulfilled = (payload) => ({
  type: LOGIN_FULFILLED,
  payload,
});

export const loginRejected = (payload) => ({
  type: LOGIN_REJECTED,
  payload,
});

export const authTokenFetched = (payload) => ({
  type: AUTH_TOKEN_FETCHED,
  payload,
});

export const setExtendedSessionDuration = (extendedSession) => ({
  type: SET_EXTENDED_SESSION_DURATION,
  payload: { extendedSession },
});
