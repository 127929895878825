// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './SettingsAndCostOverview.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  DropDown, Button, Checkbox, CollapsibleNext,
} from 'ui/basic';
import { CostOverview } from '../CostOverview';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { PLATFORM_ROLES } from 'utils/configuration/const/roles';
import {
  ASSESSMENT_RECURRENCE_TYPES,
} from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectPlan } from 'features/framework/storeNext/configurationSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: SettingsAndCostOverview
const SettingsAndCostOverview = (props) => {
  // PROPS
  const {
    users = [],
    userGroups = [],
    customMessage = '',
    generateInviteLinks = false,
    onComplete = () => {},
    onBack = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const plan = useSelector(selectPlan);

  const [ usersInternal, setUsersInternal ] = useState([]);
  useEffect(() => {
    if (usersInternal.length) {
      return;
    }

    if (users.length) {
      setUsersInternal(users);
    }
  }, [ users, usersInternal ]);


  const [ processing, setProcessing ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const handleComplete = () => {
    setProcessing(true);

    const mails = usersInternal.map((user) => {
      const userInternal = {
        mail: user.email,
        role: user.userGroup?.value,
        features: user.assessments
        .filter((a) => a.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN)
        .map((a) => ({
          id: a.featureId,
          active: a.active,
        })),
      };

      const assessmentsForManaging = user.assessments
      .filter((a) => Boolean(a.grantManagementAccess))
      .map((a) => a.id);

      if (user.userGroup?.value === PLATFORM_ROLES.ASSESSMENT_MANAGER && assessmentsForManaging.length > 0) {
        Object.assign(userInternal, {
          assessmentManagementScope: assessmentsForManaging,
        });
      }

      return userInternal;
    });

    api.put('/core/company/users', {
      mails,
      message: customMessage,
      linksOnly: generateInviteLinks,
    })
    .then(({ ok, status, data = {} }) => {
      if (ok && status === 200) {
        onComplete(data.users);
      } else {
        let { error } = data;
        if (error?.errorCode === 8001) {
          error = translate('exceeded_trial_limit');
        } else if (error?.errorMessage) {
          error = error?.errorMessage;
        }
        setErrorMessage(error);
      }
    })
    .catch((error) => {
      setErrorMessage(error.message);
      console.error(error.message);
    });
  };

  const [ usersQuantity, setUsersQuantity ] = useState();
  const [ candidatesQuantity, setCandidatesQuantity ] = useState();
  const [ rmpQuantity, setRmpQuantity ] = useState();
  const [ nineLevelsQuantity, setNineLevelsQuantity ] = useState();

  useEffect(() => {
    setUsersQuantity(usersInternal.filter((u) => u.userGroup.value !== 'RECRUITING_CANDIDATE')?.length || 0);
    setCandidatesQuantity(usersInternal.filter((u) => u.userGroup.value === 'RECRUITING_CANDIDATE')?.length || 0);

    const rmpAssessments = usersInternal.filter((u) => {
      const rmpAssessment = u.assessments.find((a) => a.id === 'ASSESSMENT_RMP');
      return rmpAssessment?.active;
    });

    const nineLevelsAssessments = usersInternal.filter((u) => {
      const nineLevelsAssessment = u.assessments.find((a) => a.id === 'ASSESSMENT_9LEVELS');
      return nineLevelsAssessment?.active;
    });

    setRmpQuantity(rmpAssessments?.length || 0);
    setNineLevelsQuantity(nineLevelsAssessments?.length || 0);
  }, [ usersInternal ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const { People } = IconsSvg;

  // HANDLERS
  const handleUserGroupChange = (option, user) => {
    const theseUsersInternal = [ ...usersInternal ];
    const thisUser = theseUsersInternal.find((u) => u.email === user.email);
    if (thisUser) {
      thisUser.userGroup = option;
      setUsersInternal(theseUsersInternal);
    }
  };

  const handleAssessmentChange = (value, user, assessment) => {
    const usersInternalCopy = JSON.parse(JSON.stringify(usersInternal));

    const thisUser = usersInternalCopy.find((u) => u.email === user.email);
    if (thisUser) {
      const thisAssessment = thisUser.assessments.find((a) => a.id === assessment.id);
      thisAssessment.active = value;
      setUsersInternal(usersInternalCopy);
    }
  };

  const handleGrantManagementAccess = (checked, userEmail, assessmentId) => {
    const usersInternalCopy = JSON.parse(JSON.stringify(usersInternal));

    const thisUser = usersInternalCopy.find((u) => u.email === userEmail);
    if (thisUser) {
      const thisAssessment = thisUser.assessments.find((a) => a.id === assessmentId);
      thisAssessment.grantManagementAccess = checked;
      setUsersInternal(usersInternalCopy);
    }
  };

  // RENDER: SettingsAndCostOverview
  return (
    <div className={classNames(styles.settingsAndCostOverview)}>
      <div className='bluTypeS'>
        { translate('check_your_info') }
      </div>
      <div className={classNames('bluTypeCopy', 'marginTopXs')}>
        { translate('invite_users_multiple_emails_check_info_copy') }
      </div>

      { errorMessage && (
        <div className={classNames('error', 'marginTopXs')}>{ errorMessage }</div>
      ) }

      { /* INVITED USERS */ }
      <div className={styles.invitedUsers}>
        <span className='bluTypeXs'>
          { translate('invited_users_access_rights') }
        </span>

        { usersInternal.map((user) => {
          let assessmentsHeader = translate('available_assessments_option_lbl');

          const thisAssessment = user.assessments.find((a) => a.premium && a.active);
          if (thisAssessment) {
            assessmentsHeader = assessmentsHeader.concat(` (${translate('chargeable_assessments_included')})`);
          }

          return (
            <div className={styles.user} key={user.email}>
              <div className={styles.firstRow}>
                <div className={styles.username}>
                  <People />
                  <span className='bluTypeLabelL'>{ user.email }</span>
                </div>

                <DropDown
                  placeholder='Choose an option'
                  size='S'
                  selectedOption={user.userGroup}
                  options={userGroups}
                  onChange={(option) => handleUserGroupChange(option, user)}
                />
              </div>

              <div className='marginTopXxs'>
                <CollapsibleNext
                  header={assessmentsHeader}
                  noBorders
                >
                  <div className={styles.list}>
                    { user.assessments
                    .filter((a) => {
                      const isAssessmentManager = user.userGroup?.value === PLATFORM_ROLES.ASSESSMENT_MANAGER;
                      return isAssessmentManager || a.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN;
                    })
                    .map((assessment) => {
                      const isRunBased = assessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN;

                      return (
                        <div className={styles.assessmentItem} key={assessment.id}>
                          <div className={styles.listRow}>
                            { !isRunBased && (
                              <>
                                <Checkbox
                                  name={assessment.name}
                                  checked={assessment.active}
                                  disabled={assessment.disabled}
                                  onChange={(value) => handleAssessmentChange(value, user, assessment)}
                                />
                                <span>{ assessment.price }</span>
                              </>
                            ) }

                            { isRunBased && (
                              <div className='bluTypeCopy'>{ assessment.name }</div>
                            ) }
                          </div>

                          { user.userGroup?.value === PLATFORM_ROLES.ASSESSMENT_MANAGER && (
                            <div className={styles.grantManagementAccess}>
                              <Checkbox
                                name={translate('grant_management_access_lbl')}
                                checked={assessment.grantManagementAccess}
                                onChange={(value) => {
                                  handleGrantManagementAccess(value, user.email, assessment.id);
                                }}
                              />
                            </div>
                          ) }
                        </div>
                      );
                    }) }
                  </div>
                </CollapsibleNext>
              </div>
            </div>
          );
        }) }
      </div>

      { plan.required && (
        <div className='marginTopM'>
          <CostOverview
            usersQuantity={usersQuantity}
            candidatesQuantity={candidatesQuantity}
            rmpQuantity={rmpQuantity}
            nineLevelsQuantity={nineLevelsQuantity}
          />
        </div>
      ) }

      { /* BUTTONS */ }
      <div className={styles.buttons}>
        <Button
          size='S'
          looks='secondary'
          onClick={() => {
            onBack();
          }}
        >
          { translate('back_lbl') }
        </Button>
        <Button
          size='S'
          disabled={processing}
          onClick={handleComplete}
        >
          { translate('complete_lbl') }
        </Button>
      </div>

      { plan.required && (
        <div className={classNames('copy', 'marginTopS')}>
          { translate('invite_users_stripe_billing_legal_text') }
        </div>
      ) }

      { (!plan.required && plan.type !== 'trial') && (
        <div className={classNames('copy', 'marginTopS')}>
          { translate('invite_users_external_billing_legal_text') }
        </div>
      ) }
    </div>
  );
};

export default SettingsAndCostOverview;
