// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './PageWrapper.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
import { useParams } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { useDispatch } from 'react-redux';
import { setRoute } from 'features/framework/storeNext/routeSlice';

// STORE NEXT

// CONFIG & DATA
const Config = {
  heartbeatInterval: 1000 * 60 * 3, // 3 minutes
};

// COMPONENT: PageWrapper
const PageWrapper = (props) => {
  const {
    children,
    route,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  // useEffect(() => {
  //   console.log('--- params ----');
  //   console.log('params', params);
  //   dispatch(setParams({ params }));
  //   dispatch(setReady(true));
  // }, [dispatch, params])

  // ROUTING
  useEffect(() => {
    if (!params || !route) return;
    // console.log('--- route ----');
    // console.log('route', route);
    const currentRoute = { ...route };
    // can't pass non-serializable data to store
    delete currentRoute.PageComponent;
    delete currentRoute.show;
    if (currentRoute.subRoutes) {
      currentRoute.subRoutes = currentRoute.subRoutes.map((subRoute) => {
        const newSubRoute = { ...subRoute };
        delete newSubRoute.PageComponent;
        delete newSubRoute.show;
        if (newSubRoute.subRoutes) {
          newSubRoute.subRoutes = newSubRoute.subRoutes.map((subSubRoute) => {
            const newSubSubRoute = { ...subSubRoute };
            delete newSubSubRoute.PageComponent;
            delete newSubSubRoute.show;
            return newSubSubRoute;
          });
        }
        return newSubRoute;
      });
    }

    // const index = nestedRoutes.find(route);

    const routeInfo = {
      currentRoute,
      params,
    };
    // console.log('PageWrapper.routeInfo', routeInfo);
    dispatch(setRoute(routeInfo));
  }, [ dispatch, route, params ]);


  // TOOLS
  useEffect(() => {
    const { tools = {}, inMainNav, inSubNav } = route;
    if (!tools || !translate) return;

    let lastTitle;
    let intervalId;

    const nav = inMainNav || inSubNav || {};

    if (tools.browserTitle || tools.browserTitleKey || nav.label || nav.labelKey) {
      const { browserTitle, browserTitleKey } = tools;
      const title = `bluquist - ${translate(browserTitleKey) || browserTitle || translate(nav.labelKey) || nav.label}`;
      lastTitle = document.title;
      document.title = title;
    }

    if (tools.heartbeat) {
      const interval = typeof (tools.heartbeat) === 'number' ? tools.heartbeat : Config.heartbeatInterval;

      intervalId = setInterval(() => {
        api.get('/core/user/heartbeat');
      }, interval);
    }

    return () => {
      if (lastTitle) {
        document.title = lastTitle;
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [ route, translate ]);

  return (
    <div className={styles.pageWrapper}>
      { children }
    </div>
  );
};

export default PageWrapper;
