// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useReducer, useEffect, useCallback, useRef,
} from 'react';
import styles from './DialogSequence.module.scss';

// TRANSLATIONS
import { getTranslationIds } from './DialogSequence.translations';

// COMPONENT REDUCER & LOGIC
import { init, reducer, initialState } from './DialogSequence.reducer';
import {
  isNextAllowed,
  copyDialogConfig,
  areAllQuestionsAnswered,
  // storageController,
} from './DialogSequence.logic';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DialogHeader } from './components/DialogHeader';
import {
  Button, Callout, ProgressBar, Modal,
} from 'ui/basic';


// UTILS
import { useTranslate } from 'utils/translator';
import { msToNumber } from 'utils/styleTools';


// STORE
import { useSelector } from 'react-redux';
import { selectCompanyHasExternalLegalAgreements } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
import { GLOBAL_CONFIG } from './DialogSequence.config';

const CONFIG = {
  progressDelayFactor: 0.6,
};

// COMPONENT: dialogSequence
const DialogSequence = (props) => {
  // PROPS
  const {
    // mandatory props
    type, // dialog type
    userId, // for persistence identification
    configOverride, // override of dialogConfig, mandatory but can be empty object

    // layout props
    showLanguageToggle,
    smallHeader,
    showCloseAsX = true,
    preventClose = false,
    preventCloseOnLastPage = false,
    closeWithoutPrompt = false,

    onAnswer = () => {},
    onCancel = () => {},
    onAllAnswers = () => {},
    onFinish = () => {},
    // onError = () => {},
  } = props;


  // SPECIAL HOOKS
  const translate = useTranslate();

  // STORE: CONFIGURATION
  const hasExternalLegalAgreements = useSelector(selectCompanyHasExternalLegalAgreements);

  // PROPS UPDATES, INITIALIZATION
  // LOADING
  const [ loading, setLoading ] = useState(false);
  const [ initialised, setInitialized ] = useState(false);

  useEffect(() => {
    // show loading bar and possible loading page until everything is available
    if (!type || !userId || !configOverride) {
      setLoading(true);
      return;
    }
    // wait with initialisation until loadingPage and loading is over
    if (loading) {
      return;
    }
    // don't reinitialise (for instance when loading is set by child)
    if (initialised) {
      return;
    }

    setLoading(false);

    // set initialState based on storageState or config
    let initialState;
    let dialogConfig = copyDialogConfig(type);
    // console.log('dialogConfig', dialogConfig);
    // console.log('configOverride', configOverride);
    // validate dialogConfig
    if (!dialogConfig) {
      setErrorMessage((
        <>
          Invalid dialog type: "
          { type }
          "
        </>
      ));
      return;
    }

    // give all pages an id (for answer tracking)
    dialogConfig.pages = dialogConfig.pages.map((page, index) => ({
      ...page,
      id: page.id || index,
    }));


    // configOverride
    dialogConfig = Object.assign(dialogConfig, configOverride);

    // NOTE: disabled local storage feature after DSGVO discussion with nils & jens on Aug 18, 2021
    // from storage
    // storageController.init(type, userId);
    // initialState = storageController.loadValidState(dialogConfig, []);

    // from config
    if (!initialState) {
      initialState = dialogConfig;
    }


    dispatchLocal({ type: 'reset' });
    dispatchLocal({ type: 'override', payload: initialState });
    dispatchLocal({
      type: 'start',
      payload: Date.now(),
    });
    setInitialized(true);
    // console.log('props updated', type, dialogConfig);

    // cleanup when there's a new call
    // so reducer persistence won't save new states with old keys
    return () => {
      // NOTE: disabled local storage feature after DSGVO discussion with nils & jens on Aug 18, 2021
      // if (storageController.isInitialised()) {
      //   storageController.reset();
      // }
    };
  }, [ type, userId, configOverride, loading, initialised ]);


  // REDUCER STATE
  const [ state, dispatchLocal ] = useReducer(reducer, initialState, init);


  // REDUCER STATE PERSISTENCE
  useEffect(() => {
    // console.log('local state', state);

    // NOTE: disabled local storage feature after DSGVO discussion with nils & jens on Aug 18, 2021
    // storageController.saveState(state);
    // const saved = storageController.saveState(state);
    // console.log(`${saved ? 'saved' : 'local'} state`, state);
  }, [ state ]);


  // STATE ON ALL ANSWERS
  const [ finishable, setFinishable ] = useState(false);
  const [ loadingEnd, setLoadingEnd ] = useState(false);
  const [ errorEnd, setErrorEnd ] = useState();
  useEffect(() => {
    if (!state.clickBlock) {
      // are we on last page?
      if (state.questionIndex === state.pages.length - 1) {
        const [ allAnswered, answers ] = areAllQuestionsAnswered(state);
        // are all questions answered?
        if (allAnswered) {
          setLoadingEnd(true);
          onAllAnswers(
            answers,
            () => {
              allowEnd();
            },
            setErrorEnd,
          );
        } else {
          setErrorEnd('Error: some answers have been skipped.');
        }
      }
      // reset UI when navigating away from last page
      else {
        setFinishable(false);
        setLoading(false);
        setErrorEnd();
      }
    }
  }, [ state, onAllAnswers ]);

  const allowEnd = () => {
    // allow finish and unset loading
    setFinishable(true);
    setLoadingEnd(false);
    setErrorEnd();
  };


  // COMPONENT/UI STATE and REFS
  // page buildup/teardown
  const [ hide, setHide ] = useState(false);

  // modals
  const [ modalHurryShow, setModalHurryShow ] = useState(false);
  const [ modalReminderWasShown, setModalHurryWasShown ] = useState(false);
  useEffect(() => {
    const page = state.pages[state.questionIndex];
    if ( // show only when ... dialog has started, page is a question
      state.started === true // dialog has started
      && page && !page.isIntermission // page is not an intermission
      && !modalReminderWasShown // modal reminder hasn't been shown already
    ) {
      const delay = page.modalHurryDelay || state.modalHurryDelay || GLOBAL_CONFIG.modalHurryDelay;
      if (delay) {
        const timerId = setTimeout(() => {
          setModalHurryShow(true);
        }, delay);
        return () => {
          clearTimeout(timerId);
        };
      }
    }
  }, [ state.started, modalReminderWasShown, state.questionIndex, state.pages, state.modalHurryDelay ]);
  const [ modalCancelShow, setModalCancelShow ] = useState(false);

  // error message
  const [ errorMessage, setErrorMessage ] = useState('');

  // page & animation handling
  const [ pageAnimationsDirection, setPageAnimationsDirection ] = useState('forwards'); // 'forwards' || 'backwards'
  const [ page1, setPage1 ] = useState();
  const [ page1Animation, setPage1Animation ] = useState('begin');
  const [ page2, setPage2 ] = useState();
  const [ page2Animation, setPage2Animation ] = useState('begin');


  // TRANSLATIONS
  const translationIds = getTranslationIds(type);


  // PROGRESS
  const [ progress, setProgress ] = useState(0);
  const [ hideProgress, setHideProgress ] = useState(false);

  // CLICKBLOCK
  useEffect(() => {
    if (!state.clickBlock) return;

    const timerId = setTimeout(() => {
      dispatchLocal({ type: 'unsetClickBlock' });
    }, GLOBAL_CONFIG.minPageDuration);

    return () => {
      clearTimeout(timerId);
    };
  }, [ state.clickBlock ]);


  // DOM & ACCESSIBILITY
  const page1Ref = useCallback((node) => {
    if (node !== null) {
      node.focus(); // set focus for new page for easy keyboard controls
    }
  }, []);

  const page2Ref = useCallback((node) => {
    if (node !== null) {
      node.focus(); // set focus for new page for easy keyboard controls
    }
  }, []);

  // ANIMATIONS
  const [ animationTimes ] = useState({ // useState() so new calc between renders
    // forwards
    pageForwardsInTotalDuration: msToNumber(styles.pageForwardsInDuration) + msToNumber(styles.pageForwardsInDelay),
    pageForwardsInDuration: msToNumber(styles.pageForwardsInDuration),
    pageForwardsInDelay: msToNumber(styles.pageForwardsInDelay),
    pageForwardsOutDuration: msToNumber(styles.pageForwardsOutDuration),
    // backdwards
    pageBackwardsInTotalDuration: msToNumber(styles.pageBackwardsInDuration) + msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsInDuration: msToNumber(styles.pageBackwardsInDuration),
    pageBackwardsInDelay: msToNumber(styles.pageBackwardsInDelay),
    pageBackwardsOutDuration: msToNumber(styles.pageBackwardsOutDuration),
  });
  useEffect(() => {
    const page1Out = state.animationCount % 2;

    const currentPage = state.pages[state.questionIndex];

    if (!currentPage) {
      return;
    }

    setRenderOutside();

    // determine animation type & content
    if (page1Out) {
      setPage1Animation('out');
      setPage2Animation('in');
      setPage2(currentPage);
    } else {
      setPage1Animation('in');
      setPage1(currentPage);
      setPage2Animation('out');
    }

    // determine animation direction & animationOutDuration for reset
    let animationsDirection;
    let animationOutDuration;
    let progressDelay;
    if (state.questionIndex >= state.lastQuestionIndex) { // >= so it's forwards on start
      animationsDirection = 'forwards';
      animationOutDuration = animationTimes.pageForwardsOutDuration;
      progressDelay = animationTimes.pageForwardsInTotalDuration;
    } else {
      animationsDirection = 'backwards';
      animationOutDuration = animationTimes.pageBackwardsOutDuration;
      progressDelay = animationTimes.pageBackwardsInTotalDuration;
    }
    setPageAnimationsDirection(animationsDirection);

    // reset content after animation is done so going back
    // rerenders component and doesn't show end state of last animation
    const resetTimerId = setTimeout(() => {
      if (page1Out) {
        setPage1();
      } else {
        setPage2();
      }
    }, animationOutDuration);


    // progress
    // apply progress update after pageIn animation is done or later when hiding
    const showProgress = !currentPage.isIntermission
      || currentPage.showProgressBar
      || (currentPage.isIntermission && currentPage.countAsProgress);
    progressDelay = showProgress
      ? progressDelay * CONFIG.progressDelayFactor
      : progressDelay * 2;
    const progressTimerId = setTimeout(() => {
      setProgress(state.progress);
    }, progressDelay);
    // hide / show progress with a separate delay based on direction
    const progressHideDelay = !showProgress
      ? 0
      : progressDelay;
    const progressHideTimerId = setTimeout(() => {
      setHideProgress(!showProgress);
    }, progressHideDelay);

    // clearTimeout on
    return () => {
      clearTimeout(resetTimerId);
      clearTimeout(progressTimerId);
      clearTimeout(progressHideTimerId);
    };
  }, [ state.questionIndex, state.lastQuestionIndex, state.animationCount, state.progress, state.pages, state.allowBackNavigation, animationTimes ]);


  // STORE HOOKS


  // METHODS
  const close = (callback = () => {}) => {
    setHide(true);

    const duration = Number(styles.animationDurationLongMs) * 2;
    setTimeout(() => {
      callback();
    }, duration);
  };
  const cleanup = () => {
    // NOTE: disabled local storage feature after DSGVO discussion with nils & jens on Aug 18, 2021
    // storageController.removeState();
  };

  // ANSWSER
  const handleAnswer = useCallback((answer, questionAnimationDelay = 0) => {
    // get previous answer if it's available
    answer = answer === undefined
      ? state.answers[state.pages[state.questionIndex].id]
      : answer;
    const { questionIndex } = state;
    const time = Date.now();
    const animationTime = pageAnimationsDirection === 'forwards' || pageAnimationsDirection === 'begin'
      ? animationTimes.pageForwardsInTotalDuration + questionAnimationDelay
      : animationTimes.pageBackwardsInTotalDuration;

    // console.log('answer', answer);

    dispatchLocal({
      type: 'next',
      payload: {
        answer,
        time,
        animationTime,
      },
    });
    onAnswer({
      questionIndex,
      time: {
        totalTime: time - state.lastTime,
        animationTime,
      },
    });
  }, [ state, animationTimes, onAnswer, pageAnimationsDirection ]);


  const [ newAnswer, setNewAnswer ] = useState();

  // NAVIGATION

  const goBack = useCallback(() => {
    const currentPage = state.pages[state.questionIndex];
    if (currentPage.noPrev) return;

    dispatchLocal({ type: 'prev' });
    setNewAnswer();
  }, [ state ]);
  const goForward = useCallback((newAnswer) => {
    const currentPage = state.pages[state.questionIndex];
    const currentAnswer = state.answers[currentPage.id];
    handleAnswer(newAnswer || currentAnswer);
    setNewAnswer();
  }, [ state, handleAnswer ]);
  const confirm = (answerParam) => {
    const thisAnswer = answerParam || newAnswer;
    handleAnswer(thisAnswer);
    setNewAnswer();
  };


  // EVENT HANDLES
  const handleCancel = useCallback(() => {
    setModalCancelShow(false);
    close(onCancel);
  }, [ onCancel ]);
  const handleFinish = useCallback(() => {
    cleanup();
    close(onFinish);
  }, [ onFinish ]);
  const handleClosePrompt = useCallback(() => {
    if (finishable) {
      handleFinish();
    } else {
      if (preventClose) return;

      if (closeWithoutPrompt) {
        handleCancel();
      } else {
        setModalCancelShow(true);
      }
    }
  }, [ finishable, preventClose, handleFinish, closeWithoutPrompt, handleCancel ]);


  // KEYBOARD CONTROLS
  const prevBlock = useRef(false);
  const questionRef = useRef();
  const handleKey = useCallback((event) => {
    const { key } = event;

    if (state.clickBlock || errorMessage) {
      return;
    }

    // note: focusing Next Button and hitting enter will call onClick on button
    // and handleKey at the same time, causing setNewAnswer to be reset
    // that's why we clickblock here

    switch (key) {
      case 'Escape': {
        handleClosePrompt();
        return;
      }
      case 'ArrowUp':
      case 'ArrowLeft':
      case 'd':
      case 'k': {
        if (!prevBlock.current) {
          prevBlock.current = true;
          setTimeout(() => { prevBlock.current = false; }, GLOBAL_CONFIG.clickBlockDuration * 2);
          event.stopPropagation();
          goBack();
        }
        return;
      }
      case 'ArrowDown':
      case 'ArrowRight':
      case 'u':
      case 'j':
      case 'Enter': {
        event.stopPropagation();
        const page = state.pages[state.questionIndex];
        if (!page) {
          return;
        }

        const selectedAnswer = state.answers[page.id];
        const isOptional = typeof (page.isOptional) === 'function'
          ? page.isOptional(state)
          : page.isOptional;
        if (!isNextAllowed(isOptional, newAnswer, selectedAnswer)) {
          return;
        }

        if (!page.customControls || !questionRef?.current?.onEnter) {
          if (newAnswer?.isValid === false) {
            return;
          }
          goForward(newAnswer);
        } else {
          questionRef.current.onEnter();
        }
      }
      default: {

      }
    }
  }, [
    state,
    goForward,
    goBack,
    newAnswer,
    handleClosePrompt,
    errorMessage,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [ handleKey ]);

  // HELPERS

  // PAGE

  // const handleQuestionExit = () => {
  //   setModalCancelShow(true);
  // };


  // RENDER PAGE
  const renderPage = (page, pageRef) => {
    if (!page) return undefined;

    // console.log('page', page);

    const isQuestion = page && !page.isIntermission;
    const { passControls } = page;
    const { customControls } = page;
    const isOptional = typeof (page.isOptional) === 'function' ? page.isOptional(state) : page.isOptional;

    const QuestionComponent = page.Component;

    const selectedAnswer = page && page.id && state.answers[page.id] !== undefined
      ? state.answers[page.id]
      : undefined;

    let title = (hasExternalLegalAgreements && page.titleForHasExternalLegalAgreements)
      || (isOptional && page.titleOptional)
      || page.title;
    title = translate(title) || title;

    // console.log('selectedAnswer', selectedAnswer);
    // console.log('isOptional', isOptional);

    return (
      <div ref={pageRef} className={styles.pageContent}>
        { /* ERROR PAGE */ }
        { (errorMessage || errorEnd) && (
          <>
            <h4>Error</h4>
            <br />
            <br />
            { errorMessage || errorEnd }
          </>
        ) }

        { /* INTERMISSION */ }
        { !isQuestion && (
          <>
            { QuestionComponent && (
              <QuestionComponent
                ref={customControls && questionRef}
                state={state}
                answer={selectedAnswer}
                onAnswer={setNewAnswer}
                setRenderOutside={setRenderOutside}
                setModalCancelShow={setModalCancelShow}
                controls={passControls && renderControls(page, true)}
                goBack={customControls && goBack}
                confirm={customControls && confirm}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
              />
            ) }
            { !QuestionComponent && (
              page.render(handleAnswer, goBack, state, selectedAnswer, handleClosePrompt, setNewAnswer, setModalCancelShow)
            ) }
          </>
        ) }

        { /* QUESTION */ }
        { isQuestion && (
          <div className={styles.question}>

            { /* TITLE */ }
            <div className={styles.title}>
              { title }
            </div>


            { /* CONTENT */ }
            <div className={classNames(styles.content, {
              [styles.customLayout]: page.customLayout,
            })}
            >

              { /* PAGE RENDER */ }
              { QuestionComponent && (
                <QuestionComponent
                  ref={customControls && questionRef}
                  state={state}
                  answer={selectedAnswer}
                  onAnswer={setNewAnswer}
                  setRenderOutside={setRenderOutside}
                  setModalCancelShow={setModalCancelShow}
                  controls={passControls && renderControls(page, isOptional, selectedAnswer)}
                  goBack={customControls && goBack}
                  confirm={customControls && confirm}
                  extras={{
                    state,
                    hasExternalLegalAgreements,
                    setLoading,
                    // TODO: pass in set modal functions
                  }}
                />
              ) }
              { !QuestionComponent && (
                page.render(handleAnswer, goBack, state, selectedAnswer, handleClosePrompt, setNewAnswer)
              ) }

              { page.callout && (
                <div className={styles.formRow}>
                  <Callout>
                    { page.callout === true && (
                      translate('cp_nationality_callout')) }
                    { page.callout !== true && page.callout }
                  </Callout>
                </div>
              ) }

              { /* CONTROLS */ }
              { !passControls && !customControls && (
                <div className={classNames(styles.controls, {
                  [styles.customLayout]: page.customLayout,
                  [styles.wideControls]: page.wideControls,
                })}
                >
                  <div className={styles.controlsInner}>
                    { renderControls(page, isOptional, selectedAnswer) }
                  </div>
                </div>
              ) }

            </div>


          </div>
        ) }

      </div>
    );
  };


  // RENDER: CONTROLS
  const renderControls = (page, isOptional, selectedAnswer) => (
    <>
      { /* CONTROLS */ }
      <div className={styles.left}>
        { !page.noPrev && (
          <Button
            looks='secondary'
            onClick={() => {
              goBack();
              setNewAnswer();
            }}
          >
            { translate('back_lbl') }
          </Button>
        ) }
      </div>
      <div className={styles.right}>
        { !page.noNext && (
          <Button
            disabled={!isNextAllowed(isOptional, newAnswer, selectedAnswer)}
            onClick={() => {
              const answer = newAnswer || selectedAnswer;
              handleAnswer(answer);
              setNewAnswer();
            }}
          >
            { translate('continue_lbl') }
          </Button>
        ) }
      </div>
    </>
  );

  const [ renderOutside, setRenderOutside ] = useState();


  // RENDER: DialogSequence
  return (
    <div className={classNames(styles.dialogSequence, {
      [styles.hide]: hide,
      [styles.smallHeader]: smallHeader,
    })}
    >


      { /* OVERLAY */ }
      <div className={classNames(styles.overlay, {
        [styles.hide]: hide,
      })}
      >

        { /* HEADER */ }
        <div className={classNames(styles.header, {
          [styles.hide]: hide,
        })}
        >
          <DialogHeader
            title={translate(state.title) || state.title}
            showLanguageToggle={showLanguageToggle}
            showCloseAsX={showCloseAsX}
            preventClose={preventClose
              || (preventCloseOnLastPage
                && (state.pages && state.questionIndex === (state.pages.length - 1)))}
            actionLabel={finishable
              ? (translate('assessment_complete'))
              : (translate('assessment_abort'))}
            smallHeader={smallHeader}
            onClose={handleClosePrompt}
          />
        </div>

        { /* PROGRESSBAR */ }
        <div className={classNames(styles.progressBarContainer, {
          // hide on hide & on intermissions that don't countAsProgress
          [styles.hide]: hide,
          [styles.hideInBetween]: (hideProgress && !loading),
        })}
        >
          <div className={styles.progressBar}>
            <ProgressBar progress={progress} loading={loading || loadingEnd} />
          </div>
        </div>

        { /* DIALOG CONTENT */ }
        <div className={classNames(styles.dialogContent, {
          [styles.hide]: hide,
        })}
        >


          { /* PAGE 1 */ }
          <div className={classNames(
            styles.page,
            styles[pageAnimationsDirection],
            styles[page1Animation],
          )}
          >

            { /* RENDER PAGE1 */ }
            { renderPage(page1, page1Ref) }

          </div>

          { /* PAGE 2 */ }
          <div className={classNames(
            styles.page,
            styles[pageAnimationsDirection],
            styles[page2Animation],
          )}
          >

            { /* RENDER PAGE2 */ }
            { renderPage(page2, page2Ref) }

          </div>

        </div>

      </div>


      { /* MODALS */ }
      { renderOutside }

      { /* HURRY MODAL */ }
      { modalHurryShow && (
        <Modal
          header={translate('big5_ass_hintmodal_inactivity_title')}
          secondaryButtonTitle={translate('okay_lbl')}
          onConfirm={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
          onClose={() => {
            setModalHurryShow(false);
            setModalHurryWasShown(true);
          }}
        >
          { translate('big5_ass_hintmodal_inactivity_description') }
        </Modal>
      ) }

      { /* CANCEL MODAL */ }
      { modalCancelShow && (
        <Modal
          header={translate(translationIds.abortTitle)}
          redButtonTitle={translate(state.cancelButtonText) || translate('pp_button_logout')}
          secondaryButtonTitle={translate('resume_assessment_lbl')}
          onConfirm={handleCancel}
          onClose={() => setModalCancelShow(false)}
        >
          { translate(state.cancelText) || translate('cp_cancel_description') }
        </Modal>
      ) }

    </div>
  );
};

export default DialogSequence;
