import React from 'react';
import styles from './DetailsPageSkeleton.module.scss';

const DetailsPageSkeleton = () => {
  const getLeftSideRows = () => {
    const rows = [];

    for (let i = 0; i < 4; i++) {
      rows.push(<div key={i} className={styles.row} />);
    }

    return rows;
  };

  const getRightSideRows = () => {
    const rows = [];

    for (let i = 0; i < 11; i++) {
      rows.push(
        <div key={i} className={styles.contentBlock}>
          <div className={styles.row} />
          <div className={styles.row} />
        </div>,
      );
    }

    return rows;
  };

  return (
    <div className={styles.detailPageSkeleton}>
      <div className={styles.left}>
        <div className={styles.top} />
        <div className={styles.content}>
          { getLeftSideRows() }
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.top} />
        { getRightSideRows() }
      </div>
    </div>
  );
};

export default DetailsPageSkeleton;
