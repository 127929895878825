const downloadDialogFromBlob = (
  blob,
  fileName,
  contentType = 'application/octet-stream',
) => {
  if (!blob) {
    throw new Error('`blob` is missing');
  }

  if (!fileName) {
    throw new Error('`fileName` is missing');
  }

  const event = document.createEvent('MouseEvents');
  const a = document.createElement('a');

  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = `${contentType}:${a.download}:${a.href}`;

  event.initMouseEvent(
    'click',
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null,
  );

  a.dispatchEvent(event);

  // window gains focus when download dialog is closed
  window.onfocus = () => {
    window.URL.revokeObjectURL(a.href);
  };
};

export default downloadDialogFromBlob;

// for future reference, this converts a binary string into a blob
// const raw = new Uint8Array(data.length);

// for (let i = 0; i < data.length; i++) {
//   raw[i] = data[i].charCodeAt(0);
// }

// const blob = new Blob([raw], { type: contentType });
