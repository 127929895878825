// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from '../QuestionMultipleChoice.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Checkbox } from 'ui/basic';

// UTILS
import { breakpoints, useBreakpoint } from 'utils/hooks';
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: MultipleChoiceOptions
const MultipleChoiceBlock = (props) => {
  // PROPS
  const {
    question,
    selectedValue,
    hint,
    onHelp = () => {},
    onAnswer = () => {},
  } = props;

  const {
    minAnswers = 1,
    maxAnswers,
    answerOptions,
  } = question;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();

  const [ multipleChoiceIds, setMultipleChoiceIds ] = useState([]);
  useEffect(() => {
    if (selectedValue) {
      setMultipleChoiceIds(selectedValue);
    }
  }, [ selectedValue ]);

  const handleOptionSelect = useCallback((optionId) => {
    const multipleChoiceIdsInternal = [ ...multipleChoiceIds ];
    if (multipleChoiceIdsInternal.includes(optionId)) {
      const index = multipleChoiceIdsInternal.indexOf(optionId);
      multipleChoiceIdsInternal.splice(index, 1);
    } else {
      multipleChoiceIdsInternal.push(optionId);
    }

    setMultipleChoiceIds(multipleChoiceIdsInternal);
  }, [ multipleChoiceIds ]);

  const nextButtonIsDisabled = !question.skipAnswer && ((minAnswers > 0 && multipleChoiceIds.length < minAnswers)
    || (maxAnswers > 0 && multipleChoiceIds.length > maxAnswers));

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    if (event.key === 'Enter' && !nextButtonIsDisabled) {
      onAnswer(multipleChoiceIds);
      return;
    }

    // NUMBER CONTROLS
    if (!Number.isNaN(Number(event.key))) {
      // the 10th option should be linked to the key '0'
      const eventKeyInternal = Number(event.key) === 0 ? 10 : Number(event.key);

      const selectedOption = answerOptions
      .find((option, index) => index + 1 === Number(eventKeyInternal));

      if (selectedOption) {
        event.preventDefault();
        handleOptionSelect(selectedOption.id);
      }
    }
  }, [
    multipleChoiceIds,
    answerOptions,
    nextButtonIsDisabled,
    handleOptionSelect,
    onAnswer,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  // RENDER: MultipleChoiceOptions
  return (
    <div className={classNames(styles.multipleChoiceBlock)}>
      <div className={styles.answerOptions}>
        { answerOptions?.map((answerOption) => (
          <div
            key={answerOption.id}
            className={styles.multipleChoiceOption}
          >
            <Checkbox
              name={translate(answerOption.label)}
              label={(answerOption.labelLight && bp.bpWidth > breakpoints.S.bpWidth)
                && answerOption.labelLight}
              size='M'
              checked={multipleChoiceIds?.includes(answerOption.id)}
              onChange={() => handleOptionSelect(answerOption.id)}
            />
          </div>
        )) }
      </div>

      { /* min answers validation */ }
      { (minAnswers > 0 && multipleChoiceIds.length < minAnswers) && (
        <div className={styles.hint}>
          { translate('question_multiple_choice_min_validation', [
            '{{min}}', minAnswers,
          ]) }
        </div>
      ) }

      { /* max answers validation */ }
      { (maxAnswers > 0 && multipleChoiceIds.length > maxAnswers) && (
        <div className={styles.errorHint}>
          { translate('question_multiple_choice_max_validation', [
            '{{max}}', maxAnswers,
          ]) }
        </div>
      ) }

      { question.skipAnswer && (
        <div
          role='button'
          tabIndex={0}
          className={styles.skip}
          onClick={() => onAnswer(null)}
          onKeyPress={() => onAnswer(null)}
        >
          { translate('question_can_not_answer_label') }
        </div>
      ) }

      <div className={styles.nextButton}>
        <Button
          size='M'
          disabled={nextButtonIsDisabled}
          onClick={() => onAnswer(multipleChoiceIds)}
        >
          { translate('wp_ass_selection_button_next') }
        </Button>
      </div>

      { !question.hideHelp && (
        <div className={styles.helpButton}>
          <Button
            size='S'
            looks='tertiary'
            onClick={onHelp}
          >
            { translate('assessment_help_button') }
          </Button>
        </div>
      ) }

      { /* HINT */ }
      { bp.bpWidth > breakpoints.S.bpWidth && (
        <div className={styles.hint}>
          <div className={styles.line2}>
            { translate('assmnt_hint_use_numbers_on_keyboard') }
          </div>

          <div className={styles.line2}>{ hint }</div>
        </div>
      ) }
    </div>
  );
};

export default MultipleChoiceBlock;
