// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './NewSkillsResult.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card } from 'ui/basic/containers/Card';
import { InfoTitle, Modal, SkeletonFlexible } from 'ui/basic';
import { Peer360ResultItem } from '../../Peer360Result/Peer360ResultItem';

// UTILS
import { useTranslate } from 'utils/translator/translator';
import { replace360PeerResults } from 'utils/assessment';
import { DownloadResultButton } from 'ui/molecules/DownloadResultButton';


// COMPONENT: NewSkillsResult
const NewSkillsResult = (props) => {
  // PROPS
  const {
    assessmentData,
    assessmentResults,
    showExportIcon = false,
    showInfoTitle = true,
    onExport = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // RESULTS DATA: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ resultsInternal, setResultsInternal ] = useState();
  useEffect(() => {
    if (!assessmentResults || !assessmentData) {
      setResultsInternal();
      return;
    }
    setResultsInternal(replace360PeerResults(assessmentData, assessmentResults));
  }, [ assessmentData, assessmentResults ]);

  // MODALS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ infoModalVisible, setInfoModalVisible ] = useState(false);

  // RENDER: NewSkillsResult
  return (
    <div className={classNames(styles.newSkillsResult)}>
      <Card>
        <div className={styles.title}>
          { !showInfoTitle && (
            <span className='bluTypeLabelL'>
              { assessmentData.title }
            </span>
          ) }

          { showInfoTitle && (
            <>
              <InfoTitle
                title={assessmentData.title}
                onClick={() => setInfoModalVisible(true)}
              />
              { showExportIcon && (
                <DownloadResultButton onExport={onExport} />
              ) }
            </>
          ) }
        </div>

        <div className='marginTopS'>
          { !resultsInternal
            ? <SkeletonFlexible noHeader fullWidth />
            : resultsInternal.map((resultItem) => (
              <Peer360ResultItem
                key={resultItem.id}
                segmentsCount={assessmentData.renderReferenceMax}
                data={resultItem}
                showInfo={false}
              />
            )) }
        </div>
      </Card>

      { infoModalVisible && (
        <Modal
          header={assessmentData.title}
          onClose={() => setInfoModalVisible(false)}
          secondaryButtonTitle={translate('close_lbl')}
        >
          { translate(assessmentData.description) }
        </Modal>
      ) }

    </div>
  );
};

export default NewSkillsResult;
