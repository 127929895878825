import React from 'react';
import styles from './ProfilePanel.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutFulfilled } from 'store/actions';
import { useTranslate } from 'utils/translator';
import { IS_ADMIN_FRONTEND } from 'utils/configuration';
import {
  getCompany,
  getEmail,
  getFirstName,
  getLastName,
  getProfession,
  getUserPicture,
} from 'store/selectors/currentUser';
import * as localStorage from 'utils/localStorage';

// OTHER COMPONENTS
import { Button, SidePanel } from 'ui/basic';


const ProfilePanel = (props) => {
  const { onClose, onEditProfile } = props;

  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const mail = useSelector(getEmail);
  const profession = useSelector(getProfession);
  const userPicture = useSelector(getUserPicture);
  const company = useSelector(getCompany);

  const name = (!firstName && !lastName) ? mail : `${firstName} ${lastName}`;

  const getAvatar = () => {
    if (userPicture) {
      return <img src={userPicture} alt='user' />;
    }
    if (!firstName && !lastName) {
      return mail.substring(0, 1);
    }
    return firstName.substring(0, 1) + lastName.substring(0, 1);
  };

  return (
    <SidePanel onClose={onClose}>
      <div className={styles.profilePanel}>
        <div className={styles.avatar}>
          { getAvatar() }
        </div>
        <div className={styles.name}>
          { name }
        </div>
        <div className={styles.occupation}>
          { profession }
        </div>
        <div className={styles.occupation}>
          { company }
        </div>
        <div className={styles.buttons}>
          { !IS_ADMIN_FRONTEND && (
            <Button
              size='S'
              looks='secondary'
              onClick={() => {
                history.push('/my-profile');
                onClose();
              }}
            >
              { translate('pp_button_myprofile') }
            </Button>
          ) }
          <Button
            size='S'
            looks='secondary'
            onClick={onEditProfile}
          >
            { translate('pp_button_editprofile') }
          </Button>
          <Button
            size='S'
            looks='secondary'
            onClick={() => {
              if (IS_ADMIN_FRONTEND) {
                history.push('/admin/settings');
              } else {
                history.push('/settings');
              }
              onClose();
            }}
          >
            { translate('pp_button_settings') }
          </Button>
          <Button
            size='S'
            looks='tertiary'
            onClick={() => {
              localStorage.logout();
              dispatch(logoutFulfilled());
              window.location.href = '/logged-out';
            }}
          >
            { translate('pp_button_logout') }
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default ProfilePanel;
