// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './TaskList.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import {
  Card, Modal, ProgressCircle, SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { capitalise, markdown } from 'utils/textTools';
import { getFullName } from 'utils/users';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: TaskList
const TaskList = (props) => {
  // PROPS
  const {
    assessments = [],
    tasks,
  } = props;

  // SPECIAL HOOKS
  const history = useHistory();
  const translate = useTranslate();

  const { CheckBig: Check, ArrowRight } = IconsSvg;

  const mapSetupPeerAssessmentData = (task) => {
    const { id, title, description } = task.assessment;

    return {
      id,
      label: translate('peer_setup_title', [ '{{assessment}}', title ]),
      buttonLabel: translate('task_list_open_setup_lbl'),
      redirectUrl: `/assessments/${id}/peer/settings`,
      title,
      description,
    };
  };

  const mapManagePeerAssessmentData = (task) => {
    const {
      id, title, description, peerProgress, peerGroups = {},
    } = task.assessment;

    const progressList = Object.entries(peerProgress)
    .filter(([ , { invited } ]) => invited !== 0)
    .map(([ key, {
      progress, invited, completed, completedAt,
    } ]) => {
      let progressValue = progress;
      if (key === 'self') {
        progressValue = progress === 100 && !completedAt ? 99 : progress;
      }

      return {
        label: (!progress || key === 'self')
          ? peerGroups[key]?.label || capitalise(key)
          : `${completed}/${invited} ${peerGroups[key]?.label || capitalise(key)}`,
        progress: progressValue,
      };
    });

    return {
      id,
      label: translate('task_list_check_assmnt_progress', [ '{{assessment}}', title ]),
      buttonLabel: translate('task_list_manage_assessment'),
      redirectUrl: `/assessments/${id}/peer/settings`,
      progress: progressList,
      title,
      description,
    };
  };

  const mapPeerAssessmentData = (task) => {
    const {
      id, title, description, progress, asPeer = {},
    } = task.assessment;

    const multiSessionEnabled = assessments.find((a) => a.id === id)?.multi_session;

    const replacements = [
      '{{assessment}}', title,
      '{{peer}}', getFullName(asPeer.forUser),
    ];
    const label = (!progress || (progress && multiSessionEnabled === false))
      ? translate('task_list_answer_peer_assmnt', replacements)
      : translate('task_list_finish_peer_assmnt', replacements);

    const buttonLabel = (!progress || (progress && multiSessionEnabled === false))
      ? translate('peer_360_teaser_button_start')
      : translate('task_list_continue_assessment');

    return {
      id,
      label,
      buttonLabel,
      redirectUrl: `/assessments/${id}`,
      title,
      description,
    };
  };

  const mapNonPeerAssessmentData = (task) => {
    const {
      id, title, description, progress,
    } = task.assessment;

    const multiSessionEnabled = assessments.find((a) => a.id === id)?.multi_session;

    const label = (!progress || (progress && multiSessionEnabled === false))
      ? translate('task_list_answer_assmnt', [ '{{assessment}}', title ])
      : translate('task_list_finish_assmnt', [ '{{assessment}}', title ]);

    const buttonLabel = (!progress || (progress && multiSessionEnabled === false))
      ? translate('peer_360_teaser_button_start')
      : translate('task_list_continue_assessment');

    return {
      id,
      label,
      buttonLabel,
      redirectUrl: `/assessments/${id}`,
      title,
      description,
    };
  };

  const mapSelfAssessmentData = (task) => {
    const {
      id, title, description, progress,
    } = task.assessment;

    const multiSessionEnabled = assessments.find((a) => a.id === id)?.multi_session;

    const label = (!progress || (progress && multiSessionEnabled === false))
      ? translate('task_list_answer_self_assmnt', [ '{{assessment}}', title ])
      : translate('task_list_finish_self_assmnt', [ '{{assessment}}', title ]);

    const buttonLabel = (!progress || (progress && multiSessionEnabled === false))
      ? translate('peer_360_teaser_button_start')
      : translate('task_list_continue_assessment');

    return {
      id,
      label,
      buttonLabel,
      redirectUrl: `/assessments/${id}`,
      title,
      description,
    };
  };

  const mapSurveyData = (task) => {
    const { id, name, description } = task.survey;

    const label = !task.progress
      ? translate('task_list_answer_survey', [ '{{survey}}', name ])
      : translate('task_list_finish_survey', [ '{{survey}}', name ]);

    const buttonLabel = !task.progress
      ? translate('task_list_start_survey_btn')
      : translate('task_list_continue_survey_btn');

    return {
      id,
      label,
      buttonLabel,
      redirectUrl: `/surveys/${id}/answer`,
      title: name,
      description,
    };
  };

  const mappedTasks = [];
  tasks?.forEach((task = {}) => {
    if (task.type === 'assessment') {
      const { peerAssessment, peerProgress, asPeer } = task.assessment;
      if (peerAssessment) {
        if (asPeer) {
          mappedTasks.push(mapPeerAssessmentData(task));
        } else {
          mappedTasks.push(peerProgress
            ? mapManagePeerAssessmentData(task)
            : mapSetupPeerAssessmentData(task));
        }
      } else {
        mappedTasks.push(mapNonPeerAssessmentData(task));
      }
    } else if (task.type === 'survey') {
      mappedTasks.push(mapSurveyData(task));
    } else if (task.type === 'self-assessment') {
      const { peerOnly } = task.assessment;
      if (!peerOnly) {
        mappedTasks.push(mapSelfAssessmentData(task));
      }
    }
  });

  const [ modalVisible, setModalVisible ] = useState(false);
  const [ modalHeader, setModalHeader ] = useState('');
  const [ modalDescription, setModalDescription ] = useState('');

  // HANDLERS
  const handleLearnMore = (task) => {
    setModalHeader(task.title);
    setModalDescription(markdown(task.description));
    setModalVisible(true);
  };

  const handleAction = (task) => {
    history.push(task.redirectUrl);
  };


  // RENDER: TaskList
  if (!tasks) {
    return <SkeletonFlexible />;
  }

  if (mappedTasks.length === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.taskList)}>
      <Card hasPaddingsForModal>
        <>
          <span className='bluTypeLabelL'>{ translate('task_list_title') }</span>
          <div className={styles.list}>
            { mappedTasks.map((task) => (
              <div
                key={task.id}
                className={styles.listItem}
              >
                <Check />
                <div className={styles.main}>
                  { task.label }

                  { task.progress && (
                    <div className={styles.progress}>
                      { task.progress.map((taskProgress) => (
                        <div
                          key={taskProgress.label}
                          className={styles.progressItem}
                        >
                          <ProgressCircle progress={taskProgress.progress} />
                          <span>{ taskProgress.label }</span>
                        </div>
                      )) }
                    </div>
                  ) }
                </div>
                <span
                  role='button'
                  tabIndex={0}
                  className={styles.learnMore}
                  onClick={() => handleLearnMore(task)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleLearnMore(task);
                    }
                  }}
                >
                  { translate('learn_more') }
                </span>

                <div
                  role='button'
                  tabIndex={0}
                  className={styles.clickable}
                  onClick={() => handleAction(task)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleAction(task);
                    }
                  }}
                >
                  <span>{ task.buttonLabel }</span>
                  <ArrowRight />
                </div>
              </div>
            )) }
          </div>
        </>
      </Card>

      { modalVisible && (
        <Modal
          header={modalHeader}
          secondaryButtonTitle={translate('close_lbl')}
          onClose={() => setModalVisible(false)}
        >
          { modalDescription }
        </Modal>
      ) }
    </div>
  );
};

export default TaskList;
