// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './Review.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import * as api from 'api';

// CONFIG & DATA
import { CURRENCY_SYMBOLS, PLANS } from 'features/+adminUG/config/payment.config';

// const Config = {};

// COMPONENT: Review
const Review = (props) => {
  // PROPS
  const {
    extras = {},
    goBack = () => {},
    confirm = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ errorMessage, setErrorMessage ] = useState();

  const [ orgName, setOrgName ] = useState();
  const [ address, setAddress ] = useState();
  const [ vatId, setVatId ] = useState();

  const [ userName, setUserName ] = useState();
  const [ email, setEmail ] = useState();
  const [ phoneNumber, setPhoneNumber ] = useState();

  const [ plan, setPlan ] = useState();
  useEffect(() => {
    const answers = extras?.state?.answers;
    const billingData = answers[1];

    if (billingData) {
      // SETTING ORG DATA
      setOrgName(billingData.orgName);
      setVatId(billingData.vatId);
      setAddress(
        <>
          <div>{ billingData.street }</div>
          <div>{ `${billingData.zip} ${billingData.city}` }</div>
          <div>{ translate(`config_country_${billingData.country?.toLowerCase()}`) }</div>
        </>,
      );

      // SETTING CONTACT PERSON DATA
      setUserName(`${billingData.firstName} ${billingData.lastName}`);
      setEmail(billingData.email);
      setPhoneNumber(billingData.phoneNumber);
    }

    // BILLING CYCLE
    if (answers[2]?.plan) {
      setPlan({
        id: answers[2].plan,
        name: answers[2].plan === PLANS.ANNUAL ? translate('annual') : translate('monthly'),
      });
    } else if (answers[0]?.plan) {
      setPlan({
        id: answers[0].plan,
        name: answers[0].plan === PLANS.ANNUAL ? translate('annual') : translate('monthly'),
      });
    }
  }, [ extras, translate ]);


  // CARD DATA
  const [ cardLast4Digits, setCardLast4Digits ] = useState();
  const [ expirationDate, setExpirationDate ] = useState();
  const [ country, setCountry ] = useState();
  useEffect(() => {
    api.get('/core/subscription/payment')
    .then(({ ok, status, data }) => {
      if (ok && status === 200 && data) {
        setCardLast4Digits(data.card?.last4);
        setExpirationDate(`${data.card?.expirationMonth}/${data.card?.expirationYear}`);
        setCountry(data.card?.country);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, []);


  // USER BILLABLE RESOURCES
  const [ userQuantity, setUserQuantity ] = useState();
  const [ userPrice, setUserPrice ] = useState();
  const [ userTotalPrice, setUserTotalPrice ] = useState();
  const [ userBillableResourcesRequested, setUserBillableResourcesRequested ] = useState();
  useEffect(() => {
    if (!plan || userBillableResourcesRequested) {
      return;
    }

    setUserBillableResourcesRequested(true);
    api.get('/core/subscription/invoice/resources', { plan: plan.id })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        const userBillableResources = data?.resources?.user;
        setUserQuantity(userBillableResources?.quantity);

        if (userBillableResources) {
          setUserPrice(`${userBillableResources.pricePerUnit / 100} ${CURRENCY_SYMBOLS[userBillableResources.currency]}`);
          setUserTotalPrice(`${userBillableResources.totalPrice / 100} ${CURRENCY_SYMBOLS[userBillableResources.currency]}`);
        }
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ plan, userBillableResourcesRequested ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ submitProcessing, setSubmitProcessing ] = useState();
  const handleConfirm = async () => {
    try {
      const { answers } = extras.state;
      const billingData = answers[1];

      const addressInternal = {
        city: billingData?.city,
        country: billingData?.country,
        line1: billingData?.street,
        postalCode: billingData?.zip,
      };

      const payload = {
        address: addressInternal,
        phone: billingData?.phoneNumber,
        billingContact: `${billingData?.firstName} ${billingData?.lastName}`,
        billingEntity: billingData?.orgName,
        billingEmail: billingData?.email,
      };

      if (billingData?.vatType && billingData?.vatId) {
        Object.assign(payload, {
          taxId: {
            type: billingData?.vatType,
            value: billingData?.vatId,
          },
        });
      }

      setSubmitProcessing(true);
      const contactDataResponse = await api.patch('/core/company/billing/contact', payload);

      if (contactDataResponse.data?.error) {
        setErrorMessage(contactDataResponse.data?.error?.errorMessage);
        setSubmitProcessing(false);
      } else {
        const planResponse = await api.post('/core/company/billing/plan', { plan: plan.id });
        if (planResponse.data?.error) {
          setErrorMessage(planResponse.data?.error?.errorMessage);
          setSubmitProcessing(false);
        } else {
          confirm({ isValid: true });
        }
      }
    } catch (error) {
      console.error(error.message);
      setErrorMessage(error.message);
    }

    return undefined;
  };

  // RENDER: Review
  return (
    <div className={classNames(styles.review)}>
      <div className='bluContent666'>
        <div className='bluTypeS'>
          { translate('upgrade_to_pp_review_title') }
        </div>

        { errorMessage
          && <div className={classNames('error', 'marginTopS')}>{ errorMessage }</div> }

        <div className={styles.items}>
          <div className='bluTypeLabelL'>{ translate('organization') }</div>

          <div className={styles.list}>
            <div className={styles.item}>
              <div>{ translate('org_name') }</div>
              <div>{ orgName || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('address_data') }</div>
              <div>{ address || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('vat_id') }</div>
              <div>{ vatId || '-' }</div>
            </div>
          </div>
        </div>

        <div className={styles.items}>
          <div className='bluTypeLabelL'>{ translate('upgrade_to_pp_billing_contact_person_title') }</div>

          <div className={styles.list}>
            <div className={styles.item}>
              <div>{ translate('contact_person') }</div>
              <div>{ userName || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('login_form_email_label') }</div>
              <div>{ email || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('phone_number') }</div>
              <div>{ phoneNumber || '-' }</div>
            </div>
          </div>
        </div>

        { /* BILLING DATA */ }
        <div className={styles.items}>
          <div className='bluTypeLabelL'>{ translate('payment_details') }</div>

          <div className={styles.list}>
            <div className={styles.item}>
              <div>{ translate('payment_method') }</div>
              <div>{ plan?.name || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('card_number') }</div>
              <div>{ cardLast4Digits ? `**** **** **** ${cardLast4Digits}` : '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('expiration_date') }</div>
              <div>{ expirationDate || '-' }</div>
            </div>

            <div className={styles.item}>
              <div>{ translate('country') }</div>
              <div>{ country || '-' }</div>
            </div>
          </div>
        </div>

        <div className={styles.items}>
          <div className='bluTypeLabelL'>{ translate('subscription_costs') }</div>

          <div className={styles.header}>
            <div>{ translate('profile_type') }</div>
            <div>{ translate('quantity') }</div>
            <div>{ translate('costs') }</div>
          </div>

          <div className={styles.list}>
            <div className={styles.itemWith3Cols}>
              <div>
                { plan?.id === PLANS.ANNUAL ? translate('billed_profiles_annual') : translate('billed_profiles_monthly') }
              </div>
              <div>{ userQuantity || '-' }</div>
              <div>{ userPrice || '-' }</div>
            </div>

            <div className={styles.itemWith2Cols}>
              <div>
                { plan?.id === PLANS.ANNUAL ? translate('total_costs_annual') : translate('total_costs_monthly') }
              </div>
              <div>{ userTotalPrice || '-' }</div>
            </div>
          </div>
        </div>

        <div className={classNames('bluTypeCopy', 'marginTopS')}>
          { translate('upgrade_to_pp_stripe_payment_info') }
        </div>

        <div className={styles.controls}>
          <Button
            size='S'
            looks='secondary'
            onClick={goBack}
          >
            { translate('back_lbl') }
          </Button>
          <Button
            size='S'
            disabled={submitProcessing}
            onClick={handleConfirm}
          >
            { translate('upgrade_to_pp_stripe_submit_btn') }
          </Button>
        </div>

        <div className={classNames('marginTopS', 'bluTypeCopy')}>
          { translate('upgrade_to_pp_hint') }
        </div>
      </div>
    </div>
  );
};

export default Review;
