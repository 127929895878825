import * as fromActionTypes from 'store/actionTypes/surveys';

const initialState = {
  participationsListLoading: false,
  participationsList: null,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case fromActionTypes.LIST_SURVEY_PARTICIPATIONS: {
      return {
        ...state,
        error: null,
        participationsListLoading: true,
      };
    }

    case fromActionTypes.LIST_SURVEY_PARTICIPATIONS_FULFILLED: {
      return {
        ...state,
        participationsList: action.payload.participations,
        participationsListLoading: false,
      };
    }

    case fromActionTypes.LIST_SURVEY_PARTICIPATIONS_REJECTED: {
      return {
        ...state,
        error: action.payload.error,
        participationsListLoading: false,
      };
    }

    default:
      return state;
  }
};
