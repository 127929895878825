import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';

function* setMenuItem(menuItem) {
  try {
    yield put(action.setMenuItemFulfilled(menuItem));
  } catch (error) {
    yield put(action.setMenuItemRejected(error));
  }
}
export default function* watchSetMenuItemRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.SET_MENU_ITEM);
      yield call(setMenuItem, payload);
    } catch (error) {
      yield put(action.setMenuItemRejected(error));
    }
  }
}
