export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_PENDING = 'GET_CURRENT_USER_PENDING';
export const GET_CURRENT_USER_FULFILLED = 'GET_CURRENT_USER_FULFILLED';
export const GET_CURRENT_USER_REJECTED = 'GET_CURRENT_USER_REJECTED';

export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
  payload: null,
});

export const getCurrentUserFulfilled = (payload) => ({
  type: GET_CURRENT_USER_FULFILLED,
  payload,
});

export const getCurrentUserRejected = (payload) => ({
  type: GET_CURRENT_USER_REJECTED,
  payload,
});

