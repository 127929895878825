import { call, put, takeEvery } from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/profile';
import * as fromActions from 'store/actions/profile';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { get } from 'api';
import { getMappedNineLevelsResults } from 'utils/assessment';

function* getProfile({ payload }) {
  try {
    const { userId, includeDeactivated } = payload;
    const { status, ok, data } = yield call(get, '/core/user/profile', { id: userId, includeDeactivated });

    if (ok && status === 200 && data.profile) {
      // change structure of 9levels assessment result to have negative and positive values for each level
      const nineLevelsAssessment = data.profile.find((p) => p.assessment === ASSESSMENT_TYPES.NINE_LEVELS);
      if (nineLevelsAssessment?.result) {
        const [ mappedResults, signatureValues ] = getMappedNineLevelsResults(nineLevelsAssessment.result.results);
        nineLevelsAssessment.result.results = mappedResults;
        nineLevelsAssessment.result.signatureValues = signatureValues;
      }

      yield put(fromActions.getProfileFulfilled({
        profile: {
          userId,
          assessmentResults: data.profile,
        },
      }));
    } else {
      yield put(fromActions.getProfileRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.getProfileRejected({ error }));
  }
}

export function* watchGetProfile() {
  yield takeEvery(fromActionTypes.GET_PROFILE, getProfile);
}
