// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef } from 'react';
import styles from './AttachmentsModalAdd.module.scss';
import { Button } from 'ui/basic';
import FormLink from './FormLink';
import FormDocument from './FormDocument';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from "classnames";

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: AttachmentsModalAdd
const AttachmentsModalAdd = (props) => {
  // PROPS
  const { onAttachmentAdd } = props;

  const fileInputRef = useRef();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ isLinkSaved, setIsLinkSaved ] = useState(false);
  const [ linkErrorMessage, setLinkErrorMessage ] = useState('');

  const [ isDocumentValid, setIsDocumentValid ] = useState(false);
  const [ isDocumentSaved, setIsDocumentSaved ] = useState(false);
  const [ documentErrorMessage, setDocumentErrorMessage ] = useState();

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const onLinkValid = () => {
    setIsLinkValid(true);
  };

  const onLinkInvalid = () => {
    setIsLinkValid(false);
  };

  const linkSavedCallback = () => {
    setIsLoading(false);
    setLinkErrorMessage('');
    setIsLinkSaved(true);
  };

  const linkErrorCallback = (errorMessageParam) => {
    setIsLoading(false);
    setLinkErrorMessage(errorMessageParam || 'Error');
  };

  const onLinkSubmit = (link) => {
    setIsLoading(true);
    onAttachmentAdd({ ...link, type: 'link' }, linkSavedCallback, linkErrorCallback);
  };

  const onAddMoreLinkClick = () => {
    setIsLinkSaved(false);
  };

  const onFileInputResetClick = (e) => {
    const el = fileInputRef.current;

    if (typeof el.reset === 'function') {
      el.reset(); // IE
    } else {
      el.value = '';
    }

    setIsDocumentValid(false);
    e.preventDefault();
  };

  const onDocumentValid = () => {
    setIsDocumentValid(true);
  };

  const onDocumentInvalid = () => {
    setIsDocumentValid(false);
  };

  const documentSavedCallback = () => {
    setIsLoading(false);
    setDocumentErrorMessage('');
    setIsDocumentSaved(true);
  };

  const documentSavedErrorCallback = (errorMessageParam) => {
    setIsLoading(false);
    setDocumentErrorMessage(errorMessageParam || 'Error');
  };

  const onDocumentSubmit = (doc) => {
    setIsLoading(true);
    onAttachmentAdd({ ...doc, type: 'file' }, documentSavedCallback, documentSavedErrorCallback);
  };

  const onAddMoreDocumentClick = () => {
    setIsDocumentSaved(false);
    setIsDocumentValid(false);
  };

  // RENDER: AttachmentsModalAdd
  return (
    <div className={styles.attachmentsModalAdd}>
      <h5>
        { translate('candidate_attachments_add_header') }
      </h5>

      <p>
        { translate('candidate_attachments_add_intro') }
      </p>

      { isLinkSaved === false && (
        <div className={styles.formLink}>
          <h6>
            { translate('candidate_attachments_add_link') }
          </h6>

          { linkErrorMessage && (
            <div className={styles.errorMessage}>{ linkErrorMessage }</div>
          ) }

          <FormLink
            mode='add'
            isLoading={isLoading}
            onValid={onLinkValid}
            onInvalid={onLinkInvalid}
            onSubmit={onLinkSubmit}
          >
            <div className={styles.linkAddButtonWrapper}>
              <Button
                looks='primary'
                size='s'
                type='submit'
                disabled={!isLinkValid || isLoading}
              >
                { translate('candidate_attachments_add_link') }
              </Button>
            </div>
          </FormLink>
        </div>
      ) }

      { isLinkSaved === true && (
        <div className={styles.linkSaved}>
          <h6>
            <span role='img' aria-label=''>
              👍
            </span>
            { ' ' }
            { translate('candidate_attachments_add_link_added_header') }
          </h6>

          <p>
            { translate('candidate_attachments_add_link_added_text') }
          </p>

          <Button
            looks='secondary'
            size='s'
            leadingIcon={IconsSvg.Plus}
            onClick={onAddMoreLinkClick}
          >
            { translate('candidate_attachments_add_link_more') }
          </Button>
        </div>
      ) }

      { isDocumentSaved === false && (
        <div>
          <h6>
            { translate('candidate_attachments_add_doc_header') }
          </h6>

          <p>
            { translate('candidate_attachments_add_doc_text') }
          </p>

          <FormDocument
            inputRef={fileInputRef}
            disabled={isLoading}
            onValid={onDocumentValid}
            onInvalid={onDocumentInvalid}
            onSubmit={onDocumentSubmit}
          >
            { isDocumentValid === true && (
              <>
                { /* Error Message */ }
                <div className={styles.errorMessage}>
                  { documentErrorMessage }
                </div>

                <div className={styles.documentAddButtonWrapper}>
                  <Button
                    looks='secondary'
                    size='s'
                    disabled={isLoading}
                    onClick={onFileInputResetClick}
                  >
                    { translate('candidate_attachments_add_doc_discard') }
                  </Button>

                  <Button
                    looks='primary'
                    size='s'
                    type='submit'
                    disabled={isLoading}
                  >
                    { translate('candidate_attachments_add_doc_add') }
                  </Button>
                </div>
              </>
            ) }
          </FormDocument>
        </div>
      ) }

      { isDocumentSaved === true && (
        <div>
          <h6>
            <span role='img' aria-label=''>
              👍
            </span>
            { ' ' }
            { translate('candidate_attachments_add_doc_addded_header') }
          </h6>

          <p>
            { translate('candidate_attachments_add_doc_addded_text') }
          </p>

          <Button
            looks='secondary'
            size='s'
            leadingIcon={IconsSvg.Plus}
            onClick={onAddMoreDocumentClick}
          >
            { translate('candidate_attachments_add_doc_more') }
          </Button>
        </div>
      ) }
    </div>
  );
};

export default AttachmentsModalAdd;
