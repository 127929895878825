// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
// import styles from './QuestionDemoCustomControls.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, InputNext } from 'ui/basic';

// UTILS
// import * as api from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useSelector } from 'react-redux';
// import {
//   selectUserFirstName,
//   selectUserLastName,
// } from 'features/framework/storeNext/configurationSlice';


// CONFIG & DATA
// const Config = {};


// COMPONENT: QuestionDemoCustomControls
const QuestionDemoCustomControls = (props, ref) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
    goBack = () => {},
    confirm = () => {},
    extras = {},
  } = props;

  useImperativeHandle(ref, () => ({
    onEnter: () => {
      handleNext();
    },
  }));

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();


  // COMPONENT/UI STATE and REFS
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ isValid, setIsValid ] = useState(false);

  // set existing answer
  useEffect(() => {
    setFirstName(answer.firstName);
  }, [ answer.firstName ]);
  useEffect(() => {
    setLastName(answer.lastName);
  }, [ answer.lastName ]);
  useEffect(() => {
    setIsValid(answer.isValid);
  }, [ answer.isValid ]);


  // ANSWER & VALIDATION
  // handlers
  const handleFirstName = (firstName) => {
    setFirstName(firstName);
    checkAnswers({ firstName, lastName });
  };
  const handleLastName = (lastName) => {
    setLastName(lastName);
    checkAnswers({ firstName, lastName });
  };
  const checkAnswers = ({ firstName, lastName }) => {
    let isValid = false;
    if (firstName?.length >= 2 && lastName?.length >= 2) {
      isValid = true;
    }

    setIsValid(isValid);
    const answer = { firstName, lastName, isValid };
    onAnswer(answer);

    return isValid;
  };

  const handleNext = () => {
    if (!checkAnswers({ firstName, lastName })) return;
    extras.setLoading(true);
    setTimeout(() => {
      extras.setLoading(false);
      confirm();
    }, 1500);
  };

  // RENDER: QuestionDemo
  return (
    <div className={classNames('bluBasicGridNoMargins')}>

      { /* CONTENT */ }
      <div className='bluContent664 bluTypeS bluTypeCenter'>
        Demo Question text: which can be quite long and will break line if too long!
      </div>
      <div className='bluContent bluTypeCopyStrong marginTopS'>
        <strong>Content/Content444:</strong>
        { ' ' }
        Über zahlreiche Assessments erhälst du
        umfassende Erkenntnisse über die individuellen Fähigkeiten und Potenziale
        von Mitarbeitenden und Kandidat:innen.
      </div>

      { /* CONTENT */ }
      <div className='bluContent'>
        { /* FORM */ }
        <div className='marginTopXs'>
          <InputNext
            size={size}
            value={firstName}
            label={translate('cp_firstname_placeholder')}
            onChange={handleFirstName}
          />
        </div>
        <div className='marginTopXs'>
          <InputNext
            size={size}
            value={lastName}
            label={translate('cp_lastname_placeholder')}
            onChange={handleLastName}
          />
        </div>
      </div>

      { /* CONTROLS */ }
      <div className='bluContent'>
        <div className='bluDialogControls'>
          <div>
            <Button
              looks='secondary'
              onClick={goBack}
            >
              { translate('back_lbl') }
            </Button>
          </div>
          <div>
            <Button
              looks='primary'
              disabled={!isValid}
              onClick={handleNext}
            >
              { translate('continue_lbl') }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(QuestionDemoCustomControls);
