export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_PENDING = 'CHANGE_LANGUAGE_PENDING';
export const CHANGE_LANGUAGE_FULFILLED = 'CHANGE_LANGUAGE_FULFILLED';
export const CHANGE_LANGUAGE_REJECTED = 'CHANGE_LANGUAGE_REJECTED';

export const changeLanguage = (payload) => ({
  type: CHANGE_LANGUAGE,
  payload,
});

export const changeLanguageFulfilled = (language) => ({
  type: CHANGE_LANGUAGE_FULFILLED,
  payload: language,
});
export const changeLanguageRejected = (error) => ({
  type: CHANGE_LANGUAGE_REJECTED,
  payload: error,
});
