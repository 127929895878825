// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  setFeatures,
  setAssessments,
  setCapabilities,
  setInitialisedExtended,
  setStateExtras,
  fetchConfigurationExtended,
  setCurrentLanguage,
  setIsCurrentLanguageFetching,
  setCurrentUserData,
  setConfigSwitches,
  setSettingsLanguage,
  setSettingsTranslations,
  setSettingsLanguages, selectSettingsTranslations, selectSettingsLanguage,
} from 'features/framework/storeNext/configurationSlice';


// CONFIG & DATA
// const Config = {};

// COMPONENT: LoggedIn
export const LoggedIn = (props) => {
  // PROPS
  const {
    tokenId, tokenExpiration,
    userId, userGroup,
    profileCompleted,
    userSettingsLoaded,
    onboardingCompleted,
    userFirstName, userLastName,
    features,
    assessments,
    capabilities,
    switches,
    stateExtras,
    currentUser,
    currentLanguage,
    isCurrentLanguageFetching,
    languages,
    translations,
  } = props;


  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();

  const externalSystemSupported = getIsExternalSystemIntegrationSupported();
  useEffect(() => {
    if (tokenId && tokenExpiration && (externalSystemSupported || (userId && userGroup))) {
      dispatch(login({
        tokenId,
        tokenExpiration,
        userId,
        userGroup,
        userFirstName,
        userLastName,
        profileCompleted,
        onboardingCompleted,
      }));

      if (!externalSystemSupported) {
        dispatch(fetchConfigurationExtended());
      }
    }
  }, [ dispatch, tokenId, tokenExpiration, userId, userGroup, profileCompleted,
    userFirstName, userLastName, userSettingsLoaded, onboardingCompleted,
    externalSystemSupported,
  ]);

  const settingsTranslations = useSelector(selectSettingsTranslations);
  const settingsLanguage = useSelector(selectSettingsLanguage);
  useEffect(() => {
    dispatch(setCurrentLanguage(currentLanguage));
    dispatch(setIsCurrentLanguageFetching(isCurrentLanguageFetching));
    dispatch(setSettingsLanguages(languages));

    if (!settingsTranslations || !Object.keys(settingsTranslations).length) {
      dispatch(setSettingsTranslations({ translations, translationsUpdated: false }));
      dispatch(setSettingsLanguage(currentLanguage));
    }
  }, [
    dispatch, currentLanguage, isCurrentLanguageFetching, languages, translations,
    settingsLanguage, settingsTranslations,
  ]);

  useEffect(() => {
    if (features && assessments && capabilities && switches && currentUser) {
      dispatch(setFeatures(features));
      dispatch(setAssessments(assessments));
      dispatch(setCapabilities(capabilities));
      dispatch(setConfigSwitches(switches));
      dispatch(setInitialisedExtended(true));
      dispatch(setCurrentUserData(currentUser));
    }
  }, [
    dispatch, features, assessments, capabilities, switches,
    currentUser,
  ]);

  // STORE: STATE EXTRAS
  useEffect(() => {
    dispatch(setStateExtras(stateExtras));
  }, [ dispatch, stateExtras ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LoggedIn
  return (
    <>
    </>
  );
};

export default LoggedIn;
