export const getPlatformRoles = store => (
  (store.roles && store.roles.roles) ? store.roles.roles : []
);

export const getOrgRoles = (store) => (
  store.roles && store.roles.orgRoles
);

export const getOrgRole = (store) => (
  store.roles && store.roles.orgRole
);

export const getUpdateOrgRoleError = (store) => (
  store.roles && store.roles.updateError
);
