// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './IterationControl.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button,
  ButtonCircular,
  Card,
  Modal,
  Toast,
  ProgressCircle,
  SkeletonFlexible,
} from 'ui/basic';
import { ParticipantsModal } from '../ParticipantsModal';

// UTILS
import { timestampToFullDate } from 'utils/dateTools';
import { useTranslate } from 'utils/translator';
import { useHistory } from 'react-router';
import { RunScope } from '../RunScope';
import { apiNext } from 'apiNext';

// STORE NEXT
// import { useDispatch } from 'react-redux';

// CONFIG & DATA
// const Config = {};

// COMPONENT: IterationControl
const IterationControl = (props) => {
  // PROPS
  const {
    entity,
    selectedRun,
    setSelectedRunId,
    runs = [],
    onEdit,
    horizontalView = false,
    isSurvey,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const historyIndex = runs.findIndex((el) => el.id === selectedRun?.id);
  const { includedUsers = 0, completedUsers = 0 } = selectedRun?.participation ?? {};
  const scopeAmount = selectedRun?.[selectedRun?.scope]?.length ?? 0;
  const externalAmount = selectedRun?.emails?.length ?? 0;
  const totalAmount = scopeAmount + externalAmount;

  const [ openRunScope, setOpenRunScope ] = useState(false);
  const [ showCloseModal, setShowCloseModal ] = useState(false);
  const [ showParticipantsModal, setShowParticipantsModal ] = useState(false);
  const [ showToast, setShowToast ] = useState(false);
  const [ closing, setClosing ] = useState(false);

  const handleParticipationClick = () => {
    if (totalAmount) {
      setShowParticipantsModal(true);
    }
  };

  // RENDER: action button
  const renderActionButton = () => {
    if (runs.every(({ closedAt }) => Boolean(closedAt))) {
      return (
        <Button
          size='S'
          onClick={() => history.push({
            pathname: `/${isSurvey ? 'surveys' : 'assessments'}/${entity.id}/new-run`,
            state: { survey: entity },
          })}
        >
          { translate('run_table_new_iteration') }
        </Button>
      );
    }

    if (!selectedRun.closedAt) {
      return (
        <Button
          size='S'
          disabled={closing}
          onClick={() => setShowCloseModal(true)}
        >
          { translate('iteration_control_end') }
        </Button>
      );
    }

    return null;
  };

  // RENDER: latestState
  const renderLatestState = () => {
    if (historyIndex) {
      return (
        <div
          role='presentation'
          className={styles.showCurrentResult}
          onClick={() => setSelectedRunId(runs[0]?.id)}
        >
          <IconsSvg.Loop />
          { translate('iteration_control_show_latest') }
        </div>
      );
    }

    return (
      <div className={classNames(styles.latestResultText, {
        [styles.hv]: horizontalView,
      })}
      >
        { !selectedRun.closedAt
          ? (
            <div className={styles.active}>
              <div className={styles.activeDot} />
              { translate('iteration_control_current') }
            </div>
          )
          : translate('iteration_control_latest') }
      </div>
    );
  };

  // RENDER: survey link
  const surveyLink = () => {
    if (selectedRun.closedAt || !selectedRun.openParticipation) {
      return null;
    }

    const [ baseUrl ] = window.location.href.split(`/${entity.id}/`);
    const link = `${baseUrl}/ext/${selectedRun.id}`;
    const handleClick = () => {
      navigator.clipboard?.writeText(link);
      setShowToast(true);
    };

    return (
      <div className={styles.survey}>
        <div className={classNames([ 'marginTopXs', styles.bold ])}>
          { `${translate('iteration_control_participation_link')}:` }
        </div>
        <div
          className={classNames([ 'bluTypeCopy', styles.shareLink, styles.blue ])}
          role='button'
          tabIndex={0}
          onClick={handleClick}
          onKeyPress={handleClick}
        >
          { link }
          <IconsSvg.Copy />
        </div>
        <div className={styles.note}>
          { translate('iteration_control_survey_notice') }
        </div>
      </div>
    );
  };

  // RENDER: Scope text
  const renderRunScopeContent = () => {
    if (selectedRun.scope === 'org') {
      return (
        <div className='bluTypeCopy'>
          { translate(`run_table_scope_${selectedRun.scope}`) }
        </div>
      );
    }

    const scopeText = [];

    if (isSurvey && externalAmount) {
      scopeText.push(translate(
        'run_table_scope_external',
        [ '{{amount}}', externalAmount ],
      ));
    }

    if (scopeAmount || !externalAmount) {
      scopeText.unshift(translate(
        `run_table_scope_${selectedRun.scope}`,
        [ '{{amount}}', scopeAmount ],
      ));
    }

    const text = scopeText.join(' + ');

    if (selectedRun.closedAt) {
      return (
        <div
          role='button'
          tabIndex={0}
          className={classNames([ 'bluTypeCopy', styles.blue ])}
          onClick={() => setOpenRunScope(true)}
          onKeyPress={() => setOpenRunScope(true)}
        >
          { text }
        </div>
      );
    }

    return (
      <>
        { text }
        <div
          role='button'
          tabIndex={0}
          className={classNames([ 'bluTypeCopy', styles.blue, styles.edit ])}
          onClick={() => setOpenRunScope(true)}
          onKeyPress={() => setOpenRunScope(true)}
        >
          <IconsSvg.Edit width={20} />
          { translate('candidate_attachments_edit') }
        </div>
      </>
    );
  };

  // RENDER: closing message
  const renderClosingMessage = () => {
    if (isSurvey) {
      return translate('survey_control_close_copy');
    }

    const amount = includedUsers - completedUsers;
    return (
      <>
        { translate('iteration_control_close_copy1') }
        { Boolean(amount) && translate('iteration_control_close_copy2', [ '{{amount}}', amount ]) }
      </>
    );
  };

  // RENDER: IterationControl
  if (!selectedRun) {
    return (
      <div className={classNames(styles.iterationControl)}>
        <Card hasPaddingsForModal>
          <SkeletonFlexible repeat={3} />
        </Card>
      </div>
    );
  }

  return (
    <div className={classNames(styles.iterationControl)}>
      <Card hasPaddingsForModal>
        <div className={styles.flexContainer}>
          <div className={classNames(styles.textContent)}>
            <span className={classNames(
              'bluTypeLabelL',
              { [styles.hv]: horizontalView },
            )}
            >
              { translate('iteration_control_date', [ '{{date}}', timestampToFullDate(selectedRun.createdAt) ]) }
            </span>
            { !horizontalView && renderLatestState() }
          </div>

          <div className={styles.controls}>
            <ButtonCircular
              looks='primary'
              icon='ArrowLeft'
              disabled={historyIndex === runs.length - 1}
              onClick={() => setSelectedRunId(runs[historyIndex + 1]?.id)}
            />
            <ButtonCircular
              looks='primary'
              icon='ArrowRight'
              disabled={historyIndex === 0}
              onClick={() => setSelectedRunId(runs[historyIndex - 1]?.id)}
            />
          </div>
        </div>

        { !horizontalView && selectedRun.description && (
          <div className={classNames([ 'marginTopXs', 'bluTypeCopy' ])}>
            { selectedRun.description }
          </div>
        ) }

        { horizontalView && (
          <div className={styles.centralHv}>
            <div>
              { renderLatestState() }
              { selectedRun.description && (
                <div className={classNames('bluTypeCopy', {
                  [styles.descriptionHv]: !selectedRun.closedAt,
                })}
                >
                  { selectedRun.description }
                </div>
              ) }
            </div>
            <div>
              { renderActionButton() }
            </div>
          </div>
        ) }

        <div className={classNames({
          [styles.details]: horizontalView,
        })}
        >
          { /* SCOPE */ }
          <div className={styles.scope}>
            <div className={classNames([ 'marginTopXs', styles.bold ])}>
              { `${translate('run_table_header_scope')}:` }
            </div>
            { renderRunScopeContent() }
          </div>

          { /* PARTICIPATION */ }
          <div className={styles.participation}>
            <div className={classNames([ 'marginTopXs', styles.bold ])}>
              { `${translate('run_table_header_participation')}:` }
            </div>
            <div className={styles.flexContainer}>
              <ProgressCircle progress={(completedUsers / includedUsers) * 100} />

              { selectedRun.closedAt
                ? (
                  translate('iteration_control_completed_ratio', [
                    '{{completed}}', completedUsers,
                    '{{included}}', includedUsers ])
                ) : (
                  <span
                    role='button'
                    tabIndex={0}
                    className={classNames(
                      'bluTypeCopy',
                      { [styles.blue]: Boolean(totalAmount) },
                    )}
                    onClick={handleParticipationClick}
                    onKeyPress={handleParticipationClick}
                  >
                    { translate('iteration_control_completed_ratio', [
                      '{{completed}}', completedUsers,
                      '{{included}}', includedUsers,
                    ]) }
                  </span>
                ) }
            </div>
          </div>

          { /* PARTICIPATION LINK */ }
          { surveyLink() }
        </div>

        { !horizontalView && renderActionButton() }
      </Card>

      { /* FLOATERS */ }
      { openRunScope && (
        <RunScope
          onClose={() => setOpenRunScope(false)}
          entity={entity}
          runId={selectedRun.id}
          isActiveRun={!selectedRun.closedAt}
          scope={selectedRun.scope}
          onAdd={onEdit}
          isSurvey={isSurvey}
        />
      ) }

      { showParticipantsModal && (
        <ParticipantsModal
          entity={entity}
          selectedRun={selectedRun}
          isSurvey={isSurvey}
          onClose={() => setShowParticipantsModal(false)}
        />
      ) }

      { showCloseModal && (
        <Modal
          header={translate(`${isSurvey ? 'survey' : 'iteration'}_control_close_title`)}
          primaryButtonTitle={isSurvey ? translate('iteration_control_end') : undefined}
          secondaryButtonTitle={translate('cancel_lbl')}
          redButtonTitle={isSurvey ? undefined : translate('confirm_lbl')}
          onClose={() => setShowCloseModal(false)}
          onConfirm={async () => {
            setShowCloseModal(false);
            try {
              setClosing(true);
              const urlBasePath = isSurvey ? 'surveys' : 'core/assessments';
              const urlMethod = isSurvey ? 'patch' : 'post';
              await apiNext[urlMethod](`${urlBasePath}/${entity.id}/run/${selectedRun.id}/close`);
            } catch (e) {
              console.error(e);
            } finally {
              setClosing(false);
              onEdit();
            }
          }}
        >
          { renderClosingMessage() }
        </Modal>
      ) }

      { showToast && (
        <Toast
          visibilityTime={5000}
          onClose={() => setShowToast(false)}
        >
          { translate('iteration_control_participation_link_copied') }
        </Toast>
      ) }
    </div>
  );
};

export default IterationControl;
