// REACT, STYLE, STORIES & COMPONENT
import React, { useRef } from 'react';

// ASSETS

// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';

// UTILS
import { joinNumbers } from 'utils/textTools';
import { setDecimalSeparator } from 'utils/userpreference';
import styles from './SpiderDiagramNext.module.scss';

// CONFIG & DATA
const DIAGRAM = {
  viewBox: '0 0 25 25',
  radius: 10.5,
  xOrigin: 12.5,
  yOrigin: 12.5,
  minRange: 0.3,
  labelRadius: 11.3,
  pointRadius: 0.15, // data point radius in viewbox coordinates
  pointRadiusHoverArea: 1.25,
  arcRadius: 20, // data lines/range radius
  viewBoxPixelFactor: 270 / 25, // css width / viewBox width for diagram (to position labels)
  ringsAmount: {
    min: 3,
    max: 10,
  },
};


// LOCAL CHILD COMPONENT: HoverLabel
const HoverLabel = (props) => {
  const {
    text,
    label,
    isVisible,
    onMouseOver,
    onClick,
    style,
  } = props;

  const transitionRef = useRef();

  return (
    <BluCSSTransition
      nodeRef={transitionRef}
      in={isVisible}
      classNames={{ ...styles }}
    >
      <div
        ref={transitionRef}
        className={styles.valueLabel}
        style={style}
        onMouseEnter={() => onMouseOver(true)}
        onMouseLeave={() => onMouseOver(false)}
        onClick={onClick}
        role='presentation'
      >
        <span className={styles.value} data-test='SpiderDiagramPointLabel'>
          { ` ${text} ` }
        </span>
        <span className={styles.text}>
          { ` ${label} ` }
        </span>
      </div>
    </BluCSSTransition>
  );
};


// COMPONENT: SpiderDiagramNext
const SpiderDiagramNext = (props) => {
  // PROPS
  const {
    dimensions = [],
    lineValues = [],
    lineValuesSecondary = [],
    rangeValues = [],
    dimensionsHovers = [],
    onHover,
    onDimensionClick,
    showTextLabels = true,
    diagramRange = [ 0, 10 ],
  } = props;

  // COMPONENT/UI STATE, DERIVED VARS and REFS
  const [ diagramRangeMin, diagramRangeMax ] = diagramRange;
  const diagramAbsRange = diagramRangeMax - diagramRangeMin;

  // METHODS
  const handleDimensionClick = (dimension) => {
    if (onDimensionClick) {
      onDimensionClick(dimension);
    }
  };

  // HELPERS, HANDLES, RENDERS
  // HELPER: getNormalisedRadius
  const getNormalisedRadius = (val) => Math.max(0, val - diagramRangeMin) * (DIAGRAM.ringsAmount.max / diagramAbsRange);
  // HELPER: getRadialPoint
  const getRadialPoint = (angle, radius = DIAGRAM.radius) => {
    const x = radius * Math.cos(angle) + DIAGRAM.xOrigin;
    const y = radius * Math.sin(angle) + DIAGRAM.yOrigin;
    return [ x, y ];
  };
  // HELPER: getRadialAngle
  const getRadialAngle = (dimensionIndex) => {
    // calculate angle and offset by 90 degrees to start on vertical line
    const angle = (dimensionIndex * 2 * Math.PI) / dimensions.length
      - Math.PI / 2;

    return angle;
  };
  // HELPER: getDataPathString
  const getDataPathString = (values) => {
    let pathString = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensionIndex);
      const [ xPoint, yPoint ] = getRadialPoint(angle, getNormalisedRadius(values[dimensionIndex]));
      let pathFragment;

      // first index? add start point
      if (dimensionIndex === 0) {
        pathFragment = `M ${xPoint} ${yPoint}`;
      } else { // all other indices, create arc
        // add a nice smooth radius to the lines
        pathFragment = `A ${DIAGRAM.arcRadius} ${DIAGRAM.arcRadius} 0 0 1 ${xPoint} ${yPoint}`;
      }

      // last index? close the shape with another arc
      if (dimensionIndex === dimensions.length - 1) {
        const anglePointEnd = getRadialAngle(0);
        const [ xPointEnd, yPointEnd ] = getRadialPoint(anglePointEnd, getNormalisedRadius(values[0]));
        pathFragment += ` A ${DIAGRAM.arcRadius} ${DIAGRAM.arcRadius} 0 0 1 ${xPointEnd} ${yPointEnd}`;
      }

      return pathFragment;
    });
    // join coordinates
    pathString = pathString.join(' ');
    return pathString;
  };

  // RENDER: GridCircles
  const renderSvgGridCircles = () => {
    const circlesAmount = Math.max(DIAGRAM.ringsAmount.min, Math.min(DIAGRAM.ringsAmount.max, diagramAbsRange));
    const svgGridCircles = [];
    for (let i = 1; i <= circlesAmount; i += 1) {
      svgGridCircles.push(
        <circle
          key={i}
          className={styles.gridCircle}
          cx={DIAGRAM.xOrigin}
          cy={DIAGRAM.yOrigin}
          r={((i / circlesAmount) * DIAGRAM.ringsAmount.max) - Number(styles.strokeWidth)}
        />,
      ); // r=i-stroke-width
    }
    return svgGridCircles;
  };

  // RENDER: GridZeroCircle
  const renderSvgGridZeroCircle = () => (
    <circle
      className={classNames(styles.gridCircle, styles.zero)}
      cx={DIAGRAM.xOrigin}
      cy={DIAGRAM.yOrigin}
      r={((Math.abs(diagramRangeMin) / diagramAbsRange) * DIAGRAM.ringsAmount.max) - Number(styles.strokeWidth)}
    />
  );

  // RENDER: GridLines
  const renderSvgGridLines = () => {
    const svgGridLines = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensionIndex);
      const [ xLine, yLine ] = getRadialPoint(angle);

      return (
        <line
          key={angle}
          className={styles.gridLine}
          x1={DIAGRAM.xOrigin}
          y1={DIAGRAM.yOrigin}
          x2={xLine}
          y2={yLine}
        />
      );
    });

    return svgGridLines;
  };

  // RENDER: GridNumbers
  const renderSvgGridNumbers = () => {
    const svgGridNumbers = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensionIndex);
      const [ xPoint, yPoint ] = getRadialPoint(angle, DIAGRAM.labelRadius);
      return (
        <text
          key={angle}
          className={classNames(
            styles.gridNumber,
            { [styles.hover]: dimensionsHovers[dimensionIndex] },
          )}
          x={xPoint - 0.3}
          y={yPoint + 0.3}
          onMouseEnter={() => { onHover?.(dimensionIndex, true); }}
          onMouseLeave={() => { onHover?.(dimensionIndex, false); }}
          onClick={() => handleDimensionClick(dimension)}
        >
          { ` ${dimensionIndex + 1} ` }
        </text>
      );
    });
    return svgGridNumbers;
  };

  // RENDER: DataPoints
  const renderSvgDataPoints = () => {
    const svgDataPoints = dimensions.map((dimension, dimensionIndex) => {
      const angle = getRadialAngle(dimensionIndex);
      const [ xPoint, yPoint ] = getRadialPoint(angle, getNormalisedRadius(lineValues[dimensionIndex]));

      return (
        <g key={angle} data-test='SpiderDiagramPoint'>
          <circle
            className={classNames(
              styles.dataPoint,
              { [styles.hover]: dimensionsHovers[dimensionIndex] },
            )}
            cx={xPoint}
            cy={yPoint}
            r={DIAGRAM.pointRadius}
          />
          <circle
            className={classNames(
              styles.dataPointHoverArea,
              { [styles.hover]: dimensionsHovers[dimensionIndex] },
            )}
            cx={xPoint}
            cy={yPoint}
            r={DIAGRAM.pointRadiusHoverArea}
            onMouseEnter={() => { onHover(dimensionIndex, true); }}
            onMouseLeave={() => { onHover(dimensionIndex, false); }}
            onClick={() => handleDimensionClick(dimension)}
          />
        </g>
      );
    });
    return svgDataPoints;
  };

  // RENDER: DataLines
  const renderSvgDataLines = (orderSuffix = '') => (
    <path
      // eslint-disable-next-line react/destructuring-assignment
      d={getDataPathString(props[`lineValues${orderSuffix}`])}
      className={styles[`dataLine${orderSuffix}`]}
    />
  );

  // RENDER: DataRange
  const renderSvgDataRange = () => {
    if (!rangeValues?.length) {
      return null;
    }

    const minValues = [];
    const maxValues = [];

    rangeValues.map(([ value1, value2 ]) => {
      let min = Math.min(value1, value2);
      let max = Math.max(value1, value2);

      // make sure we have a minimum range of DIAGRAM.minRange
      // and make sure it's within max range
      if (max - min < DIAGRAM.minRange) {
        if (max < diagramRangeMax - DIAGRAM.minRange) {
          max += DIAGRAM.minRange;
        } else {
          min -= DIAGRAM.minRange;
        }
      }

      minValues.push(min);
      maxValues.push(max);
      return false;
    });

    const pathStringOuter = getDataPathString(minValues);

    const pathStringInner = getDataPathString(maxValues);

    const pathString = `${pathStringOuter} ${pathStringInner}`;
    return <path className={styles.dataRange} d={pathString} />;
  };

  // RENDER: TextLabels
  const renderTextLabel = () => dimensions.map((dimension, dimensionIndex) => {
    // get angle and point
    const angle = getRadialAngle(dimensionIndex);
    const point = getRadialPoint(angle, DIAGRAM.labelRadius);
    const pointInPixels = [
      // X: -12.5 to start from center
      (point[0] - 12.5) * DIAGRAM.viewBoxPixelFactor,
      point[1] * DIAGRAM.viewBoxPixelFactor,
    ];

    // positioning depending on left or right half of circle
    const style = {};
    // all dimension to the right and center top and center bottom
    if (dimensionIndex <= (dimensions.length) / 2) {
      style.marginTop = `${pointInPixels[1]}px`;
      style.marginLeft = `${pointInPixels[0]}px`;
    } else { // all dimensions to the left
      style.marginTop = `${pointInPixels[1]}px`;
      style.marginLeft = `calc(-${styles.labelWidth}px + ${pointInPixels[0] - 30/* offset */}px)`;
      style.textAlign = 'right';
    }

    return (
      <div
        key={angle}
        className={classNames(
          styles.textLabel,
          { [styles.hover]: dimensionsHovers[dimensionIndex] },
        )}
        style={style}
        onMouseEnter={() => { onHover(dimensionIndex, true); }}
        onMouseLeave={() => { onHover(dimensionIndex, false); }}
        onClick={() => handleDimensionClick(dimension)}
        role='presentation'
      >
        { dimension.label }
      </div>
    );
  });

  // RENDER: ValueLabels
  const renderValueLabels = () => dimensions.map((dimension, dimensionIndex) => {
    // get angle and point
    const lineValue = lineValues[dimensionIndex];
    const range = (rangeValues && rangeValues[dimensionIndex]) || [];

    const value = lineValue || Math.max(...range);
    const angle = getRadialAngle(dimensionIndex);
    const point = getRadialPoint(angle, getNormalisedRadius(value));
    const pointInPixels = [
      // X: -12.5 to start from center
      (point[0] - 12.5) * DIAGRAM.viewBoxPixelFactor,
      point[1] * DIAGRAM.viewBoxPixelFactor,
    ];

    // positioning
    const style = {};
    style.marginTop = `${pointInPixels[1]}px`;
    style.marginLeft = `${pointInPixels[0]}px`;

    // valueText
    const valueText = !Number.isNaN(lineValue)
      ? setDecimalSeparator(lineValue)
      : joinNumbers(range, '-', setDecimalSeparator);

    return (
      <HoverLabel
        key={angle}
        text={valueText}
        label={dimension.label}
        isVisible={dimensionsHovers[dimensionIndex]}
        onMouseOver={(entering) => onHover(dimensionIndex, entering)}
        onClick={() => handleDimensionClick(dimension)}
        style={style}
      />
    );
  });

  // RENDER: SpiderDiagramNext
  return (
    <div className={`${styles.spiderDiagramNext}`} data-test='SpiderDiagramNext'>
      { /* LABELS */ }
      <div className={styles.labels}>
        { showTextLabels && renderTextLabel() }
        { Boolean(lineValues?.length) && renderValueLabels() }
      </div>

      { /* SVG */ }
      <svg viewBox={DIAGRAM.viewBox}>
        { /* grid circles */ }
        { renderSvgGridCircles() }
        { /* grid lines */ }
        { renderSvgGridLines() }
        { /* grid zero circle */ }
        { diagramRangeMin < 0 && diagramRangeMax > 0 && renderSvgGridZeroCircle() }
        { /* grid numbers */ }
        { renderSvgGridNumbers() }
        { /* data range */ }
        { Boolean(rangeValues?.length) && renderSvgDataRange() }
        { /* data lines */ }
        { Boolean(lineValuesSecondary?.length) && renderSvgDataLines('Secondary') }
        { Boolean(lineValues?.length) && renderSvgDataLines() }
        { /* data points */ }
        { Boolean(lineValues?.length) && renderSvgDataPoints() }
      </svg>
    </div>
  );
};

export default SpiderDiagramNext;
