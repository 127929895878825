// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './MyProfileWellBeing.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { PillGroup } from 'ui/basic';
import { WellBeing } from './WellBeing';
import { BalancedYou } from './BalancedYou';
import { Garmin } from './Garmin';
import { Ist } from './Ist';

// UTILS
import { translate } from 'utils/translator/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { selectFeaturesNext } from 'store/selectors/configuration';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';

// CONFIG & DATA
// const Config = {};

const pillItemsObj = {
  'well-being': {
    id: 'well-being', labelKey: 'well_being_index', component: <WellBeing />,
  },
  garmin: {
    id: 'garmin', labelKey: 'garmin_connect', component: <Garmin />,
  },
  'balanced-you': {
    id: 'balanced-you', labelKey: 'Balanced You', component: <BalancedYou />,
  },
  ist: {
    id: 'ist', labelKey: 'IST', component: <Ist />,
  },
};


// COMPONENT: MyProfileWellBeing
const MyProfileWellBeing = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();

  const [ selectedItem, setSelectedItem ] = useState();

  const featuresNext = useSelector(selectFeaturesNext);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  const byAssessment = assessmentsFromStore.find((a) => a.id === ASSESSMENT_TYPES.BALANCED_YOU);
  const istAssessment = assessmentsFromStore.find((a) => a.id === ASSESSMENT_TYPES.IST);

  // GARMIN CONNECTION STATUS
  const garminAccountConnected = useSelector(fromGarminSelectors.selectGarminConnectionStatus);
  useEffect(() => {
    if (garminAccountConnected === null) {
      dispatch(fromGarminActions.getConnectionStatus());
    }
  }, [ dispatch, featuresNext, garminAccountConnected ]);

  const returnMappedItem = (key) => ({
    ...pillItemsObj[key],
    label: translate(pillItemsObj[key].labelKey),
  });

  const [ items, setItems ] = useState([]);
  useEffect(() => {
    const itemsInternal = [];

    if (featuresNext.wellbeing) {
      itemsInternal.push({ ...returnMappedItem('well-being') });
    }

    if (featuresNext.balancedYou && byAssessment?.completed) {
      itemsInternal.push({ ...returnMappedItem('balanced-you') });
    }

    if (featuresNext.garmin && garminAccountConnected) {
      itemsInternal.push({ ...returnMappedItem('garmin') });
    }

    if (featuresNext.ist && istAssessment?.completed) {
      itemsInternal.push({ ...returnMappedItem('ist') });
    }

    setItems(itemsInternal);
  }, [
    featuresNext.wellbeing,
    featuresNext.balancedYou,
    featuresNext.ist,
    featuresNext.garmin,
    byAssessment,
    istAssessment,
    garminAccountConnected,
  ]);

  useEffect(() => {
    if (!selectedItem && items.length > 0) {
      const route = new URLSearchParams(window.location.search).get('route');
      const index = Math.max(0, items.findIndex(({ id }) => id === route));
      setSelectedItem(items[index]);
    }
  }, [ items, selectedItem ]);

  if (!selectedItem) {
    return null;
  }

  // RENDER: MyProfileWellBeing
  return (
    <div className={classNames(styles.myProfileWellBeing)}>
      <span className='bluTypeXs'>{ translate('wellbeing_ind_result_title') }</span>
      { items.length > 1 && (
        <PillGroup
          activeIndex={items.findIndex((item) => item.id === selectedItem.id)}
          looks='primary'
          pillItems={items}
          onPillClick={(pillItem) => {
            setSelectedItem(pillItem);
            history.push(`/my-profile/well-being?route=${pillItem.id}`);
          }}
        />
      ) }

      <div className={classNames({
        marginTopXs: items.length === 1,
      })}
      >
        { selectedItem.component }
      </div>
    </div>
  );
};

export default MyProfileWellBeing;
