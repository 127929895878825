// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './Garmin.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { GarminIndividualResult } from 'ui/molecules';
import { CollapsibleNext, InfoCard } from 'ui/basic';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import { BodyBatteryInsights } from './components/BodyBatteryInsights';
import { BodyBatteryStatistics } from './components/BodyBatteryStatistics';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';

// CONFIG & DATA

// COMPONENT: Garmin
const Garmin = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  // GARMIN USER SCORE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const garminUserScore = useSelector(fromGarminSelectors.selectGarminUserScore);
  const garminUserScorePending = useSelector(fromGarminSelectors.selectGarminUserScorePending);
  const recommendations = useSelector(selectRecommendationsUnarchived);

  const [ garminUserScoreRequested, setGarminUserScoreRequested ] = useState(false);

  const bodyBatteryClass = garminUserScore?.bodyBattery?.score?.class;
  useEffect(() => {
    if (!garminUserScore && !garminUserScoreRequested) {
      dispatch(fromGarminActions.getUserScore());
      setGarminUserScoreRequested(true);
    }
  }, [
    dispatch,
    garminUserScore,
    garminUserScoreRequested,
  ]);

  // GARMIN HISTORY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const garminUserHistory = useSelector(fromGarminSelectors.selectGarminUserHistory);
  const [ garminUserHistoryRequested, setGarminUserHistoryRequested ] = useState(false);
  useEffect(() => {
    if (!garminUserHistory && !garminUserHistoryRequested) {
      dispatch(fromGarminActions.getUserHistory());
      setGarminUserHistoryRequested(true);
    }
  }, [
    dispatch,
    garminUserHistory,
    garminUserHistoryRequested,
  ]);

  // RENDER: Garmin
  return (
    <div className={classNames(styles.garmin)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          <GarminIndividualResult
            result={garminUserScore}
            showPendingState={garminUserScorePending}
          />

          { garminUserScore && (
            <BodyBatteryInsights
              userScore={garminUserScore}
            />
          ) }

          { garminUserHistory && (
            <BodyBatteryStatistics
              history={garminUserHistory}
            />
          ) }
        </div>

        <div className={styles.right}>
          { bodyBatteryClass && (
            <InfoCard
              title={translate('garmin_ind_report_compact_header_title')}
              content={translate(`body_battery_${bodyBatteryClass}_copy_long`)}
            />
          ) }

          { recommendations
          .filter((el) => el.module === 'garmin')
          .map((el) => <RecommendationCard key={el.id} {...el} />) }

          <div className={styles.legal}>
            <CollapsibleNext
              header={(
                <span className='bluTypeLabel'>
                  { translate('body_battery_data_collection_goals_title') }
                </span>
              )}
              withBorders
            >
              { translate('body_battery_data_collection_goals_copy') }
            </CollapsibleNext>

            <CollapsibleNext
              header={(
                <span className='bluTypeLabel'>
                  { translate('body_battery_measurement_calculation_title') }
                </span>
              )}
              withBorders
            >
              { translate('body_battery_measurement_calculation_copy') }
            </CollapsibleNext>

            <CollapsibleNext
              header={(
                <span className='bluTypeLabel'>
                  { translate('body_battery_data_protection_title') }
                </span>
              )}
              withBorders
            >
              { translate('body_battery_data_protection_copy') }
            </CollapsibleNext>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Garmin;
