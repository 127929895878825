// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './SpiderChart.module.scss';

// 3RD PARTY
import { SpiderDiagramNext, CollapsibleNext } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';


// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';


// COMPONENT: SpiderChart
const SpiderChart = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    assessmentResults,
    assessmentResultsAll,
    segmentsCount = 10,
    isPeerAssessment = false,
  } = props;

  const {
    showResultTable = true,
  } = configuration;
  const {
    includeDimensions = [],
  } = content;

  const [ dimensionsHovers, setDimensionsHovers ] = useState([]);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const { isXs } = useBreakpoint();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  let dimensions = assessmentResults;
  if (includeDimensions.length) {
    // assessmentResultsAll containing all visible and hidden dimensions
    // https://blueexcellence.atlassian.net/browse/BDE-33
    dimensions = assessmentResultsAll;

    const allDimensions = [ ...dimensions, ...dimensions.map(({ subDimensions = [] }) => subDimensions) ]
    .flat();
    dimensions = includeDimensions
    .map((id) => allDimensions.find((el) => el?.id === id))
    .filter(Boolean);
  }

  const dimensionsLabels = dimensions.map(({ name: label }) => ({ label }));
  const lineValues = dimensions.map(({ result }) => result);
  const rangeValues = dimensions
  .filter(({ minResult, maxResult }) => Number.isFinite(minResult) && Number.isFinite(maxResult))
  .map(({ minResult, maxResult }) => [ minResult, maxResult ]);

  const setHover = (dimensionIndex, isHover) => {
    const dimensionsHoversNew = new Array(dimensions.length).fill(false);
    dimensionsHoversNew[dimensionIndex] = isHover;
    setDimensionsHovers(dimensionsHoversNew);
  };
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: HeaderRight
  const renderHeaderRight = (dimension) => {
    if (!isPeerAssessment) {
      return null;
    }

    const range = `${setDecimalSeparator(dimension.minResult)}-${setDecimalSeparator(dimension.maxResult)}`;
    if (isXs) {
      return range;
    }

    return (
      <div className={styles.collapsibleHeader}>
        <div>
          <div className={styles.title}>
            { translate('mind_journey_result__range_lbl') }
          </div>
          <div>
            { range }
          </div>
        </div>
        <div>
          <div className={styles.title}>
            { translate('role_result_lbl') }
          </div>
          <div>
            { setDecimalSeparator(dimension.result, 2) }
          </div>
        </div>
      </div>
    );
  };

  // RENDER: SpiderChart
  if (dimensions.length < 3 || dimensions.length > 10) {
    return null;
  }

  return (
    <div className={styles.spiderChart}>
      <SpiderDiagramNext
        dimensions={dimensionsLabels}
        dimensionsHovers={dimensionsHovers}
        lineValues={lineValues}
        rangeValues={rangeValues}
        onHover={setHover}
        diagramRange={[ 0, segmentsCount ]}
        showTextLabels
      />
      { showResultTable && dimensions.map((dimension, dimensionIndex) => (
        <CollapsibleNext
          key={dimension.name}
          showTopBorder={dimensionIndex === 0}
          headerSubtle={dimensionIndex + 1}
          header={dimension.name}
          headerHighlight={(isPeerAssessment && !isXs)
            ? null
            : setDecimalSeparator(lineValues[dimensionIndex], 2)}
          headerRight={renderHeaderRight(dimension)}
          isHovered={dimensionsHovers[dimensionIndex]}
          onMouseEnter={() => { setHover(dimensionIndex, true); }}
          onMouseLeave={() => { setHover(dimensionIndex, false); }}
          isExpandable={false}
        />
      )) }
    </div>
  );
};

export default SpiderChart;
