// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ImgPin.module.scss';

// ASSETS
import { ReactComponent as Pin } from './assets/pin.svg';


// STORE

// 3RD PARTY

// OTHER COMPONENTS
import ImgCircle from 'ui/basic/micro-ui/ImgCircle';

// UTILS

// CONFIG & DATA

// COMPONENT: ImgPin
const ImgPin = (props) => {
  // PROPS
  const {
    src, // string || promise
    fullLabel,
    label1,
    label2,
    onClick,
  } = props;

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: ImgPin
  return (
    <div
      className={`${styles.imgPin}`}
      onClick={onClick}
    >
      <div className={styles.circle}>
        <ImgCircle
          className='noBorder'
          src={src}
          fullLabel={fullLabel}
          label1={label1}
          label2={label2}
          size='Xs'
        />
      </div>
      <div className={styles.pin}>
        <Pin />
      </div>
    </div>
  );
};

export default ImgPin;
