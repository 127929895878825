// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './Ist.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { IstResult } from 'ui/molecules';
import {
  InfoCard,
  SkeletonFlexible,
} from 'ui/basic';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import { BookModal } from 'ui/molecules/IstResult/BookModal';
import { ExtraCard } from './ExtraCard';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector, useDispatch } from 'react-redux';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';
import { selectAssessments } from 'store/selectors/assessment';
import { getCurrentUser } from 'store/selectors/currentUser';
import { getAssessmentResult, getExtraCards } from 'store/actions';
import { DUMMY_CARDS } from '../../MyCoaching/MyCoaching.stories';


// CONFIG & DATA
// const Config = {};

// COMPONENT: Ist
const Ist = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const recommendations = useSelector(selectRecommendationsUnarchived);
  const assessmentsFromStore = useSelector(selectAssessments);
  const me = useSelector(getCurrentUser);

  const [ selectedCard, setSelectedCard ] = useState();
  const [ showBookCoaching, setShowBookCoaching ] = useState(false);

  const istAssessment = assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.IST);

  const hasResults = Boolean(istAssessment?.results?.length);

  useEffect(() => {
    if (!hasResults) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.IST));
    }
  }, [ dispatch, hasResults ]);

  const cards = istAssessment?.extraCards;
  useEffect(() => {
    if (!cards || cards.length === 0) {
      dispatch(getExtraCards(ASSESSMENT_TYPES.IST));
    }
  }, [ dispatch, cards ]);

  // RENDER: Ist
  if (!hasResults) {
    return (
      <div className={classNames(styles.ist)}>
        <SkeletonFlexible noHeader repeat={9} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.ist)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          <IstResult
            ist={istAssessment}
            user={me}
          />
        </div>

        <div className={styles.right}>
          <InfoCard
            title={translate('ist_info_title')}
            content={translate('ist_assessment_description')}
          />

          { cards?.map((card) => (
            <ExtraCard
              key={card.type}
              data={card}
              dateOfBirthProvided={Boolean(istAssessment?.registrationData?.date_of_birth)}
              onBook={() => {
                setSelectedCard(card);
                setShowBookCoaching(true);
              }}
            />
          )) }

          { recommendations
          .filter((el) => el.module === ASSESSMENT_TYPES.IST)
          .map((el) => <RecommendationCard key={el.id} {...el} />) }
        </div>
      </div>

      { showBookCoaching && (
        <BookModal
          type={selectedCard?.type}
          link={selectedCard?.url}
          showShortText={Boolean(selectedCard?.isPaid) || selectedCard?.type === 'upselling'}
          onClose={() => setShowBookCoaching(false)}
        />
      ) }
    </div>
  );
};

export default Ist;
