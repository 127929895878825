import React, { useState, useEffect } from 'react';

// 3RD PARTY
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { AssessmentNext } from 'ui/molecules/AssessmentNext';


// UTILS
import { initialiseAssessment } from 'utils/assessment';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import * as api from 'api';

// STORE
import { eventBus } from 'architecture/eventBus';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { getAssessmentResult } from 'store/actions';


// CONFIG

const RmpAssessmentPage = () => {
  // SPECIAL HOOKS
  const history = useHistory();
  const dispatch = useDispatch();

  // STORE
  const userId = useSelector(fromCurrentUserSelectors.getCurrentUserId);
  const currentLanguage = useSelector((state) => state.localisation && state.localisation.currentLanguage);

  // GET ASSESSMENT DATA
  const assessmentId = ASSESSMENT_TYPES.RMP;
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  useEffect(() => {
    if (!userId || !assessmentId || loadingAssessment || !currentLanguage) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.push('/');
      },
    })
    .then(({ questions, assessment }) => {
      // ignore previous answers
      setConfigOverride({
        title: assessment.title,
        description: assessment.description,

        allowBackNavigation: assessment.free_navigation,
        canContinueLater: assessment.multi_session,
        // modalHurryDelay: 5 * 1000,
        // modalHurryDelay: assessment.time_to_reminder * 1000,

        // // modalHelpContent: '[blu-markdown] **modal help content**',
        // // guidanceContent: '[blu-markdown] **guidance content**',
        // modalHelpContent: assessment.info_texts.question_help,
        // guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        // prevAnswers, // ignore previous answers

        languageDefault: currentLanguage,
        languageOptions: assessment.registrationSchema?.language?.options ?? [],
        genderOptions: assessment.registrationSchema?.gender?.options ?? [],
        countryOptions: assessment.registrationSchema?.region?.options ?? [],
        skipRegistration: assessment.registered && questions?.length > 0,
        registrationPendingExternal: assessment.registered && questions?.length === 0,
      });

      // set empty questions if registration is not ready or hasn't been completed
      const inflatedQuestions = (questions ?? []).map((question) => ({ ...question, hideLabelLight: true }));

      setAssessmentQuestions(inflatedQuestions);
    });
  }, [
    history,
    loadingAssessment,
    userId,
    assessmentId,
    assessmentQuestions,
    currentLanguage,
  ]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const handleAnswer = () => {
    api.get('core/user/heartbeat');
  };


  const handleAllAnswers = (answers, setFinishable) => {
    const answersInternal = Object.keys(answers).map((questionId) => ({
      question: questionId,
      content: answers[questionId],
    }));

    api.post(`/core/assessments/${assessmentId}/1/answers`, {
      answers: answersInternal,
    })
    .then((response) => {
      if (response.ok) {
        setFinishable(true);
        dispatch(getAssessmentResult(assessmentId));
        history.push('/my-profile');
      } else {
        console.error(response.data);
        eventBus.dispatch('assessmentNext.error', {
          message: response.data?.error?.errorMessage || response.data,
        });
      }
    })
    .catch((error) => {
      eventBus.dispatch('assessmentNext.error', {
        message: error.toString(),
      });
    });
  };

  return (
    <AssessmentNext
      type='rmp'
      questions={assessmentQuestions}
      userId={userId}
      configOverride={configOverride}
      showCopyright
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer} // session prolongation
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default RmpAssessmentPage;
