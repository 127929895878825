// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CostOverview.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { CollapsibleNext } from 'ui/basic';
import PricingTable from 'ui/basic/containers/PricingTable';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectPlan, selectPlanBillingPeriod, selectPlanId } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
import { getPriceFromCents } from 'features/+adminUG/config/payment.config';

// const Config = {};

// COMPONENT: CostOverview
const CostOverview = (props) => {
  // PROPS
  const {
    usersQuantity = 0,
    candidatesQuantity = 0,
    rmpQuantity = 0,
    nineLevelsQuantity = 0,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const plan = useSelector(selectPlan);
  const planId = useSelector(selectPlanId);
  const billingPeriod = useSelector(selectPlanBillingPeriod);
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ usersPrice, setUsersPrice ] = useState();
  const [ candidatesPrice, setCandidatesPrice ] = useState();
  const [ rmpPrice, setRmpPrice ] = useState();
  const [ nineLevelsPrice, setNineLevelsPrice ] = useState();
  const [ totalPrice, setTotalPrice ] = useState();

  const [ prices, setPrices ] = useState({});
  const [ pricesRequested, setPricesRequested ] = useState();
  useEffect(() => {
    if (pricesRequested) {
      return;
    }

    setPricesRequested(true);
    api.get('/core/company/billing/plan/prices', { plan: planId })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setPrices(data.prices);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ prices, planId, pricesRequested ]);

  useEffect(() => {
    const { user = {}, candidate = {}, assessments = {} } = prices;

    const usersPriceAll = user.pricePerUnit * usersQuantity;
    setUsersPrice(getPriceFromCents(usersPriceAll, user.currency) || 0);

    const candidatesPriceAll = candidate.pricePerUnit * candidatesQuantity;
    setCandidatesPrice(getPriceFromCents(candidatesPriceAll, candidate.currency) || 0);

    const rmpPriceAll = assessments.rmp?.pricePerUnit * rmpQuantity;
    setRmpPrice(getPriceFromCents(rmpPriceAll, assessments.rmp?.currency) || 0);

    const nineLevelsPriceAll = assessments['9levels']?.pricePerUnit * nineLevelsQuantity;
    setNineLevelsPrice(getPriceFromCents(nineLevelsPriceAll, assessments['9levels']?.currency) || 0);

    setTotalPrice(getPriceFromCents(candidatesPriceAll + rmpPriceAll + nineLevelsPriceAll));
  }, [
    prices,
    usersQuantity,
    candidatesQuantity,
    rmpQuantity,
    nineLevelsQuantity,
  ]);

  const [ summaryText, setSummaryText ] = useState();
  useEffect(() => {
    if (candidatesQuantity && !usersQuantity) {
      setSummaryText(translate('one_time_payment_summary', [ '{{amount}}', totalPrice ]));
      return;
    }

    if (usersQuantity) {
      setSummaryText(billingPeriod === 'monthly'
        ? translate('recurring_payment_monthly_summary', [ '{{oneTimeCost}}', totalPrice, '{{recurringCost}}', usersPrice ])
        : translate('recurring_payment_annual_summary', [ '{{oneTimeCost}}', totalPrice, '{{recurringCost}}', usersPrice ]));
    }
  }, [
    translate,
    billingPeriod,
    candidatesQuantity,
    usersQuantity,
    usersPrice,
    totalPrice,
  ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const COST_OVERVIEW_LIST = [
    {
      description: translate('settings_payment_cost_preview_header_description'),
      quantity: translate('quantity'),
      amount: translate('costs'),
      bold: true,
    },
    {
      description: translate('costs_candidates_profiles'),
      quantity: candidatesQuantity,
      amount: candidatesPrice,
    },
    {
      description: translate('settings_payment_cost_preview_assessment_rmp'),
      quantity: rmpQuantity,
      amount: rmpPrice,
    },
    {
      description: translate('settings_payment_cost_preview_assessment_9levels'),
      quantity: nineLevelsQuantity,
      amount: nineLevelsPrice,
    },
    {
      description: translate('costs_one_time'),
      amount: totalPrice,
      bold: true,
    },
    {
      description: billingPeriod === 'monthly'
        ? translate('costs_user_profiles_monthly_billing')
        : translate('costs_user_profiles_annual_billing'),
      quantity: usersQuantity,
      amount: usersPrice,
    },
    {
      description: billingPeriod === 'monthly' ? translate('costs_monthly') : translate('costs_annual'),
      amount: usersPrice,
      bold: true,
    },
  ];

  // plan.required = stripe payment handling
  // if billing scenario is other than stripe -> CostOverview component shouldn't be visible
  if (!plan.required) {
    return null;
  }

  // RENDER: CostOverview
  return (
    <div className={classNames(styles.costOverview)}>
      <span className='bluTypeLabelL'>{ translate('costs') }</span>
      <div className={classNames('bluTypeLabel', 'marginTopXxs')}>
        { summaryText }
      </div>
      <div className='marginTopXs'>
        <CollapsibleNext
          header={translate('cost_overview')}
          noBorders
        >
          <PricingTable list={COST_OVERVIEW_LIST} />

          <div className={classNames('bluTypeCopy', 'marginTopXs')}>
            { billingPeriod === 'monthly' && translate('costs_monthly_copy') }

            { billingPeriod === 'yearly' && translate('costs_annual_copy') }
          </div>
        </CollapsibleNext>
      </div>
    </div>
  );
};

export default CostOverview;
