// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './TableCard.module.scss';

// ASSETS

// 3RD PARTY

// OTHER COMPONENTS
import Card from 'ui/basic/containers/Card';


// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: TableCard
const TableCard = (props) => {
  // PROPS
  const {
    title,
    subHeaderChildren,
    column1Data,
    column2Data,
    children,
  } = props;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Component
  return (
    <Card>
      <div className={styles.tableCard}>
        <div className={styles.label}>{ title }</div>

        { /* SUB HEADER BLOCK */ }
        { subHeaderChildren
        && (
          <div className={styles.subHeader}>
            { subHeaderChildren }
          </div>
        ) }

        { /* COLUMNS */ }
        <div className={styles.columns}>
          { /* COLUMN HEADER */ }
          <div className={styles.header}>
            { column1Data && <div className={styles.headerItem}>{ column1Data.title }</div> }
            { column2Data && <div className={styles.headerItem}>{ column2Data.title }</div> }
          </div>

          { column1Data && column1Data.items.map((columnItem, index) => (
            <div className={styles.row} key={index}>
              { /* FIRST ROW ITEM */ }
              <div className={styles.rowItem}>
                <div className={styles.hint}>{ columnItem.label }</div>
                <div className={styles.copy}>{ columnItem.value || '-' }</div>
              </div>

              { /* SECOND ROW ITEM */ }
              { (column2Data && column2Data.items)
              && (
                <div className={styles.rowItem}>
                  <div className={styles.hint}>{ column2Data.items[index].label }</div>
                  <div className={styles.copy}>{ column2Data.items[index].value || '-' }</div>
                </div>
              ) }

            </div>
          )) }
        </div>

        { /* CHILDREN */ }
        { children }
      </div>
    </Card>
  );
};

export default TableCard;
