// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useFooterOffset } from 'utils/hooks';
import styles from './AssessmentPageWithFooter.module.scss';

// STORE

// CONFIG & DATA

// COMPONENT: AssessmentPageWithFooter
const AssessmentPageWithFooter = (props) => {
  // PROPS
  const {
    children,
    contentSmall = false,
    copyrightShort, // copyrightShort displayed only if supplied
    copyrightDescriptionHeader, // shows modal on click on copyrightShort if both are supplied
    copyrightDescriptionContent, // shows modal on click on copyrightShort if both are supplied
    nextButtonText, // required
    onClickNext, // disables button if not supplied
  } = props;

  const translate = useTranslate();
  const footerOffset = useFooterOffset(Number(styles.introFooterHeight.replace(/\D/g, '')));

  // COMPONENT/UI STATE and REFS
  const [ showModal, setShowModal ] = useState();

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: AssessmentPageWithFooter
  return (
    <div className={classNames(styles.assessmentPageWithFooter)}>

      <div className={styles.scrollableContent}>
        <div className={styles.grid}>
          <div className={classNames(styles.container, { [styles.small]: contentSmall })}>

            { /* CHILDREN */ }
            <div className={styles.children}>
              { children }
            </div>

            { /* COPYRIGHT */ }
            { copyrightShort && (
              <div className={styles.copyright}>
                <span role='presentation' onClick={() => setShowModal(true)}>
                  { copyrightShort }
                </span>
              </div>
            ) }
          </div>
        </div>
      </div>

      { /* FOOTER */ }
      <div
        className={styles.footer}
        style={{ top: footerOffset }}
      >
        <div className={styles.footerGrid}>
          <div className={styles.footerContent}>
            <Button
              size='L'
              disabled={!onClickNext}
              onClick={() => onClickNext()}
            >
              { nextButtonText }
            </Button>
          </div>
        </div>
      </div>

      { /* MODAL */ }
      { showModal && copyrightDescriptionHeader && copyrightDescriptionContent && (
        <div className={styles.modal}>
          <Modal
            header={copyrightDescriptionHeader}
            secondaryButtonTitle={translate('ok') || 'OK'}
            onClose={() => setShowModal(false)}
          >
            { copyrightDescriptionContent }
          </Modal>
        </div>
      ) }

    </div>
  );
};

export default AssessmentPageWithFooter;
