/* eslint-disable max-len */
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';


export const ROLE_ASSESSMENTS = {
  [ASSESSMENT_TYPES.BIG5]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
      {
        id: 1,
        name: 'Dimension 2',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.POTENTIAL]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
      },
      {
        id: 1,
        name: 'Dimension 2',
      },
    ],
  },
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
      {
        id: 1,
        name: 'Dimension 2',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
      {
        id: 1,
        name: 'Dimension 2',
        subDimensions: [
          { id: 1, name: 'Sub-dimension 1' },
          { id: 2, name: 'Sub-dimension 2' },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
      },
      {
        id: 1,
        name: 'Dimension 2',
      },
    ],
  },
  [ASSESSMENT_TYPES.RMP]: {
    resultSchema: [
      {
        id: 0,
        name: 'Dimension 1',
      },
      {
        id: 1,
        name: 'Dimension 2',
      },
    ],
  },
};

export const CONFIG_SWITCHES = [
  {
    id: 'candidatesInvite',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'candidatesView',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'company_leader_invite_user',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'employeesInvite',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'employeesView',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'rolesCreate',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'rolesMatch',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'rolesView',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'teamsCreate',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'teamsStaff',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'teamsView',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'vacanciesCreate',
    status: true,
    type: 'company_role_config',
  },
  {
    id: 'vacanciesView',
    status: true,
    type: 'company_role_config',
  },
];

export const DISC_RESULTS = {
  completed_date: '2022-10-10T11:26:17.717',
  adaptive_style_initials: 'CId',
  natural_style_initials: 'C',
  adaptive_style_indicator: '4525',
  natural_style_indicator: '3136',
  adaptive_wheel_location: '77',
  natural_wheel_location: '71',
  style_name: 'Fact-Finder',
  adaptive_i_score: 69.642857142,
  adaptive_d_score: 51.785714285,
  adaptive_s_score: 13.357142857,
  adaptive_c_score: 76.785714285,
  natural_c_score: 80.357142857,
  natural_d_score: 48.214285714,
  natural_i_score: 20.238214285,
  natural_s_score: 1.10,
  report_content: [
    {
      category: 'STYOV',
      name: 'Style Overview',
      text: [
        'Les \'Factuels(elles)\' sont soucieux(ieuses) de contrôler la qualité de leur travail et ont une capacité à développer une pensée critique poussée. Ils/Elles favorisent la logique et les faits, mais possèdent également des capacités intuitives q\'ls/elles utilisent en complément. Bien se préparer avant \'gir est pour eux/elles une chose essentielle. Ils/elles peuvent sembler timides, mais peuvent travailler avec toute personne ayant le même souci de qualité. Ils/Elles évitent \'ntrer en confrontation et, parce q\'ls/elles doivent «bien faire les choses», peuvent retarder les décisions. Si les \'Factuels(elles)\' font une erreur, ils/elles chercheront probablement des éléments complémentaires pour renforcer leur décision initiale.',
      ],
    },
    {
      category: 'STYEC',
      name: 'Emotional characteristic',
      text: [
        'Avoid unsafe or risky ideas or relationships.',
      ],
    },
    {
      category: 'STYGO',
      name: 'Goals',
      text: [
        'Safety and accomplishment through correctness.',
      ],
    },
    {
      category: 'STYHO',
      name: 'How others are valued',
      text: [
        'Others\' ability to effectively use logic and data.',
      ],
    },
    {
      category: 'STYIL',
      name: 'Influences group',
      text: [
        'Encourages and supports attention and focus on quality of evidence and analytical thinking.',
      ],
    },
    {
      category: 'STYVT',
      name: 'Value to the organization',
      text: [
        'Gets the facts, reviews the findings and brings analytical clarity to the project.',
      ],
    },
    {
      category: 'STYWO',
      name: 'Cautions',
      text: [
        'Subject to \'analysis paralysis\'; may try to indirectly impose a more controlled environment.',
      ],
    },
    {
      category: 'STYWU',
      name: 'Under Pressure',
      text: [
        'Can become overly alarmed and anxious in risky or uncertain work situations.',
      ],
    },
    {
      category: 'STYFE',
      name: 'Fears',
      text: [
        'Other\'s erratic behavior or facing strong criticism or blame.',
      ],
    },
    {
      category: 'BS',
      name: 'Behavioral Tendencies',
      text: [
        'Test, vous avez tendance à fixer des standards de qualité élevés, à vous-même comme aux autres, afin de minimiser les erreurs et les oublis. Si cela peut être une force, tant que vous restez conscient(e) qu\'il faut faire progresser le chiffre d\'affaires. La qualité a souvent un prix : prendre plus de temps minimisera sûrement les erreurs, mais cela peut aussi augmenter les coûts. Pensez à peser le pour et le contre avant de demander plus de temps pour vous assurer de la qualité de votre production dans le cadre d\'un projet.',
        'Vous avez un talent pour poser les bonnes questions afin d\'entrer en détails dans un sujet. Vos réponses indiquent que vous avez d\'excellentes capacités pour mener des études grâce à votre pensée analytique et la manière systématique dont vous recueillez et étudiez les informations. Vous pouvez travailler seul(e) lors de vos recherches ou poser des questions rationnelles en réunion. Votre perspicacité permet à votre équipe de prendre en considération toutes les données avant d\'en venir à une conclusion.',
        'Vous décidez en ayant à la fois recours à votre intuition et à votre esprit d\'analyse. Vous prenez soin de rééquilibrer votre raisonnement rationnel en laissant s\'exprimer une inspiration venant de vos tripes. Ce qui vous évite, soit de tirer des conclusions hâtives (sans prendre le soin d\'analyser) ou bien au contraire de souffrir de « paralysie décisionnelle » liée à une analyse trop poussée et détaillée.',
        'Les autres font appel à vous dès lors qu\'il s\'agit de répondre à des procédures complexes, car ils savent que vous aurez la réponse ou que vous savez o\u00F9 la trouver. Si vous recevez un mémo sur une procédure dans votre boîte e-mail, vous le lirez, tandis que d\'autres l\'effaceront pour ensuite venir vous demander comment faire les choses.',
        'Vous aimez être à l\'heure à vos rendez-vous et vous vous attendez à ce que les autres fassent de même : vous êtes un modèle de gestion du temps. Vous terminez vos tâches à temps, parfois même plus tôt que prévu.',
        'Vous êtes sceptique quand il s\'agit d\'effectuer des changements juste pour le plaisir de changer, surtout quand l\'alternative n\'est pas encore éprouvée. La nouveauté ne signifie pas toujours le mieux, comme vous l\'avez probablement  déjà testé par le passé. Test, votre scepticisme provient principalement de votre désir d\'atteindre des standards de qualité élevés et de votre inclinaison à prendre des décisions fondées sur une analyse rationnelle. Vous aimez être certain(e) de prendre la bonne décision, et ne concluez jamais sans avoir étudié attentivement la question.',
        'Vous faites preuve d\'une excellente pensée critique. Ceci est particulièrement important pour toute personne devant exprimer son opinion de manière convaincante face à une équipe ou à l\'ensemble des membres de l\'entreprise.',
        'Vous aimez vous assurer que vous faites les choses correctement du premier coup. Ce qui peut vous conduire à demander un délai supplémentaire à court terme pour éviter les erreurs sur le long terme. Cependant, certaines personnes peuvent ne pas comprendre que remettre en question les procédures ou faire une dernière vérification est un excellent moyen de gagner du temps et de l\'énergie à l\'avenir.',
      ],
    },
    {
      category: 'DO',
      name: 'Communication Tips',
      text: [
        'Faites la liste des avantages et des inconvénients des suggestions que vous faites.',
        'Structurez votre demande d\'aide ou votre appel à contributions.',
        'Concentrez-vous sur les aspects professionnels.',
        'Assurez-vous que les informations détenues par les autres soient exactes.',
        'Préparez votre travail à l\'avance. N\'essayez pas d\'improviser en utilisant juste votre charme.',
        'Faites votre part du travail, car Test fera la sienne.',
        'Donnez-lui du temps pour étudier le sujet et évaluer les résultats potentiels.',
      ],
    },
    {
      category: 'DONT',
      name: 'Communication Tips (Don\'ts)',
      text: [
        'Être trop dur(e).',
        'Être trop décontracté(e) ou informel(le).',
        'Être vague sur ce qu\'on attend du groupe.',
        'Être désorganisé(e) ou négligeant(e).',
        'Être irréaliste concernant les délais.',
        'Avoir l\'habitude de manipuler rapidement les idées.',
        'Utiliser une preuve ou des témoignages peu fiables.',
      ],
    },
    {
      category: 'MOT',
      name: 'Tend to be Motivated',
      text: [
        'Des environnements o\u00F9 les changements sont sous contrôle et effectués uniquement lorsqu\'ils s\'avèrent nécessaires.',
        'Des tâches spécialisées pour satisfaire votre curiosité naturelle et votre sens du détail.',
        'Des standards de qualité qui sont respectés par toute l\'entreprise, pas seulement par quelques personnes.',
        'Des procédures qui peuvent être améliorées sans être drastiquement changées.',
        'Des exemples et des informations spécifiques sur les changements suggérés dans les processus qui peuvent avoir un impact sur la qualité.',
        'Un environnement de travail avec un minimum d\'hostilité et de pression.',
        'Être inclus(e) à part entière dans le groupe.',
      ],
    },
    {
      category: 'MAN',
      name: 'Tend to Need',
      text: [
        'Une confiance accrue dans votre capacité à prendre des décisions.',
        'Des descriptions de postes présentées clairement, sans ambiguïté.',
        'Des missions exigeant beaucoup de précision et d\'exactitude, pour capitaliser sur votre grande attention aux détails.',
        'De déléguer plus souvent les tâches quotidiennes et la mise en oeuvre des procédures.',
        'Un plus large spectre de perspectives et de responsabilités.',
        'De passer moins de temps sur les détails et prêter plus d\'attention à la vue d\'ensemble.',
        'Des explications complètes sur les processus et les systèmes internes utilisés.',
      ],
    },
    {
      category: 'STRG',
      name: 'Strengths',
      text: [
        'Comme toute personne consciencieuse, vous faites preuve de sérieux dans ce que entreprenez.',
        'Vous prenez des décisions basées sur des données factuelles.',
        'Vous fournissez une contribution importante aux projets et aux processus, et vous êtes vigilant(e) quant aux problèmes potentiels et aux contrôles nécessaires pour les éviter.',
        'Vous êtes fréquemment vu(e) par les autres comme un(e) expert(e) dans votre domaine.',
        'Vous savez comment obtenir la coopération des autres de manière logique plutôt qu\'en l\'exigeant.',
        'Vous êtes conscient(e) des délais, et avez d\'excellentes compétences en matière de gestion du temps.',
        'Vous apportez aux projets une compétence unique alliant précision et intuition.',
      ],
    },
    {
      category: 'IMP',
      name: 'Areas for Improvement',
      text: [
        'Vous pouvez exiger une explication complète et des détails avant de prendre une décision.',
        'Lorsque vous formez des équipes, vous pouvez avoir tendance à sélectionner des personnes qui vous ressemblent.',
        'Vous pouvez parfois vous enfermer dans des règles, des réglementations et des procédures alors même qu\'il faudrait faire preuve de flexibilité.',
        'Vous pouvez avoir tendance à éviter d\'assumer vos responsabilités en cas de problèmes ou d\'échecs.',
        'Vous pouvez vous révéler trop prudent(e) quand vous prenez des décisions, ce qui vous conduit à réviser sans cesse votre propre opinion plutôt qu\'à prendre une position ferme.',
        'Vous pourriez avoir besoin de plus vous affirmer dans les réunions d\'équipe, afin que les autres puissent prendre vos opinions en considération.',
        'Vous pouvez parfois hésiter à prendre une décision appropriée lorsque vous êtes sous pression.',
      ],
    },
    {
      category: 'WS',
      name: 'Work Style Tendencies',
      text: [
        'Vous pouvez hésiter à faire confiance aux personnes nouvelles dans l\'entreprise, jusqu\'à ce qu\'elles aient établi leur crédibilité.',
        'Vous êtes probablement l\'un des rares membres de l\'équipe à avoir lu en détails toutes les procédures.',
        'Vous préférez gérer précisément votre temps : vous appréciez les réunions qui commencent et finissent à l\'heure.',
        'Vous avez tendance à être plus efficace dans une ambiance de travail paisible, peu conflictuelle.',
        'Vous êtes très concerné(e) par le contrôle de la qualité.',
        'Vous êtes quelque peu conventionnel(le) et prudent(e) quand il s\'agit de prendre des décisions ayant un impact sur la qualité.',
        'Lorsqu\'une erreur est faite ou que le travail est bâclé, vous en êtes immédiatement conscient(e).',
      ],
    },
    {
      category: 'JOB',
      name: 'Environments that Provide',
      text: [
        'Des standards et des procédures opérationnelles qui peuvent parfois être adaptés à des processus complexes.',
        'Des informations complètes, des détails et des exemples, sans lacunes ou surprises.',
        'Des encouragements pour vos aptitudes à développer un esprit critique.',
        'Une absence de prise de décisions sous une forte pression.',
        'Un environnement de travail avec une activité prévisible, pour garantir la qualité.',
        'Du temps pour penser et réfléchir aux avantages et aux inconvénients des solutions.',
        'Des missions hautement spécialisées et des domaines de responsabilité techniques.',
      ],
    },
    {
      category: 'PERCEPS',
      name: 'Self',
      text: [
        'Prudent(e)',
        'Respectueux(se)',
        'Expert(e)',
        'Une personne attentive à la qualité de ce qu\'elle produit',
      ],
    },
    {
      category: 'PERCEPO',
      name: 'Others',
      text: [
        'Inflexible',
        'Hésitant(e)',
        'Anxieux(euse)',
        'Une personne difficile',
      ],
    },
    {
      category: 'STRESSN',
      name: 'Stress - Need',
      text: [
        'De comprendre les principes et les détails',
        'D\'exactitude',
        'De traiter les informations avec un rythme plus posé',
      ],
    },
    {
      category: 'STRESSC',
      name: 'Stress - Conflict',
      text: [
        'Vous recourez souvent à diverses techniques indirectes pour modifier l\'environnement afin de le rendre plus favorable. Vous pouvez recourir à des règles et procédures peu connues et autres approches indirectes.',
        'Vous êtes assez mal à l\'aise face à un conflit ouvert, face à l\'agressivité et à la colère. Vous ferez tout ce que vous pouvez pour éviter ces situations ou pour éviter les personnes avec qui vous avez un désaccord.',
        'Vous avez tendance à garder à l\'esprit les conflits ou les points de vue contradictoires, à chercher la preuve que vous avez raison ou à chercher une nouvelle manière valable de voir les choses qui permette de concilier les deux points de vue.',
      ],
    },
    {
      category: 'STRESSH',
      name: 'Stress - Harmony',
      text: [
        'Soyez plus ouvert(e) avec vos amis et vos collègues, en partageant vos sentiments, vos besoins et vos préoccupations avec eux.',
        'Affirmez-vous devant vos managers, vos amis et vos collègues plutôt que de les éviter ou prétendre les suivre.',
        'Assurez-vous que le raisonnement qui conduit à vos décisions soit partagé. Ne pas le faire peut rendre celles-ci en apparence arbitraires .',
      ],
    },
  ],
  behavioral_tendencies: [
    {
      title_main: 'Reasoning',
      title_left: 'Intuition-based (I)',
      title_right: 'Evidence-based (C)',
      norm_low: 30.3571428575,
      norm_mid: 0.0,
      norm_high: 60.7142857145,
      natural: 80.059464286,
      natural_intensity: 'HI',
      natural_intensity_text: 'You frequently rely on data and evidence to ensure decisions reflect the right thing to do, and will seek comprehensive verification to determine precise and accurate judgments. You are likely think through things with careful and thoughtful consideration, often weighing risks and examining the proof and data to make decisions. Remember, the brain and the heart together make a great team. ',
      adapted: 53.5714285715,
      adapted_intensity: 'MOD',
      adapted_intensity_text: 'You may rely somewhat on your feelings and interactions with others to make decisions, and choose what is likely to be considered acceptable but will seek to back up judgments with evidence and verification. When reasoning, you likely rely on a balanced approach of logic and emotion, and look at the circumstances with a logical perspective and also paying attention to what feels right.',
      plot_x: 54,
      plot_y: 80,
      plot_name: 'The Team Support Behavior (C/I)',
      relation_key: 'C/I',
    },
    {
      title_main: 'Accuracy',
      title_left: 'Predictability (S)',
      title_right: 'Precision (C)',
      norm_low: 40.8928571425,
      norm_mid: 0.0,
      norm_high: 53.6785714285,
      natural: 70.1785714285,
      natural_intensity: 'HM',
      natural_intensity_text: 'You frequently focus on carefully and deliberately ensuring high-quality outcomes with great importance on accuracy, structure, order and precision in all you do. You are likely to focus on being and doing things right. While doing it the right way can impact success dramatically, it is also helpful to have dependability and uniformity in planning processes.',
      adapted: 73.214285714,
      adapted_intensity: 'HM',
      adapted_intensity_text: 'Consistent with natural style',
      plot_x: 73,
      plot_y: 70,
      plot_name: 'The Precision Behavior (C/S)',
      relation_key: 'C/S',
    },
    {
      title_main: 'Prioritizing',
      title_left: 'Results (D)',
      title_right: 'Rules (C)',
      norm_low: 44.642857143,
      norm_mid: 0.0,
      norm_high: 66.0714285715,
      natural: 66.0714285715,
      natural_intensity: 'HM',
      natural_intensity_text: 'You often focus on following established structural and procedural guidelines to ensure high-quality outcomes with great importance on accuracy, order and precision. You are likely to prioritize the rules rather than the results. While the rules and procedures are a key component to success and what should take precedence, be sure you know what the end result should be. ',
      adapted: 62.5,
      adapted_intensity: 'HM',
      adapted_intensity_text: 'Consistent with natural style',
      plot_x: 62,
      plot_y: 66,
      plot_name: 'The Rules vs. Results Behavior (C/D) ',
      relation_key: 'C/D',
    },
    {
      title_main: 'Self-Reliance',
      title_left: 'Collaborative (I)',
      title_right: 'Directive (D)',
      norm_low: 28.5714285715,
      norm_mid: 0.0,
      norm_high: 53.27375,
      natural: 63.9880357145,
      natural_intensity: 'HM',
      natural_intensity_text: 'You are quite results driven, focused on accomplishing things quickly and efficiently and are likely to do so mostly independently and directively. You will likely do your best work independently when you can manage your productivity and efficiency autonomously. Be sure you are not distancing yourself too much. ',
      adapted: 41.0714285715,
      adapted_intensity: 'MOD',
      adapted_intensity_text: 'You balance results and interaction, getting things done efficiently, but also involving others to get this accomplished as effectively as possible. You are likely to be productive and efficient whether working independently or in collaboration with others, depending on the circumstances and variables of the work. ',
      plot_x: 41,
      plot_y: 64,
      plot_name: 'The Directness Behavior (D/I)',
      relation_key: 'D/I',
    },
    {
      title_main: 'Careful Decision Making',
      title_left: 'Impulsive (I)',
      title_right: 'Cautious (S)',
      norm_low: 35.25,
      norm_mid: 0.0,
      norm_high: 62.6071428575,
      natural: 59.8808928575,
      natural_intensity: 'MOD',
      natural_intensity_text: 'You balance careful attention and consideration of risks, but may also act more impulsively, going with your gut and intuition. Your decisions can be based on a balanced approach of logic and emotion where you will do what feels right and also what makes sense while being attentive to risks. ',
      adapted: 30.3571428575,
      adapted_intensity: 'LM',
      adapted_intensity_text: 'You are somewhat impulsive based on feelings rather than taking the time to consider the risks and consequences. You are likely to make decisions spontaneously and emotionally, trusting your gut and going with what feels right. Sometimes it is important to see if it makes sense too, not just feels good.',
      plot_x: 30,
      plot_y: 60,
      plot_name: 'The People Interaction Behavior (S/I)',
      relation_key: 'S/I',
    },
    {
      title_main: 'Personal Drive',
      title_left: 'Others-driven (S)',
      title_right: 'Self-Driven (D)',
      norm_low: 30.1785714285,
      norm_mid: 0.0,
      norm_high: 52.321428571,
      natural: 54.107142857,
      natural_intensity: 'MOD',
      natural_intensity_text: 'Your determination is balanced between a self-driven and others-driven approach, focusing on actions to achieve results with awareness of risks and consequences of actions. You are likely driven by both a desire to meet your own needs and motivations, and support and help others in the process. ',
      adapted: 60.714285714,
      adapted_intensity: 'HM',
      adapted_intensity_text: 'You are somewhat self-determined, often focused on taking actions that achieve results and goals. You will likely be driven to action based on your own needs and motivations and are likely a self-starter. Be aware that it can be appropriate to support and help others as well. ',
      plot_x: 61,
      plot_y: 54,
      plot_name: 'The Self-Determination Behavior (D/S)',
      relation_key: 'D/S',
    },
    {
      title_main: 'Change Resistance',
      title_left: 'Drives Change (D)',
      title_right: 'Reluctant to Change (S)',
      norm_low: 47.678571429,
      norm_mid: 0.0,
      norm_high: 69.8214285715,
      natural: 45.892857143,
      natural_intensity: 'MOD',
      natural_intensity_text: 'You can be slow to accept or embrace change or more committed to your own thoughts and ideas during times of change, depending on the level of risk and expected outcome. There may be times when you actively accept and engage in change and other times you feel like more information and planning would be beneficial. You are likely to be on board, as long as things make sense.',
      adapted: 39.285714286,
      adapted_intensity: 'LM',
      adapted_intensity_text: 'You are likely to be more firm in times of change, preferring to lead and direct activities focused on results and solutions. You are likely to respond/interact in change by driving action and facing it head on as it comes. You may even want to change things just to see how it can be different. Sometimes keeping things consistent is good too. ',
      plot_x: 39,
      plot_y: 46,
      plot_name: 'The Accommodation Behavior (S/D)',
      relation_key: 'S/D',
    },
    {
      title_main: 'Customer & Team Interaction',
      title_left: 'Supporting (S)',
      title_right: 'Engaging (I)',
      norm_low: 37.3928571425,
      norm_mid: 0.0,
      norm_high: 64.75,
      natural: 40.1191071425,
      natural_intensity: 'MOD',
      natural_intensity_text: 'You can be engaging and persuasive while providing support and stability in your interactions with others. You are likely to balance the needs of others, creating a relationship and ensuring their needs are met. This can effectively create loyal and trusting relationships. ',
      adapted: 69.6428571425,
      adapted_intensity: 'HM',
      adapted_intensity_text: 'You are somewhat engaging, charming, persuasive, and influential, often connecting with others in a way that builds trust and confidence. You are more likely to focus on engaging with the others to create a relationship, interacting with them to build a friendship to ensure they will come back to work with you directly. Sometimes business should be just business. ',
      plot_x: 70,
      plot_y: 40,
      plot_name: 'The Vitality Behavior (I/S)',
      relation_key: 'I/S',
    },
    {
      title_main: 'Building Rapport',
      title_left: 'Results-Focused (D)',
      title_right: 'Relationships-Focused (I)',
      norm_low: 46.72625,
      norm_mid: 0.0,
      norm_high: 71.4285714285,
      natural: 36.0119642855,
      natural_intensity: 'LM',
      natural_intensity_text: 'You are somewhat results driven in your interactions, preferring not to connect socially unless there is a specific outcome or purpose. You are more likely to focus on results with a desire to reach a goal or complete a task, rather than connecting or building relationship. Remember, others may like to get to know you more when working together. ',
      adapted: 58.9285714285,
      adapted_intensity: 'MOD',
      adapted_intensity_text: 'Your interactions are driven by both a desire to connect with others socially, and to get the work done and reach results.  If you can do both at once, that\'s great!',
      plot_x: 59,
      plot_y: 36,
      plot_name: 'The Sociable Behavior (I/D)',
      relation_key: 'I/D',
    },
    {
      title_main: 'Providing Instruction',
      title_left: 'Reserved & Detailed (C)',
      title_right: 'Directive & Compulsive (D)',
      norm_low: 33.9285714285,
      norm_mid: 0.0,
      norm_high: 55.357142857,
      natural: 33.9285714285,
      natural_intensity: 'LM',
      natural_intensity_text: 'You are more likely to precisely follow established structural and procedural guidelines, and are aware of the need for accuracy and compliance to certain guidelines and protocol. Sometimes, bending the rules slightly is important to getting the best results.',
      adapted: 37.5,
      adapted_intensity: 'LM',
      adapted_intensity_text: 'Consistent with natural style',
      plot_x: 38,
      plot_y: 34,
      plot_name: 'The Individualistic Behavior (D/C)',
      relation_key: 'D/C',
    },
    {
      title_main: 'Work Process Alignment',
      title_left: 'Accuracy (C)',
      title_right: 'Consistency (S)',
      norm_low: 46.3214285715,
      norm_mid: 0.0,
      norm_high: 59.1071428575,
      natural: 29.8214285715,
      natural_intensity: 'LM',
      natural_intensity_text: 'Your process and follow through is often driven by upholding quality standards to be sure what you are doing is accurate and precise. You are likely to process information and follow through with exactness and precision as a focus. There are times when consistency is as important as accuracy. Don\'t forget to balance them. ',
      adapted: 26.785714286,
      adapted_intensity: 'LM',
      adapted_intensity_text: 'Consistent with natural style',
      plot_x: 27,
      plot_y: 30,
      plot_name: 'The Persistence Behavior (S/C)',
      relation_key: 'S/C',
    },
    {
      title_main: 'Expressing Openness',
      title_left: 'Structural (C)',
      title_right: 'Social (I)',
      norm_low: 39.2857142855,
      norm_mid: 0.0,
      norm_high: 69.6428571425,
      natural: 19.940535714,
      natural_intensity: 'LOW',
      natural_intensity_text: 'You are very comfortable when focused on the structure, detail and accuracy requiring adequate time for planning and consideration of consequences before acting. You are likely to be more confident with data, information and procedures that ensure precision. Remember, there are times when creating connection with others can boost your success as well. ',
      adapted: 46.4285714285,
      adapted_intensity: 'MOD',
      adapted_intensity_text: 'Your comfort is balanced between your ability to  interact with others and build personal connections, while still maintaining a focus on structure, detail and accuracy, and you may struggle with maintaining a consistent pace or focus. You can be confident with both social interactions and information to support your perspective. ',
      plot_x: 46,
      plot_y: 20,
      plot_name: 'The Self-Assured Behavior (I/C)',
      relation_key: 'I/C',
    },
  ],
};

export const ASSESSMENTS = {
  [ASSESSMENT_TYPES.PEER_360]: {
    id: 'peer-360',
    title: 'Example peer assessment',
    description: 'Example peer assessment',
    max_frequency: -1,

    layer: 2,

    default: true,
    protected: false,

    randomized: true,
    free_navigation: true,
    interstitial: false,
    render_results: 'scale',
    multi_session: true,

    info_texts: {
      required_time: '15-20 minutes',
      short_description: 'Dummy 360 assessment',
      long_description: '[blu-markdown]\nLong description of the dummy 360 assessment!',
      guidance_text: '[blu-markdown]\nDummy guidance text',
      question_help: 'Answer as intuitively as possible.',
      result_description: 'Dummy results description.',
    },

    peerAssessment: true,
    peerGroups: {
      colleagues: {
        label: 'Colleagues',
        description: 'Colleagues are peers that work on the same level as you. These should be people that you work with regularly.',
        required: true,
        minPeers: 3,
        maxPeers: 5,
        weight: 1,
      },
      manager: {
        label: 'Manager',
        description: 'Your manager is usually the person you report to, and that is responsible for setting your goals and personal development roadmap.',
        required: true,
        minPeers: 1,
        maxPeers: 1,
        weight: 1,
      },
      'direct-reports': {
        label: 'Direct reports',
        description: 'Direct reports are people in your team that you are directly responsible for.',
        required: false,
        minPeers: 2,
        maxPeers: null,
        weight: 1,
      },
      others: {
        label: 'Others',
        description: 'You can invite any other people, that you think can provide valuable feedback.',
        required: false,
        minPeers: 0,
        maxPeers: null,
        weight: 1,
      },
    },

    evaluation: [
      {
        type: 'weighted-average',
        normalize_to: {
          min: 1,
          max: 10,
        },
      },
    ],
    render_reference_input: 'slider',

    question_config: [
      {
        id: 'scale',
        question_type: 'scale',
        answer_from: 1,
        answer_to: 6,
        answer_step: 1,
        representation: 'boxes',
        answer_option_prefix: 'peer_ass_scale_item',
      },
      {
        id: 'text',
        question_type: 'free-text',
        multiline: true,
        answer_option_prefix: 'balanced_you_ass_scale_item_phy_new',
        min_answer_length: null,
        max_answer_length: 250,
      },
    ],

    result_dimensions: [
      {
        id: 'peer_1',
        name: 'Example peer result dimension 1',
      },
      {
        id: 'peer_2',
        name: 'Example peer result dimension 2',
      },
      {
        id: 'peer_3',
        name: 'String result dimension',
        type: 'string',
      },
    ],

    stages: [
      {
        questions: [
          {
            id: '1',
            label: 'Example label',
            text: 'Example question 1',
            mappings: [ { map_to: 'peer_1', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 1',
            },
          },
          {
            id: '2',
            label: 'Example label',
            text: 'Example question 2',
            mappings: [ { map_to: 'peer_1', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 2',
            },
          },
          {
            id: '3',
            label: 'Example label',
            text: 'Example question 3',
            mappings: [ { map_to: 'peer_1', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 3',
              label: 'Example peer label',
            },
          },
          {
            id: '4',
            text: 'Example question 4',
            mappings: [ { map_to: 'peer_2', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 4',
            },
          },
          {
            id: '5',
            text: 'Example question 5',
            mappings: [ { map_to: 'peer_2', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 5',
            },
          },
          {
            id: '6',
            text: 'Example question 6',
            mappings: [ { map_to: 'peer_2', weight: 1 } ],
            config: 'scale',
            peer: {
              for_self: true,
              for_peer: true,
              text: 'Peer text question 6',
            },
          },
          {
            id: '7',
            text: 'Example question 7',
            mappings: [ { map_to: 'peer_3' } ],
            config: 'text',
            peer: {
              for_self: false,
              for_peer: true,
              text: 'Peer text question 7',
            },
          },
        ],
      },
    ],
  },
};

export const IST_RESULTS = {
  completed: true,
  description: 'Test IST Description',
  evaluation: [
    {
      id: 'ist-base',
      normalize_to: {
        max: 100,
        min: 0,
      },
      type: 'weighted-average',
    },
    {
      id: 'ist-main',
      normalize_to: {
        max: 100,
        min: 0,
      },
      type: 'aggregate',
    },
    {
      id: 'ist-sub-binary',
      min_score: 1,
      type: 'binary',
    },
    {
      id: 'ist-aggregate',
      type: 'aggregate',
    },
    {
      id: 'ist-total',
      type: 'manual',
    },
    {
      id: 'stressors_list',
      type: 'manual',
    },
    {
      id: 'stress_amplifier',
      normalize_to: {
        max: 10,
        min: 0,
      },
      type: 'aggregate',
    },
    {
      id: 'phq',
      type: 'manual',
    },
    {
      id: 'phq_self_harm',
      type: 'aggregate',
    },
    {
      id: 'er-ratio',
      type: 'manual',
    },
    {
      id: 'eri',
      type: 'weighted-average',
    },
  ],
  id: 'ist',
  layer: 3,
  multi_session: true,
  peerAssessment: false,
  peerGroups: {},
  premium: false,
  purchasedAt: null,
  registrationRequired: true,
  render_results: 'ist',
  self_only: true,
  date: 1690280151,
  stages: [
    {
      lastViewed: 1690280151,
      length: 116,
      questionsAnswered: 116,
    },
  ],
  title: 'Integral Stress Test™',
  registrationData: {
    abdominal_girth: 131,
    activity: 'inactive',
    age: 44,
    dateOfBirth: 698439600,
    gender: 'd',
    height: 177,
    weight: 90,
  },
  results: [
    {
      id: 'bio_med_hist_stress',
      name: 'Do you feel subjectively stressed?',
      parentDimension: 'bio_med_hist',
      result: 1.0,
    },
    {
      id: 'bio_med_hist_traumas',
      name: 'As a child or as an adult, did you suffer from any psychic or physical traumas?',
      parentDimension: 'bio_med_hist',
      result: 1.0,
    },
    {
      id: 'bio_med_hist_neg_events',
      name: 'Have you been through any negative life events recently? (separation, bereavement, personal problems)',
      parentDimension: 'bio_med_hist',
      result: 1.0,
    },
    {
      id: 'bio_med_hist_illnesses',
      name: 'Have you gone through any serious illnesses?',
      parentDimension: 'bio_med_hist',
      result: 1.0,
    },
    {
      id: 'bio_med_hist_medications',
      name: 'Are you currently taking any medications?',
      parentDimension: 'bio_med_hist',
      result: 1.0,
    },
    {
      classification: 'vulnerable',
      id: 'bio_med_hist',
      name: 'Biography / Medical History',
      parentDimension: null,
      result: 0.0,
    },
    {
      id: 'symptoms_chronic_pain',
      name: 'I have chronic pain (back pain, joint pain or headaches) and / or muscle tensions (tense neck or shoulders).',
      parentDimension: 'symptoms',
      result: 1.0,
    },
    {
      id: 'symptoms_sleep_issues',
      name: 'I have trouble sleeping (falling asleep and/or staying asleep), an excessive need for sleep, and/or do not feel regenerated in the morning despite getting enough sleep.',
      parentDimension: 'symptoms',
      result: 2.0,
    },
    {
      id: 'symptoms_exhaustion',
      name: 'I often feel exhausted, tired and/or burnt out.',
      parentDimension: 'symptoms',
      result: 3.0,
    },
    {
      id: 'symptoms_worries_fears',
      name: 'I often have worries and/or fears or feel panic.',
      parentDimension: 'symptoms',
      result: 2.0,
    },
    {
      id: 'symptoms_feeling_down',
      name: 'I often feel down and/or sad, hopeless and/or feel less joy and interest in things I usually enjoy doing.',
      parentDimension: 'symptoms',
      result: 3.0,
    },
    {
      id: 'symptoms_conflict',
      name: 'In conflict situations I often lack composure, react angrily and/or am easily upset and short-tempered.',
      parentDimension: 'symptoms',
      result: 1.0,
    },
    {
      id: 'symptoms_restlessness',
      name: 'I feel restless and/or nervous during the day and/or I am not able to relax.',
      parentDimension: 'symptoms',
      result: 2.0,
    },
    {
      id: 'symptoms_helplessness',
      name: 'I often feel helpless, powerless or unable to act in or after a stressful situation.',
      parentDimension: 'symptoms',
      result: 2.0,
    },
    {
      id: 'symptoms_alcohol',
      name: 'I regularly drink alcoholic beverages.',
      parentDimension: 'symptoms',
      result: 3.0,
    },
    {
      id: 'symptoms_screen_time',
      name: 'I have a lot of screen time in my free time (emails, social media, TV, etc).',
      parentDimension: 'symptoms',
      result: 1.0,
    },
    {
      classification: 'vulnerable',
      id: 'symptoms',
      name: 'Symptoms',
      parentDimension: null,
      result: 33.33,
    },
    {
      id: 'stressors_occ_personal',
      name: 'Occupational stress (own personal perception)',
      parentDimension: 'stressors',
      result: 4.0,
    },
    {
      id: 'stressors_occ_external',
      name: 'Occupational stresses (external circumstances)',
      parentDimension: 'stressors',
      result: 6.0,
    },
    {
      id: 'stressors_fam_interpersonal',
      name: 'Family and interpersonal stresses',
      parentDimension: 'stressors',
      result: 5.0,
    },
    {
      id: 'stressors_personal_burdens',
      name: 'Personal burdens',
      parentDimension: 'stressors',
      result: 5.0,
    },
    {
      classification: 'vulnerable',
      id: 'stressors',
      name: 'Stressors',
      parentDimension: null,
      result: 28.57,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier_perfectionism',
      name: 'Perfectionism',
      parentDimension: 'stress_amplifier',
      result: 9.0,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier_missing_dissociation',
      name: 'Missing dissociation',
      parentDimension: 'stress_amplifier',
      result: 10.0,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier_self_expectation',
      name: 'High self-expectation',
      parentDimension: 'stress_amplifier',
      result: 7.0,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier_fears_concerns',
      name: 'Fears and concerns',
      parentDimension: 'stress_amplifier',
      result: 9.0,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier_overload',
      name: 'Overload',
      parentDimension: 'stress_amplifier',
      result: 6.0,
    },
    {
      classification: 'vulnerable',
      id: 'stress_amplifier',
      name: 'Stress Amplifier',
      parentDimension: null,
      result: 18.0,
    },
    {
      id: 'phq_depressive_syndrome',
      name: 'Depressive syndrome',
      parentDimension: 'phq',
      result: 81.48,
    },
    {
      id: 'phq_panic_anxiety',
      name: 'Panic attacks and anxiety symptoms',
      parentDimension: 'phq',
      result: 86.21,
    },
    {
      id: 'phq_ptsd_cat',
      name: 'Post-traumatic stress disorder',
      parentDimension: 'phq',
      result: 66.67,
    },
    {
      classification: 'very_high_hazard',
      id: 'phq_self_harm',
      name: 'Self-harm risk',
      parentDimension: null,
      result: 3.0,
    },
    {
      classification: 'normal',
      id: 'cbi_personal_burnout',
      name: 'Personal burnout',
      parentDimension: 'cbi',
      result: 15.0,
    },
    {
      classification: 'vulnerable',
      id: 'cbi_occupational_burnout',
      name: 'Occupational burnout',
      parentDimension: 'cbi',
      result: 20.0,
    },
    {
      classification: 'vulnerable',
      id: 'cbi',
      name: 'Copenhagen Burnout Inventory (CBI)',
      parentDimension: null,
      result: 32.69,
    },
    {
      classification: 'vulnerable',
      id: 'eri_effort',
      name: 'Effort',
      parentDimension: 'eri',
      result: 3.67,
    },
    {
      classification: 'normal',
      id: 'eri_reward',
      name: 'Reward',
      parentDimension: 'eri',
      result: 2.86,
    },
    {
      classification: 'vulnerable',
      id: 'eri_overcommitment',
      name: 'Overcommitment',
      parentDimension: 'eri',
      result: 3.5,
    },
    {
      id: 'eri_esteem',
      name: 'Esteem',
      parentDimension: 'eri',
      result: 4.0,
    },
    {
      id: 'eri_promotion',
      name: 'Promotion',
      parentDimension: 'eri',
      result: 3.33,
    },
    {
      id: 'eri_security',
      name: 'Security',
      parentDimension: 'eri',
      result: 1.0,
    },
    {
      classification: 'vulnerable',
      id: 'eri',
      name: 'Effort Reward Ratio',
      parentDimension: null,
      result: 21.96,
    },
    {
      id: 'stressors_low',
      name: 'The following everyday issues are rarely or lightly stressful for you',
      parentDimension: null,
      result: [
        '{{ist_ass_q_stressors_2_2_label}}',
      ],
    },
    {
      id: 'stressors_high',
      name: 'The following everyday issues are frequently or heavily stressful for you',
      parentDimension: null,
      result: [
        '{{ist_ass_q_stressors_1_3_label}}',
        '{{ist_ass_q_stressors_4_2_label}}',
        '{{ist_ass_q_stressors_3_3_label}}',
        '{{ist_ass_q_stressors_3_1_label}}',
        '{{ist_ass_q_stressors_1_2_label}}',
        '{{ist_ass_q_stressors_2_1_label}}',
        '{{ist_ass_q_stressors_4_4_label}}',
        '{{ist_ass_q_stressors_3_2_label}}',
        '{{ist_ass_q_stressors_1_1_label}}',
        '{{ist_ass_q_stressors_4_3_label}}',
        '{{ist_ass_q_stressors_4_1_label}}',
        '{{ist_ass_q_stressors_2_3_label}}',
        '{{ist_ass_q_stressors_2_4_label}}',
      ],
    },
    {
      id: 'phq_depressive_syndrome_major',
      name: 'Major depressive syndrome',
      parentDimension: 'phq',
      result: 1.0,
    },
    {
      id: 'phq_depressive_syndrome_other',
      name: 'Other depressive syndromes',
      parentDimension: 'phq',
      result: 0.0,
    },
    {
      id: 'phq_panic',
      name: 'Panic syndrome',
      parentDimension: 'phq',
      result: 0.0,
    },
    {
      id: 'phq_anxiety',
      name: 'Other anxiety syndromes',
      parentDimension: 'phq',
      result: 1.0,
    },
    {
      id: 'phq_ptsd',
      name: 'Post-traumatic stress disorder',
      parentDimension: 'phq',
      result: 1.0,
    },
    {
      classification: 'normal',
      id: 'phq',
      name: 'Patient Health Questionnaire (PHQ)',
      parentDimension: null,
      result: 78.12,
    },
    {
      classification: 'vulnerable',
      id: 'eri_er-ratio',
      name: 'Effort Reward Ratio',
      parentDimension: null,
      result: 2.98,
    },
    {
      classification: 'vulnerable',
      id: 'ist_total',
      name: 'IST Total',
      parentDimension: null,
      result: 32.45,
    },
    {
      id: 'bio_med_hist_illnesses_list',
      name: 'Have you gone through any serious illnesses?',
      parentDimension: null,
      result: [
        '{{ist_ass_qc_bio_med_illness_op_chronic_infections}}',
        '{{ist_ass_qc_bio_med_illness_op_auto-immune_disease}}',
        '{{ist_ass_qc_bio_med_illness_op_diabetes}}',
        '{{ist_ass_qc_bio_med_illness_op_burnout}}',
        '{{ist_ass_qc_bio_med_illness_op_mental_illnes}}',
      ],
    },
  ],
};
