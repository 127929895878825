// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// eslint-disable-next-line import/no-cycle
import { InputNext } from 'ui/basic';

// OTHER COMPONENTS

// UTILS
import REGEXES from 'utils/configuration/const/regexes';
import { useTranslate } from 'utils/translator';

// CONFIG & DATA
// const Config = {};

// COMPONENT: InputNumber
const InputNumber = (props = {}) => {
  const {
    value = '',
    floatingNumbers = false,
    errorMessage = '',
    validate = {},
    onChange = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ errorMessageInternal, setErrorMessageInternal ] = useState('');

  const handleChange = (valueInternal, error) => {
    let errorMessageTemp = '';
    if (error) {
      const patternErrorMessage = floatingNumbers
        ? translate('admin_new_instance_subdomain_invalid_format_msg')
        : translate('numbers_only_validation_msg');

      errorMessageTemp = errorMessage || (error?.pattern
        ? patternErrorMessage
        : '');
    }
    setErrorMessageInternal(errorMessageTemp);

    onChange(
      floatingNumbers
        ? valueInternal?.replace(',', '.')
        : valueInternal,
      !error,
    );
  };

  // RENDER: InputNumber
  return (
    <InputNext
      {...props}
      value={value?.toString() || ''}
      inputMode='numeric'
      errorMessage={errorMessageInternal}
      validate={{
        pattern: floatingNumbers ? REGEXES.FLOATING_NUMBERS : REGEXES.DIGITS,
        onChange: true,
        ...validate,
      }}
      onChange={handleChange}
    />
  );
};

export default InputNumber;
