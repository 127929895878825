// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StripeForm.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import StripeWidget from 'features/+adminUG/components/StripeWidget';
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};


// COMPONENT: StripeForm
const StripeForm = (props) => {
  // PROPS
  const {
    goBack = () => {},
    confirm = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // CONTROLS
  const Controls = ({ submitButtonDisabled }) => (
    <div className={styles.controls}>
      <Button
        size='S'
        looks='secondary'
        onClick={goBack}
      >
        { translate('back_lbl') }

      </Button>
      <Button
        size='S'
        type='submit'
        disabled={submitButtonDisabled}
      >
        { translate('continue_lbl') }

      </Button>
    </div>
  );

  // RENDER: StripeForm
  return (
    <div className={classNames(styles.stripeForm)}>
      <div className='bluContent466'>
        <div className='bluTypeS'>
          { translate('upgrade_to_pp_stripe_title') }
        </div>

        <StripeWidget
          controls={Controls}
          onSubmit={() => {
            confirm({
              isValid: true,
            });
          }}
        />
      </div>
    </div>
  );
};

export default StripeForm;
