import * as fromActionTypes from 'store/actionTypes/teamOverview';

// GET TEAMS
export const getTeams = (requestDto) => ({
  type: fromActionTypes.GET_TEAMS,
  payload: { requestDto },
});

export const getTeamsFulfilled = ({ teams, totalCount }) => ({
  type: fromActionTypes.GET_TEAMS_FULFILLED,
  payload: { teams, totalCount },
});

export const getTeamsRejected = ({ error }) => ({
  type: fromActionTypes.GET_TEAMS_REJECTED,
  payload: { error },
});

// GET TEAM BY ID
export const getTeam = (teamId, fetchUsers) => ({
  type: fromActionTypes.GET_TEAM,
  payload: { teamId, fetchUsers },
});

export const getTeamFulfilled = ({ team }) => ({
  type: fromActionTypes.GET_TEAM_FULFILLED,
  payload: { team },
});

export const getTeamRejected = ({ error }) => ({
  type: fromActionTypes.GET_TEAM_REJECTED,
  payload: { error },
});

export const initTeam = () => ({
  type: fromActionTypes.INIT_TEAM,
  payload: {},
});

// GET TEAM USERS
export const getTeamUsers = ({ teamId, offset }) => ({
  type: fromActionTypes.GET_TEAM_USERS,
  payload: { teamId, offset },
});

export const getTeamUsersFulfilled = ({ teamUsers }) => ({
  type: fromActionTypes.GET_TEAM_USERS_FULFILLED,
  payload: { teamUsers },
});

export const getTeamUsersRejected = ({ error }) => ({
  type: fromActionTypes.GET_TEAM_USERS_REJECTED,
  payload: { error },
});

// GET TEAM TYPES
export const getTeamTypes = () => ({
  type: fromActionTypes.GET_TEAM_TYPES,
  payload: {},
});

export const getTeamTypesFulfilled = ({ teamTypes }) => ({
  type: fromActionTypes.GET_TEAM_TYPES_FULFILLED,
  payload: { teamTypes },
});

export const getTeamTypesRejected = ({ error }) => ({
  type: fromActionTypes.GET_TEAM_TYPES_REJECTED,
  payload: { error },
});

// ADD USERS TO TEAM
export const initAddUsers = () => ({
  type: fromActionTypes.INIT_ADD_USERS,
  payload: {},
});

export const addUsers = (addUsersDto) => ({
  type: fromActionTypes.ADD_USERS,
  payload: { addUsersDto },
});

export const addUsersFulfilled = () => ({
  type: fromActionTypes.ADD_USERS_FULFILLED,
  payload: {},
});

export const addUsersRejected = ({ error }) => ({
  type: fromActionTypes.ADD_USERS_REJECTED,
  payload: { error },
});

// DELETE USER FROM TEAM
export const initDeleteUser = () => ({
  type: fromActionTypes.INIT_DELETE_USER,
  payload: {},
});

export const deleteUser = ({ teamId, userId }) => ({
  type: fromActionTypes.DELETE_USER,
  payload: { teamId, userId },
});

export const deleteUserFulfilled = () => ({
  type: fromActionTypes.DELETE_USER_FULFILLED,
  payload: {},
});

export const deleteUserRejected = ({ error }) => ({
  type: fromActionTypes.DELETE_USER_REJECTED,
  payload: { error },
});

// ADD ROLES TO TEAM
export const initAddPositions = () => ({
  type: fromActionTypes.INIT_ADD_POSITIONS,
  payload: {},
});

export const addPositions = ({ teamId, roles }) => ({
  type: fromActionTypes.ADD_POSITIONS,
  payload: { teamId, roles },
});

export const addPositionsFulfilled = () => ({
  type: fromActionTypes.ADD_POSITIONS_FULFILLED,
  payload: {},
});

export const addPositionsRejected = ({ error }) => ({
  type: fromActionTypes.ADD_POSITIONS_REJECTED,
  payload: { error },
});

// GET POSITIONS
export const getPositions = (teamId) => ({
  type: fromActionTypes.GET_POSITIONS,
  payload: { teamId },
});

export const getPositionsFulfilled = ({ positions }) => ({
  type: fromActionTypes.GET_POSITIONS_FULFILLED,
  payload: { positions },
});

export const getPositionsRejected = ({ error }) => ({
  type: fromActionTypes.GET_POSITIONS_REJECTED,
  payload: { error },
});

// DELETE POSITION
export const initDeletePosition = () => ({
  type: fromActionTypes.INIT_DELETE_POSITION,
  payload: {},
});

export const deletePosition = ({ deletePositionDto }) => ({
  type: fromActionTypes.DELETE_POSITION,
  payload: { deletePositionDto },
});

export const deletePositionFulfilled = () => ({
  type: fromActionTypes.DELETE_POSITION_FULFILLED,
  payload: {},
});

export const deletePositionRejected = ({ error }) => ({
  type: fromActionTypes.DELETE_POSITION_REJECTED,
  payload: { error },
});

// FILL POSITION
export const initFillPosition = () => ({
  type: fromActionTypes.INIT_FILL_POSITION,
  payload: {},
});

export const fillPosition = ({ fillPositionDto }) => ({
  type: fromActionTypes.FILL_POSITION,
  payload: { fillPositionDto },
});

export const fillPositionFulfilled = () => ({
  type: fromActionTypes.FILL_POSITION_FULFILLED,
  payload: {},
});

export const fillPositionRejected = ({ error }) => ({
  type: fromActionTypes.FILL_POSITION_REJECTED,
  payload: { error },
});

// GET ASSESSMENT RESULTS FOR TEAM MEMBERS
export const getAssessmentResults = () => ({
  type: fromActionTypes.GET_ASSESSMENT_RESULTS,
  payload: {},
});

export const getAssessmentResultsFulfilled = ({ assessmentResults }) => ({
  type: fromActionTypes.GET_ASSESSMENT_RESULTS_FULFILLED,
  payload: { assessmentResults },
});

export const getAssessmentResultsRejected = ({ error }) => ({
  type: fromActionTypes.GET_ASSESSMENT_RESULTS_REJECTED,
  payload: { error },
});

// GET TEAM PROFILES
export const initTeamProfiles = () => ({
  type: fromActionTypes.INIT_TEAM_PROFILES,
  payload: {},
});

export const getTeamProfiles = (teamId) => ({
  type: fromActionTypes.GET_TEAM_PROFILES,
  payload: { teamId },
});

export const getTeamProfilesFulfilled = ({ teamProfiles }) => ({
  type: fromActionTypes.GET_TEAM_PROFILES_FULFILLED,
  payload: { teamProfiles },
});

export const getTeamProfilesRejected = ({ error }) => ({
  type: fromActionTypes.GET_TEAM_PROFILES_REJECTED,
  payload: { error },
});

// UPDATE TEAM
export const updateTeam = (updateTeamDto) => ({
  type: fromActionTypes.UPDATE_TEAM,
  payload: { updateTeamDto },
});

export const updateTeamFulfilled = () => ({
  type: fromActionTypes.UPDATE_TEAM_FULFILLED,
  payload: {},
});

export const updateTeamRejected = ({ error }) => ({
  type: fromActionTypes.UPDATE_TEAM_REJECTED,
  payload: { error },
});

// UPDATE USER ROLE
export const initUpdateUserRole = () => ({
  type: fromActionTypes.INIT_UPDATE_USER_ROLE,
  payload: {},
});

export const updateUserRole = (updateUserRoleDto) => ({
  type: fromActionTypes.UPDATE_USER_ROLE,
  payload: { updateUserRoleDto },
});

export const updateUserRoleFulfilled = ({ updatedUserRole }) => ({
  type: fromActionTypes.UPDATE_USER_ROLE_FULFILLED,
  payload: { updatedUserRole },
});

export const updateUserRoleRejected = ({ error }) => ({
  type: fromActionTypes.UPDATE_USER_ROLE_REJECTED,
  payload: { error },
});
