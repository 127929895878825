// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';

// ASSETS

// 3RD PARTY

// OTHER COMPONENTS

// UTILS
import * as api from 'api';

// STORE
import { eventBus } from 'architecture/eventBus';

// CONFIG & DATA
// eslint-disable-next-line import/no-cycle
import { nineLevelsConfig } from '../../config';

const CONFIG = {
  intermissionOffset: 3,
  dragDropHurryDelay: 60 * 1000,
  questionsRequestMaxNumber: 3,
};

// COMPONENT: NineLevelsStage
const NineLevelsStage = (props) => {
  // PROPS
  const {
    state,
    extras,
    nextStageNumber,
    onNext,
  } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  let requestCount = 0;
  const fetchQuestions = useCallback(async () => {
    if (requestCount === CONFIG.questionsRequestMaxNumber) {
      eventBus.dispatch('assessmentNext.error', {
        message: 'An error occurred while fetching questions. Please try again later.',
      });

      return undefined;
    }

    requestCount += 1;
    const { ok, status, data = {} } = await api.get(`core/assessments/9levels/${nextStageNumber}/questions`);

    if (ok && status === 200) {
      if (data.questions.length) {
        return data.questions;
      }
      return await fetchQuestions();
    }
    throw new Error('An Error occurred while fetching the assessment questions');
  }, [ nextStageNumber, requestCount ]);

  // EFFECT HOOKS
  const [ error, setError ] = useState();
  const [ request, setRequest ] = useState();
  useEffect(() => {
    if (!state || !extras || !nextStageNumber || !onNext || request) return;

    // Note: disabled sending answers here since we send them on every answer now (as of v21.7)
    // const prevStageNumber = nextStageNumber - 1;
    // const pages = state.pages;
    // const answers = state.answers;

    // console.log(`send answers ${prevStageNumber}`);
    // console.log(`get questions ${nextStageNumber}`);

    const { stages } = state.assessmentDetails;
    const nextQuestionIndex = state.questionIndex + 1;

    const { setLoadingDuring } = extras;
    const { addPages } = extras;

    setLoadingDuring(true);

    // const prevStageAnswers = pages
    // .filter(page => page.stageNumber === prevStageNumber)
    // .map((page) => page.id)
    // .map(questionId => ({
    //   question: questionId,
    //   content: answers[questionId]
    // }));

    // const requestData = {
    //   answers: prevStageAnswers
    // };

    // // answers available => send and get next stage questions
    // if (prevStageAnswers.length) {
    //   stageRequest = api.post(`/core/assessments/9levels/${prevStageNumber}/answers`, requestData)
    //   .then(() => {
    //     return api.get(`assessments/9levels/${nextStageNumber}/questions`)
    //   });
    // }
    // // no answers => just get next stage questions
    // else {
    //   // get next stage questions
    //   stageRequest = api.get(`assessments/9levels/${nextStageNumber}/questions`);
    // }

    // wait for 3 seconds for any slow answer requests to finish
    const stageRequest = new Promise((resolve) => setTimeout(resolve, 3000))
    .then(() => fetchQuestions())
    .then((questions) => {
      const nextStageQuestions = questions
      .map((question) => {
        const questionType = `${question.type}-${question.representation}`;
        const modalHurryDelay = questionType === 'sort-sort'
          ? CONFIG.dragDropHurryDelay
          : undefined;
        return {
          stageNumber: nextStageNumber,
          ...question,
          modalHurryDelay,
        };
      });

      // if questions array is empty that means stage has already been completed
      if (nextStageQuestions && nextStageQuestions.length === 0) {
        onNext();
        setLoadingDuring(false);
        return;
      }

      let questionsWithPlaceholders = [
        ...nextStageQuestions,
      ];

      // next stage available? => add nextStageIntermission and placeholderQuestions
      if (nextStageNumber < stages.length) {
        // placeholder questions for stages after nextStage
        const nextPlaceholderCount = stages.slice(nextStageNumber)
        .reduce((acc, current) => acc + current.length, 0);

        const nextPlaceholderQuestions = new Array(nextPlaceholderCount)
        .fill()
        .map(() => ({
          isIntermission: true,
          countAsProgress: true,
          isPlaceholder: true,
          render: () => {},
        }));

        // nextStage intermission
        const { intermissions } = nineLevelsConfig;
        const nextStageIntermission = intermissions[CONFIG.intermissionOffset + nextStageNumber];
        nextStageIntermission.isIntermission = true;

        questionsWithPlaceholders = [
          ...questionsWithPlaceholders,
          nextStageIntermission,
          ...nextPlaceholderQuestions,
        ];
      }

      addPages({
        pages: questionsWithPlaceholders,
        insertAtIndex: nextQuestionIndex,
        replace: true,
      });

      setTimeout(() => {
        onNext();
        setLoadingDuring(false);
      }, 500);
    })
    .catch((errorParam) => {
      setLoadingDuring(false);
      console.error(errorParam.message);
      setError(JSON.stringify(errorParam, null, 2));
    });

    setRequest(stageRequest);
  }, [
    state,
    extras,
    nextStageNumber,
    request,
    fetchQuestions,
    onNext,
  ]);


  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: NineLevelsStage
  return (
    <div>{ error }</div>
  );
};

export default NineLevelsStage;
