import React, { useRef, useState, useEffect } from 'react';
import styles from './RmpImport.module.scss';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getAssessmentResult, listAssessments } from 'store/actions';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

import {
  InputNext, InputPassword, Link, Modal,
} from 'ui/basic';
import { SUPPORT_LINK } from 'utils/configuration';


const RmpImport = (props) => {
  const { onClose } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const [ rmpEmail, setRmpEmail ] = useState();
  const [ rmpPassword, setRmpPassword ] = useState();
  const [ rmpImportErrorMessage, setRmpImportErrorMessage ] = useState();
  const [ rmpImportProcessing, setRmpImportProcessing ] = useState(false);
  const [ rmpImportModalVisible, setRmpImportModalVisible ] = useState(true);
  const [ rmpImportSuccessModalVisible, setRmpImportSuccessModalVisible ] = useState(false);
  const copyRef = useRef();

  useEffect(() => {
    if (!copyRef.current) {
      return;
    }
    copyRef.current.querySelector('a')?.setAttribute('target', '_blank');
  }, [ copyRef ]);

  const handleRmpReport = () => {
    setRmpImportProcessing(true);

    api.post(`/core/assessments/${ASSESSMENT_TYPES.RMP}/import`, {
      mail: rmpEmail,
      password: rmpPassword,
    }).then(({ ok, status, data }) => {
      setRmpImportProcessing(false);

      if (ok && status === 200) {
        setRmpImportModalVisible(false);
        setRmpImportSuccessModalVisible(true);

        dispatch(listAssessments());
        dispatch(getAssessmentResult(ASSESSMENT_TYPES.RMP));
      } else {
        let errorCode = 0;
        if (data.error && data.error.errorCode) {
          errorCode = data.error.errorCode;
        }

        switch (errorCode) {
          case 1407:
            setRmpImportErrorMessage(translate('rmp_import_invalid_credentials_error_msg'));
            break;
          case 1408:
            setRmpImportErrorMessage(translate('rmp_import_no_profile_error_msg'));
            break;
          default:
            setRmpImportErrorMessage(data.error ? data.error.errorMessage : '');
        }
      }
    }).catch((error) => {
      setRmpImportProcessing(false);
      console.error(error.message);
    });
  };


  return (
    <div>
      { rmpImportSuccessModalVisible && (
        <Modal
          header={translate('rmp_import_success_header')}
          primaryButtonTitle={translate('assessment_view_results')}
          onClose={onClose}
          onConfirm={() => {
            history.push('/my-profile/strength-profile');
            onClose();
          }}
        >
          { translate('rmp_import_success_copy') }
        </Modal>
      ) }

      { rmpImportModalVisible && (
        <Modal
          header={translate('rmp_import_header')}
          primaryButtonTitle={translate('profile_import_lbl')}
          primaryButtonDisabled={!rmpEmail || !rmpPassword || rmpImportProcessing}
          secondaryButtonTitle={translate('cancel_lbl')}
          buttonsPosition='column'
          closeOnConfirm={false}
          onConfirm={handleRmpReport}
          onClose={onClose}
        >
          <div className={styles.content}>
            { translate('rmp_import_copy1') }

            <div className={styles.loginData}>
              <div className={styles.label}>
                { translate('rmp_import_label') }
              </div>
              <div ref={copyRef} className={styles.copy}>
                { translate('rmp_import_copy2', [ '{{link}}', SUPPORT_LINK ]) }
              </div>
              <div className={styles.inputs}>
                <InputNext
                  label={translate('login_form_email_label')}
                  errorMessage={rmpImportErrorMessage}
                  onChange={(value) => setRmpEmail(value)}
                />
                <InputPassword
                  placeHolder={translate('login_form_password_label')}
                  onInputChanged={(value) => setRmpPassword(value)}
                />
              </div>
            </div>

            <div className={styles.forgotPassword}>
              <Link to='https://platform-auth.rmp-online.com/recovery' openInNewTab>
                { translate('rmp_import_forgot_password_label') }
              </Link>
            </div>

          </div>
        </Modal>
      ) }
    </div>
  );
};

export default RmpImport;
