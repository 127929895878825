// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import { InputNext } from 'ui/basic';
import REGEXES from 'utils/configuration/const/regexes';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: FormLink
const FormLink = (props) => {
  // PROPS
  const {
    children,
    link = {},
    isLoading,
    onInputFocus,
    onValid = () => {},
    onInvalid = () => {},
    onSubmit,
  } = props;

  const [ linkUrl, setLinkUrl ] = useState((link && link.url) || '');
  const [ linkName, setLinkName ] = useState((link && link.name) || '');
  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ isLinkUrlValid, setIsLinkUrlValid ] = useState(true);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  useEffect(() => {
    let isValid = true;

    if (linkUrl.length === 0) {
      isValid = false;

      setIsLinkUrlValid(true); // no error state if no value
    } else if (!REGEXES.DOMAIN_FULL.test(linkUrl)) {
      isValid = false;

      setIsLinkUrlValid(false);
    } else {
      setIsLinkUrlValid(true);
    }

    setIsLinkValid(isValid);
  }, [ linkUrl, linkName ]);

  useEffect(() => {
    if (isLinkValid) {
      onValid();
    } else {
      onInvalid();
    }
  });

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const onLinkUrlChange = (value) => {
    setLinkUrl(value);
  };

  const onLinkNameChange = (value) => {
    setLinkName(value);
  };

  const onLinkSubmit = (e) => {
    if (isLinkValid) {
      if (link) {
        onSubmit({ ...link, url: linkUrl, name: linkName }); // edit existing link
      } else {
        onSubmit({ url: linkUrl, name: linkName }); // create new link
      }
    }

    e.preventDefault();
  };

  // RENDER: FormLink
  return (
    <form onSubmit={onLinkSubmit}>
      <div>
        <InputNext
          label={translate('candidate_attachments_url')} // Link-Ziel (URL)
          value={link.url || ''}
          disabled={isLoading}
          className={isLinkUrlValid === false ? 'error' : undefined}
          onChange={onLinkUrlChange}
          onFocus={onInputFocus}
        />

        { isLinkUrlValid === false && (
          <small>
            { /* Die eingegebene URL ist ungültig. Bitte überprüffe deine Eingabe. */ }
            { translate('candidate_attachments_invalid_url') }
          </small>
        ) }
      </div>

      <div>
        <InputNext
          label={translate('candidate_attachments_title')} // Anzeigetext
          value={link.name || ''}
          disabled={isLoading}
          onChange={onLinkNameChange}
          onFocus={onInputFocus}
        />
      </div>

      <div>{ children }</div>
    </form>
  );
};

export default FormLink;
