// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';
import styles from './TeamDiagramBarsNext.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { ImgPin, PillGroup } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { numberWithForcedDecimals } from 'utils/numberFormatting';

// STORE

// CONFIG & DATA
// const CONFIG = {
//   scaleMin: styles.scaleMin,
//   scaleMax: styles.scaleMax,
//   rowHeight: pxToNumber(styles.rowHeight),
//   barHeight: pxToNumber(styles.barHeight),
//   paddingBottom: pxToNumber(styles.paddingBottom),
// };

const CONFIG = {
  // diagram
  scaleMin: 1,
  scaleMax: 10,
  // dimensions
  rowHeight: 45,
  barHeight: 37,
  markerWidth: 30,
  labelMaxWidth: 165,
  paddingBottom: 20,
};

// COMPONENT: TeamDiagramBarsNext
const TeamDiagramBarsNext = (props) => {
  // PROPS
  const {
    // average
    labelAverage = '',
    showAverage = true,
    // range
    showRanges = false,
    labelRange = '',
    // all
    dimensions = [],
    onItemClick = () => {},
  } = props;

  // METHODS
  const calcAverage = (data) => data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / data.length;

  // COMPONENT/UI STATE and REFS

  // buildup
  const [ isBuildUp, setIsBuildUp ] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBuildUp(false);
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // activeDimension & activeDimensionIndex
  const [ activeDimension, setActiveDimension ] = useState({ data: [] });
  const [ activeDimensionIndex, setActiveDimensionIndex ] = useState(0);
  useEffect(() => {
    const index = activeDimensionIndex >= dimensions.length - 1
      ? dimensions.length - 1
      : activeDimensionIndex;

    setActiveDimensionIndex(index);
    setActiveDimension(dimensions[index]);
  }, [ dimensions, activeDimensionIndex ]);

  // average
  const [ average, setAverage ] = useState();
  useEffect(() => {
    const newAverage = calcAverage(activeDimension.data.map((item) => item.value));
    setAverage(newAverage);
  }, [ activeDimension ]);

  // using htmlLayer as diagramEl because of SVG resizing issues
  const [ diagramNode, setDiagramNode ] = useState(null);
  const diagramNodeCb = useCallback((node) => {
    setDiagramNode(node);
  }, []);

  // gridLinesCount
  const gridLinesCount = CONFIG.scaleMax - CONFIG.scaleMin + 1;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  // STORE HOOKS

  // EVENT HANDLES
  const handlePillClick = (item, index) => {
    setActiveDimensionIndex(index);
  };
  const handleItemClick = (item, index) => {
    onItemClick(item, index);
  };

  // HELPERS
  const calcLinePos = (pos) => {
    const value = isBuildUp ? 0 : pos * (100 / (gridLinesCount - 1));
    return `${value}%`;
  };

  // RENDER: TeamDiagramBarsNext
  return (
    <div className={classNames(styles.teamDiagramBarsNext)}>
      { /* PILL GROUP WITH DIMENSIONS */ }
      <PillGroup
        pillItems={dimensions.map(({ label }) => ({ label }))}
        onPillClick={handlePillClick}
      />

      { /* LEGEND */ }
      <div className={styles.legend}>
        { new Array(gridLinesCount).fill().map((_, index) => (
          <div key={index} className={styles.legendItem}>
            { index + CONFIG.scaleMin }
          </div>
        )) }
      </div>

      { /* DIAGRAM CONTAINER */ }
      <div className={styles.diagramContainer}>

        { /* SVG */ }
        <svg className={styles.svgContainer}>
          <svg className={styles.svg}>

            { /* GRID & LINES */ }
            <g className={styles.grid}>
              { new Array(gridLinesCount).fill().map((_, index) => (
                <line
                  key={index}
                  x1={calcLinePos(index)}
                  x2={calcLinePos(index)}
                  y1={0}
                  // the lines stop at the bottom of the last bar
                  y2={CONFIG.rowHeight * (activeDimension.data.length - 1) + CONFIG.barHeight}
                />
              )) }
            </g>

          </svg>
        </svg>

        { /* HTML LAYER */ }
        <div
          className={styles.htmlLayer}
          ref={diagramNodeCb}
        >

          { /* BARS */ }
          { diagramNode !== null && (
            <div className={styles.bars}>
              { activeDimension.data.map((item, index) => {
                const { value } = item;
                const { label } = item;

                const barWidth = calcLinePos(value - CONFIG.scaleMin);
                const availableLabelSpace = (diagramNode.clientWidth * barWidth.slice(0, -1)) / 100;
                const moveLabelToRight = availableLabelSpace < CONFIG.labelMaxWidth;

                // BAR CONTAINER
                return (
                  <div
                    key={index}
                    role='presentation'
                    className={styles.barContainer}
                    onClick={() => {
                      handleItemClick({
                        ...item,
                        dimensionId: activeDimension.id,
                        dimensionName: activeDimension.label,
                      }, index);
                    }}
                  >

                    { /* BAR */ }
                    <div
                      className={styles.bar}
                      style={{ width: barWidth }}
                    >
                      <div className={styles.barFill} />
                      <div className={styles.barHover} />
                      <div className={classNames(styles.barLabel, {
                        [styles.moveToRight]: moveLabelToRight,
                      })}
                      >
                        { label }
                      </div>

                      { /* MARKER */ }
                      <div className={styles.marker}>
                        <ImgPin src={item.img} fullLabel={item.label} />
                      </div>
                    </div>

                  </div>
                );
              }) }
            </div>
          ) }

          { /* AVERAGE BAR */ }
          { (!showRanges && showAverage) && (
            <div className={styles.averageBarContainer}>
              <div
                className={styles.averageBar}
                style={{ marginLeft: calcLinePos(average - CONFIG.scaleMin) }}
              />
            </div>
          ) }

          { /* RANGE AREA */ }
          { showRanges && activeDimension.range && (
            <div className={styles.rangeContainer}>
              <div
                className={styles.range}
                style={{
                  width: calcLinePos(activeDimension.range[1] - activeDimension.range[0]),
                  marginLeft: calcLinePos(activeDimension.range[0] - CONFIG.scaleMin),
                }}
              />
            </div>
          ) }

        </div>

      </div>

      { /* AVERAGE LABEL */ }
      { (!showRanges && labelAverage && showAverage) && (
        <div className={styles.averageLabel}>
          { labelAverage }
          :
          <span className={styles.highlight}>
            { numberWithForcedDecimals(average) }
          </span>
        </div>
      ) }

      { /* RANGE LABEL */ }
      { showRanges && activeDimension.range && (
        <div className={styles.rangeLabel}>
          <span className={styles.rangeLegend} />
          { labelRange }
          <span className={styles.highlight}>
            { numberWithForcedDecimals(activeDimension.range[0]) }
            { ` ${translate('component_team_diagrams_next_range_to')} ` }
            { numberWithForcedDecimals(activeDimension.range[1]) }
          </span>
        </div>
      ) }
    </div>
  );
};

export default TeamDiagramBarsNext;
