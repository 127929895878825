// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './CodeInput.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: CodeInput
const CodeInput = (props) => {
  // PROPS
  const {
    autofocus,
    error,
    onChange = () => {},
    forceUpperCase = false,
  } = props;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ digit, setDigit ] = useState();

  const handleChange = (value) => {
    let newValue = value.trim();
    if (forceUpperCase) {
      newValue = newValue.toUpperCase();
    }
    setDigit(newValue);
    onChange(newValue);
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: CodeInput
  return (
    <div className={classNames(styles.codeInput)}>
      <InputNext
        value={digit}
        maxLength={1}
        autofocus={autofocus}
        errorMessage={error}
        onChange={handleChange}
      />
    </div>
  );
};

export default CodeInput;
