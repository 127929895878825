// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './AttachmentsModal.module.scss';

import { Modal, PillGroup } from 'ui/basic';
import AttachmentsModalAdd from './AttachmentsModalAdd';
import AttachmentsModalEdit from './AttachmentsModalEdit';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: AttachmentsModal
const AttachmentsModal = (props) => {
  // PROPS
  const {
    attachments,
    onClose = () => {},
    onAttachmentAdd,
    onAttachmentEdit,
    onAttachmentDelete,
  } = props;

  if (!attachments) {
    throw new Error('Missing `attachments` prop');
  }

  if (!onAttachmentAdd) {
    throw new Error('Missing `onAttachmentAdd` prop');
  }

  if (!onAttachmentEdit) {
    throw new Error('Missing `onAttachmentEdit` prop');
  }

  if (!onAttachmentDelete) {
    throw new Error('Missing `onAttachmentDelete` prop');
  }

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ attachmentsArr, setAttachmentsArr ] = useState(attachments);
  const [ currentView, setCurrentView ] = useState('add');
  const subNavigationItems = [
    { id: 'add', label: translate('candidate_attachments_add') },
    { id: 'edit', label: translate('candidate_attachments_edit') },
  ];

  useEffect(() => {
    // only reset if coming from the Abort button click
    if (attachmentsArr.length === 0) {
      // initialize new state from a clone of the attachments prop
      setAttachmentsArr(JSON.parse(JSON.stringify(attachments)));
    }
  }, [ attachmentsArr, attachments ]);

  useEffect(() => {
    setAttachmentsArr(attachments);
  }, [ attachments ]);

  const onSubNavigationClick = (item) => {
    setCurrentView(item.id);
  };

  const onAttachmentAbort = () => {
    // this will reset the complete attachments state, see useEffect above for the re-initialization
    setAttachmentsArr([]);
  };

  // RENDER: AttachmentsModal
  return (
    <div className={styles.attachmentsModal}>
      <Modal
        secondaryButtonTitle={translate('candidate_attachments_close')}
        onClose={onClose}
      >
        <div className={styles.subNavigation}>
          <PillGroup
            pillItems={subNavigationItems}
            onPillClick={onSubNavigationClick}
          />
        </div>

        <div className={styles.viewsWrapper}>
          { currentView === 'add' && (
            <AttachmentsModalAdd onAttachmentAdd={onAttachmentAdd} />
          ) }

          { currentView === 'edit' && (
            <AttachmentsModalEdit
              attachments={attachmentsArr}
              onAttachmentEdit={onAttachmentEdit}
              onAttachmentDelete={onAttachmentDelete}
              onAttachmentAbort={onAttachmentAbort}
            />
          ) }
        </div>
      </Modal>
    </div>
  );
};

export default AttachmentsModal;
