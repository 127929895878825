import React, { useCallback, useEffect, useState } from 'react';
import styles from './PotentialResultSkeleton.module.scss';

import { useBreakpoint } from 'utils/hooks';

const PotentialResultSkeleton = () => {
  const bp = useBreakpoint();

  const [ skeleton, setSkeleton ] = useState();

  const getSubItems = () => {
    const subItems = [];
    for (let i = 0; i < 8; i++) {
      subItems.push(
        <div key={`index-${i}`} className={styles.subItem} />,
      );
    }
    return subItems;
  };

  const getLittleSubItems = () => {
    const subItems = [];
    for (let i = 0; i < 8; i++) {
      subItems.push(
        <div key={`index-${i}`} className={styles.subItemGroup}>
          <div className={styles.subItem1} />
          <div className={styles.subItem2} />
        </div>,
      );
    }
    return subItems;
  };

  const getSkeleton = useCallback(() => {
    if (bp.isS || bp.isXs) {
      return (
        <div id='container-id' className={styles.skeletonContainer}>
          <div className={styles.left}>
            <div className={styles.header} />
            <div className={styles.subItems}>{ getLittleSubItems() }</div>
          </div>
        </div>
      );
    }

    return (
      <div id='container-id' className={styles.skeletonContainer}>
        <div className={styles.left}>
          <div className={styles.header} />
          <div className={styles.subItems}>{ getSubItems() }</div>
        </div>
        <div className={styles.right}>
          <div className={styles.subItems}>{ getSubItems() }</div>
        </div>
      </div>
    );
  }, [ bp ]);

  const handleResize = useCallback(() => {
    setSkeleton(getSkeleton());
  }, [ getSkeleton ]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ handleResize ]);

  return skeleton || getSkeleton();
};

export default PotentialResultSkeleton;
