export const SIGNATURE_VALUES = {
  levels_turquoise: [
    'spiritual_consciousness',
    'collective_intuition',
    'network_intelligence',
    'sustainability',
    'systemic_action',
    'improvement_of_living_conditions_for_all_life_forms',
    'self-organization_of_living_systems',
  ],
  levels_yellow: [
    'individuality',
    'multi-perspectivity',
    'personal_development',
    'self_reflection',
    'systemic_integration',
    'appreciation_of_uniqueness',
    'profound_expertise',
  ],
  levels_green: [
    'tolerance',
    'equality',
    'cooperation',
    'harmony',
    'consensus',
    'dialogue',
    'empathy',
  ],
  levels_orange: [
    'target_orientation',
    'innovation',
    'performance',
    'responsibility',
    'challenge',
    'entrepreneurial_thinking',
    'independence',
  ],
  levels_blue: [
    'obligation',
    'order',
    'safety',
    'quality',
    'law_and_justice',
    'stability',
    'control',
  ],
  levels_red: [
    'glory',
    'valour',
    'strength',
    'assertiveness',
    'impulsiveness',
    'dominance',
    'personal_success',
  ],
  levels_purple: [
    'respecting_taboos',
    'tradition',
    'blood_relationship',
    'rituals',
    'obedience',
    'protection',
    'willingness_to_sacrifice',
  ],
};
