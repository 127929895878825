// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useRef, useState } from 'react';
import styles from './MainHeader.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

// OTHER COMPONENTS
import { ProfileCircle } from './ProfileCircle';
import { QuickAccess } from './QuickAccess';
import { Logo } from '../Logo';
import { BluCSSTransition, Scrollable } from 'ui/basic';
import { HelpMenu } from './HelpMenu';

// STORE OLD
import store from 'store';

// STORE NEXT
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  selectRouteInfo,
  selectBreadcrumb, setBreadcrumb as setBreadcrumbStore,
} from 'features/framework/storeNext/routeSlice';

// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { alsoHighlightFor } from 'features/framework/utils/routeNext';

// CONFIG & DATA
// const Config = {};
const {
  Burger, CloseBig, ArrowRight,
} = IconsSvg;

// COMPONENT: MainHeader
const MainHeader = (props) => {
  // PROPS
  const {
    showBurgerMenu,
    onOpenMainNav = () => {},
    onOpenProfile = () => {},
    onSubNav = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const bp = useBreakpoint();
  const dispatch = useDispatch();
  const location = useLocation();

  // CUSTOM LOGO
  // CUSTOM LOGO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  // const logo = useSelector(selectLogo);
  const transitionRef = useRef();

  // ROUTEINFO: title
  const [ subNavIsOpen, setSubNavIsOpen ] = useState(false);
  const [ hideQuickAccess, setHideQuickAccess ] = useState(false);
  const [ subNavRoutes, setSubNavRoutes ] = useState([]);
  const [ titleRoute, setTitleRoute ] = useState({});

  // ROUTEINFO: breadcrumb
  const [ breadcrumbRoute, setBreadcrumbRoute ] = useState();
  const [ breadcrumb, setBreadcrumb ] = useState({});

  // ROUTEINFO: showsubnav
  const [ activeSubNavIndex, setActiveSubNavIndex ] = useState(-1);

  // ROUTEINFO
  const routeInfo = useSelector(selectRouteInfo);
  useEffect(() => {
    const {
      currentRoute, params, parentRoute, grandParentRoute,
    } = routeInfo;

    setTitleRoute(grandParentRoute || parentRoute || currentRoute || {});

    let subNavRoot = {};
    if (currentRoute.subRoutes) {
      subNavRoot = currentRoute;
    } else if (parentRoute && parentRoute.subRoutes) {
      subNavRoot = parentRoute;
    } else if (grandParentRoute && grandParentRoute.subRoutes) {
      subNavRoot = grandParentRoute;
    }

    // breadcrumb
    if (subNavRoot.asBreadcrumb) {
      setBreadcrumbRoute({
        ...subNavRoot.asBreadcrumb,
        link: subNavRoot.path,
      });
    } else {
      setBreadcrumbRoute();
    }

    let quickAccessHidden;
    if (subNavRoot.subRoutes?.length === 0) {
      quickAccessHidden = true;
    }

    // subNavRoutes
    let isOpen = false;
    let routes = [];
    if (subNavRoot?.subRoutes?.length) {
      const newSubNavRoutes = subNavRoot.subRoutes.filter((subRoute) => subRoute.inSubNav);

      if (newSubNavRoutes.length > 1) {
        isOpen = true;
        quickAccessHidden = true;
        routes = routes.concat(newSubNavRoutes);
      }
    }

    // routes: add pathResolved: replace params
    const paramKeys = Object.keys(params);
    if (paramKeys.length) {
      routes = routes.map((subNavRoute) => {
        const newSubNavRoute = { ...subNavRoute, pathResolved: subNavRoute.path };
        paramKeys.forEach((paramKey) => {
          const paramValue = params[paramKey];
          newSubNavRoute.pathResolved = newSubNavRoute.pathResolved.replace(`:${paramKey}`, paramValue);
        });
        return newSubNavRoute;
      });
    }

    // alsoHighlightFor
    const activeSubNavIndexNew = routes.findIndex((subNavRoute) => alsoHighlightFor(location, subNavRoute));
    setActiveSubNavIndex(activeSubNavIndexNew);

    onSubNav(isOpen);
    setSubNavIsOpen(isOpen);
    setHideQuickAccess(quickAccessHidden);
    setSubNavRoutes(routes);
  }, [ dispatch, location, routeInfo, onSubNav ]);

  const getHeaderTitle = () => {
    // override 'parentLabel' if it differs from 'inMainNav' value
    if (breadcrumb.parentLabel) {
      return (
        <Link to={breadcrumb.parentLink || '/'}>
          { breadcrumb.parentLabel }
        </Link>
      );
    }

    if (titleRoute.inMainNav) {
      const { label, labelKey } = titleRoute.inMainNav;
      return (
        <Link to={titleRoute.path || '/'}>
          { label || translate(labelKey) }
        </Link>
      );
    }

    return undefined;
  };
  const getSubNavTitle = (subNavRoute) => {
    const { label, labelKey } = subNavRoute.inSubNav;
    return label || translate(labelKey);
  };

  // BREADCRUMB
  const breadcrumbStore = useSelector(selectBreadcrumb);

  useEffect(() => {
    if (!location || !dispatch) return;
    if (!breadcrumbStore.label && breadcrumbRoute) {
      setBreadcrumb(breadcrumbRoute);
    } else if (!breadcrumbRoute) {
      setBreadcrumb({});
    }

    if (breadcrumbStore.label) {
      let removeBreadcrumb = true;
      if (location.pathname.toLowerCase().includes(breadcrumbStore.routesMustInclude)) {
        removeBreadcrumb = false;
      }

      if (removeBreadcrumb) {
        dispatch(setBreadcrumbStore({}));
      } else {
        setBreadcrumb(breadcrumbStore);
      }
    }
  }, [ dispatch, location, breadcrumbStore, breadcrumbRoute ]);

  const getBreadcrumb = () => (
    <BluCSSTransition
      nodeRef={transitionRef}
      in={!!(breadcrumb.label || breadcrumb.labelKey)}
    >
      <span className={styles.breadcrumb} ref={transitionRef}>
        <span className={styles.breadcrumbArrow}><ArrowRight /></span>
        { breadcrumb.link && (
          <Link to={breadcrumb.link}>
            { translate(breadcrumb.labelKey) || breadcrumb.label }
          </Link>
        ) }
        { !breadcrumb.link && (translate(breadcrumb.labelKey) || breadcrumb.label) }
      </span>
    </BluCSSTransition>
  );

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: MainHeader
  return (
    <div className={classNames(styles.mainHeader, {
      [styles.subNavIsOpen]: subNavIsOpen,
    })}
    >

      { /* FIRST LEVEL */ }
      <div className={styles.firstLevel}>
        { /* LEFT: LARGE SCREENS */ }
        { !bp.isXs && (
          <div className={styles.left}>

            { /* BURGER */ }
            <div
              role='presentation'
              className={classNames(styles.burger, {
                [styles.hidden]: !showBurgerMenu,
              })}
              onClick={onOpenMainNav}
              data-test='MainNavBurger'
            >
              <Burger />
            </div>

            { /* TITLE & BREADCRUMB */ }
            <div className={styles.title}>
              { getHeaderTitle() }
              { getBreadcrumb() }
            </div>

          </div>
        ) }
        { /* LEFT: XS */ }
        { bp.isXs && (
          <div
            role='presentation'
            className={styles.left}
            onClick={() => onOpenMainNav(false)}
          >
            { /* LOGO */ }
            { /* logo link */ }
            <Logo link='/' />
          </div>
        ) }


        { /* RIGHT */ }
        <div className={styles.right}>

          { /* QUICK ACCESS: LARGE SCREENS */ }
          { !bp.isXs && (
            <div className={classNames(styles.quickAccess)}>
              <QuickAccess />
            </div>
          ) }

          { /* HELP MENU */ }
          <div className={classNames(styles.helpMenu, {
            [styles.hidden]: bp.isXs && !showBurgerMenu,
          })}
          >
            <HelpMenu />
          </div>

          { /* PROFILE */ }
          <div
            role='presentation'
            className={classNames(styles.profile, {
              [styles.hidden]: bp.isXs && !showBurgerMenu,
            })}
            data-test='ProfileCircle'
            onClick={onOpenProfile}
          >
            <Provider store={store}>
              <ProfileCircle />
            </Provider>
          </div>

          { /* BURGER & MENU CLOSE: XS */ }
          { bp.isXs && (
            <>
              <div
                role='presentation'
                className={classNames(styles.burger, {
                  [styles.hidden]: !showBurgerMenu,
                })}
                onClick={onOpenMainNav}
              >
                <Burger />
              </div>
              <div
                role='presentation'
                className={classNames(styles.menuClose, {
                  [styles.hidden]: !bp.isXs || showBurgerMenu,
                })}
                onClick={() => onOpenMainNav(false)}
              >
                <CloseBig />
              </div>
            </>
          ) }
        </div>
      </div>

      { /* SECOND LEVEL */ }
      { (bp.isXs || subNavIsOpen) && (
        <div
          className={classNames(
            styles.secondLevel,
            { [styles.subNavIsOpen]: subNavIsOpen },
          )}
        >

          { /* TITLE & BREADCRUMB: XS  */ }
          { bp.isXs && (
            <div className={styles.title}>
              { getHeaderTitle() }
              { getBreadcrumb() }
            </div>
          ) }

          { /* QUICK ACCESS: XS */ }
          { bp.isXs && !hideQuickAccess && (
            <div className={classNames(styles.quickAccess)}>
              <QuickAccess />
            </div>
          ) }

          { /* SUBNAV */ }
          { subNavIsOpen && (
            <div className={styles.subNav}>
              <Scrollable scroll scrollOnClick noMargins>
                { subNavRoutes.map((subNavRoute, subNavRouteIndex) => (
                  <Link
                    key={subNavRoute.path}
                    to={subNavRoute.pathResolved || subNavRoute.path}
                    className={classNames(styles.subNavItem, {
                      [styles.active]: subNavRoute.path === routeInfo.currentRoute.path
                      || subNavRouteIndex === activeSubNavIndex,
                    })}
                  >
                    { getSubNavTitle(subNavRoute) }
                    <div className={styles.border} />
                  </Link>
                )) }
              </Scrollable>
            </div>
          ) }
        </div>
      ) }
    </div>
  );
};

export default MainHeader;
