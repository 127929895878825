import * as fromActionTypes from 'store/actionTypes/signUp';

export const initSignUp = () => ({
  type: fromActionTypes.INIT_SIGN_UP,
  payload: { },
});

export const signUp = (signUpDto) => ({
  type: fromActionTypes.SIGN_UP,
  payload: { signUpDto },
});

export const signUpFulFilled = (payload) => ({
  type: fromActionTypes.SIGN_UP_FULFILLED,
  payload,
});

export const signUpRejected = ({ error }) => ({
  type: fromActionTypes.SIGN_UP_REJECTED,
  payload: { error },
});

export const sendSignUpEmail = () => ({
  type: fromActionTypes.SEND_SIGN_UP_EMAIL,
  payload: {},
});

export const sendSignUpEmailFulfilled = () => ({
  type: fromActionTypes.SEND_SIGN_UP_EMAIL_FULFILLED,
  payload: {},
});

export const sendSignUpEmailRejected = ({ error }) => ({
  type: fromActionTypes.SEND_SIGN_UP_EMAIL_REJECTED,
  payload: { error },
});
