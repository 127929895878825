import React from 'react';

// INTERMISSIONS
import { QuestionIntro } from '../components/trial-signup/QuestionIntro';
import { QuestionPersonalInfo } from '../components/trial-signup/QuestionPersonalInfo';
import { QuestionCodeConfirmation } from '../components/trial-signup/QuestionCodeConfirmation';
import { QuestionCompanyDetails } from '../components/trial-signup/QuestionCompanyDetails';
import { QuestionCompanyDomain } from '../components/trial-signup/QuestionCompanyDomain';
import { QuestionPassword } from '../components/trial-signup/QuestionPassword';
import { InstanceCreation } from '../components/trial-signup/InstanceCreation';
import { InstanceCreationSuccess } from '../components/trial-signup/InstanceCreationSuccess';

// OTHER COMPONENTS

export const trialSignupConfig = {
  id: 'trialSignup',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    // INTRO
    {
      isIntermission: true,
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer, setModalCancelShow) => (
        <QuestionIntro
          answer={answer}
          setModalCancelShow={setModalCancelShow}
          onNext={next}
        />
      ),
    },
    {
      title: 'trial_signup_company_details_title',
      Component: QuestionCompanyDetails,
    },
    {
      title: 'trial_signup_company_domain_title',
      Component: QuestionCompanyDomain,
    },
    {
      title: 'trial_signup_personal_info_title',
      customControls: true,
      Component: QuestionPersonalInfo,
    },
    {
      title: 'trial_signup_confirmation_code_title',
      customControls: true,
      Component: QuestionCodeConfirmation,
    },
    {
      title: 'trial_signup_password_title',
      noPrev: true,
      Component: QuestionPassword,
    },
    {
      isIntermission: true,
      showProgressBar: false,
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer, setModalCancelShow) => (
        <InstanceCreation
          extras={state}
          onNext={next}
        />
      ),
    },
    {
      isIntermission: true,
      showProgressBar: true,
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer, setModalCancelShow) => (
        <InstanceCreationSuccess
          extras={state}
        />
      ),
    },
  ],
};
