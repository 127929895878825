import React, { useEffect, useState } from 'react';
import styles from './EditProfilePanel.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from 'store/actions';
import { getCompanyEntryDate } from 'store/selectors/currentUser';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromSelectors from 'store/selectors/staticValues';

import { useTranslate } from 'utils/translator';
import { getYears } from 'utils/years';
import { getMonths } from 'utils/months';
import { timestampToDate } from 'utils/dateTools';

import {
  UploadImage, SidePanel, Button, DropDown, InputNext,
} from 'ui/basic';


const EditProfilePanel = (props) => {
  const { onClose } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const entryDate = useSelector((state) => ((me.companyData && me.companyData.companyEntryDate)
    ? timestampToDate(getCompanyEntryDate(state))
    : undefined));

  const [ firstName, setFirstName ] = useState(me.firstName || '');
  const [ lastName, setLastName ] = useState(me.lastName || '');
  const [ profession, setProfession ] = useState(me.companyData.profession || '');
  const [ entryMonth, setEntryMonth ] = useState(entryDate ? { value: entryDate.getMonth(), label: getMonths()[entryDate.getMonth()] } : undefined);
  const [ entryYear, setEntryYear ] = useState(entryDate ? { value: entryDate.getFullYear(), label: entryDate.getFullYear() } : undefined);
  const [ careerLevel, setCareerLevel ] = useState();

  const careerLevelOptions = useSelector(fromSelectors.selectCareerLevelOptions);
  useEffect(() => {
    if (careerLevelOptions.length === 0) {
      return;
    }

    if (me.companyData && me.companyData.careerLevel) {
      setCareerLevel({
        value: me.companyData.careerLevel,
        label: careerLevelOptions.find((cl) => cl.value === me.companyData.careerLevel).label,
      });
    }
  }, [ careerLevelOptions, me ]);

  return (
    <SidePanel
      size='M'
      onClose={onClose}
    >
      <div className={styles.editProfilePanel}>
        <div className={styles.header}>
          { translate('edp_edit_profile_data') || 'Edit Profile Data' }
        </div>
        <UploadImage
          image={me.picture}
          altImageText={firstName.substring(0, 1) + lastName.substring(0, 1)}
          text={translate('edp_link_edit_profile_picture') || 'Change Image'}
        />
        <div className={styles.infoBlock1}>
          <InputNext
            label={translate('cp_label_input_name') || 'Name'}
            value={firstName}
            errorMessage={!firstName && (translate('cp_label_input_name_error_nofill') || 'Name is empty')}
            onChange={(value) => setFirstName(value)}
          />

          <InputNext
            label={translate('cp_label_input_surname') || 'Surname'}
            value={lastName}
            errorMessage={!lastName && (translate('cp_label_input_surname_error_nofill') || 'Surname is empty')}
            onChange={(value) => setLastName(value)}
          />

          <InputNext
            label={translate('cp_label_input_position') || 'Actual Position'}
            value={profession}
            onChange={(value) => setProfession(value)}
          />

          <DropDown
            placeholder={translate('cp_label_dropdown_careerlevel') || 'Career Level'}
            options={careerLevelOptions}
            selectedOption={careerLevel}
            onChange={(option) => {
              setCareerLevel(option);
            }}
          />
        </div>
        <div className={styles.infoBlock2}>
          <div className={styles.title}>
            { translate('cp_subheadline_timeincomp') || 'In Company since' }
          </div>
          <div className={styles.selects}>
            <div className={styles.select}>
              <DropDown
                placeholder={translate('cp_label_dropdown_month') || 'Choose month'}
                options={getMonths().map((month, index) => ({ value: index, label: month }))}
                selectedOption={entryMonth}
                onChange={(option) => {
                  setEntryMonth(option);
                }}
              />
            </div>
            <div className={styles.select}>
              <DropDown
                placeholder={translate('cp_label_dropdown_year') || 'Choose year'}
                options={getYears().reverse().map((year) => ({ value: year, label: year }))}
                selectedOption={entryYear}
                onChange={(option) => {
                  setEntryYear(option);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            size='S'
            looks='secondary'
            onClick={onClose}
          >
            { translate('edp_button_cancel') || 'Cancel' }
          </Button>
          <Button
            size='S'
            disabled={!firstName || !lastName}
            onClick={() => {
              dispatch(updateCurrentUser({
                firstName,
                lastName,
                profession: profession || undefined,
                careerLevel: careerLevel ? careerLevel.value : undefined,
                companyEntryDate: (entryMonth && entryYear) ? new Date(entryYear.value, entryMonth.value).getTime() / 1000 : undefined,
              }));
              onClose();
            }}
          >
            { translate('save_lbl') || 'Save' }
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default EditProfilePanel;
