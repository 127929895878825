// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './RmpResultTable.module.scss';

// UTILS
import { useTranslate } from 'utils/translator';
import { resultIsStrong, resultIsAverage } from 'utils/rmp';

// CONFIG & DATA
const Config = {
  rows: [
    { title: 'objective', type: 'goal' },
    { title: 'emotion', type: 'emotion' },
    { title: 'frustration_emotion', type: 'neg_emotion' },
    { title: 'character', type: 'character' },
  ],
};

// COMPONENT: RmpResultTable
const RmpResultTable = ({
  dimensionId = '',
  score = 0,
}) => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const strength = resultIsStrong(score) ? 'strong' : 'weak';

  // Don't show component for average results.
  // https://blueexcellence.atlassian.net/browse/BQDQ-736
  if (resultIsAverage(score)) {
    return null;
  }

  // RENDER: RmpResultTable
  return (
    <div className={styles.rmpResultTable}>
      { Config.rows.map(({ title, type }) => (
        <div className={styles.row} key={title}>
          <div className={styles.label}>
            { translate(`rmp_report_${title}_lbl`) }
          </div>
          <div className={styles.value}>
            { translate(`rmp_report_${dimensionId}_${strength}_${type}`) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default RmpResultTable;
