import * as fromActionTypes from 'store/actionTypes/staffing';

export const initStaffingEmployee = () => ({
  type: fromActionTypes.INIT_STAFFING_EMPLOYEE,
  payload: {},
});

// GET STAFFING EMPLOYEE
export const getStaffingEmployee = (roleId, userId) => ({
  type: fromActionTypes.GET_STAFFING_EMPLOYEE,
  payload: { roleId, userId },
});

export const getStaffingEmployeeFulfilled = ({ employee }) => ({
  type: fromActionTypes.GET_STAFFING_EMPLOYEE_FULFILLED,
  payload: { employee },
});

export const getStaffingEmployeeRejected = ({ error }) => ({
  type: fromActionTypes.GET_STAFFING_EMPLOYEE_REJECTED,
  payload: { error },
});

// SETTING ROLE TO EMPLOYEE
export const setUserRole = (userId, roleId) => ({
  type: fromActionTypes.SET_USER_ROLE,
  payload: { userId, roleId },
});

export const setUserRoleFulfilled = () => ({
  type: fromActionTypes.SET_USER_ROLE_FULFILLED,
  payload: {},
});

export const setUserRoleRejected = ({ error }) => ({
  type: fromActionTypes.SET_USER_ROLE_REJECTED,
  payload: { error },
});

// DELETE USER ROLE
export const initDeleteUserRole = () => ({
  type: fromActionTypes.INIT_DELETE_USER_ROLE,
  payload: {},
});

export const deleteUserRole = (userId, roleId) => ({
  type: fromActionTypes.DELETE_USER_ROLE,
  payload: { userId, roleId },
});

export const deleteUserRoleFulfilled = () => ({
  type: fromActionTypes.DELETE_USER_ROLE_FULFILLED,
  payload: {},
});

export const deleteUserRoleRejected = ({ error }) => ({
  type: fromActionTypes.DELETE_USER_ROLE_REJECTED,
  payload: { error },
});

