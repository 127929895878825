import React from 'react';
import styles from './PotentialResult.module.scss';

import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';
import { InfoTitle, LineDiagram } from 'ui/basic';


const PotentialResultItem = (props) => {
  const {
    id,
    label,
    result,
    range,
    exportView,
    onInfoClick,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ , index ] = id.split('pot_');

  return (
    <div className={styles.row}>
      <div className={styles.diagram}>
        <LineDiagram
          score={result}
          range={range}
        />
      </div>
      <div className={styles.points}>
        { (!range || result)
          ? setDecimalSeparator(result)
          : `${setDecimalSeparator(range[0])}-${setDecimalSeparator(range[1])}` }
      </div>

      { exportView ? (
        <span className='bluTypeCopy'>
          { label || translate(`potential_results_label_dim${index}`) }
        </span>
      ) : (
        <InfoTitle
          title={label || translate(`potential_results_label_dim${index}`)}
          type='regular'
          onClick={onInfoClick}
        />
      ) }
    </div>
  );
};

export default PotentialResultItem;
