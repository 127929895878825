import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './store/reducers';
// eslint-disable-next-line import/no-cycle
import rootSaga from './store/sagas';

// CONFIG
const Config = {
  enableLogger: false,
};

const logger = () => (next) => (action) => {
  if (process.env.NODE_ENV !== 'production' && Config.enableLogger) {
    // eslint-disable-next-line no-console
    console.warn(action);
  }
  return next(action);
};

// STORE SETUP
const sagaMiddleware = createSagaMiddleware();
const middlewares = [ sagaMiddleware, logger ];
let enhancer = applyMiddleware(...middlewares);

if (process.env.NODE_ENV === 'development') {
  enhancer = composeWithDevTools({})(applyMiddleware(...middlewares));
}

const initialState = {
  assessments: {
    table: {},
  },
};

const store = createStore(reducers, initialState, enhancer);
sagaMiddleware.run(rootSaga);

export default store;
