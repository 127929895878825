import { getDynamicEnvVar } from 'utils/configuration';
import { numberWithForcedDecimals } from 'utils/numberFormatting';

export const STRIPE_ELEMENT_PUBLIC_KEY = getDynamicEnvVar('STRIPE_ELEMENT_PUBLIC_KEY', 'pk_test_51HKmyzB2Ilnhw9Uov1F3FBNfNt8AxY5flsOlgrbpRH2AxQAjSeuGq85iT4lkcrv9HMTndSttXdesmYkvtE2eGMPk00YB33qlsx');
export const PAID_PLAN_ID_MONTHLY = getDynamicEnvVar('REACT_APP_PAID_PLAN_ID_MONTHLY', 'example_paid_plan');
export const PAID_PLAN_ID_YEARLY = getDynamicEnvVar('REACT_APP_PAID_PLAN_ID_YEARLY', 'example_paid_plan_yearly');

export const PLANS = {
  ANNUAL: PAID_PLAN_ID_YEARLY,
  MONTHLY: PAID_PLAN_ID_MONTHLY,
};

export const CURRENCY_SYMBOLS = {
  eur: '€',
  usd: '$',
  chf: 'CHF',
};

export const Config = {
  stripePriceDivider: 100,
};

// VatIds
export const stripeVatIds = [
  'ae_trn',
  'au_abn',
  'au_arn',
  'bg_uic',
  'br_cnpj',
  'br_cpf',
  'ca_bn',
  'ca_gst_hst',
  'ca_pst_bc',
  'ca_pst_mb',
  'ca_pst_sk',
  'ca_qst',
  'ch_vat',
  'cl_tin',
  'es_cif',
  'eu_oss_vat',
  'eu_vat',
  'gb_vat',
  'ge_vat',
  'hk_br',
  'hu_tin',
  'id_npwp',
  'il_vat',
  'in_gst',
  'is_vat',
  'jp_cn',
  'jp_rn',
  'kr_brn',
  'li_uid',
  'mx_rfc',
  'my_frp',
  'my_itn',
  'my_sst',
  'no_vat',
  'nz_gst',
  'ru_inn',
  'ru_kpp',
  'sa_vat',
  'sg_gst',
  'sg_uen',
  'si_tin',
  'th_vat',
  'tw_vat',
  'ua_vat',
  'us_ein',
  'za_vat',
];

const ISO_REPLACEMENT = {
  de: 'eu_vat',
  as: 'eu_vat',
  be: 'eu_vat',
  bu: 'eu_vat',
  hr: 'eu_vat',
  cy: 'eu_vat',
  cz: 'eu_vat',
  dk: 'eu_vat',
  ee: 'eu_vat',
  fi: 'eu_vat',
  fr: 'eu_vat',
  gr: 'eu_vat',
  hu: 'eu_vat',
  ie: 'eu_vat',
  it: 'eu_vat',
  lt: 'eu_vat',
  lu: 'eu_vat',
  po: 'eu_vat',
  pt: 'eu_vat',
  ro: 'eu_vat',
  sk: 'eu_vat',
  sp: 'eu_vat',
  se: 'eu_vat',
};


/**
 * returns stripeVatId default based on countryIsoAlpha2 or empty string
 * @param {string} countryIsoAlpha2
 */
export const getStripeVatIdDefault = (countryIsoAlpha2) => {
  const countryIso = countryIsoAlpha2.toLowerCase();
  const vatDefault = stripeVatIds.findIndex((vatId) => vatId.startsWith(countryIso));

  return vatDefault || '';
};

export const getStripeVatTypes = (countryIsoAlpha2) => {
  const countryIso = ISO_REPLACEMENT[countryIsoAlpha2] || countryIsoAlpha2.toLowerCase();
  const vatTypes = stripeVatIds.filter((vatId) => vatId.startsWith(countryIso));

  return vatTypes || [];
};

// currency
const currencySymbolMap = {
  usd: '$',
  eur: '€',
};
/**
 * returns currency symbol or capitalised currencyIso iso 4217 code
 * https://www.iso.org/iso-4217-currency-codes.html
 * @param {string} currencyIso
 */
export const getCurrencySymbol = (currencyIso = '') => currencySymbolMap[currencyIso] || currencyIso.toUpperCase();

/**
 * takes a stripePrice (in cents) and currency (iso string) normalises it by dividing by
 * Config.stripPriceDivier (100) and formats it with two decimals
 * and a localised divider
 * @param {number} stripePrice in cents
 * @param {string} currencyIso in iso 4217
 * @returns
 */
export const getPriceFromCents = (
  stripePrice = 0,
  currencyIso = '',
  decimalsIncluded = true, // false will only work with prices divisble by 100
) => {
  const normalisedPrice = stripePrice / Config.stripePriceDivider;
  const currencySymbol = getCurrencySymbol(currencyIso);
  const priceString = numberWithForcedDecimals(normalisedPrice, { decimals: 2 });

  return `${decimalsIncluded ? priceString : normalisedPrice}${currencySymbol ? ' ' : ''}${currencySymbol}`;
};
