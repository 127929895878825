// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './DynamicFilter.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BluCSSTransition, DropDownSubtle, Scrollable } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

const emptyFilterItems = [];

// COMPONENT: DynamicFilter
const DynamicFilter = (props) => {
  // PROPS
  const {
    filterItems = emptyFilterItems,
    onReset,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: DynamicFilter
  return (
    <div className={classNames(styles.dynamicFilter)}>
      <Scrollable
        drag
        scroll
        scrollOnClick
        fade
        fadeWhite
      >
        <div className={styles.pillContainer}>
          { filterItems.map((filterItem) => (
            <DropDownSubtle
              key={filterItem.id}
              className='containerPositionOverride'
              options={filterItem.options}
              selected={filterItem.selected}
              onChange={filterItem.onChange}
            >
              { filterItem.label }
            </DropDownSubtle>
          )) }

          { /* Reset button */ }
          <BluCSSTransition
            in={Boolean(filterItems.reduce((acc, { selected }) => acc + selected.size, 0))}
            classNames={{ ...styles }}
          >
            <div
              className={styles.resetFilters}
              role='presentation'
              onClick={() => {
                filterItems.forEach((filterItem) => {
                  filterItem.onChange(new Set());
                });

                onReset();
              }}
            >
              <IconsSvg.Loop />
              { translate('myprofile_recommendation_filter_reset') }
            </div>
          </BluCSSTransition>
        </div>
      </Scrollable>
    </div>
  );
};

export default DynamicFilter;
