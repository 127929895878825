import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './OptionsMenu.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { handleRipple } from 'utils/ripple';
import { useBreakpoint, useDebounce, useWindowWidth } from 'utils/hooks';

// OTHER COMPONENTS
import { ReactComponent as OptionsIcon } from 'assets/icons/icn_options.svg';
// eslint-disable-next-line import/no-cycle
import { BluCSSTransition } from 'ui/basic';

const Config = {
  flyoutMenuHeight: 182,
  optionsMenuHeight: 36,
  spaceXs: 16,
};

const OptionsMenu = (props) => {
  const {
    withBackground = false,
    withBackgroundLight = false,
    withBackgroundDark = false,
    flyoutPosition = 'right',
    options = [],
    onClick = () => {},
  } = props;

  // SPECIAL HOOKS
  const { isXs } = useBreakpoint();
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);
  const transitionRef = useRef();

  const [ node, setNode ] = useState();
  const ref = useCallback((newNode) => {
    if (newNode !== node && newNode !== null) {
      setNode(newNode);
    }
  }, [ node ]);

  const [ leftPosition, setLeftPosition ] = useState();
  useEffect(() => {
    if (node && debouncedWindowWidth) {
      const { left } = node.getBoundingClientRect();
      setLeftPosition(isXs ? -(left - 16) : 'unset');
    }
  }, [ node, isXs, debouncedWindowWidth ]);

  const [ showMenu, setShowMenu ] = useState(false);

  const [ flyoutVerticalPosition, setFlyoutVerticalPosition ] = useState('top');
  const handleClick = (event) => {
    event.stopPropagation();

    const bodyHeight = document.body.clientHeight;
    const targetTopPosition = event.target.getBoundingClientRect().top;
    const overallTop = targetTopPosition + Config.optionsMenuHeight + Config.spaceXs;

    setFlyoutVerticalPosition(overallTop + Config.flyoutMenuHeight > bodyHeight
      ? 'bottom'
      : 'top');

    // prevent further propagation for instance for use in Cards
    // with their own onClick behavior.
    setShowMenu(!showMenu);
  };

  const showBackground = withBackground;

  if (!options?.length) {
    return null;
  }

  // RENDER
  return (
    <div
      tabIndex='0'
      role='button'
      ref={ref}
      className={classNames(styles.optionsMenu, {
        [styles.withBackground]: withBackground,
        [styles.withBackgroundLight]: withBackgroundLight,
        [styles.withBackgroundDark]: withBackgroundDark,
      })}
      onBlur={() => {
        setTimeout(() => {
          setShowMenu(false);
        }, 100);
      }}
      onClick={handleClick}
      onKeyPress={handleClick}
    >

      { showBackground && (
        <div className={classNames(styles.background, { [styles.active]: showMenu })}>
          <OptionsIcon />
        </div>
      ) }

      { !showBackground && <OptionsIcon /> }

      <BluCSSTransition
        nodeRef={transitionRef}
        in={showMenu}
        classNames={{
          enter: flyoutVerticalPosition === 'top' ? styles.topEnter : styles.bottomEnter,
          enterActive: flyoutVerticalPosition === 'top' ? styles.topEnterActive : styles.bottomEnterActive,
          enterDone: flyoutVerticalPosition === 'top' ? styles.topEnterDone : styles.bottomEnterDone,
          exit: flyoutVerticalPosition === 'top' ? styles.topExit : styles.bottomExit,
          exitActive: flyoutVerticalPosition === 'top' ? styles.topExitActive : styles.bottomExitActive,
          exitDone: flyoutVerticalPosition === 'top' ? styles.topExitDone : styles.bottomExitDone,
        }}
      >
        <div
          ref={transitionRef}
          style={{ left: leftPosition }}
          className={classNames(
            styles.flyout,
            styles[flyoutPosition],
            styles[flyoutVerticalPosition],
          )}
        >
          { options.map((option) => {
            const menuItemProps = {
              className: styles.menuItem,
              onClick: (event) => {
                handleRipple(event, styles.colorPrimary3);
                window.setTimeout(() => {
                  onClick(option.value, event);
                  setShowMenu(false);
                }, styles.animationDurationLongMs);
              },
            };

            return (
              <div key={option.value} {...menuItemProps}>
                <span>{ option.label }</span>
              </div>
            );
          }) }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default OptionsMenu;
