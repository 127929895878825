// import React from 'react';

// INTERMISSIONS
import { PaymentCycleChange } from '../components/payment/PaymentCycleChange';

// OTHER COMPONENTS
// import { StoreNext } from 'ui/basic';

export const adminPaymentCycleChangeConfig = {
  id: 'adminPaymentCycleChange',
  title: 'payment_cycle_change',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    {
      isIntermission: true,
      customLayout: true,
      // customControls: true,
      noPrev: true,
      Component: PaymentCycleChange,
    },
  ],
};
