export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_FULFILLED = 'GET_TEAMS_FULFILLED';
export const GET_TEAMS_REJECTED = 'GET_TEAMS_REJECTED';

export const INIT_TEAM = 'INIT_TEAM';
export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_FULFILLED = 'GET_TEAM_FULFILLED';
export const GET_TEAM_REJECTED = 'GET_TEAM_REJECTED';

export const GET_TEAM_USERS = 'GET_TEAM_USERS';
export const GET_TEAM_USERS_FULFILLED = 'GET_TEAM_USERS_FULFILLED';
export const GET_TEAM_USERS_REJECTED = 'GET_TEAM_USERS_REJECTED';

export const GET_TEAM_TYPES = 'GET_TEAM_TYPES';
export const GET_TEAM_TYPES_FULFILLED = 'GET_TEAM_TYPES_FULFILLED';
export const GET_TEAM_TYPES_REJECTED = 'GET_TEAM_TYPES_REJECTED';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_FULFILLED = 'UPDATE_TEAM_FULFILLED';
export const UPDATE_TEAM_REJECTED = 'UPDATE_TEAM_REJECTED';

export const INIT_ADD_USERS = 'INIT_ADD_USERS';
export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_FULFILLED = 'ADD_USERS_FULFILLED';
export const ADD_USERS_REJECTED = 'ADD_USERS_REJECTED';

export const INIT_DELETE_USER = 'INIT_DELETE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const INIT_ADD_POSITIONS = 'INIT_ADD_POSITIONS';
export const ADD_POSITIONS = 'ADD_POSITIONS';
export const ADD_POSITIONS_FULFILLED = 'ADD_POSITIONS_FULFILLED';
export const ADD_POSITIONS_REJECTED = 'ADD_POSITIONS_REJECTED';

export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_POSITIONS_FULFILLED = 'GET_POSITIONS_FULFILLED';
export const GET_POSITIONS_REJECTED = 'GET_POSITIONS_REJECTED';

export const INIT_DELETE_POSITION = 'INIT_DELETE_POSITION';
export const DELETE_POSITION = 'DELETE_POSITION';
export const DELETE_POSITION_FULFILLED = 'DELETE_POSITION_FULFILLED';
export const DELETE_POSITION_REJECTED = 'DELETE_POSITION_REJECTED';

export const INIT_FILL_POSITION = 'INIT_FILL_POSITION';
export const FILL_POSITION = 'FILL_POSITION';
export const FILL_POSITION_FULFILLED = 'FILL_POSITION_FULFILLED';
export const FILL_POSITION_REJECTED = 'FILL_POSITION_REJECTED';

export const GET_ASSESSMENT_RESULTS = 'GET_ASSESSMENT_RESULTS';
export const GET_ASSESSMENT_RESULTS_FULFILLED = 'GET_ASSESSMENT_RESULTS_FULFILLED';
export const GET_ASSESSMENT_RESULTS_REJECTED = 'GET_ASSESSMENT_RESULTS_REJECTED';

export const INIT_TEAM_PROFILES = 'INIT_TEAM_PROFILES';
export const GET_TEAM_PROFILES = 'GET_TEAM_PROFILES';
export const GET_TEAM_PROFILES_FULFILLED = 'GET_TEAM_PROFILES_FULFILLED';
export const GET_TEAM_PROFILES_REJECTED = 'GET_TEAM_PROFILES_REJECTED';

export const INIT_UPDATE_USER_ROLE = 'INIT_UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_FULFILLED = 'UPDATE_USER_ROLE_FULFILLED';
export const UPDATE_USER_ROLE_REJECTED = 'UPDATE_USER_ROLE_REJECTED';
