// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useRef, useState } from 'react';
import styles from './HotChips.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { InputNext, Chip, BluCSSTransition } from 'ui/basic';


// LOCAL CHILD COMPONENT: AnimatedChip
const AnimatedChip = (props) => {
  const {
    content,
    onClick,
    in: transitionIn, // injected by TransitionGroup
  } = props;

  const transitionRef = useRef();

  return (
    <BluCSSTransition
      nodeRef={transitionRef}
      classNames={{ ...styles }}
      in={transitionIn}
    >
      <Chip
        ref={transitionRef}
        onClick={onClick}
      >
        { content }
      </Chip>
    </BluCSSTransition>
  );
};


// COMPONENT: HotChips
const HotChips = (props) => {
  // PROPS
  const {
    type = 'text',
    values,
    crossValues = [], // includes values from other HotChips that are connected to this one
    label,
    placeholder,
    hint,
    errorHint,
    errorHintDuplicate,
    confirmHint,
    validate,
    onUpdate,
    required = true,
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ list, setList ] = useState(values || []);
  const [ error, setError ] = useState(null);
  const [ disabled, setDisabled ] = useState(true);
  const [ focussed, setFocussed ] = useState(false);

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    if (values?.length) {
      setList(values);
    }
  }, [ values ]);

  // OTHER HOOKS

  // HELPERS
  const validateLength = (l) => {
    if (!l.length && required) {
      setError({ empty: true });
    } else {
      setError(null);
    }
  };

  // METHODS
  const addItem = (item) => {
    // prevent duplicates
    if ([ ...list, ...crossValues ].indexOf(item) !== -1) {
      setError({ duplicate: true });
      return;
    }

    const newList = [ ...list ];
    newList.unshift(item); // add at the beginning for more dynamic feel on mobile
    setList(newList);
    if (onUpdate) {
      onUpdate(newList);
    }
    validateLength(newList);
  };
  const removeItem = (item) => {
    const newList = list.filter((listItem) => listItem !== item);
    setList(newList);
    if (onUpdate) {
      onUpdate(newList);
    }
    validateLength(newList);
  };

  // HANDLES
  const handleFocus = () => {
    setFocussed(true);
  };
  const handleBlur = () => {
    validateLength(list);
    setFocussed(false);
  };

  // RENDERS

  // RENDER: HotChips
  return (
    <div className={`${styles.hotChips}`}>
      { label && (
        <div className={styles.label}>
          { label }
        </div>
      ) }

      { /* INPUT */ }
      <InputNext
        type={type}
        placeholder={placeholder}
        validate={{
          ...(validate || {}),
        }}
        onConfirm={(item) => {
          addItem(item);
          setDisabled(true);
        }}
        onError={(err) => {
          setError(err);
          handleBlur();
        }}
        onChange={(_, err) => {
          if (err && err.duplicate) {
            setError(null);
          }
          setDisabled(Boolean(err));
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        bigButton
        bigButtonDisabled={disabled}
      />

      { /* FLYOUT */ }
      { confirmHint && focussed && (
        <div className={classNames(
          styles.flyoutContainer,
          { [styles.hide]: !focussed },
        )}
        >
          <div className={styles.flyout}>
            { confirmHint }
          </div>
        </div>
      ) }

      { /* HINT */ }
      { (hint || error) && (
        <div className={classNames(
          styles.hint,
          { [styles.error]: error },
        )}
        >
          { ((error?.duplicate && errorHintDuplicate) || (error && errorHint)) || hint }
        </div>
      ) }

      { /* CHIPS */ }
      <div className={styles.chips}>
        <TransitionGroup>
          { list.map((item) => {
            const content = item?.label ?? item;
            return (
              <AnimatedChip
                key={content}
                content={content}
                onClick={() => removeItem(item)}
              />
            );
          }) }
        </TransitionGroup>
      </div>

    </div>
  );
};

export default HotChips;
