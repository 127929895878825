// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ShowResultsButton.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { apiNext } from 'apiNext';

// UTILS
import { useTranslate } from 'utils/translator';
import { Toggle } from 'ui/basic';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: ShowResultsButton
const ShowResultsButton = (props) => {
  // PROPS
  const {
    userId,
    assessmentId,
    sharedWithUser = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: ShowResultsButton
  return (
    <div className={classNames(styles.showResultsButton)}>
      <Toggle
        label={translate('manager_control_show_results_label')}
        checked={sharedWithUser}
        disabled={false}
        onChange={(shared) => {
          try {
            apiNext.post(
              `/core/assessments/${assessmentId}/result/visibility`,
              { shared },
              { user: userId },
            );
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </div>
  );
};

export default ShowResultsButton;
