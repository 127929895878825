// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './MultiEmailInput.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { TextArea } from 'ui/basic';

// UTILS
import REGEXES from 'utils/configuration/const/regexes';
import { useTranslate } from 'utils/translator/translator';

// COMPONENT: MultiEmailInput
const MultiEmailInput = (props) => {
  // PROPS
  const {
    value,
    placeholder,
    onChange = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ emailsValid, setEmailsValid ] = useState();

  const handleChange = (text) => {
    const emails = text
    .replace(/\s+/g, ',')
    .split(',')
    .filter((el, idx, arr) => el && arr.indexOf(el) === idx);
    const areEmailsValid = emails.every((el) => REGEXES.EMAIL.test(el));

    setEmailsValid(areEmailsValid);
    onChange(emails, text, areEmailsValid);
  };

  // RENDER: MultiEmailInput
  return (
    <div className={classNames(styles.multiEmailInput)}>
      <TextArea
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <div className={classNames('bluTypeHint', 'marginTopXxs')}>
        { emailsValid === false && translate('multiple_emails_invalid_msg') }
      </div>
    </div>
  );
};

export default MultiEmailInput;
