// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useCallback } from 'react';
import styles from './QuestionDragDrop.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, DragAndDrop } from 'ui/basic';


// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
};


// COMPONENT: QuestionDragDrop
const QuestionDragDrop = (props) => {
  // PROPS
  const {
    question,
    // range = {},
    selectedValue,
    onAnswer = () => {},
    // onAnimation = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ showAnimation, setShowAnimation ] = useState(true);
  const [ answered, setAnswered ] = useState(false);

  // Drag and Drop selected items
  const [ isValid, setIsValid ] = useState(false);
  const [ selectedItems, setSelectedItems ] = useState([]);
  useEffect(() => {
    setIsValid(selectedItems.length === 3);
  }, [ selectedItems ]);

  const questionContainerClassNames = classNames(
    styles.questionContainer,
  );

  const containerClassNames = classNames(styles.container, {
  });

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  useEffect(() => {
    setSelectedItems([]);
    setShowAnimation(false);
    setTimeout(() => {
      setShowAnimation(true);
    });
  }, [ question ]);
  useEffect(() => {
    // console.log('selectedValue', selectedValue);
    setSelectedItems(selectedValue || []);
  }, [ selectedValue ]);

  // STORE HOOKS

  // METHODS
  // EVENT HANDLES
  const handleAnswer = useCallback(() => {
    if (!isValid || answered) return;

    setAnswered(true);
    setTimeout(() => {
      onAnswer(selectedItems.map((item) => item.id));
    }, CONFIG.answerDelay);
  }, [ isValid, answered, onAnswer, selectedItems ]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAnswer();
    }
  }, [ handleAnswer ]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  // HELPERS

  // RENDERS

  // RENDER: QuestionDragDrop
  if (!showAnimation) {
    return <div />;
  }

  if (!question) {
    return null;
  }
  return (
    <div className={classNames(styles.questionDragDrop, {
      [styles.closing]: answered,
      [styles.valid]: isValid,
    })}
    >
      <div className={containerClassNames}>
        <div className={questionContainerClassNames}>
          <div className={styles.question}>
            { (question && question.question) || translate(`9levels_ass_dnd_${question.id}`) }
          </div>

          { question.description && (
            <div className={styles.questionDescription}>
              { translate(question.description) }
            </div>
          ) }

          <div className={styles.sliderBlock}>
            <DragAndDrop
              items={question.keywords.map((keyword) => ({ id: keyword.name, name: keyword.translation }))}
              onDrop={(newSelectedItems) => {
                setSelectedItems(newSelectedItems);
              }}
            />
          </div>

          <div className={styles.button}>
            <Button
              size='M'
              disabled={!isValid}
              onClick={handleAnswer}
            >
              { translate('9levels_ass_continue_btn') }
            </Button>
          </div>

          <div className={classNames(styles.hint, {
            [styles.show]: selectedItems.length > 0,
          })}
          >
            <span>{ translate('9levels_ass_dnd_placeholder') }</span>
          </div>

        </div>
      </div>
    </div>
  );
};

export default QuestionDragDrop;
