import { get } from 'api';
import * as action from 'store/actions';
import { call, put, take } from 'redux-saga/effects';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';


export function* listAssessments() {
  try {
    // Extra fetches associated to assessments
    if (!getIsExternalSystemIntegrationSupported()) {
      yield put(action.listSurveyParticipations());
      yield put(action.listRecommendations());
    }

    const { status, ok, data } = yield call(get, '/core/assessments');

    if (ok && status === 200) {
      yield put(action.listAssessmentsFulfilled(data));
    } else { // will 403 for coachHub
      yield put(action.listAssessmentsRejected(data));
    }
  } catch (error) {
    yield put(action.listAssessmentsRejected({ error }));
  }
}

export default function* watchlistAssessmentsRequest() {
  while (true) {
    try {
      yield take(action.LIST_ASSESSMENTS);
      yield call(listAssessments);
    } catch (error) {
      yield put(action.listAssessmentsRejected({ error }));
    }
  }
}

