export const UPLOAD_USER_PROFILE_PICTURE = 'UPLOAD_USER_PROFILE_PICTURE';
export const UPLOAD_USER_PROFILE_PICTURE_PENDING = 'UPLOAD_USER_PROFILE_PICTURE_PENDING';
export const UPLOAD_USER_PROFILE_PICTURE_FULFILLED = 'UPLOAD_USER_PROFILE_PICTURE_FULFILLED';
export const UPLOAD_USER_PROFILE_PICTURE_REJECTED = 'UPLOAD_USER_PROFILE_PICTURE_REJECTED';

export const uploadUserProfilePicture = (data) => ({
  type: UPLOAD_USER_PROFILE_PICTURE,
  payload: data,
});

export const uploadUserProfilePictureFulfilled = () => ({
  type: UPLOAD_USER_PROFILE_PICTURE_FULFILLED,
  payload: null,
});
export const uploadUserProfilePictureRejected = ({ error }) => ({
  type: UPLOAD_USER_PROFILE_PICTURE_REJECTED,
  payload: { error },
});

