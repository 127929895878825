import * as fromActions from 'store/actions/configuration';

const initialState = {
  initialised: false,
  loading: false,

  deployment: {
    isAdminFrontend: false,
  },
  instance: {
    flavor: 'bluquist',
  },

  company: {
    // tbd:
    companyId: '',
    customLogoUrl: '',
    hasExternalLegalAgreements: false,
    availableInstances: null,

    // api spec:
    // "invitationDomainLimit": "string",
    // "openRegistrationDomains": [
    // null
    // ],
    // "wellbeingMails": true,
    // "assessmentReminderMails": true,
    // "externalDataProcessingContext": true,
    // "custom": { }

  },
  features: [],
  userGroup: '',
  userSubGroup: '',
  switches: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActions.GET_CONFIGURATION:
    case fromActions.GET_CONFIGURATION_EXTENDED: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActions.GET_CONFIGURATION_SUCCESS: {
      const { company, deployment } = action.payload;

      return {
        ...state,
        deployment,
        company,
        loading: false,
        initialised: true,
      };
    }
    case fromActions.GET_CONFIGURATION_ERROR:
    case fromActions.GET_CONFIGURATION_EXTENDED_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case fromActions.GET_CONFIGURATION_EXTENDED_SUCCESS: {
      let {
        features, userGroup, userSubGroup, switches,
      } = action.payload;

      features = features || state.features;
      userGroup = userGroup || state.userGroup;
      userSubGroup = userSubGroup || state.userSubGroup;
      switches = switches || state.switches;
      return {
        ...state,
        loading: false,
        features,
        userGroup,
        userSubGroup,
        switches,
      };
    }
    case fromActions.SET_CONFIGURATION_COMPANY: {
      const { company } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          ...company,
        },
      };
    }
    case fromActions.SET_CONFIGURATION_CUSTOM_LOGO_URL: {
      const { customLogoUrl } = action.payload;
      const company = {
        ...state.company,
        customLogoUrl,
      };
      return {
        ...state,
        company,
      };
    }
    case fromActions.SET_CONFIGURATION_AVAILABLE_INSTANCES: {
      const { availableInstances } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          availableInstances,
        },
      };
    }
    case fromActions.SET_INSTANCE_FLAVOR: {
      const { flavor } = action.payload;
      const instance = {
        ...state.instance,
        flavor,
        flavorLoaded: true,
      };
      return {
        ...state,
        instance,
      };
    }
    default: {
      return state;
    }
  }
};
