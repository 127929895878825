// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ArchDiagram.module.scss';

// CONFIG & DATA
const Config = {
  size: 100,
  stroke: 8,
};


// COMPONENT: ArchDiagram
const ArchDiagram = (props) => {
  // PROPS
  const {
    score = 0, // range: [0, 1]
  } = props;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // These svg properties cannot be stored as css, Safari will complain
  const circleProps = {
    cx: Config.size / 2,
    cy: Config.size / 2,
    r: (Config.size - Config.stroke) / 2,
    pathLength: Config.size * 2,
    strokeWidth: Config.stroke,
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: ArchDiagram
  return (
    <svg
      className={styles.archDiagram}
      viewBox={`0 ${Config.size / 2} ${Config.size} ${Config.size / 2}`}
    >
      <circle
        className={styles.background}
        {...circleProps}
      />
      <circle
        className={styles.score}
        {...circleProps}
        strokeDasharray={`${score * Config.size} 100`}
      />
    </svg>
  );
};

export default ArchDiagram;
