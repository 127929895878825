import { post } from 'api';
import * as action from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/signUp';
import { call, put, take } from 'redux-saga/effects';

// UTILS
import { COMPANY_ID } from 'utils/configuration';

function* signUp(payload) {
  try {
    const { mail, password, invitationId } = payload.signUpDto;
    yield put(action.loading());
    const { status, data } = yield call(
      post,
      '/core/user/register',
      {
        mail,
        password,
        invitationId,
        companyId: COMPANY_ID,
      },
    );

    if (status === 200) {
      if (COMPANY_ID && !invitationId) {
        yield put(action.sendSignUpEmailFulfilled());
      } else {
        yield put(action.signUpFulFilled({ token: data.token }));
        yield put(action.getCurrentUser());
      }
    } else {
      yield put(action.signUpRejected(data));
    }
  } catch (error) {
    yield put(action.signUpRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

export default function* watchSighUpRequest() {
  while (true) {
    try {
      const { payload } = yield take(fromActionTypes.SIGN_UP);
      yield call(signUp, payload);
    } catch (error) {
      yield put(action.signUpRejected(error));
    }
  }
}
