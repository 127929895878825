// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './AssessmentsList.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Checkbox } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectPlan,
  selectPlanId, selectPlanIsNonBilling,
  selectPlanIsTrial,
  selectPlanType,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};
import { Config, CURRENCY_SYMBOLS } from 'features/+adminUG/config/payment.config';
import {
  ASSESSMENT_RECURRENCE_TYPES,
} from 'utils/configuration/const/assessment-types';

// COMPONENT: AssessmentsList
const AssessmentsList = (props) => {
  // PROPS
  const {
    assessments = [],
    premiumAssessmentCanBePurchased = false,
    showGrantManagementAccess = false,
    onChange = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const plan = useSelector(selectPlan);
  const planId = useSelector(selectPlanId);
  const planType = useSelector(selectPlanType);
  const planIsTrial = useSelector(selectPlanIsTrial);
  const planIsNonBilling = useSelector(selectPlanIsNonBilling);

  const [ assessmentsPrices, setAssessmentsPrices ] = useState({});
  const [ pricesRequested, setPricesRequested ] = useState(false);
  useEffect(() => {
    if (pricesRequested) {
      return;
    }

    setPricesRequested(true);
    api.get('/core/company/billing/plan/prices', { plan: planId })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setAssessmentsPrices(data.prices?.assessments);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ assessmentsPrices, planId, pricesRequested ]);

  // ASSESSMENTS INTERNAL: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ assessmentsInternal, setAssessmentsInternal ] = useState([]);
  useEffect(() => {
    if (assessmentsInternal.length
      || !assessments.length
      || (!planIsTrial && plan?.required && !Object.keys(assessmentsPrices).length)) {
      return;
    }

    const mappedAssessments = assessments.map((assessment) => {
      let price;
      let disabled;
      const assessmentId = assessment.id.split('ASSESSMENT_')[1]?.toLowerCase();

      if (planIsTrial) {
        price = assessment.premium && translate('subscription_only');
        disabled = !!assessment.premium;
      } else if (planIsNonBilling) {
        price = assessment.premium
          ? translate('subject_to_surcharge')
          : translate('in_profile_price_included');
      } else {
        price = !assessmentsPrices[assessmentId]
          ? translate('in_profile_price_included')
          : `${assessmentsPrices[assessmentId].pricePerUnit / Config.stripePriceDivider} ${CURRENCY_SYMBOLS[assessmentsPrices[assessmentId].currency]} / Assessment`;
      }

      if (assessment.premium && !premiumAssessmentCanBePurchased) {
        disabled = true;
      }

      return {
        ...assessment,
        price,
        disabled,
      };
    });

    setAssessmentsInternal(mappedAssessments);
    onChange(mappedAssessments);
  }, [
    translate,
    plan,
    planType,
    planIsTrial,
    assessments,
    assessmentsPrices,
    premiumAssessmentCanBePurchased,
    assessmentsInternal,
    onChange,
  ]);

  // HANDLERS
  const handleChange = (value, assessment) => {
    const thisAssessment = assessmentsInternal.find((aInternal) => aInternal.id === assessment.id);

    if (thisAssessment) {
      thisAssessment.active = value;
      onChange(assessmentsInternal);
    }
  };

  const handleGrantManagementAccess = (checked, assessmentId) => {
    const thisAssessment = assessmentsInternal.find((aInternal) => aInternal.id === assessmentId);

    if (thisAssessment) {
      thisAssessment.grantManagementAccess = checked;
      onChange(assessmentsInternal);
    }
  };

  // RENDER: AssessmentsList
  return (
    <div className={classNames(styles.assessmentsList)}>
      { assessmentsInternal
      .filter((a) => showGrantManagementAccess || a.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN)
      .map((assessment) => {
        const isRunBased = assessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN;

        return (
          <div className={styles.assessmentItem} key={assessment.id}>
            <div className={styles.listRow}>
              { !isRunBased && (
                <>
                  <Checkbox
                    name={assessment.name}
                    checked={assessment.active}
                    disabled={assessment.disabled}
                    onChange={(value) => handleChange(value, assessment)}
                  />
                  <span>{ assessment.price }</span>
                </>
              ) }

              { isRunBased && (
                <div className='bluTypeCopy'>{ assessment.name }</div>
              ) }
            </div>

            { showGrantManagementAccess && (
              <div className={styles.grantManagementAccess}>
                <Checkbox
                  name={translate('grant_management_access_lbl')}
                  onChange={(value) => handleGrantManagementAccess(value, assessment.id)}
                />
              </div>
            ) }
          </div>
        );
      }) }
    </div>
  );
};

export default AssessmentsList;
