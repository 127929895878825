// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './GapAnalysis.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { Callout, LineDiagram } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';

// CONFIG & DATA
const Config = {
  classes: { // note: mind the plural forms
    clearStrength: 'clearStrengths',
    clearWeakness: 'clearWeaknesses',
    unrecognizedStrength: 'unrecognizedStrengths',
    unrecognizedWeakness: 'unrecognizedWeaknesses',
  },
};


// COMPONENT: GapAnalysis
const GapAnalysis = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    segmentsCount,
    renderMin,
    assessmentResults = [],
    assessmentResultsAll = [],
    forCustomReport = false,
  } = props;

  const {
    limitDimensionsPerClass,
    hideEmptyClasses,
    dimensionLevel,
  } = configuration;
  const {
    topLevelDimension,
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const getMappedDimension = (dimension) => {
    const selfScore = dimension.peerResults.find((pr) => pr.id === 'self')?.result || 1;
    const others = dimension.peerResults.filter((pr) => pr.id !== 'self');
    const othersScore = others.reduce((acc, current) => acc + current.result, 0) / others.length;
    const gap = othersScore - selfScore;

    return {
      id: dimension.id,
      name: dimension.name || dimension.id,
      score: dimension.result,
      selfScore,
      othersScore,
      gap,
    };
  };

  const data = Object.values(Config.classes).reduce((acc, el) => {
    acc[el] = [];
    return acc;
  }, {});

  let dimensions = assessmentResults.filter(({ result }) => Number.isFinite(result));
  if (topLevelDimension) {
    // assessmentResultsAll containing all visible and hidden dimensions
    // https://blueexcellence.atlassian.net/browse/BDE-33
    dimensions = assessmentResultsAll
    .filter(({ result }) => Number.isFinite(result))
    .filter((result) => result.id === topLevelDimension);
  }

  if (dimensionLevel !== 'top-level') {
    dimensions.forEach((result) => {
      if (result.subDimensions) {
        dimensions.push(...result.subDimensions);
      }
    });

    if (dimensionLevel === 'sub') {
      dimensions = dimensions.filter((el) => el.parentDimension);
    }
  }

  dimensions.forEach((dimension) => {
    const classification = Config.classes[dimension.peerClassification];
    data[classification]?.push(getMappedDimension(dimension));
  });

  // Sort by gap wideness for unrecognized strengths and weaknesses
  // by descending overall result for clear strengths
  // by ascending overall result for clear weaknesses
  Object.entries(data).forEach(([ key, v ]) => {
    if ([ Config.classes.unrecognizedStrength, Config.classes.unrecognizedWeakness ].includes(key)) {
      return v.sort((a, b) => Math.abs(b.gap) - Math.abs(a.gap));
    }

    if (key === Config.classes.clearStrength) {
      return v.sort((a, b) => b.score - a.score);
    }

    return v.sort((a, b) => a.score - b.score);
  });

  // Limit displayed max dimensions
  if (limitDimensionsPerClass) {
    Object.entries(data).forEach(([ k, v ]) => {
      data[k] = v.slice(0, limitDimensionsPerClass);
    });
  }

  // Hide empty classes
  if (hideEmptyClasses) {
    Object.entries(data).forEach(([ k, v ]) => {
      if (!v.length) {
        delete data[k];
      }
    });
  }

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: GapAnalysis
  return (
    <div className={classNames(styles.gapAnalysis)}>
      { Object.keys(data).map((classification) => (
        <div
          key={classification}
          className={classNames(styles.classification, { [styles.forCustomReport]: forCustomReport })}
        >
          <span className='bluTypeXs'>{ translate(`peer_360_report_strengths_classif_${classification}`) }</span>
          <div className={classNames('bluTypeCopy', 'marginTopXs')}>
            { translate(`peer_360_report_strengths_classif_copy_${classification}`) }
          </div>

          { !data[classification]?.length && (
            <div className='marginTopS'>
              <Callout triangleOnTop={false}>
                <>
                  <span className='bluTypeLabelL'>
                    { translate(`peer_360_report_strengths_classif_nodata_${classification}`) }
                  </span>
                  <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                    { translate(`peer_360_report_strengths_classif_nodata_copy_${classification}`) }
                  </div>
                </>
              </Callout>
            </div>
          ) }

          { data[classification]?.map((dimension, index) => (
            <div className={styles.classificationItem} key={dimension.id}>
              <div className={classNames([ styles.blue, styles.cardinal ])}>
                { `#${index + 1}` }
              </div>
              <div>
                <span className={styles.dimensionName}>
                  { translate(dimension.name) }
                </span>

                <div className={styles.diagramParent}>
                  <LineDiagram
                    score={dimension.othersScore}
                    score2={dimension.selfScore}
                    range={[
                      Math.min(dimension.selfScore, dimension.othersScore),
                      Math.max(dimension.selfScore, dimension.othersScore),
                    ]}
                    segmentsCount={segmentsCount}
                    renderMin={renderMin}
                    style={{ range: 'yellow' }}
                  />

                  <div className={styles.bottomPanel}>
                    <div className={styles.indBlock}>
                      <div className={styles.topBlock}>
                        <div className={styles.selfCircle} />
                        <span>{ translate('self_lbl') }</span>
                      </div>
                      <div>{ setDecimalSeparator(dimension.selfScore) }</div>
                    </div>
                    <div className={styles.indBlock}>
                      <div className={styles.topBlock}>
                        <div className={styles.othersCircle} />
                        <span>{ translate('others_lbl') }</span>
                      </div>
                      <div>{ setDecimalSeparator(dimension.othersScore) }</div>
                    </div>
                    <div className={styles.indBlock}>
                      <div>{ translate('gap_lbl') }</div>
                      <div>{ setDecimalSeparator(dimension.gap, 1, { signDisplay: 'always' }) }</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) }
        </div>
      )) }
    </div>
  );
};

export default GapAnalysis;
