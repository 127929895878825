// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
// import styles from './StoreNext.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { Provider } from 'react-redux';
import { storeNext } from 'features/framework/utils/storeNext';

// CONFIG & DATA
// const Config = {};

// COMPONENT: StoreNext
const StoreNext = (props) => {
  // PROPS

  const { children } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: StoreNext
  return (
    <Provider store={storeNext}>
      { children }
    </Provider>
  );
};

export default StoreNext;
