// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Strengths.module.scss';

// ASSETS
import { ReactComponent as Teaser } from './teaser.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Strengths
const Strengths = (props) => {
  // PROPS
  const {
    // onAnswer = () => {},
    controls = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Strengths
  return (
    <div className={classNames(styles.strengths)}>
      <div className={styles.content}>
        <div className={styles.teaser}>
          <Teaser />
        </div>
        <div className={styles.title}>
          { translate('onboarding_candidate_strengths_title') || 'Lerne dich und deine Stärken besser kennen.' }
        </div>
        <div className={styles.text}>
          { translate('onboarding_candidate_strengths_text') || `
            Über kurze spielerische Assessments
            erhälst du neue Erkenntnisse über deine individuellen
            Fähigkeiten und Vorlieben und darüber was
            dich als Person ausmacht.` }
        </div>
        { controls && (
          <div className={styles.controls}>
            { controls }
          </div>
        ) }
      </div>
    </div>
  );
};

export default Strengths;
