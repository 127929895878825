import * as localStorage from 'utils/localStorage';
import { LANGUAGES } from 'utils/configuration/const/languages';


/**
 * Given a list of language options, find the current user's preferred language.
 * Fallback to 'en_US' if no match is found.
 *
 * @param {Array} languages List of available languages to choose from
 * @returns {String} Selected language
 */
export const getUserLanguage = (languages) => {
  // Try and match the param to any of the available languages
  const getClosestMatch = (str) => {
    if (!str || typeof str !== 'string') {
      return undefined;
    }

    const language = str.replace('-', '_');

    // Find perfect match
    if (languages.includes(language)) {
      return language;
    }

    // If missing, find closest match of same language family
    const [ languageFamily ] = language.split('_');
    const regex = new RegExp(`^${languageFamily}_`);
    return languages.find((el) => regex.test(el));
  };

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const browserLanguage = window.navigator.language;
  return getClosestMatch(selectedLanguage) || getClosestMatch(browserLanguage) || LANGUAGES.EN;
};

/**
 * Formats a number to its locale representation.
 *
 * @param {Any} input value to format
 * @param {Number} [digitsNumber] how many digits to show after comma
 * @param {Object} [formattingOptions] extra formatting options (can override defaults)
 * @returns {String} Number with correct separator and decimal digits (rounded)
 */
export const setDecimalSeparator = (input, digitsNumber = 1, formattingOptions = {}) => {
  // Try to normalise and convert input to number.
  // Exiting for: non-numeric strings, null, undefined, booleans, objects, NaN.
  const inputAsString = String(input).trim().replace(',', '.');
  const number = Number(inputAsString);
  if (inputAsString === '' || Number.isNaN(number)) {
    return input;
  }

  // Convert current language to BCP 47 tag
  let language = localStorage.getItem('selectedLanguage') ?? LANGUAGES.EN;
  language = language.replace('_', '-');
  // Number formatting options
  const options = {
    minimumFractionDigits: digitsNumber,
    maximumFractionDigits: digitsNumber,
    useGrouping: false,
    ...formattingOptions,
  };

  let formattedNumber;
  try {
    formattedNumber = number.toLocaleString(language, options);
  } catch (error) {
    // Fallback for unsupported language tags
    if (error instanceof RangeError) {
      formattedNumber = number.toLocaleString(undefined, options);
    }
  }

  return formattedNumber;
};
