/* Utilities for storing CoachHub data on local storage. */

import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';

export const getIsExternalSystemIntegrationSupported = () => CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB
  || Boolean(localStorage.getItem('externalSystem'));

export const storeCoachHubParams = (data = {}) => {
  const itemKey = CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB ? 'coachHub' : 'externalSystem';
  localStorage.setItem(itemKey, JSON.stringify(data));
};

export const getCoachHubParam = (key) => {
  const itemKey = CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB ? 'coachHub' : 'externalSystem';
  let coachHubSettings = localStorage.getItem(itemKey);
  if (!coachHubSettings) {
    return null;
  }
  coachHubSettings = JSON.parse(coachHubSettings);


  return coachHubSettings[key] ?? null;
};

// Clear local storage for coachHub
export const deleteCoachHubParams = () => {
  const language = localStorage.getItem('selectedLanguage'); // language is a whitelisted value
  localStorage.clear();
  localStorage.setItem('selectedLanguage', language);
};

export const replaceCoachHubTarget = (newTarget, newPath) => {
  const itemKey = CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB ? 'coachHub' : 'externalSystem';

  const coachHubParams = JSON.parse(localStorage.getItem(itemKey));
  coachHubParams.target = newTarget;

  storeCoachHubParams(coachHubParams);
  window.open(newPath, '_self');
};
