import React, { lazy } from 'react';

const EmployeesMain = lazy(() => import('./pages/EmployeesMain' /* webpackChunkName: "employees" */));

export const routesConfigEmployees = {
  show: {
    forUserGroup: [ 'employee', 'leader', 'assessmentManager', 'admin' ],
    forFeature: 'employees',
    forCapabilities: { employee: 'employeesView', leader: 'employeesView' },
  },
  routes: [
    {
      path: '/employees',
      PageComponent: () => <EmployeesMain />,
      LazyPageComponent: 'EmployeeOverview',
      inMainNav: {
        labelKey: 'main_navigation_item_2',
      },
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      subRoutes: [
        {
          path: '/employees/:employeeId/profile',
          PageComponent: () => <EmployeesMain />,
          LazyPageComponent: 'EmployeeProfile',
          tools: {
            browserTitleKey: 'main_navigation_item_2',
          },
          show: {
            forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
          },
          subRoutes: [
            {
              path: '/employees/:employeeId/profile',
              inSubNav: {
                labelKey: 'employee_subnav_strength_profile',
              },
            },
            {
              path: '/employees/:employeeId/role',
              PageComponent: () => <EmployeesMain />,
              LazyPageComponent: 'EmployeeRoles',
              show: {
                forCapabilities: { leader: 'rolesView' },
                forFeature: [ 'employees', 'roles' ],
              },
              inSubNav: {
                labelKey: 'employee_subnav_role',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_2',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/user/invite',
      pageType: 'modal',
      PageComponent: () => <EmployeesMain />,
      LazyPageComponent: 'UserInvitationNext',
      show: {
        forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
        forCapabilities: { leader: [ 'employeesInvite', 'candidatesInvite' ] },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_2',
      },
    },
    {
      path: '/user/invite',
      pageType: 'modal',
      PageComponent: () => <EmployeesMain />,
      LazyPageComponent: 'UserInvitation',
      show: {
        forFlavor: [ 'balancedYou' ],
        forCapabilities: { leader: [ 'employeesInvite' ] },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_2',
      },
    },
  ],
};
