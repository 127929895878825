// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './VacancyInternalContent.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { VacancyExternalSummary } from '../VacancyExternalSummary';
import { VacancyExternalMatching } from '../VacancyExternalMatching';
import { VacancyExternalRoleDetails } from '../VacancyExternalRoleDetails';

// UTILS
import { useTranslate } from 'utils/translator';
import { useDispatch } from 'react-redux';

// STORE
import { listAssessments } from 'store/actions';

// CONFIG & DATA
// const Config = {};

// COMPONENT: VacancyInternalContent
const VacancyInternalContent = (props) => {
  // PROPS
  const { vacancy, token } = props;

  const pages = [
    { value: 'summary', label: 'vacancy_subnav_summary' },
    { value: 'details', label: 'vacancy_subnav_matching' },
  ];
  if (vacancy?.role) {
    pages.push({ value: 'role', label: 'vacancy_subnav_roledetails' });
  }

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ currentPage, setCurrentPage ] = useState(pages[0].value);
  useEffect(() => {
    dispatch(listAssessments());
  }, [ dispatch ]);

  const renderPage = () => {
    switch (currentPage) {
      case 'summary':
        return (
          <VacancyExternalSummary
            vacancy={vacancy}
            token={token}
            onMatchingClick={() => setCurrentPage(pages[1].value)}
          />
        );
      case 'details':
        return (
          <VacancyExternalMatching
            vacancy={vacancy}
            token={token}
          />
        );
      case 'role':
        return (
          <VacancyExternalRoleDetails
            vacancy={vacancy}
            token={token}
          />
        );
      default:
        return null;
    }
  };

  // RENDER: VacancyInternalContent
  return (
    <div className={classNames(styles.vacancyInternalContent)}>
      { /* NAVIGATION */ }
      <div className={styles.navigation}>
        { pages.map((page) => (
          <div
            key={page.value}
            role='presentation'
            className={classNames(styles.item, { [styles.active]: currentPage === page.value })}
            onClick={() => setCurrentPage(page.value)}
          >
            { translate(page.label) }
          </div>
        )) }
      </div>

      { renderPage() }
    </div>
  );
};

export default VacancyInternalContent;
