import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  selectConfiguration,
  selectRoutesFromConfigurationAndStateExtras,
  selectStateExtras,
} from './configurationSlice';

// initialState
const initialState = {
  routeInfo: {
    currentRoute: {
      path: '',
      pageType: '',
      // PageComponent: null, // this property will be deleted cause it cant be serialized
    },
    params: {},
    history: [], // contains objects of {currentRoute, partenRoute, grandParentRoute}
    // 0 is the route before the current state in the state
  },
  breadcrumb: {
    label: '',
    routesMustInclude: '',
    link: '',
    parentLink: '',
    parentLabel: '',
  },
};

// selectors
const selectRoute = (state) => state.route;
const selectRouteRouteInfo = createSelector(
  selectRoute,
  (route) => route.routeInfo,
);

export const selectRouteInfo = createSelector(
  selectConfiguration,
  selectRouteRouteInfo,
  selectStateExtras,
  (configuration, routeInfo, stateExtras) => {
    const { nestedRoutes } = selectRoutesFromConfigurationAndStateExtras(configuration, stateExtras);
    const { currentRoute } = routeInfo;

    // level1
    let newRouteInfo;
    if (nestedRoutes && nestedRoutes.length) {
      nestedRoutes.forEach((route) => {
        if (!newRouteInfo && route.subRoutes && route.subRoutes.length) {
          route.subRoutes.forEach((subRoute) => {
            if (!newRouteInfo) {
            // level 2
              if (subRoute.path === currentRoute.path) {
                newRouteInfo = { currentRoute, parentRoute: route };
              }
              if (subRoute.subRoutes && subRoute.subRoutes.length) {
                subRoute.subRoutes.forEach((subSubRoute) => {
                  if (!newRouteInfo) {
                  // level 3
                    if (subSubRoute.path === currentRoute.path) {
                      newRouteInfo = { currentRoute, parentRoute: subRoute, grandParentRoute: route };
                    }
                  }
                });
              }
            }
          });
        }
      });
    }

    if (!newRouteInfo) {
      newRouteInfo = { ...routeInfo };
    } else {
      newRouteInfo = { ...routeInfo, ...newRouteInfo };
    }
    return newRouteInfo;
  },
);

// breadcrumb
export const selectBreadcrumb = createSelector(
  selectRoute,
  (route) => route.breadcrumb,
);

// slice & reducers
export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRoute: (state, action) => {
      const {
        currentRoute = null,
        params = {},
      } = action.payload;

      // move current into to history
      const history = [ {
        currentRoute: state.routeInfo.currentRoute,
      }, ...state.routeInfo.history ];

      state.routeInfo = {
        currentRoute,
        params,
        history,
      };
    },
    setParams: (state, action) => {
      const { params } = action.payload;
      state.routeInfo.params = params;
    },
    setBreadcrumb: (state, action) => {
      const {
        label = '', routesMustInclude = '', link = '', parentLabel, parentLink,
      } = action.payload;
      state.breadcrumb = {
        label,
        routesMustInclude,
        link,
        parentLink,
        parentLabel,
      };
    },
  },
});

// reducer
export default routeSlice.reducer;

// actions
export const {
  setRoute, setParams, setBreadcrumb,
} = routeSlice.actions;
