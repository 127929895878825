import { createSelector } from '@reduxjs/toolkit';

export const getRegistrationDomains = (store) => (
  (store.settings && store.settings.registrationDomains) ? store.settings.registrationDomains : []
);

export const getAllFeatures = (store) => store.settings && store.settings.allFeatures;

export const selectCompanyFeatures = (store) => store.settings?.features || [];

export const getUserSettings = (store) => store.settings && store.settings.userSettings;

export const selectSettings = (store) => store?.settings;
export const selectUserSettingsLoaded = createSelector(
  selectSettings,
  (settings) => settings.userSettingsLoaded,
);

export const selectUserOnboardingCompletedNext = createSelector(
  getUserSettings,
  (userSettings) => (!!userSettings?.custom?.onboardingCompleted),
);

export const getUserSettingsError = (store) => store.settings && store.settings.userSettingsError;
export const getUpdateUserSettingsProcessing = (store) => store.settings && store.settings.updateUserSettingsProcessing;
export const getUpdateUserSettingsSuccess = (store) => store.settings && store.settings.updateUserSettingsSuccess;
export const selectLanguageIsChanged = (store) => store.settings && store.settings.languageChanged;

export const getCompanySettings = (store) => store.settings?.companySettings;
export const getCompanyIsBalancedYou = (store) => {
  const plan = getCompanySettings(store)?.plan || '';
  return plan.startsWith('balanced_you');
};

export const getCompanySsoProvider = (store) => getCompanySettings(store)?.ssoProvider;
export const getCompanySsoExclusive = (store) => getCompanySettings(store)?.ssoExclusive;

export const getEmployeeOverview = (store) => store.employeeOverview;

export const getCompanySettingsError = (store) => store.settings && store.settings.companySettingsError;
export const getUpdateCompanySettingsProcessing = (store) => store.settings && store.settings.updateCompanySettingsProcessing;

export const getUpdateCompanyFeaturesProcessing = (store) => store.settings && store.settings.updateCompanyFeaturesProcessing;
