// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './ProgressBar.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// CONFIG & DATA
const CONFIG = {
  loadingWidth: 20,
  alternateAnimationThreshhold: 40,
};

// COMPONENT: ProgressBar
const ProgressBar = (props) => {
  // PROPS
  const {
    progress = 0, // 0 - 100%
    loading = false,
    strong = false,
    size = '', // 'S'
    hide,
  } = props;

  const [ width, setWidth ] = useState(); // 0 - 100%

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    const progressSafe = Number.isNaN(Number(progress)) ? 0 : progress;
    let newWidth = progressSafe;
    if (loading && progressSafe < CONFIG.loadingWidth) {
      newWidth = CONFIG.loadingWidth;
    }

    setWidth(newWidth);
  }, [ progress, loading ]);

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: ProgressBar
  return (
    <div className={classNames(styles.progressBar, {
      [styles[`size${size}`]]: size,
      [styles.strong]: strong,
      [styles.hide]: hide,
    })}
    >
      <div
        className={classNames(styles.progressIndicator, {
          [styles.loading]: loading,
          [styles.alternateAnimation]: progress > CONFIG.alternateAnimationThreshhold,
        })}
        style={{ width: `${width}%` }}
      />
    </div>
  );
};

export default ProgressBar;
