// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ListNextCell.module.scss';

// ASSETS

// 3RD PARTY

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA

// COMPONENT: ListNextCell
const ListNextCell = (props) => {
  // PROPS
  const {
    label, identifier, hideEmptyLabel,
    value, hideEmptyValue,
    tooltip, tooltipHighlight,
  } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: ListNextCell
  return (
    <div className={styles.listNextCell}>
      { /* TOOLTIP */ }
      { (tooltip || tooltipHighlight) && (
        <div className={styles.tooltip}>
          { tooltip }
          { /* TOOLTIP HIGHLIGHT */ }
          { tooltipHighlight && (
            <span className={styles.highlight}>
              { tooltip ? ' ' : '' }
              { tooltipHighlight }
            </span>
          ) }
        </div>
      ) }

      { /* TEXTS */ }
      <div className={styles.texts}>

        { /* IDENTIFIER */ }
        { identifier && !(!identifier && hideEmptyLabel) && (
          <span className={styles.identifier}>
            { identifier }
          </span>
        ) }

        { /* LABEL */ }
        { label && !(!label && hideEmptyLabel) && (
          <span className={styles.label}>
            { label }
          </span>
        ) }

        { /* VALUE */ }
        { value && !(!value && hideEmptyValue) && (
          <span className={styles.value}>
            { value }
          </span>
        ) }
      </div>
    </div>
  );
};

export default ListNextCell;
