import React from 'react';

import { Provider } from 'react-redux';
import store from 'store';

// INTERMISSIONS
import { QuestionLanguage } from '../components/profile-completion/QuestionLanguage';
import { QuestionPrefilledName } from '../components/profile-completion/QuestionPrefilledName';
import { QuestionLocation } from '../components/profile-completion/QuestionLocation';
import { QuestionNationality } from '../components/profile-completion/QuestionNationality';
import { QuestionGender } from '../components/profile-completion/QuestionGender';
import { QuestionAge } from '../components/profile-completion/QuestionAge';
import { QuestionDegree } from '../components/profile-completion/QuestionDegree';
import { QuestionCareerLevel } from '../components/profile-completion/QuestionCareerLevel';
import { QuestionPosition } from '../components/profile-completion/QuestionPosition';
import { QuestionJobFamily } from '../components/profile-completion/QuestionJobFamily';
import { QuestionIndustry } from '../components/profile-completion/QuestionIndustry';
import { QuestionPicture } from '../components/profile-completion/QuestionPicture';
import { QuestionPolicy } from '../components/profile-completion/QuestionPolicy';
import { QuestionInCompanySince } from '../components/profile-completion/QuestionInCompanySince';


export const profileCompletionConfig = {
  id: 'profileCompletion',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 0,
  pages: [
    // INTRO
    {
      id: 'language',
      isIntermission: true,
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer, setModalCancelShow) => (
        <QuestionLanguage
          setModalCancelShow={setModalCancelShow}
          onNext={next}
        />
      ),
    },
    // NAME
    {
      id: 'username',
      noPrev: true,
      title: 'cp_name_question',
      render: (next, prev, state, answer, handleClosePrompt, setNewAnswer) => (
        <QuestionPrefilledName
          answer={answer}
          onAnswer={setNewAnswer}
        />
      ),
    },
    // LOCATION
    {
      id: 'location',
      title: 'cp_residence_question',
      Component: QuestionLocation,
      callout: true,
    },
    // NATIONALITY
    {
      id: 'nationality',
      isOptional: true,
      title: 'cp_nationality_question',
      callout: true,
      Component: QuestionNationality,
    },
    // GENDER
    {
      id: 'gender',
      isOptional: true,
      title: 'cp_gender_question',
      callout: true,
      Component: QuestionGender,
    },
    // AGE
    {
      id: 'age',
      isOptional: true,
      title: 'cp_age_question',
      callout: true,
      Component: QuestionAge,
    },
    // DEGREE
    {
      id: 'schoolDegree',
      title: 'cp_school_degree_question',
      callout: true,
      Component: QuestionDegree,
    },
    // IN COMPANY SINCE
    {
      id: 'inCompanySince',
      title: 'cp_in_company_since_question',
      callout: true,
      Component: QuestionInCompanySince,
    },
    // CAREER LEVEL
    {
      id: 'careerLevel',
      title: 'cp_careerlevel_question',
      Component: QuestionCareerLevel,
    },
    // POSITION
    {
      id: 'position',
      // 1 - beginner
      isOptional: (state) => state.answers.careerLevel?.value === 1
        && state.answers.careerLevel?.name === 'careerLevel',
      title: 'cp_current_position_question',
      titleOptional: 'cp_current_position_question_optional',
      Component: QuestionPosition,
    },
    // JOB FAMILY
    {
      id: 'jobFamily',
      // 1 - beginner
      isOptional: (state) => state.answers.careerLevel?.value === 1
        && state.answers.careerLevel?.name === 'careerLevel',
      title: 'cp_job_family_question',
      titleOptional: 'cp_job_family_question_optional',
      Component: QuestionJobFamily,
    },
    // INDUSTRY
    {
      id: 'experience',
      // 1 - beginner
      isOptional: (state) => state.answers.careerLevel?.value === 1
        && state.answers.careerLevel?.name === 'careerLevel',
      title: 'cp_industry_experience_question',
      titleOptional: 'cp_industry_experience_question_optional',
      Component: QuestionIndustry,
    },
    // PICTURE
    {
      id: 'picture',
      isOptional: true,
      title: 'cp_image_question',
      Component: (props) => (
        <Provider store={store}>
          <QuestionPicture {...props} />
        </Provider>
      ),
    },
    // CONFIRM POLICY
    {
      id: 'policy',
      title: 'cp_policy',
      titleForHasExternalLegalAgreements: 'cp_policy_external',
      Component: QuestionPolicy,
    },
    // END
    {
      id: 'end',
      isIntermission: true,
      showProgressBar: true,
      render: (next, prev, state, answer, handleClosePrompt, finishable, handleFinish, errorEnd) => (
        <>
          { /* /!* ERROR END *!/ */ }
          { errorEnd }
        </>
      ),
    },
  ],
};
