import React, { memo } from 'react';
import styles from './WorkPreferencesResult.module.scss';

import { useTranslate } from 'utils/translator';
import { BipolarDiagram } from 'ui/basic';


const WorkPreferencesResultItem = memo((props) => {
  const {
    score,
    id = '',
    range,
    name,
  } = props;

  const translate = useTranslate();

  const [ , index ] = id.split('wp_');

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        { name }
      </div>
      <BipolarDiagram
        diagramRange={[ -5, 5 ]}
        score={score}
        range={range}
        markers={[]}
      />
      <div className={styles.titles}>
        <div className={score < 0 ? styles.blue : null}>
          { translate(`workpreferences_results_min_dim${index}`) }
        </div>
        <div className={score > 0 ? styles.blue : null}>
          { translate(`workpreferences_results_max_dim${index}`) }
        </div>
      </div>
    </div>
  );
});

export default WorkPreferencesResultItem;
