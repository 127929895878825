// REACT, STYLE, STORIES & COMPONENT
import React, { useRef } from 'react';
import styles from './MultiSelect.module.scss';

// UTILS
import { useTranslate } from 'utils/translator';
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';


// COMPONENT: MultiSelectTransition
const MultiSelectTransition = (props) => {
  // PROPS
  const {
    option,
    children,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const transitionRef = useRef();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: MultiSelectTransition
  return (
    <>
      <div className={styles.label}>
        { ' ' }
        { translate(option.label) || option.label }
        { ' ' }
      </div>
      <BluCSSTransition
        nodeRef={transitionRef}
        in={option.selected}
        classNames={{ ...styles }}
      >
        <div ref={transitionRef} className={styles.inputContainer}>
          { children }
        </div>
      </BluCSSTransition>
    </>
  );
};

export default MultiSelectTransition;
