import React, { memo, useState } from 'react';
import styles from './CollapsiblePanel.module.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';

const CollapsiblePanel = memo((props) => {
  const {
    header,
    children,
    showTopBorder = true,
    headerClassName,
    expanded = false,
    isExpandable = true,

    onMouseDown,
    onMouseUp,
    onExpand,
  } = props;

  const [ isExpanded, setIsExpanded ] = useState(expanded);

  return (
    <div className={classNames(
      styles.collapsiblePanel,
      { [styles.expanded]: isExpanded },
    )}
    >
      <div
        className={classNames(
          styles.header,
          headerClassName,
          { [styles.expanded]: isExpanded },
          { [styles.showBorder]: showTopBorder },
        )}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        role='presentation'
        data-test='CollapsiblePanelHeader'
        onClick={() => {
          setIsExpanded(!isExpanded);
          onExpand?.(!isExpanded);
        }}
      >
        { header }

        { isExpandable && (
          <div className={styles.arrow}>
            <ArrowDown className={styles.arrow} />
          </div>
        ) }
      </div>
      { isExpanded && (
        <div className={classNames(
          styles.body,
          { [styles.bodyExpanded]: isExpanded },
        )}
        >
          { children }
        </div>
      ) }
    </div>
  );
});

export default CollapsiblePanel;
