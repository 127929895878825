// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './BillingData.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';
import { BillingDetailsForm } from 'features/+adminUG/components/BillingDetailsForm';

// UTILS
import { useTranslate } from 'utils/translator';
import * as api from 'api';
import REGEXES from 'utils/configuration/const/regexes';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectUserFirstName,
  selectUserLastName,
  selectPlanSubscriptionCanceled,
  selectUser,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA

const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
};

// COMPONENT: BillingData
const BillingData = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
    goBack = () => {},
    confirm = () => {},
    extras = {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const subscriptionCanceled = useSelector(selectPlanSubscriptionCanceled);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const currentUser = useSelector(selectUser);

  const [ billingDetails, setBillingDetails ] = useState(extras.state?.answers?.[1] ?? {
    firstName,
    lastName,
    email: currentUser?.mail,
    orgName: currentUser?.company,
  });
  const [ billingDataRequested, setBillingDataRequested ] = useState();
  useEffect(() => {
    if (Object.keys(billingDetails).length > 0) {
      return;
    }

    // if user has already saved billing contact data, all respective field should be prefilled
    // f.e. by reclaiming a subscription
    if ((!answer || Object.keys(answer).length === 0)
    && subscriptionCanceled && !billingDataRequested) {
      setBillingDataRequested(true);

      api.get('/core/company/billing/contact')
      .then(({ ok, status, data = {} }) => {
        if (ok && status === 200) {
          const thisBillingDetails = {
            email: data.billingEmail,
            firstName: data.billingContact.split(' ')?.[0],
            lastName: data.billingContact.split(' ')?.[1],
            orgName: data.billingEntity,
            phoneNumber: data.phone,
            city: data.address?.city,
            country: data.address?.country,
            street: data.address?.line1,
            zip: data.address?.postalCode,
            vatType: data.taxId?.type,
            vatId: data.taxId?.value,
          };

          const isValid = thisBillingDetails.firstName
            && thisBillingDetails.lastName
            && thisBillingDetails.email
            && thisBillingDetails.orgName
            && thisBillingDetails.street
            && thisBillingDetails.houseNumber
            && thisBillingDetails.city
            && thisBillingDetails.zip
            && thisBillingDetails.country
            && thisBillingDetails.firstName.length >= Config.minLengthFirstName
            && thisBillingDetails.lastName.length >= Config.minLengthLastName
            && REGEXES.EMAIL.test(thisBillingDetails.email)
            && (!thisBillingDetails.phoneNumber || (thisBillingDetails.phoneNumber && (thisBillingDetails.phoneNumber.startsWith('+') || thisBillingDetails.phoneNumber.startsWith('00'))));

          setBillingDetails({
            ...thisBillingDetails,
            isValid,
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
    }

    if (answer && Object.keys(answer).length) {
      setBillingDetails(answer);
    }
  }, [ answer, subscriptionCanceled, billingDataRequested, billingDetails ]);

  const handleConfirm = () => {
    confirm(billingDetails);
  };

  // RENDER: BillingData
  return (
    <div className={classNames(styles.billingData)}>
      <div className='bluContent466'>
        <div className='bluTypeS'>
          { translate('upgrade_to_pp_billing_title') }
        </div>

        <BillingDetailsForm
          billingDetails={billingDetails}
          onChange={(thisBillingDetails) => {
            setBillingDetails(thisBillingDetails);
            onAnswer(thisBillingDetails);
          }}
        />

        { /* CONTROLS */ }
        <div className={styles.controls}>
          <Button size='S' looks='secondary' onClick={goBack}>{ translate('back_lbl') }</Button>
          <Button
            size='S'
            disabled={!billingDetails.isValid}
            onClick={handleConfirm}
          >
            { translate('confirm_lbl') }
          </Button>
        </div>

      </div>

    </div>
  );
};

export default BillingData;
