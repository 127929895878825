// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './QuestionMultipleChoice.module.scss';

// ASSETS
import { ReactComponent as CursorUp } from 'assets/icons/icn_cursor_up.svg';
import { ReactComponent as CursorDown } from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import SingleChoiceBlock from './blocks/SingleChoiceBlock';
import MultipleChoiceBlock from './blocks/MultipleChoiceBlock';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
};


// COMPONENT: QuestionMultipleChoice
const QuestionMultipleChoice = (props) => {
  // PROPS
  const {
    question,
    selectedValue,
    clickBlock,
    localBlock,
    setLocalBlock = () => {},
    onAnswer = () => {},
    onHelp = () => {},
    onAnimation = () => {},
  } = props;

  const {
    question: title,
    maxAnswers,
  } = question;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ animate, setAnimate ] = useState(false);

  const renderHint = (hintTranslationKey) => {
    const hint = [];
    const translation = translate(hintTranslationKey);

    if (!translation) {
      return '';
    }

    translation
    .split('{{key arrow up / key arrow down}}')
    .forEach((s, index) => {
      if (index === 0) {
        hint.push(
          <div className={styles.line2} key={index}>
            <span>{ s }</span>
            <CursorUp />
            <CursorDown />
          </div>,
        );
      } else {
        hint.push(<span key={index}>{ s }</span>);
      }
    });

    return hint;
  };

  const handleAnswer = (value) => {
    if (clickBlock || localBlock?.current) {
      return;
    }

    setLocalBlock();

    setTimeout(() => {
      setAnimate(true);
      onAnimation();
      setTimeout(() => {
        onAnswer(value, CONFIG.answerDelay);
      }, Number(styles.animationDurationMs));
    }, CONFIG.answerDelay);
  };

  if (!question) {
    return null;
  }

  // RENDER: QuestionMultipleChoice
  return (
    <div className={classNames(styles.questionMultipleChoice, { [styles.animate]: animate })}>
      { title && (
        <div className={styles.gridContentBig}>
          { /* QUESTION LABEL */ }
          { question.questionLabel && (
            <div className={styles.questionLabel}>
              { question.questionLabel }
            </div>
          ) }
          { /* QUESTION TITLE */ }
          <div className={styles.questionTitle}>
            { title }
          </div>
          { /* QUESTION DESCRIPTION */ }
          { question.description && (
            <div className={styles.questionDescription}>
              { translate(question.description) }
            </div>
          ) }
        </div>
      ) }

      <div className={styles.gridContentSmall}>
        { /* maxAnswers = 1 should be treated as a scale question type, with a radio button component */ }
        { maxAnswers === 1 && (
          <SingleChoiceBlock
            question={question}
            selectedValue={selectedValue}
            hint={renderHint('assessment_bipolar_slider__hint')}
            clickBlock={clickBlock}
            onAnimation={onAnimation}
            onHelp={onHelp}
            onAnswer={handleAnswer}
          />
        ) }

        { maxAnswers !== 1 && (
          <MultipleChoiceBlock
            question={question}
            selectedValue={selectedValue}
            hint={renderHint('assessment_bipolar_slider__hint', false)}
            onHelp={onHelp}
            onAnswer={handleAnswer}
          />
        ) }
      </div>

    </div>
  );
};

export default QuestionMultipleChoice;
