// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './AssessmentRegistration.module.scss';

import { useHistory } from 'react-router';

import * as api from 'api';
import { eventBus } from 'architecture/eventBus';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { LANGUAGES } from 'utils/configuration/const/languages';

// OTHER COMPONENTS
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';
import {
  Button, Checkbox, DropDown, InputNext, InputNumber, Modal,
} from 'ui/basic';


import { ASSESSMENT_NEXT_TYPES } from '../../AssessmentNext.config';
import { getTranslationIds } from '../../AssessmentNext.translations';

// STORE

// CONFIG & DATA

const LEGAL_TEXT_IDS = {
  DATA_PROTECTION: 'dataprotection',
  DATA_PROCESSING: 'dataprocessing',
  COPYRIGHT_AGREEMENT: 'copyrightagreement',
};


// COMPONENT: AssessmentRegistration
const AssessmentRegistration = (props) => {
  // PROPS
  const {
    assessmentType = ASSESSMENT_TYPES.RMP,
    skipRegistration,
    registrationPendingExternal,
    languageDefault,
    languageOptions = [],
    genderOptions = [],
    countryOptions = [],
    extras, // setLoadingDuring, loadingDuring, addPages properties
    onClickNext,
  } = props;

  const translate = useTranslate();
  const history = useHistory();

  const { setLoadingDuring, loadingDuring, addPages } = extras;

  const assessmentNextName = ASSESSMENT_NEXT_TYPES[assessmentType];
  const translationIds = getTranslationIds(assessmentNextName);

  const showDataProcessingLink = [ ASSESSMENT_TYPES.RMP, ASSESSMENT_TYPES.NINE_LEVELS ].includes(assessmentType);
  const showDataProtectionLink = assessmentType === ASSESSMENT_TYPES.RMP;
  const showCopyrightAgreementLink = assessmentType === ASSESSMENT_TYPES.RMP;

  // COMPONENT/UI STATE and REFS
  const [ language, setLanguage ] = useState(languageDefault === LANGUAGES.EN
    ? languageOptions.find((languageOption) => languageOption.value === 'EN')
    : languageOptions.find((languageOption) => languageOption.value === 'DE'));

  const [ gender, setGender ] = useState('');

  const [ age, setAge ] = useState('');
  const [ ageIsValid, setAgeIsValid ] = useState('');

  const [ country, setCountry ] = useState('');
  const [ branch, setBranch ] = useState('');
  const [ myFunction, setMyFunction ] = useState('');
  const [ careerLevel, setCareerLevel ] = useState('');
  const [ companySize, setCompanySize ] = useState();
  const [ companySizeIsValid, setCompanySizeIsValid ] = useState();
  const [ inCompanySince, setInCompanySince ] = useState();
  const [ inCompanySinceIsValid, setInCompanySinceIsValid ] = useState();
  const [ dataProtectionChecked, setDataProtectionChecked ] = useState(false);
  const [ personalDataChecked, setPersonalDataChecked ] = useState(false);
  const [ copyrightChecked, setCopyrightChecked ] = useState(false);

  const [ modalVisible, setModalVisible ] = useState(false);
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();

  const [ registrationPendingInternal, setRegistrationPendingInternal ] = useState(false);

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  const TERMS_OF_PRIVACY = translate('rmp_ass_form_cm_label_dataprotection');
  const PERSONAL_DATA_PROCESSING = translate('rmp_ass_form_cm_label_dataprocessing');
  const COPYRIGHT_NOTICE = translate('rmp_ass_form_cm_label_copyrightagreement');

  const getParsedText = (text, id) => {
    const joinedText = [];
    const part1 = text.split(/{{link./);
    if (part1.length > 1) {
      joinedText.push(part1[0]);
      joinedText.push(
        <span
          key={id}
          className={styles.policy}
          onClick={() => {
            setModalHeader(translate(`${assessmentType}_ass_form_info_title_${id}`));
            setModalContent(translate(`${assessmentType}_ass_form_info_description_${id}`));
            setModalVisible(true);
          }}
        >
          { part1[1].split('}}')[0] }
        </span>,
      );
      joinedText.push(part1[1].split('}}')[1]);
    } else {
      joinedText.push(text);
    }
    return joinedText;
  };
  const handleError = (errorMessage) => {
    eventBus.dispatch('assessmentNext.error', {
      message: errorMessage || translate('error_something_went_wrong'),
    });
  };

  const handleRegister = useCallback(() => {
    setLoadingDuring(true);

    const payload = {
      gender: gender.value,
      position: myFunction || undefined,
      region: country.value,
      language: language.value,
      career: careerLevel || undefined,
      industry: branch || undefined,
      company_size: companySize ? Number(companySize) : undefined,
      company_years: inCompanySince ? Number(inCompanySince) : undefined,
    };

    if (assessmentType === ASSESSMENT_TYPES.RMP) {
      Object.assign(payload, { age: Number(age) });
    }

    // REGISTRATION
    api.post(`/core/assessments/${assessmentType}/register`, payload)
    .then(({ ok, status, data = {} }) => {
      if (ok && status === 200) {
        // FETCHING QUESTIONS
        api.get('/core/assessments/rmp/1/questions')
        .then(({ status, ok, data }) => {
          setLoadingDuring(false);
          const { questions } = data;

          if (ok && status === 200) {
            // if questions array is empty that means registration is still being processed
            if (!questions || questions.length === 0) {
              setRegistrationPendingInternal(true);
              return;
            }

            addPages({
              pages: questions.map((question) => ({ ...question, hideLabelLight: true })),
              insertAtIndex: 3,
              replace: true,
            });

            onClickNext();
          } else {
            handleError(data.error?.errorMessage);
          }
        })
        .catch((error) => {
          handleError(error.message);
        });
      } else {
        handleError(data.error?.errorMessage);
      }
    })
    .catch((error) => {
      handleError(error.message);
    });
  }, [ assessmentType, age, branch, careerLevel, companySize, myFunction,
    country, gender, inCompanySince, language,
    onClickNext, addPages, setLoadingDuring,
  ]);

  const [ handleNext, setHandleNext ] = useState();
  useEffect(() => {
    if (!gender.value || !country.value || !language.value
      || (!age || (age && !ageIsValid))
      || (companySize && !companySizeIsValid)
      || (inCompanySince && !inCompanySinceIsValid)
      || (showDataProtectionLink && !dataProtectionChecked)
      || (showDataProcessingLink && !personalDataChecked)
      || (showCopyrightAgreementLink && !copyrightChecked) || loadingDuring
    ) {
      setHandleNext(undefined);
      return;
    }

    setHandleNext(() => handleRegister);
  }, [ age, ageIsValid, country, gender, language,
    companySize, companySizeIsValid,
    inCompanySince, inCompanySinceIsValid,
    dataProtectionChecked, copyrightChecked, personalDataChecked,
    showDataProcessingLink, showDataProtectionLink, showCopyrightAgreementLink,
    loadingDuring, onClickNext, handleRegister,
  ]);

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // if user is already registered for RMP, user should be redirected to the next page
  if (skipRegistration) {
    onClickNext();
    return null;
  }

  // RENDER: pending page
  if (registrationPendingExternal || registrationPendingInternal) {
    return (
      <div className={styles.registrationPending}>
        <span className={styles.clockEmoji}>⏱</span>
        <div className={styles.sTitle}>
          { translate(`${assessmentType}_ass_pending_title`) }
        </div>
        <div className={styles.description}>
          { markdown(translate(`${assessmentType}_ass_pending_description`)) }
        </div>
        <Button
          size='M'
          looks='secondary'
          onClick={() => history.push('/my-profile')}
        >
          { translate(`${assessmentType}_ass_pending_btn`) }
        </Button>
      </div>
    );
  }

  // RENDER: AssessmentRegistration
  return (
    <AssessmentPageWithFooter
      copyrightShort={translate(`${assessmentType}_ass_copyrightlink`, [ '{{year}}', new Date().getFullYear() ])}
      copyrightDescriptionHeader={translate(`${assessmentType}_ass_info_title_copyright`)}
      copyrightDescriptionContent={translate(`${assessmentType}_ass_info_description_copyright`)}
      nextButtonText={translate(translationIds.introContinue)}
      onClickNext={handleNext}
    >
      <div className={classNames(styles.assessmentRegistration)}>
        <div className={styles.xxsTitle}>
          { translate('rmp_ass_form_title_info') }
        </div>

        { /* ASSESSMENT LANGUAGE */ }
        <div className={styles.assessmentLanguage}>
          <div className={styles.label}>
            { translate('rmp_ass_form_label_choose_lang') }
          </div>
          <div className={styles.languageSelect}>
            <DropDown
              placeholder={translate('rmp_ass_form_language_label')}
              options={languageOptions}
              optionLabel='name'
              optionValue='value'
              selectedOption={language}
              onChange={(option) => setLanguage(option)}
            />
          </div>
        </div>

        { /* OTHER INPUTS */ }
        <div className={styles.staticInfo}>
          <div className={styles.label}>
            { translate('rmp_ass_form_subtitle_statistical_info') }
          </div>
          <div className={styles.description}>
            { translate('rmp_ass_form_description_statistical_info') }
          </div>

          <div className={styles.columns}>
            <div className={styles.left}>
              <div className={styles.select}>
                <DropDown
                  placeholder={translate('rmp_ass_form_gender_placeholder')}
                  options={genderOptions}
                  selectedOption={gender}
                  optionLabel='name'
                  optionValue='value'
                  onChange={(option) => setGender(option)}
                />
              </div>
              <div className={styles.input}>
                <InputNumber
                  label={translate('assessment_registration_age_placeholder')}
                  value={age}
                  errorMessage={translate('ass_registration_invalid_age_msg')}
                  validate={{
                    // accept the age value from 12 to 99,
                    pattern: /^(1[2-9]|[2-9][0-9])$/,
                  }}
                  onChange={(value, isValid) => {
                    setAge(value);
                    setAgeIsValid(isValid);
                  }}
                />
              </div>
              <div className={styles.select}>
                <DropDown
                  placeholder={translate('rmp_ass_form_country_placeholder')}
                  options={countryOptions}
                  selectedOption={country}
                  optionLabel='name'
                  optionValue='value'
                  onChange={(option) => setCountry(option)}
                />
              </div>
              <div className={styles.input}>
                <InputNext
                  label={translate('rmp_ass_form_branches_placeholder')}
                  value={branch}
                  onChange={(value) => setBranch(value)}
                />
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.input}>
                <InputNext
                  label={translate('rmp_ass_form_function_placeholder')}
                  value={myFunction}
                  onChange={(value) => setMyFunction(value)}
                />
              </div>
              <div className={styles.input}>
                <InputNext
                  label={translate('rmp_ass_form_careerlevel_placeholder')}
                  value={careerLevel}
                  onChange={(value) => setCareerLevel(value)}
                />
              </div>
              <div className={styles.input}>
                <InputNumber
                  label={translate('rmp_ass_form_companysize_placeholder')}
                  value={companySize}
                  onChange={(value, valueIsValid) => {
                    setCompanySize(value);
                    setCompanySizeIsValid(valueIsValid);
                  }}
                />
              </div>
              <div className={styles.input}>
                <InputNumber
                  label={translate('rmp_ass_form_companyyears_placeholder')}
                  value={inCompanySince}
                  onChange={(value, valueIsValid) => {
                    setInCompanySince(value);
                    setInCompanySinceIsValid(valueIsValid);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.mandatory}>
            { translate('rmp_ass_form_hint_required_info') }
          </div>
          <div className={styles.checkboxes}>
            { showDataProtectionLink
            && (
              <div className={styles.checkbox}>
                <Checkbox
                  name={getParsedText(TERMS_OF_PRIVACY, LEGAL_TEXT_IDS.DATA_PROTECTION)}
                  onChange={(value) => setDataProtectionChecked(value)}
                />
              </div>
            ) }

            { showDataProcessingLink
            && (
              <div className={styles.checkbox}>
                <Checkbox
                  name={getParsedText(PERSONAL_DATA_PROCESSING, LEGAL_TEXT_IDS.DATA_PROCESSING)}
                  onChange={(value) => setPersonalDataChecked(value)}
                />
              </div>
            ) }

            { showCopyrightAgreementLink
            && (
              <div className={styles.checkbox}>
                <Checkbox
                  name={getParsedText(COPYRIGHT_NOTICE, LEGAL_TEXT_IDS.COPYRIGHT_AGREEMENT)}
                  onChange={(value) => setCopyrightChecked(value)}
                />
              </div>
            ) }
          </div>
        </div>

        { modalVisible
        && (
          <Modal
            header={modalHeader}
            secondaryButtonTitle={translate('okay_lbl')}
            onClose={() => setModalVisible(false)}
          >
            { modalContent }
          </Modal>
        ) }

      </div>
    </AssessmentPageWithFooter>
  );
};

export default AssessmentRegistration;
