// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ResultSummary.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { PeerBreakdown } from 'ui/molecules/CustomReport/Blocks';


// COMPONENT: ResultSummary
const ResultSummary = (props) => {
  // SPECIAL HOOKS
  const translate = useTranslate();


  // RENDER: ResultSummary
  return (
    <div className={classNames(styles.resultSummary)}>
      <div className={classNames('bluTypeS', styles.blue)}>
        { translate('peer_360_report_result_summary') }
      </div>
      <div className={classNames('bluTypeCopy', 'marginTopM')}>
        { translate('peer_360_report_result_summary_copy') }
      </div>
      <PeerBreakdown
        configuration={{ mode: 'top-level' }}
        {...props}
      />
    </div>
  );
};

export default ResultSummary;
