// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StringResults.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: StringResults
const StringResults = (props) => {
  // PROPS
  const {
    assessmentResults,
    assessmentResultsAll,
    configuration = {},
    content = {},
  } = props;

  const {
    showDimensionName = true,
    showDimensionDescription,
    breakdownPeerGroups,
  } = configuration;
  const {
    dimension: dimensionId,
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const isBreakdownPeerGroups = (dim) => breakdownPeerGroups && dim.peerResults?.length;

  const results = dimensionId
    // assessmentResultsAll containing all visible and hidden dimensions
    // https://blueexcellence.atlassian.net/browse/BDE-33
    ? assessmentResultsAll
    : assessmentResults;

  // RENDER: resultItem
  const renderResultItem = (result = [], id = '', name = null) => (
    <div key={id} className={styles.peerGroupName}>
      { translate(name) }
      <ul>
        { Array.isArray(result)
          ? result.map?.((resultItem, index) => (
            <li key={index} className='marginTopXxs'>
              { resultItem }
            </li>
          )) : (
            <li className='marginTopXxs'>
              { result }
            </li>
          ) }
      </ul>
    </div>
  );

  // RENDER: StringResults
  return (
    <div className={classNames(styles.stringResults)}>
      { results
      .filter(({ id }) => (dimensionId ? id === dimensionId : true))
      .map((dimension) => (
        <div
          id={dimension.id}
          key={dimension.id}
          className={styles.dimension}
        >
          { showDimensionName && (
            <span className={classNames('bluTypeXs')}>
              { dimension.name || dimension.id }
            </span>
          ) }

          { showDimensionDescription && (
            <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
              { translate(dimension.description) || '-' }
            </div>
          ) }

          <div className='marginTopXs'>
            { !isBreakdownPeerGroups(dimension) ? (
              renderResultItem(dimension.result, '', 'peer_360_report_additional_input_comments')
            ) : (
              <>
                <span className='bluTypeXxs'>
                  { translate('peer_360_report_additional_input_comments') }
                </span>
                { dimension.peerResults.map(({ id, name = 'self_lbl', result }) => renderResultItem(result, id, name)) }
              </>
            ) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default StringResults;
