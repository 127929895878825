// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import styles from './QuestionBubblesHorizontal.module.scss';

// ASSETS
import { ReactComponent as CursorUp } from 'assets/icons/icn_cursor_up.svg';
import { ReactComponent as CursorDown } from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import {
  breakpoints, useBreakpoint, useDebounce, useWindowWidth,
} from 'utils/hooks';
import { isValid } from 'utils/numbers';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// STORE

// CONFIG & DATA
const Config = {
  answerDelay: 200,

  layout: {
    radioButtonSizeLarge: 40,
    radioButtonSizeSmall: 24,
    spacingSmall: 16,
    spacingLarge: 48,
  },
};

// COMPONENT: QuestionBubblesHorizontal
const QuestionBubblesHorizontal = (props) => {
  // PROPS
  const {
    question = {},
    range = [],
    selectedValue,
    clickBlock,
    localBlock,
    setLocalBlock = () => {},
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {},
    allowAnswerSkip = false,
  } = props;

  // COMPONENT/UI STATE and REFS
  const translate = useTranslate();
  const bp = useBreakpoint();
  const ref = useRef();
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);

  const [ hoveredBubbleLabel, setHoveredBubbleLabel ] = useState('');

  // useAnswerOptions
  const [ rangeInternal, setRangeInternal ] = useState([]);
  useEffect(() => {
    let answerOptionsInternal;
    if (question.useAnswerOptions && question.answerOptions?.length) {
      answerOptionsInternal = question.answerOptions.map((answerOption) => ({
        ...answerOption,
        display: translate(answerOption.label || answerOption.translationKey || answerOption.translationFallback),
      }));
    } else {
      answerOptionsInternal = range.map((r) => ({
        ...r,
        display: translate(r.label || r.translationKey || r.translationFallback),
      }));
    }

    setRangeInternal(answerOptionsInternal);
  }, [
    translate,
    question,
    range,
  ]);

  const [ gap, setGap ] = useState(0);
  const [ shrinkRadioButton, setShrinkRadioButton ] = useState(false);
  useEffect(() => {
    if (!rangeInternal) {
      return;
    }

    setShrinkRadioButton(false);

    const clientWidth = ref.current?.clientWidth;
    let gapInternal = (clientWidth - (rangeInternal.length * Config.layout.radioButtonSizeLarge))
      / (rangeInternal.length - 1);

    if (bp.isXs) {
      const isEnoughSpace = gapInternal >= Config.layout.spacingSmall;

      if (isEnoughSpace) {
        gapInternal = Config.layout.spacingSmall;
      } else {
        setShrinkRadioButton(true);

        const gapInternalNew = (clientWidth - (rangeInternal.length * Config.layout.radioButtonSizeSmall))
          / (rangeInternal.length - 1);
        const isEnoughSpaceNew = gapInternalNew >= Config.layout.spacingSmall;

        gapInternal = isEnoughSpaceNew ? Config.layout.spacingSmall : gapInternalNew;
      }

      setGap(gapInternal);
      return;
    }

    setGap(gapInternal > Config.layout.spacingLarge
      ? Config.layout.spacingLarge
      : gapInternal);
  }, [ bp, debouncedWindowWidth, rangeInternal ]);

  // selectedValue
  const [ selectedOption, setSelectedOption ] = useState();
  useEffect(() => {
    if (!rangeInternal?.length) {
      return;
    }

    const thisSelectionOption = rangeInternal.find((option) => option.value === selectedValue);
    setSelectedOption(thisSelectionOption);
  }, [ selectedValue, rangeInternal ]);

  // answered & animate
  const [ answered, setAnswered ] = useState(false);
  const [ animate, setAnimate ] = useState(false);

  // METHODS
  const handleAnswer = useCallback((index) => {
    if (clickBlock || localBlock?.current || answered) return;
    const selectedOptionInternal = rangeInternal[index];
    if (!selectedOptionInternal) return;
    const { value } = selectedOptionInternal;
    if (Number.isNaN(Number(value))) return;

    setLocalBlock();
    setSelectedOption(selectedOptionInternal);
    setAnswered(true);
    setTimeout(() => {
      setAnimate(true);
      onAnimation();
      setTimeout(() => {
        onAnswer(value, Config.answerDelay);
      }, Number(styles.animationDurationMs));
    }, Config.answerDelay);
  }, [
    clickBlock,
    answered,
    rangeInternal,
    localBlock,
    setLocalBlock,
    onAnimation,
    onAnswer,
  ]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    const selectedOptionInternal = Number(event.key);
    if (selectedOptionInternal <= rangeInternal.length) {
      event.preventDefault();

      // since the 10th option is linked to key 0 we need to set the index to 9 separately
      const indexInternal = selectedOptionInternal === 0 ? 9 : selectedOptionInternal - 1;
      handleAnswer(indexInternal);
    }
  }, [ handleAnswer, rangeInternal ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  const renderHint = () => {
    const hint = [];

    translate('assessment_selection_items__hint_line2')
    .split('{{key arrow up / key arrow down}}')
    .forEach((s, index) => {
      if (index === 0) {
        hint.push(
          <div className={styles.line} key={`H${index}`}>
            <span>{ s }</span>
            <CursorUp />
            <CursorDown />
          </div>,
        );
      } else {
        hint.push(<span key={`H${index}`}>{ s }</span>);
      }
    });

    return hint;
  };

  // RENDER: HorizontalQuestionBubbles
  return (
    <div
      ref={ref}
      className={classNames(styles.questionBubblesHorizontal, {
        [styles.animate]: animate,
      })}
      data-test='HorizontalQuestionBubbles'
      data-id={question.id}
    >

      { /* QUESTION LABEL */ }
      { question.questionLabel && (
        <div className={styles.questionLabel}>
          { question.questionLabel }
        </div>
      ) }

      { /* QUESTION TITLE */ }
      <div
        data-test='QuestionBubblesTitle'
        className={classNames(styles.questionTitle, {
          [styles.isBlue]: question.questionLabel || question.questionDescription,
        })}
      >
        { isValid(question.number) ? `${question.number} - ${question.question}` : question.question }
      </div>

      { /* QUESTION DESCRIPTION */ }
      { question.questionDescription && (
        <div className={styles.questionDescription}>
          { translate(question.questionDescription) }
        </div>
      ) }

      <div className={styles.optionsParent}>
        <div className={styles.optionsContainer}>
          <div className={styles.hints}>
            <span>{ rangeInternal[0]?.display }</span>
            <span>{ rangeInternal[rangeInternal.length - 1]?.display }</span>
          </div>

          <div
            className={styles.options}
            style={{ gap }}
          >
            { rangeInternal.map((option, index) => (
              <div
                key={option.value}
                className={classNames(styles.option, {
                  [styles.selected]: option.value === selectedOption?.value,
                  [styles.shrink]: shrinkRadioButton,
                })}
                onClick={() => {
                  handleAnswer(index);
                }}
                role='presentation'
                data-test='Option'
                onMouseEnter={() => {
                  setHoveredBubbleLabel(option.display);
                }}
                onMouseLeave={() => setHoveredBubbleLabel('')}
              >
                <div className={styles.checkbox} />
              </div>
            )) }
          </div>

          <div className={classNames(styles.dynamicHint, {
            [styles.visible]: Boolean(hoveredBubbleLabel || selectedOption),
          })}
          >
            { hoveredBubbleLabel || selectedOption?.display }
          </div>
        </div>
      </div>

      { /* SKIP */ }
      { (allowAnswerSkip || question.skipAnswer) && (
        <div
          role='button'
          tabIndex={0}
          className={styles.skip}
          onClick={() => onAnswer(null)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onAnswer(null);
            }
          }}
        >
          { question.skip_option_label || translate('peer_360_campari_ass_skip_label') }
        </div>
      ) }

      { /* HELP WITH SELECTION BUTTON */ }
      { !question.hideHelp && (
        <div className={styles.helpButton}>
          <Button
            size='S'
            looks='tertiary'
            onClick={onHelp}
          >
            { translate('assessment_help_button') }
          </Button>
        </div>
      ) }

      { /* HINT */ }
      { bp.bpWidth > breakpoints.M.bpWidth && (
        <div className={styles.keyBoardNavHint}>
          <div className={styles.line}>
            { translate('assessment_selection_items__hint_line1', [
              '{{endNumber}}', rangeInternal.length <= 10 ? rangeInternal.length % 10 : '',
            ]) }
          </div>

          <div className={styles.line}>{ renderHint() }</div>
        </div>
      ) }

    </div>
  );
};

export default QuestionBubblesHorizontal;
