import { useContext } from 'react';

// STORE
import store from 'store';
import { getShowTranslationKeys } from 'store/selectors/debug';

// UTILS
import { TranslateContext } from './translator.context';
import { markdown } from 'utils/textTools';
import { ALLOW_TRANSLATION_DISABLE, COMPANY_ID } from 'utils/configuration';


// company-specific translations
// TODO: remove after content has been set on CMS
const COMPANY_MAP = {
  peer_360_manage_description_default: {
    campari: 'peer_360_manage_description_campari',
    'campari-test': 'peer_360_manage_description_campari',
  },
  peer_360_asmnt_peer_intro_copy: {
    campari: 'peer_360_asmnt_peer_intro_copy_campari',
    'campari-test': 'peer_360_asmnt_peer_intro_copy_campari',
  },
  peer_360_ass_guidance_copy: {
    campari: 'peer_360_ass_guidance_copy_campari',
    'campari-test': 'peer_360_ass_guidance_copy_campari',
  },
};

/**
 * hook to provide translate function (context set up in main)
 * @returns translate function (id, replacements) with current translations from store
 */
export const useTranslate = () => {
  const { translate } = useContext(TranslateContext);

  return translate;
};

// replacement of variables
export const replace = (translation = '', replacements = []) => {
  if (replacements.length % 2) {
    throw new Error('translator: odd variable replacement list length');
  }

  let res = translation;
  for (let i = 0; i < replacements.length; i += 2) {
    res = res.replaceAll(replacements[i], replacements[i + 1]);
  }

  return res;
};

const getTranslation = ({
  translations,
  showTranslationKeys,
  id,
  replacements,
}) => {
  if (!translations) return '';

  let translation;

  // check for company-specific translations
  const key = COMPANY_MAP?.[id]?.[COMPANY_ID] ?? id;

  // translation from copybook strings
  translation = ALLOW_TRANSLATION_DISABLE && showTranslationKeys
    ? key
    : translations[key] || key || '';

  translation = replace(translation, replacements);

  // transform to markdown if it contains [blu-markdown]
  return markdown(translation);
};

export const getTranslate = (translations, showTranslationKeys) => (id, replacements) => getTranslation({
  translations,
  showTranslationKeys,
  id,
  replacements,
});

/**
 * direct use deprecated: depends on other rerenders when store changes happen
 * @param {*} id
 * @param {*} replacements
 * @returns
 */
export const translate = (id, replacements = []) => {
  const state = store.getState();
  const translations = state && state.localisation && state.localisation.translations;
  const showTranslationKeys = getShowTranslationKeys(state);

  return getTranslation({
    translations,
    showTranslationKeys,
    id,
    replacements,
  });
};
