export const randomizeArray = (array:Array<*>) => {
  if(!array.length){
    return array;
  }

  //Fisher-Yates shuffle
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array
};
