import React from 'react';
import styles from './LogoutSuccessForm.module.scss';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';
import * as fromSettingsSelectors from 'store/selectors/settings';

import { useTranslate } from 'utils/translator';
import { markdown } from 'utils/textTools';
import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';

import { Button, PoweredBy } from 'ui/basic';

const LogoutSuccessForm = () => {
  const translate = useTranslate();
  const history = useHistory();

  const isBalancedYou = useSelector(fromSettingsSelectors.getCompanyIsBalancedYou);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  return (
    <div className={styles.logoutSuccessForm}>
      <div className={classNames(styles.container, { [styles.balancedYou]: isBalancedYou })}>
        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }

        <span className={styles.text}>
          { markdown(translate('logout_title')) }
        </span>
        <Button
          looks='secondary'
          onClick={() => history.push('/')}
        >
          { translate(CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB
            ? 'back_to_coachhub'
            : 'logout_button_login') }
        </Button>
      </div>
    </div>
  );
};

export default LogoutSuccessForm;
