// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
// import styles from './BalancedYouAssessmentPage.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { AssessmentNext } from 'ui/molecules/AssessmentNext';

// UTILS
// import { useTranslate } from 'utils/translator';
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import {
  initialiseAssessment,
  handleAssessmentAnswer,
} from 'utils/assessment';

// STORE OLD
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { getAssessmentHistory } from 'store/actions';

// CONFIG & DATA
// const Config = {};

// COMPONENT: BalancedYouAssessmentPage
const BalancedYouAssessmentPage = () => {
  // PROPS
  // const { children } = props;
  const dispatch = useDispatch();

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();

  // STORE
  const userId = useSelector(fromCurrentUserSelectors.getCurrentUserId);

  // GET ASSESSMENT DATA
  const assessmentId = ASSESSMENT_TYPES.BALANCED_YOU;
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();

  // initialise asssessment
  useEffect(() => {
    if (!userId || !assessmentId || loadingAssessment) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.push('/');
      },
    })
    .then(({ questions, assessment, prevAnswers }) => {
      setConfigOverride({
        title: assessment.title,
        description: assessment.description,
        // description: assessment.info_texts.long_description,

        allowBackNavigation: 1,
        allowBackNavigationOnlyOncePerQuestion: true,
        // canContinueLater: true,
        // modalHurryDelay: 5 * 1000,
        canContinueLater: assessment.multi_session,
        // modalHurryDelay: assessment.time_to_reminder * 1000,

        // // modalHelpContent: '[blu-markdown] **modal help content**',
        // // guidanceContent: '[blu-markdown] **guidance content**',
        // modalHelpContent: assessment.info_texts.question_help,
        // guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        prevAnswers,

      });

      setAssessmentQuestions(questions);
    });
  }, [
    translate,
    loadingAssessment,
    history,
    userId,
    assessmentId,
    assessmentQuestions,
  ]);

  // HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId);
  };

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);
    // fetching the history will update the latest results too
    dispatch(getAssessmentHistory(ASSESSMENT_TYPES.BALANCED_YOU));
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: BalancedYouAssessmentPage
  return (
    <AssessmentNext
      type='balancedYou'
      questions={assessmentQuestions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer}
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default BalancedYouAssessmentPage;
