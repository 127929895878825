// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Welcome.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';
import GrowKeyVisual from 'assets/keyvisuals/grow_keyvisual_new.svg';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectInstanceContainsBluquistFeatures,
  selectInstanceIsBalancedYou,
  selectUserOnboardingCompleted,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};


// COMPONENT: Welcome
const Welcome = (props) => {
  // PROPS
  const {
    firstName = '',
    handleOnboardingStart = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const onboardingCompleted = useSelector(selectUserOnboardingCompleted);

  const containsBluquistFeatures = useSelector(selectInstanceContainsBluquistFeatures);
  const isBalancedYou = useSelector(selectInstanceIsBalancedYou);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Welcome
  return onboardingCompleted ? null : (
    <div
      className={styles.welcome}
      style={{ backgroundImage: `url(${GrowKeyVisual})` }}
      data-test='Welcome'
    >
      <div
        className={styles.welcomeAltImage}
        style={{ backgroundImage: `url(${GrowKeyVisual})` }}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          { translate(`${isBalancedYou ? 'mind_journey__' : ''}home_hero_stage_title`, [
            '{{username}}', firstName || '',
          ]) }
        </div>
        <div className={styles.description}>
          { translate(`${isBalancedYou ? 'mind_journey__' : ''}home_hero_stage_description`) }
        </div>

        { containsBluquistFeatures && (
          <Button
            leadingIcon={IconsSvg.Play}
            onClick={handleOnboardingStart}
          >
            { translate('home_hero_stage_btn') }
          </Button>
        ) }
      </div>
    </div>
  );
};

export default Welcome;
