// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PeerBreakdown.module.scss';

// UTILS
import { DimensionResult } from 'ui/molecules/Peer360Result/Peer360Report/components/DimensionResult';

// 3RD PARTY
import classNames from 'classnames';


// COMPONENT: PeerBreakdown
const PeerBreakdown = (props) => {
  // PROPS
  const {
    segmentsCount,
    renderMin,
    assessmentResults,
    assessmentResultsAll,
    configuration = {},
    content = {},
    blockIndex = null,
    forCustomReport = false,
  } = props;

  const {
    mode,
    includeOverallResult = true,
    showPeerClassification = true,
    showDistribution = false,
  } = configuration;
  const {
    dimension: singleDimensionId,
    peerGroups,
  } = content;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const topLevelOnly = mode === 'top-level';
  const singleDimensionOnly = mode === 'single-dimension';

  const results = singleDimensionOnly
    // assessmentResultsAll containing all visible and hidden dimensions
    // https://blueexcellence.atlassian.net/browse/BDE-33
    ? assessmentResultsAll
    : assessmentResults;

  const flatResults = [
    ...results,
    ...results.map(({ subDimensions = [] }) => subDimensions),
  ].flat();

  const assessmentResultsInternal = singleDimensionOnly ? flatResults : results;

  // RENDER: PeerBreakdown
  return (
    <div className={styles.peerBreakdown}>
      { assessmentResultsInternal
      .filter(({ result }) => Number.isFinite(result))
      .filter(({ id }) => !singleDimensionId || singleDimensionId === id)
      .map((dimension) => (
        <div
          id={Number.isFinite(blockIndex) ? `${blockIndex}_${dimension.id}` : dimension.id}
          key={dimension.id}
          className={classNames(styles.dimension, { [styles.forCustomReport]: forCustomReport })}
        >
          { /* overall result */ }
          { includeOverallResult && (
            <div className={styles.diagram}>
              <DimensionResult
                dimension={dimension}
                segmentsCount={segmentsCount}
                renderMin={renderMin}
                showPeerClassification={showPeerClassification}
                showDistribution={showDistribution}
                showPeerResults={topLevelOnly || singleDimensionOnly}
                peerGroups={peerGroups}
              />
            </div>
          ) }

          { /* sub-dimensions results */ }
          { (!topLevelOnly && !singleDimensionOnly) && dimension.subDimensions?.map((subDimension) => (
            <div className={styles.diagram} key={subDimension.id}>
              <DimensionResult
                dimension={subDimension}
                segmentsCount={segmentsCount}
                renderMin={renderMin}
                showPeerClassification={showPeerClassification}
                showDistribution={showDistribution}
                peerGroups={peerGroups}
              />
            </div>
          )) }
        </div>
      )) }

    </div>
  );
};

export default PeerBreakdown;
