import React from 'react';
import styles from './Button.module.scss';
import classnames from 'classnames';


const Button = (props) => {
  const {
    children,
    type = 'button',
    leadingIcon,
    trailingIcon,
    primaryIcon,
    looks = 'primary',
    disabled,
    showProgressIndicator,
    size = 'L',
    onClick = () => {},
  } = props;

  // HELPERS
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;
  const PrimaryIcon = primaryIcon;
  const lookStyles = looks.split(' ').filter(Boolean).map((l) => styles[l]);

  // RENDER: Button
  return (
    <button
      type={type} // eslint-disable-line react/button-has-type
      disabled={disabled}
      onClick={onClick}
      className={classnames(
        styles.button,
        lookStyles,
        { [styles[`size${size.toUpperCase()}`]]: Boolean(size) },
        { [styles.iconOnly]: primaryIcon },
        { [styles.progressIndicator]: showProgressIndicator },
      )}
    >
      <div className={classnames(styles.content, { [styles.hasIcon]: leadingIcon || trailingIcon })}>
        { primaryIcon
          ? <PrimaryIcon />
          : (
            <>
              { leadingIcon && <div className={styles.icon}><LeadingIcon /></div> }
              <div>{ children }</div>
              { trailingIcon && <div className={styles.icon}><TrailingIcon /></div> }
            </>
          ) }
      </div>

      { showProgressIndicator && <div className={styles.progressIndicator} /> }
    </button>
  );
};

export default Button;
