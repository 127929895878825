
// ROUTE CONFIGS
import { routesConfigAdmin } from 'features/+admin/routeNext';
import { routesConfigAdminUG } from 'features/+adminUG/routeNext';
import { routesConfigCore } from 'features/+core/routeNext';
import { routesConfigCoachHub } from 'features/+coachHub/routeNext';
import { routesConfigEmployees } from 'features/+employees/routeNext';
import { routesConfigRoles } from 'features/+roles/routeNext';
import { routesConfigTeams } from 'features/+teams/routeNext';
import { routesConfigCandidates } from 'features/+candidates/routeNext';
import { routesConfigSurveys } from 'features/+surveys/routeNext';
import { routesConfigStatic } from 'features/+static/routeNext';
import { routesConfigFramework } from 'features/framework/routeNext';

// Order is important here as the routes
// are created and put in the dom and main nav based on this sequence
// rearrangements can be made with inMainNav.forceIndex
export const routesConfigs = [

  // ADMIN
  routesConfigAdmin,

  // CORE
  routesConfigCore,

  // COACH HUB
  routesConfigCoachHub,

  // ADMIN UG
  routesConfigAdminUG,

  // EMPLOYES
  routesConfigEmployees,

  // ROLES
  routesConfigRoles,

  // TEAMS
  routesConfigTeams,

  // CANDIDATES
  routesConfigCandidates,

  // SURVEYS
  routesConfigSurveys,

  // STATIC
  routesConfigStatic,

  // FRAMEWORK
  routesConfigFramework,
];

export const getAllRoutes = () => {
  const routes = routesConfigs.flatMap((config) => config.routes);

  return routes;
};
