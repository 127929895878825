// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LabelCheckmark.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: LabelCheckmark
const LabelCheckmark = (props) => {
  // PROPS
  const {
    label, active, showTopBorder, onClick = () => {},
  } = props;

  const CheckMark = IconsSvg.CheckBig;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LabelCheckmark
  return (
    <div
      className={classNames(styles.labelCheckmark, { [styles.topBorder]: showTopBorder }, { [styles.active]: active })}
      onClick={onClick}
    >
      { label }

      { active && <CheckMark /> }
    </div>
  );
};

export default LabelCheckmark;
