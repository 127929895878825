// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Introduction.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};


// COMPONENT: Introduction
const Introduction = (props) => {
  const { assessmentData = {} } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Introduction
  return (
    <div className={classNames(styles.introduction)}>
      <span className='bluTypeM'>
        { translate(
          'peer_360_report_intro_title',
          [ '{{assessment}}', assessmentData.title ],
        ) }
      </span>

      { /* introduction */ }
      <div className='marginTopL'>
        <span className={classNames('bluTypeS', styles.blue)}>{ translate('introduction_header_title') }</span>
        <div className={classNames('marginTopM', 'bluTypeCopy')}>
          { translate(
            'peer_360_report_intro_copy',
            [ '{{assessment}}', assessmentData.title ],
          ) }
        </div>
      </div>

      { /* peers */ }
      <div className='marginTopL'>
        <span className='bluTypeXs'>{ translate('peer_360_report_peer_groups') }</span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('peer_360_report_peer_groups_copy') }
        </div>

        { Object.entries(assessmentData.peerGroups)
        .filter(([ , peerGroup ]) => peerGroup?.label)
        .map(([ key, peerGroup ]) => (
          <div className={styles.block} key={key}>
            <span>{ peerGroup?.label || key }</span>
            <div>{ peerGroup?.description || key }</div>
          </div>
        )) }
      </div>

      { /* classification */ }
      <div className='marginTopL'>
        <span className='bluTypeXs'>{ translate('peer_360_report_strengths_types') }</span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('peer_360_report_strengths_types_copy') }
        </div>

        <div className={styles.block}>
          <span>{ translate('peer_360_classification_title_unrecognizedStrength') }</span>
          <div>{ translate('peer_360_classification_copy_unrecognizedStrength') }</div>
        </div>

        <div className={styles.block}>
          <span>{ translate('peer_360_classification_title_unrecognizedWeakness') }</span>
          <div>{ translate('peer_360_classification_copy_unrecognizedWeakness') }</div>
        </div>

        <div className={styles.block}>
          <span>{ translate('peer_360_classification_title_clearStrength') }</span>
          <div>{ translate('peer_360_classification_copy_clearStrength') }</div>
        </div>

        <div className={styles.block}>
          <span>{ translate('peer_360_classification_title_clearWeakness') }</span>
          <div>{ translate('peer_360_classification_copy_clearWeakness') }</div>
        </div>
      </div>

      <div className='marginTopL'>
        <span className='bluTypeXs'>{ translate('peer_360_report_tips') }</span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('peer_360_report_tips_copy') }
        </div>
      </div>
    </div>
  );
};

export default Introduction;
