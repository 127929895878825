// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LoadingHint.module.scss';

// ASSETS
import { ReactComponent as Loader } from './loader_small.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA

// COMPONENT: LoadingHint
const LoadingHint = (props) => {
  // PROPS
  const { children } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: LoadingHint
  return (
    <div className={classNames(styles.loadingHint)}>
      <div className={styles.loader}>
        <Loader />
      </div>
      <div className={styles.hint}>
        { children }
      </div>
    </div>
  );
};

export default LoadingHint;
