// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useRef, useEffect } from 'react';
import styles from './InputMasked.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import InputMask from 'react-input-mask';

// UTILS
import { capitalise } from 'utils/textTools';


// COMPONENT: InputMasked
const InputMasked = (props) => {
  // PROPS
  const {
    // dom props
    id,
    className,
    name,
    disabled,
    mask,
    placeholder,
    label,
    value,
    type,
    // modifiers
    size = '', // M (default), L, Responsive
    // component props
    hint,
    errorMessage,
    onChange,
    onBlur,
    onFocus,
    onError,
  } = props;

  // COMPONENT/UI STATE and REFS
  const inputRef = useRef(null);
  const [ valueInternal, setValueInternal ] = useState(value || '');
  const [ smallLabel, setSmallLabel ] = useState(!!value || placeholder);
  const [ error, setError ] = useState(null);

  // HELPERS

  // METHODS

  useEffect(() => {
    setValueInternal(value || '');
    setSmallLabel(!!value || placeholder);
  }, [ value, placeholder ]);

  // RENDER: InputMasked
  const domProps = {
    id,
    name,
    placeholder,
  };
  return (
    // CONTAINER & onConfirm
    <div className={classNames(styles.inputContainer, {
      [styles[`size${capitalise(size)}`]]: size,
    })}
    >
      { /* LABEL */ }
      { label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className={classNames(styles.label, {
            [styles.small]: smallLabel,
            [styles.disabled]: disabled,
          })}
        >
          { label }
        </label>
      ) }

      <InputMask
        value={valueInternal}
        disabled={disabled}
        mask={mask}
        // maskPlaceholder={maskPlaceholder}
        maskChar=''
        onChange={(event) => {
          if (error) { // reset error onChange
            setError(null);
            onError?.(null);
          }

          // handle new value
          const { value: targetValue } = event.target;
          setValueInternal(targetValue);
          // fire on change?
          onChange?.(targetValue);
        }}
        onFocus={() => {
          setSmallLabel(true);
          onFocus?.();
        }}
        onBlur={() => {
          setSmallLabel(!!valueInternal || placeholder);
          onBlur?.(valueInternal);
        }}
        onKeyUp={(event) => {
          if (event.key !== 'Enter') event.stopPropagation();
          // preventive measure so form elements in Dialogs / Assessments etc.
          // don't trigger page navigations
        }}
      >
        { (inputProps) => (
          <input
            {...inputProps}
            type={type}
            className={classNames(styles[className], styles.input, {
              [styles.placeholder]: placeholder,
              [styles.small]: smallLabel,
              [styles.error]: error,
            })}
            {...domProps}
            ref={inputRef}
            noValidate
          />
        ) }
      </InputMask>

      { errorMessage && (
        <div className={styles.errorMessage}>
          { errorMessage }
        </div>
      ) }

      { /* HINT */ }
      { (hint && !errorMessage) && (
        <div className={styles.hint}>
          { hint }
        </div>
      ) }

    </div>
  );
};

export default InputMasked;
