import { createSelector } from '@reduxjs/toolkit';
import bqLogo from 'assets/bq_brandmark.svg';
import byLogo from 'assets/by_brandmark.svg';
import { CAPABILITIES_SWITCHES } from 'utils/configuration/const/capabilities';
import { COMPANY_FEATURES_NEXT } from 'utils/configuration/const/settings';

export const selectConfiguration = (state) => state.configuration;

// export const selectConfigurationInitialised = createSelector(
//   selectConfiguration,
//   (configuration) => configuration.initialised,
// );

export const selectConfigurationLoading = createSelector(
  selectConfiguration,
  (configuration) => configuration.loading,
);

// DEPLOYMENT
export const selectDeployment = (state) => {
  const configuration = selectConfiguration(state);
  return configuration.deployment;
};

export const selectIsAdminFrontend = (state) => {
  const deployment = selectDeployment(state);
  return deployment.isAdminFrontend;
};

// INSTANCE & FLAVOR
export const selectInstance = createSelector(
  selectConfiguration,
  (configuration) => configuration.instance,
);
export const selectInstanceFlavor = createSelector(
  selectInstance,
  (instance) => instance.flavor,
);

export const selectInstanceFlavorLoaded = createSelector(
  selectInstance,
  (instance) => instance.flavorLoaded,
);
export const selectIsCoachHub = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'coachHub',
);
export const selectIsBluquist = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'bluquist',
);
export const selectIsBalancedYou = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'balancedYou',
);
// export const selectIsBluquistAndBalancedYou = createSelector(
//   selectInstanceFlavor,
//   (flavor) => flavor === 'bluquistAndBalancedYou',
// );
export const selectInstanceContainsBluquistFeatures = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'bluquist' || flavor === 'bluquistAndBalancedYou',
);
export const selectInstanceContainsBalancedYouFeatures = createSelector(
  selectInstanceFlavor,
  (flavor) => flavor === 'balancedYou' || flavor === 'bluquistAndBalancedYou',
);

// COMPANY
export const selectCompany = (state) => {
  const configuration = selectConfiguration(state);
  return configuration.company;
};

export const selectCompanyHasExternalLegalAgreements = (state) => {
  const company = selectCompany(state);
  return company.hasExternalLegalAgreements;
};

export const selectCompanyCustomLogoUrl = (state) => {
  const company = selectCompany(state);
  return company.customLogoUrl;
};

export const selectAvailableInstances = createSelector(
  selectCompany,
  (company) => company.availableInstances,
);

// FEATURES
const selectFeatures = createSelector(
  selectConfiguration,
  (configuration) => configuration.features,
);
export const selectFeaturesNext = createSelector(
  selectFeatures,
  (storeFeatures) => {
    if (!storeFeatures.length) {
      return undefined;
    }

    const featuresNext = {
      employees: true, // always available
    };
    Object.entries(COMPANY_FEATURES_NEXT).forEach(([ featureId, featureNextId ]) => {
      featuresNext[featureNextId] = storeFeatures.some(({ id, active }) => id === featureId && active);
    });

    return featuresNext;
  },
);

const hasAssessment = (assessmentId, assessments) => assessments.find((assessment) => (
  assessment.id === assessmentId
));

export const selectAssessmentsNext = createSelector(
  selectFeatures,
  (storeFeatures) => {
    if (!storeFeatures.length) return;

    const assessments = {
      balancedYou: false,
      big5: false,
      keyComp: false,
      leadComp: false,
      potential: false,
      workPref: false,
      nineLevels: false,
      rmp: false,
    };

    // add all clear names from api
    const storeAssessments = storeFeatures
    .filter((feature) => feature.category === 'assessment' && feature.active)
    .map((assessment) => {
      assessments[assessment.id] = true;
      return assessment;
    });


    // add simpler names for frontend
    if (hasAssessment('ASSESSMENT_BALANCED-YOU', storeAssessments)) {
      assessments.balancedYou = true;
    }
    if (hasAssessment('ASSESSMENT_BIG5', storeAssessments)) {
      assessments.big5 = true;
    }
    if (hasAssessment('ASSESSMENT_KEY-COMP', storeAssessments)) {
      assessments.keyComp = true;
    }
    if (hasAssessment('ASSESSMENT_LEAD-COMP', storeAssessments)) {
      assessments.leadComp = true;
    }
    if (hasAssessment('ASSESSMENT_POTENTIAL', storeAssessments)) {
      assessments.potential = true;
    }
    if (hasAssessment('ASSESSMENT_WORK-PREFERENCE', storeAssessments)) {
      assessments.workPref = true;
    }
    if (hasAssessment('ASSESSMENT_9LEVELS', storeAssessments)) {
      assessments.nineLevels = true;
    }
    if (hasAssessment('ASSESSMENT_RMP', storeAssessments)) {
      assessments.rmp = true;
    }

    // eslint-disable-next-line consistent-return
    return assessments;
  },
);

export const selectSwitches = createSelector(
  selectConfiguration,
  (configuration) => configuration.switches,
);

const selectUserGroup = createSelector(
  selectConfiguration,
  (configuration) => configuration.userGroup,
);
const selectUserSubGroup = createSelector(
  selectConfiguration,
  (configuration) => configuration.userSubGroup,
);

export const selectCapabilitiesNext = createSelector(
  selectSwitches,
  selectUserGroup,
  selectUserSubGroup,
  selectIsBalancedYou,
  (switches, userGroup, userSubGroup, isBalancedYou) => {
    const capabilitiesNext = {};

    if (userGroup === 'admin' || userGroup === 'assessmentManager') {
      CAPABILITIES_SWITCHES.forEach((cs) => {
        capabilitiesNext[cs] = true;
      });
    }

    if (userGroup === 'leader') {
      CAPABILITIES_SWITCHES.forEach((cs) => {
        capabilitiesNext[cs] = Boolean(switches.find(({ id }) => id === cs)?.status);
      });
    }

    // Allow team leaders to view employees/teams pages.
    // Backend will take care of filtering results by team leadership.
    if (capabilitiesNext.employeesView !== undefined) {
      capabilitiesNext.employeesViewOriginal = capabilitiesNext.employeesView;
      capabilitiesNext.teamsViewOriginal = capabilitiesNext.teamsView;
    }
    if (userSubGroup === 'teamLeader') {
      capabilitiesNext.employeesView = true;
      capabilitiesNext.teamsView = true;
    }

    // Turn off most capabilities for BalancedYou
    if (isBalancedYou) {
      [ 'candidatesView', 'candidatesInvite',
        'vacanciesView', 'vacanciesCreate',
        'rolesView', 'rolesCreate', 'rolesMatch',
        'teamsView', 'teamsCreate', 'teamsUpdate', 'teamsStaff',
        'surveysView', 'surveysCreate',
      ].forEach((capability) => {
        capabilitiesNext[capability] = false;
      });
    }

    return capabilitiesNext;
  },
);

export const selectIsPeerControlled = createSelector(
  selectCompany,
  selectCapabilitiesNext,
  (company, capabilitiesNext) => Boolean(company?.controlledAssessmentFlow && !capabilitiesNext?.employeesViewOriginal),
);
export const selectIsPeerController = createSelector(
  selectCompany,
  selectCapabilitiesNext,
  (company, capabilitiesNext) => Boolean(
    (company?.controlledAssessmentFlow || company?.employeeAssessmentManagementFlow)
    && capabilitiesNext?.employeesViewOriginal,
  ),
);

// COMBINED
export const selectLogo = (state = {}) => {
  const customLogoUrl = selectCompanyCustomLogoUrl(state);
  const isAdminFrontend = selectIsAdminFrontend(state);

  const isBalancedYou = state.configuration?.instance?.flavor === 'balancedYou';
  const defaultLogo = isBalancedYou ? byLogo : bqLogo;

  // return custom logo if available
  let logo = customLogoUrl || defaultLogo;
  // always bqLogo when admin frontend
  if (isAdminFrontend) {
    logo = bqLogo;
  }

  return logo;
};

export const selectIsCustomLogoActive = (state) => {
  const logo = selectLogo(state);
  const customLogoUrl = selectCompanyCustomLogoUrl(state);

  return logo === customLogoUrl;
};
