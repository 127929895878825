/* eslint-disable no-use-before-define */
// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './BillingDetailsForm.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable, InputNext, InputNumber } from 'ui/basic';

// UTILS
import REGEXES from 'utils/configuration/const/regexes';
import { useTranslate } from 'utils/translator';
import { sortAlphabetically } from 'utils/strings';

// STORE NEXT
import * as api from 'api';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
import { getStripeVatTypes } from 'features/+adminUG/config/payment.config';

const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
  countryPreSelectedValue: 'de',
};

// COMPONENT: BillingDetailsForm
const BillingDetailsForm = (props) => {
  // PROPS
  const {
    billingDetails = {},
    onChange = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ countries, setCountries ] = useState([]);
  useEffect(() => {
    if (countries.length > 0) {
      return;
    }

    api.get('/core/config/profile/countries')
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setCountries(data.content
        .map(({ id, country }) => ({ value: id, label: country }))
        .sort((a, b) => sortAlphabetically(a.label, b.label)));
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ countries ]);

  // EMAIL
  const [ email, setEmail ] = useState('');
  const [ emailError, setEmailError ] = useState();
  useEffect(() => {
    setEmail(billingDetails.email);
  }, [ billingDetails.email ]);

  const handleEmail = (thisEmail) => {
    setEmail(thisEmail);
    checkAnswers({ email: thisEmail });
  };

  // FIRST NAME
  const [ firstName, setFirstName ] = useState('');
  useEffect(() => {
    setFirstName(billingDetails.firstName);
  }, [ billingDetails.firstName ]);

  const handleFirstName = (thisFirstName) => {
    setFirstName(thisFirstName);
    checkAnswers({ firstName: thisFirstName });
  };

  // LAST NAME
  const [ lastName, setLastName ] = useState('');
  useEffect(() => {
    setLastName(billingDetails.lastName);
  }, [ billingDetails.lastName ]);

  const handleLastName = (thisLastName) => {
    setLastName(thisLastName);
    checkAnswers({ lastName: thisLastName });
  };

  // PHONE NUMBER
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ phoneNumberErrorMessage, setPhoneNumberErrorMessage ] = useState('');
  useEffect(() => {
    setPhoneNumber(billingDetails.phoneNumber);
  }, [ billingDetails.phoneNumber ]);

  const handlePhoneNumber = (thisPhoneNumber) => {
    setPhoneNumber(thisPhoneNumber);
    checkAnswers({ phoneNumber: thisPhoneNumber });
  };

  // ORG NAME
  const [ orgName, setOrgName ] = useState('');
  useEffect(() => {
    setOrgName(billingDetails.orgName);
  }, [ billingDetails.orgName ]);

  const handleOrgName = (thisOrgName) => {
    setOrgName(thisOrgName);
    checkAnswers({ orgName: thisOrgName });
  };

  // ORG STREET
  const [ street, setStreet ] = useState('');
  useEffect(() => {
    setStreet(billingDetails.street);
  }, [ billingDetails.street ]);

  const handleStreet = (thisStreet) => {
    setStreet(thisStreet);
    checkAnswers({ street: thisStreet });
  };

  // ORG CITY
  const [ city, setCity ] = useState();
  useEffect(() => {
    setCity(billingDetails.city);
  }, [ billingDetails.city ]);

  const handleCity = (thisCity) => {
    setCity(thisCity);
    checkAnswers({ city: thisCity });
  };

  // ORG ZIP
  const [ zip, setZip ] = useState();
  const [ zipIsValid, setZipIsValid ] = useState();
  useEffect(() => {
    if (zip) {
      return;
    }

    setZip(billingDetails.zip);
  }, [ billingDetails.zip, zip ]);

  const handleZip = (thisZip, isValid) => {
    setZip(thisZip);
    setZipIsValid(isValid);
    checkAnswers({ zip: thisZip });
  };

  // ORG COUNTRY
  const [ country, setCountry ] = useState();
  useEffect(() => {
    if (country || countries.length === 0) {
      return;
    }

    if ((!billingDetails || Object.keys(billingDetails).length === 0) && countries.length) {
      setCountry(Config.countryPreSelectedValue);
      return;
    }

    setCountry(billingDetails.country);
  }, [ billingDetails, countries, country ]);


  const handleCountry = (thisCountry) => {
    setCountry(thisCountry);

    // by re-selecting the country 'vat id' field should be empty
    setVatId(null);
    checkAnswers({ country: thisCountry });
  };

  // VAT ID OPTIONS
  const [ vatTypeOptions, setVatTypeOptions ] = useState([]);
  useEffect(() => {
    if (country) {
      const vatTypes = getStripeVatTypes(country);

      if (!vatTypes || vatTypes.length === 0) {
        setVatType(null);
        setVatTypeOptions([]);
        return;
      }

      setVatTypeOptions(vatTypes.map((v) => ({
        value: v,
        label: translate(v),
      })));

      setVatType(null);
      if (vatTypes.length === 1) {
        setVatType(vatTypes[0]);
      }
    }
  }, [ translate, country ]);

  // ORG VAT ID
  const [ vatType, setVatType ] = useState();
  useEffect(() => {
    if (vatType || vatTypeOptions.length === 0) {
      return;
    }

    setVatType(billingDetails.vatType);
  }, [ billingDetails.vatType, vatType, vatTypeOptions.length ]);

  const handleVatType = (thisVatType) => {
    setVatType(thisVatType);
    setVatId(null);
    checkAnswers({
      vatType: thisVatType,
      vatId: null,
    });
  };

  // VAT NUMBER
  const [ vatId, setVatId ] = useState();
  useEffect(() => {
    setVatId(billingDetails.vatId);
  }, [ billingDetails.vatId ]);

  const handleVatId = (thisVatId) => {
    setVatId(thisVatId);
    checkAnswers({ vatId: thisVatId });
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const checkAnswers = (overrides) => {
    const firstNameValue = overrides.firstName !== undefined ? overrides.firstName : firstName;
    const lastNameValue = overrides.lastName !== undefined ? overrides.lastName : lastName;
    const emailValue = overrides.email !== undefined ? overrides.email : email;
    const orgNameValue = overrides.orgName !== undefined ? overrides.orgName : orgName;
    const streetValue = overrides.street !== undefined ? overrides.street : street;
    const cityValue = overrides.city !== undefined ? overrides.city : city;
    const zipValue = overrides.zip !== undefined ? overrides.zip : zip;
    const countryValue = overrides.country !== undefined ? overrides.country : country;
    const phoneNumberValue = overrides.phoneNumber !== undefined
      ? overrides.phoneNumber
      : phoneNumber;
    const vatTypeValue = overrides.vatType !== undefined ? overrides.vatType : vatType;
    const vatIdValue = overrides.vatId !== undefined ? overrides.vatId : vatId;

    let phoneErrorMessage;
    if (phoneNumberValue && !phoneNumberValue.startsWith('+') && !phoneNumberValue.startsWith('00')) {
      phoneErrorMessage = translate('phone_number_country_code_required_error');
    } else {
      phoneErrorMessage = '';
    }

    const isValid = firstNameValue && lastNameValue && emailValue && orgNameValue
      && streetValue && cityValue
      && zipValue && zipIsValid
      && countryValue
      && firstNameValue.length >= Config.minLengthFirstName
      && lastNameValue.length >= Config.minLengthLastName
      && !emailError
      && !phoneErrorMessage;

    setPhoneNumberErrorMessage(phoneErrorMessage);

    const thisBillingDetails = {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      phoneNumber: phoneNumberValue,
      orgName: orgNameValue,
      street: streetValue,
      city: cityValue,
      zip: zipValue,
      country: countryValue,
      vatType: vatTypeValue,
      vatId: vatIdValue,
      isValid,
    };

    onChange(thisBillingDetails);
  };

  // RENDER: BillingDetailsForm
  return (
    <div className={classNames(styles.billingDetailsForm)}>
      { /* ORG DATA */ }
      <div className={styles.orgData}>
        <div className='bluTypeLabelL'>{ translate('organization') }</div>
        <div className={styles.formRow}>
          <InputNext
            value={orgName}
            label={translate('org_name')}
            onChange={handleOrgName}
          />
        </div>

        <div className={styles.formRow}>
          <InputNext
            value={street}
            label={translate('street')}
            onChange={handleStreet}
          />
        </div>

        <div className={styles.inputs}>
          <InputNext
            value={city}
            label={translate('city')}
            onChange={handleCity}
          />

          <InputNumber
            value={zip}
            label={translate('zip')}
            onChange={handleZip}
          />
        </div>

        <div className={styles.formRow}>
          <DropDownSearchable
            placeholder='Country'
            filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
            activeValue={country}
            options={countries}
            onChange={(option) => {
              handleCountry(option?.value ?? '');
            }}
          />
        </div>

        <div className={styles.formRow}>
          <DropDownSearchable
            placeholder={translate('vat_type')}
            filterPlaceholder={translate('search_lbl') || 'Durchsuchen'}
            activeValue={vatType}
            options={vatTypeOptions}
            onChange={(option) => {
              handleVatType(option?.value ?? '');
            }}
          />
        </div>

        { vatType && (
          <div className={styles.formRow}>
            <InputNext
              value={vatId}
              label={translate('vat_id')}
              onChange={handleVatId}
            />
          </div>
        ) }
      </div>

      { /* CONTACT PERSON DATA */ }
      <div className={styles.contactPerson}>
        <div className='bluTypeLabelL'>{ translate('upgrade_to_pp_billing_contact_person_title') }</div>
        <div className={styles.formRow}>
          <InputNext
            value={firstName}
            label={translate('cp_firstname_placeholder')}
            onChange={handleFirstName}
          />
        </div>

        <div className={styles.formRow}>
          <InputNext
            value={lastName}
            label={translate('cp_lastname_placeholder')}
            onChange={handleLastName}
          />
        </div>

        <div className={styles.formRow}>
          <InputNext
            type='email'
            value={email}
            label={translate('business_email')}
            validate={{
              pattern: REGEXES.EMAIL,
              onChange: true,
            }}
            errorMessage={emailError && (emailError.pattern
              ? (translate('admin_new_instance_admin_format_msg') || 'Please enter valid email')
              : 'Required?'
            )}
            onChange={handleEmail}
            onError={(error) => setEmailError(error)}
          />
        </div>

        <div className={styles.formRow}>
          <InputNext
            type='tel'
            value={phoneNumber}
            label={translate('phone_number_optional')}
            errorMessage={phoneNumberErrorMessage}
            onChange={handlePhoneNumber}
          />
        </div>

      </div>

    </div>
  );
};

export default BillingDetailsForm;
