
export const DEFAULT_DESCRIPTION = `[blu-markdown]

Um wertvolle Erkenntnisse aus diesem Assessment ziehen zu können, ist es wichtig, dass du die folgenden Hinweise beachtest:

In diesem Test werden dir eine Reihe von Aussagen vorgelegt, die sich auf deine psychologischen Fähigkeiten beziehen. Bitte bewerte, inwieweit du jeder dieser Aussagen zustimmst oder nicht zustimmst. Wenn nach etwas gefragt, das in deinem Leben noch nicht passiert ist, versuche dir vorzustellen, wie du reagieren würdest, wenn das Ereignis eintreten würde. Wenn du z. B. gefragt wirst, wie du dich einer bestimmten Situation verhältst, versuche dir die Situation vorzustellen und antworte nach bestem Wissen und Gewissen.


**Viel Spaß beim Assessment!**
`;
export const DEFAULT_COPYRIGHT_TITLE = '© IDS Publishing Corporation. All rights reserved.';
export const DEFAULT_COPYRIGHT_DESCRIPTION = `
All uses of this content are covered by this Copyright Notice,
which may not be removed or altered. No modidfications of the content are permitted,
including any translations into another language. No rights are granted for use of any portion
of this content without the express, written permission obtained in advance from IDS Publishing Corporation,
 including creation of any derivative work for puoblication such as documents that quote, cite,
 or are based upon this content , whether for research puposes or otherwise.
`;
