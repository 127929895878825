// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './InstanceCreationSuccess.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { translate } from 'utils/translator/translator';
import { getInstancePageUrl } from 'features/framework/utils/domain';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: InstanceCreationSuccess
const InstanceCreationSuccess = (props) => {
  // PROPS
  const { extras } = props;

  const answers = extras?.answers;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleClick = () => {
    if (!answers) {
      return;
    }

    const instance = answers[2]?.domain;
    window.open(getInstancePageUrl(instance), '_self');
  };

  // RENDER: InstanceCreationSuccess
  return (
    <div className={classNames(styles.instanceCreationSuccess)}>
      <div className={styles.gridContent}>
        <div className='bluTypeM'>
          <span role='img' aria-label='success'>🙌</span>
          <br />
          { translate('trial_signup_instance_created_title') }
        </div>

        <div className='bluTypeCopy'>
          { translate('trial_signup_instance_created_copy') }
        </div>

        <Button onClick={handleClick}>
          { translate('share_profile_link_expired_btn') }
        </Button>
      </div>
    </div>
  );
};

export default InstanceCreationSuccess;
