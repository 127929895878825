import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import { Peer360AssessmentEnd } from '../intermissions/Peer360AssessmentEnd';

// UTILS
import { translate } from 'utils/translator/translator';
import classNames from 'classnames';

// OTHER COMPONENTS


export const customPeer360Config = {
  id: 'customPeer360',
  title: 'Peer360',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: () => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  allQuestionsSkippedPage: {
    isIntermission: true,
    showBackArrow: true,
    preventKeyboardNext: true,
    isAnswersSkippedPage: true,
    render: () => (
      <div>
        <div className='bluTypeS'>{ translate('warning') }</div>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('peer_assmnt_missing_required_answer_msg') }
        </div>
      </div>
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => {
        const {
          peerFor,
          title,
          description,
        } = state;

        return (
          <AssessmentIntro
            assessmentType='customPeer360'
            title={title}
            description={peerFor
              ? translate(
                state.customContent?.peer_360_asmnt_peer_intro_copy ?? 'peer_360_asmnt_peer_intro_copy',
                [ '{{username}}', peerFor ],
              )
              : translate(description)}
            onClickNext={next}
          />
        );
      },
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => {
        const { peerFor, guidanceContent } = state;
        let guidanceKey = peerFor
          ? state.customContent?.peer_360_ass_guidance_copy ?? 'peer_360_ass_guidance_copy'
          : 'peer_360_ass_guidance_self_copy';
        // Custom guidance only for self assessment
        if (guidanceContent && !peerFor) {
          guidanceKey = guidanceContent;
        }

        return (
          <AssessmentGuidance
            assessmentType='customPeer360'
            onClickNext={next}
            content={translate(guidanceKey, [ '{{username}}', peerFor ])}
          />
        );
      },
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <Peer360AssessmentEnd
          {...extras}
          customContent={state.customContent}
          userId={state.userId}
          peerFor={state.peerFor}
          asPeer={state.asPeer}
          hasBqAccount={state.hasBqAccount}
        />
      ),
    },
  ],
};
