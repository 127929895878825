// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './QuestionIntro.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useDispatch } from 'react-redux';
import { fetchStaticValues } from 'features/framework/storeNext/staticValuesSlice';

// STORE

// CONFIG & DATA

// COMPONENT: QuestionIntro
const QuestionIntro = (props) => {
  // PROPS
  const {
    onNext = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const dispatch = useDispatch();

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS

  // STORE HOOKS
  useEffect(() => {
    dispatch(fetchStaticValues());
  }, [ dispatch ]);

  // METHODS

  // EVENT HANDLES
  const handleNext = () => {
    onNext();
  };

  // HELPERS

  // RENDERS

  // RENDER: QuestionIntro
  return (
    <div className={classNames(styles.questionIntro)}>

      { /* BREATHE */ }
      <div className={styles.breatheContainer} />

      { /* CONTENT */ }
      <div className={styles.content}>

        { /* TITLE */ }
        <div className={styles.title}>
          { translate('trial_signup_intro_title') }
        </div>

        <div className={styles.description}>
          { translate('trial_signup_intro_sub_title') }
        </div>

        <Button onClick={handleNext}>{ translate('continue_lbl') }</Button>
      </div>

    </div>

  );
};

export default QuestionIntro;
