// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';

import { InputNext, InputFile } from 'ui/basic';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};
const DOCUMENT_MAX_SIZE_MB = 10;
const DOCUMENT_ALLOWED_TYPES = [
  'doc',
  'docx',
  'odt',
  'xls',
  'xlsx',
  'ods',
  'ppt',
  'pptx',
  'odp',
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'tif',
  'tiff',
];

// COMPONENT: FormDocument
const FormDocument = (props) => {
  // PROPS
  const {
    children,
    file,
    inputRef,
    disabled,
    isTitleShown = false,
    onValid = () => {},
    onInvalid = () => {},
    onInputFocus = () => {},
    onChange = () => {},
    onSubmit,
  } = props;

  const [ documentFullName, setDocumentFullName ] = useState(file?.name || '');
  const [ documentFile, setDocumentFile ] = useState(null);
  const [ isDocumentValid, setIsDocumentValid ] = useState(false);

  const [ documentName, setDocumentName ] = useState();
  useEffect(() => {
    if (documentName || !file?.name) {
      return;
    }

    const parts = file.name?.split('.');
    if (parts) {
      setDocumentName(parts.slice(0, parts.length - 1).join(''));
    }
  }, [ documentName, file ]);

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ fileExtension, setFileExtension ] = useState(file?.name?.split('.')?.pop() || '');
  const onDocumentSelect = (fileInternal = {}) => {
    setFileExtension(fileInternal.name.split('.')?.pop());
    setDocumentFile(fileInternal);
    onChange({ file: fileInternal, name: documentName });
  };

  const onDocumentNameChange = (value) => {
    setDocumentName(value);

    let docFullName = value;
    if (value && fileExtension) {
      docFullName = `${value}.${fileExtension}`;
    }
    setDocumentFullName(docFullName);
    onChange({ file: documentFile, name: value });
  };

  const onDocumentSubmit = (e) => {
    if (isDocumentValid || documentFile === null) {
      // submit is valid if only the name was changed
      onSubmit({ ...file, file: documentFile, name: documentFullName });
    }

    e.preventDefault();
  };

  const onFileInputValid = () => {
    setIsDocumentValid(true);
    onValid();
  };

  const onFileInputInvalid = () => {
    setIsDocumentValid(false);
    onInvalid();
  };

  // RENDER: FormDocument
  return (
    <form onSubmit={onDocumentSubmit}>
      <div>
        <InputFile
          label={translate('input_file_choose_file')}
          inputRef={inputRef}
          disabled={disabled}
          maxSizeMB={DOCUMENT_MAX_SIZE_MB}
          allowedTypes={DOCUMENT_ALLOWED_TYPES}
          initialFileName={documentFullName}
          onChange={onDocumentSelect}
          onValid={onFileInputValid}
          onInvalid={onFileInputInvalid}
        />
      </div>

      { (isDocumentValid === true || isTitleShown === true) && (
        <InputNext
          label={translate('candidate_attachments_title')} // Anzeigetext
          value={documentName}
          disabled={disabled}
          domain={fileExtension && `.${fileExtension}`}
          onChange={onDocumentNameChange}
          onFocus={onInputFocus}
        />
      ) }

      <div>{ children }</div>
    </form>
  );
};

export default FormDocument;
