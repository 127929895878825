import React from 'react';
import styles from './MyProfileTeaser.module.scss';
import { useHistory } from 'react-router';

import * as api from 'api';

import classNames from 'classnames';

import MyProfileTeaserImage from 'assets/keyvisuals/mp-teaser.svg';

import { useTranslate } from 'utils/translator';

import { Button, Card, ImgCircle } from 'ui/basic';


const MyProfileTeaser = ({ user, buttonTitle, showStrengths }) => {
  const translate = useTranslate();
  const history = useHistory();

  const firstName = user.firstName || '';
  const lastName = user.lastName || '';

  return (
    <div className={classNames(styles.myProfileTeaser)}>
      <Card hasNoPaddings>
        <div className={styles.content}>
          { /* IMAGE */ }
          <div className={styles.imageBlock}>
            <img
              alt='teaser'
              src={MyProfileTeaserImage}
              draggable={false}
            />

            <ImgCircle
              src={api.getUserImageUrl(user.id)}
              label1={firstName}
              label2={lastName}
              size='M'
            />
          </div>

          { /* TEXT CONTENT */ }
          <div className={styles.textBlock}>
            <div className={styles.xxsTitle}>
              { translate('myprofile_teaser_title') }
            </div>
            <div className={styles.copy}>
              { translate('myprofile_teaser_copy') }
            </div>
            <Button
              size='S'
              looks='secondary'
              onClick={() => history.push(`/my-profile/${!showStrengths ? '' : 'strength-profile/'}`)}
            >
              { buttonTitle }
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MyProfileTeaser;
