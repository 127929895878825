// REACT

// UTILS
import { SecureStorage } from 'utils/storage';

// CONFIGS
import * as dialogConfigs from './config';
import { GLOBAL_CONFIG } from './DialogSequence.config';

/**
 *
 * @param {*} isOptional
 * @param {*} newAnswer
 * @param {*} selectedAnswer
 * @returns true or false
 */
export const isNextAllowed = (isOptional, newAnswer, selectedAnswer) => {
  const answer = newAnswer || selectedAnswer || {};
  if (isOptional) {
    if (answer.value) {
      // Also valid when all answer fields are empty
      const areFieldsEmpty = answer.value.every?.((answer) => Object.values(answer).filter(Boolean).length === 0);
      return answer.isValid || areFieldsEmpty;
    }

    return true;
  }

  if (answer) {
    return answer.isValid;
  }

  return false;
};


/**
 * copyDialogConfig:
 * retrieves a copy of the dialogConfig for type
 * or undefined if it can't be found
 * @param {string} type dialogType
 * @returns a copy of the config
 */
export const copyDialogConfig = (type) => {
  const dialogConfig = dialogConfigs[`${type}Config`];
  if (!dialogConfig) {
    return;
  }
  return { ...dialogConfig };
};


/**
 * validateDeflatedState:
 * validates a deflated state from the storage.
 * Checks if the intermission count and indices are equal
 * (assumes they are sorted by their insertAtIndex property,
 * which they are after state has been created with createStateWithPages)
 * and checks if the questions count is the same
 * and all question ids can be found in config
 * @param {Object} deflatedState parsed state from secure storage which doesn't include intermission components
 * @param {Object} config dialog config to validate against
 * @returns true or false
 */
export const validateDeflatedState = (deflatedState, config) => {
  const deflatedPages = deflatedState.pages;

  // storageValidityWindow
  if (!hasValidStorageValidityWindow(deflatedState, config)) {
    return false;
  }

  // pages
  const pages = [ ...config.pages ];

  // check lengths
  if (pages.length !== deflatedPages.length) {
    return false;
  }
  // check question ids
  let pageIdsValid = true;
  const pagesMap = {};
  pages.forEach((page) => {
    pagesMap[page.id] = page;
  });
  deflatedPages.forEach((deflatedPage) => {
    const deflatedId = deflatedPage.id;
    const pageFromPage = pagesMap[deflatedId];
    if (!pageFromPage) {
      pageIdsValid = false;
    }
  });
  if (!pageIdsValid) {
    return false;
  }

  return true;
};

const hasValidStorageValidityWindow = (deflatedState, config) => {
  const storageValidityWindow = !isNaN(config.storageValidityWindow)
    ? config.storageValidityWindow
    : GLOBAL_CONFIG.storageValidityWindow;
  const lastSaveTime = deflatedState.lastSaveTime || 0;

  if (Date.now() >= lastSaveTime + storageValidityWindow) {
    return false;
  }
  return true;
};

/**
 * inflateStorageState:
 * Adds non JSONable properties back to the intermissions of deflatedValidatedState
 * e.g. render functions, insertAtIndex: Infinity
 * also unsets any potential clickBlock
 * @param {*} deflatedValidatedState deflated state from storage, needs to be validated with validateDeflatedState before
 * @param {*} config config with intermissions to inflate state with
 * @return returns a new inflatedState
 */
export const inflateStorageState = (deflatedValidatedState, config) => {
  const inflatedState = { ...deflatedValidatedState };
  const { pages } = deflatedValidatedState;
  const configPages = config.pages;

  // populate pages
  pages.forEach((page, index) => {
    const configPage = configPages[index];
    Object.assign(page, configPage);
  });

  // unset ClickBlock
  inflatedState.clickBlock = false;

  return inflatedState;
};

export const getQuestionsFromState = (state) => state.pages.filter((page) => !page.isIntermission);

export const areAllQuestionsAnswered = (state) => {
  const questions = getQuestionsFromState(state);
  const { answers } = state;
  const answerKeys = Object.keys(answers);

  // larger equals questions.length because intermissions produce undefined answers
  // but we don't count them for necessary answers
  return [ answerKeys.length >= questions.length, answers, questions ];
};

export const getIntermissionsFromState = (state) => state.pages.filter((page) => page.isIntermission);

export const hasStoredAnswers = (type, userId) => {
  // init storage controller for type and userId
  storageController.init(type, userId);
  const storageState = storageController.loadState();

  // retrieve config for type
  const dialogConfig = copyDialogConfig(type);

  let hasStoredAnswers = false;
  // has a config & state?
  if (dialogConfig && storageState) {
    // valid window?
    if (hasValidStorageValidityWindow(storageState, dialogConfig)) {
      // non-empty answers
      if (hasAnswers(storageState)) {
        hasStoredAnswers = true;
      }
    }
  }

  storageController.reset();
  return hasStoredAnswers;
};

export const hasAnswers = (state) => {
  const answers = state.answers || {};
  const answerKeys = Object.keys(answers);

  return answerKeys.length;
};


export const storageController = {
  secureStorage: null,

  init(type, userId) {
    this.secureStorage = new SecureStorage(type, userId);
  },
  isInitialised() {
    const { secureStorage } = this;

    return secureStorage && secureStorage.isInitialised();
  },
  reset() {
    const { secureStorage } = this;

    secureStorage.reset();

    this.secureStorage = null;
  },
  /**
   * loads state from storage and returns it if it's valid
   * @param {Object} config
   * @param {Array} questions
   * @returns validState or undefined
   */
  loadValidState(config, questions) {
    const { secureStorage } = this;

    const storageState = secureStorage.load();

    let validState;

    if (storageState) {
      // validate storage state
      const isValid = validateDeflatedState(storageState, config, questions);
      // inflate if valid
      if (isValid) {
        const inflatedStorageState = inflateStorageState(storageState, config, questions);
        validState = inflatedStorageState;
      }
      // remove state from secureStorage if it's invalid
      else {
        secureStorage.remove();
      }
    }

    return validState;
  },
  loadState() {
    const { secureStorage } = this;
    return secureStorage.load();
  },
  /**
   * saves if:
   *  - storageController is initialised
   *  - clickBlock is not active
   *  - answers are not empty
   * @param {Object} state
   * @returns true or false
   */
  saveState(state) {
    const { secureStorage } = this;

    let saved = false;
    if (
      this.isInitialised()
      && !state.clickBlock
      && hasAnswers(state)
    ) {
      secureStorage.save(state);
      saved = true;
    }

    return saved;
  },
  removeState() {
    const { secureStorage } = this;
    secureStorage.remove();
  },

};
