// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Peer360Setup.module.scss';

// OTHER COMPONENTS
import { Button, HotChips, Toast } from 'ui/basic';
import { SkeletonFlexible } from 'ui/basic/micro-ui/SkeletonFlexible';
import { PeerIconsLine } from './PeerIconsLine';
import { PeerHotChipsPeople } from '../PeerHotChipsPeople';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';
import { sortAlphabetically } from 'utils/strings';
import REGEXES from 'utils/configuration/const/regexes';

// STORE NEXT
import { useHistory } from 'react-router';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENT: Peer360Setup
const Peer360Setup = ({
  assessment,
  employee,
  allowInviteInternalUsers = true,
  viewIsModal = true,
  onSetup,
}) => {
  const {
    id: employeeId,
    name: employeeName,
    mail: employeeMail,
  } = employee ?? {};

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();

  // SELECTORS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ peers, setPeers ] = useState({});
  const [ toastContent, setToastContent ] = useState(null);
  const [ isSendingInvite, setIsSendingInvite ] = useState(false);

  const peerGroups = Object.entries(assessment?.peerGroups ?? {})
  .filter(([ , value ]) => value?.label)
  .sort(([ keyA ], [ keyB ]) => sortAlphabetically(keyA, keyB));
  const crossValues = Object.values(peers).flat()
  .concat({ value: employeeMail || localStorage.getItem('mail') }); // prevent user from selecting themself
  const hasWrongQuantity = peerGroups.some(([ key, value ]) => {
    const { minPeers, maxPeers, required } = value;
    const total = peers[key]?.length ?? 0;

    if (!required && !total) {
      return false;
    }

    return total < minPeers || total > (maxPeers ?? Infinity);
  });

  /**
   * Api call to invite a list of users and set up the assessment.
   */
  const sendInvites = () => {
    const invitedPeers = Object.entries(peers).reduce((acc, [ group, groupValues ]) => {
      acc.push(...groupValues.map(({ id, value, isEmail }) => (isEmail
        ? { group, email: value }
        : { group, user: id }
      )));
      return acc;
    }, []);

    setIsSendingInvite(true);
    const params = employeeId ? { user: employeeId } : undefined;
    apiNext.post(`/core/assessments/${assessment.id}/setup`, { peers: invitedPeers }, params)
    .then(() => {
      // Show success toast
      setToastContent((
        <>
          <div className={styles.toastTitle}>
            { translate('invite_emp_success_title') }
          </div>
          <div className={styles.toastDescription}>
            { translate('invite_emp_success_descr') }
          </div>
        </>
      ));

      // Change to edit page after small delay
      setTimeout(onSetup, 2500);
    })
    .catch((error) => {
      console.error(error.message);
      setToastContent(translate('api_error_generic'));
      setIsSendingInvite(false);
    });
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuantityError
  const renderQuantityError = (total, min, max) => {
    if (total) {
      let errorText;
      if (max && total > max) {
        errorText = translate(
          `peer_360_setup_quantity_error_max_${max === 1 ? 'singular' : 'plural'}`,
          [ '{{number}}', max ],
        );
      }
      if (total < min) {
        errorText = translate(
          'peer_360_setup_quantity_error_min_plural', // min_singular case is impossible
          [ '{{number}}', min ],
        );
      }

      if (errorText) {
        return (
          <div className={styles.inviteQuantityError}>
            { errorText }
          </div>
        );
      }
    }

    return null;
  };

  // RENDER: Peer360Setup
  return (
    <div className={classNames(styles.peer360Setup, { [styles.modal]: viewIsModal })}>
      { /* HEADER */ }
      { viewIsModal && (
        <div className={styles.header}>
          <span>
            { translate(
              'peer_assessment_title',
              [ '{{assessment}}', assessment.title ],
            ) }
          </span>
          <div
            className={styles.close}
            role='presentation'
            onClick={() => history.goBack()}
          >
            { translate('close_lbl') }
          </div>
        </div>
      ) }

      { /* BODY */ }
      <div className={styles.scrollableContent}>
        <div className={styles.gridContent}>
          <div className={styles.main}>
            { employeeName && (
              <div className={styles.preTitle}>
                { translate(
                  'peer_setup_pretitle',
                  [ '{{username}}', employeeName ],
                ) }
              </div>
            ) }
            <div className={styles.title}>
              { translate(
                'peer_setup_title',
                [ '{{assessment}}', assessment.title ],
              ) }
            </div>
            <div className={styles.description}>
              { translate(assessment.customContent?.peer_360_setup_description
                ?? 'peer_360_setup_description_default') }
            </div>

            { /* PEER GROUP SECTIONS */ }
            { !peerGroups.length
              ? (
                <div className={styles.skeleton}>
                  <SkeletonFlexible repeat={5} />
                </div>
              ) : (
                <>
                  { peerGroups.map(([ key, value ], index) => (
                    <div className={styles.peerGroup} key={key}>
                      <div className={styles.title}>
                        { capitalise(value.label) }
                        { !value.required && ` (${translate('peer_360_group_optional')})` }
                      </div>
                      <div className={styles.description}>
                        { capitalise(value.description) }
                      </div>

                      <div className={styles.inviteTitle}>
                        { translate('peer_360_group_invite_peers') }
                      </div>
                      <PeerIconsLine
                        total={peers[key]?.length}
                        min={value.minPeers}
                        max={value.maxPeers}
                      />

                      { allowInviteInternalUsers && (
                        <PeerHotChipsPeople
                          values={peers[key] ?? []}
                          crossValues={crossValues}
                          onUpdate={(newPeers) => {
                            setPeers((state) => ({ ...state, [key]: newPeers }));
                          }}
                          topFlyout={index + 1 === peerGroups.length}
                          employeeId={employeeId}
                        />
                      ) }

                      { !allowInviteInternalUsers && (
                        <HotChips
                          hint={translate('invite_emp_emails_hint')}
                          errorHint={translate('invite_emp_emails_hint_invalid')}
                          errorHintDuplicate={translate('invite_emp_emails_duplicate_hint')}
                          placeholder={translate('email_add_lbl')}
                          validate={{ pattern: REGEXES.EMAIL }}
                          values={peers[key]?.map((peer) => peer.value)}
                          crossValues={crossValues}
                          onUpdate={(newMails) => {
                            setPeers((state) => ({
                              ...state,
                              [key]: newMails.map((newMail) => ({
                                value: newMail,
                                isEmail: true,
                              })),
                            }));
                          }}
                          required={value.required}
                        />
                      ) }

                      { renderQuantityError(peers[key]?.length, value.minPeers, value.maxPeers) }
                    </div>
                  )) }

                  { /* BOTTOM BUTTON */ }
                  <div className={styles.bottom}>
                    <Button
                      disabled={hasWrongQuantity || isSendingInvite}
                      onClick={sendInvites}
                    >
                      { translate('peer_360_group_setup_invite') }
                    </Button>
                  </div>
                </>
              ) }
          </div>
        </div>
      </div>

      { /* FLOATERS */ }
      { toastContent && (
        <Toast onClose={() => setToastContent(null)}>
          { toastContent }
        </Toast>
      ) }
    </div>
  );
};

export default Peer360Setup;
