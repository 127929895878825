// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './StandaloneHeader.module.scss';

// ASSETS
// eslint-disable-next-line max-len
import coachHubLogo from 'features/framework/components/MainLayout/WrapperCoachHub/components/CoachHubHeader/coachHub.svg';

// 3RD PARTY
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

// OTHER COMPONENTS
import { Logo } from '../../Logo';
import { DropDownSubtle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import * as localStorage from 'utils/localStorage';
import { SUBDOMAIN_FOR_DEFAULT_COMPANY_ID } from 'utils/configuration';
import { getInstancePageUrl } from 'features/framework/utils/domain';

// STORE OLD
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'store/actions';
import { selectIsCoachHub } from 'store/selectors/configuration';
import { useBreakpoint } from 'utils/hooks';


// COMPONENT: StandaloneHeader
const StandaloneHeader = (props) => {
  // PROPS
  const {
    noHeaderLogoLink = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isXs } = useBreakpoint();

  const isCoachHub = useSelector(selectIsCoachHub);

  // LANGUAGE SWITCH
  const allLanguages = useSelector((state) => state.localisation.languages || []);
  const [ languageOptions, setLanguageOptions ] = useState([]);
  useEffect(() => {
    const newLanguageOptions = allLanguages.map((languageId) => ({
      value: languageId,
      label: translate(`langswitch_menu_${languageId}`),
    }));
    setLanguageOptions(newLanguageOptions);
  }, [ allLanguages, translate ]);
  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);
  const handleLanguageChange = (index) => {
    localStorage.setItem('overrideLanguage', true);

    // for external assessments
    if (window.location.pathname.includes('share') && window.location.pathname.includes('assessments')) {
      localStorage.setItem('langChangedForExternalAssessment', true);
    }

    dispatch(changeLanguage({ language: allLanguages[index] }));
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const getLink = () => {
    if (noHeaderLogoLink) {
      return undefined;
    }
    if (location.pathname === '/nonexistent-instance') {
      return getInstancePageUrl(SUBDOMAIN_FOR_DEFAULT_COMPANY_ID);
    }
    return '/';
  };

  const getLogoSize = () => {
    if (isCoachHub) {
      return 'coachhub';
    }
    if (isXs) {
      return 'M';
    }
    return 'L';
  };


  // RENDER: StandaloneHeader
  return (
    <div className={classNames(styles.standaloneHeader)}>
      <div className={styles.left}>
        <Logo
          size={getLogoSize()}
          compensatePadding
          link={getLink()}
          srcOverride={isCoachHub
            ? coachHubLogo
            : undefined}
        />
      </div>
      <div className={styles.right}>
        <DropDownSubtle
          className='alignRight'
          options={languageOptions}
          activeIndex={allLanguages.indexOf(currentLanguage)}
          onChange={(index) => {
            handleLanguageChange(index);
          }}
        >
          { translate('langswitch_popover_label') }
        </DropDownSubtle>
      </div>
    </div>
  );
};

export default StandaloneHeader;
