import { createSelector, createSlice } from '@reduxjs/toolkit';
import { apiNext } from 'apiNext';
import { sortAlphabetically } from 'utils/strings';

export const selectStaticValues = (state) => state.staticValues;
export const selectCountries = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.countries,
);
export const selectNationalities = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.nationalities,
);
export const selectEducation = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.education,
);
export const selectIndustries = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.industries,
);
export const selectCareerLevels = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.careerLevels,
);
export const selectJobFamilies = createSelector(
  selectStaticValues,
  (staticValues) => staticValues.jobFamilies,
);

export const fetchStaticValues = (forceReFetching) => async (dispatch, getState) => {
  const {
    countries, nationalities,
    education, industries,
    careerLevels, jobFamilies,
  } = selectStaticValues(getState());

  if (countries.length && nationalities.length
    && education.length && industries.length
    && careerLevels.length && jobFamilies.length
    && !forceReFetching
  ) {
    return;
  }

  Promise.all([
    apiNext.get('core/config/profile/countries'),
    apiNext.get('core/config/profile/education'),
    apiNext.get('core/config/company/industries'),
    apiNext.get('core/config/profile/careerLevels'),
    apiNext.get('core/config/job/families'),
  ])
  .then(([
    countriesInternal,
    educationInternal,
    industriesInternal,
    careerLevelsInternal,
    jobFamiliesInternal ]) => dispatch(staticValuesReceived({
    countries: countriesInternal.content?.map((countryItem) => ({
      value: countryItem.id, label: countryItem.country,
    }))
    .sort((a, b) => sortAlphabetically(a.label, b.label)),

    nationalities: countriesInternal.content?.map((countryItem) => ({
      value: countryItem.id, label: countryItem.nationality,
    }))
    .sort((a, b) => sortAlphabetically(a.label, b.label)),

    education: educationInternal.content?.map((educationItem) => ({
      value: educationItem.id, label: educationItem.name,
    })),
    industries: industriesInternal.content?.map((industryItem) => ({
      value: industryItem.id, label: industryItem.name,
    })),
    careerLevels: careerLevelsInternal.content?.map((careerLevelItem) => ({
      value: careerLevelItem.id, label: careerLevelItem.name,
    })),
    jobFamilies: jobFamiliesInternal.content?.map((jobFamilyItem) => ({
      value: jobFamilyItem.id, label: jobFamilyItem.name,
    })),
  })))
  .catch((error) => {
    console.error(error.message);
  });
};

export const staticValuesSlice = createSlice({
  name: 'staticValues',
  initialState: {
    countries: [],
    nationalities: [],
    education: [],
    industries: [],
    careerLevels: [],
    jobFamilies: [],
  },
  reducers: {
    staticValuesReceived: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const {
        countries, nationalities, education, industries, careerLevels, jobFamilies,
      } = action.payload;

      state.countries = countries;
      state.nationalities = nationalities;
      state.education = education;
      state.industries = industries;
      state.careerLevels = careerLevels;
      state.jobFamilies = jobFamilies;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  staticValuesReceived,
} = staticValuesSlice.actions;

export default staticValuesSlice.reducer;
