import { call, put, takeEvery } from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/staffing';
import * as fromActions from 'store/actions/staffing';
import * as fromTeamOverviewActions from 'store/actions/employeeOverview';
import { _delete, get, post } from 'api';


function* getStaffingEmployee({ payload }) {
  try {
    const { roleId, userId } = payload;

    const { status, ok, data } = yield call(get, `/rolemapping/users/${userId}/matches/${roleId}`);

    if (ok && status === 200) {
      yield put(fromActions.getStaffingEmployeeFulfilled({ employee: data }));
    } else {
      yield put(fromActions.getStaffingEmployeeRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.getStaffingEmployeeRejected({ error }));
  }
}

function* setUserRole({ payload }) {
  try {
    const { userId, roleId } = payload;

    const { status, ok, data } = yield call(post, `/rolemapping/users/${userId}`, { role: roleId });

    if (ok && status === 200) {
      yield put(fromActions.setUserRoleFulfilled());
      yield put(fromTeamOverviewActions.getEmployee(userId));
    } else {
      yield put(fromActions.setUserRoleRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.setUserRoleRejected({ error }));
  }
}

function* deleteUserRole({ payload }) {
  try {
    const { userId, roleId } = payload;

    const { status, ok, data } = yield call(_delete, `/rolemapping/users/${userId}/${roleId}`);

    if (ok && status === 200) {
      yield put(fromActions.deleteUserRoleFulfilled());
      yield put(fromTeamOverviewActions.getEmployee(userId));
    } else {
      yield put(fromActions.deleteUserRoleRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.deleteUserRoleRejected({ error }));
  }
}

export function* watchGetStaffingEmployee() {
  yield takeEvery(fromActionTypes.GET_STAFFING_EMPLOYEE, getStaffingEmployee);
}

export function* watchSetUserRole() {
  yield takeEvery(fromActionTypes.SET_USER_ROLE, setUserRole);
}

export function* watchDeleteUserRole() {
  yield takeEvery(fromActionTypes.DELETE_USER_ROLE, deleteUserRole);
}
