// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CoachHubHeader.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';
import coachHubLogo from './coachHub.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSubtle } from 'ui/basic';
import { Logo } from 'features/framework/components/MainLayout/Logo';

// UTILS
import { apiNext } from 'apiNext';
import { useBreakpoint } from 'utils/hooks';
import { useTranslate } from 'utils/translator';
import { getStoredReturnUrl, returnToUrl } from 'features/+coachHub/utils/returnUrl';
import * as localStorage from 'utils/localStorage';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, listAssessments } from 'store/actions';
import { selectIsCoachHub } from 'store/selectors/configuration';

// CONFIG & DATA
// const Config = {};

// COMPONENT: CoachHubHeader
const CoachHubHeader = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const { bpWidth, bps } = useBreakpoint();
  const translate = useTranslate();

  const allLanguages = useSelector((state) => state.localisation?.languages || []);
  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);
  const isCoachHub = useSelector(selectIsCoachHub);

  const [ languageOptions, setLanguageOptions ] = useState([]);
  useEffect(() => {
    if (languageOptions.length) {
      return;
    }

    const languageOptionsInternal = allLanguages.map((languageId) => ({
      value: languageId,
      label: translate(`langswitch_menu_${languageId}`),
    }));

    setLanguageOptions(languageOptionsInternal);
  }, [ translate, allLanguages, languageOptions ]);

  // SECOND LEVEL: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // BACK TO COACHHUB: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const Arrow = IconsSvg.ArrowRight;
  const renderToCoachHub = () => (
    <div
      role='presentation'
      className={styles.backToCoachHub}
      onClick={() => {
        returnToUrl({ getParams: { status: 'manual_exit' } });
      }}
    >
      <Arrow />
      <span className={classNames('bluTypeCopy')}>
        { isCoachHub
          ? translate('back_to_coachhub')
          : translate('back_to_external_system') }
      </span>
    </div>
  );

  const handleLanguageChange = (index) => {
    localStorage.setItem('overrideLanguage', true);
    dispatch(changeLanguage({ language: allLanguages[index] }));
    dispatch(listAssessments());
  };

  // RENDER: CoachHubHeader
  return (
    <div className={classNames(styles.coachHubHeader)}>
      <div className={styles.firstLevel}>
        <div className={styles.left}>
          <Logo
            size='coachHub'
            compensatePadding
            srcOverride={isCoachHub ? coachHubLogo : undefined}
            link={apiNext.mergeGetParams(getStoredReturnUrl(), { status: 'manual_exit' })}
          />
        </div>
        <div className={styles.right}>
          { (bpWidth > bps.Xs.bpWidth) && renderToCoachHub() }

          <DropDownSubtle
            className='alignRight'
            options={languageOptions}
            activeIndex={allLanguages.indexOf(currentLanguage)}
            onChange={(index) => {
              handleLanguageChange(index);
            }}
          >
            { translate('langswitch_popover_label') }
          </DropDownSubtle>

        </div>
      </div>
      { (bpWidth <= bps.Xs.bpWidth) && (
        <div className={styles.secondLevel}>
          { renderToCoachHub() }
        </div>
      ) }
    </div>
  );
};

export default CoachHubHeader;
