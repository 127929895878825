import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import styles from './TeamCompetenciesResult.module.scss';

import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { normalizeText } from 'utils/normalizeText';
import { useWindowWidth, useDebounce, useBreakpoint } from 'utils/hooks';
import { filterHiddenTeamResults } from 'utils/assessment';

import CompetenciesResultSkeleton from 'ui/molecules/CompetenciesResult/CompetenciesResultSkeleton';
import {
  getResultInfoDescription, getResultInfoTitle, getTranslationIds,
} from 'ui/molecules/CompetenciesResult/translationIds';
import {
  Card, InfoTitle, Modal, PillGroup,
} from 'ui/basic';
import { SteppedScaleResultItem } from '../SteppedScaleResultItem';

import {LayoutContext} from 'features/framework/components/MainLayout/MainLayout';


const TeamCompetenciesResult = memo((props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.KEY_COMPETENCIES,
    profile,
    referenceProfile,
    showAverage = true
  } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const resultsFiltered = useMemo(() => (
    filterHiddenTeamResults(profile?.results)
  ), [ profile?.results ]);

  const translationIds = getTranslationIds(assessmentType);

  const [rowsNode, setRowsNode] = useState();
  const [rowsWidth, setRowsWidth] = useState();

  const [infoPageIsVisible, setInfoPageIsVisible] = useState(false);
  const [infoPageTitle, setInfoPageTitle] = useState();
  const [infoPageDescription, setInfoPageDescription] = useState();
  const [infoPageButtonTitle, setInfoPageButtonTitle] = useState();

  const [currentDimension, setCurrentDimension] = useState();
  const [assessmentDimensionResults, setAssessmentDimensionResults] = useState();

  const rowsRef = useCallback(node => {
    if (node) {
      setRowsNode(node);
    }
  }, []);

  // EXTERNAL EFFECTS
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  useEffect(() => {
    if (rowsNode) {
      setTimeout(() => {
        const smallScreenWidth = rowsNode.clientWidth;
        const largeScreenWidth = rowsNode.clientWidth / 2;

        if (bp.isXs || bp.isS) {
          setRowsWidth(smallScreenWidth);
          return;
        }

        setRowsWidth(largeScreenWidth);
      }, 500);
    }
  }, [debouncedWindowWidth, rowsNode, mainNavIsOpenDebounced, bp]);

  useEffect(() => {
    if (!resultsFiltered) {
      return;
    }

    setCurrentDimension({ id: resultsFiltered[0].id, name: resultsFiltered[0].name });
  }, [ resultsFiltered ]);

  useEffect(() => {
    if (!resultsFiltered || !currentDimension) {
      return;
    }

    setAssessmentDimensionResults(resultsFiltered.find((r) => r.id === currentDimension.id));
  }, [ resultsFiltered, currentDimension ]);

  const [data, setData] = useState();
  useEffect(() => {
    if (!resultsFiltered || !currentDimension) {
      return;
    }

    const thisDimension = resultsFiltered.find((r) => r.id === currentDimension.id);
    if (!thisDimension) {
      return;
    }

    const mappedData = thisDimension.values.map(valueItem => {
      let dimensionReferenceValue;

      if (referenceProfile) {
        if (referenceProfile.results.find(r => r.dimensionId === thisDimension.id)) {
          dimensionReferenceValue = referenceProfile.results.find(r => r.dimensionId === thisDimension.id).minValue;
        } else {
          dimensionReferenceValue = referenceProfile.results
          .reduce((total, value) => total + value, 0) / referenceProfile.results.length;
        }
      }

      return {
        id: thisDimension.id,
        name: thisDimension.name,
        result: valueItem.value,
        referenceValue: dimensionReferenceValue,
        user: {
          id: valueItem.user.id,
          name: valueItem.user.name
        },
        subDimensions: thisDimension.subDimensions.map(subDimension => {
          let subDimensionResult;
          const subDimensionUser = subDimension.values.find((vItem) => (
            (vItem.user?.id || vItem.user) === valueItem.user.id
          ));
          if (subDimensionUser) {
            subDimensionResult = subDimensionUser.value;
          }

          let subDimensionReferenceValue;
          if (referenceProfile && referenceProfile.results) {
            subDimensionReferenceValue = referenceProfile.results.find(r => r.dimensionId === subDimension.id);
          }

          return {
            id: subDimension.id,
            name: subDimension.name,
            result: subDimensionResult,
            referenceValue: subDimensionReferenceValue && subDimensionReferenceValue.minValue
          }
        })
      }
    })

    setData(mappedData);
  }, [ resultsFiltered, currentDimension, referenceProfile ]);

  if (!data || data.length === 0) {
    return <CompetenciesResultSkeleton />
  }

  return (
    <Card hasNoPaddings>
      <div className={styles.teamCompetenciesResult}>
        <div className={styles.mainHeader}>
          <InfoTitle
            title={translate(translationIds.resultTitle) || normalizeText(assessmentType)}
            className={styles.infoTitle}
            onClick={() => {
              setInfoPageTitle(translate(translationIds.resultInfoTitle));
              setInfoPageDescription(translate(translationIds.resultInfoDescription));
              setInfoPageButtonTitle(translate(translationIds.resultInfoButton) || translate('okay_lbl'));
              setInfoPageIsVisible(true);
            }}
          />

          <div
            className={styles.dimensions}
            style={{ maxWidth: `${(rowsWidth || 0) * 2}px` }}
          >
            <PillGroup
              pillItems={resultsFiltered.map((rs) => ({ id: rs.id, label: rs.name }))}
              onPillClick={(pillItem) => setCurrentDimension(pillItem)}
            />
          </div>
        </div>

        <div className={styles.rowsParent}>
          <div className={styles.rows} ref={rowsRef}>
            { data.map((dimensionResult) => (
              <SteppedScaleResultItem
                key={dimensionResult.id}
                data={dimensionResult}
                onInfoClick={(facetIndex) => {
                  const dimIndex = resultsFiltered?.findIndex((r) => r.id === currentDimension.id);

                  setInfoPageTitle(translate(getResultInfoTitle(assessmentType, dimIndex + 1, facetIndex)));
                  setInfoPageDescription(translate(getResultInfoDescription(assessmentType, dimIndex + 1, facetIndex)));
                  setInfoPageButtonTitle(translate('okay_lbl'));
                  setInfoPageIsVisible(true);
                }}
              />
            )) }
          </div>
        </div>

        { showAverage && (
          <div className={styles.bottomText}>
            { translate('team_profile_average_title') }
            <span>{ assessmentDimensionResults ? ` ${assessmentDimensionResults.average}` : ' 0' }</span>
          </div>
        ) }
      </div>

      { infoPageIsVisible && (
        <Modal
          header={infoPageTitle}
          secondaryButtonTitle={infoPageButtonTitle}
          onClose={() => setInfoPageIsVisible(false)}
        >
          { infoPageDescription }
        </Modal>
      ) }
    </Card>
  )
});

export default TeamCompetenciesResult;
