// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './CompleteProfileNext.module.scss';

// ASSETS

// 3RD PARTY
import { useHistory } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import { DialogSequence } from 'ui/molecules/DialogSequence';
import {
  QuestionProfileCompleted,
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionProfileCompleted';
import { StoreNextTranslations } from 'ui/basic/utils/StoreNextTranslations';

// UTILS
import * as api from 'api';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserGroup,
  selectUserId,
  selectCompany,
  setCurrentUserData,
} from 'features/framework/storeNext/configurationSlice';
import { fetchStaticValues } from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
import { profileCompletionConfig } from 'ui/molecules/DialogSequence/config';

const Config = {
  waitAtEnd: 1500,
};

// COMPONENT: CompleteProfileNext
const CompleteProfileNext = () => {
  // PROPS
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(selectUserId);
  const userGroup = useSelector(selectUserGroup);

  const company = useSelector(selectCompany);

  const [ userIdInternal, setUserIdInternal ] = useState();
  // IMPORTANT: set this via state
  // otherwise some store updates will trigger an update for userId
  // and rerender and reset DialogSequence
  useEffect(() => {
    if (userId && userId !== userIdInternal) {
      setUserIdInternal(userId);
    }
  }, [ userId, userIdInternal ]);

  // PAGES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // Filter out pages depending on user profile
  let profile = userGroup;
  if (profile === 'assessmentManager') {
    profile = 'employee';
  }
  let pages = [];
  if (company.custom) {
    const profileSettings = company.custom.profileSettings?.[profile] ?? {};
    pages = profileCompletionConfig.pages.filter(({ id }) => profileSettings[id] !== false);
  }

  const [ staticValuesRequested, setStaticValuesRequested ] = useState(false);
  useEffect(() => {
    if (staticValuesRequested) {
      return;
    }

    setStaticValuesRequested(true);
    dispatch(fetchStaticValues());
  }, [ dispatch, staticValuesRequested ]);

  const [ finalRequestSubmitted, setFinalRequestSubmitted ] = useState(false);
  const [ finalRequestSuccessful, setFinalRequestSuccessful ] = useState(false);
  useEffect(() => {
    if (finalRequestSuccessful) {
      // page reload is needed for fetching the new data
      // instead of setting it from new store to old store
      // temp solution for migration phase
      window.location.href = '/';

      // timeout to let StoreNext & QuestionProfileCompleted perform
      // setTimeout(() => {
      // history.push('/');
      // }, Config.waitAtEnd);
    }
  }, [ history, finalRequestSuccessful ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // HANDLERS
  const handleAnswer = () => {
    api.get('core/user/heartbeat');
  };

  const getPayload = (answers) => {
    // PAYLOAD
    const payload = {
      firstName: answers.username.firstName,
      lastName: answers.username.lastName,
    };
    payload.companyEntryDate = answers.inCompanySince?.inCompanySinceTimestamp;

    // PROFILE DATA
    const profileData = {};
    profileData.country = answers.location?.country;

    profileData.city = answers.location?.city;
    if (answers.nationality?.value) {
      profileData.nationality = [ answers.nationality.value ];
    }

    profileData.gender = answers.gender?.value;

    if (answers.age?.value) {
      profileData.yearOfBirth = Number(answers.age.value);
    }

    profileData.education = answers.schoolDegree?.value;
    profileData.careerLevel = answers.careerLevel?.value;
    profileData.currentPosition = answers.position?.value;
    profileData.currentJobFamily = answers.jobFamily?.value;
    profileData.industryExperience = answers.experience?.value.map((answer) => ({
      industry: answer.name,
      experience: answer.experience,
    }));

    payload.profileData = profileData;
    payload.settings = { custom: { profileCompleted: true } };

    return payload;
  };

  const handleAllAnswers = (answers, setFinishable, setErrorEnd) => {
    if (finalRequestSubmitted) {
      return;
    }

    const payload = getPayload(answers);

    setFinalRequestSubmitted(true);
    api.post('core/user/update', payload)
    .then(({ ok, status }) => {
      if (ok && status === 200) {
        const params = new URLSearchParams();
        params.append('expand', 'roles');
        params.append('expand', 'organizationalRoles');
        params.append('include', 'settings');

        api.get('core/user/info', params)
        .then((userResponse) => {
          if (userResponse.ok) {
            dispatch(setCurrentUserData(userResponse.data));

            // give user request some time, since profileCompleted update
            // will trigger a new page load
            setTimeout(() => {
              setFinalRequestSuccessful(true);
              setFinishable(true);
            }, Config.waitAtEnd);
          }
        });
      }
    })
    .catch((error) => {
      console.error(error.message);
      setErrorEnd(error.message);
    });
  };

  if (pages.length === 0) {
    return null;
  }

  // RENDER: CandidateCompleteProfile
  return (
    <div className={classNames(styles.completeProfileNext)}>
      { /* this component is used for updating the translations in old store from new store;
          this is a temporary solution until we migrate the data completely;
      */ }
      <StoreNextTranslations />

      { finalRequestSuccessful && (
        <QuestionProfileCompleted />
      ) }

      <DialogSequence
        type='profileCompletion'
        userId={userIdInternal}
        configOverride={{ pages }}
        showLanguageToggle
        onAnswer={handleAnswer}
        onAllAnswers={handleAllAnswers}
        onFinish={() => {}}
        onCancel={() => {}}
        onError={() => {}}
      />
    </div>
  );
};

export default CompleteProfileNext;
