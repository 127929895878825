export const GET_USER_PROFILE_PICTURE = 'GET_USER_PROFILE_PICTURE';
export const GET_USER_PROFILE_PICTURE_PENDING = 'GET_USER_PROFILE_PICTURE_PENDING';
export const GET_USER_PROFILE_PICTURE_FULFILLED = 'GET_USER_PROFILE_PICTURE_FULFILLED';
export const GET_USER_PROFILE_PICTURE_REJECTED = 'GET_USER_PROFILE_PICTURE_REJECTED';

export const getUserProfilePicture = () => ({
  type: GET_USER_PROFILE_PICTURE,
  payload: {},
});

export const getUserProfilePictureFulfilled = (user) => ({
  type: GET_USER_PROFILE_PICTURE_FULFILLED,
  payload: user,
});
export const getUserProfilePictureRejected = ({ error }) => ({
  type: GET_USER_PROFILE_PICTURE_REJECTED,
  payload: { error },
});

