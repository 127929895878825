import { get, post } from 'api';
import * as action from 'store/actions';
import {
  call, put, take, takeEvery,
} from 'redux-saga/effects';

function* updateCurrentUser(userDto) {
  try {
    yield put(action.loading());

    const { status, ok, data } = yield call(
      post,
      'core/user/update',
      userDto,
    );

    if (ok && status === 200) {
      yield put(action.updateCurrentUserFulfilled());
      yield put(action.getCurrentUser());
    } else {
      yield put(action.updateCurrentUserRejected(data));
      yield put(action.loadingFulFilled());
    }
  } catch (error) {
    yield put(action.updateCurrentUserRejected(error));
    yield put(action.loadingFulFilled());
  }
}

function* prolongSession() {
  yield call(get, 'core/user/heartbeat');
}

export default function* watchGetUserRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.UPDATE_CURRENT_USER);
      yield call(updateCurrentUser, payload);
    } catch (error) {
      yield put(action.updateCurrentUserRejected(error));
    }
  }
}

export function* watchProlongSession() {
  yield takeEvery(action.PROLONG_SESSION, prolongSession);
}
