import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { getLocal } from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectInstanceIsCoachHub } from 'features/framework/storeNext/configurationSlice';
import styles from './Footer.module.scss';

const Footer = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  // STORE NEXT
  const isCoachHub = useSelector(selectInstanceIsCoachHub);

  const [ versionInfo, setVersionInfo ] = useState();
  const [ showBuildInfo, setShowBuildInfo ] = useState(false);

  useEffect(() => {
    const initialize = () => {
      getLocal('/version.json').then((result) => {
        const { data } = result;
        setVersionInfo(data);
      });
    };
    initialize();
  }, []);

  const handleVersionClick = () => {
    setShowBuildInfo((state) => !state);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        { translate('foo_item_copyright', [ '© bluquist. ', isCoachHub ? '' : '© bluquist. ' ]) }
        { versionInfo && (
          <>
            <span
              onClick={handleVersionClick}
              role='presentation'
              data-test='BluquistVersion'
            >
              { ` Version ${versionInfo.version}.` }
            </span>
            { showBuildInfo && (
              <span>
                { ` Build ${versionInfo.shortBuild} ${versionInfo.buildDetail}.` }
              </span>
            ) }
          </>
        ) }
      </div>
      <div className={styles.right}>
        <Link to='/legal/provider'>{ translate('foo_item_provider') }</Link>
        <Link to='/legal/data-protection-privacy'>
          { translate('foo_item_privacy') }
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
