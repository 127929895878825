import { get, post } from 'api';
import * as action from 'store/actions';
import { call, put, takeLeading } from 'redux-saga/effects';


const getParams = (payload) => {
  const { userId, resultId } = payload;
  if (userId || resultId) {
    return {
      params: {
        result_id: resultId,
        user: userId,
      },
    };
  }
  return undefined;
};


export function* getAssessmentReportNotes({ payload }) {
  const { id, userId } = payload;

  try {
    const { status, ok, data } = yield call(
      get,
      `/core/assessments/${id}/notes`,
      undefined,
      undefined,
      getParams(payload),
    );

    if (ok && status === 200) {
      const reportNotes = data ?? {};
      if (userId) {
        yield put(action.getProfileReportNotesFulfilled({ ...payload, reportNotes }));
      } else {
        yield put(action.getAssessmentReportNotesFulfilled({ id, reportNotes }));
      }
      return;
    }

    yield put(action.getAssessmentReportNotesRejected({ ...payload, error: data.error }));
  } catch (error) {
    yield put(action.getAssessmentReportNotesRejected({ ...payload, error }));
  }
}

export function* setAssessmentReportNotes({ payload }) {
  const { id, body } = payload;

  try {
    const { status, ok, data } = yield call(
      post,
      `/core/assessments/${id}/notes`,
      body,
      undefined,
      getParams(payload),
    );

    if (ok && status === 200) {
      yield put(action.getAssessmentReportNotes(payload));
    }

    yield put(action.getAssessmentReportNotesRejected({ ...payload, error: data.error }));
  } catch (error) {
    yield put(action.getAssessmentReportNotesRejected({ ...payload, error }));
  }
}

export function* watchGetAssessmentReportNotesRequest() {
  yield takeLeading(action.GET_ASSESSMENT_REPORT_NOTES, getAssessmentReportNotes);
}

export function* watchSetAssessmentReportNotesRequest() {
  yield takeLeading(action.SET_ASSESSMENT_REPORT_NOTES, setAssessmentReportNotes);
}
