// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Pill.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { capitalise } from 'utils/textTools';

// CONFIG & DATA

// COMPONENT: Pill
const Pill = (props) => {
  // PROPS
  const {
    children,
    active,
    looks,
    color,
    onClick,
  } = props;

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: Pill
  const pillProps = {
    onClick,
  };

  const [ pressed, setPressed ] = useState(false);
  const handleMouseDown = () => {
    setPressed(true);
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  return (
    <div
      className={`${classnames(styles.pill, styles[looks], {
        [styles.hasColorIndicator]: Boolean(color),
        [styles.active]: active,
        [styles.pressed]: pressed,
      })}`}
      role='presentation'
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      {...pillProps}
    >
      { children }

      { color && (
        <div
          className={styles.rectangle}
          style={{
            backgroundColor: styles[`color${capitalise(color)}`],
          }}
        />
      ) }
    </div>
  );
};

export default Pill;
