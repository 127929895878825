import React, { useEffect, useState } from 'react';
import styles from './SteppedScaleResultItem.module.scss';

import * as api from 'api';

import classNames from 'classnames';

import {
  SegmentedLineDiagram, InfoTitle, CollapsiblePanel, ImgCircle,
} from 'ui/basic';

import { setDecimalSeparator } from 'utils/userpreference';
import { isValid } from 'utils/numbers';


// COMPONENT: SteppedScaleResultItem
const SteppedScaleResultItem = (props) => {
  // PROPS
  const {
    data,
    segmentsCount,
    isExpandable = true,
    onInfoClick = () => {},
  } = props;

  const [ mouseButtonPressed, setMouseButtonPressed ] = useState();

  // DIMENSION REFERENCE VALUE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ referenceValueInternal, setReferenceValueInternal ] = useState();
  useEffect(() => {
    if (!data) {
      return;
    }

    if (isValid(data.referenceValue)) {
      setReferenceValueInternal(data.referenceValue);
    } else {
      // if each sub dimension has a reference value, total reference value can be calculated
      if (!data.subDimensions || !data.subDimensions.every((subDimension) => isValid(subDimension.referenceValue))) {
        return;
      }

      const subDimensionsReferenceValues = data.subDimensions
      .map((subDimension) => subDimension.referenceValue);
      const totalReferenceValue = subDimensionsReferenceValues
      .reduce((total, value) => total + value, 0) / subDimensionsReferenceValues.length;

      if (isValid(totalReferenceValue)) {
        setReferenceValueInternal(Number(Number(totalReferenceValue).toFixed(2)));
      }
    }
  }, [ data ]);

  // DIMENSION RESULT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ resultInternal, setResultInternal ] = useState();
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.result) {
      setResultInternal(data.result);
    } else {
      // if each sub dimension has a result, total result can be calculated
      if (!data.subDimensions || !data.subDimensions.every((subDimension) => isValid(subDimension.result))) {
        return;
      }

      const subDimensionsResults = data.subDimensions.map((subDimension) => subDimension.result);
      const totalResult = subDimensionsResults.reduce((total, value) => total + value, 0) / subDimensionsResults.length;

      setResultInternal(Number(Number(totalResult).toFixed(2)));
    }
  }, [ data ]);


  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.left}>
        <SegmentedLineDiagram
          score={resultInternal}
          segmentsCount={segmentsCount}
          referenceValue={referenceValueInternal}
        />
      </div>
      <div className={styles.right}>
        <span className={classNames(
          styles.points,
          { [styles.big]: referenceValueInternal && resultInternal && !data.user },
        )}
        >
          { (referenceValueInternal && resultInternal) && (
            data.user ? (
              <span>
                { setDecimalSeparator(resultInternal) }
              </span>
            ) : (
              <span>
                { `${setDecimalSeparator(resultInternal)} / ${setDecimalSeparator(referenceValueInternal)}` }
              </span>
            )
          ) }

          { (resultInternal && !referenceValueInternal)
          && <span>{ setDecimalSeparator(resultInternal) }</span> }

          { (referenceValueInternal && !resultInternal)
          && <span>{ setDecimalSeparator(referenceValueInternal) }</span> }
        </span>

        { data.user ? (
          <div className={styles.user}>
            <ImgCircle
              size='Xs'
              src={api.getUserImageUrl(data.user.id)}
              label1={data.user.name?.split(' ')[0]}
              label2={data.user.name?.split(' ')[1]}
            />
            <span className={styles.name}>{ data.user.name }</span>
          </div>
        ) : (
          <span className={styles.name}>
            { data.name }
          </span>
        ) }
      </div>
    </div>
  );

  const renderHeaderWithNoSubDimensions = () => (
    <div
      role='presentation'
      className={styles.headerWithNoSubDimensions}
      onClick={() => onInfoClick(-1, data)}
    >
      <div className={styles.left}>
        <SegmentedLineDiagram
          score={resultInternal}
          segmentsCount={segmentsCount}
          referenceValue={referenceValueInternal}
        />
      </div>
      <div className={styles.right}>
        { data.user ? (
          <div className={styles.user}>
            <ImgCircle
              size='Xs'
              src={api.getUserImageUrl(data.user.id)}
              label1={data.user.name.split(' ')[0]}
              label2={data.user.name.split(' ')[1]}
            />
            <span className={styles.name}>
              { data.user.name }
            </span>
          </div>
        ) : (
          <InfoTitle
            title={data.name}
            type='regular'
            className={styles.infoTitle}
            fullWidth
            onClick={() => onInfoClick(-1, data)}
          />
        ) }
      </div>
    </div>
  );


  return (
    <div className={styles.steppedScaleResultItem}>
      { /* NO SUB DIMENSIONS */ }
      { (!data.subDimensions || data.subDimensions.length === 0) && renderHeaderWithNoSubDimensions() }

      { /* WITH SUB DIMENSIONS */ }
      { (data.subDimensions && data.subDimensions.length > 0) && (
        <CollapsiblePanel
          header={renderHeader()}
          headerClassName={classNames(
            styles.collapsibleHeader,
            { [styles.active]: mouseButtonPressed },
          )}
          isExpandable={isExpandable}
          showTopBorder={false}
          onMouseDown={() => setMouseButtonPressed(true)}
          onMouseUp={() => setMouseButtonPressed(false)}
        >
          <div className={styles.body}>
            { data.subDimensions.map((subDimension, subDimensionIndex) => (
              <div
                key={`facet-${subDimension.id}`}
                role='button'
                tabIndex='0'
                className={styles.subItemRow}
                onClick={() => onInfoClick(subDimensionIndex + 1, subDimension)}
                onKeyPress={() => onInfoClick(subDimensionIndex + 1, subDimension)}
              >
                <SegmentedLineDiagram
                  score={subDimension.result}
                  segmentsCount={segmentsCount}
                  referenceValue={subDimension.referenceValue}
                />
                <div className={styles.right}>
                  <InfoTitle
                    className={styles.infoTitle}
                    title={subDimension.name}
                    fullWidth
                    type='regular'
                    onClick={() => onInfoClick(subDimensionIndex + 1, subDimension)}
                  />
                </div>
              </div>
            )) }
          </div>
        </CollapsiblePanel>
      ) }

    </div>
  );
};

export default SteppedScaleResultItem;
