// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PricingTable.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { SkeletonFlexible } from 'ui/basic/micro-ui/SkeletonFlexible';

// UTILS
// import { useTranslate } from 'utils/translator';
import { getPriceFromCents } from 'features/+adminUG/config/payment.config';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: PricingTable
const PricingTable = (props) => {
  // PROPS
  const {
    list = [],
    currency = '', // iso e.g 'usd', 'eur'
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: PricingTableRow
  const renderPricingTableRow = ({
    bold, description, quantity, amount,
  }, index) => (
    <React.Fragment key={index}>
      <div className={classNames(styles.rowDescription, { [styles.rowBold]: bold })}>
        { description }
      </div>
      <div className={classNames(styles.rowCount, { [styles.rowBold]: bold })}>
        { quantity }
      </div>
      <div className={classNames(styles.rowPrice, { [styles.rowBold]: bold })}>
        { typeof amount === 'number'
          ? getPriceFromCents(amount, currency)
          : amount }
      </div>
    </React.Fragment>
  );

  if (!list?.length) {
    return (
      <div className={classNames(styles.pricingTableLoading)}>
        <SkeletonFlexible
          noHeader
          repeat={7}
        />
      </div>
    );
  }

  // RENDER: PricingTable
  return (
    <div className={classNames(styles.pricingTable)}>
      { list.map(renderPricingTableRow) }
    </div>
  );
};

export default PricingTable;
