import { apiNext } from 'apiNext';
import { getCoachHubParam, deleteCoachHubParams } from './localStorage';

// CONFIG & DATA
import { EXTERNAL_WEBSITE_FALLBACK } from 'utils/configuration';
import REGEXES from 'utils/configuration/const/regexes';

export const Config = {
  returnUrlFallback: EXTERNAL_WEBSITE_FALLBACK,
};

export const getStoredReturnUrl = () => getCoachHubParam('returnUrl') || Config.returnUrlFallback;

/**
 * Return to url by setting window.location.href and adding getParams
 * either with returnUrl or with urlFromStorage
 * We're not deleting returnUrl from storage because users might navigate
 * back in the browser (e.g after cancelling) and then we'd have no valid returnUrl
 * @param {*} param0
 */
let done = false;
export const returnToUrl = ({ returnUrl = '', getParams = {}, urlFromStorage = false }) => {
  if (done) {
    return;
  }
  done = true; // Bar later executions of returnToUrl overriding the first error

  if (urlFromStorage || !REGEXES.DOMAIN_FULL.test(returnUrl)) {
    returnUrl = getStoredReturnUrl();
  }

  const urlWithParams = apiNext.mergeGetParams(returnUrl, getParams);

  // Coachhub prod debugging
  // eslint-disable-next-line no-debugger
  debugger;

  // Wipe out local storage
  deleteCoachHubParams();

  // Redirect to returnUrl
  window.location.href = urlWithParams;
};
