import { translate } from 'utils/translator/translator';

export const getMonths = () => {
  const months = [
    translate('cp_dropdown_month_january') || 'January',
    translate('cp_dropdown_month_february') || 'February',
    translate('cp_dropdown_month_march') || 'March',
    translate('cp_dropdown_month_april') || 'April',
    translate('cp_dropdown_month_may') || 'May',
    translate('cp_dropdown_month_june') || 'June',
    translate('cp_dropdown_month_july') || 'July',
    translate('cp_dropdown_month_august') || 'August',
    translate('cp_dropdown_month_september') || 'September',
    translate('cp_dropdown_month_october') || 'October',
    translate('cp_dropdown_month_november') || 'November',
    translate('cp_dropdown_month_december') || 'December',
  ];

  return months;
};
