import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
// import DiscAssessmentRegistration from '../intermissions/DiscAssessmentRegistration';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import DiscAssessmentEnd from '../intermissions/DiscAssessmentEnd';

// UTILS

// CONFIG

// OTHER COMPONENTS

const assessmentType = 'disc';

const DUMMY_DESCRIPTION = `
  DISC is a simple, practical, easy to remember and universally applicable model. It focuses on individual patterns of external, observable behaviors and measures the intensity of characteristics using scales of directness and openness for each of the four styles: Dominance, Influence, Steadiness, and Conscientious.

Using the DISC model, it is easy to identify and understand our own style, recognize and cognitively adapt to different styles, and develop a process to communicate more effectively with others.

The DISCstyles™ Assessment helps you to improve your day-to-day communication, teamwork and collaboration.
`;

const DUMMY_GUIDANCE = `[blu-markdown]
  **In order to gain valuable insights from this assessment, it is important that you follow the instructions below:**

  - Make sure that you can complete the assessment calmly and without interruptions.
  - Answer honestly so that accurate recommendations can be made about your development.
  - Don’t overthink! Try to answer quickly and intuitively.
  - You can correct your answers if you have made a mistake
  - There is no right or wrong - every person is unique!
  
  
  **Enjoy the assessment!** 
  `;


export const discConfig = {
  id: assessmentType,
  title: 'DISCstyles™',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 60 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  // modalHelpContentTranslationKey: 'big5_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={assessmentType}
          description={DUMMY_DESCRIPTION}
          onClickNext={() => next()}
        />
      ),
    },
    // REGISTRATION
    // {
    //   insertAtIndex: 1,
    //   render: (next, prev, state) => (
    //     <DiscAssessmentRegistration
    //       onClickNext={() => next()}
    //     />
    //   )
    // },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={assessmentType}
          content={DUMMY_GUIDANCE}
          onClickNext={() => next()}
        />
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => <DiscAssessmentEnd {...extras} />,
    },
  ],
};
