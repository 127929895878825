// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './ListItem.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: ListItem
const ListItem = (props) => {
  // PROPS
  const {
    title = '',
    description = '',
    hint = '',
    active = false,
    disabled = false,
    compactView = false,
    onClick = () => {},
  } = props;

  // RENDER: ListItem
  return (
    <div className={classNames(
      styles.listItem,
      { [styles.compactView]: compactView },
    )}
    >
      <div
        className={classNames(
          styles.menuItem,
          { [styles.active]: active },
          { [styles.disabled]: disabled },
        )}
        role='presentation'
        onClick={onClick}
      >
        <div className={styles.title}>
          { title }
        </div>
        <div className={styles.description}>
          { description }
        </div>
      </div>

      { disabled && hint && (
        <div className={styles.hint}>
          { hint }
        </div>
      ) }
    </div>
  );
};

export default ListItem;
