// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Introduction.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: Introduction
const Introduction = ({ title }) => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Introduction
  return (
    <div className={classNames(styles.introduction)}>
      <span className='bluTypeM'>{ translate('ist_report_title') }</span>

      { /* introduction */ }
      <div className='marginTopL'>
        <h2>{ translate('introduction_header_title') }</h2>
        <div className={classNames('bluTypeCopy')}>
          { translate('ist_report_intro_copy', [ '{{assessment}}', title ]) }
        </div>
      </div>
    </div>
  );
};

export default Introduction;
