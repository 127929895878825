// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AssessmentHeader.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// STORE

// CONFIG & DATA

// COMPONENT: AssessmentHeader
const AssessmentHeader = (props) => {
  // PROPS
  const {
    title,
    actionLabel = '',
    onAction = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleClick = () => {
    onAction();
  };

  // HELPERS

  // RENDERS

  // RENDER: AssessmentHeader
  return (
    <div className={classNames(styles.assessmentHeader)}>
      <div className={styles.left}>
        <div className={styles.title}>
          { title }
        </div>
      </div>
      <div className={styles.right}>
        <div
          className={styles.cancel}
          onClick={handleClick}
        >
          { actionLabel }
        </div>
      </div>
    </div>
  );
};

export default AssessmentHeader;
