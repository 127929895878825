// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback } from 'react';

// UTILS
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { HotChipsPeopleWithSearch } from 'ui/basic';

// STORE
import { useSelector } from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';


// COMPONENT: PeerHotChipsPeople
const PeerHotChipsPeople = (props) => {
  const { employeeId } = props;
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const userId = employeeId || me.id;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const mapSearchResults = useCallback(({ users }) => users
  .map((user) => ({
    id: user.id,
    value: user.mail,
    label: getFullName(user),
    subLabel: user.companyData?.profession,
    disabled: user.id === userId,
  })), [ userId ]);

  // RENDER: PeerHotChipsPeople
  return (
    <HotChipsPeopleWithSearch
      placeholder={translate('email_add_lbl')}
      hint={translate('invite_emp_emails_hint')}
      errorHint={translate('invite_emp_emails_hint_invalid')}
      errorHintDuplicate={translate('invite_emp_emails_duplicate_hint')}
      noResultsHint={translate('employees_filter_no_users')}
      searchConfig={{
        entrypoint: '/core/company/users/search',
        mapResults: mapSearchResults,
        extraParams: { expand: 'user' },
      }}
      {...props}
    />
  );
};

export default PeerHotChipsPeople;
