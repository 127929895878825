import { get } from 'api';
import * as action from 'store/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { COMPANY_ID } from 'utils/configuration';


function* getTranslations({ payload: language }) {
  try {
    const { status, ok, data } = yield call(
      get,
      `/core/language/${language}`,
      { company: COMPANY_ID },
    );

    if (ok && status === 200) {
      yield put(action.getTranslationsFulfilled(data));
    } else {
      yield put(action.getTranslationsRejected(data));
    }
  } catch (error) {
    yield put(action.getTranslationsRejected(error));
  }
}

export default function* watchGetLanguageStringsRequest() {
  yield takeLatest(action.GET_TRANSLATIONS, getTranslations);
}
