import * as fromActionTypes from 'store/actionTypes/resetPassword';

export const initRequestResetPassword = () => ({
  type: fromActionTypes.INIT_RESET_PASSWORD_REQUEST,
  payload: { },
});

export const requestResetPassword = (mail, companyId) => ({
  type: fromActionTypes.RESET_PASSWORD_REQUEST,
  payload: { mail, companyId },
});

export const resetPasswordRequestFulFilled = () => ({
  type: fromActionTypes.RESET_PASSWORD_REQUEST_FULFILLED,
});

export const resetPasswordRequestRejected = ({ error }) => ({
  type: fromActionTypes.RESET_PASSWORD_REQUEST_REJECTED,
  payload: { error },
});

export const resetPassword = (password, token) => ({
  type: fromActionTypes.RESET_PASSWORD,
  payload: { password, token },
});

export const resetPasswordFulFilled = () => ({
  type: fromActionTypes.RESET_PASSWORD_FULFILLED,
});

export const resetPasswordRejected = ({ error }) => ({
  type: fromActionTypes.RESET_PASSWORD_REJECTED,
  payload: { error },
});
