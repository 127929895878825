import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import Big5AssessmentEnd from '../intermissions/Big5AssessmentEnd';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// CONFIG

// OTHER COMPONENTS

export const big5Config = {
  id: 'big5',
  title: 'Big 5',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'big5_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.BIG5}
          onClickNext={() => next()}
        />
      ),
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.BIG5}
          onClickNext={() => next()}
        />
      ),
    },
    // BREATHE is set in Big5AssessmentPage
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => <Big5AssessmentEnd {...extras} />,
    },
  ],
};
