// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './RecurringAssessmentControl.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import {
  Button, ButtonCircular, Card, SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { timestampToFullDate } from 'utils/dateTools';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { apiNext } from 'apiNext';
import { useDispatch, useSelector } from 'react-redux';
import { getFullName } from 'utils/users';
import { ASSESSMENT_RECURRENCE_TYPES } from 'utils/configuration/const/assessment-types';
import { getAssessment, getAssessmentHistory } from 'store/actions';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';


const Config = {
  canStartOwnIterationRoles: [ 'admin', 'leader', 'assessmentManager' ],
};

// COMPONENT: RecurringAssessmentControl
const RecurringAssessmentControl = (props) => {
  // PROPS
  const {
    assessment = {},
    historyIndex = 0, // 0 == show most recent result (ignore prev states)
    onHistoryChange = () => {},
    user,
  } = props;

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const [ starting, setStarting ] = useState(false);

  // SPECIAL HOOKS
  const translate = useTranslate();
  const navigationHistory = useHistory();
  const dispatch = useDispatch();

  const LoopIcon = IconsSvg.Loop;
  const {
    id,
    title,
    date,
    recurrenceType,
    locked,
    history,
    completed,
  } = assessment;


  // RENDER: content
  const renderContent = () => {
    if (user) {
      if (recurrenceType === ASSESSMENT_RECURRENCE_TYPES.MANUAL) {
        return (
          <div className={styles.locked}>
            <div className='bluTypeCopy'>
              { translate(
                completed && locked
                  ? `assessment_retake_${recurrenceType}_copy_locked`
                  : `assessment_retake_${recurrenceType}_copy`,
                [
                  '{{assessment}}', title,
                  '{{username}}', getFullName(user),
                ],
              ) }
            </div>
            <div className={styles.lockedButton}>
              <Button
                size='S'
                onClick={async () => {
                  setStarting(true);
                  await apiNext.post(`/core/assessments/${id}/start`, undefined, { user: user.id });
                  dispatch(getAssessment(id));
                }}
                disabled={starting || !(completed && locked)}
              >
                { translate('assessment_retake_start') }
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate(
            locked
              ? 'assessment_retake_run_copy'
              : 'assessment_retake_run_copy_available',
            [
              '{{assessment}}', title,
              '{{username}}', getFullName(user),
            ],
          ) }
        </div>
      );
    }

    if (locked) {
      let message = `assessment_retake_${recurrenceType}_copy`;
      if (recurrenceType === ASSESSMENT_RECURRENCE_TYPES.MANUAL) {
        if (Config.canStartOwnIterationRoles.includes(me.roleNext)) {
          return (
            <div className={styles.locked}>
              <div />
              <div className={styles.lockedButton}>
                <Button
                  size='S'
                  onClick={async () => {
                    setStarting(true);
                    await apiNext.post(`/core/assessments/${id}/start`);
                    dispatch(getAssessment(id));
                  }}
                  disabled={starting || !(completed && locked)}
                >
                  { translate('assessment_retake_start') }
                </Button>
              </div>
            </div>
          );
        }
        message = 'assessment_retake_run_copy';
      }

      return (
        <div className={styles.locked}>
          <div className='bluTypeCopy'>
            { translate(message, [
              '{{assessment}}', title,
              '{{date}}', timestampToFullDate((new Date().valueOf() / 1000) + locked),
            ]) }
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('by_assmnt_next_evaluation_copy', [
            '{{assessment}}', title,
          ]) }
        </div>
        <Button
          size='S'
          onClick={() => navigationHistory.push(`/assessments/${id}`)}
        >
          { translate('mind_journey_teaser_button_start') }
        </Button>
      </>
    );
  };

  // RENDER: RecurringAssessmentControl
  return (
    <div className={classNames(styles.recurringAssessmentControl)}>
      <Card hasPaddingsForModal>
        { !history
          ? <SkeletonFlexible noHeader repeat={2} />
          : (
            <>
              <div className={styles.flexContainer}>
                <div className={styles.textContent}>
                  <span className='bluTypeLabelL'>
                    { translate('results_of', [
                      '{{date}}', timestampToFullDate(history?.results?.[historyIndex]?.date ?? date),
                    ]) }
                  </span>

                  { /* if user is viewing the latest result */ }
                  { historyIndex === 0 && (
                    <div className={styles.latestResultText}>
                      { translate(user ? 'recent_result_text_other' : 'recent_result_text') }
                    </div>
                  ) }

                  { /* if user is viewing the history navigating back */ }
                  { historyIndex > 0 && (
                    <div
                      role='presentation'
                      className={styles.showCurrentResult}
                      onClick={() => onHistoryChange(0)}
                    >
                      <LoopIcon />
                      { translate('show_latest_result') }
                    </div>
                  ) }
                </div>

                <div className={styles.controls}>
                  <ButtonCircular
                    looks='primary'
                    icon='ArrowLeft'
                    disabled={!history?.results[historyIndex + 1]}
                    onClick={() => {
                      onHistoryChange(historyIndex + 1);

                      // Fetch if beyond history index
                      const offset = historyIndex + 2;
                      if (history.assessmentStatistics.totalResults > offset && !history.results[offset]) {
                        dispatch(getAssessmentHistory(id, {
                          offset,
                          userId: user?.id,
                        }));
                      }
                    }}
                  />
                  <ButtonCircular
                    looks='primary'
                    icon='ArrowRight'
                    disabled={historyIndex === 0}
                    onClick={() => onHistoryChange(historyIndex - 1)}
                  />
                </div>
              </div>
              { renderContent() }
            </>
          ) }
      </Card>
    </div>
  );
};

export default RecurringAssessmentControl;
