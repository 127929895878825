export const SET_MENU_ITEM = 'SET_MENU_ITEM';
export const SET_MENU_ITEM_FULFILLED = 'SET_MENU_ITEM_FULFILLED';
export const SET_MENU_ITEM_REJECTED = 'SET_MENU_ITEM_REJECTED';

export const setMenuItem = (menuItem) => ({
  type: SET_MENU_ITEM,
  payload: menuItem,
});
export const setMenuItemFulfilled = (menuItem) => ({
  type: SET_MENU_ITEM_FULFILLED,
  payload: menuItem,
});
export const setMenuItemRejected = (error) => ({
  type: SET_MENU_ITEM_FULFILLED,
  payload: error,
});
