// REACT, STYLE, STORIES & COMPONENT
import React, { useRef, useEffect } from 'react';
import styles from './VerticalFlexWrapContainer.module.scss';
import { debounce } from 'utils/delay';


// COMPONENT: VerticalFlexWrapContainer
const VerticalFlexWrapContainer = (props) => {
  // PROPS
  const {
    children,
    maxColumns = 1, // max amount of columns
    gap = 16, // flex gap in px
    onCalc = () => {},
  } = props;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const ref = useRef();
  const prevCols = useRef();
  const prevHeight = useRef();

  /**
   * Calculate the optimal container height so that all cards are as evenly distributed as possible.
   * This algorithm is a special case of the coding exercise "Split Array Largest Sum",
   * see https://leetcode.com/problems/split-array-largest-sum/.
   *
   * @param {Array[Number]} numbers heights of all cards, ordered by appearance
   * @param {Number} k The amount of flex-wrap columns that should be rendered
   * @param {Number} [margin] The margin between cards
   * @returns {Number} The minimum height required (in px) to optimally distribute the cards
   */
  const splitArray = (numbers, k, margin = 0) => {
    // Helper fn to check whether the array can be split in k subarrays or less
    const canSplit = (limit) => {
      let subarraysCounter = 0;
      let currentSum = 0;

      numbers.forEach((n) => {
        // Count gap between adjacent cards
        if (currentSum > 0) {
          currentSum += margin;
        }
        currentSum += n;
        // When the sum goes beyond the cap, start counting numbers for the next subarray
        if (currentSum > limit) {
          subarraysCounter += 1;
          currentSum = n;
        }
      });

      return subarraysCounter + 1 <= k; // +1 == last subarray left (not counted yet)
    };

    // Binary search between smallest and largest possible response value
    let left = Math.max(...numbers, 0);
    let right = numbers.reduce((acc, el) => acc + el, 0) + numbers.length * margin;
    let res = right;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      if (canSplit(mid)) {
        res = mid;
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return res;
  };

  // Hook to redistribute cards on children update or resize
  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    const observerCb = debounce(() => {
      const heights = Array.from(ref.current.children).map((el) => {
        // clientHeight rounds down subpixel heights which causes card placement overflow on some browsers,
        // so we use getComputedStyle instead. See https://blueexcellence.atlassian.net/browse/BQDQ-1456
        const { height } = getComputedStyle(el);
        return Math.ceil(parseFloat(height));
      });

      // Calculate optimal height and columns amount
      let cols = Math.min(maxColumns, heights.length);
      let height = Infinity;
      for (let i = cols; i > 0; i -= 1) {
        const h = splitArray(heights, i, gap);
        if (h <= height) {
          height = h;
          cols = i;
        }
      }

      // Update state if needed
      if (cols !== prevCols.current || height !== prevHeight.current) {
        ref.current.style.setProperty('--cols', cols);
        ref.current.style.setProperty('--height', `${height}px`);
        ref.current.style.setProperty('--gap', `${gap}px`);
        prevCols.current = cols;
        prevHeight.current = height;
        onCalc(height, cols);
      }
    }, 0);

    const mutationObserver = new MutationObserver(observerCb);
    const resizeObserver = new ResizeObserver(observerCb);
    mutationObserver.observe(ref.current, {
      childList: true,
      subtree: true,
      attributeFilter: [ 'style' ],
    });
    resizeObserver.observe(ref.current);
    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [ onCalc, gap, maxColumns ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: VerticalFlexWrapContainer
  return (
    <div
      ref={ref}
      className={styles.verticalFlexWrapContainer}
    >
      { children }
    </div>
  );
};

export default VerticalFlexWrapContainer;
