import React from 'react';
import styles from './ProfileDeleted.module.scss';

import classNames from 'classnames';

import { useTranslate } from 'utils/translator';


const ProfileDeleted = () => {
  const translate = useTranslate();

  return (
    <div className={styles.profileDeleted}>
      <div className={styles.content}>
        <div className='bluTypeM'>
          { translate('my_profile_deleted_title') || 'Dein Nutzerprofil wurde entfernt.' }
        </div>
        <div className={classNames('bluTypeCopy', 'marginTopS')}>
          { translate('my_profile_deleted_copy') || 'Deine Profil und alle zugehörigen Daten wurden unwiderruflich gelöscht.' }
        </div>
      </div>
    </div>
  );
};

export default ProfileDeleted;
