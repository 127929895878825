// OTHER COMPONENTS
// import { QuestionBubbles } from './components/QuestionBubbles';
// import { QuestionBipolarSlider } from './components/QuestionBipolarSlider';
// import { QuestionSegments } from './components/QuestionSegments';
// import { QuestionSlider } from './components/QuestionSlider';
// import { QuestionDragDrop } from './components/QuestionDragDrop';


// GLOBAL_CONFIG
export const GLOBAL_CONFIG = {
  minPageDuration: 300,
  modalHurryDelay: 0, // disabled
  storageValidityWindow: 24 * 60 * 60 * 1000, // 24 hours
  validDialogTypes: [ // only documentation & used in stories for now
    // Note: could also get from importing config/index.js
    'adminOnboarding',
    'adminPaymentCycleChange',
    'adminProfileCompletion',
    'candidateProfileCompletion',
    'candidateOnboarding',
    'demo',
    'profileCompletion',
    'trialSignup',
    'trialUpgrade',
  ],
  // validQuestionTypes: {
  //   'scale-boxes': {
  //     component: QuestionBubbles,
  //     validRangeTypes: questionBubblesRanges
  //   },
  //   'bipolar-scale-slider': {
  //     component: QuestionBipolarSlider
  //   },
  //   'scale-stepped-slider': {
  //     component: QuestionSegments,
  //     validRangeTypes: { from1Step1To5: {} }
  //   },
  //   'scale-slider': {
  //     component: QuestionSlider,
  //   },
  //   'multiple-choice-dragdrop': {
  //     component: QuestionDragDrop,
  //   },
  //   'invalid-type': { // keep this for logic.tests of validateQuestions
  //     component: null,
  //   }
  // },
};
