import React, { useState, useEffect } from 'react';

// 3RD PARTY
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import { AssessmentNext } from 'ui/molecules/AssessmentNext';


// UTILS
import { useTranslate } from 'utils/translator';
import {
  handleAssessmentAnswer,
  initialiseAssessment,
} from 'utils/assessment';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';


// CONFIG
import { getCompetencyName, getCompetencyDescription } from './KeyCompetenciesAssessmentPage.translations';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';


const LeadershipCompetenciesAssessmentPage = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  // STORE
  const userId = useSelector(fromCurrentUserSelectors.getCurrentUserId);

  // GET ASSESSMENT DATA
  const assessmentId = ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES;
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();


  useEffect(() => {
    if (!userId || !assessmentId || loadingAssessment) {
      return;
    }

    setLoadingAssessment(true);

    initialiseAssessment({
      userId,
      assessmentId,
      handleExit: () => {
        history.push('/');
      },
    })
    .then(({ questions, assessment, prevAnswers }) => {
      setConfigOverride({
        title: assessment.title,
        description: assessment.description,

        allowBackNavigation: assessment.free_navigation,
        canContinueLater: assessment.multi_session,
        // modalHurryDelay: 5 * 1000,
        // modalHurryDelay: assessment.time_to_reminder * 1000,

        // // modalHelpContent: '[blu-markdown] **modal help content**',
        // // guidanceContent: '[blu-markdown] **guidance content**',
        // modalHelpContent: assessment.info_texts.question_help,
        // guidanceContent: assessment.info_texts.guidance_text,

        progress: assessment.progress,
        prevAnswers,
      });

      // set translations and custom question.type
      const inflatedQuestions = questions.map((question) => ({
        ...question,
        type: `competencies-${question.type}`,
        questionLabel: translate('competencies_selection_items_guidance_question'),
        questionDescription: translate(getCompetencyDescription(assessmentId, question.id.split('_').pop())),
        question: translate(getCompetencyName(assessmentId, question.id.split('_').pop())) || question.question,
        useAnswerOptions: false,
      }));

      setAssessmentQuestions(inflatedQuestions);
    });
  }, [
    history,
    translate,
    loadingAssessment,
    userId,
    assessmentId,
    assessmentQuestions,
  ]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    history.goBack();
  };

  const handleFinish = () => {
    history.push('/my-profile');
  };

  const handleAnswer = (answer) => {
    handleAssessmentAnswer(answer, assessmentId);
  };

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);
  };

  return (
    <AssessmentNext
      type={ASSESSMENT_NEXT_TYPES[assessmentId]}
      questions={assessmentQuestions}
      userId={userId}
      configOverride={configOverride}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer} // session prolongation
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default LeadershipCompetenciesAssessmentPage;
