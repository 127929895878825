// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
// import styles from './QuestionOnboardingCompleted.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { setOnboardingCompletedApi } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionOnboardingCompleted
const QuestionOnboardingCompleted = (props) => {
  // PROPS
  // const { children } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();

  // MARK PROFILE COMPLETED
  useEffect(() => {
    dispatch(setOnboardingCompletedApi());
  }, [ dispatch ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionOnboardingCompleted
  return (
    <>
    </>
  );
};

export default QuestionOnboardingCompleted;
