import React, { lazy } from 'react';

const SurveysMain = lazy(() => import('./pages/SurveysMain'));


export const routesConfigSurveys = {
  show: {
    forUserGroup: 'all',
    forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
  },
  routes: [
    {
      path: '/surveys',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'SurveysOverview',
      show: {
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
        forFeature: 'surveys',
      },
      inMainNav: {
        labelKey: 'main_navigation_item_12',
      },
      subRoutes: [
        {
          path: '/surveys/:surveyId/profile',
          PageComponent: () => <SurveysMain />,
          LazyPageComponent: 'SurveyProfile',
          tools: {
            browserTitleKey: 'main_navigation_item_12',
          },
        },
      ],
    },
    {
      path: '/surveys/new',
      pageType: 'modal',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'NewSurvey',
      show: {
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
        forFeature: 'surveys',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/:surveyId/new-run',
      pageType: 'modal',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'NewSurveyRun',
      show: {
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
        forFeature: 'surveys',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/:surveyId/answer',
      pageType: 'modal',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'AnswerSurvey',
      show: {
        forFeature: 'surveys',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/:surveyId/edit',
      pageType: 'modal',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'EditSurvey',
      show: {
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
        forFeature: 'surveys',
      },
      tools: {
        browserTitle: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/:surveyId/copy',
      pageType: 'modal',
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'DuplicateSurvey',
      show: {
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
        forFeature: 'surveys',
      },
      tools: {
        browserTitle: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/:surveyId/external/:invitationId',
      pageType: 'standalone',
      pageWrapper: {
        noHeaderLogoLink: true,
      },
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'ExternalSurveyWrapper',
      show: {
        needsLogin: false,
      },
      tools: {
        browserTitleKey: 'main_navigation_item_12',
      },
    },
    {
      path: '/surveys/ext/:invitationId',
      pageType: 'standalone',
      pageWrapper: {
        noHeaderLogoLink: true,
      },
      PageComponent: () => <SurveysMain />,
      LazyPageComponent: 'ExternalSurveyWrapper',
      show: {
        needsLogin: false,
      },
      tools: {
        browserTitleKey: 'main_navigation_item_12',
      },
    },
  ],
};
