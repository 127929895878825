import { createSelector } from '@reduxjs/toolkit';

const selectLogin = (state) => state.login;

export const selectLoginAuthToken = createSelector(
  selectLogin,
  (login) => login.authToken,
);

export const selectLoginExtendedSessionDuration = createSelector(
  selectLogin,
  (login) => login.extendedSession,
);
export const selectIsLoggedIn = (state) => state.login?.status === 'valid'
  || state.signUp?.registeredSuccessfully;

