import React, { lazy } from 'react';

const CandidatesMain = lazy(() => import('./pages/CandidatesMain' /* webpackChunkName: "candidates" */));


export const routesConfigCandidates = {
  show: {
    forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
    forFeature: 'candidates',
  },

  routes: [
    {
      path: '/vacancies',
      PageComponent: () => <CandidatesMain />,
      LazyPageComponent: 'Vacancies',
      show: {
        forCapabilities: { leader: 'vacanciesView' },
      },
      inMainNav: {
        labelKey: 'vacancies_nav',
      },
      subRoutes: [
        {
          path: '/vacancies/:vacancyId/summary',
          PageComponent: () => <CandidatesMain />,
          LazyPageComponent: 'VacancySummary',
          tools: {
            browserTitleKey: 'vacancies_nav',
          },
          subRoutes: [
            {
              path: '/vacancies/:vacancyId/summary',
              inSubNav: {
                labelKey: 'vacancy_subnav_summary',
              },
            },
            {
              path: '/vacancies/:vacancyId/matching',
              PageComponent: () => <CandidatesMain />,
              LazyPageComponent: 'VacancyMatching',
              show: {
                show: (state, stateExtras) => {
                  const { configuration = {} } = state;
                  if (!stateExtras) return true;
                  return configuration.capabilities?.rolesView && stateExtras.vacancyHasCandidates;
                },
              },
              inSubNav: {
                labelKey: 'vacancy_subnav_matching',
              },
              tools: {
                browserTitleKey: 'vacancies_nav',
              },
            },
            {
              path: '/vacancies/:vacancyId/role-details',
              PageComponent: () => <CandidatesMain />,
              LazyPageComponent: 'VacancyRoleDetails',
              show: {
                show: (state, stateExtras) => {
                  const { configuration = {} } = state;
                  if (!stateExtras) return true;
                  return configuration.capabilities?.rolesView && stateExtras.vacancyHasRole;
                },
              },
              inSubNav: {
                labelKey: 'vacancy_subnav_roledetails',
              },
              tools: {
                browserTitleKey: 'vacancies_nav',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/vacancies/new',
      pageType: 'modal',
      PageComponent: () => <CandidatesMain />,
      LazyPageComponent: 'NewVacancy',
      show: {
        forCapabilities: { leader: 'vacanciesCreate' },
      },
      tools: {
        browserTitleKey: 'vacancies_nav',
        heartbeat: true,
      },
    },
    {
      path: '/vacancies/:vacancyId/edit',
      pageType: 'modal',
      PageComponent: () => <CandidatesMain />,
      LazyPageComponent: 'EditVacancy',
      show: {
        forCapabilities: { leader: 'vacanciesCreate' },
      },
      tools: {
        browserTitleKey: 'vacancies_nav',
        heartbeat: true,
      },
    },
    {
      path: '/vacancies/:vacancyId/duplicate',
      pageType: 'modal',
      PageComponent: () => <CandidatesMain />,
      LazyPageComponent: 'DuplicateVacancy',
      show: {
        forCapabilities: { leader: 'vacanciesCreate' },
      },
      tools: {
        browserTitleKey: 'vacancies_nav',
        heartbeat: true,
      },
    },

    // CANDIDATES
    {
      path: '/candidates',
      PageComponent: () => <CandidatesMain />,
      LazyPageComponent: 'Candidates',
      show: {
        forCapabilities: { leader: 'candidatesView' },
      },
      inMainNav: {
        labelKey: 'candidates_nav',
      },
      subRoutes: [
        {
          path: '/candidates/:candidateId/summary',
          PageComponent: () => <CandidatesMain />,
          LazyPageComponent: 'CandidateSummary',
          tools: {
            browserTitleKey: 'candidates_nav',
          },
          subRoutes: [
            {
              path: '/candidates/:candidateId/summary',
              inSubNav: {
                labelKey: 'candidate_summary',
              },
            },
            {
              path: '/candidates/:candidateId/assessments',
              PageComponent: () => <CandidatesMain />,
              LazyPageComponent: 'CandidateAssessments',
              inSubNav: {
                labelKey: 'candidate_assessments',
              },
              tools: {
                browserTitleKey: 'candidates_nav',
              },
            },
            {
              path: '/candidates/:candidateId/rolefit',
              PageComponent: () => <CandidatesMain />,
              LazyPageComponent: 'CandidateRoleFit',
              inSubNav: {
                labelKey: 'candidate_rolefit',
              },
              show: ({ configuration }, stateExtras) => {
                if (!stateExtras) return true;
                return stateExtras.candidateHasRoleFitPage
                  && stateExtras.rolesModuleActive
                  && configuration?.capabilities?.rolesView;
              },
              tools: {
                browserTitleKey: 'candidates_nav',
              },
            },
          ],
        },
      ],
    },
  ],

};
