import React, { lazy } from 'react';
import { getCoachHubParam } from 'features/+coachHub/utils/localStorage';

// eslint-disable-next-line import/no-cycle
const CoachHubMain = lazy(() => import('./pages/CoachHubMain' /* webpackChunkName: "coachHub" */));

export const COACHHUB_TARGETS = {
  ASSESSMENT: 'assessment',
  PROFILE: 'profile',
  REPORT: 'report',
  PROFILE_COACH: 'profile-coach',
  MANAGE_PEER_ASSESSMENT: 'manage-assessment',
};

export const routesConfigCoachHub = {
  show: {
    forFlavor: 'coachHub',
    forUserGroup: 'all',
    needsProfileCompleted: 'ignore',
  },
  routes: [
    {
      path: '/action-link',
      pageType: 'coachHub',
      pageWrapper: {
        noFramework: true,
      },
      PageComponent: () => <CoachHubMain />,
      show: {
        needsLogin: false,
      },
      LazyPageComponent: 'ActionLink',
    },
    {
      path: '/my-profile',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        show: () => [ COACHHUB_TARGETS.PROFILE, COACHHUB_TARGETS.REPORT ].includes(getCoachHubParam('target')),
      },
      LazyPageComponent: 'MyProfile',
    },
    {
      path: '/profile-coach',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.PROFILE_COACH,
      },
      LazyPageComponent: 'MyProfile',
    },
    {
      path: '/assessments/disc',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.ASSESSMENT,
      },
      useStoreNext: true,
      LazyPageComponent: 'DiscAssessmentPage',
    },
    {
      path: '/assessments/:customAssessmentId',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.ASSESSMENT,
      },
      LazyPageComponent: 'CustomAssessmentPage',
    },
    {
      path: '/assessments/:customAssessmentId/peer/settings',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.MANAGE_PEER_ASSESSMENT,
      },
      LazyPageComponent: 'Peer360Wrapper',
    },
  ],
};
