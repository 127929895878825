import React, { useEffect, useState } from 'react';
import styles from './UserInvitationNext.module.scss';

/* ASSETS */
import { Icons } from 'assets/icons';

/* 3RD PARTY */
import { useHistory } from 'react-router';
import classNames from 'classnames';

/* STORE */
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformRoles, showToast } from 'store/actions';
import * as rolesSelector from 'store/selectors/roles';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import {
  selectCapabilitiesNext,
  selectIsBalancedYou,
} from 'store/selectors/configuration';
import { selectFeatures } from 'features/framework/storeNext/configurationSlice';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

/* UTILS */
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { getFilteredRoles } from 'utils/roles';
import REGEXES from 'utils/configuration/const/regexes';
import { MAPPED_PLATFORM_ROLES, PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { ASSESSMENT_RECURRENCE_TYPES, ASSESSMENT_TITLES } from 'utils/configuration/const/assessment-types';

/* OTHER COMPONENTS */
import {
  Icon,
  Button,
  DropDown,
  Checkbox,
  BigToggle,
  InputNext,
  MultiEmailInput,
  StoreNext,
} from 'ui/basic';
import { UserInvitation } from 'features/framework/pages';
import { InvitationLinks } from './components/InvitationLinks';
import { SettingsAndCostOverview } from './components/SettingsAndCostOverview';
import { CostOverview } from './components/CostOverview';
import { AssessmentsList } from './components/AssessmentsList';
import { LegalText } from './components/LegalText';


const ROLES_FOR_COMPANY_LEADER = [
  { name: PLATFORM_ROLES.COMPANY_USER },
  { name: PLATFORM_ROLES.CANDIDATE, accessKey: 'candidatesInvite' },
];

const TOGGLE_VALUES = {
  SINGLE_EMAIL: 0,
  MULTIPLE_EMAILS: 1,
};

const getRoleOptionLabel = (role, translate) => (
  <div className={styles.option}>
    <div className={styles.optionTitle}>{ translate(`platform_roles_${role}`) || role.toLowerCase() }</div>
    <div className={styles.optionDescription}>
      { translate(`platform_roles_descr_${role}`) }
    </div>
  </div>
);


const UserInvitationNext = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const companyFeatures = useSelector(selectFeatures);

  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsFilteredForUserGroup);

  const isBalancedYou = useSelector(selectIsBalancedYou);

  const [ addUsersProcessing, setAddUsersProcessing ] = useState(false);
  const [ addUsersErrorMessage, setAddUsersErrorMessage ] = useState();

  const [ toggleValue, setToggleValue ] = useState(0);

  const [ emails, setEmails ] = useState([]);
  const [ emailsDirty, setEmailsDirty ] = useState([]);
  const [ emailsValid, setEmailsValid ] = useState();
  const [ userGroup, setUserGroup ] = useState();
  const [ userGroups, setUserGroups ] = useState();

  // EMAIL: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ emailError, setEmailError ] = useState();
  const handleEmailChange = (value) => {
    const emailsInternal = [ ...emails ];
    emailsInternal[0] = value;

    setEmails(emailsInternal);
  };

  const handleEmailsChange = (clean, dirty, valid) => {
    setEmails(clean);
    setEmailsDirty(dirty);
    setEmailsValid(valid);
  };

  // GENERATE INVITE LINKS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ generateInviteLinks, setGenerateInviteLinks ] = useState();
  const [ invitationLinks, setInvitationLinks ] = useState();
  const handleGenerateInviteLinks = (value) => {
    setGenerateInviteLinks(value);
  };

  // PLATFORM ROLES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const allPlatformRoles = useSelector((state) => rolesSelector.getPlatformRoles(state));
  useEffect(() => {
    dispatch(getPlatformRoles());
  }, [ dispatch ]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ availableAssessments, setAvailableAssessments ] = useState([]);
  useEffect(() => {
    if (!companyFeatures || availableAssessments.length > 0) {
      return;
    }

    const assessmentFeatures = companyFeatures
    .filter((feature) => feature.active && feature.category.includes('assessment'))
    .map((feature) => {
      const assessmentId = feature.id.toLowerCase().replace(`${feature.category}_`, '');
      const assessment = assessmentsFromStore.find(({ id }) => id === assessmentId);
      return {
        id: assessmentId,
        featureId: feature.id,
        name: translate(ASSESSMENT_TITLES[assessmentId]) || feature.name || assessment?.title,
        active: !!feature.defaultUserActive,
        premium: assessment?.premium,
        recurrenceType: assessment?.recurrenceType,
      };
    });

    const availableAssessmentsInternal = assessmentFeatures
    .filter((assessment) => {
      if (me.roleNext !== MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER) {
        return true;
      }

      return me.accessibleAssessments.includes(assessment.id);
    })
    .filter((assessment) => assessment.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN)
    .sort((assessment) => (assessment.premium ? 1 : -1));

    const runBasedAssessments = assessmentFeatures.filter((a) => a.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN);
    setAvailableAssessments(availableAssessmentsInternal.concat(runBasedAssessments));
  }, [
    translate,
    me,
    companyFeatures,
    assessmentsFromStore,
    availableAssessments.length,
    capabilitiesNext.premiumAssessmentBooking,
  ]);

  // CUSTOM MESSAGE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ customMessage, setCustomMessage ] = useState();
  const handleCustomMessageChange = (value) => {
    setCustomMessage(value);
  };

  useEffect(() => {
    const filteredRoles = getFilteredRoles(allPlatformRoles, true);

    setUserGroups(filteredRoles
    .filter((role) => {
      if (me.role !== MAPPED_PLATFORM_ROLES.COMPANY_LEADER
        && me.role !== MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER
      ) {
        return true;
      }

      const thisRole = ROLES_FOR_COMPANY_LEADER.find((r) => r.name === role);
      return !!(thisRole && (!thisRole.accessKey || capabilitiesNext[thisRole.accessKey]));
    })
    .map((role) => ({
      value: role,
      label: getRoleOptionLabel(role.toLowerCase(), translate),
    })));
  }, [ allPlatformRoles, translate, capabilitiesNext, me ]);

  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      const splitValues = window.location.search.split('?type=');
      const type = splitValues[1];

      if (type && type === 'candidate') {
        setUserGroup(userGroups.find((role) => role.value === PLATFORM_ROLES.CANDIDATE));
      } else {
        setUserGroup(userGroups.find((role) => role.value === PLATFORM_ROLES.COMPANY_USER));
      }
    }
  }, [ userGroups ]);

  const [ showSettingsAndCostOverview, setShowSettingsAndCostOverview ] = useState(false);

  const [ showInvitationLinksPage, setShowInvitationLinksPage ] = useState(false);
  const handleSingleUserSubmit = () => {
    const mails = emails
    .map((email) => {
      const user = {
        mail: email,
        role: userGroup.value,
        features: availableAssessments
        .filter((a) => a.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN)
        .map((a) => ({
          id: a.featureId,
          active: a.active,
        })),
      };

      const assessmentsForManaging = availableAssessments
      .filter((a) => Boolean(a.grantManagementAccess))
      .map((a) => a.id);

      if (userGroup?.value === PLATFORM_ROLES.ASSESSMENT_MANAGER && assessmentsForManaging.length > 0) {
        Object.assign(user, {
          assessmentManagementScope: assessmentsForManaging,
        });
      }

      return user;
    });

    setAddUsersProcessing(true);
    api.put('/core/company/users', {
      mails,
      message: customMessage,
      linksOnly: generateInviteLinks,
    })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        const user = data.users[0] || {};
        if (generateInviteLinks) {
          setInvitationLinks([ {
            email: user.mail,
            link: user.registrationLink,
          } ]);
          setShowInvitationLinksPage(true);
        } else {
          dispatch(showToast(
            translate('invite_emp_invited_toast_title'),
            translate('invite_emp_invited_toast_content'),
          ));
          history.goBack();
        }
      } else {
        let { error } = data;
        if (error?.errorCode === 8001) {
          error = translate('exceeded_trial_limit');
        } else if (error?.errorMessage) {
          error = error?.errorMessage;
        }
        setAddUsersErrorMessage(error);
      }
    })
    .catch((error) => {
      setAddUsersErrorMessage(error.message);
      console.error(error.message);
    })
    .finally(() => {
      setAddUsersProcessing(false);
    });
  };

  const handleMultipleUsersSubmit = () => {
    setShowSettingsAndCostOverview(true);
  };

  const handleMultipleUsersComplete = (users = []) => {
    if (generateInviteLinks) {
      setInvitationLinks(users.map((user) => ({
        email: user.mail,
        link: user.registrationLink,
      })));

      setShowSettingsAndCostOverview(false);
      setShowInvitationLinksPage(true);
    } else {
      dispatch(showToast(
        translate('invite_emp_invited_toast_title'),
        translate('invite_emp_invited_toast_content'),
      ));
      history.goBack();
    }
  };

  if (!userGroups) {
    return null;
  }

  if (isBalancedYou) {
    return <UserInvitation />;
  }

  return (
    <div className={styles.userInvitation}>
      <div className={styles.header}>
        <span>{ translate('invite_emp_header_title') }</span>
        <div
          className={styles.close}
          role='presentation'
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>

      <div className={styles.scrollableContainer}>
        <div className={styles.gridContainer}>
          <div className={styles.gridContent}>
            { showInvitationLinksPage && (
              <InvitationLinks
                links={invitationLinks}
                userEmail={me.mail}
              />
            ) }

            { showSettingsAndCostOverview && (
              <StoreNext>
                <SettingsAndCostOverview
                  users={emails.map((email) => ({
                    email,
                    userGroup: {
                      value: userGroup.value,
                      label: translate(`platform_roles_${userGroup.value.toLowerCase()}`),
                    },
                    assessments: availableAssessments,
                  }))}
                  userGroups={userGroups.map((ug) => ({
                    value: ug.value,
                    label: translate(`platform_roles_${ug.value.toLowerCase()}`),
                  }))}
                  customMessage={customMessage}
                  generateInviteLinks={generateInviteLinks}
                  onBack={() => {
                    setShowSettingsAndCostOverview(false);
                  }}
                  onComplete={handleMultipleUsersComplete}
                />
              </StoreNext>
            ) }

            { (!showInvitationLinksPage && !showSettingsAndCostOverview) && (
              <>
                <div className='bluTypeS'>
                  { translate('invite_emp_title') }
                </div>

                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  { translate('invite_emp_emails_description') }
                </div>

                { addUsersErrorMessage && (
                  <div className={classNames('error', 'marginTopXs')}>{ addUsersErrorMessage }</div>
                ) }

                { /* EMAILS */ }
                <section>
                  <BigToggle
                    activeIndex={toggleValue}
                    inlineStyling
                    options={[ translate('single'), translate('multiple') ]}
                    onChange={(value) => setToggleValue(value)}
                  />

                  { /* single email */ }
                  { toggleValue === TOGGLE_VALUES.SINGLE_EMAIL && (
                    <div className='marginTopS'>
                      <div className='bluTypeLabelL'>{ translate('email_add_lbl') }</div>
                      <div className='marginTopXs'>
                        <InputNext
                          type='email'
                          label={translate('login_form_email_label')}
                          validate={{
                            pattern: REGEXES.EMAIL,
                            onChange: true,
                          }}
                          errorMessage={emailError && (emailError.pattern
                            ? (translate('admin_new_instance_admin_format_msg'))
                            : 'Required?'
                          )}
                          onChange={handleEmailChange}
                          onError={(error) => setEmailError(error)}
                        />
                      </div>
                    </div>
                  ) }

                  { /* multiple emails */ }
                  { toggleValue === TOGGLE_VALUES.MULTIPLE_EMAILS && (
                    <div className='marginTopS'>
                      <div className='bluTypeLabelL'>{ translate('emails_add_lbl') }</div>
                      <div className='marginTopXs'>
                        <MultiEmailInput
                          placeholder={translate('multiple_emails_placeholder')}
                          value={emailsDirty}
                          onChange={handleEmailsChange}
                        />
                      </div>
                    </div>
                  ) }
                </section>

                { /* SELECT USER GROUP */ }
                <section>
                  <div className='bluTypeLabelL'>{ translate('invite_emp_usergroups_placeholder') }</div>

                  { toggleValue === TOGGLE_VALUES.MULTIPLE_EMAILS && (
                    <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                      { translate('user_group_select_multiple_users_hint') }
                    </div>
                  ) }

                  <div className={styles.select}>
                    <DropDown
                      size='L'
                      disabled={userGroups && userGroups.length === 1}
                      selectedOption={userGroup}
                      placeholder={translate('invite_emp_usergroups_placeholder')}
                      options={userGroups}
                      onChange={(option) => setUserGroup(option)}
                    />
                  </div>
                </section>

                { /* CUSTOM MESSAGE */ }
                <section>
                  <div className='bluTypeLabelL'>
                    { translate('invite_emp_customization_title') }
                  </div>
                  <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                    { toggleValue === TOGGLE_VALUES.SINGLE_EMAIL
                      ? translate('custom_message_add_copy')
                      : translate('custom_message_add_multiple_users_copy') }
                  </div>
                  <div className='marginTopXs'>
                    <InputNext
                      label={translate('custom_message_add_placeholder')}
                      onChange={handleCustomMessageChange}
                    />
                  </div>
                </section>

                { /* ASSESSMENTS */ }
                <section className={styles.bigTopMargin}>
                  <div className='bluTypeLabelL'>
                    { translate('invite_emp_select_assessments_title') }
                  </div>

                  { toggleValue === TOGGLE_VALUES.MULTIPLE_EMAILS && (
                    <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                      { translate('user_group_select_multiple_users_hint') }
                    </div>
                  ) }

                  <StoreNext>
                    <AssessmentsList
                      assessments={availableAssessments}
                      premiumAssessmentCanBePurchased={capabilitiesNext.premiumAssessmentBooking}
                      showGrantManagementAccess={userGroup?.value === PLATFORM_ROLES.ASSESSMENT_MANAGER}
                      onChange={(assessments) => {
                        setAvailableAssessments([ ...assessments ]);
                      }}
                    />
                  </StoreNext>
                </section>

                { /* INVITATION LINKS */ }
                <section className={styles.bigTopMargin}>
                  <div className='bluTypeLabelL'>
                    { translate('invite_links_generate_title') }
                  </div>
                  <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                    { translate('invite_links_generate_copy') }
                  </div>
                  <div className='marginTopXs'>
                    <Checkbox
                      name={translate('invite_links_generate_checkbox_lbl')}
                      onChange={handleGenerateInviteLinks}
                    />
                  </div>
                </section>

                { toggleValue === TOGGLE_VALUES.SINGLE_EMAIL && (
                  <section>
                    <StoreNext>
                      <CostOverview
                        usersQuantity={(emails[0]
                          && !emailError && userGroup?.value !== 'RECRUITING_CANDIDATE') ? 1 : 0}
                        candidatesQuantity={(emails[0]
                          && !emailError && userGroup?.value === 'RECRUITING_CANDIDATE') ? 1 : 0}
                        rmpQuantity={availableAssessments.filter((a) => a.id === 'ASSESSMENT_RMP' && a.active)?.length}
                        nineLevelsQuantity={availableAssessments.filter((a) => (
                          a.id === 'ASSESSMENT_9LEVELS' && a.active
                        ))?.length}
                      />
                    </StoreNext>
                  </section>
                ) }

                { /* BUTTONS */ }
                <div className={styles.buttons}>
                  <Button
                    size='S'
                    looks='secondary'
                    onClick={() => history.goBack()}
                  >
                    { translate('cancel_lbl') }
                  </Button>
                  <Button
                    size='S'
                    disabled={(toggleValue === TOGGLE_VALUES.SINGLE_EMAIL
                        && (!emails.length || (emails.length && emailError)))
                      || (toggleValue === TOGGLE_VALUES.MULTIPLE_EMAILS
                        && !emailsValid) || !userGroup || addUsersProcessing}
                    onClick={toggleValue === TOGGLE_VALUES.SINGLE_EMAIL
                      ? handleSingleUserSubmit
                      : handleMultipleUsersSubmit}
                  >
                    { translate('complete_lbl') }
                  </Button>
                </div>

                <StoreNext>
                  <LegalText />
                </StoreNext>
              </>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInvitationNext;
