// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionJobFamily.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDownSearchable, Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import {
  selectJobFamilies,
} from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
// const Config = {};


// COMPONENT: QuestionJobFamily
const QuestionJobFamily = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const jobFamilyOptions = useSelector(selectJobFamilies);
  const [ jobFamily, setJobFamily ] = useState('');
  useEffect(() => {
    setJobFamily(answer.value ?? '');
  }, [ answer.value ]);
  const [ useDropDown, setUseDropDown ] = useState(true);
  useEffect(() => {
    if (typeof (answer.useDropDown) === 'boolean') {
      setUseDropDown(answer.useDropDown);
    }
  }, [ answer.useDropDown ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const handleJobFamily = (jobFamilyParam = '') => {
    setJobFamily(jobFamilyParam);
    onAnswer({
      value: jobFamilyParam,
      name: 'jobFamily',
      isValid: Boolean(jobFamilyParam),
      useDropDown,
    });
  };

  // RENDER: QuestionJobFamily
  return (
    <div className={classNames(styles.questionJobFamily)}>

      { /* FORM */ }
      <div className={styles.formRow}>
        { useDropDown ? (
          <DropDownSearchable
            size={size}
            placeholder={translate('cp_job_family_dropdown_placeholder')}
            filterPlaceholder={translate('search_lbl')}
            activeValue={answer.useDropDown && jobFamily}
            options={jobFamilyOptions}
            onChange={(option) => handleJobFamily(option?.value)}
          />
        ) : (
          <InputNext
            size={size}
            value={!answer.useDropDown && jobFamily}
            placeholder={translate('cp_job_family_input_placeholder')}
            onChange={handleJobFamily}
          />
        ) }
      </div>

      <div className={classNames(styles.formRow, styles.center, styles.bigMargin)}>
        <Button
          size={size === 'responsive' ? 'L' : 'S'}
          looks='tertiary'
          onClick={() => {
            setUseDropDown(!useDropDown);
            handleJobFamily('');
          }}
        >
          { useDropDown
            ? translate('cp_job_family_add_new')
            : translate('cp_job_family_choose_from_list') }
        </Button>
      </div>
    </div>
  );
};

export default QuestionJobFamily;
