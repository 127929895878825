export const GET_CONFIGURATION = '[CONFIGURATION] GET CONFIGURATION';
export const getConfiguration = () => ({
  type: GET_CONFIGURATION,
  payload: {},
});

export const GET_CONFIGURATION_SUCCESS = '[CONFIGURATION] GET CONFIGURATION SUCCESS';
export const getConfigurationSuccess = (configuration) => ({
  type: GET_CONFIGURATION_SUCCESS,
  payload: configuration,
});

export const GET_CONFIGURATION_ERROR = '[CONFIGURATION] GET CONFIGURATION ERROR';
export const getConfigurationError = ({ error }) => ({
  type: GET_CONFIGURATION_ERROR,
  payload: { error },
});


export const SET_CONFIGURATION_COMPANY = '[CONFIGURATION] SET CONFIGURATION COMPANY';
export const setConfigurationCompany = (company) => ({
  type: SET_CONFIGURATION_COMPANY,
  payload: { company },
});

export const SET_CONFIGURATION_CUSTOM_LOGO_URL = '[CONFIGURATION] SET CONFIGURATION CUSTOM LOGO URL';
export const setConfigurationCustomLogoUrl = (customLogoUrl) => ({
  type: SET_CONFIGURATION_CUSTOM_LOGO_URL,
  payload: { customLogoUrl },
});

export const GET_CONFIGURATION_EXTENDED = '[CONFIGURATION] GET CONFIGURATION EXTENDED';
export const getConfigurationExtended = (payload) => ({
  type: GET_CONFIGURATION_EXTENDED,
  payload,
});

export const GET_CONFIGURATION_EXTENDED_SUCCESS = '[CONFIGURATION] GET CONFIGURATION EXTENDED SUCCESS';
export const getConfigurationExtendedSuccess = (configuration) => ({
  type: GET_CONFIGURATION_EXTENDED_SUCCESS,
  payload: configuration,
});

export const GET_CONFIGURATION_EXTENDED_ERROR = '[CONFIGURATION] GET CONFIGURATION EXTENDED ERROR';
export const getConfigurationExtendedError = ({ error }) => ({
  type: GET_CONFIGURATION_EXTENDED_ERROR,
  payload: { error },
});

export const SET_CONFIGURATION_AVAILABLE_INSTANCES = '[CONFIGURATION] SET CONFIGURATION AVAILABLE INSTANCES';
export const setConfigurationAvailableInstances = (availableInstances) => ({
  type: SET_CONFIGURATION_AVAILABLE_INSTANCES,
  payload: { availableInstances },
});

export const SET_INSTANCE_FLAVOR = '[CONFIGURATION] SET INSTANCE FLAVOR';
export const setInstanceFlavor = (flavor, subFlavor = '') => ({
  type: SET_INSTANCE_FLAVOR,
  payload: { flavor, subFlavor },
});
