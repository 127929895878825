import React from 'react';
import styles from './FilterListSkeleton.module.scss';

const FilterListSkeleton = () => {
  const getBodyBlocks = () => {
    const blocks = [];

    for (let i = 0; i < 9; i++) {
      blocks.push(
        <div key={i} className={styles.bodyBlock}>
          <div />
          <div />
        </div>,
      );
    }
    return blocks;
  };

  return (
    <div className={styles.filterListSkeleton}>
      <div className={styles.gridContent}>
        <div className={styles.header}>
          <div />
          <div />
          <div />
        </div>

        <div className={styles.body}>
          { getBodyBlocks() }
        </div>
      </div>
    </div>
  );
};

export default FilterListSkeleton;
