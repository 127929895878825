// REACT, STYLE, STORIES & COMPONENT
import { useState, useEffect } from 'react';
// import styles from './LoadConfiguration.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';
import { IS_ADMIN_FRONTEND } from 'utils/configuration';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyDashboards,
  fetchConfiguration,
  selectCompany,
  selectFeatures,
  selectInitialised,
  selectInstance,
  selectInstanceIsCoachHub,
  selectIsLoggedIn,
  selectPlanId,
  selectUserGroup,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: LoadConfiguration
const LoadConfiguration = (props) => {
  // PROPS
  const {
    onConfiguration,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const initialised = useSelector(selectInitialised);
  const company = useSelector(selectCompany);
  const instance = useSelector(selectInstance);

  const [ companyInternal, setCompanyInternal ] = useState();
  const [ instanceInternal, setInstanceInternal ] = useState();

  const planId = useSelector(selectPlanId);
  const features = useSelector(selectFeatures);
  useEffect(() => {
    // fetching of plan can differ depending on user group
    // for admin users planId would be available, which relates to billing end-point
    // for non-admin users plan value will be available from company settings
    dispatch(fetchConfiguration(planId || company?.plan, features));
  }, [ dispatch, planId, features, company?.plan ]);

  useEffect(() => {
    // not sure why this is called three times without checking if it's the same
    if (initialised && onConfiguration && (companyInternal !== company || instanceInternal !== instance)) {
      setCompanyInternal(company);
      setInstanceInternal(instance);
      onConfiguration({ company, instance });
    }
  }, [ onConfiguration, initialised, company, instance, companyInternal, instanceInternal ]);

  const isCoachHub = useSelector(selectInstanceIsCoachHub);
  const userIsLoggedIn = useSelector(selectIsLoggedIn);
  const userGroup = useSelector(selectUserGroup);
  useEffect(() => {
    if (!userIsLoggedIn
      || isCoachHub
      || IS_ADMIN_FRONTEND
    ) {
      return;
    }

    dispatch(fetchCompanyDashboards());
  }, [ dispatch, isCoachHub, userIsLoggedIn, userGroup ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LoadConfiguration
  return null;
};

export default LoadConfiguration;
