export const GET_USER_SCORE = '[GARMIN] GET_USER_SCORE';
export const getUserScore = () => ({
  type: GET_USER_SCORE,
  payload: {},
});

export const GET_USER_SCORE_SUCCESS = '[GARMIN] GET_USER_SCORE_SUCCESS';
export const getUserScoreSuccess = ({ data }) => ({
  type: GET_USER_SCORE_SUCCESS,
  payload: { data },
});

export const GET_USER_SCORE_FAIL = '[GARMIN] GET_USER_SCORE_FAIL';
export const getUserScoreFail = ({ error }) => ({
  type: GET_USER_SCORE_FAIL,
  payload: { error },
});

// GET CONNECTION STATUS
export const GET_CONNECTION_STATUS = '[GARMIN] GET_CONNECTION_STATUS';
export const getConnectionStatus = () => ({
  type: GET_CONNECTION_STATUS,
  payload: {},
});

export const GET_CONNECTION_STATUS_SUCCESS = '[GARMIN] GET_CONNECTION_STATUS_SUCCESS';
export const getConnectionStatusSuccess = ({ data }) => ({
  type: GET_CONNECTION_STATUS_SUCCESS,
  payload: { data },
});

export const GET_CONNECTION_STATUS_FAIL = '[GARMIN] GET_CONNECTION_STATUS_FAIL';
export const getConnectionStatusFail = ({ error }) => ({
  type: GET_CONNECTION_STATUS_FAIL,
  payload: { error },
});

// GET GARMIN USER HISTORY
export const GET_USER_HISTORY = '[GARMIN] GET_USER_HISTORY';
export const getUserHistory = () => ({
  type: GET_USER_HISTORY,
  payload: {},
});

export const GET_USER_HISTORY_SUCCESS = '[GARMIN] GET_USER_HISTORY_SUCCESS';
export const getUserHistorySuccess = ({ data }) => ({
  type: GET_USER_HISTORY_SUCCESS,
  payload: { data },
});

export const GET_USER_HISTORY_FAIL = '[GARMIN] GET_USER_HISTORY_FAIL';
export const getUserHistoryFail = ({ error }) => ({
  type: GET_USER_HISTORY_FAIL,
  payload: { error },
});

// GET GARMIN ORG SCORE
export const GET_ORG_SCORE = '[GARMIN] GET_ORG_SCORE';
export const getOrgScore = () => ({
  type: GET_ORG_SCORE,
  payload: {},
});

export const GET_ORG_SCORE_SUCCESS = '[GARMIN] GET_ORG_SCORE_SUCCESS';
export const getOrgScoreSuccess = ({ data }) => ({
  type: GET_ORG_SCORE_SUCCESS,
  payload: { data },
});

export const GET_ORG_SCORE_FAIL = '[GARMIN] GET_ORG_SCORE_FAIL';
export const getOrgScoreFail = ({ error }) => ({
  type: GET_ORG_SCORE_FAIL,
  payload: { error },
});

// GET GARMIN ORG HISTORY
export const GET_ORG_HISTORY = '[GARMIN] GET_ORG_HISTORY';
export const getOrgHistory = () => ({
  type: GET_ORG_HISTORY,
  payload: {},
});

export const GET_ORG_HISTORY_SUCCESS = '[GARMIN] GET_ORG_HISTORY_SUCCESS';
export const getOrgHistorySuccess = ({ data }) => ({
  type: GET_ORG_HISTORY_SUCCESS,
  payload: { data },
});

export const GET_ORG_HISTORY_FAIL = '[GARMIN] GET_ORG_HISTORY_FAIL';
export const getOrgHistoryFail = ({ error }) => ({
  type: GET_ORG_HISTORY_FAIL,
  payload: { error },
});
