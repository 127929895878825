import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styles from './CustomScrollable.module.scss';
import { Pagination } from '../../Pagination';
import classNames from 'classnames';

const CONFIG = {
  dragSnapThreshold: 25,
  gap: 16,
};


const CustomScrollable = (props, ref) => {
  const {
    showPaginationBubbles,
    onPaginationAvailabilities,
    children,
  } = props;

  const [ pagePosition, setPagePosition ] = useState(0);
  const [ dragStartX, setDragStartX ] = useState(0);
  const [ dragStartScroll, setDragStartScroll ] = useState(0);
  const [ dragging, setDragging ] = useState(false);
  const [ isSnapMode, setIsSnapMode ] = useState(true);
  const scrollableRef = useRef(null);
  const scrollDestination = useRef(null);
  const pageCount = children.length;
  const isStatic = pageCount <= 1;

  // HELPERS
  const pageLeft = () => setPagePosition((page) => Math.max(page - 1, 0));
  const pageRight = () => setPagePosition((page) => Math.min(page + 1, pageCount - 1));
  useImperativeHandle(ref, () => ({ pageLeft, pageRight }));

  const triggerScroll = useCallback((page) => {
    const preciseWidth = parseFloat(getComputedStyle(scrollableRef.current).width);
    const to = Math.round(page * (preciseWidth + CONFIG.gap));
    if (Math.abs(scrollableRef.current.scrollLeft - to) > 1) {
      scrollableRef.current.scrollTo({ left: to, behavior: 'smooth' });
      scrollDestination.current = to;
    }
  }, []);

  // EFFECTS
  useEffect(() => {
    triggerScroll(pagePosition);
  }, [ triggerScroll, pagePosition ]);

  useEffect(() => {
    const onScroll = () => {
      const delta = Math.abs(scrollableRef.current.scrollLeft - scrollDestination.current);
      if (Number.isFinite(scrollDestination.current) && delta <= 1) { // scrolling ends
        scrollDestination.current = null;
        setIsSnapMode(true);
      }
    };

    const { current: node } = scrollableRef;
    node.addEventListener('scroll', onScroll);
    return () => node.removeEventListener('scroll', onScroll);
  }, []);

  // CONTROLS
  const dragStart = (event) => {
    if (isStatic) {
      return;
    }

    const pageX = event.pageX || event.touches[0].pageX;

    setIsSnapMode(false);
    setDragging(true);
    setDragStartX(pageX);
    setDragStartScroll(scrollableRef.current.scrollLeft);
    scrollDestination.current = null;
  };

  const dragMove = (event) => {
    if (!dragging) {
      return;
    }

    const pageX = event.pageX || event.changedTouches[0].pageX;
    const delta = dragStartX - pageX;
    const scrollLeft = dragStartScroll + delta;
    scrollableRef.current.scrollTo({ left: scrollLeft });
  };

  const dragEnd = (event) => {
    if (!dragging) {
      return;
    }

    setDragging(false);
    const pageX = event.pageX || event.changedTouches?.[0]?.pageX;
    const delta = dragStartX - pageX;

    if (delta === 0) {
      // No movement
      scrollDestination.current = null;
      setIsSnapMode(true);
    } else if (delta < -CONFIG.dragSnapThreshold && pagePosition > 0) {
      // Move backward
      pageLeft();
    } else if (delta > CONFIG.dragSnapThreshold && pagePosition < pageCount - 1) {
      // Move forward
      pageRight();
    } else {
      // Not enough drag: restore view
      triggerScroll(pagePosition);
    }
  };

  const keyUp = (event) => {
    setDragging(false);
    setIsSnapMode(true);
    scrollDestination.current = null;

    if (event.key === 'Tab') {
      const newPagePosition = Math.floor(event.target.offsetLeft / scrollableRef.current.clientWidth);
      setPagePosition(newPagePosition);
    }
    if (event.key === 'ArrowLeft' && pagePosition > 0) {
      pageLeft();
    }
    if (event.key === 'ArrowRight' && pagePosition < pageCount - 1) {
      pageRight();
    }
  };


  // RENDER: CustomScrollable
  return (
    <div
      className={classNames(styles.customScrollable, {
        [styles.isStatic]: isStatic,
        [styles.dragging]: dragging,
      })}
      role='menuitem'
      tabIndex={0}
      onKeyUp={keyUp}
    >
      <div
        ref={scrollableRef}
        role='presentation'
        className={classNames(styles.slider, { [styles.snap]: isSnapMode })}
        onMouseDown={dragStart}
        onMouseMove={dragMove}
        onMouseUp={dragEnd}
        onMouseLeave={dragEnd}
        onTouchStart={dragStart}
        onTouchMove={dragMove}
        onTouchEnd={dragEnd}
      >
        { children }
      </div>

      { /* CONTROLS */ }
      { !isStatic && (
        <div className={styles.controls}>
          <Pagination
            showPaginationBubbles={showPaginationBubbles}
            pagePosition={pagePosition}
            pageCount={pageCount}
            onPagePositionUpdate={(newPagePosition, pageLeftAvailable, pageRightAvailable, pageToLeft) => {
              setPagePosition(newPagePosition);
              onPaginationAvailabilities?.([ pageLeftAvailable, pageRightAvailable, newPagePosition, pageToLeft ]);
            }}
          />
        </div>
      ) }
    </div>
  );
};

export default forwardRef(CustomScrollable);
