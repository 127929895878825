import React, { useCallback, useEffect, useState } from 'react';
import styles from './VacancyExternalProfile.module.scss';

import * as api from 'api';

import { useHistory, useParams } from 'react-router';

import { useTranslate } from 'utils/translator';
import { COMPANY_ID } from 'utils/configuration';

import { Button, InputPassword } from 'ui/basic';
import { VacancyInternalContent } from './VacancyInternalContent';
import { getMappedVacancyData } from 'features/+candidates/store/vacancy/vacancy.utils';

/**
 * all API requests triggered here use 'shareToken' instead of a basic token
 *
 * there are few requests for fetching assessments info which get triggered implicitly
 * in results components (big5, key comp, leadership comp)
 * and where the request header gets generated in api/index.js
 *
 * for that case another piece of code is added in api/index.js:
 * for external profile requests use always 'shareToken';
 */

const VacancyExternalProfile = () => {
  const translate = useTranslate();
  const history = useHistory();
  const params = useParams();

  let { vacancyId } = params;

  // if there are no params with vacancy id, fetch vacancy id from url
  if (!vacancyId) {
    const splitValue = history.location.pathname.split('/vacancy/')[1];
    if (splitValue && splitValue?.split('/share/')[0]) {
      [ vacancyId ] = splitValue.split('/share/');
    }
  }

  const [ token, setToken ] = useState();
  const [ linkExpired, setLinkExpired ] = useState();

  const [ password, setPassword ] = useState();
  const [ passwordProtected, setPasswordProtected ] = useState();

  const [ vacancy, setVacancy ] = useState();

  const [ loginProcessing, setLoginProcessing ] = useState();
  const [ loginError, setLoginError ] = useState();

  let shareToken;
  const split = history.location.pathname.split('/share/');
  if (split && split.length === 2) {
    [ , shareToken ] = split;
  }

  const getVacancy = useCallback((thisToken) => {
    const vacancyParams = new URLSearchParams();
    vacancyParams.append('expand', 'role');
    vacancyParams.append('expand', 'team');

    api.get(`/recruiting/jobs/${vacancyId}`, vacancyParams, { Authorization: `Bearer ${thisToken}` })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setVacancy(getMappedVacancyData(data));
      } else {
        if (status === 404) {
          setLinkExpired(true);
        }

        setToken(null);
        if (localStorage.getItem(`shareToken${shareToken}`)) {
          localStorage.removeItem(`shareToken${shareToken}`);
        }
      }
    })
    .catch((error) => console.error(error.message));
  }, [ vacancyId, shareToken ]);

  useEffect(() => {
    let externalProfileToken;
    if (shareToken) {
      externalProfileToken = localStorage.getItem(`shareToken${shareToken}`);
    }

    // if there is a token in local storage -> use this token for fetching the user info/profile
    if (externalProfileToken) {
      setToken(externalProfileToken);
      setPasswordProtected(false);

      getVacancy(externalProfileToken);
      return;
    }

    // some external links are not password protected.
    // for that case first try to login without a password.
    // If it succeeds fetch user info and user profile
    api.post('/core/user/login', {
      shareToken,
      company: COMPANY_ID || 'dummy-inc',
    })
    .then(({ ok, status, data }) => {
      if (status === 403 && data.error && data.error.errorCode === 1209) {
        setPasswordProtected(false);
        setLinkExpired(true);
        return;
      }

      if (ok && status === 200) {
        setPasswordProtected(false);
        setToken(data.token);
        localStorage.setItem(`shareToken${shareToken}`, data.token);

        getVacancy(data.token);
      } else {
        setPasswordProtected(true);
      }
    })
    .catch((error) => {
      setLoginProcessing(false);
      console.error(error.message);
    });
  }, [ shareToken, getVacancy ]);

  if (passwordProtected === undefined) {
    return null;
  }

  return (
    <>

      { /* IF USER IS NOT LOGGED IN */ }
      { !token && (
        <div className={styles.vacancyExternalProfile}>
          <div className={styles.loginContainer}>
            { linkExpired && (
              <>
                <div className={styles.mTitle}>
                  { translate('share_profile_link_expired_title') }
                </div>
                <div className={styles.copy}>
                  { translate('share_profile_link_expired_copy') }
                </div>
                <Button size='L' onClick={() => history.push('/')}>
                  { translate('share_profile_link_expired_btn') }
                </Button>
              </>
            ) }

            { !linkExpired && (
              <>
                <div className={styles.mTitle}>
                  { translate('login_title') }
                </div>
                <div className={styles.copyStrong}>
                  { translate('share_profile_login_copy') }
                </div>

                <form onSubmit={(event) => {
                  event.preventDefault();

                  setLoginProcessing(true);

                  api.post('/core/user/login', {
                    shareToken,
                    company: COMPANY_ID || 'dummyinc',
                    password,
                  })
                  .then(({ ok, status, data }) => {
                    setLoginProcessing(false);

                    if (status === 403 && data.error && data.error.errorCode === 1209) {
                      setLinkExpired(true);
                      return;
                    }

                    if (ok && status === 200) {
                      setToken(data.token);
                      localStorage.setItem(`shareToken${shareToken}`, data.token);

                      getVacancy(data.token);
                    } else {
                      setLoginError(data.error.errorMessage);
                    }
                  })
                  .catch((error) => {
                    setLoginProcessing(false);
                    console.error(error.message);
                  });
                }}
                >
                  <div className={styles.password}>
                    <InputPassword
                      placeHolder={translate('share_profile_login_password_placeholder')}
                      error={loginError}
                      onInputChanged={(value) => setPassword(value)}
                    />
                  </div>

                  <Button
                    size='L'
                    disabled={!password || loginProcessing}
                    type='submit'
                  >
                    { translate('continue_lbl') }
                  </Button>
                </form>
              </>
            ) }
          </div>
        </div>
      ) }

      { /* IF USER IS LOGGED IN */ }
      { token && vacancy && (
        <VacancyInternalContent
          vacancy={vacancy}
          token={token}
        />
      ) }
    </>
  );
};

export default VacancyExternalProfile;
