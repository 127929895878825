import React, { useEffect, useState, useMemo } from 'react';

// 3RD PARTY
import { useLocation, useHistory } from 'react-router-dom';

// STORE NEXT
import { Provider, useSelector, useDispatch } from 'react-redux';
import { storeNext } from 'features/framework/utils/storeNext';
import { LoggedIn } from 'features/framework/components/LoggedIn';
import LoadConfiguration from 'features/framework/components/LoadConfiguration';
import { MainLayout } from 'features/framework/components/MainLayout';
import { getCoachHubParam } from 'features/+coachHub/utils/localStorage';

import { COACHHUB_TARGETS } from 'features/+coachHub/routeNext';

// OTHER COMPONENTS
import { Loading } from 'ui/basic';

// STORE OLD
import {
  getConfigurationExtended,
  getConfigurationSuccess,
  setInstanceFlavor,
} from 'store/actions/configuration';
import {
  selectIsCoachHub,
  selectAssessmentsNext,
  selectCapabilitiesNext,
  selectFeaturesNext,
  selectInstanceFlavorLoaded, selectSwitches,
} from 'store/selectors/configuration';

import {
  getLanguages,
  changeLanguage,
  loginFulfilled,
  getUserSettings,
  getCurrentUser,
  getCompanySettings,
  listAssessments,
} from 'store/actions';
import { setNextRoute } from 'store/actions/routing';
import { getShowTranslationKeys } from 'store/selectors/debug';
import {
  getCurrentUserId, getEmail, getFirstName, getLastName,
  selectUserIsBasicDataReady, selectUserProfileCompletedNext, selectUserSubRoleNext,
  selectUserRoleNext, getIsUserTeamLeader,
} from 'store/selectors/currentUser';
import { selectIsLoggedIn, selectLoginAuthToken, selectLoginExtendedSessionDuration } from 'store/selectors/login';
import { selectUserOnboardingCompletedNext, selectUserSettingsLoaded } from 'store/selectors/settings';
import { selectVacancyHasCandidates, selectVacancyHasRole } from 'features/+candidates/store/vacancy/vacancy.selectors';
import { selectCandidateHasRoleFitPage } from 'features/+candidates/store/candidate/candidate.selectors';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { selectLanguages } from 'store/selectors/localisation';
import { selectShowStrengths } from 'store/selectors/assessment';

// UTILS
import * as api from 'api';
import * as localStorage from 'utils/localStorage';
import { TranslateContext, getTranslate } from 'utils/translator';
import { getUserLanguage } from 'utils/userpreference';
import {
  COMPANY_ID,
  CONFIGURATION,
  CONFIGURATION_OPTIONS,
  IS_ADMIN_FRONTEND,
  TRACKING_ENABLE,
  TRACKING_SITE_ID,
} from 'utils/configuration';


const Main = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  // TRANSLATE: setup context & translate function based on store data
  const languages = useSelector(selectLanguages);
  const translations = useSelector((state) => state.localisation?.translations);
  const showTranslationKeys = useSelector(getShowTranslationKeys);
  const translateContextValue = useMemo(() => ({
    translate: getTranslate(translations, showTranslationKeys),
  }), [ translations, showTranslationKeys ]);

  // features & assessments next
  const featuresNext = useSelector(selectFeaturesNext);
  const assessmentsNext = useSelector(selectAssessmentsNext);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const switches = useSelector(selectSwitches);

  // INITIALISE
  useEffect(() => {
    dispatch(getLanguages());

    if (!IS_ADMIN_FRONTEND) {
      dispatch(getCompanySettings());
    }

    const regularLoginTokenName = 'token';
    const token = localStorage.getItem(regularLoginTokenName);
    if (token && api.getTokenNameForCurrentPage() === regularLoginTokenName) {
      dispatch(loginFulfilled({ token }));
      if (!IS_ADMIN_FRONTEND) {
        dispatch(listAssessments());
      }
    }
  }, [ dispatch ]);

  // INITIALISE LANGUAGE
  useEffect(() => {
    if (!languages.length) {
      return;
    }

    dispatch(changeLanguage({
      language: getUserLanguage(languages),
      refetchStaticValues: true,
    }));
  }, [ dispatch, languages ]);

  const handleConfiguration = (({ instance, company }) => {
    // console.log('onConfiguration', instance, company)
    dispatch(setInstanceFlavor(instance.flavor));
    dispatch(getConfigurationSuccess({
      company,
      deployment: {
        isAdminFrontend: instance.flavor === 'adminFrontend',
      },
    }));
  });

  // LOGIN
  const isLoggedIn = useSelector(selectIsLoggedIn);
  useEffect(() => {
    // console.log('isLoggedIn', isLoggedIn);
    if (isLoggedIn) {
      // Skip fetch of user settings for CH profiles because the view is shared
      if (CONFIGURATION === CONFIGURATION_OPTIONS.COACH_HUB
        && COACHHUB_TARGETS.PROFILE_COACH === getCoachHubParam('target')) {
        return;
      }

      dispatch(getCurrentUser());
      dispatch(getUserSettings());
      dispatch(getCompanySettings(COMPANY_ID));
    }
  }, [ dispatch, isLoggedIn ]);

  // USER
  const [ tokenExpiration, setTokenExpiration ] = useState();
  const currentUser = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const authToken = useSelector(selectLoginAuthToken);
  const extendedSession = useSelector(selectLoginExtendedSessionDuration);

  useEffect(() => {
    if (authToken) {
      const sessionDuration = extendedSession ? api.EXTENDED_SESSION_DURATION : api.SESSION_DURATION;
      setTokenExpiration(Date.now() + sessionDuration);
    }
  }, [ authToken, extendedSession ]);

  const userIsBasicDataReady = useSelector(selectUserIsBasicDataReady);
  const userId = useSelector(getCurrentUserId);
  const userMail = useSelector(getEmail);
  const userFirstName = useSelector(getFirstName);
  const userLastName = useSelector(getLastName);
  const isCoachHub = useSelector(selectIsCoachHub);
  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);
  const isCurrentLanguageFetching = useSelector((state) => state.localisation?.processing);

  // user next
  const userRoleNext = useSelector(selectUserRoleNext);
  const userSubRoleNext = useSelector(selectUserSubRoleNext);
  const userProfileCompletedNext = useSelector(selectUserProfileCompletedNext);
  // user settings
  const userSettingsLoaded = useSelector(selectUserSettingsLoaded);
  const userOnboardingCompleted = useSelector(selectUserOnboardingCompletedNext);

  const instanceFlavorLoaded = useSelector(selectInstanceFlavorLoaded);

  // post login requests
  useEffect(() => {
    if (userIsBasicDataReady && userRoleNext && userSubRoleNext && !IS_ADMIN_FRONTEND) {
      dispatch(getConfigurationExtended({ userGroup: userRoleNext, userSubGroup: userSubRoleNext }));
    }
  }, [ dispatch, userIsBasicDataReady, userRoleNext, userSubRoleNext ]);

  // loading
  const isLoading = useSelector((state) => state.loading);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (pathname === '/new-account'
      && params.get('mail') && params.get('invitationId')
      && localStorage.getItem('token')
    ) {
      localStorage.logout();
      window.location.href = `${pathname}${search}`;
    }
  }, [ dispatch, pathname, search ]);

  // cross store communication
  const [ stateExtras, setStateExtras ] = useState({
    vacancyHasCandidates: false,
    candidateHasRoleFitPage: false,
  });
  const vacancyHasCandidates = useSelector(selectVacancyHasCandidates);
  const vacancyHasRole = useSelector(selectVacancyHasRole);
  const candidateHasRoleFitPage = useSelector(selectCandidateHasRoleFitPage);
  const isUserTeamLeader = useSelector(getIsUserTeamLeader);
  const showStrengths = useSelector(selectShowStrengths);

  useEffect(() => {
    if (
      vacancyHasCandidates !== stateExtras.vacancyHasCandidates
      || candidateHasRoleFitPage !== stateExtras.candidateHasRoleFitPage
      || isUserTeamLeader !== stateExtras.isUserTeamLeader
      || vacancyHasRole !== stateExtras.vacancyHasRole
      || showStrengths !== stateExtras.showStrengths
    ) {
      setStateExtras({
        vacancyHasCandidates,
        candidateHasRoleFitPage,
        rolesModuleActive: !!featuresNext?.roles,
        isUserTeamLeader,
        vacancyHasRole,
        showStrengths,
      });
    }
  }, [
    stateExtras,
    vacancyHasCandidates,
    candidateHasRoleFitPage,
    featuresNext,
    isUserTeamLeader,
    vacancyHasRole,
    showStrengths,
  ]);

  // store params from mail dialogues in localStorage
  // after login user will be redirected to the respective page depending on params
  if (search.includes('mailType')) {
    let mailType;
    let assessmentId;
    const splittedValues = search.split('?mailType=');

    if (search.includes('assessmentId')) {
      [ mailType, assessmentId ] = splittedValues[1].split('&assessmentId=');
    } else {
      [ , mailType ] = splittedValues;
    }

    localStorage.setItem('mailType', mailType);
    localStorage.setItem('assessmentId', assessmentId);

    // this will prevent searchParams from being processed again in case of history.goBack()
    history.push(pathname);
  }

  // store: routing
  useEffect(() => {
    dispatch(setNextRoute(location));
  }, [ location, history, dispatch ]);

  // TRACKING
  const [ isTrackingSetUp, setIsTrackingSetUp ] = useState(false);
  // matomo tracking: setup after login
  useEffect(() => {
    if (isLoggedIn && TRACKING_ENABLE === 'ENABLE' && userMail && !isTrackingSetUp) {
      setIsTrackingSetUp(true);

      /* eslint-disable */
      // setup MATOMO
      let visitorId;
      const _paq = window._paq = window._paq || [];
      // tracker methods like "setCustomDimension" should be called before "trackPageView"
      _paq.push([ 'setUserId', userMail ]);
      _paq.push([ 'trackPageView' ]);
      _paq.push([ 'enableLinkTracking' ]);
      _paq.push([ function () {
        visitorId = this.getVisitorId();
        api.addCustomHeaders({ 'X-VISITOR-ID': visitorId });
      } ]);
      (function () {
        const u = '//analytics.bluquist.cloud/';
        _paq.push([ 'setTrackerUrl', `${u}matomo.php` ]);
        _paq.push([ 'setSiteId', TRACKING_SITE_ID ]); // <--- SITE ID
        const d = document; const g = d.createElement('script'); const
          s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.src = `${u}matomo.js`;
        s.parentNode.insertBefore(g, s);
      }());
      /* eslint-enable */
    }
  }, [ isLoggedIn, userMail, isTrackingSetUp ]);

  // Interface update after initial loading has to be slightly delayed via setState.
  // See https://blueexcellence.atlassian.net/browse/BQDQ-954
  const isConfigExtendedReady = userSettingsLoaded && userIsBasicDataReady && instanceFlavorLoaded
  && (IS_ADMIN_FRONTEND || (featuresNext && assessmentsNext && capabilitiesNext));
  const loading = isLoading || !translations || (isLoggedIn && !(isConfigExtendedReady || isCoachHub));
  const [ delayedLoading, setDelayedLoading ] = useState(true);
  useEffect(() => {
    setTimeout(() => setDelayedLoading(loading), 10);
  }, [ loading ]);

  return (
    <TranslateContext.Provider value={translateContextValue}>

      <Loading opaque visible={delayedLoading} />

      <Provider store={storeNext}>
        <LoadConfiguration onConfiguration={handleConfiguration} />

        <LoggedIn
          tokenId={authToken}
          tokenExpiration={tokenExpiration}
          userId={userId}
          userGroup={userRoleNext}
          currentUser={currentUser}
          profileCompleted={userProfileCompletedNext}
          userSettingsLoaded={userSettingsLoaded}
          onboardingCompleted={userOnboardingCompleted}
          userFirstName={userFirstName}
          userLastName={userLastName}
          features={featuresNext}
          assessments={assessmentsNext}
          capabilities={capabilitiesNext}
          switches={switches}
          stateExtras={stateExtras}
          currentLanguage={currentLanguage}
          isCurrentLanguageFetching={isCurrentLanguageFetching}
          languages={languages}
          translations={translations}
        />

        <MainLayout loading={delayedLoading} />
      </Provider>

    </TranslateContext.Provider>
  );
};

export default Main;
