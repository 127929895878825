// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionPersonalInfo.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext, Checkbox, Button } from 'ui/basic';
import { Legal } from 'features/framework/pages';

// UTILS
import { useTranslate } from 'utils/translator';
import REGEXES from 'utils/configuration/const/regexes';

// STORE
import * as api from 'api';

// CONFIG & DATA
const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
};


// COMPONENT: QuestionPersonalInfo
const QuestionPersonalInfo = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {},
    extras = {},
    goBack = () => {},
    confirm = () => {},
    setRenderOutside = () => {},
  } = props;

  const translate = useTranslate();

  // COMPONENT/UI STATE and REFS
  const [ errorMessage, setErrorMessage ] = useState();

  // FIRST NAME
  const [ firstName, setFirstName ] = useState('');
  useEffect(() => {
    setFirstName(answer.firstName);
  }, [ answer.firstName ]);

  const handleFirstName = (firstName) => {
    setFirstName(firstName);
    checkAnswers({
      firstName,
      lastName,
      phoneNumber,
      email,
      termsOfUseChecked,
      privacyPolicyChecked,
    });
  };

  // LAST NAME
  const [ lastName, setLastName ] = useState('');
  useEffect(() => {
    setLastName(answer.lastName);
  }, [ answer.lastName ]);

  const handleLastName = (lastName) => {
    setLastName(lastName);
    checkAnswers({
      firstName,
      lastName,
      phoneNumber,
      email,
      termsOfUseChecked,
      privacyPolicyChecked,
    });
  };

  // EMAIL
  const [ email, setEmail ] = useState('');
  const [ emailError, setEmailError ] = useState();
  useEffect(() => {
    setEmail(answer.email);
  }, [ answer.email ]);

  const handleEmail = (email, validate) => {
    setEmail(email);
    checkAnswers({
      firstName,
      lastName,
      email,
      phoneNumber,
      termsOfUseChecked,
      privacyPolicyChecked,
    });
  };

  // PHONE NUMBER
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ phoneNumberErrorMessage, setPhoneNumberErrorMessage ] = useState('');
  useEffect(() => {
    setPhoneNumber(answer.phoneNumber);
  }, [ answer.phoneNumber ]);

  const handlePhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    checkAnswers({
      firstName,
      lastName,
      phoneNumber,
      email,
      termsOfUseChecked,
      privacyPolicyChecked,
    });
  };

  // TERMS OF USE
  const [ termsOfUseChecked, setTermsOfUseChecked ] = useState();
  useEffect(() => {
    setTermsOfUseChecked(answer.termsOfUseChecked);
  }, [ answer.termsOfUseChecked ]);

  const handleTermsOfUse = (checked) => {
    setTermsOfUseChecked(checked);
    checkAnswers({
      firstName,
      lastName,
      phoneNumber,
      email,
      termsOfUseChecked: checked,
      privacyPolicyChecked,
    });
  };

  // PRIVACY POLICY
  const [ privacyPolicyChecked, setPrivacyPolicyChecked ] = useState();
  useEffect(() => {
    setPrivacyPolicyChecked(answer.privacyPolicyChecked);
  }, [ answer.privacyPolicyChecked ]);

  const handlePrivacyPolicy = (checked) => {
    setPrivacyPolicyChecked(checked);
    checkAnswers({
      firstName,
      lastName,
      phoneNumber,
      email,
      termsOfUseChecked,
      privacyPolicyChecked: checked,
    });
  };

  const [ isValid, setIsValid ] = useState(false);
  useEffect(() => {
    setIsValid(answer.isValid);
  }, [ answer.isValid ]);

  // EFFECTS: UPDATE BASED ON PROPS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // HELPERS

  useEffect(() => {
    if (!answer || Object.keys(answer).length === 0) {
      return;
    }

    const isValid = answer.firstName && answer.lastName && answer.email && answer.termsOfUseChecked && answer.privacyPolicyChecked
      && answer.firstName.length >= Config.minLengthFirstName
      && answer.lastName.length >= Config.minLengthLastName;

    setIsValid(isValid);
  }, [ answer ]);

  const checkAnswers = ({
    firstName, lastName, email, phoneNumber, termsOfUseChecked, privacyPolicyChecked,
  }) => {
    let phoneErrorMessage;
    if (phoneNumber && !phoneNumber.startsWith('+') && !phoneNumber.startsWith('00')) {
      phoneErrorMessage = translate('phone_number_country_code_required_error');
    } else {
      phoneErrorMessage = '';
    }

    const isValid = firstName && lastName && email && termsOfUseChecked && privacyPolicyChecked
      && firstName.length >= Config.minLengthFirstName
      && lastName.length >= Config.minLengthLastName
      && !emailError
      && !phoneErrorMessage;

    setPhoneNumberErrorMessage(phoneErrorMessage);
    setIsValid(isValid);

    const answer = {
      firstName, lastName, email, phoneNumber, termsOfUseChecked, privacyPolicyChecked, isValid,
    };
    onAnswer(answer);
  };

  const registerNewSubscription = (data) => {
    setIsValid(false);

    api.post('/core/subscription/register', data)
    .then(({ ok, status, data }) => {
      setIsValid(true);

      if (ok && status === 200) {
        const answer = {
          firstName,
          lastName,
          email,
          phoneNumber,
          termsOfUseChecked,
          privacyPolicyChecked,
          registrationRequestId: data.id,
          isValid: true,
        };

        confirm(answer);
      } else {
        setErrorMessage(data?.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
      setErrorMessage(error.message);
    });
  };

  const updateSubscription = (data, registrationRequestId) => {
    setIsValid(false);

    api.patch(`/core/subscription/register/${registrationRequestId}`, data)
    .then(({ ok, status, data }) => {
      setIsValid(true);

      if (ok && status === 200) {
        confirm();
      } else {
        setErrorMessage(data?.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
      setErrorMessage(error.message);
    });
  };

  // EVENT HANDLES
  const handleNext = () => {
    const { answers } = extras.state;

    const isValid = firstName && lastName && email && termsOfUseChecked && privacyPolicyChecked
      && firstName.length >= Config.minLengthFirstName
      && lastName.length >= Config.minLengthLastName
      && !emailError;

    if (isValid) {
      const payload = {
        companyId: answers[2]?.domain,
        companyName: answers[1]?.companyName,
        industry: answers[1]?.industry?.value,
        size: answers[1]?.employeesNumber?.value,
      };

      const adminUser = {
        mail: email,
        firstName,
        lastName,
      };

      const customer = {
        phone: phoneNumber,
      };

      Object.assign(payload, { adminUser, customer });

      const registrationRequestId = answers[3]?.registrationRequestId;
      if (registrationRequestId) {
        updateSubscription(payload, registrationRequestId);
      } else {
        registerNewSubscription(payload);
      }
    }
  };

  // MODALS
  const termsDialog = <Legal propSubPage='terms-of-use' onClose={() => setRenderOutside()} />;
  const privacyDialog = <Legal propSubPage='privacy-agreement' onClose={() => setRenderOutside()} />;

  const getParsedText = (text, content = 'terms') => {
    const joinedText = [];
    const part1 = text.split(/{{link./);
    if (part1.length > 1) {
      joinedText.push(part1[0]);
      joinedText.push(
        <span
          key={2}
          className={styles.policy}
          onClick={() => {
            if (content === 'terms') {
              setRenderOutside(termsDialog);
            } else {
              setRenderOutside(privacyDialog);
            }
          }}
        >
          { part1[1].split('}}')[0] }
        </span>,
      );
      joinedText.push(part1[1].split('}}')[1]);
    } else {
      joinedText.push(text);
    }
    return joinedText;
  };

  // RENDERS

  // RENDER: QuestionName
  return (
    <div className={classNames(styles.questionPersonalInfo)}>
      { errorMessage
        && <div className='error'>{ errorMessage }</div> }

      { /* FORM */ }
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={firstName}
          label={translate('cp_firstname_placeholder')}
          onChange={handleFirstName}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={lastName}
          label={translate('cp_lastname_placeholder')}
          onChange={handleLastName}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          type='email'
          size={size}
          value={email}
          label={translate('business_email')}
          validate={{
            pattern: REGEXES.EMAIL,
            onChange: true,
          }}
          errorMessage={emailError && (emailError.pattern
            ? (translate('admin_new_instance_admin_format_msg') || 'Please enter valid email')
            : 'Required?'
          )}
          onChange={handleEmail}
          onError={(error) => setEmailError(error)}
        />
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          type='tel'
          value={phoneNumber}
          label={translate('phone_number_optional')}
          errorMessage={phoneNumberErrorMessage}
          onChange={handlePhoneNumber}
        />
      </div>

      <div className={styles.checkBox}>
        <Checkbox
          name={getParsedText(translate('cp_cm_label_tos'), 'terms')}
          checked={termsOfUseChecked}
          onChange={handleTermsOfUse}
        />
      </div>

      <div className={styles.checkBox}>
        <Checkbox
          name={getParsedText(translate('cp_cm_label_privacy'), 'privacyPolicy')}
          checked={privacyPolicyChecked}
          onChange={handlePrivacyPolicy}
        />
      </div>

      <div className={styles.buttons}>
        <Button
          looks='secondary'
          onClick={goBack}
        >
          { translate('back_lbl') }
        </Button>
        <Button
          disabled={!isValid}
          onClick={() => {
            handleNext();
          }}
        >
          { translate('continue_lbl') }
        </Button>
      </div>
    </div>
  );
};

export default QuestionPersonalInfo;
