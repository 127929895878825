import { get, put as putApi } from 'api';
import * as action from 'store/actions';
import { call, takeLatest, put } from 'redux-saga/effects';


export function* listRecommendations() {
  try {
    const { ok, data } = yield call(get, '/core/recommendations/user');
    if (ok) {
      yield put(action.listRecommendationsFulfilled(data.recommendations));
    }
  } catch (error) {
    yield put(action.listRecommendationsRejected({ error }));
  }
}

export function* getRecommendation({ payload }) {
  const { id } = payload;
  try {
    const { ok, data } = yield call(get, `/core/recommendations/user/${id}`);
    if (ok) {
      yield put(action.getRecommendationFulfilled(data));
    }
  } catch (error) {
    yield put(action.getRecommendationRejected({ error }));
  }
}

export function* updateRecommendation({ payload }) {
  const { state, id } = payload;
  try {
    const { ok } = yield call(putApi, `/core/recommendations/user/${id}/state`, { state });
    if (ok) {
      yield put(action.updateRecommendationFulfilled({ id, state }));
    }
  } catch (error) {
    yield put(action.updateRecommendationRejected({ error }));
  }
}


export function* watchListRecommendationsRequest() {
  yield takeLatest(action.LIST_RECOMMENDATIONS, listRecommendations);
}

export function* watchGetRecommendationRequest() {
  yield takeLatest(action.GET_RECOMMENDATION, getRecommendation);
}

export function* watchUpdateRecommendationRequest() {
  yield takeLatest(action.UPDATE_RECOMMENDATION, updateRecommendation);
}

