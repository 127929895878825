export const INIT_STATE = 'INIT_STATE';

export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const DELETE_COMPANY_USER_FULFILLED = 'DELETE_COMPANY_USER_FULFILLED';
export const DELETE_COMPANY_USER_REJECTED = 'DELETE_COMPANY_USER_REJECTED';
export const INIT_DELETE_COMPANY_USER = 'INIT_DELETE_COMPANY_USER';

export const INIT_REMOVE_COMPANY_USER = 'INIT_REMOVE_COMPANY_USER';
export const REMOVE_COMPANY_USER = 'REMOVE_COMPANY_USER';
export const REMOVE_COMPANY_USER_FULFILLED = 'REMOVE_COMPANY_USER_FULFILLED';
export const REMOVE_COMPANY_USER_REJECTED = 'REMOVE_COMPANY_USER_REJECTED';

export const INIT_ADD_COMPANY_USERS = 'INIT_ADD_COMPANY_USERS';
export const ADD_COMPANY_USERS = 'ADD_COMPANY_USERS';
export const ADD_COMPANY_USERS_FULFILLED = 'ADD_COMPANY_USERS_FULFILLED';
export const ADD_COMPANY_USERS_REJECTED = 'ADD_COMPANY_USERS_REJECTED';

export const EDIT_COMPANY_USER_ROLE = 'EDIT_COMPANY_USER_ROLE';
export const EDIT_COMPANY_USER_ROLE_FULFILLED = 'EDIT_COMPANY_USER_ROLE_FULFILLED';
export const EDIT_COMPANY_USER_ROLE_REJECTED = 'EDIT_COMPANY_USER_ROLE_REJECTED';

