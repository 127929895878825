// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
import styles from './WellBeing.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InfoCard } from 'ui/basic';
import { WellBeingResult } from 'ui/molecules';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import { getWellBeingIndex } from 'store/actions';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';

// CONFIG & DATA
// const Config = {};

// COMPONENT: WellBeing
const WellBeing = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  // WELL-BEING: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const myWellBeing = useSelector(fromWellBeingSelectors.getMyWellBeing);
  const recommendations = useSelector(selectRecommendationsUnarchived);

  useEffect(() => {
    if (!myWellBeing) {
      dispatch(getWellBeingIndex());
    }
  }, [
    dispatch,
    myWellBeing,
  ]);

  // RENDER: WellBeing
  return (
    <div className={classNames(styles.wellBeing)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          { myWellBeing && (
            <WellBeingResult
              wellBeing={myWellBeing}
            />
          ) }
        </div>

        <div className={styles.right}>
          <InfoCard
            title={translate('wellbeing_info_title')}
            content={translate('wellbeing_ind_result_info_modal_description')}
          />
          { recommendations
          .filter((el) => el.module === 'well-being')
          .map((el) => <RecommendationCard key={el.id} {...el} />) }
        </div>
      </div>
    </div>
  );
};

export default WellBeing;
