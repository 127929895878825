// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './QuestionCodeConfirmation.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, Link, CodeInputGroup } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator/translator';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};

// COMPONENT: CodeConfirmation
const QuestionCodeConfirmation = (props) => {
  // PROPS
  const {
    extras = {},
    goBack = () => {},
    confirm = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ codeIsInvalid, setCodeIsInvalid ] = useState();
  const handleValidation = (code) => {
    const { answers } = extras.state;

    api.post('/core/subscription/register/validate', {
      companyId: answers[2]?.domain,
      confirmationCode: code,
    })
    .then(({ ok, status, data }) => {
      if (ok && status === 200 && data.valid) {
        confirm({ code, isValid: true });
        // onNext({code, isValid: true});
      } else {
        setCodeIsInvalid(true);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  };

  const [ errorMessage, setErrorMessage ] = useState();
  const handleCodeResend = () => {
    const { answers } = extras.state;
    const payload = {
      companyId: answers[2]?.domain,
      companyName: answers[1]?.companyName,
      industry: answers[1]?.industry?.value,
      size: answers[1]?.employeesNumber?.value,
    };

    const adminUser = {
      mail: answers[3]?.email,
      firstName: answers[3]?.firstName,
      lastName: answers[3]?.lastName,
    };

    const customer = {
      phone: answers[3]?.phoneNumber,
    };

    Object.assign(payload, { adminUser, customer });

    api.post('/core/subscription/register', payload)
    .then(({ ok, status, data }) => {
      if (!ok || status !== 200) {
        setErrorMessage(data?.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
      setErrorMessage(error.message);
    });
  };

  // RENDER: CodeConfirmation
  return (
    <div className={classNames(styles.questionCodeConfirmation)}>
      { errorMessage && <span className='error'>{ errorMessage }</span> }

      <div className='bluTypeCopyStrong'>
        { translate('trial_signup_confirmation_code_copy', [ '{{email}}', extras?.state?.answers[3]?.email ]) }
      </div>
      <div className={styles.codeInputGroupContainer}>
        <CodeInputGroup
          codeIsInvalid={codeIsInvalid}
          onValidate={handleValidation}
          forceUpperCase
        />
      </div>

      <Link onClick={handleCodeResend}>
        { translate('resend_code') }
      </Link>

      <Button looks='secondary' onClick={goBack}>
        { translate('change_email') }
      </Button>

      <div className='bluTypeCopy'>
        { translate('trial_signup_confirmation_code_hint') }
      </div>
    </div>
  );
};

export default QuestionCodeConfirmation;
