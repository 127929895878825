import React from 'react';
import styles from './ResetPassword.module.scss';

import classNames from 'classnames';

import { useParams } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';

import PoweredBy from 'ui/basic/micro-ui/PoweredBy';
import ResetPasswordForm from 'features/framework/components/ResetPasswordForm';
import ResetPasswordSuccessForm from 'features/framework/components/ResetPasswordSuccessForm';


const ResetPassword = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const error = useSelector((state) => state.resetPassword && state.resetPassword.error);
  const resetPasswordSuccessful = useSelector((state) => state.resetPassword.status === 'valid');

  const isBalancedYou = useSelector(fromSettingsSelectors.getCompanyIsBalancedYou);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  const handleResetPassword = (password) => {
    dispatch(resetPassword(password, params.token));
  };

  return (
    <div className={styles.resetPassword}>
      <div className={classNames(styles.container, { [styles.balancedYou]: isBalancedYou })}>
        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }

        { resetPasswordSuccessful
          ? <ResetPasswordSuccessForm />
          : (
            <ResetPasswordForm
              error={error && error.error && error.error.errorMessage}
              onResetPassword={handleResetPassword}
            />
          ) }
      </div>
    </div>
  );
};

export default ResetPassword;
