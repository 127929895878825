// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './DownloadFileButton.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Button, ProgressCircle } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: DownloadFileButton
const DownloadFileButton = ({
  downloading,
  handleDownload,
}) => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: DownloadIcon
  const renderDownloadIcon = () => {
    if (downloading) {
      return (
        <div className={styles.downloadLoader}>
          <ProgressCircle progress={20} loading />
        </div>
      );
    }

    return <IconsSvg.DownloadSvg />;
  };

  // RENDER: DownloadFileButton
  if (!handleDownload) {
    return null;
  }

  return (
    <div className={classNames(styles.downloadFileButton)}>
      <Button
        leadingIcon={renderDownloadIcon}
        size='S'
        looks='secondary'
        disabled={downloading}
        onClick={handleDownload}
      >
        { translate('disc_pdf_report_download') }
      </Button>
    </div>
  );
};

export default DownloadFileButton;
