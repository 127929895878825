import React, { useCallback, useEffect, useState } from 'react';
import styles from './WorkPreferencesResultSkeleton.module.scss';

const WorkPreferencesResultSkeleton = () => {
  const [ skeleton, setSkeleton ] = useState();

  const getSubItems = () => {
    const subItems = [];
    for (let i = 0; i < 18; i++) {
      subItems.push(
        <div key={`index-${i}`} className={styles.subItemsGroup}>
          <div className={styles.subItem1} />
          <div className={styles.subItem2} />
        </div>,
      );
    }
    return subItems;
  };

  const getSkeleton = useCallback(() => (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonBlock}>
        <div className={styles.header} />
        <div className={styles.subItems}>{ getSubItems() }</div>
      </div>
    </div>
  ), []);

  const handleResize = useCallback(() => {
    setSkeleton(getSkeleton());
  }, [ getSkeleton ]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ handleResize ]);

  return skeleton || getSkeleton();
};

export default WorkPreferencesResultSkeleton;
