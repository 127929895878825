import * as fromActionTypes from 'store/actionTypes/admin';

export const initAdminState = () => ({
  type: fromActionTypes.INIT_STATE,
  payload: {},
});

export const initAddCompanyUsers = () => ({
  type: fromActionTypes.INIT_ADD_COMPANY_USERS,
  payload: {},
});

export const addCompanyUsers = (addCompanyUsersDto) => ({
  type: fromActionTypes.ADD_COMPANY_USERS,
  payload: { addCompanyUsersDto },
});

export const addCompanyUsersFulfilled = () => ({
  type: fromActionTypes.ADD_COMPANY_USERS_FULFILLED,
  payload: {},
});

export const addCompanyUsersRejected = ({ error }) => ({
  type: fromActionTypes.ADD_COMPANY_USERS_REJECTED,
  payload: { error },
});

export const editCompanyUserRole = (userId, role, companyId) => ({
  type: fromActionTypes.EDIT_COMPANY_USER_ROLE,
  payload: { userId, role, companyId },
});

export const editCompanyUserFulfilled = () => ({
  type: fromActionTypes.EDIT_COMPANY_USER_ROLE_FULFILLED,
  payload: {},
});

export const editCompanyUserRejected = ({ error }) => ({
  type: fromActionTypes.EDIT_COMPANY_USER_ROLE_REJECTED,
  payload: { error },
});

// DELETE COMPANY USER
export const initDeleteCompanyUser = () => ({
  type: fromActionTypes.INIT_DELETE_COMPANY_USER,
  payload: {},
});

export const deleteCompanyUser = (userId, companyId, userName) => ({
  type: fromActionTypes.DELETE_COMPANY_USER,
  payload: { userId, companyId, userName },
});

export const deleteCompanyUserFulfilled = ({ deletedUserId, deletedUserName }) => ({
  type: fromActionTypes.DELETE_COMPANY_USER_FULFILLED,
  payload: { deletedUserId, deletedUserName },
});

export const deleteCompanyUserRejected = ({ error }) => ({
  type: fromActionTypes.DELETE_COMPANY_USER_REJECTED,
  payload: { error },
});

// REMOVE COMPANY USER
export const initRemoveCompanyUser = () => ({
  type: fromActionTypes.INIT_REMOVE_COMPANY_USER,
  payload: {},
});

export const removeCompanyUser = (userId, companyId, userName) => ({
  type: fromActionTypes.REMOVE_COMPANY_USER,
  payload: { userId, companyId, userName },
});

export const removeCompanyUserFulfilled = ({ removedUserId, removedUserName }) => ({
  type: fromActionTypes.REMOVE_COMPANY_USER_FULFILLED,
  payload: { removedUserId, removedUserName },
});

export const removeCompanyUserRejected = ({ error }) => ({
  type: fromActionTypes.REMOVE_COMPANY_USER_REJECTED,
  payload: { error },
});
