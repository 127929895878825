import { get } from 'api';
import { call, put, takeLeading } from 'redux-saga/effects';
import * as action from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/surveys';


export function* listSurveyParticipations() {
  try {
    const { status, ok, data } = yield call(get, '/surveys/participations');

    if (ok && status === 200) {
      yield put(action.listSurveyParticipationsFulfilled(data));
    } else {
      yield put(action.listSurveyParticipationsRejected(data));
    }
  } catch (error) {
    yield put(action.listSurveyParticipationsRejected({ error }));
  }
}

export function* watchListSurveyParticipations() {
  yield takeLeading(fromActionTypes.LIST_SURVEY_PARTICIPATIONS, listSurveyParticipations);
}
