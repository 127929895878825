import React from 'react';
import classNames from 'classnames';
import styles from './SegmentedLineDiagram.module.scss';

// CONFIG
const Config = {
  SIZE: 4,
};


const SegmentedLineDiagram = (props) => {
  const {
    score = 0,
    segmentsCount = 5,
    referenceValue = null,
    looks = 'blue',
  } = props;

  const halfSize = Config.SIZE / 2;

  // RENDER: mask, aka the gaps between the segments, as a series of white rectangles with concave sides.
  // Note: do NOT <mask> the lines directly because Chrome is buggy and won't update the mask width on resize.
  // If a non-white fillColor is needed, change it from the parent component (eg. SteppedScaleResultItem).
  const renderMask = () => (
    <>
      <mask id='segmentedLineGapMask'>
        <rect
          fill='white'
          width='100%'
          height={Config.SIZE}
        />
        <circle
          fill='black'
          cx={0}
          cy={halfSize}
          r={halfSize}
        />
        <circle
          fill='black'
          cx={Config.SIZE * 2}
          cy={halfSize}
          r={halfSize}
        />
      </mask>
      { Array(segmentsCount + 1).fill().map(((_, i) => (
        // g required for Safari/Firefox: https://stackoverflow.com/a/59747870/8042204
        <g
          key={i} // eslint-disable-line react/no-array-index-key
          className={styles.maskWrapper}
        >
          <svg
            x={`${(i / segmentsCount) * 100}%`}
            y={Config.SIZE * 2}
          >
            <rect
              width={Config.SIZE * 2}
              height={Config.SIZE}
              mask='url(#segmentedLineGapMask)'
              className={styles.fillColor}
            />
          </svg>
        </g>
      ))) }
    </>
  );

  // RENDER: line
  const renderLine = (percentage, fill) => (
    <svg
      className={classNames(styles.diagram, styles[fill])}
      y={Config.SIZE * 2}
    >
      <rect
        width={`${percentage}%`}
        height={Config.SIZE}
        rx={halfSize}
        className={classNames({ [styles.animate]: fill })}
      />
    </svg>
  );

  // RENDER: reference value
  const renderReferenceValue = () => {
    if (typeof referenceValue !== 'number') {
      return null;
    }

    let translateX = -halfSize;
    if (referenceValue <= 0) {
      translateX += halfSize;
    }
    if (referenceValue >= segmentsCount) {
      translateX -= halfSize;
    }

    return (
      <rect
        className={classNames(styles.referenceLine, styles[looks])}
        x={`${(referenceValue / segmentsCount) * 100}%`}
        transform={`translate(${translateX}, 0)`}
      />
    );
  };

  // RENDER: SegmentedLineDiagram
  return (
    <svg className={styles.segmentedLineDiagramNext}>
      { renderLine(100) }
      { renderLine((score / segmentsCount) * 100, looks) }
      { renderMask() }
      { renderReferenceValue() }
    </svg>
  );
};

export default SegmentedLineDiagram;
