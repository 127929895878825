// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './SurveyEnd.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import { useHistory } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { getTokenNameForCurrentPage } from 'api';

// CONFIG & DATA
// const Config = {};

// COMPONENT: SurveyEnd
const SurveyEnd = (props) => {
  // PROPS
  const {
    finishable = false,
    errorEnd,
  } = props;

  // SPECIAL HOOKS
  const history = useHistory();
  const translate = useTranslate();

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);

  const handleButtonClick = () => {
    if (getTokenNameForCurrentPage().includes('surveyToken')) {
      const link = companySettings.linkoutButtonUrl || 'https://bluquist.com/';
      window.open(link, '_self');
      return;
    }

    history.push('/');
  };

  const renderButtonTitle = () => {
    const externalButtonLabel = companySettings.linkoutButtonLabel
      ? translate(companySettings.linkoutButtonLabel)
      : translate('bq_learn_more');

    return getTokenNameForCurrentPage().includes('surveyToken')
      ? externalButtonLabel
      : translate('survey_go_to_overview');
  };

  // RENDER: AssessmentEnd
  return (
    <div className={classNames(styles.surveyEnd)}>
      { errorEnd }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>
          <div className={classNames('bluTypeS', 'marginTopXs')}>
            { translate('survey_completed_headline') }
          </div>
          <div className={classNames('bluTypeCopy', 'marginTopXs')}>
            { translate('survey_completed_copy') }
          </div>

          <Button
            size='M'
            looks='secondary'
            disabled={!finishable}
            onClick={handleButtonClick}
          >
            { renderButtonTitle() }
          </Button>
        </>
      ) }
    </div>
  );
};

export default SurveyEnd;
