// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './LoginMfa.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { SUPPORT_LINK } from 'utils/configuration';

// STORE
import { useDispatch } from 'react-redux';
import { login } from 'store/actions';

// CONFIG & DATA
const Config = {
  totpLength: 6,
};


// COMPONENT: LoginMfa
const LoginMfa = (props) => {
  // PROPS
  const {
    loginData,
    error,
    company,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ totp, setTotp ] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login({
      authToken: loginData.authToken,
      totp,
      company,
      extendedSession: loginData.extendedSession,
    }));
  };
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: LoginMfa
  return (
    <div className={classNames(styles.loginMfa)}>
      <div className={styles.title}>
        { translate('login_mfa_title') }
      </div>
      <div className={styles.subTitle}>
        { translate(`login_mfa_subtitle_${loginData.mfaMode}`) }
      </div>

      <form onSubmit={handleSubmit}>
        <InputNext
          label={translate('login_mfa_auth_label')}
          value={totp}
          validate={{
            required: true,
            min: Config.totpLength,
            max: Config.totpLength,
          }}
          autofocus
          errorMessage={!totp && error}
          onChange={(value = '') => setTotp(value.toUpperCase())}
        />

        <div className={styles.actions}>
          <a
            href={SUPPORT_LINK}
            target='_blank'
            rel='noreferrer'
          >
            { translate('login_mfa_support') }
          </a>
          <Button
            type='submit'
            disabled={totp.length !== Config.totpLength}
          >
            { translate('confirm_lbl') }
          </Button>
        </div>
      </form>

      <div>
        { translate(`login_mfa_note_${loginData.mfaMode}`) }
      </div>
    </div>
  );
};

export default LoginMfa;
