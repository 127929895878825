export const selectGarmin = (state) => state.garmin;

export const selectGarminUserScore = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.userScore;
};

export const selectGarminUserScorePending = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.userScorePending;
};

export const selectGarminConnectionStatus = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.accountConnected;
};

export const selectGarminUserHistory = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.userHistory;
};

export const selectGarminOrgScore = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.orgScore;
};

export const selectGarminOrgScorePending = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.orgScorePending;
};

export const selectGarminOrgHistory = (state) => {
  const garmin = selectGarmin(state);
  return garmin && garmin.orgHistory;
};
