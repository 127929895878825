import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import LeadershipCompetenciesAssessmentEnd from '../intermissions/LeadershipCompetenciesAssessmentEnd';

// CONFIG

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// OTHER COMPONENTS


export const leadershipCompetenciesConfig = {
  id: 'leadershipCompetencies',
  title: 'Leadership Competencies',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'lscomp_assessment_help_info_content',
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: (state) => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
          onClickNext={() => next()}
        />
      ),
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next, prev, state) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
          onClickNext={() => next()}
        />
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => <LeadershipCompetenciesAssessmentEnd {...extras} />,
    },
  ],
};
