// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './ModalAssessmentsConfiguration.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useParams } from 'react-router';

// OTHER COMPONENTS
import {
  Checkbox,
  LoadingHint, Modal, StoreNext, Toggle,
} from 'ui/basic';
import { LegalText } from 'features/+employees/pages/EmployeeProfile/components/LegalText';
import { AssessmentsList } from 'features/+employees/pages/EmployeeProfile/components/AssessmentsList';

// UTILS
import * as api from 'api';
import { apiNext } from 'apiNext';
import { COMPANY_ID } from 'utils/configuration';
import { useTranslate } from 'utils/translator';
import {
  ASSESSMENT_DESCRIPTIONS,
  ASSESSMENT_TITLES,
  ASSESSMENT_TYPES,
  ASSESSMENT_RECURRENCE_TYPES,
} from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';

// STORE
import { getEmployee, listAssessments, showToast } from 'store/actions';
import { getCurrentUserId } from 'store/selectors/currentUser';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import {
  selectCapabilitiesNext,
  selectInstanceContainsBluquistFeatures,
} from 'store/selectors/configuration';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

// CONFIG & DATA
import { getPriceFromCents } from 'features/+adminUG/config/payment.config';
import { ResetAssessmentHandle } from 'ui/basic/micro-ui/ResetAssessmentHandle';


// COMPONENT: ModalAssessmentsConfiguration
const ModalAssessmentsConfiguration = (props) => {
  // PROPS
  const {
    userId,
    showGrantManagementToggle = false,
    onClose = () => {},
    onUpdate = () => {},
    showAssessmentResetHandle = false,
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const params = useParams();

  const assessmentsFromStore = useSelector((state) => fromAssessmentsSelectors.selectAssessments(state, [
    fromAssessmentsSelectors.ASSESSMENT_FILTERS.noClifton,
    fromAssessmentsSelectors.ASSESSMENT_FILTERS.forUserGroup,
    fromAssessmentsSelectors.ASSESSMENT_FILTERS.noAsPeer,
  ]));

  const currentUserId = useSelector(getCurrentUserId);

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  // Include paid assessments if missing (trial case, showoff only)
  if (me.roleNext !== MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER) {
    if (!assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.RMP)) {
      assessmentsFromStore.push({ id: ASSESSMENT_TYPES.RMP, premium: true });
    }
    if (!assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.NINE_LEVELS)) {
      assessmentsFromStore.push({ id: ASSESSMENT_TYPES.NINE_LEVELS, premium: true });
    }
  }

  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const containsBluquistFeatures = useSelector(selectInstanceContainsBluquistFeatures);

  const [ processing, setProcessing ] = useState(false);

  // ASSESSMENTS PRICES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ assessmentsPrices, setAssessmentsPrices ] = useState({});
  const [ profile, setProfile ] = useState();
  useEffect(() => {
    Promise.all([
      api.get('/core/company/billing/plan/prices'),
      api.get('/core/user/profile', { id: userId, includeDeactivated: true }),
    ])
    .then(([ prices, userProfile ]) => {
      if (prices.ok) {
        setAssessmentsPrices(prices.data.prices?.assessments);
      }
      if (userProfile.ok) {
        const { profile: assessmentResults } = userProfile.data;
        setProfile({ userId, assessmentResults });
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, [ userId ]);

  // USER FEATURES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ userFeatures, setUserFeatures ] = useState([]);
  const [ userFeaturesRequested, setUserFeaturesRequested ] = useState(false);
  useEffect(() => {
    if (userFeaturesRequested) {
      return;
    }

    api.get(`/core/company/users/${userId}/features`)
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setUserFeatures(data.features);
      }
    });

    setUserFeaturesRequested(true);
  }, [ userId, userFeaturesRequested ]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const chargeableAssessments = [];
  const notChargeableAssessments = [];
  if (containsBluquistFeatures) {
    assessmentsFromStore.forEach((assessmentFromStore) => {
      const { id: assessmentId, premium } = assessmentFromStore;
      const isCustomAssessment = !Object.values(ASSESSMENT_TYPES).includes(assessmentId);
      const active = Boolean(userFeatures
      ?.find(({ id: featureId, category }) => featureId?.toLowerCase() === `${category}_${assessmentId}`.toLowerCase())
      ?.active);
      const purchasedAt = profile?.assessmentResults
      ?.find(({ assessment }) => assessment === assessmentId)
      ?.purchasedAt;
      const targetList = premium ? chargeableAssessments : notChargeableAssessments;
      targetList.push({
        ...assessmentFromStore,
        isCustomAssessment,
        purchasedAt,
        active,
      });
    });
  }

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ assessmentToBuy, setAssessmentToBuy ] = useState();

  // HANDLERS
  const handleAssessmentChange = (assessment, assessmentActivated) => {
    let thisAssessment;
    if (chargeableAssessments.find((a) => a.id === assessment.id)) {
      thisAssessment = chargeableAssessments.find((a) => a.id === assessment.id);
    } else if (notChargeableAssessments.find((a) => a.id === assessment.id)) {
      thisAssessment = notChargeableAssessments.find((a) => a.id === assessment.id);
    }

    if (!thisAssessment) {
      return;
    }

    setProcessing(true);
    thisAssessment.active = assessmentActivated;

    const newUserFeatures = notChargeableAssessments.concat(chargeableAssessments)
    .filter((el) => el.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN)
    .map(({ id: assessmentId, isCustomAssessment, active }) => {
      const category = isCustomAssessment ? 'custom-assessment' : 'assessment';
      let id = `${category}_${assessmentId}`;
      if (!isCustomAssessment) {
        id = id.toUpperCase();
      }
      return { id, active };
    });

    api.put(`/core/company/users/${userId}/features`, { features: newUserFeatures })
    .then(({ ok, status }) => {
      setProcessing(false);
      onUpdate();

      if (ok && status === 200) {
        const assessmentState = assessmentActivated ? 'activated' : 'deactivated';
        dispatch(showToast(
          translate(`user_settings__assessment_${assessmentState}_toast_title`),
          translate(`user_settings__assessment_${assessmentState}_toast_content`),
        ));
        // trigger features request again
        setUserFeaturesRequested(false);

        // update assessments list
        if (currentUserId === userId) {
          dispatch(listAssessments());
        } else {
          dispatch(getEmployee(userId));
        }

        // for chargeable assessments
        // after purchasing close the confirmation modal and open the assessments settings modal
        if (assessment.premium && assessmentActivated) {
          setShowConfirmation(false);
        }
      }
    })
    .catch((error) => {
      setProcessing(false);
      console.error(error.message);
    });
  };

  const [ assessmentsIdsForManaging, setAssessmentsIdsForManaging ] = useState([]);
  useEffect(() => {
    apiNext.get('/core/access/assessment', {
      user: params.employeeId,
      company: COMPANY_ID,
    })
    .then(({ users }) => {
      const thisUser = users.find((u) => u.user === params.employeeId);
      if (thisUser) {
        setAssessmentsIdsForManaging(thisUser.assessments || []);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ params.employeeId ]);

  const handleAssessmentAccessScopeChange = (checked, assessmentId) => {
    setProcessing(true);

    const assessmentsIdsForManagingInternal = [ ...assessmentsIdsForManaging ];
    if (checked && !assessmentsIdsForManagingInternal.includes(assessmentId)) {
      assessmentsIdsForManagingInternal.push(assessmentId);
    } else {
      const thisAssessmentIndex = assessmentsIdsForManagingInternal.findIndex((aId) => aId === assessmentId);
      assessmentsIdsForManagingInternal.splice(thisAssessmentIndex, 1);
    }

    apiNext.post('/core/access/assessment', {
      user: params.employeeId,
      company: COMPANY_ID,
      assessments: assessmentsIdsForManagingInternal,
    }).then(() => {
      setAssessmentsIdsForManaging(assessmentsIdsForManagingInternal);
    })
    .catch((error) => {
      console.error(error.message);
    })
    .finally(() => {
      setProcessing(false);
    });
  };

  const handleAssessmentBuy = (assessmentId) => {
    setAssessmentToBuy(assessmentId);

    setTimeout(() => {
      setShowConfirmation(true);
    }, 250);
  };

  const handleConfirm = () => {
    handleAssessmentChange({
      id: assessmentToBuy,
      premium: true,
    }, true);
  };

  // RENDER: ModalAssessmentsConfiguration
  return (
    <div className={classNames(styles.modalAssessmentsConfiguration)}>
      { showConfirmation && (
        <Modal
          header={translate('premium_assessments_purchase_confirmation_title')}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('confirm_lbl')}
          primaryButtonDisabled={processing}
          closeOnConfirm={false}
          onClose={() => setShowConfirmation(false)}
          onConfirm={handleConfirm}
        >
          <>
            <div className='bluTypeCopy'>
              { translate('premium_assessments_purchase_confirmation_copy') }
            </div>
            <div className={styles.flexRow}>
              <span className='bluTypeLabelL'>
                { translate(ASSESSMENT_TITLES[assessmentToBuy]) }
              </span>
              <span className='bluTypeLabelL'>
                { getPriceFromCents(
                  assessmentsPrices[assessmentToBuy]?.pricePerUnit,
                  assessmentsPrices[assessmentToBuy]?.currency,
                ) }
                { ` (${translate('one_time')})` }
              </span>
            </div>
          </>
        </Modal>
      ) }

      { !showConfirmation && (
        <Modal
          header={translate('user_settings_configure_assessments_title')}
          secondaryButtonTitle={translate('close_lbl')}
          onClose={onClose}
        >
          <>
            { translate('user_settings_configure_assessments_copy') }

            { (containsBluquistFeatures && (!userFeatures.length || profile?.userId !== userId))
              ? (
                <div className='marginTopS'>
                  <LoadingHint />
                </div>
              ) : (
                <>
                  <div className={styles.list}>
                    { notChargeableAssessments.map((assessment) => {
                      const assessmentFromProfile = profile?.assessmentResults
                      ?.find(({ assessment: aId }) => aId === assessment.id);
                      const done = assessmentFromProfile?.progress === 1;
                      return (
                        <div className={styles.listItem} key={assessment.id}>
                          <div className={styles.row1}>
                            <span className='bluTypeLabelL'>
                              { translate(ASSESSMENT_TITLES[assessment.id]) || assessment.title }

                              { assessment.active && (
                                ` (${translate(`reset_assessments__${done ? 'completed' : 'open'}_status`)})`
                              ) }

                              { !assessment.active && ` (${translate('not_available_lbl')})` }
                            </span>
                            { assessment.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.RUN && (
                              <Toggle
                                id={`${assessment.id}_employeeProfile`}
                                checked={assessment.active}
                                disabled={processing}
                                onChange={(value) => handleAssessmentChange(assessment, value)}
                              />
                            ) }
                          </div>

                          <div className={styles.row2}>
                            { translate(ASSESSMENT_DESCRIPTIONS[assessment.id]) || translate(assessment.description) }
                          </div>

                          { assessment.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.RUN && (
                            <div className={styles.runNote}>
                              { translate('user_settings_configure_assessments_run_note') }
                            </div>
                          ) }

                          { showAssessmentResetHandle && (
                            <div className={styles.row3}>
                              <ResetAssessmentHandle
                                userId={userId}
                                assessmentId={assessment.id}
                                assessmentProtected={Boolean(assessment.self_only)}
                                assessmentProgress={assessmentFromProfile?.progress}
                                onConfirm={onUpdate}
                              />
                            </div>
                          ) }
                          { showGrantManagementToggle && (
                            <div className='marginTopXs'>
                              <Checkbox
                                name={translate('grant_management_access_lbl')}
                                checked={assessmentsIdsForManaging.includes(assessment.id)}
                                disabled={processing}
                                onChange={(value) => {
                                  handleAssessmentAccessScopeChange(value, assessment.id);
                                }}
                              />
                            </div>
                          ) }
                        </div>
                      );
                    }) }
                  </div>

                  { /* CHARGEABLE ASSESSMENTS */ }
                  { Boolean(chargeableAssessments?.length) && (
                    <div className='marginTopS'>
                      <span className='bluTypeLabelL'>
                        { translate('chargeable_assessments') }
                      </span>

                      <StoreNext>
                        <LegalText />

                        <AssessmentsList
                          userId={userId}
                          profile={profile}
                          onResetConfirm={onUpdate}
                          assessments={chargeableAssessments}
                          prices={assessmentsPrices}
                          togglesDisabled={processing
                            || !capabilitiesNext.premiumAssessmentBooking}
                          premiumAssessmentsCanBePurchased={
                            capabilitiesNext.premiumAssessmentBooking
                          }
                          showGrantManagementAccess={showGrantManagementToggle}
                          assessmentsIdsForManaging={assessmentsIdsForManaging}
                          onAssessmentChange={handleAssessmentChange}
                          onAssessmentBuy={handleAssessmentBuy}
                          showAssessmentResetHandle={showAssessmentResetHandle}
                          onAssessmentAccessScopeChange={handleAssessmentAccessScopeChange}
                        />
                      </StoreNext>
                    </div>
                  ) }
                </>
              ) }
          </>
        </Modal>
      ) }
    </div>
  );
};

export default ModalAssessmentsConfiguration;
