import React, { lazy } from 'react';

// eslint-disable-next-line import/no-cycle
import {
  AdminUserGroup,
  BasicUserGroup,
  CandidateUserGroup,
  LeaderUserGroup,
  CandidateCompleteProfile,
  CompleteProfileNext,
  Info,
  Legal,
  MyOrganization,
  Assessments,
  Overview,
  ResetPassword,
  ResetPasswordRequest,
  Settings,
  SignUp,
  Login,
  LogoutSuccessForm,
  ExternalProfile,
  Debug,
  ReportPreview,
  ProfileDeleted,
  WellBeingMeasurement,
  NonexistentInstance,
  Maintenance,
  VacancyExternalProfile,
  MyProfileOverview,
  MyProfileRecommendations,
  MyProfileWellBeing,
  MyStrengthProfile,
  Dashboards, AssessmentManagerUserGroup,
} from './pages';
// eslint-disable-next-line import/no-named-as-default
import PlanExpired from './pages/PlanExpired';
import { IS_ADMIN_FRONTEND, SUPPORT_LINK } from 'utils/configuration';
import { COACHHUB_TARGETS } from 'features/+coachHub/routeNext';
import { getCoachHubParam } from 'features/+coachHub/utils/localStorage';

const CoachHubMain = lazy(() => import('features/+coachHub/pages/CoachHubMain' /* webpackChunkName: "coachHub" */));
const UserManagement = lazy(() => import('features/+adminUG/pages/UserManagement' /* webpackChunkName: "adminUG" */));
const OrganisationSettings = lazy(() => (
  import('features/+adminUG/pages/OrganisationSettings' /* webpackChunkName: "adminUG" */)
));
const UserIndividualSettings = lazy(() => (
  import('features/+adminUG/pages/UserIndividualSettings' /* webpackChunkName: "adminUG" */)
));
const SettingsPayment = lazy(() => import('features/+adminUG/pages/SettingsPayment' /* webpackChunkName: "adminUG" */));


// eslint-disable-next-line import/prefer-default-export
export const routesConfigFramework = {

  show: {
    forFlavor: 'all',
    forUserGroup: 'all',
  },

  routes: [
    // FRAMEWORK ROUTES: NO LOGIN REQUIRED
    {
      path: '/login',
      pageType: 'standalone',
      PageComponent: () => <Login />,
      show: {
        needsLogout: true,
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      tools: {
        browserTitleKey: 'login_button_login',
      },
    },
    {
      path: '/logged-out',
      pageType: 'standalone',
      PageComponent: () => <LogoutSuccessForm />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'pp_button_logout',
      },
    },
    {
      path: '/new-account',
      pageType: 'standalone',
      PageComponent: () => <SignUp />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'signup_button_signup',
      },
    },
    {
      path: '/recovery-password/:token',
      pageType: 'standalone',
      PageComponent: () => <ResetPassword />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'resetpw_title',
      },
    },
    {
      path: '/recovery-password',
      pageType: 'standalone',
      PageComponent: () => <ResetPasswordRequest />,
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'resetpw_title',
      },
    },
    {
      path: '/profile-deleted',
      pageType: 'standalone',
      PageComponent: () => <ProfileDeleted />,
      show: {
        needsLogout: true,
      },
    },
    {
      path: '/debug',
      pageType: 'modal',
      PageComponent: () => <Debug />,
      show: {
        needsLogin: false,
      },
    },
    {
      path: '/report-preview',
      PageComponent: () => <ReportPreview />,
      show: {
        show: () => !IS_ADMIN_FRONTEND && process.env.NODE_ENV !== 'production',
      },
    },
    {
      path: '/info',
      pageType: 'modal',
      useStoreNext: true,
      PageComponent: () => <Info />,
      show: {
        needsLogin: false,
      },
    },
    {
      path: '/wb-measurement',
      pageType: 'modal',
      PageComponent: () => <WellBeingMeasurement />,
      show: {
        needsLogin: false,
      },
    },
    {
      path: '/nonexistent-instance',
      pageType: 'standalone',
      PageComponent: () => <NonexistentInstance />,
      useStoreNext: true,
      show: {
        needsLogin: false,
      },
    },
    {
      path: '/maintenance',
      pageType: 'standalone',
      pageWrapper: {
        noHeaderLogoLink: true,
      },
      PageComponent: () => <Maintenance />,
      show: {
        needsLogin: false,
      },
    },


    // SHARING USER PROFILE
    {
      path: '/share/:token',
      pageType: 'standalone',
      PageComponent: () => <ExternalProfile />,
      show: {
        needsLogin: false,
        forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
      },
    },

    // SHARING VACANCY
    {
      path: '/vacancy/:vacancyId/share/:token',
      pageType: 'standalone',
      PageComponent: () => <VacancyExternalProfile />,
      show: {
        needsLogin: false,
      },
    },

    // MAIN NAV ROUTES
    {
      path: '/',
      PageComponent: () => <Overview />,
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      pageWrapper: {
        autoMargins: false,
      },
      inMainNav: {
        forceIndex: 0,
        labelKey: 'main_navigation_item_1',
      },
    },
    {
      path: '/my-profile',
      PageComponent: () => <MyProfileOverview />,
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
        forUserGroup: 'all',
      },
      inMainNav: {
        labelKey: 'main_navigation_item_6',
      },
      subRoutes: [
        {
          path: '/my-profile',
          PageComponent: () => <MyProfileOverview />,
          inSubNav: {
            labelKey: 'main_navigation_item_1',
          },
        },
        {
          path: '/my-profile/well-being',
          PageComponent: () => <MyProfileWellBeing />,
          inSubNav: {
            labelKey: 'wellbeing_ind_result_title',
          },
          show: {
            forAnyFeature: [ 'wellbeing', 'balancedYou', 'garmin', 'ist' ],
            forUserGroup: [ 'employee', 'leader', 'assessmentManager', 'admin' ], // all but candidate
          },
        },
        {
          path: '/my-profile/strength-profile',
          PageComponent: () => <MyStrengthProfile />,
          inSubNav: {
            labelKey: 'strength_profile_nav',
          },
          show: (state, stateExtras) => stateExtras.showStrengths,
        },
        {
          path: '/my-profile/recommendations',
          PageComponent: () => <MyProfileRecommendations />,
          inSubNav: {
            labelKey: 'recommendations_nav',
          },
        },
      ],
    },
    {
      path: '/my-organization',
      PageComponent: () => <MyOrganization />,
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
        forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
      },
      inMainNav: {
        labelKey: 'main_navigation_item_9',
      },
      subRoutes: [
        {
          path: '/my-organization',
          inSubNav: {
            labelKey: 'main_navigation_item_1',
          },
        },
        {
          path: '/my-organization/assessments',
          PageComponent: () => <Assessments />,
          inSubNav: {
            labelKey: 'main_navigation_item_5',
          },
          tools: {
            browserTitleKey: 'main_navigation_item_5',
          },
        },
        {
          path: '/my-organization/dashboards',
          PageComponent: () => <Dashboards />,
          inSubNav: {
            labelKey: 'dashboards',
          },
          useStoreNext: true,
          show: (state) => {
            const { configuration = {} } = state;
            const { company = {} } = configuration;
            return company.dashboards?.length > 0;
          },
          tools: {
            browserTitleKey: 'dashboards',
          },
        },
      ],
    },
    // for the cases when 'My-organization' page is not accessible for the user
    // but there are dashboards available to show
    {
      path: '/my-organization/dashboards',
      PageComponent: () => <Dashboards />,
      inMainNav: {
        labelKey: 'main_navigation_item_9',
      },
      show: (state) => {
        const { company = {}, instance = {}, user = {} } = state.configuration;

        return (![ 'leader', 'assessmentManager', 'admin' ].includes(user.userGroup)
            || ![ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ].includes(instance.flavor))
          && company.dashboards?.length > 0;
      },
    },
    {
      path: '/settings',
      PageComponent: () => <Settings />,
      pageWrapper: {
        background: 'white',
      },
      inMainNav: {
        labelKey: 'main_navigation_item_8',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_8',
      },
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      subRoutes: [
        {
          path: '/settings',
          inSubNav: {
            labelKey: 'settings_subnav_general',
          },
        },
        {
          path: '/settings/users',
          PageComponent: () => <UserManagement />,
          show: {
            forUserGroup: 'admin',
          },
          inSubNav: {
            labelKey: 'settings_subnav_user_management',
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8',
          },
        },
        {
          path: '/settings/user-groups/basic',
          PageComponent: () => <BasicUserGroup />,
          useStoreNext: true,
          show: {
            forUserGroup: 'admin',
            forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
          },
          asBreadcrumb: {
            labelKey: 'settings_subnav_usergroups_management',
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8',
          },
          subRoutes: [
            {
              path: '/settings/user-groups/basic',
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_basic',
              },
            },
            {
              path: '/settings/user-groups/candidate',
              PageComponent: () => <CandidateUserGroup />,
              useStoreNext: true,
              inSubNav: {
                labelKey: 'candidates_nav',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8',
              },
            },
            {
              path: '/settings/user-groups/leader',
              useStoreNext: true,
              PageComponent: () => <LeaderUserGroup />,
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_leader',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8',
              },
            },
            {
              path: '/settings/user-groups/assessment-manager',
              PageComponent: () => <AssessmentManagerUserGroup />,
              inSubNav: {
                labelKey: 'platform_roles_assessment_manager',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8',
              },
            },
            {
              path: '/settings/user-groups/admin',
              useStoreNext: true,
              PageComponent: () => <AdminUserGroup />,
              inSubNav: {
                labelKey: 'settings_subnav_usergroups_admin',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_8',
              },
            },
          ],
        },
        {
          path: '/settings/organisation',
          PageComponent: () => <OrganisationSettings />,
          show: {
            forUserGroup: 'admin',
          },
          inSubNav: {
            labelKey: 'settings_subnav_organisation',
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8',
          },
        },
        {
          path: '/settings/users/:userId',
          PageComponent: () => <UserIndividualSettings />,
          show: {
            forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
          },
          asBreadcrumb: {},
          tools: {
            browserTitleKey: 'main_navigation_item_8',
          },
          subRoutes: [],
        },
        {
          path: '/settings/payment',
          PageComponent: () => <SettingsPayment />,
          useStoreNext: true,
          show: {
            forPlan: 'active',
            forUserGroup: 'admin',
            forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
          },
          inSubNav: {
            labelKey: 'billing_subscription',
          },
          tools: {
            browserTitleKey: 'main_navigation_item_8',
          },
        },
      ],
    },

    // SUPPORT
    {
      path: SUPPORT_LINK,
      pageType: 'external',
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      inMainNav: {
        labelKey: 'main_navigation_item_sec_1',
        linkType: 'small',
      },
    },
    {
      path: '/legal/:routerSubPage',
      pageType: 'modal',
      pageWrapper: {
        instantBackground: true, // prevent flash of MainLayout when
        // navigating from standanlone (e.g. login) to modal page (e.g. legal)
      },
      useStoreNext: true,
      PageComponent: () => <Legal />,
      show: {
        needsLogin: false,
      },
      inMainNav: {
        labelKey: 'main_navigation_item_sec_2',
        linkType: 'small',
        mainNavPath: '/legal/data-protection-privacy',
      },
    },


    // PLAN & PAYMENT
    {
      path: '/restricted',
      pageType: 'standalone',
      PageComponent: () => <PlanExpired />,
      useStoreNext: true,
      show: {
        forPlan: 'restricted',
        forUserGroup: [ 'candidate', 'employee', 'leader', 'assessmentManager' ],
        needsLogin: false,
      },
    },
    // {
    //   path: '/trial-only',
    //   pageType: 'standalone',
    //   PageComponent: () => <h1>Only During Trial</h1>,
    //   useStoreNext: true,
    //   show: {
    //     forPlan: 'trial',
    //   }
    // },


    // FRAMEWORK ROUTES: LOGIN REQUIRED
    {
      path: '/complete-profile',
      pageType: 'modal',
      pageWrapper: { instantBackground: true, noAnimate: true },
      PageComponent: () => <CompleteProfileNext />,
      useStoreNext: true,
      show: {
        forFlavor: [ 'bluquist', 'balancedYou', 'bluquistAndBalancedYou' ],
        forUserGroup: [ 'employee', 'leader', 'assessmentManager', 'admin' ],
        needsProfileCompleted: false,
      },

    },
    {
      path: '/complete-profile',
      pageType: 'modal',
      pageWrapper: { instantBackground: true, noAnimate: true },
      PageComponent: () => <CandidateCompleteProfile />,
      useStoreNext: true,
      show: {
        forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
        forUserGroup: 'candidate',
        needsProfileCompleted: false,
      },
    },
    // EXTERNAL SYSTEM ROUTES
    {
      path: '/action-link',
      pageType: 'coachHub',
      pageWrapper: {
        noFramework: true,
      },
      PageComponent: () => <CoachHubMain />,
      show: {
        needsLogin: false,
      },
      LazyPageComponent: 'ActionLink',
    },
    {
      path: '/my-profile',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        needsLogin: true,
        needsProfileCompleted: 'ignore',
        show: () => [ COACHHUB_TARGETS.PROFILE, COACHHUB_TARGETS.REPORT ].includes(getCoachHubParam('target', false)),
      },
      LazyPageComponent: 'MyProfile',
    },
    {
      path: '/assessments/:customAssessmentId',
      pageType: 'modal',
      PageComponent: () => <CoachHubMain />,
      show: {
        needsLogin: true,
        needsProfileCompleted: 'ignore',
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.ASSESSMENT
        && getCoachHubParam('assessment') !== 'disc',
      },
      LazyPageComponent: 'CustomAssessmentPage',
    },
    {
      path: '/assessments/:customAssessmentId/peer/settings',
      pageType: 'coachHub',
      PageComponent: () => <CoachHubMain />,
      show: {
        needsLogin: true,
        needsProfileCompleted: 'ignore',
        show: () => getCoachHubParam('target') === COACHHUB_TARGETS.MANAGE_PEER_ASSESSMENT,
      },
      LazyPageComponent: 'Peer360Wrapper',
    },
  ],

};
