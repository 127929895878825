// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './IstIndicator.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// COMPONENT: IstIndicator
const IstIndicator = (props) => {
  // PROPS
  const {
    size = 'M', // M (default), S small
    color = '',
  } = props;

  // RENDER: IstIndicator
  return (
    <div className={classNames(
      styles.istIndicator,
      {
        [styles.small]: size === 'S',
        [styles.danger]: color === 'red',
        [styles.light]: color === 'light',
      },
    )}
    >
      <IconsSvg.Ist />
    </div>
  );
};

export default IstIndicator;
