import {
  call, put, take, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { get } from 'api';
import * as action from 'store/actions';
import * as fromActionTypes from 'store/actionTypes/settings';

// eslint-disable-next-line import/no-cycle
import { translate } from 'utils/translator/translator';
import {
  COMPANY_ID,
  IS_ADMIN_FRONTEND,
} from 'utils/configuration';
import * as localStorage from 'utils/localStorage';
import { LANGUAGES } from 'utils/configuration/const/languages';
import {
  ASSESSMENT_DESCRIPTIONS,
  ASSESSMENT_TITLES,
  ASSESSMENT_TYPES,
} from 'utils/configuration/const/assessment-types';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';

function* getSettings() {
  try {
    const { status, ok, data } = yield call(
      get,
      'core/company/features',
    );

    if (ok && status === 200) {
      const modules = data.features.filter((feature) => !feature.category.includes('assessment'));
      const customAssessments = data.features.filter((feature) => feature.category === 'custom-assessment');
      const coreAssessments = [];

      // order core assessments
      Object.values(ASSESSMENT_TYPES).forEach((assessmentId) => {
        const thisAssessment = data.features.find((a) => a.id === `ASSESSMENT_${assessmentId.toUpperCase()}`);
        // if assessment is not yet included in core assessments list
        if (thisAssessment && !coreAssessments.find((a) => a.id?.toLowerCase().includes(assessmentId))) {
          coreAssessments.push({
            ...thisAssessment,
            name: translate(ASSESSMENT_TITLES[assessmentId]) || thisAssessment.name,
            description: translate(ASSESSMENT_DESCRIPTIONS[assessmentId]) || thisAssessment.description,
          });
        }
      });

      const features = [ ...modules, ...coreAssessments, ...customAssessments ];
      yield put(action.getSettingsFulfilled({ features }));
    } else {
      yield put(action.getSettingsRejected(data));
    }
  } catch (error) {
    yield put(action.getSettingsRejected(error));
  }
}

function* getRegistrationDomains() {
  try {
    const { status, ok, data } = yield call(
      get,
      'core/company/settings',
      { company: COMPANY_ID },
    );

    if (ok && status === 200) {
      yield put(action.getRegistrationDomainsFulfilled({ domains: data.openRegistrationDomains }));
    } else {
      yield put(action.getRegistrationDomainsRejected(data));
    }
  } catch (error) {
    yield put(action.getRegistrationDomainsRejected(error));
  }
}

function* getAllFeatures() {
  try {
    const { status, ok, data } = yield call(get, 'core/features');

    if (ok && status === 200) {
      yield put(action.getAllFeaturesFulfilled({ features: data.features }));
    } else {
      yield put(action.getAllFeaturesRejected(data));
    }
  } catch (error) {
    yield put(action.getAllFeaturesRejected(error));
  }
}

function* getUserSettings() {
  try {
    const { status, ok, data = {} } = yield call(get, 'core/user/settings');

    if (ok && status === 200) {
      yield put(action.getUserSettingsFulfilled({ userSettings: data }));

      // Set language
      const lsLanguage = localStorage.getItem('selectedLanguage');
      if (data.language !== lsLanguage && !getIsExternalSystemIntegrationSupported()) {
        if (localStorage.getItem('overrideLanguage')) {
          yield put(action.updateUserSettings({ language: lsLanguage }, false));
        } else {
          yield put(action.changeLanguage({ language: data.language || LANGUAGES.EN }));
        }
      }

      localStorage.removeItem('overrideLanguage');
    } else {
      yield put(action.getUserSettingsRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.getUserSettingsRejected(error));
  }
}

function* getCompanySettings({ payload }) {
  try {
    const headerItems = {};
    let company = COMPANY_ID;
    if (IS_ADMIN_FRONTEND && payload.companyId) {
      company = payload.companyId;
      headerItems['x-admin-company'] = company;
    }

    const { status, ok, data } = yield call(
      get,
      'core/company/settings',
      { company },
      headerItems,
    );

    if (ok && status === 200) {
      yield put(action.getCompanySettingsFulfilled({ companySettings: data }));
    } else {
      yield put(action.getCompanySettingsRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.getCompanySettingsRejected(error));
  }
}

export function* watchGetSettingsRequest() {
  while (true) {
    try {
      yield take(action.GET_SETTINGS);
      yield call(getSettings);
    } catch (error) {
      yield put(action.getSettingsRejected(error));
    }
  }
}

export function* watchGetRegistrationDomains() {
  yield takeEvery(fromActionTypes.GET_REGISTRATION_DOMAINS, getRegistrationDomains);
}

export function* watchGetAllFeatures() {
  yield takeEvery(fromActionTypes.GET_ALL_FEATURES, getAllFeatures);
}

export function* watchGetUserSettings() {
  yield takeEvery(fromActionTypes.GET_USER_SETTINGS, getUserSettings);
}

export function* watchGetCompanySettings() {
  yield takeLatest(fromActionTypes.GET_COMPANY_SETTINGS, getCompanySettings);
}
