export const pxToNumber = (value) => {
  const numberString = value.split('px')[0];
  const number = Number(numberString);
  return number;
};


export const msToNumber = (value) => {
  const numberString = value.split('ms')[0];
  const number = Number(numberString);
  return number;
};

export const sToNumber = (value) => {
  const numberString = value.split('s')[0];
  const number = Number(numberString);
  return number;
};

