// 3RD PARTY
import * as moment from 'moment';
import { getMonths } from './months';

// UTILS
import REGEXES from 'utils/configuration/const/regexes';
import { translate } from './translator/translator';
import { capitalise } from './textTools';

export const timestampToDate = (timestamp) => new Date(timestamp * 1000); //* 1000 to get milliseconds

export const timestampToFullDate = (timestamp, format = 'DD.MM.YYYY') => {
  if (!timestamp) {
    return '';
  }

  return moment(timestampToDate(timestamp)).format(format);
};

export const timestampToShortMonthFormat = (timestamp) => {
  const month = moment(timestampToDate(timestamp), '', 'en').format('MMMM');

  if (month) {
    const translation = translate(`cp_dropdown_month_${month.toLowerCase()}`) || capitalise(month);
    return capitalise(translation.slice(0, 3)) || '';
  }

  return '';
};

export const timestampToDateString = (timestamp, language = 'en') => {
  const date = timestampToDate(timestamp);
  let month;
  if (language === 'en') {
    month = ` ${getMonths()[date.getMonth()]}`;
  } else {
    month = `. ${getMonths()[date.getMonth()]}`;
  }

  return `${date.getDate()}${month} ${date.getFullYear()}`;
};

export const isTimestampInPast = (timestamp) => {
  const validUntilTime = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
  const todayTime = new Date().setHours(0, 0, 0, 0);

  return validUntilTime < todayTime;
};

export const millisecondsToDays = (ms) => Math.ceil(ms / 1000 / 60 / 60 / 24);
export const isTimestampInFuture = (timestamp) => {
  const enteredTime = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
  const todayTime = new Date().setHours(0, 0, 0, 0);

  return enteredTime > todayTime;
};

export const dateIsValid = ({ day, month, year }) => {
  if (day === 0 || day > 31 || month === 0 || month > 12 || Number(year)?.toString()?.length < 4) {
    return false;
  }
  return true;
};

// date string of format 'DD.MM.YYYY'
export const dateHasValidFormat = (dateString = '') => REGEXES.DATE.test(dateString);

// date string format 'DD-MM-YYYY HH:mm:ss'
export const convertDateToLocale = (dateString = '') => {
  const dateRgx = /\d{4}-\d{2}-\d{2}/;
  const timeRgx = /\d{2}:\d{2}:\d{2}/;
  const [ date ] = dateString.match(dateRgx);
  const [ time ] = dateString.match(timeRgx);
  if (!date || !time) {
    throw new Error('convertDateToLocale: invalid date format');
  }

  return dateString.replace(
    new RegExp(`${dateRgx.source} ${timeRgx.source}`),
    new Date(`${date}T${time}Z`).toLocaleString(),
  );
};

// returns the parsed time if the provided string is in ISO8601 format, undefined otherwise
// https://en.wikipedia.org/wiki/ISO_8601
export const tryParseTimeFromIso8601 = (timeString) => {
  const time = moment(timeString, moment.ISO_8601);
  return time.isValid() ? time : undefined;
};
