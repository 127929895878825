

// 3RD PARTY
import SecureLS from 'secure-ls';

export class SecureStorage {
  /**
   * init: initializes secureStorage
   * throws an error if either type or userId are invalid
   * @param {String} type
   * @param {String} userId
   */
  constructor(type, userId, shareToken) {
    this.key = SecureStorage.createKey(type, userId, shareToken);
    this.ls = new SecureLS({ encodingType: 'aes' });
  }

  /**
   * isInitialised:
   * returns truthy or falsy value based on whether storage has been initialized
   * @returns truthy or falsy value
   */
  isInitialised() {
    return this.ls && this.key;
  }

  /**
   * reset: resets secureStorage
   */
  reset() {
    // secureStorage.reset() does not remove anymore so we can call it in AssessmentNext props update useEffect()
    this.ls = null;
    this.key = '';
  }

  /**
   * save: saves the state to the secure encrypted storage
   * adds lastSaveTime field with current time to state
   * @param {Object} state
   */
  save(state = {}) {
    const { key } = this;
    if (!key) {
      throw new Error('secureStorage: key is not set for save');
    }

    // eslint-disable-next-line no-param-reassign
    state.lastSaveTime = Date.now();

    this.ls.set(key, state);
  }

  /**
   * load: return the state for this.key if there is a state
   * returns undefined if there's no item
   * Throws an error if key is not set
   * @returns state or undefined
   */
  load() {
    const { key } = this;
    if (!key) {
      throw new Error('secureStorage: key is not set for load');
    }

    let state;
    const item = this.ls.get(key);

    if (item) {
      state = item;
    }

    return state;
  }

  /**
   * remove: removes the data from the secureStorage
   */
  remove() {
    const { key } = this;
    if (!key) {
      throw new Error('secureStorage: key is not set for remove');
    }

    this.ls.remove(key);
  }

  /**
   * createKey: returns a key based on type & userId
   * or throws an error if one of them is invalid
   * @param {String} type
   * @param {String} userId
   * @param {String} shareToken
   * @returns {String} key
   */
  static createKey(type, userId, shareToken) {
    // If it's a shared assessment, the token is a distinctive enough key
    if (shareToken) {
      return shareToken;
    }

    // check if it's a valid type
    if (!type) {
      throw new Error(`no valid type ${type}`);
    }
    // check if it's a valid id
    if (!userId) {
      throw new Error(`no valid userId ${userId}`);
    }
    return `${type}${userId}`;
  }
}
