import { highlight } from 'utils/textTools';

export const initialState = {
  showFlyout: false,
  filterString: '',
  options: [],
  filteredOptions: [],
  filteredOptionsIndex: 0,
  activeOption: null,
};

const getFilterOptions = (options, filterString) => {
  const filteredOptions = options.filter(({ label, value }) => {
    const labelMatch = label.toLowerCase().includes(filterString.toLowerCase());
    const valueMatch = `${value}`.toLowerCase().includes(filterString.toLowerCase());
    return labelMatch || valueMatch;
  })
  .map((option) => ({
    ...option,
    label: highlight(option.label, filterString),
  }));

  return filteredOptions;
};

const getFilteredOptionsIndex = (filterOptions, filteredOptionsIndex = 0) => {
  if (filteredOptionsIndex > filterOptions.length - 1) {
    filteredOptionsIndex = filterOptions.length - 1;
  }
  if (filteredOptionsIndex < 0) {
    filteredOptionsIndex = 0;
  }

  return filteredOptionsIndex;
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState,
      };
    }
    case 'showFlyout': {
      const showFlyout = true;
      return {
        ...state,
        showFlyout,
      };
    }
    case 'hideFlyout': {
      const showFlyout = false;
      return {
        ...state,
        showFlyout,
      };
    }
    case 'toggleFlyout': {
      const showFlyout = !state.showFlyout;
      return {
        ...state,
        showFlyout,
      };
    }
    case 'setOptions': {
      const options = action.payload;

      const { filterString } = state;
      const filteredOptions = !filterString ? options : getFilterOptions(options, filterString);

      return {
        ...state,
        options,
        filteredOptions,
      };
    }
    case 'setFilterString': {
      const filterString = action.payload;

      const { options } = state;
      const filteredOptions = getFilterOptions(options, filterString);
      const filteredOptionsIndex = 0;

      return {
        ...state,
        filterString,
        filteredOptions,
        filteredOptionsIndex,
      };
    }
    case 'setActiveOption': {
      const activeOption = action.payload;

      return {
        ...state,
        activeOption,
      };
    }
    case 'setActiveOptionByOption': {
      const desiredOption = action.payload;
      const activeOption = state.options.find((option) => option.value === desiredOption.value);

      return {
        ...state,
        activeOption,
      };
    }
    case 'setActiveOptionByValue': {
      const activeValue = action.payload;
      const activeOption = state.options.find((option) => option.value === activeValue);

      return {
        ...state,
        activeOption,
      };
    }
    case 'setActiveOptionByIndex': {
      const activeIndex = action.payload;
      const activeOption = state.options[activeIndex];

      return {
        ...state,
        activeOption,
      };
    }
    case 'setActiveOptionByFilteredOptionsIndex': {
      const { filteredOptionsIndex } = state;
      const activeFilterOption = state.filteredOptions[filteredOptionsIndex];
      let activeOption = null;
      if (activeFilterOption) {
        activeOption = state.options.find((option) => option.value === activeFilterOption.value);
      }

      return {
        ...state,
        activeOption,
      };
    }
    case 'setFilteredOptionsIndex': {
      const filteredOptionsIndex = getFilteredOptionsIndex(state.filteredOptions, action.payload);

      return {
        ...state,
        filteredOptionsIndex,
      };
    }
    case 'incFilteredOptionsIndex': {
      const filteredOptionsIndex = getFilteredOptionsIndex(state.filteredOptions, state.filteredOptionsIndex + 1);

      return {
        ...state,
        filteredOptionsIndex,
      };
    }
    case 'decFilteredOptionsIndex': {
      const filteredOptionsIndex = getFilteredOptionsIndex(state.filteredOptions, state.filteredOptionsIndex - 1);

      return {
        ...state,
        filteredOptionsIndex,
      };
    }
    default: {
      throw new Error(`DropDownSearchable.reducer: action.type ${action.type} not handled`);
    }
  }
};
