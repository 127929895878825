// REACT, STYLE, STORIES & COMPONENT
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import styles from './QuestionCompanyDomain.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';

// UTILS
import REGEXES from 'utils/configuration/const/regexes';
import { useTranslate } from 'utils/translator/translator';
import { useDebounce } from 'utils/hooks';

// STORE
import * as api from 'api';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionCompanyDomain
const QuestionCompanyDomain = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ domainError, setDomainError ] = useState();
  const [ domain, setDomain ] = useState('');
  const domainRef = useRef();
  domainRef.current = domain;
  const latestCall = useRef();
  const debouncedDomainName = useDebounce(domain, 300);

  const checkDomain = useCallback(() => {
    // Prevent unnecessary or invalid onSearch calls
    if (domainRef.current?.length < api.MIN_SEARCH_LENGTH || !REGEXES.SUBDOMAIN.test(domainRef.current)) {
      return;
    }

    const thisCall = Date.now();
    latestCall.current = thisCall;

    api.get(`/core/company/exists/${domainRef.current}`)
    .then(({ ok, status, data }) => {
      // Only accept last call
      if (thisCall !== latestCall.current) {
        return;
      }
      if (ok && status === 200) {
        const exists = !data.available;
        const pattern = !REGEXES.SUBDOMAIN.test(domainRef.current);
        setDomainError({ exists, pattern });
        onAnswer({ domain: domainRef.current, isValid: !(exists || pattern) });
      } else {
        console.error(data?.error?.errorMessage);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ onAnswer ]);

  useEffect(() => {
    if (answer.domain) {
      setDomain(answer.domain);
    }
  }, [ answer.domain ]);

  useEffect(() => {
    checkDomain();
  }, [ debouncedDomainName, checkDomain ]);

  const handleDomain = (value = '') => {
    setDomain(value.toLowerCase());
    onAnswer({ domain: '', isValid: false });
  };

  const getErrorMessage = () => {
    if (domainError?.pattern) {
      return translate('trial_signup_company_domain_invalid_error');
    }
    if (domainError?.exists) {
      return translate('trial_signup_company_domain_exists_error');
    }
    return undefined;
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: QuestionCompanyDomain
  return (
    <div className={classNames(styles.questionCompanyDomain)}>
      <div className='bluTypeCopyStrong'>
        { translate('trial_signup_company_domain_copy') }
      </div>
      <InputNext
        size='L'
        placeholder='company'
        validate={{
          pattern: REGEXES.SUBDOMAIN,
          onChange: true,
        }}
        value={domain}
        domain='.bluquist.app'
        hint={translate('trial_signup_company_domain_hint')}
        errorMessage={getErrorMessage()}
        onChange={handleDomain}
        onError={setDomainError}
      />
    </div>
  );
};

export default QuestionCompanyDomain;
