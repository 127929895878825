import React from 'react';
import styles from './ResetPasswordRequestSuccessForm.module.scss';

import { useTranslate } from 'utils/translator';

import Link from 'ui/basic/micro-ui/Link';

const ResetPasswordRequestSuccessForm = ({ email }) => {
  const translate = useTranslate();

  return (
    <div className={styles.resetPasswordRequestSuccessForm}>
      { translate('resetpwsuccess_title') }
      <div className={styles.paragraph}>
        { translate('resetpwsuccess_copy') }
      </div>
      <div className={styles.paragraph2}>
        { translate('resetpwsuccess_copyhint') }
      </div>
      <Link to='/'>{ translate('resetpwsuccess_link_login') }</Link>
    </div>
  );
};

export default ResetPasswordRequestSuccessForm;
