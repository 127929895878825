import React, {
  useEffect, useRef, useState,
} from 'react';
import styles from './RmpReport.module.scss';

import {
  resultIsStrong,
  resultIsWeak,
  RMP_FIRST_MARKER_POSITION,
} from 'utils/rmp';

import { Icons } from 'assets/icons';
import RmpDiagram from 'assets/assessments/rmp-diagram.svg';

import {
  Icon, Link, BipolarDiagram, Modal,
} from 'ui/basic';
import RmpResultTable from '../RmpResultTable';

import { useTranslate } from 'utils/translator';
import { setDecimalSeparator } from 'utils/userpreference';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

const CONTENTS = [
  { id: 'general-info', name: 'rmp_report_subtitle_general_info' },
  {
    id: 'classify-results',
    name: 'rmp_report_subtitle_general_result_info',
  },
  { id: 'quick-results', name: 'rmp_report_subtitle_results_ataglance' },
  { id: 'detailed-results', name: 'rmp_report_subtitle_results_detailed' },
];

const RmpReport = (props) => {
  const {
    strongResults, weakResults, averageResults, onClose,
  } = props;

  const ref = useRef();
  const translate = useTranslate();

  const [ copyrightDialogVisible, setCopyrightDialogVisible ] = useState(false);

  const renderCalloutComponent = (score, dimensionId) => {
    if (resultIsStrong(score)) {
      return (
        <>
          <div className={styles.summary}>
            { translate(`rmp_report_${dimensionId}_strong_short_summ`) }
          </div>
          <div className={styles.details}>
            { translate(`rmp_report_${dimensionId}_strong_details`) }
          </div>
        </>
      );
    }

    if (resultIsWeak(score)) {
      return (
        <>
          <div className={styles.summary}>{ translate(`rmp_report_${dimensionId}_weak_short_summ`) }</div>
          <div className={styles.details}>
            { translate(`rmp_report_${dimensionId}_weak_details`) }
          </div>
        </>
      );
    }

    return (
      <div className={styles.details}>
        { translate(`rmp_report_${dimensionId}_average_details`) }
      </div>
    );
  };

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    };
  }, []);

  return (
    <div className={styles.rmpReport}>
      <div className={styles.header}>
        <span>{ translate('rmp_report_header_title') }</span>
        <div
          role='presentation'
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>
      <div className={styles.bodyContainer} id='body'>
        <div className={styles.body}>
          <div className={styles.main} ref={ref}>
            <div className={styles.mTitle}>{ translate('rmp_report_title') }</div>
            <div className={styles.contents}>
              { CONTENTS.map((content) => (
                <Link
                  key={content.id}
                  type='anchor'
                  onClick={() => {
                    const { offsetTop } = document.getElementById(content.id);
                    document.getElementById('body').scrollTo({
                      top: offsetTop - 60, // 60 - height of header
                      behavior: 'smooth',
                    });
                  }}
                >
                  { translate(content.name) }
                </Link>
              )) }
            </div>

            <div className={styles.generalInfo} id='general-info'>
              <div className={styles.sTitle}>
                { translate('rmp_report_subtitle_general_info') }
              </div>
              <div className={styles.description}>
                { translate('rmp_report_copy_general_info') }
              </div>
            </div>

            <div className={styles.classifyResults} id='classify-results'>
              <div className={styles.sTitle}>
                { translate('rmp_report_subtitle_general_result_info') }
              </div>
              <div className={styles.description}>
                { translate('rmp_report_copy_general_result_info') }
              </div>
              <div className={styles.diagram}>
                <div className={styles.headerLabels}>
                  <span>{ translate('rmp_report_chart_tl_l') }</span>
                  <span className={styles.center}>{ translate('rmp_report_chart_tl_c') }</span>
                  <span className={styles.right}>{ translate('rmp_report_chart_tl_r') }</span>
                </div>
                <img src={RmpDiagram} alt='rmp-diagram' />
                <div className={styles.footerLabels}>
                  <span>{ translate('rmp_report_chart_bl_l') }</span>
                  <span>{ translate('rmp_report_chart_bl_c') }</span>
                  <span>{ translate('rmp_report_chart_bl_r') }</span>
                </div>
              </div>
              <div className={styles.callOutContainer}>
                <div className={styles.triangle} />
                <div className={styles.callOut}>
                  { translate('rmp_report_copy_general_result_info_callout') }
                </div>
              </div>
            </div>

            <div className={styles.quickResults} id='quick-results'>
              <div className={styles.sTitle}>
                { translate('rmp_report_subtitle_results_ataglance') }
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  { translate('rmp_report_label_results_strong') }
                </div>
                <div className={styles.links}>
                  { strongResults.length === 0
                    ? '-'
                    : strongResults.map((motive) => {
                      const motiveId = motive.id?.toLowerCase();
                      return (
                        <Link
                          key={motiveId}
                          type='anchor'
                          onClick={() => {
                            const { offsetTop } = document.getElementById(motiveId);
                            document.getElementById('body').scrollTo({
                              top: offsetTop - 60, // 60 - height of header
                              behavior: 'smooth',
                            });
                          }}
                        >
                          { `${translate(`rmp_results_label_${motiveId}`) || motive.name} 
                        (${setDecimalSeparator(motive.result, 2)})` }
                        </Link>
                      );
                    }) }
                </div>
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  { translate('rmp_report_label_results_weak') }
                </div>
                <div className={styles.links}>
                  { weakResults.length === 0
                    ? '-'
                    : weakResults.map((motive) => {
                      const motiveId = motive.id?.toLowerCase();
                      return (
                        <Link
                          key={motiveId}
                          type='anchor'
                          onClick={() => {
                            const { offsetTop } = document.getElementById(motiveId);
                            document.getElementById('body').scrollTo({
                              top: offsetTop - 60, // 60 - height of header
                              behavior: 'smooth',
                            });
                          }}
                        >
                          { `${translate(`rmp_results_label_${motiveId}`) || motive.name} 
                        (${setDecimalSeparator(motive.result, 2)})` }
                        </Link>
                      );
                    }) }
                </div>
              </div>
              <div className={styles.subBlock}>
                <div className={styles.title}>
                  { translate('rmp_report_label_results_average') }
                </div>
                <div className={styles.links}>
                  { averageResults.length === 0
                    ? '-'
                    : averageResults.map((motive) => {
                      const motiveId = motive.id?.toLowerCase();
                      return (
                        <Link
                          key={motiveId}
                          type='anchor'
                          onClick={() => {
                            const { offsetTop } = document.getElementById(motiveId);
                            document.getElementById('body').scrollTo({
                              top: offsetTop - 60, // 60 - height of header
                              behavior: 'smooth',
                            });
                          }}
                        >
                          { `${translate(`rmp_results_label_${motiveId}`) || motive.name} 
                        (${setDecimalSeparator(motive.result, 2)})` }
                        </Link>
                      );
                    }) }
                </div>
              </div>
            </div>

            <div className={styles.detailedResults} id='detailed-results'>
              <div className={styles.sTitle}>
                { translate('rmp_report_subtitle_results_detailed') }
              </div>

              { strongResults.concat(weakResults).concat(averageResults).map((resultItem) => (
                <div
                  id={resultItem.id}
                  key={resultItem.id}
                  className={styles.dimension}
                >
                  <div className={styles.title}>
                    { translate(`rmp_results_label_${resultItem.id}`) || resultItem.name }
                  </div>
                  <div className={styles.description}>
                    { translate(`rmp_report_${resultItem.id}_gen_description`) }
                  </div>
                  <div className={styles.diagramBlock}>
                    <div className={styles.diagramHeader}>
                      <div className={styles.left}>{ translate('big5_report_facet_result') }</div>
                      <div className={styles.right}>{ setDecimalSeparator(resultItem.result, 2) }</div>
                    </div>
                    <div className={styles.diagram}>
                      <BipolarDiagram
                        score={resultItem.result}
                        showMarkers
                      />
                    </div>
                    <div className={styles.underTitles}>
                      <div className={resultItem.result < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
                        { translate('rmp_results_scale_min') }
                      </div>
                      <div className={resultItem.result > RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
                        { translate('rmp_results_scale_max') }
                      </div>
                    </div>
                  </div>

                  <div className={styles.callOutContainer}>
                    <div className={styles.triangle} />
                    <div className={styles.callOut}>
                      { renderCalloutComponent(resultItem.result, resultItem.id) }
                    </div>
                  </div>

                  <RmpResultTable
                    dimensionId={resultItem.id}
                    score={resultItem.result}
                  />
                </div>
              )) }
            </div>

            <div className={styles.copyright}>
              <span
                role='presentation'
                onClick={() => setCopyrightDialogVisible(true)}
              >
                { translate('rmp_ass_copyrightlink', [ '{{year}}', new Date().getFullYear() ]) }
              </span>
            </div>
          </div>
        </div>
      </div>

      { copyrightDialogVisible && (
        <Modal
          header={translate('rmp_ass_copyrightlink', [ '{{year}}', new Date().getFullYear() ])}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setCopyrightDialogVisible(false)}
        >
          { translate('rmp_ass_info_description_copyright') }
        </Modal>
      ) }

    </div>
  );
};

export default RmpReport;
