import React from 'react';

import { Introduction } from './pages';
import { Peer360Wrapper } from './pages/Assessment/Setup';
import Big5AssessmentPage from './pages/Assessment/Big5AssessmentPage';
import CustomAssessmentPage from './pages/Assessment/CustomAssessmentPage';
import KeyCompetenciesAssessmentPage from './pages/Assessment/KeyCompetenciesAssessmentPage';
import LeadershipCompetenciesAssessmentPage from './pages/Assessment/LeadershipCompetenciesAssessmentPage';
import NineLevelsAssessmentPage from './pages/Assessment/NineLevelsAssessmentPage';
import PotentialAssessmentPage from './pages/Assessment/PotentialAssessmentPage';
import RmpAssessmentPage from './pages/Assessment/RmpAssessmentPage';
import WorkPreferencesAssessmentPage from './pages/Assessment/WorkPreferencesAssessmentPage';
import BalancedYouAssessmentPage from './pages/Assessment/BalancedYouAssessmentPage';
import IstAssessmentPage from './pages/Assessment/IstAssessmentPage';
import { ExternalAssessmentWrapper } from './pages/Assessment/External/ExternalAssessmentWrapper';
import { CreateNewRun } from 'features/framework/pages/MyOrganization/CreateNewRun';
import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';


export const routesConfigCore = {
  show: {
    forUserGroup: 'all',
    forFlavor: [ 'bluquist', 'bluquistAndBalancedYou', 'coachHub' ],
  },
  routes: [
    {
      path: '/introduction',
      pageType: 'modal',
      useStoreNext: true,
      PageComponent: () => <Introduction />,
      show: {
        forUserGroup: [ 'employee', 'leader', 'assessmentManager', 'admin' ], // all but candidate
      },
      tools: {
        browserTitleKey: 'introduction_header_title',
      },
    },

    //  ASSESSMENTS
    {
      path: '/assessments/:customAssessmentId/peer/settings',
      pageType: 'modal',
      PageComponent: () => <Peer360Wrapper />,
      show: {
        forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/:customAssessmentId/new-run',
      pageType: 'modal',
      PageComponent: () => <CreateNewRun />,
      show: {
        forFlavor: 'all',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/:customAssessmentId/share/:shareToken',
      pageType: 'standalone',
      pageWrapper: {
        noHeaderLogoLink: getIsExternalSystemIntegrationSupported(),
      },
      PageComponent: () => <ExternalAssessmentWrapper />,
      show: {
        needsLogin: false,
        forFlavor: 'all',
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/big5',
      pageType: 'modal',
      PageComponent: () => <Big5AssessmentPage />,
      show: {
        forFlavor: [ 'bluquist', 'coachHub', 'bluquistAndBalancedYou' ],
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/potential',
      pageType: 'modal',
      PageComponent: () => <PotentialAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/work-preference',
      pageType: 'modal',
      PageComponent: () => <WorkPreferencesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/key-comp',
      pageType: 'modal',
      PageComponent: () => <KeyCompetenciesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/lead-comp',
      pageType: 'modal',
      PageComponent: () => <LeadershipCompetenciesAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/rmp',
      pageType: 'modal',
      PageComponent: () => <RmpAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/9levels',
      pageType: 'modal',
      PageComponent: () => <NineLevelsAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/balanced-you',
      pageType: 'modal',
      PageComponent: () => <BalancedYouAssessmentPage />,
      show: {
        forFlavor: [ 'balancedYou', 'bluquistAndBalancedYou' ],
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/ist',
      pageType: 'modal',
      PageComponent: () => <IstAssessmentPage />,
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
    {
      path: '/assessments/:customAssessmentId',
      pageType: 'modal',
      PageComponent: () => <CustomAssessmentPage />,
      show: {
        forFlavor: [ 'bluquist', 'bluquistAndBalancedYou' ],
      },
      tools: {
        browserTitleKey: 'main_navigation_item_5',
      },
    },
  ],
};
