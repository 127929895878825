// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './SkeletonFlexible.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  repeat: 4,
};


// COMPONENT: SkeletonFlexible
const SkeletonFlexible = (props) => {
  // PROPS
  const {
    repeat = Config.repeat,
    xxsMargins = false,
    noHeader = false,
    rowHeight,
    fullWidth,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: SkeletonFlexible
  return (
    <div className={classNames(styles.skeletonFlexible, {
      [styles.fullWidth]: fullWidth,
    })}
    >
      { !noHeader && (
        <div className={styles.headerGroup}>
          <div className={classNames(styles.bar, styles.thick, styles.shorter)} />
        </div>
      ) }
      { new Array(repeat).fill(1).map((item, index) => (
        <div
          key={index}
          className={classNames(styles.group, {
            [styles.xxsMargins]: xxsMargins && !rowHeight,
            [styles.noMargins]: rowHeight,
          })}
          style={{ height: `${rowHeight}px` }}
        >
          <div className={classNames(styles.bar, styles.short)} />
          <div className={classNames(styles.bar)} />
        </div>
      )) }
    </div>
  );
};

export default SkeletonFlexible;
