// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './ProfileSummary.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button,
  Modal,
  OptionsMenu,
  Profile,
  HotChipsPeopleWithSearch,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';

// STORE
import * as api from 'api';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectCapabilitiesNext } from 'store/selectors/configuration';

// CONFIG & DATA
// const Config = {};

const OPTION_TYPES = {
  OPEN_AVAILABLE_ASSESSMENTS: 'OPEN_AVAILABLE_ASSESSMENTS',
  GO_TO_USER_SETTINGS: 'GO_TO_USER_SETTINGS',
  SHARE_PROFILE_LINK: 'SHARE_PROFILE_LINK',
  EXPORT_ASSESSMENTS_RESULTS: 'EXPORT_ASSESSMENTS_RESULTS',
};

// COMPONENT: ProfileSummary
const ProfileSummary = (props) => {
  // PROPS
  const {
    userRole,
    candidate,
    showControls = true,
    onAssessmentsConfigure = () => {},
    onProfileShare = () => {},
    onVacanciesAdd = () => {},
    onAssessmentsResultsExport = () => {},
  } = props;

  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const canEdit = !candidate.disabled && capabilitiesNext.vacanciesCreate;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const history = useHistory();

  // NEW VACANCIES TO ADD THE CANDIDATE TO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ addToVacanciesModalVisible, setAddToVacanciesModalVisible ] = useState();
  const [ addToVacanciesProcessing, setAddToVacanciesProcessing ] = useState();

  const [ newVacancies, setNewVacancies ] = useState([]);

  const mapSearchResults = useCallback(({ jobs }) => jobs.map(({ id, name }) => ({ value: id, label: name })), []);

  const handleAddToNewVacancies = () => {
    setAddToVacanciesProcessing(true);

    Promise.all(newVacancies.map((vacancy) => api.post(
      `recruiting/jobs/${vacancy.value}/candidates`,
      { users: [ { id: candidate.id } ] },
    ))).then((responseArray) => {
      const response = responseArray[0];
      setAddToVacanciesProcessing(false);
      if (response.ok && response.status === 200) {
        setAddToVacanciesModalVisible(false);
        onVacanciesAdd();
      }
    }, (reason) => {
      setAddToVacanciesProcessing(false);
      console.error(reason);
    });
  };

  // MENU OPTIONS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ options, setOptions ] = useState([]);
  useEffect(() => {
    const opt = [];

    if (!candidate.disabled) {
      opt.push({
        value: OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS,
        label: translate('available_assessments_option_lbl'),
      });
      opt.push({
        value: OPTION_TYPES.SHARE_PROFILE_LINK,
        label: translate('manage_profile_links'),
      });
    }

    if (userRole === MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR) {
      opt.splice(1, 0, {
        value: OPTION_TYPES.GO_TO_USER_SETTINGS,
        label: translate('user_settings_lbl'),
      });
    }

    if (window.location.pathname.includes('assessments') && candidate.profileCompletion?.completed) {
      opt.push({
        value: OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS,
        label: translate('export_assessments_results'),
      });
    }

    setOptions(opt);
  }, [
    candidate.disabled,
    candidate.profileCompletion?.completed,
    userRole,
    translate,
  ]);

  // RENDER: ProfileSummary
  return (
    <div className={classNames(styles.profileSummary)}>
      <div>
        <Profile user={candidate} showCompanyData={false} />

        { showControls && (
          <div className={styles.buttons}>
            { canEdit && (
              <Button
                size='S'
                looks='secondary'
                onClick={() => setAddToVacanciesModalVisible(true)}
              >
                { translate('add_to_vacancy_btn') }
              </Button>
            ) }

            { options.length > 0 && (
              <OptionsMenu
                withBackground
                flyoutPosition={canEdit ? 'right' : 'left'}
                options={options}
                onClick={(value) => {
                  switch (value) {
                    case OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS:
                      if (onAssessmentsConfigure) {
                        onAssessmentsConfigure();
                      }
                      break;
                    case OPTION_TYPES.GO_TO_USER_SETTINGS:
                      history.push(`/settings/users/${candidate.id}`);
                      break;
                    case OPTION_TYPES.SHARE_PROFILE_LINK:
                      if (onProfileShare) {
                        onProfileShare();
                      }
                      break;
                    case OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS:
                      onAssessmentsResultsExport();
                      break;
                    default:
                  }
                }}
              />
            ) }
          </div>
        ) }
      </div>

      { addToVacanciesModalVisible && (
        <Modal
          header={translate('add_vacancies_to_candidate_long')}
          primaryButtonTitle={translate('add_lbl')}
          primaryButtonDisabled={addToVacanciesProcessing || newVacancies.length === 0}
          secondaryButtonTitle={translate('cancel_lbl')}
          closeOnConfirm={false}
          onConfirm={handleAddToNewVacancies}
          onClose={() => setAddToVacanciesModalVisible(false)}
        >
          <div className={styles.addToVacanciesContent}>
            { translate('add_vacancies_to_candidate_copy') }
            <HotChipsPeopleWithSearch
              placeholder={translate('add_vacancies_to_candidate_placeholder')}
              label={translate('add_vacancies_to_candidate_label')}
              values={newVacancies}
              noResultsHint={translate('add_vacancies_to_candidate_no_results')}
              onUpdate={setNewVacancies}
              canMail={false}
              searchConfig={{
                entrypoint: 'recruiting/jobs',
                mapResults: mapSearchResults,
              }}
            />
          </div>
        </Modal>
      ) }
    </div>
  );
};

export default ProfileSummary;
