import React, { useState } from 'react';
import styles from './ResetPasswordRequestForm.module.scss';

import { Link, Button, InputNext } from 'ui/basic';

import { useTranslate } from 'utils/translator';
import REGEXES from 'utils/configuration/const/regexes';


const ResetPasswordRequestForm = ({ error, onResetPassword }) => {
  const [ email, setEmail ] = useState('');
  const [ formError, setFormError ] = useState();

  const translate = useTranslate();

  return (
    <div className={styles.resetPasswordRequestForm}>
      <div className={styles.title}>
        { translate('resetpw_title') }
      </div>
      <div className={styles.subTitle}>
        { translate('resetpw_copy') }
      </div>

      <form onSubmit={(event) => {
        event.preventDefault();
        onResetPassword(email);
      }}
      >
        <InputNext
          placeholder={translate('resetpw_form_email_label')}
          value={email}
          autofocus
          errorMessage={error || formError}
          onChange={(value) => {
            const newValue = value.trim();
            if (!newValue || REGEXES.EMAIL.test(newValue)) {
              setFormError();
            } else {
              setFormError(translate('multiple_emails_invalid_msg'));
            }
            setEmail(newValue);
          }}
        />

        <div className={styles.bottom}>
          <Link to='/'>
            { translate('resetpw_link_login') }
          </Link>
          <Button
            disabled={!email || formError}
            type='submit'
          >
            { translate('resetpw_button_send') }
          </Button>
        </div>
      </form>

    </div>
  );
};

export default ResetPasswordRequestForm;
