import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import AssessmentGuidance from '../intermissions/AssessmentGuidance';
import { IstAssessmentEnd } from '../intermissions/IstAssessmentEnd';
import { IstRegistration } from '../intermissions/IstRegistration';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { translate } from 'utils/translator/translator';

// OTHER COMPONENTS

export const istConfig = {
  id: 'ist',
  title: 'IST',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  modalHurryDelay: 30 * 1000,
  // storageValidityWindow: 1 * 24 * 60 * 60 * 1000, // 1 day
  modalHelpContentTranslationKey: 'ist_assmnt_help_content',
  loadingPage: {
    // INTRO with disabled next button and skeleton
    render: () => (
      <AssessmentIntro
        skeletonOnly
      />
    ),
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next) => (
        <AssessmentIntro
          assessmentType={ASSESSMENT_TYPES.IST}
          title={translate('ist_assmnt_title')}
          description={translate('ist_assmnt_description')}
          onClickNext={() => next()}
        />
      ),
    },
    // GUIDANCE
    {
      insertAtIndex: 1,
      render: (next) => (
        <AssessmentGuidance
          assessmentType={ASSESSMENT_TYPES.IST}
          content={translate('ist_assmnt_guidance')}
          onClickNext={() => next()}
        />
      ),
    },
    // REGISTRATION
    {
      insertAtIndex: 2,
      showProgressBar: true,
      preventKeyboardNext: true,
      render: (next, prev, state, extras) => (
        <IstRegistration
          // extraAssessmentInfo passed in from IstAssessmentPage
          assessmentDetails={state.assessmentDetails}
          skipRegistration={state.skipRegistration}
          extras={extras}
          clickBlock={state.clickBlock}
          onClickNext={() => { next(); }}
        />
      ),
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <IstAssessmentEnd extras={extras} />
      ),
    },
  ],
};
