// REACT, STYLE, STORIES & COMPONENT
import React, { useRef, useState } from 'react';
import styles from './RecommendationCarousel.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { ButtonCircular, Scrollable } from 'ui/basic';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';


// COMPONENT: RecommendationCarousel
const RecommendationCarousel = (props) => {
  // PROPS
  const { assessmentId } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const recommendations = useSelector(selectRecommendationsUnarchived);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ leftAvailable, setLeftAvailable ] = useState(false);
  const [ rightAvailable, setRightAvailable ] = useState(false);
  const scrollableRef = useRef();

  // RENDER: RecommendationCarousel
  if (!recommendations.length) {
    return null;
  }

  return (
    <div className={classNames(styles.recommendationCarousel)}>
      <div className={classNames(
        styles.scrollingButtons,
        { [styles.hidden]: !(leftAvailable || rightAvailable) },
      )}
      >
        <ButtonCircular
          icon='ArrowLeft'
          looks='primary'
          disabled={!leftAvailable}
          onClick={() => { scrollableRef.current?.pageLeft(); }}
        />
        <ButtonCircular
          icon='ArrowRight'
          looks='primary'
          disabled={!rightAvailable}
          onClick={() => { scrollableRef.current?.pageRight(); }}
        />
      </div>

      <Scrollable
        ref={scrollableRef}
        pagination
        showPaginationBubbles
        drag
        pageMargin={16}
        refresh={recommendations}
        onPaginationAvailabilities={([ pageLeftAvailable, pageRightAvailable ]) => {
          setLeftAvailable(pageLeftAvailable);
          setRightAvailable(pageRightAvailable);
        }}
      >
        { recommendations
        .filter((card) => card.module === assessmentId)
        .map((card) => (
          <div key={card.id} className={classNames(styles.scrollableCard)}>
            <RecommendationCard {...card} />
          </div>
        )) }
      </Scrollable>
    </div>
  );
};

export default RecommendationCarousel;
