// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useRef } from 'react';
import styles from './Login.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import PoweredBy from 'ui/basic/micro-ui/PoweredBy';
import { Button, StoreNext } from 'ui/basic';
import { LoginMfa } from 'features/framework/components/LoginMfa';
import LoginForm from 'features/framework/components/LoginForm';

// UTILS
import * as api from 'api';
import { COMPANY_ID, SUBDOMAIN_FOR_DEFAULT_COMPANY_ID, CURRENT_LOCATION } from 'utils/configuration';
import { getCookie, deleteCookie } from 'utils/cookie';
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';

// STORE OLD
import { useDispatch, useSelector } from 'react-redux';
import { login, changeLanguage } from 'store/actions';
import { selectIsCustomLogoActive, selectAvailableInstances } from 'store/selectors/configuration';
import * as fromSettingsSelectors from 'store/selectors/settings';
import RedirectNext from './RedirectNext';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const translate = useTranslate();

  const searchParams = useRef(new URLSearchParams(search)).current;
  const allLanguages = useSelector((state) => state.localisation?.languages ?? []);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);
  const hasMultipleInstances = useSelector(selectAvailableInstances)?.length > 1;
  const loginData = useSelector((state) => state.login);
  const errorMessage = loginData.error?.errorMessage;
  const isDefault = COMPANY_ID === SUBDOMAIN_FOR_DEFAULT_COMPANY_ID;
  const { domain } = CURRENT_LOCATION;

  const ssoProvider = useSelector(fromSettingsSelectors.getCompanySsoProvider);
  const ssoExclusive = useSelector(fromSettingsSelectors.getCompanySsoExclusive);

  const ssoProviderTranslation = translate(`sso_provider_${ssoProvider}`);
  let ssoProviderName = '';
  if (ssoProvider) {
    ssoProviderName = (ssoProviderTranslation === `sso_provider_${ssoProvider}`)
      ? capitalise(ssoProvider)
      : ssoProviderTranslation;
  }

  const isBalancedYou = useSelector(fromSettingsSelectors.getCompanyIsBalancedYou);

  /**
   * Hook to parse QS language param and set it (default: en_US).
   */
  useEffect(() => {
    const language = searchParams.get('lang');
    if (allLanguages.includes(language)) {
      localStorage.setItem('overrideLanguage', true);
      dispatch(changeLanguage({ language }));
    }
  }, [ allLanguages, searchParams, dispatch ]);

  /**
   * Hook to process login params.
   * If the auth token cookie is included, attempt to log in automatically.
   */
  useEffect(() => {
    // Do not process token on default company id
    if (isDefault) {
      return;
    }

    const authToken = getCookie('token');

    // Auth token missing: nothing to process
    if (!authToken) {
      return;
    }

    deleteCookie('token', { domain });

    // If a login error is present, redirect to standard login page
    // (prevents infinite loop due to mount/unmount of loading screen).
    if (errorMessage) {
      history.push('/login');
      return;
    }

    // Parse extended session param if present (default: false)
    let extendedSession = Number(searchParams.get('ext'));
    extendedSession = Number.isNaN(extendedSession) ? false : Boolean(extendedSession);

    // Log in
    dispatch(login({
      authToken,
      company: COMPANY_ID,
      extendedSession,
    }));
  }, [
    searchParams,
    history,
    dispatch,
    isDefault,
    domain,
    errorMessage,
  ]);

  /**
   * Redirect when there are multiple instances to choose from.
   */
  useEffect(() => {
    if (hasMultipleInstances) {
      history.push('/select-instance');
    }
  }, [ hasMultipleInstances, history ]);

  const handleFormLogin = (mail, password, extendedSession) => {
    const payload = { mail, password, extendedSession };

    // Pass 'undefined' as company value to trigger instance selection
    if (isDefault) {
      payload.company = undefined;
    }

    dispatch(login(payload));
  };


  // RENDER: Form
  const renderForm = () => {
    if (!isDefault) {
      // MFA authentication 2nd step
      if (loginData.mfaRequired) {
        return (
          <LoginMfa
            loginData={loginData}
            error={errorMessage}
            company={COMPANY_ID}
          />
        );
      }

      // Single sign-on
      if (ssoExclusive) {
        return (
          <form
            method='get'
            action={`${api.getBaseUrl()}/core/user/sso/login`}
          >
            <div className={styles.ssoExclusive}>
              <input type='hidden' name='company' value={COMPANY_ID} />

              <div className='bluTypeM'>{ translate('login_title') }</div>
              <div className={classNames('bluTypeCopyStrong', 'marginTopS')}>
                { translate('sso_exclusive_login_copy', [
                  '{{provider}}', ssoProviderName,
                ]) }
              </div>
              <Button type='submit'>
                { translate('sso_login_btn', [
                  '{{provider}}', ssoProviderName,
                ]) }
              </Button>
            </div>
          </form>
        );
      }
    }

    // Standard form
    return (
      <LoginForm
        isBalancedYou={isBalancedYou}
        ssoProvider={ssoProviderName}
        error={errorMessage}
        onSubmit={handleFormLogin}
      />
    );
  };

  // RENDER: Login
  return (
    <div className={styles.login}>
      <div className={classNames(styles.container)}>

        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }

        <StoreNext>
          <RedirectNext restricted={errorMessage === 'restrictedCompany'} />
        </StoreNext>

        { renderForm() }
      </div>
    </div>
  );
};

export default Login;
