import ARROW_ANCHOR, { ReactComponent as ARROW_ANCHOR_SVG } from './icn_arrow_anchor.svg';
import ARROW_DOWN, { ReactComponent as ARROW_DOWN_SVG } from './icn_arrow_down.svg';
import ARROW_LEFT, { ReactComponent as ARROW_LEFT_SVG } from './icn_arrow_left.svg';
import ARROW_RIGHT, { ReactComponent as ARROW_RIGHT_SVG } from './icn_arrow_right.svg';
import ARROW_CROSS, { ReactComponent as ARROW_CROSS_SVG } from './icn_arrow_cross.svg';
import ARROW_CROSS_SMALL, { ReactComponent as ARROW_CROSS_SMALL_SVG } from './icn_arrow_cross_small.svg';
import ASSISTANT, { ReactComponent as ASSISTANT_SVG } from './icn_assistant.svg';
import BURGER, { ReactComponent as BURGER_SVG } from './icn_burger.svg';
import BURGER_PARTIAL, { ReactComponent as BURGER_PARTIAL_SVG } from './icn_burger_partial.svg';
import CHECK_SMALL, { ReactComponent as CHECK_SMALL_SVG } from './icn_check_small.svg';
import CLOSE_BIG, { ReactComponent as CLOSE_BIG_SVG } from './icn_close_big.svg';
import CURSOR_UP, { ReactComponent as CURSOR_UP_SVG } from './icn_cursor_up.svg';
import CURSOR_DOWN, { ReactComponent as CURSOR_DOWN_SVG } from './icn_cursor_down.svg';
import HINT, { ReactComponent as HINT_SVG } from './icn_hint.svg';
import INFO, { ReactComponent as INFO_SVG } from './icn_info.svg';
import INFO_BLUE, { ReactComponent as INFO_BLUE_SVG } from './icn_info_blue.svg';
import MENU_CLOSE, { ReactComponent as MENU_CLOSE_SVG } from './icn_menu_close.svg';
import PLAY, { ReactComponent as PLAY_SVG } from './icn_play.svg';
import PLUS, { ReactComponent as PLUS_SVG } from './plus.svg';
import DISC_CIRCLE, { ReactComponent as DISC_CIRCLE_SVG } from './icn_disc_circle.svg';
import DISC_STAR, { ReactComponent as DISC_STAR_SVG } from './icn_disc_star.svg';
import SEARCH_BIG, { ReactComponent as SEARCH_BIG_SVG } from './icn_search_big.svg';
import VISIBILITY_ON, { ReactComponent as VISIBILITY_ON_SVG } from './icn_vis_on.svg';
import VISIBILITY_OFF, { ReactComponent as VISIBILITY_OFF_SVG } from './icn_vis_off.svg';
import BQ_CONTACT, { ReactComponent as BQ_CONTACT_SVG } from './icn_bq_contact.svg';
import BQ_LOGO, { ReactComponent as BQ_LOGO_SVG } from './icn_bq_logo.svg';
import CHAIN, { ReactComponent as CHAIN_SVG } from './icn_chain.svg';
import OPTIONS, { ReactComponent as OPTIONS_SVG } from './icn_options.svg';
import DOWNLOAD, { ReactComponent as DOWNLOAD_SVG } from './icn_download.svg';
import DOCUMENT, { ReactComponent as DOCUMENT_SVG } from './icn_doc.svg';
import EXTERNAL_LINK, { ReactComponent as EXTERNAL_LINK_SVG } from './icn_external_link.svg';
import COPY, { ReactComponent as COPY_SVG } from './icn_copy.svg';
import PEOPLE, { ReactComponent as PEOPLE_SVG } from './icn_people.svg';
import PEOPLE_CHECKMARK, { ReactComponent as PEOPLE_CHECKMARK_SVG } from './icn_people_checkmark.svg';
import ARROW, { ReactComponent as ARROW_SVG } from './icn_arrow.svg';
import ARROW_UP, { ReactComponent as ARROW_UP_SVG } from './icn_arrow_up.svg';
import CHECK_BIG, { ReactComponent as CHECK_BIG_SVG } from './icn_check_big.svg';
import LOOP, { ReactComponent as LOOP_SVG } from './icn_loop.svg';
import BLOCK, { ReactComponent as BLOCK_SVG } from './icn_block.svg';
import WELL_BEING, { ReactComponent as WELL_BEING_SVG } from './icn_wbi.svg';
import TRASH, { ReactComponent as TRASH_SVG } from './icn_trash.svg';
import PEOPLE_SEARCH, { ReactComponent as PEOPLE_SEARCH_SVG } from './people_search.svg';
import UPLOAD, { ReactComponent as UPLOAD_SVG } from './icn_upload.svg';
import EDIT, { ReactComponent as EDIT_SVG } from './icn_edit.svg';
import SHARE, { ReactComponent as SHARE_SVG } from './icn_share.svg';
import SEND, { ReactComponent as SEND_SVG } from './icn_send.svg';
import IST, { ReactComponent as IST_SVG } from './icn_ist.svg';
import CALL_HELP, { ReactComponent as CALL_HELP_SVG } from './icn_call_help.svg';
import CONTENT_AUDIO, { ReactComponent as CONTENT_AUDIO_SVG } from './icn_content_audio.svg';
import CONTENT_VIDEO, { ReactComponent as CONTENT_VIDEO_SVG } from './icn_content_video.svg';
import CONTENT_TEXT, { ReactComponent as CONTENT_TEXT_SVG } from './icn_content_text.svg';
import BANNER, { ReactComponent as BANNER_SVG } from './icn_banner.svg';
import BANNER_NO, { ReactComponent as BANNER_NO_SVG } from './icn_banner_no.svg';
import HELP_MENU, { ReactComponent as HELP_MENU_SVG } from './icn_help_menu.svg';
import CHART, { ReactComponent as CHART_SVG } from './icn_chart.svg';
import STAR, { ReactComponent as STAR_SVG } from './icn_star.svg';
import RESET, { ReactComponent as RESET_SVG } from './icn_reset.svg';

export const Icons = {
  ArrowAnchor: ARROW_ANCHOR,
  Arrow: ARROW,
  ArrowDown: ARROW_DOWN,
  ArrowUp: ARROW_UP,
  ArrowLeft: ARROW_LEFT,
  ArrowRight: ARROW_RIGHT,
  ArrowCross: ARROW_CROSS,
  ArrowCrossSmall: ARROW_CROSS_SMALL,
  Assistant: ASSISTANT,
  Burger: BURGER,
  BurgerPartial: BURGER_PARTIAL,
  CloseBig: CLOSE_BIG,
  CheckBig: CHECK_BIG,
  CheckSmall: CHECK_SMALL,
  CursorUp: CURSOR_UP,
  CursorDown: CURSOR_DOWN,
  Hint: HINT,
  Info: INFO,
  InfoBlue: INFO_BLUE,
  Loop: LOOP,
  MenuClose: MENU_CLOSE,
  Play: PLAY,
  Plus: PLUS,
  DiscCircle: DISC_CIRCLE,
  DiscStar: DISC_STAR,
  SearchBig: SEARCH_BIG,
  VisibilityOn: VISIBILITY_ON,
  VisibilityOff: VISIBILITY_OFF,
  Block: BLOCK,
  WellBeing: WELL_BEING,
  BqContact: BQ_CONTACT,
  BqLogo: BQ_LOGO,
  Chain: CHAIN,
  Options: OPTIONS,
  Trash: TRASH,
  PeopleSearch: PEOPLE_SEARCH,
  Download: DOWNLOAD,
  Document: DOCUMENT,
  ExternalLink: EXTERNAL_LINK,
  Upload: UPLOAD,
  Edit: EDIT,
  People: PEOPLE,
  PeopleCheckmark: PEOPLE_CHECKMARK,
  Copy: COPY,
  Share: SHARE,
  Send: SEND,
  Ist: IST,
  CallHelp: CALL_HELP,
  ContentAudio: CONTENT_AUDIO,
  ContentVideo: CONTENT_VIDEO,
  ContentText: CONTENT_TEXT,
  Banner: BANNER,
  BannerNo: BANNER_NO,
  HelpMenu: HELP_MENU,
  Chart: CHART,
  Star: STAR,
  Reset: RESET,
};

export const IconsSvg = {
  ArrowAnchor: ARROW_ANCHOR_SVG,
  Arrow: ARROW_SVG,
  ArrowDown: ARROW_DOWN_SVG,
  ArrowUp: ARROW_UP_SVG,
  ArrowLeft: ARROW_LEFT_SVG,
  ArrowRight: ARROW_RIGHT_SVG,
  ArrowCross: ARROW_CROSS_SVG,
  ArrowCrossSmall: ARROW_CROSS_SMALL_SVG,
  Assistant: ASSISTANT_SVG,
  Burger: BURGER_SVG,
  BurgerPartial: BURGER_PARTIAL_SVG,
  BqContact: BQ_CONTACT_SVG,
  BqLogo: BQ_LOGO_SVG,
  CloseBig: CLOSE_BIG_SVG,
  CheckBig: CHECK_BIG_SVG,
  CheckSmall: CHECK_SMALL_SVG,
  CursorUp: CURSOR_UP_SVG,
  CursorDown: CURSOR_DOWN_SVG,
  Hint: HINT_SVG,
  Info: INFO_SVG,
  InfoBlue: INFO_BLUE_SVG,
  Loop: LOOP_SVG,
  MenuClose: MENU_CLOSE_SVG,
  Play: PLAY_SVG,
  Plus: PLUS_SVG,
  DiscCircle: DISC_CIRCLE_SVG,
  DiscStar: DISC_STAR_SVG,
  SearchBig: SEARCH_BIG_SVG,
  VisibilityOn: VISIBILITY_ON_SVG,
  VisibilityOff: VISIBILITY_OFF_SVG,
  Block: BLOCK_SVG,
  WellBeing: WELL_BEING_SVG,
  Chain: CHAIN_SVG,
  Options: OPTIONS_SVG,
  Trash: TRASH_SVG,
  PeopleSearch: PEOPLE_SEARCH_SVG,
  DownloadSvg: DOWNLOAD_SVG,
  Document: DOCUMENT_SVG,
  ExternalLink: EXTERNAL_LINK_SVG,
  Upload: UPLOAD_SVG,
  Edit: EDIT_SVG,
  Copy: COPY_SVG,
  People: PEOPLE_SVG,
  Share: SHARE_SVG,
  PeopleCheckmark: PEOPLE_CHECKMARK_SVG,
  Send: SEND_SVG,
  Ist: IST_SVG,
  CallHelp: CALL_HELP_SVG,
  ContentAudio: CONTENT_AUDIO_SVG,
  ContentVideo: CONTENT_VIDEO_SVG,
  ContentText: CONTENT_TEXT_SVG,
  Banner: BANNER_SVG,
  BannerNo: BANNER_NO_SVG,
  HelpMenu: HELP_MENU_SVG,
  Chart: CHART_SVG,
  Star: STAR_SVG,
  Reset: RESET_SVG,
};
