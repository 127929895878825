import React, { useEffect, useState } from 'react';
import styles from './Toggle.module.scss';
import classNames from 'classnames';


const Toggle = (props) => {
  const {
    id,
    disabled,
    onChange,
    checked,
    label = '',
  } = props;
  const [ isChecked, setIsChecked ] = useState(checked || false);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [ checked ]);

  return (
    <label
      htmlFor={id}
      className={classNames(styles.toggle, { [styles.disabled]: disabled })}
    >
      { Boolean(label) && (
        <span className={classNames(styles.textLabel, { [styles.isChecked]: isChecked })}>
          { label }
        </span>
      ) }
      <div className={classNames(styles.toggleButton, { [styles.disabled]: disabled })}>
        <input
          id={id}
          type='checkbox'
          name='toggleSwitch'
          className={styles.toggleCheckbox}
          checked={isChecked}
          onChange={handleChange}
        />
        <div className={styles.toggleLabel}>
          <span className={styles.toggleInner} />
          <span className={styles.toggleSwitch} />
        </div>
      </div>
    </label>
  );
};

export default Toggle;
