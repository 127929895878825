import { call, put, takeEvery } from 'redux-saga/effects';

import { get } from 'api';

import * as fromActionTypes from 'store/actionTypes/employeeOverview';
import * as fromActions from 'store/actions/employeeOverview';

import { getMappedEmployees } from 'utils/users';


function* getEmployee(payload) {
  try {
    const { employeeId } = payload.payload;

    const params = new URLSearchParams();
    params.append('id', employeeId);
    params.append('expand', 'roles');
    params.append('expand', 'organizationalRoles');
    params.append('include', 'settings');

    const { status, ok, data } = yield call(get, 'core/user/info', params);
    const employeeData = { ...data, id: employeeId };

    if (ok && status === 200) {
      const mappedEmployee = getMappedEmployees([ { user: employeeData } ])[0];
      yield put(fromActions.getEmployeeFulfilled({ employee: mappedEmployee }));
    } else {
      yield put(fromActions.getEmployeeRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.getEmployeeRejected({ error }));
  }
}

export function* watchGetEmployee() {
  yield takeEvery(fromActionTypes.GET_EMPLOYEE, getEmployee);
}
