export const COMPANY_FEATURES = {
  TEAM_MAPPING: 'MODULE_TEAMMAPPING',
  ROLE_MAPPING: 'MODULE_ROLEMAPPING',
  WELL_BEING: 'MODULE_WELLBEING',
  INTEGRATION_GARMIN: 'INTEGRATION_GARMIN',
  BIG5_ASSESSMENT: 'ASSESSMENT_BIG5',
  POTENTIAL_ASSESSMENT: 'ASSESSMENT_POTENTIAL',
  WORK_PREF_ASSESSMENT: 'ASSESSMENT_WORK-PREFERENCE',
  KEY_COMP_ASSESSMENT: 'ASSESSMENT_KEY-COMP',
  LEAD_COMP_ASSESSMENT: 'ASSESSMENT_LEAD-COMP',
  RMP_ASSESSMENT: 'ASSESSMENT_RMP',
  NINE_LEVELS_ASSESSMENT: 'ASSESSMENT_9LEVELS',
};

export const COMPANY_FEATURES_NEXT = {
  MODULE_RECRUITING: 'candidates',
  MODULE_ROLEMAPPING: 'roles',
  MODULE_TEAMMAPPING: 'teams',
  MODULE_SURVEYS: 'surveys',
  MODULE_WELLBEING: 'wellbeing',
  MODULE_BALANCEDYOU: 'balancedYou',
  INTEGRATION_GARMIN: 'garmin',
  FEATURE_ASSISTANT: 'assistant',
  FEATURE_ONTOLOGY: 'ontology',
  FEATURE_ROLE_TEMPLATES: 'roleTemplates',
  ASSESSMENT_RMP: 'rmp',
  ASSESSMENT_IST: 'ist',
};
