// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY

// OTHER COMPONENTS

// UTILS

// STORE NEXT
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  selectSettingsTranslations, selectSettingsTranslationsUpdateFlag,
} from 'features/framework/storeNext/configurationSlice';
import store from 'store';
import { getTranslationsFulfilled } from 'store/actions';

// CONFIG & DATA
// const Config = {};

const OldStoreTranslations = ({ translations }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslationsFulfilled(translations));
  }, [ dispatch, translations ]);

  return null;
};

const StoreNextTranslations = () => {
  const translations = useSelector(selectSettingsTranslations);
  const translationsUpdateFlag = useSelector(selectSettingsTranslationsUpdateFlag);

  // RENDER: StoreNextTranslations
  return (
    <div>
      { translationsUpdateFlag !== undefined && (
        <Provider store={store}>
          <OldStoreTranslations translations={translations} />
        </Provider>
      ) }
    </div>
  );
};

export default StoreNextTranslations;
