import * as action from 'store/actions';
import { post } from 'api';
import {
  call,
  put,
  take,
  select,
} from 'redux-saga/effects';

// UTILS
import { COMPANY_ID, CURRENT_LOCATION } from 'utils/configuration';
import { setCookie } from 'utils/cookie';
// eslint-disable-next-line import/no-cycle
import { translate } from 'utils/translator/translator';
import { convertDateToLocale } from 'utils/dateTools';
import { setConfigurationAvailableInstances } from 'store/actions/configuration';
import { selectCurrentLanguage } from 'store/selectors/localisation';
import { getInstanceLoginPageUrl } from 'features/framework/utils/domain';

function* login(loginDto) {
  const {
    mail,
    password,
    authToken,
    totp,
    extendedSession,
  } = loginDto;

  // Define company to add to payload.
  // Note: if loginDto.company is undefined, the 'company' key will be scrapped,
  // which is useful for the multiple instance selection flow.
  let company = COMPANY_ID;
  if ('company' in loginDto) {
    company = loginDto.company;
  }

  let payload;
  if (authToken) {
    // If authToken is set, login with mail/password has already been successfully executed;
    // in this case the payload will only contain the data required for the 2nd login step.
    payload = {
      authToken,
      totp,
      company,
      extendedSession,
    };
  } else {
    // Otherwise it's the 1st login step, which is often the only one required for regular users.
    payload = {
      mail,
      password,
      company,
      extendedSession,
    };
  }

  try {
    yield put(action.loading());

    const { ok, status, data } = yield call(
      post,
      '/core/user/login',
      payload,
      {},
      { withCredentials: true },
    );

    if (ok && status === 200) {
      yield put(action.setExtendedSessionDuration(extendedSession));

      // Login successful
      if (data.token) {
        yield put(action.loginFulfilled(data));
        return;
      }

      // First login step successful. Several cases:
      // 1) Admin FE login
      // 2) company-enabled MFA login
      // 3) user with multiple companies to choose from
      if (data.authToken) {
        yield put(action.authTokenFetched(data));

        // Check user instances
        if (data.companies) {
          yield put(setConfigurationAvailableInstances(data.companies));

          // Automatic redirect if only one instance is provided
          if (data.companies.length === 1) {
            const lang = yield select(selectCurrentLanguage);
            const { domain } = CURRENT_LOCATION;

            setCookie(
              'token',
              data.authToken,
              {
                domain,
                samesite: 'lax',
                'max-age': 60, // 1 minute
              },
            );

            window.location.href = getInstanceLoginPageUrl(
              data.companies[0].id,
              extendedSession,
              lang,
            );
          }
        }

        return;
      }

      // Reject login (admin case only)
      yield put(action.loginRejected({
        error: {
          errorMessage: translate('admin_login_not_allowed_msg'),
        },
      }));
    } else {
      // ERROR PROCESSING
      const { error } = data;

      // Restricted company error (expired trial or subscription)
      if (error?.errorCode === 8003) {
        error.errorMessage = 'restrictedCompany';
      }

      // Too many login attempts
      if (error?.errorCode === 1114) {
        error.errorMessage = convertDateToLocale(error.errorMessage); // Fix UTP date
      }

      // MFA: the user took too long, restart over
      let mfaRequired;
      if (error?.errorCode === 1108 || error?.errorCode === 1109) {
        error.errorMessage = translate('login_mfa_error_expired');
        mfaRequired = false;
      }

      yield put(action.loginRejected({ error, mfaRequired }));
    }
  } catch (error) {
    yield put(action.loginRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

export default function* watchLoginRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.LOGIN);
      yield call(login, payload);
    } catch (error) {
      yield put(action.loginRejected(error));
    }
  }
}
