// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './NineLevelsDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { capitalise } from 'utils/textTools';

// CONFIG
const Config = {
  rangeMinWidth: 2.5,
  size: 15,
};


// COMPONENT: NineLevelsDiagram
const NineLevelsDiagram = (props) => {
  const {
    results,
    range,
    color,
  } = props;

  let resultsHeight = Config.size;
  if (range) {
    resultsHeight /= 3;
  }

  // RENDER: Range
  const renderRange = () => {
    if (!range?.length) {
      return null;
    }

    let [ rangeMin, rangeMax ] = range;
    if (rangeMin > rangeMax) {
      return null;
    }

    // A range must have a min width. Also adjust position near edges if overflowing.
    const diff = rangeMax - rangeMin;
    if (diff < Config.rangeMinWidth) {
      rangeMin -= (Config.rangeMinWidth - diff) / 2;
      rangeMax += (Config.rangeMinWidth - diff) / 2;
      if (rangeMax > 100) {
        rangeMin = 100 - Config.rangeMinWidth;
        rangeMax = 100;
      }
      if (rangeMin < -100) {
        rangeMin = -100;
        rangeMax = -100 + Config.rangeMinWidth;
      }
    }

    return (
      <rect
        x={`${(rangeMin + 100) / 2}%`}
        width={`${(rangeMax - rangeMin) / 2}%`}
        height={Config.size}
        rx={Config.size / 2}
        fill={styles.colorPrimary3}
      />
    );
  };

  // RENDER: Score bar
  const renderBar = (index) => {
    const value = Math.abs(results[index]);
    const shade = index ? 1 : 2;
    return (
      <div
        className={classNames([
          styles.bar,
          { [styles.flipped]: !index },
        ])}
        style={{
          paddingRight: resultsHeight / 2,
          height: Config.size,
        }}
      >
        <svg fill={styles[`color${capitalise(color)}${shade}`]}>
          <circle
            cx={`${value / 2}%`}
            cy={Config.size / 2}
            r={resultsHeight / 2}
          />
          <rect
            width={`${value / 2}%`}
            y={(Config.size - resultsHeight) / 2}
            height={resultsHeight}
          />
        </svg>
      </div>
    );
  };

  // RENDER: NineLevelsDiagram
  return (
    <div className={styles.nineLevelsDiagram}>
      <svg
        height={Config.size}
        width='100%'
      >
        { /* RANGE */ }
        { renderRange() }

        { /* MAIN HORIZONTAL LINE */ }
        <rect
          y={Math.floor(Config.size / 2)}
          width='100%'
          height={1}
          fill={styles.colorGrey3}
        />
        { /* VERTICAL LINE ON CENTER */ }
        <rect
          x='50%'
          width={2}
          height={Config.size}
          fill='white'
          transform='translate(-1 0)'
        />
      </svg>

      { Boolean(results?.length) && (
        <div
          className={styles.barContainer}
          style={{ marginTop: -Config.size }}
        >
          { renderBar(0) }
          { renderBar(1) }
        </div>
      ) }
    </div>
  );
};

export default NineLevelsDiagram;
