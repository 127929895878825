import React from 'react';
import styles from './Big5AssessmentEnd.module.scss';

// STORE
import { useDispatch } from 'react-redux';
import { getAssessmentResult } from 'store/actions';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// CONFIG & DATA
import { getTranslationIds } from 'ui/molecules/AssessmentNext/AssessmentNext.translations';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';


const Big5AssessmentEnd = (props) => {
  const {
    finishable,
    handleFinish = () => {},
    errorEnd,
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();

  const assessmentType = ASSESSMENT_TYPES.BIG5;
  const translationIds = getTranslationIds(ASSESSMENT_NEXT_TYPES[assessmentType]);

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  const handleReport = () => {
    dispatch(getAssessmentResult(assessmentType));
    handleFinish();
  };

  return (
    <div className={styles.assessmentEnd}>
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>
          <div className={styles.sTitle}>
            { translate(translationIds.successTitle) }
          </div>

          <div className={styles.buttons}>
            <Button
              size='M'
              disabled={!finishable}
              looks='secondary'
              onClick={handleReport}
            >
              { translate('big5_results_button_showreport') }
            </Button>
          </div>
        </>
      ) }
    </div>
  );
};

export default Big5AssessmentEnd;
