// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { embedDashboard } from '@preset-sdk/embedded';

// UTILS
import { apiNext } from 'apiNext';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: Diagram
const Diagram = (props) => {
  // PROPS
  const { supersetUrl, dashboardEmbeddedId } = props;

  // SPECIAL HOOKS

  const fetchGuestToken = () => apiNext.post('/core/company/dashboards/token')
  .then((response) => response.token);

  useEffect(() => {
    if (!dashboardEmbeddedId) {
      return;
    }

    embedDashboard({
      id: dashboardEmbeddedId,
      supersetDomain: supersetUrl,
      mountPoint: document.getElementById('blu-superset-diagram'),
      fetchGuestToken: () => fetchGuestToken(),
    });
  }, [ dashboardEmbeddedId, supersetUrl ]);

  // RENDER: Diagram
  return (
    <div id='blu-superset-diagram' />
  );
};

export default Diagram;
