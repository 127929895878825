export const GET_ALL_ASSESSMENTS = 'GET_ALL_ASSESSMENTS';
export const GET_ALL_ASSESSMENTS_PENDING = 'GET_ALL_ASSESSMENTS_PENDING';
export const GET_ALL_ASSESSMENTS_FULFILLED = 'GET_ALL_ASSESSMENTS_FULFILLED';
export const GET_ALL_ASSESSMENTS_REJECTED = 'GET_ALL_ASSESSMENTS_REJECTED';

export const getAllAssessments = () => ({
  type: GET_ALL_ASSESSMENTS,
  payload: null,
});

export const getAllAssessmentsFulfilled = () => ({
  type: GET_ALL_ASSESSMENTS_FULFILLED,
  payload: null,
});

export const getAllAssessmentsRejected = ({ error }) => ({
  type: GET_ALL_ASSESSMENTS_REJECTED,
  payload: { error },
});

