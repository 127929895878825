import { sortAlphabetically } from 'utils/strings';

export const selectCountries = (state) => state.staticValues?.countries
?.sort((a, b) => sortAlphabetically(a.label, b.label));

export const selectNationalities = (state) => state.staticValues?.nationalities
?.sort((a, b) => sortAlphabetically(a.label, b.label));

export const selectEducation = (state) => state.staticValues.education;

export const selectEducationOptions = (state) => state.staticValues.educationOptions;

export const selectIndustries = (state) => state.staticValues.industries;

export const selectIndustryOptions = (state) => state.staticValues.industryOptions;

export const selectCurrencies = (state) => state.staticValues.currencies;

export const selectCurrencyOptions = (state) => state.staticValues.currencyOptions;

export const selectCareerLevels = (state) => state.staticValues.careerLevels;

export const selectCareerLevelOptions = (state) => state.staticValues.careerLevelOptions;

export const selectJobFamilies = (state) => state.staticValues.jobFamilies;

export const selectJobFamilyOptions = (state) => state.staticValues.jobFamilyOptions;

