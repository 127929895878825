import React, { useEffect, useState } from 'react';
import styles from './TeamUsersList.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useTranslate } from 'utils/translator';
import { PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { REGISTRATION_STATUSES } from 'utils/configuration/const/registration-statuses';
import { useBreakpoint } from 'utils/hooks';
import { sortAlphabetically } from 'utils/strings';

import * as api from 'api';
import { apiNext } from 'apiNext';
import classNames from 'classnames';

import {
  deleteUser, getCurrentUser, getTeamUsers,
  initDeleteUser,
  showToast,
} from 'store/actions';

import {
  getDeleteTeamUserSuccess,
} from 'store/selectors/teamOverview';
import { selectCapabilitiesNext } from 'store/selectors/configuration';

import { ReactComponent as Star } from 'assets/icons/icn_star.svg';
// eslint-disable-next-line import/no-cycle
import {
  ImgCircle, OptionsMenu, CollapsiblePanel, Pagination, SkeletonFlexible,
} from 'ui/basic';


import * as fromTeamOverviewSelectors from 'store/selectors/teamOverview';


const OPTION_TYPES = {
  SHOW_PROFILE: 'showProfile',
  SET_AS_TEAM_LEAD: 'setAsTeamLead',
  REMOVE_TEAM_LEAD_STATUS: 'removeTeamLeadStatus',
  DELETE_FROM_TEAM: 'deleteFromTeam',
};

const Config = {
  paginationLimit: 20,
};

const TeamUsersList = (props) => {
  const {
    onUserClick,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const bp = useBreakpoint();

  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const teamUserDeletedSuccessfully = useSelector(getDeleteTeamUserSuccess);
  const me = useSelector(({ currentUser }) => currentUser);

  const team = useSelector(fromTeamOverviewSelectors.getTeam);
  const teamUsersProcessing = useSelector(fromTeamOverviewSelectors.getFetchTeamUsersProcessing);
  const users = team?.paginatedUsers || [];
  const usersTotalCount = team?.usersTotalCount;

  const teamLeaders = users.filter((user) => user.isTeamLead) || [];
  const teamMembers = users.filter((user) => !user.isTeamLead) || [];

  const [ showPagination, setShowPagination ] = useState(true);
  const [ pageIndex, setPageIndex ] = useState(0);
  useEffect(() => {
    if ((usersTotalCount > 0 && !users.length) || usersTotalCount > Config.paginationLimit) {
      dispatch(getTeamUsers({
        teamId: params.teamId,
        offset: pageIndex * Config.paginationLimit,
      }));
    }
  }, [
    dispatch,
    params.teamId,
    pageIndex,
    usersTotalCount,
    users.length,
  ]);

  const getOptions = (user) => {
    const options = [
      { value: OPTION_TYPES.DELETE_FROM_TEAM, label: translate('team_users_option3') },
    ];

    if (user && user.registrationStatus !== REGISTRATION_STATUSES.INVITED) {
      if (!user.isTeamLead) {
        options.unshift({ value: OPTION_TYPES.SET_AS_TEAM_LEAD, label: translate('team_users_option2') });
      }

      if (user.isTeamLead) {
        options.unshift({
          value: OPTION_TYPES.REMOVE_TEAM_LEAD_STATUS,
          label: translate('team_users_option4'),
        });
      }

      if (capabilitiesNext.employeesView) {
        options.unshift({ value: OPTION_TYPES.SHOW_PROFILE, label: translate('team_users_option1') });
      }
    }

    return options;
  };

  useEffect(() => {
    if (teamUserDeletedSuccessfully) {
      const toastTitle = translate('team_user_removed__toast_title');
      const toastContent = translate('team_user_removed__toast_desc');

      dispatch(showToast(toastTitle, toastContent));

      // set to initial values, thus the toast will be displayed only once
      dispatch(initDeleteUser());
    }
  }, [ dispatch, teamUserDeletedSuccessfully, translate ]);

  const handleUserRoleUpdate = (userId, role) => {
    apiNext.post(`core/company/teams/${params.teamId}/users/${userId}`, { role })
    .then(() => {
      let toastTitle = translate('team_user_set_as_teamlead__toast_title');
      let toastContent = translate('team_user_set_as_teamlead__toast_descr');

      if (role !== PLATFORM_ROLES.TEAM_LEADER) {
        toastTitle = translate('team_user_remove_teamlead_status__toast_title');
        toastContent = translate('team_user_remove_teamlead_status__toast_descr');
      }

      dispatch(showToast(toastTitle, toastContent));

      dispatch(getTeamUsers({
        teamId: params.teamId,
        offset: pageIndex * Config.paginationLimit,
      }));
      if (userId === me.id) {
        dispatch(getCurrentUser());
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  };

  const renderUser = (user) => {
    let name = user.mail;
    if (user.name) {
      name = user.name;
    }

    return (
      <div
        key={`employee-${user.id}`}
        className={styles.employee}
        role='presentation'
        onClick={(event) => {
          const optionsMenuEl = document.getElementById(`optionsMenu${user.id}`);
          if (optionsMenuEl?.contains(event.target) || optionsMenuEl === event.target) {
            return;
          }

          setTimeout(() => {
            onUserClick?.(user);
          });
        }}
      >
        <div className={styles.imgCircle}>
          <ImgCircle
            size='S'
            src={api.getUserImageUrl(user.id)}
            label1={name.split(' ')[0]}
            label2={name.split(' ')[1]}
          />
        </div>

        <div className={styles.namePosition}>
          <div className={styles.name}>{ name }</div>
          <div className={styles.position}>{ user.orgRole || user.position }</div>
        </div>

        { /* STAR */ }
        { user.isTeamLead && (
          <div className={styles.star}>
            <Star />
          </div>
        ) }

        { capabilitiesNext.teamsCreate && (
          <div id={`optionsMenu${user.id}`} className={styles.optionsMenu}>
            <OptionsMenu
              withBackgroundDark
              options={getOptions(user)}
              onClick={(value) => {
                switch (value) {
                  case OPTION_TYPES.SHOW_PROFILE: {
                    const path = me.id === user.id
                      ? '/my-profile'
                      : `/employees/${user.id}/profile`;
                    window.open(path, '_blank');
                    break;
                  }
                  case OPTION_TYPES.SET_AS_TEAM_LEAD:
                    handleUserRoleUpdate(user.id, PLATFORM_ROLES.TEAM_LEADER);
                    break;
                  case OPTION_TYPES.REMOVE_TEAM_LEAD_STATUS:
                    handleUserRoleUpdate(user.id, PLATFORM_ROLES.TEAM_MEMBER);
                    break;
                  case OPTION_TYPES.DELETE_FROM_TEAM:
                    dispatch(deleteUser({ teamId: params.teamId, userId: user.id }));
                    break;
                  default:
                }
              }}
            />
          </div>
        ) }
      </div>
    );
  };

  return (
    <div className={styles.teamUsersList}>
      <CollapsiblePanel
        header={<div className={styles.header}>{ translate('team_profile__team_members') }</div>}
        showTopBorder={false}
        expanded={!bp.isXs}
        onExpand={setShowPagination}
      >
        <>
          { teamUsersProcessing && (
            <div className={styles.loading}>
              <SkeletonFlexible repeat={10} />
            </div>
          ) }

          { !teamUsersProcessing && (
            <>
              { ((pageIndex === 0 && !teamLeaders.length) || teamLeaders.length > 0) && (
                <div className={styles.leaders}>
                  <div className={styles.label}>{ translate('leads') }</div>

                  { teamLeaders.length === 0 && (
                    <div className={styles.empty}>
                      { translate('team_no_leads') }
                    </div>
                  ) }

                  { teamLeaders.length > 0 && (
                    <>
                      { teamLeaders
                      .sort((item1, item2) => {
                        const item1LastName = item1.name.split(' ')[1];
                        const item2LastName = item2.name.split(' ')[1];
                        return sortAlphabetically(item1LastName, item2LastName);
                      })
                      .map((user) => renderUser(user)) }
                    </>
                  ) }
                </div>
              ) }

              { teamLeaders.length < 10 && (
                <div className={styles.members}>
                  <div className={classNames(styles.label, {
                    [styles.noLeaders]: teamLeaders.length === 0 && pageIndex !== 0,
                  })}
                  >
                    { translate('members') }
                  </div>
                  { teamMembers.length === 0 && (
                    <div className={styles.empty}>
                      { translate('team_no_members') }
                    </div>
                  ) }

                  { teamMembers.length > 0 && (
                    <>
                      { teamMembers
                      .sort((item1, item2) => {
                        const item1LastName = item1.name.split(' ')[1];
                        const item2LastName = item2.name.split(' ')[1];
                        return sortAlphabetically(item1LastName, item2LastName);
                      })
                      .map((user) => renderUser(user)) }
                    </>
                  ) }
                </div>
              ) }
            </>
          ) }
        </>
      </CollapsiblePanel>

      { usersTotalCount > Config.paginationLimit && showPagination && (
        <div className={styles.paginationWrapper}>
          <Pagination
            pageCount={Math.ceil(usersTotalCount / Config.paginationLimit)}
            pagePosition={pageIndex}
            showPagerButtons
            showNumbers
            onPagePositionUpdate={setPageIndex}
          />
        </div>
      ) }
    </div>
  );
};

export default TeamUsersList;
