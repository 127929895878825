// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PlanSignupTrial.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DialogSequence } from 'ui/molecules/DialogSequence';
import { StoreNextTranslations } from 'ui/basic/utils/StoreNextTranslations';

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: PlanSignupTrial
const PlanSignupTrial = () => {
  // PROPS

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);
  };

  // RENDER: DemoInstanceRequest
  return (
    <div className={classNames(styles.planSignupTrial)}>
      <StoreNextTranslations />
      <DialogSequence
        type='trialSignup'
        configOverride={{}}
        userId='trial-signup'
        showLanguageToggle
        onAnswer={() => {}}
        onAllAnswers={handleAllAnswers}
        onError={() => {}}
      />
    </div>
  );
};

export default PlanSignupTrial;
