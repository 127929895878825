import React, { useEffect, useState } from 'react';
import styles from './LeaderUserGroup.module.scss';

import * as api from 'api';

// UTILS
import { useTranslate } from 'utils/translator';
import { USER_GROUP_PROFILE_SETTINGS_ITEMS } from 'features/framework/utils/configuration';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompany, selectConfigSwitches,
  selectInstanceFlavor,
  setCompanyCustomProfileSettingsApi, setConfigSwitches,
} from 'features/framework/storeNext/configurationSlice';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Checkbox, Toggle } from 'ui/basic';


const CONFIG_SWITCHES = [
  {
    id: 'employeesInvite',
    title: 'usergroup_settings_leader_right1',
    description: 'usergroup_settings_leader_right1_descr',
  },
  {
    id: 'candidatesInvite',
    title: 'invite_candidates_permission',
    description: 'invite_candidates_permission_descr',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'premiumAssessmentBooking',
    title: 'purchase_premium_assessments_permission_title',
    description: 'purchase_premium_assessments_permission_copy',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'employeesView',
    title: 'usergroup_settings_leader_right9',
    description: 'usergroup_settings_leader_right9_descr',
  },
  {
    id: 'candidatesView',
    title: 'view_candidates_permission',
    description: 'view_candidates_permission_descr',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'rolesView',
    title: 'usergroup_settings_leader_right2',
    description: 'usergroup_settings_leader_right2_descr',
    childrenItems: [ 'rolesCreate', 'rolesMatch' ],
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'rolesCreate',
    title: 'usergroup_settings_leader_right3',
    description: 'usergroup_settings_leader_right3_descr',
    parentItem: 'rolesView',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'rolesMatch',
    title: 'usergroup_settings_leader_right4',
    description: 'usergroup_settings_leader_right4_descr',
    parentItem: 'rolesView',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'teamsView',
    title: 'usergroup_settings_leader_right8',
    description: 'usergroup_settings_leader_right8_descr',
    childrenItems: [ 'teamsCreate', 'teamsStaff' ],
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'teamsCreate',
    title: 'usergroup_settings_leader_right6',
    description: 'usergroup_settings_leader_right6_descr',
    parentItem: 'teamsView',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'teamsStaff',
    title: 'usergroup_settings_leader_right7',
    description: 'usergroup_settings_leader_right7_descr',
    parentItem: 'teamsView',
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'vacanciesView',
    title: 'usergroup_settings_leader_view_vacancies',
    description: 'usergroup_settings_leader_view_vacancies_descr',
    childrenItems: [ 'vacanciesCreate' ],
    excludeForFlavor: [ 'balancedYou' ],
  },
  {
    id: 'vacanciesCreate',
    title: 'usergroup_settings_leader_create_vacancy',
    description: 'usergroup_settings_leader_create_vacancy_descr',
    parentItem: 'vacanciesView',
    excludeForFlavor: [ 'balancedYou' ],
  },
];

const DESCRIPTIONS = [
  { id: 'descr1', label: 'usergroup_settings_leader_descr1' },
  { id: 'descr2', label: 'usergroup_settings_leader_descr2' },
  { id: 'descr3', label: 'usergroup_settings_leader_descr3' },
  { id: 'descr4', label: 'usergroup_settings_leader_descr4' },
];

const LeaderUserGroup = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const instanceFlavor = useSelector(selectInstanceFlavor);
  const company = useSelector(selectCompany);

  // PROFILE SETTINGS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ profileSettingsItems, setProfileSettingsItems ] = useState([]);
  useEffect(() => {
    if (!company || profileSettingsItems.length) {
      return;
    }

    setProfileSettingsItems(USER_GROUP_PROFILE_SETTINGS_ITEMS
    .filter((item) => {
      if (!item.excludeFor) {
        return true;
      }

      return !item.excludeFor.includes('leader');
    })
    .map((item) => {
      let checked;
      if (item.alwaysChecked) {
        checked = true;
      } else {
        checked = company.custom?.profileSettings?.leader?.[item.id] === undefined
          ? true
          : !!company.custom.profileSettings.leader[item.id];
      }

      return {
        ...item,
        checked,
      };
    }));
  }, [ company, profileSettingsItems ]);

  const handleProfileSettingsChange = (value, targetItem) => {
    const payload = {};
    profileSettingsItems.forEach((itemInternal) => {
      if (targetItem.id === itemInternal.id) {
        payload[itemInternal.id] = value;
      } else {
        payload[itemInternal.id] = itemInternal.checked;
      }
    });

    dispatch(setCompanyCustomProfileSettingsApi({
      leader: payload,
    }));
  };

  // CONFIG SWITCHES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const configSwitchesFromStore = useSelector(selectConfigSwitches);
  const [ configSwitchUpdateProcessing, setConfigSwitchUpdateProcessing ] = useState(false);
  const [ configSwitchesInternal, setConfigSwitchesInternal ] = useState([]);
  useEffect(() => {
    if (!configSwitchesFromStore?.length || configSwitchesInternal.length) {
      return;
    }

    const internal = CONFIG_SWITCHES
    .filter((configSwitchItem) => !configSwitchItem.excludeForFlavor
        || !configSwitchItem.excludeForFlavor.includes(instanceFlavor))
    .map((configSwitchItem) => {
      const configSwitchFromStore = configSwitchesFromStore.find((switchItem) => (
        switchItem.id === configSwitchItem.id
      ));

      return {
        ...configSwitchItem,
        active: !!(configSwitchFromStore && configSwitchFromStore.status),
      };
    });

    setConfigSwitchesInternal(internal);
  }, [ configSwitchesFromStore, configSwitchesInternal, instanceFlavor ]);

  const getIsDisabled = (configSwitch) => {
    if (!configSwitch || !configSwitch.parentItem) {
      return false;
    }

    const parentItem = configSwitchesInternal.find((rightItem) => rightItem.id === configSwitch.parentItem);
    return parentItem?.active === false;
  };

  const [ configSwitchesErrorMessage, setConfigSwitchesErrorMessage ] = useState('');
  const handleToggleChangeAPI = (configSwitch, value) => {
    setConfigSwitchUpdateProcessing(true);

    // on toggling off, all children items that are dependent from parent should also be deactivated
    if (!value && configSwitch.childrenItems) {
      const configSwitchIds = [
        configSwitch.id,
        ...configSwitch.childrenItems.map((childItem) => childItem),
      ];
      Promise.all(configSwitchIds.map((configSwitchId) => (
        api.post(`core/config/switches/${configSwitchId}`, { active: value })
      )))
      .then((response) => {
        if (response.every((r) => r.ok)) {
          dispatch(setConfigSwitches(configSwitchesInternal));
        } else {
          setConfigSwitchesErrorMessage(translate('error_something_went_wrong'));
        }
        setConfigSwitchUpdateProcessing(false);
      });
    } else {
      api
      .post(`core/config/switches/${configSwitch.id}`, { active: value })
      .then(({ ok, status, data }) => {
        if (ok && status === 200) {
          dispatch(setConfigSwitches(configSwitchesInternal));
        } else {
          setConfigSwitchesErrorMessage(data.error?.errorMessage);
        }

        setConfigSwitchUpdateProcessing(false);
      })
      .catch((error) => {
        setConfigSwitchesErrorMessage(error.message);
      });
    }
  };

  const handleToggleChange = (configSwitchItem, checked) => {
    const thisConfigSwitchesInternal = JSON.parse(JSON.stringify(configSwitchesInternal));
    const thisConfigSwitchItem = thisConfigSwitchesInternal.find((csi) => (
      csi.id === configSwitchItem.id
    ));

    if (thisConfigSwitchItem) {
      thisConfigSwitchItem.active = checked;

      // on toggle off, all children items should also be deactivated
      if (thisConfigSwitchItem.childrenItems && !checked) {
        thisConfigSwitchItem.childrenItems.forEach((childItemId) => {
          const thisChildItem = thisConfigSwitchesInternal.find((csi) => csi.id === childItemId);
          if (thisChildItem) {
            thisChildItem.active = checked;
          }
        });
      }

      setConfigSwitchesInternal(thisConfigSwitchesInternal);
      handleToggleChangeAPI(thisConfigSwitchItem, checked);
    }
  };


  return (
    <div className={styles.leaderUserGroup}>
      <div className={styles.main}>
        <div className={styles.title}>
          { translate('usergroup_settings_leader') }
        </div>
        <div className={styles.description}>
          { DESCRIPTIONS.map((description) => (
            <div className={styles.descRow} key={description.id}>
              <span>-</span>
              <span>{ translate(description.label) }</span>
            </div>
          )) }
        </div>

        { /* PROFILE SETTINGS */ }
        <div className={styles.profileSettings}>
          <span className='bluTypeLabelL'>
            { translate('profile_settings_configuration_title') }
          </span>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('profile_settings_configuration_copy') }
          </div>
          <div className={styles.checkBoxes}>
            { profileSettingsItems.map((item) => (
              <Checkbox
                key={item.id}
                name={translate(item.name)}
                checked={item.checked}
                disabled={item.disabled}
                onChange={(checked) => {
                  const profileSettingsItemsInternal = JSON.parse(JSON.stringify(profileSettingsItems));
                  const thisItem = profileSettingsItemsInternal.find((psi) => psi.id === item.id);
                  if (thisItem) {
                    thisItem.checked = checked;
                    setProfileSettingsItems(profileSettingsItemsInternal);

                    handleProfileSettingsChange(checked, item);
                  }
                }}
              />
            )) }
          </div>
        </div>

        <div className={styles.rights}>
          <div className={styles.title}>
            { translate('usergroup_settings_leader_rights') }
          </div>
          <div className={styles.description}>
            { translate('usergroup_settings_leader_rights_descr') }
          </div>

          { configSwitchesErrorMessage && (
            <div className={classNames('error', 'marginTopXs')}>{ configSwitchesErrorMessage }</div>
          ) }

          <div className={styles.rows}>
            { configSwitchesInternal.map((configSwitchItem, index) => (
              <div className={styles.row} key={configSwitchItem.id}>
                <div className={styles.row1}>
                  <span className='bluTypeLabel'>{ translate(configSwitchItem.title) }</span>
                  <Toggle
                    id={`toggle-${index}`}
                    checked={configSwitchItem.active}
                    disabled={getIsDisabled(configSwitchItem)
                      || configSwitchUpdateProcessing}
                    onChange={(checked) => handleToggleChange(configSwitchItem, checked)}
                  />
                </div>
                <div className={styles.row2}>
                  { translate(configSwitchItem.description) }
                </div>
              </div>
            )) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderUserGroup;
