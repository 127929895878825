import React from 'react';

// INTERMISSIONS
import AssessmentIntro from '../intermissions/AssessmentIntro';
import { SurveyEnd } from '../intermissions/SurveyEnd';

// UTILS

// CONFIG

// OTHER COMPONENTS

export const surveyConfig = {
  id: 'survey',
  title: 'Survey',
  allowBackNavigation: true, // 0, 1, 2, 3, ..., Infinity
  abortModalConfirmKey: 'survey_abort_confirm',
  abortModalCancelKey: 'survey_abort_cancel',
  abortModalDescriptionKey: 'survey_abort_description',
  canContinueLater: true,
  welcomeBackKey: 'survey_welcome_back_content',
  loadingPage: {
    // INTRO with disabled next button
    render: () => <AssessmentIntro skeletonOnly />,
  },
  intermissions: [
    // INTRO
    {
      insertAtIndex: 0,
      render: (next, prev, state) => {
        const { title, description } = state;
        return (
          <AssessmentIntro
            assessmentType='survey'
            title={title}
            description={description}
            onClickNext={next}
            nextButtonKey='assessment_start'
          />
        );
      },
    },
    // END
    {
      insertAtIndex: Infinity,
      showProgressBar: true,
      render: (next, prev, state, extras) => (
        <SurveyEnd {...extras} />
      ),
    },
  ],
};
