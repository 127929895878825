import * as actiontype from 'store/actions';
import * as localStorage from 'utils/localStorage';
import * as signupActionTypes from 'store/actionTypes/signUp';

const initialState = {
  authToken: null,
  status: 'invalid',
  processing: false,
  mfaRequired: false,
  error: null,
  extendedSession: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actiontype.LOGIN:
      return {
        ...state,
        processing: true,
      };
    case actiontype.LOGOUT_FULFILLED:
      localStorage.logout();
      return {
        ...state,
        status: 'invalid',
        processing: false,
      };
    case actiontype.GET_CURRENT_USER_REJECTED:
    case actiontype.LOGIN_REJECTED: {
      localStorage.logout();
      const {
        error = state.error,
        mfaRequired = state.mfaRequired,
      } = action.payload;

      return {
        ...state,
        status: 'invalid',
        processing: false,
        error,
        mfaRequired,
      };
    }
    case actiontype.LOGIN_FULFILLED:
    case signupActionTypes.SIGN_UP_FULFILLED: {
      const { token } = action.payload;

      localStorage.setItem('token', token);

      return {
        ...state,
        authToken: token || state.authToken,
        mfaRequired: false,
        status: 'valid',
        error: null,
        processing: false,
      };
    }
    case actiontype.AUTH_TOKEN_FETCHED: {
      return {
        ...state,
        ...action.payload,
        error: null,
        processing: false,
      };
    }
    case actiontype.SET_EXTENDED_SESSION_DURATION: {
      const { extendedSession } = action.payload;

      return {
        ...state,
        extendedSession,
      };
    }
    default:
      return state;
  }
};
