// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Success.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';
import { useHistory } from 'react-router-dom';

// UTILS
import { useTranslate } from 'utils/translator';
import { apiNext } from 'apiNext';


// STORE NEXT
import { useDispatch } from 'react-redux';
import {
  configurationExtendedReceived,
  mapSubscriptionData,
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: Success
const Success = () => {
  // PROPS

  // SPECIAL HOOKS
  const history = useHistory();
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ processing, setProcessing ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const handleComplete = () => {
    setProcessing(true);

    apiNext.get('/core/subscription?expand=plan')
    .then((response) => {
      const {
        planType, required,
        subscriptionCanceled, subscriptionExpiration,
        billingPeriod,
      } = mapSubscriptionData(response);

      dispatch(configurationExtendedReceived({
        planType,
        trialExpiration: 0,
        subscriptionCanceled,
        subscriptionExpiration,
        required,
        billingPeriod,
      }));

      history.push('/');
    })
    .catch((error) => {
      console.error(error.message);
      setErrorMessage(error.message);
      setProcessing(false);
    });
  };

  // RENDER: Success
  return (
    <div className={classNames(styles.success)}>
      <div className='bluContent664'>
        <div className='bluTypeS'>
          { translate('upgrade_to_pp_success_title') }
        </div>

        { errorMessage && (
          <div className={classNames('error', 'marginTopXs')}>{ errorMessage }</div>
        ) }

        <div className={classNames('bluTypeCopy', 'marginTopS')}>
          { translate('upgrade_to_pp_success_copy') }
        </div>

        <Button
          size='S'
          disabled={processing}
          onClick={handleComplete}
        >
          { translate('complete_lbl') }
        </Button>
      </div>
    </div>
  );
};

export default Success;
