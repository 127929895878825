// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuestionFreeText.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNext, ResizableTextArea } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useDebounce } from 'utils/hooks';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionFreeText
const QuestionFreeText = (props) => {
  // PROPS
  const {
    question,
    value = '',
    error = '',
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const {
    questionText, description, multiline, minAnswerLength, maxAnswerLength,
  } = question;

  const [ valueInternal, setValueInternal ] = useState(value);
  const debouncedValue = useDebounce(valueInternal, 300);

  const [ errorMessage, setErrorMessage ] = useState('');
  const validateAnswer = useCallback(() => {
    let errorMessageInternal = '';

    if (minAnswerLength && debouncedValue.trim().length < minAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_min_length_error',
        [ '{{minLength}}', minAnswerLength ],
      );
    } else if (maxAnswerLength && debouncedValue.trim().length > maxAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_max_length_error',
        [ '{{maxLength}}', maxAnswerLength ],
      );
    }

    return errorMessageInternal;
  }, [ translate, minAnswerLength, maxAnswerLength, debouncedValue ]);

  useEffect(() => {
    if (value === debouncedValue) {
      return;
    }

    const errorMessageInternal = validateAnswer();
    setErrorMessage(errorMessageInternal);

    onAnswer({
      question: question.id,
      content: debouncedValue,
    }, Boolean(errorMessageInternal));
  }, [
    debouncedValue,
    value, question,
    validateAnswer,
    onAnswer,
  ]);


  // RENDER: QuestionFreeText
  return (
    <div className={classNames(styles.questionFreeText)}>
      <span className='bluTypeLabelL'>{ questionText }</span>

      { error && (
        <div className={classNames('error', 'marginTopXs')}>{ error }</div>
      ) }

      <div className='marginTopXs'>
        { !multiline && (
          <InputNext
            label={translate('your_answer_lbl')}
            value={valueInternal}
            clearvalueInternalOnConfirm={false}
            onChange={(valueInternalParam) => setValueInternal(valueInternalParam)}
          />
        ) }

        { multiline && (
          <ResizableTextArea
            label={translate('your_answer_lbl')}
            value={valueInternal}
            minRows={2}
            maxRows={5}
            onChange={(valueInternalParam) => setValueInternal(valueInternalParam)}
          />
        ) }
      </div>

      { (maxAnswerLength || errorMessage) && (
        <div className={styles.bottomText}>
          { errorMessage && (
            <span className={styles.error}>{ errorMessage }</span>
          ) }

          { maxAnswerLength && (
            <div className={styles.hint}>
              { `${valueInternal.length} / ${maxAnswerLength}` }
            </div>
          ) }
        </div>
      ) }

      { description && (
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate(description) }
        </div>
      ) }

    </div>
  );
};

export default QuestionFreeText;
