import * as fromActionTypes from 'store/actionTypes/bqActions';

// GET ACTION
export const getAction = (actionId) => ({
  type: fromActionTypes.GET_ACTION,
  payload: { actionId },
});

export const getActionFulfilled = ({ actionInfo }) => ({
  type: fromActionTypes.GET_ACTION_FULFILLED,
  payload: { actionInfo },
});

export const getActionRejected = ({ error }) => ({
  type: fromActionTypes.GET_ACTION_REJECTED,
  payload: { error },
});

// EXECUTE ACTION
export const executeAction = (executeActionDto) => ({
  type: fromActionTypes.EXECUTE_ACTION,
  payload: { executeActionDto },
});

export const executeActionFulfilled = ({ executedActionInfo }) => ({
  type: fromActionTypes.EXECUTE_ACTION_FULFILLED,
  payload: { executedActionInfo },
});

export const executeActionRejected = ({ error }) => ({
  type: fromActionTypes.EXECUTE_ACTION_REJECTED,
  payload: { error },
});
