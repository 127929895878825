import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './DragAndDrop.module.scss';

import { ReactComponent as Add } from 'assets/icons/icn_add.svg';
import { ReactComponent as Minus } from 'assets/icons/icn_minus.svg';
import { ReactComponent as Anchor } from 'assets/icons/icn_arrow_anchor.svg';

// 3RD PARTY
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useTranslate } from 'utils/translator';

const DROPPABLE1 = 'droppable1';
const DROPPABLE2 = 'droppable2';

const SELECTED_ITEMS_MAX_COUNT = 3;


const DragAndDrop = (props) => {
  const { items, onDrop } = props;

  const translate = useTranslate();

  const [ localItems, setLocalItems ] = useState(items || []);
  const [ selectedItems, setSelectedItems ] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [ removed ] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getItemStyle = (droppableId, isDragging, draggableStyle) => ({
    boxShadow: isDragging ? '0px 2px 20px rgba(0, 0, 0, 0.2)' : 'none',
    ...draggableStyle,
  });

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newItems = reorder(
        source.droppableId === DROPPABLE1 ? localItems : selectedItems,
        source.index,
        destination.index,
      );

      if (source.droppableId === DROPPABLE1) {
        setLocalItems(newItems);
      } else {
        setSelectedItems(newItems);
      }

      if (onDrop) {
        onDrop(newItems);
      }
    } else {
      const newResult = move(
        source.droppableId === DROPPABLE1 ? localItems : selectedItems,
        source.droppableId === DROPPABLE1 ? selectedItems : localItems,
        source,
        destination,
      );

      setLocalItems(newResult.droppable1);
      setSelectedItems(newResult.droppable2);

      if (onDrop) {
        onDrop(newResult.droppable2);
      }
    }
  };

  // RENDER: DRAGGABLE COMPONENT
  const renderDraggableComp = (isDragging, draggableComp) => {
    if (isDragging) {
      // portal is used here to fix an offset issue on dragging
      return createPortal(draggableComp, document.body);
    }
    return draggableComp;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={styles.dragAndDrop}>

        { /* SOURCE ITEMS */ }
        <Droppable droppableId={DROPPABLE1}>
          { (provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.left}
            >
              { localItems.map((localItem, index) => (
                <Draggable
                  key={localItem.id}
                  draggableId={localItem.id}
                  index={index}
                  isDragDisabled={selectedItems.length === SELECTED_ITEMS_MAX_COUNT}
                >
                  { (provided2, snapshot) => {
                    const draggableComp = (
                      <div
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                        className={styles.item}
                        style={getItemStyle(
                          DROPPABLE1,
                          snapshot.isDragging,
                          provided2.draggableProps.style,
                        )}
                      >
                        { localItem.name }
                        <Add onClick={() => {
                          if (selectedItems.length === SELECTED_ITEMS_MAX_COUNT) {
                            return;
                          }

                          const altLocalItems = [ ...localItems ];
                          const altSelectedItems = [ ...selectedItems ];

                          const itemIndex = altLocalItems.indexOf(localItem);

                          // delete item from local items
                          altLocalItems.splice(itemIndex, 1);
                          setLocalItems(altLocalItems);

                          // add item to selected items
                          altSelectedItems.push(localItem);
                          setSelectedItems(altSelectedItems);

                          if (onDrop) {
                            onDrop(altSelectedItems);
                          }
                        }}
                        />
                      </div>
                    );

                    return renderDraggableComp(snapshot.isDragging, draggableComp);
                  } }
                </Draggable>
              )) }
            </div>
          ) }
        </Droppable>

        <Droppable droppableId={DROPPABLE2}>
          { (provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.right}
            >
              <div className={styles.selectedItems}>
                { selectedItems.map((selectedItem, index) => (
                  <Draggable key={selectedItem.id} draggableId={selectedItem.id} index={index}>
                    { (provided2, snapshot) => {
                      const draggableComp = (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          className={styles.item}
                          style={getItemStyle(
                            DROPPABLE2,
                            snapshot.isDragging,
                            provided2.draggableProps.style,
                          )}
                        >
                          { selectedItem.name }
                          <Minus onClick={() => {
                            const altLocalItems = [ ...localItems ];
                            const altSelectedItems = [ ...selectedItems ];

                            const itemIndex = altSelectedItems.indexOf(selectedItem);

                            // delete selected item from selected items
                            altSelectedItems.splice(itemIndex, 1);
                            setSelectedItems(altSelectedItems);

                            // add selected item to local items
                            altLocalItems.push(selectedItem);
                            setLocalItems(altLocalItems);

                            if (onDrop) {
                              onDrop(altSelectedItems);
                            }
                          }}
                          />
                        </div>
                      );

                      return renderDraggableComp(snapshot.isDragging, draggableComp);
                    } }
                  </Draggable>
                )) }
              </div>

              { /* PLACEHOLDER */ }
              { selectedItems.length === 0 && (
                <div className={styles.placeholder}>
                  { translate('9levels_ass_dnd_placeholder') }
                  <div className={styles.label}>
                    <Anchor />
                    drag & drop
                  </div>
                </div>
              ) }
            </div>
          ) }
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default DragAndDrop;
