// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PeerGroupDescription.module.scss';

// 3RD PARTY
import classNames from 'classnames';


// COMPONENT: PeerGroupDescription
const PeerGroupDescription = (props = {}) => {
  // PROPS
  const { content = {}, peerGroups = {} } = props;
  const { peerGroups: peerGroupsIds = [] } = content;

  const renderBlock = (key, label, description) => (
    <div key={key} className={styles.peerGroupDescription}>
      <span className='bluTypeXxs'>{ label }</span>
      <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
        { description || '-' }
      </div>
    </div>
  );

  // RENDER: PeerGroupDescription
  return (
    <>
      { peerGroupsIds.length > 0 && peerGroupsIds
      .filter((peerGroupId) => peerGroups[peerGroupId]?.label)
      .map((peerGroupId) => (
        renderBlock(peerGroupId, peerGroups[peerGroupId]?.label, peerGroups[peerGroupId]?.description)
      )) }

      { /* if no peer groups defined within content -> display the peer groups from assessment config */ }
      { peerGroupsIds.length === 0 && Object.entries(peerGroups)
      .filter(([ , peerGroup ]) => peerGroup?.label)
      .map(([ key, peerGroup ]) => renderBlock(key, peerGroup?.label, peerGroup?.description)) }
    </>
  );
};

export default PeerGroupDescription;
