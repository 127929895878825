import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import styles from './Toast.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/icn_close_small.svg';
import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';

export const VISIBILITY_TIME = 10000; // milliseconds


const Toast = ({
  children,
  headline,
  message,
  visibilityTime = VISIBILITY_TIME,
  onClose = () => {},
}) => {
  const [ isVisible, setIsVisible ] = useState(false);
  const autoCloseTimeout = useRef();
  const transitionRef = useRef();

  const handleClose = useCallback(() => {
    setIsVisible(false);
    // Trigger onClose cb only after animation end
    setTimeout(onClose, Number(styles.animationDurationMs));
  }, [ onClose ]);

  // On dependency array update, (re)set timeout for automatic close
  useEffect(() => {
    clearTimeout(autoCloseTimeout.current);
    autoCloseTimeout.current = setTimeout(handleClose, visibilityTime);
  }, [ handleClose, visibilityTime ]);

  // On mount setup
  useEffect(() => {
    // perform opening animation
    setIsVisible(true);
    // prepare clearTimeout to prevent memory leaks on unmount
    return () => clearTimeout(autoCloseTimeout.current);
  }, []);

  return (
    <BluCSSTransition
      nodeRef={transitionRef}
      in={isVisible}
      classNames={{ ...styles }}
    >
      <div ref={transitionRef} className={styles.toast}>
        <CloseIcon onClick={handleClose} />
        <div className={styles.content}>
          { /* HEADLINE & MESSAGE */ }
          { (headline || message) && (
            <>
              <div className={styles.headline}>
                { headline }
              </div>
              <div className={styles.message}>
                { message }
              </div>
            </>
          ) }

          { /* CHILDREN */ }
          { children }
        </div>
      </div>
    </BluCSSTransition>
  );
};

export default Toast;
