import * as fromActionTypes from 'store/actionTypes/organization';

// GET MINDFULNESS JOURNEY SCORE OF ORGANIZATION
export const getMindJourneyScore = () => ({
  type: fromActionTypes.GET_MIND_JOURNEY_SCORE,
  payload: {},
});

export const getMindJourneyScoreFulfilled = ({ score }) => ({
  type: fromActionTypes.GET_MIND_JOURNEY_SCORE_FULFILLED,
  payload: { score },
});

export const getMindJourneyScoreRejected = ({ error }) => ({
  type: fromActionTypes.GET_MIND_JOURNEY_SCORE_REJECTED,
  payload: { error },
});

// GET ORG STATISTIC
export const getStatistic = () => ({
  type: fromActionTypes.GET_STATISTIC,
  payload: {},
});

export const getStatisticFulfilled = ({ statistic }) => ({
  type: fromActionTypes.GET_STATISTIC_FULFILLED,
  payload: { statistic },
});

export const getStatisticRejected = ({ error }) => ({
  type: fromActionTypes.GET_STATISTIC_REJECTED,
  payload: { error },
});

// GET MINDFULNESS JOURNEY RESULTS
export const getMindJourneyResults = () => ({
  type: fromActionTypes.GET_MIND_JOURNEY_RESULTS,
  payload: {},
});

export const getMindJourneyResultsFulfilled = ({ results }) => ({
  type: fromActionTypes.GET_MIND_JOURNEY_RESULTS_FULFILLED,
  payload: { results },
});

export const getMindJourneyResultsRejected = ({ error }) => ({
  type: fromActionTypes.GET_MIND_JOURNEY_RESULTS_REJECTED,
  payload: { error },
});

// DASHBOARDS
export const getDashboards = () => ({
  type: fromActionTypes.GET_DASHBOARDS,
  payload: {},
});

export const getDashboardsFulfilled = ({ dashboards }) => ({
  type: fromActionTypes.GET_DASHBOARDS_FULFILLED,
  payload: { dashboards },
});

export const getDashboardsRejected = ({ error }) => ({
  type: fromActionTypes.GET_DASHBOARDS_REJECTED,
  payload: { error },
});
