import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import styles from './Checkbox.module.scss';
import classnames from 'classnames';

const Checkbox = memo((props) => {
  const {
    name,
    label,
    checked,
    disabled,
    size = 'S', // S - small, M - medium
    isError,
    onChange,
    hasEllipsis = false,
  } = props;

  const checkmarkLabelRef = useRef();
  const checkmarkRef = useRef();

  const [ isChecked, setIsChecked ] = useState(checked);

  const classNames = classnames(
    styles.checkbox,
    styles[`size${size?.toUpperCase()}`],
    { [styles.error]: isError },
    { [styles.disabled]: disabled },
    { [styles.checked]: isChecked },
  );

  useEffect(() => {
    setIsChecked(checked);
  }, [ checked ]);

  const handleKey = (event) => {
    const { key } = event;

    if (key === ' ') {
      event.stopPropagation();
      if (disabled) return;
      setIsChecked(!isChecked);
      onChange(!isChecked);
    }
  };

  return (
    <label
      tabIndex={0}
      role='button'
      className={classNames}
      onClick={(event) => {
        if (disabled || (event.target !== checkmarkLabelRef.current && event.target !== checkmarkRef.current)) {
          return;
        }
        setIsChecked(!isChecked);
        onChange(!isChecked);
      }}
      onKeyUp={handleKey}
    >
      <div className={styles.labels}>
        { label && (
          <div className={styles.label}>{ label }</div>
        ) }
        <div
          ref={checkmarkLabelRef}
          className={classnames(styles.name, { [styles.ellipsis]: hasEllipsis })}
        >
          { name }
        </div>
      </div>

      <span className={styles.checkmark} ref={checkmarkRef} data-test='Checkbox' />
    </label>
  );
});

export default Checkbox;
