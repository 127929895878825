import * as fromActionTypes from 'store/actionTypes/roles';

// PLATFORM ROLES
export const getPlatformRoles = () => ({
  type: fromActionTypes.GET_PLATFORM_ROLES,
  payload: {},
});

export const getPlatformRolesFulfilled = ({ roles }) => ({
  type: fromActionTypes.GET_PLATFORM_ROLES_FULFILLED,
  payload: { roles },
});

export const getPlatformRolesRejected = ({ error }) => ({
  type: fromActionTypes.GET_PLATFORM_ROLES_REJECTED,
  payload: { error },
});

export const createPlatformRole = (payload) => ({
  type: fromActionTypes.CREATE_PLATFORM_ROLE,
  payload: { payload },
});

export const createPlatformRoleFulfilled = ({ response }) => ({
  type: fromActionTypes.CREATE_PLATFORM_ROLE_FULFILLED,
  payload: { response },
});

export const createPlatformRoleRejected = ({ error }) => ({
  type: fromActionTypes.CREATE_PLATFORM_ROLE_REJECTED,
  payload: { error },
});

export const updatePlatformRole = (updatePlatformDto) => ({
  type: fromActionTypes.UPDATE_PLATFORM_ROLE,
  payload: { updatePlatformDto },
});

export const updatePlatformRoleFulfilled = ({ response }) => ({
  type: fromActionTypes.UPDATE_PLATFORM_ROLE_FULFILLED,
  payload: { response },
});

export const updatePlatformRoleRejected = ({ error }) => ({
  type: fromActionTypes.UPDATE_PLATFORM_ROLE_REJECTED,
  payload: { error },
});

// ORGANISATIONAL ROLES
export const getOrgRoles = (requestDto) => ({
  type: fromActionTypes.GET_ORG_ROLES,
  payload: { requestDto },
});

export const getOrgRolesFulfilled = ({ roles, totalCount }) => ({
  type: fromActionTypes.GET_ORG_ROLES_FULFILLED,
  payload: { roles, totalCount },
});

export const getOrgRolesRejected = ({ error }) => ({
  type: fromActionTypes.GET_ORG_ROLES_REJECTED,
  payload: { error },
});

// GET ORG ROLE BY ID
export const initGetOrgRole = () => ({
  type: fromActionTypes.INIT_GET_ORG_ROLE,
  payload: {},
});

export const getOrgRole = (id) => ({
  type: fromActionTypes.GET_ORG_ROLE,
  payload: { id },
});

export const getOrgRoleFulfilled = ({ role }) => ({
  type: fromActionTypes.GET_ORG_ROLE_FULFILLED,
  payload: { role },
});

export const getOrgRoleRejected = ({ error }) => ({
  type: fromActionTypes.GET_ORG_ROLE_REJECTED,
  payload: { error },
});

// CREATE ORG ROLE
export const initCreateOrgRole = () => ({
  type: fromActionTypes.INIT_CREATE_ORG_ROLE,
  payload: {},
});

export const createOrgRole = (newRoleDto) => ({
  type: fromActionTypes.CREATE_ORG_ROLE,
  payload: { newRoleDto },
});

export const createOrgRoleFulfilled = ({ roleId }) => ({
  type: fromActionTypes.CREATE_ORG_ROLE_FULFILLED,
  payload: { roleId },
});

export const createOrgRoleRejected = ({ error }) => ({
  type: fromActionTypes.CREATE_ORG_ROLE_REJECTED,
  payload: { error },
});

// UPDATE ORG ROLE
export const initUpdateOrgRole = () => ({
  type: fromActionTypes.INIT_UPDATE_ORG_ROLE,
  payload: {},
});

export const updateOrgRole = (updateRoleDto) => ({
  type: fromActionTypes.UPDATE_ORG_ROLE,
  payload: { updateRoleDto },
});

export const updateOrgRoleFulfilled = () => ({
  type: fromActionTypes.UPDATE_ORG_ROLE_FULFILLED,
  payload: {},
});

export const updateOrgRoleRejected = ({ error }) => ({
  type: fromActionTypes.UPDATE_ORG_ROLE_REJECTED,
  payload: { error },
});

// DELETE ORG ROLE
export const initDeleteOrgRole = () => ({
  type: fromActionTypes.INIT_DELETE_ORG_ROLE,
  payload: {},
});

export const deleteOrgRole = (roleId, roleName) => ({
  type: fromActionTypes.DELETE_ORG_ROLE,
  payload: { roleId, roleName },
});

export const deleteOrgRoleFulfilled = ({ deletedRoleId, deletedRoleName }) => ({
  type: fromActionTypes.DELETE_ORG_ROLE_FULFILLED,
  payload: { deletedRoleId, deletedRoleName },
});

export const deleteOrgRoleRejected = ({ error }) => ({
  type: fromActionTypes.DELETE_ORG_ROLE_REJECTED,
  payload: { error },
});

// GET REFERENCES PROFILES
export const getReferencesProfiles = ({ ids }) => ({
  type: fromActionTypes.GET_REFERENCES_PROFILES,
  payload: { ids },
});

export const getReferencesProfilesFulfilled = ({ referencesProfiles }) => ({
  type: fromActionTypes.GET_REFERENCES_PROFILES_FULFILLED,
  payload: { referencesProfiles },
});

export const getReferencesProfilesRejected = ({ error }) => ({
  type: fromActionTypes.GET_REFERENCES_PROFILES_REJECTED,
  payload: { error },
});

// GET EXPANDED REFERENCES
export const getExpandedReferences = ({ ids }) => ({
  type: fromActionTypes.GET_EXPANDED_REFERENCES,
  payload: { ids },
});

export const getExpandedReferencesFulfilled = ({ expandedReferences }) => ({
  type: fromActionTypes.GET_EXPANDED_REFERENCES_FULFILLED,
  payload: { expandedReferences },
});

export const getExpandedReferencesRejected = ({ error }) => ({
  type: fromActionTypes.GET_EXPANDED_REFERENCES_REJECTED,
  payload: { error },
});

// GET ROLE TEMPLATE
export const getRoleTemplate = (id) => ({
  type: fromActionTypes.GET_ROLE_TEMPLATE,
  payload: { id },
});

export const initRoleTemplate = () => ({
  type: fromActionTypes.INIT_ROLE_TEMPLATE,
  payload: { },
});


export const getRoleTemplateFulfilled = ({ template }) => ({
  type: fromActionTypes.GET_ROLE_TEMPLATE_FULFILLED,
  payload: { template },
});

export const getRoleTemplateRejected = ({ error }) => ({
  type: fromActionTypes.GET_ROLE_TEMPLATE_REJECTED,
  payload: { error },
});
