import { CURRENT_LOCATION } from 'utils/configuration';

/**
 * Given an instance id, create the base url for the current environment.
 *
 * @param {String} [instance] The name of the instance
 * @returns {String} The base url for this instance.
 */
export const getInstancePageUrl = (instance = '') => {
  const { protocol, domain, port } = CURRENT_LOCATION;

  return `${protocol}://${instance}${instance && '.'}${domain}${port && ':'}${port}`;
};

/**
 * Given an instance id, create the url for login page.
 *
 * @param {String} instance The name of the instance
 * @param {Boolean} [extendedSession] Whether the session will be extended to 1 week
 * @param {String} [currentLanguage] The language that will be used on the login page
 * @returns {String} The url to log into the instance, with the login parameters as QS.
 */
export const getInstanceLoginPageUrl = (instance, extendedSession, currentLanguage) => {
  const params = new URLSearchParams({
    ext: Number(Boolean(extendedSession)),
    lang: currentLanguage,
  }).toString();

  return `${getInstancePageUrl(instance)}/login?${params}`;
};
