// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './InvitationLinks.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: InvitationLinks
const InvitationLinks = (props) => {
  // PROPS
  const {
    userEmail = '',
    links = [],
  } = props;

  // SPECIAL HOOKS
  const history = useHistory();
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const { Copy } = IconsSvg;

  // RENDER: InvitationLinks
  return (
    <div className={classNames(styles.invitationLinks)}>
      <div className='bluTypeS'>
        { translate('invite_links_generated_title') }
      </div>
      <div className={classNames('bluTypeCopy', 'marginTopXs')}>
        { translate('invite_links_generated_copy', [ '{{email}}', userEmail ]) }
      </div>

      { links.length && (
        <div className='marginTopXs'>
          { links.map(({ email, link }, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.row}>
                <div className='bluTypeLabelL'>{ email }</div>
                <Copy onClick={() => navigator.clipboard.writeText(email)} />
              </div>
              <div className={styles.row}>
                <div className='bluTypeCopy'>{ link }</div>
                <Copy onClick={() => navigator.clipboard.writeText(link)} />
              </div>
            </div>
          )) }
        </div>
      ) }

      <Button
        size='S'
        onClick={() => history.goBack()}
      >
        { translate('close_lbl') }
      </Button>
    </div>
  );
};

export default InvitationLinks;
