// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './QuestionAge.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { InputNumber } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
const Config = {
  oldest: 120,
  youngest: 14,
};

// COMPONENT: QuestionAge
const QuestionAge = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleAge = (age, ageIsValid) => {
    const ageNumber = Number(age);
    const year = new Date().getFullYear();
    const isValid = typeof (ageNumber) === 'number'
      && ageNumber > year - Config.oldest
      && ageNumber < year - Config.youngest;

    onAnswer({
      value: age,
      name: 'age',
      isValid: isValid && ageIsValid,
    });
  };

  // RENDER: QuestionAge
  return (
    <div className={classNames(styles.questionAge)}>

      { /* FORM */ }
      <div className={styles.formRow}>
        <InputNumber
          size='responsive'
          value={answer.value}
          label={translate('cp_age_placeholder')}
          onChange={handleAge}
        />
      </div>

    </div>
  );
};

export default QuestionAge;
