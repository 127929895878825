// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './StrengthsAndDevelopmentAreas.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { GapAnalysis } from 'ui/molecules/CustomReport/Blocks/GapAnalysis';


// COMPONENT: StrengthsAndDevelopmentAreas
const StrengthsAndDevelopmentAreas = (props) => {
  // SPECIAL HOOKS
  const translate = useTranslate();


  // RENDER: StrengthsAndDevelopmentAreas
  return (
    <div className={classNames(styles.strengthsAndDevelopmentAreas)}>
      <span className={classNames('bluTypeS', styles.blue)}>{ translate('peer_360_report_strengths') }</span>
      <div className={classNames('bluTypeCopy', 'marginTopM')}>
        { translate('peer_360_report_strengths_copy') }
      </div>
      <GapAnalysis {...props} />
    </div>
  );
};

export default StrengthsAndDevelopmentAreas;
