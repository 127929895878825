export const SET_ASSESSMENT_REPORT_NOTES = 'SET_ASSESSMENT_REPORT_NOTES';
export const SET_ASSESSMENT_REPORT_NOTES_PENDING = 'SET_ASSESSMENT_REPORT_NOTES_PENDING';
export const SET_ASSESSMENT_REPORT_NOTES_FULFILLED = 'SET_ASSESSMENT_REPORT_NOTES_FULFILLED';
export const SET_ASSESSMENT_REPORT_NOTES_REJECTED = 'SET_ASSESSMENT_REPORT_NOTES_REJECTED';
export const GET_ASSESSMENT_REPORT_NOTES = 'GET_ASSESSMENT_REPORT_NOTES';
export const GET_ASSESSMENT_REPORT_NOTES_PENDING = 'GET_ASSESSMENT_REPORT_NOTES_PENDING';
export const GET_ASSESSMENT_REPORT_NOTES_FULFILLED = 'GET_ASSESSMENT_REPORT_NOTES_FULFILLED';
export const GET_ASSESSMENT_REPORT_NOTES_REJECTED = 'GET_ASSESSMENT_REPORT_NOTES_REJECTED';


export const setAssessmentReportNotes = (payload) => ({
  type: SET_ASSESSMENT_REPORT_NOTES,
  payload,
});

export const setAssessmentReportNotesFulfilled = (payload) => ({
  type: SET_ASSESSMENT_REPORT_NOTES_FULFILLED,
  payload,
});

export const setAssessmentReportNotesRejected = (payload) => ({
  type: SET_ASSESSMENT_REPORT_NOTES_REJECTED,
  payload,
});

export const getAssessmentReportNotes = (payload) => ({
  type: GET_ASSESSMENT_REPORT_NOTES,
  payload,
});

export const getAssessmentReportNotesFulfilled = (payload) => ({
  type: GET_ASSESSMENT_REPORT_NOTES_FULFILLED,
  payload,
});

export const getAssessmentReportNotesRejected = (payload) => ({
  type: GET_ASSESSMENT_REPORT_NOTES_REJECTED,
  payload,
});
