export const TOGGLE_TYPE = '[FILTER LIST ENTITIES] TOGGLE_TYPE';
export const toggleType = ({ entity }) => ({
  type: TOGGLE_TYPE,
  payload: { entity },
});

export const RESET = '[FILTER LIST ENTITIES] RESET';
export const reset = ({ entity }) => ({
  type: RESET,
  payload: { entity },
});

export const GET_LIST_ITEMS = '[FILTER LIST ENTITIES] GET_LIST_ITEMS';
export const getListItems = ({ entity, dynamicConfig, showLastDataWhileLoading }) => ({
  type: GET_LIST_ITEMS,
  payload: { entity, dynamicConfig, showLastDataWhileLoading },
});

export const GET_LIST_ITEMS_SUCCESS = '[FILTER LIST ENTITIES] GET_LIST_ITEMS_SUCCESS';
export const getListItemsSuccess = ({
  entity, listItems, numberOfEntries, noDataAtAll, noDataDuringCalculation,
}) => ({
  type: GET_LIST_ITEMS_SUCCESS,
  payload: {
    entity, listItems, numberOfEntries, noDataAtAll, noDataDuringCalculation,
  },
});

export const GET_LIST_ITEMS_FAIL = '[FILTER LIST ENTITIES] GET_LIST_ITEMS_FAIL';
export const getListItemsFail = ({ entity, error }) => ({
  type: GET_LIST_ITEMS_FAIL,
  payload: { entity, error },
});

export const SET_SEARCH_STRING = '[FILTER LIST ENTITIES] SET_SEARCH_STRING';
export const setSearchString = ({ entity, searchString }) => ({
  type: SET_SEARCH_STRING,
  payload: { entity, searchString },
});

export const SET_SORT = '[FILTER LIST ENTITIES] SET_SORT';
export const setSort = ({
  entity, sortBy, sortDirection, extraSearchParams,
}) => ({
  type: SET_SORT,
  payload: {
    entity, sortBy, sortDirection, extraSearchParams,
  },
});

export const SET_PAGE_INDEX = '[FILTER LIST ENTITIES] SET_PAGE_INDEX';
export const setPageIndex = ({ entity, pageIndex }) => ({
  type: SET_PAGE_INDEX,
  payload: { entity, pageIndex },
});

export const SET_FILTER = '[FILTER LIST ENTITIES] SET_FILTER';
export const setFilter = ({ entity, filter }) => {
  return {
    type: SET_FILTER,
    payload: { entity, filter }
  };
};
