/* eslint-disable  no-restricted-syntax, no-await-in-loop */
// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './ExportAssessmentResult.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { toBlob } from 'html-to-image';

// OTHER COMPONENTS
import { Button, LoadingHint, OverlayModal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { downloadFile } from 'utils/file';


// COMPONENT: ExportAssessmentResult
const ExportAssessmentResult = (props) => {
  // PROPS
  const {
    diagramType = '',
    diagramRenderProcessing = false,
    user = {},
    assessmentsIds = [],
    children,
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ diagramExportProcessing, setDiagramExportProcessing ] = useState(false);

  const exportImages = async (ids = []) => {
    setDiagramExportProcessing(true);
    try {
      // Use regular for loop instead of forEach to download images sequentially;
      // avoid parallel downloads as some images may get lost (Safari bug).
      for (const id of ids) {
        const node = document.getElementById(`export-${id}`);
        if (node) {
          // Extract the image twice (another Safari bug: https://github.com/tsayen/dom-to-image/issues/343).
          let blob = await toBlob(node);
          blob = await toBlob(node);
          downloadFile(blob, `${getFullName(user)}_${id}`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDiagramExportProcessing(false);
      onClose();
    }
  };


  // RENDER: export view footer
  const renderExportViewFooter = () => (
    <div className={styles.exportViewFooter}>
      <Button
        size='M'
        disabled={diagramRenderProcessing || diagramExportProcessing}
        onClick={() => {
          const exportedIds = diagramType === 'all' ? assessmentsIds : [ diagramType ];
          exportImages(exportedIds);
        }}
      >
        { translate('download') }
      </Button>
    </div>
  );

  // RENDER: ExportAssessmentResult
  return (
    <div className={classNames(styles.exportAssessmentResult)}>
      <OverlayModal
        header='Export'
        footer={renderExportViewFooter()}
        onClose={onClose}
      >
        <div className={styles.exportView}>
          <div className={styles.loading}>
            { diagramRenderProcessing
              ? <LoadingHint>{ translate('export_loading') }</LoadingHint>
              : <span className='bluTypeHint'>{ translate('export_success') }</span> }
          </div>

          { children }
        </div>
      </OverlayModal>
    </div>
  );
};

export default ExportAssessmentResult;
