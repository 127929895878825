// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionCareerLevel.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentRadioButtonGroup, Button, Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import { useSelector } from 'react-redux';
import {
  selectCareerLevels,
} from 'features/framework/storeNext/staticValuesSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionCareerLevel
const QuestionCareerLevel = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
    setRenderOutside = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const careerLevelOptions = useSelector(selectCareerLevels);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const handleCareerLevel = (careerLevelParam) => {
    onAnswer({
      value: careerLevelParam,
      name: 'careerLevel',
      isValid: !!careerLevelParam,
    });
  };

  const helpModal = (
    <Modal
      header={translate('assessment_help_info_title')}
      secondaryButtonTitle={translate('close_lbl')}
      onClose={() => setRenderOutside()}
    >
      { translate('cp_careerlevel_help_modal_content') }
    </Modal>
  );

  // RENDER: QuestionCareerLevel
  return (
    <div className={classNames(styles.questionCareerLevel)}>

      { /* FORM */ }
      <div className={classNames(styles.formRow, styles.radioButtonGroupContainer)}>
        <div className={styles.assessmentRadioButtonGroup}>
          <AssessmentRadioButtonGroup
            name='radio-button-group'
            selectedValue={answer.value}
            items={careerLevelOptions}
            deselectable
            onChange={handleCareerLevel}
          />
        </div>
      </div>

      <div className={classNames(styles.formRow, styles.center)}>
        <Button
          looks='tertiary'
          onClick={() => setRenderOutside(helpModal)}
        >
          { translate('assessment_help_button') }
        </Button>
      </div>

    </div>
  );
};

export default QuestionCareerLevel;
