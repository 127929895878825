import React, { memo } from 'react';
import styles from './SelectedIndicator.module.scss';

const SelectedIndicator = memo((props) => {
  const { position } = props;
  return (
    <div
      className={styles.selectedIndicator}
      style={{ top: `${position}px` }}
    />
  );
});
export default SelectedIndicator;
