// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useCallback } from 'react';
import styles from './ModalCreditCard.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { Button, Modal } from 'ui/basic';
import { StripeWidget } from 'features/+adminUG/components/StripeWidget';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: ModalCreditCard
const ModalCreditCard = (props) => {
  // PROPS
  const {
    onCancel = () => {},
    onFinish = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  const [ showModalCreditCard, setShowModalCreditCard ] = useState(true);
  const [ showModalSuccess, setShowModalSuccess ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // CONTROLS
  const Controls = useCallback(({ submitButtonDisabled }) => (
    <div className={styles.controls}>
      <Button
        size='S'
        looks='secondary'
        onClick={onCancel}
      >
        { translate('cancel_lbl') }
      </Button>
      <Button
        size='S'
        type='submit'
        disabled={submitButtonDisabled}
      >
        { translate('save') }
      </Button>
    </div>
  ), [ onCancel, translate ]);

  // RENDER: ModalCreditCard
  return (
    <div className={classNames(styles.modalCreditCard)}>
      { showModalCreditCard && (
        <Modal
          header={translate('payment_method_change_modal_title')}
          controlsVisible={false}
          closeOnConfirm={false}
          onClose={onCancel}
        >
          { translate('payment_method_change_modal_copy') }

          <StripeWidget
            controls={Controls}
            onSubmit={() => {
              setShowModalCreditCard(false);
              setShowModalSuccess(true);
            }}
          />

        </Modal>
      ) }

      { showModalSuccess && (
        <Modal
          header={translate('payment_method_changed_success_title')}
          secondaryButtonTitle={translate('close_lbl')}
          onClose={onFinish}
        >
          { translate('payment_method_changed_success_copy') }
        </Modal>
      ) }
    </div>
  );
};

export default ModalCreditCard;
