// REACT, STYLE, STORIES & COMPONENT
import React, { forwardRef } from 'react';
import styles from './Chip.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import ImgCircle from 'ui/basic/micro-ui/ImgCircle';


// UTILS

// CONFIG & DATA
const Close = IconsSvg.CloseBig;

// COMPONENT: Chip
const Chip = (props, ref) => {
  // PROPS
  const {
    children, onClick, img = '', showImage, label, look,
  } = props;

  // COMPONENT/UI STATE and REFS
  const hasCircle = showImage && (img || label);
  const labels = label && label.split(' ');

  // STORE HOOKS

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: Chip
  return (
    <div
      ref={ref}
      role='presentation'
      className={classNames(styles.chip, {
        [styles[look]]: look,
        [styles.hasCircle]: hasCircle,
      })}
      onClick={onClick}
    >
      { hasCircle && (
        <div className={styles.circleContainer}>
          <ImgCircle
            size='Ss'
            className='darker noBorder'
            label1={labels[0]}
            label2={labels[1]}
            src={img}
          />
        </div>
      ) }
      { children || label }
      <Close />
    </div>
  );
};

export default forwardRef(Chip);
