// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect } from 'react';
// import styles from './QuestionProfileCompleted.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { setUserProfileCompleted } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionProfileCompleted
const QuestionProfileCompleted = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();
  const dispatch = useDispatch();

  // MARK PROFILE COMPLETED
  useEffect(() => {
    dispatch(setUserProfileCompleted());
  }, [ dispatch ]);


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionProfileCompleted
  return (
    <></>
  );
};

export default QuestionProfileCompleted;
