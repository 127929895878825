import React, { useRef, useState } from 'react';
import styles from './RolesPopover.module.scss';

import classNames from 'classnames';

import { useTranslate } from 'utils/translator';
import { handleRipple } from 'utils/ripple';

import BluCSSTransition from 'ui/basic/containers/BluCSSTransition';


const RolesPopover = (props) => {
  const { roles, roleClickable, onClick } = props;

  const translate = useTranslate();

  const [ menuOpen, setMenuOpen ] = useState(false);
  const transitionRef = useRef();

  return (
    <div
      tabIndex='0'
      className={styles.popover}
      onBlur={() => setMenuOpen(false)}
    >
      <div
        className={styles.label}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        { `${roles[0].name} + ${roles.length - 1} ${translate('profile_more')}` }
      </div>

      <BluCSSTransition
        nodeRef={transitionRef}
        in={menuOpen}
        classNames={{ ...styles }}
      >
        <div ref={transitionRef} className={styles.menu}>
          { roles.map((role) => {
            const menuItemProps = {
              className: classNames(styles.menuItem, { [styles.active]: roleClickable }),
              onClick: (event) => {
                if (!roleClickable) {
                  return;
                }

                handleRipple(event, styles.colorPrimary3);
                window.setTimeout(() => {
                  if (onClick) {
                    onClick(role.id);
                  }
                }, styles.animationDurationLongMs);
              },
            };

            return (
              <div key={role.id} {...menuItemProps}>
                <span>{ role.name }</span>
              </div>
            );
          }) }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default RolesPopover;
