// REACT, STYLE, STORIES & COMPONENT
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import styles from './TextEditor.module.scss';

// ASSETS
import { ReactComponent as Bold } from './assets/bold.svg';
import { ReactComponent as Italic } from './assets/italic.svg';
import { ReactComponent as Underline } from './assets/underline.svg';
import { ReactComponent as ListOrdered } from './assets/list_ordered.svg';
import { ReactComponent as ListUnordered } from './assets/list_unordered.svg';

// 3RD PARTY
import classNames from 'classnames';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  getDefaultKeyBinding,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';

import './css/draft.css';

// OTHER COMPONENTS
import Button from 'ui/basic/forms/Button';

// UTILS
import { useTranslate } from 'utils/translator';
import { isNatural } from 'utils/numbers';


// SUBCOMPONENTS: Control Buttons
const StyleButton = ({
  onToggle, active, label, style,
}) => (
  <span
    role='presentation'
    className={classNames(styles.styleButton, {
      [styles.active]: active,
    })}
    onMouseDown={(e) => {
      e.preventDefault();
      onToggle(style);
    }}
  >
    { label }
  </span>
);

const BlockStyleControls = ({ editorState, onToggle }) => {
  const blockTypes = [
    { label: <ListUnordered />, style: 'unordered-list-item' },
    { label: <ListOrdered />, style: 'ordered-list-item' },
  ];

  const selection = editorState.getSelection();
  const blockType = editorState
  .getCurrentContent()
  .getBlockForKey(selection.getStartKey())
  .getType();

  return (
    <div className={styles.controls}>
      { blockTypes.map((type) => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )) }
    </div>
  );
};

const InlineStyleControls = ({ editorState, onToggle }) => {
  const inlineStyles = [
    { label: <Bold />, style: 'BOLD' },
    { label: <Italic />, style: 'ITALIC' },
    { label: <Underline />, style: 'UNDERLINE' },
  ];

  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={styles.controls}>
      { inlineStyles.map((type) => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )) }
    </div>
  );
};


// COMPONENT: TextEditor
const TextEditor = (props) => {
  // PROPS
  const {
    placeholder,
    content,
    disabled,
    maxLength,
    forceFocus = false,
    onChange,
    onSave = () => {},
    onCancel = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ editorState, setEditorState ] = useState(EditorState.createEmpty());
  const [ focussed, setFocussed ] = useState(false);
  const [ empty, setEmpty ] = useState(!content);
  const [ hidePlaceholder, setHidePlaceholder ] = useState(!content);
  const editor = useRef(null);

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS
  useEffect(() => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const firstBlock = rawContent.blocks && rawContent.blocks[0];
    if (firstBlock && firstBlock.text) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    // HIDE PLACEHOLDER when changed to block type before text is entered
    let newHidePlaceholder = false;
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        newHidePlaceholder = true;
      }
    }
    setHidePlaceholder(newHidePlaceholder);
  }, [ editorState ]);

  const initializeContent = useCallback(() => {
    let newEditorState;
    if (content) {
      // if content includes already formatted text
      if (content.includes('{"blocks')) {
        const contentParsed = JSON.parse(content);
        newEditorState = EditorState.createWithContent(convertFromRaw(contentParsed));
      } else {
        // if content contains either plain or markdown text
        newEditorState = EditorState.createWithContent(convertFromRaw(markdownToDraft(
          content
          .replace(/\[blu-markdown\](\n)?/g, '')
          // avoid having more than 2 newline characters (\n) to avoid having extra lines with 'preserveNewLines' config
          .replace(/\n{3,}/g, '\n\n'),
          {
            preserveNewlines: true,
          },
        )));
      }
    } else {
      newEditorState = EditorState.createEmpty();
    }
    setEditorState(newEditorState);
  }, [ content ]);

  useEffect(() => {
    initializeContent(content);
  }, [ content, initializeContent ]);

  // METHODS
  const focus = useCallback(() => {
    if (disabled) {
      return;
    }
    if (editor.current) {
      editor.current.focus();
    }
    setFocussed(true);
  }, [ disabled ]);

  useEffect(() => {
    if (forceFocus) {
      focus();
    }
  }, [ focus, forceFocus ]);

  const cancel = () => {
    // defocus and reset state
    setFocussed(false);
    initializeContent(content);
    onCancel();
  };

  const save = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const rawContentJSON = JSON.stringify(rawContent);
    setFocussed(false);
    onSave(rawContentJSON, editorState.getCurrentContent().getPlainText());
  };

  // EVENT HANDLES
  const handleKeyCommand = useCallback((command, state) => {
    // prevent newline when maxLength is reached
    if (command === 'split-block' && state.getCurrentContent().getPlainText().length >= maxLength) {
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }, [ setEditorState, maxLength ]);

  const handleBeforeInput = useCallback((text, state) => {
    if (!isNatural(maxLength)) {
      return 'not-handled';
    }
    const totalLength = state.getCurrentContent().getPlainText().length + text.length;
    return totalLength > maxLength ? 'handled' : 'not-handled';
  }, [ maxLength ]);

  const handlePastedText = useCallback((text, _, state) => {
    if (!isNatural(maxLength)) {
      return 'not-handled';
    }
    const overflowChars = text.length + state.getCurrentContent().getPlainText().length - maxLength;
    if (overflowChars > 0) {
      if (text.length - overflowChars > 0) {
        const newContent = Modifier.insertText(
          state.getCurrentContent(),
          state.getSelection(),
          text.substring(0, text.length - overflowChars),
        );
        setEditorState(EditorState.push(state, newContent, 'insert-characters'));
      }
      return 'handled';
    }
    return 'not-handled';
  }, [ setEditorState, maxLength ]);

  const handleChange = (state) => {
    setEditorState(state);

    if (onChange) {
      const rawContent = convertToRaw(state.getCurrentContent());
      const rawContentJSON = JSON.stringify(rawContent);
      onChange(rawContentJSON);
    }
  };

  const mapKeyToEditorCommand = useCallback((e) => {
    switch (e.keyCode) {
      case 9: { // TAB
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          2, // maxDepth, starting from 0
        );
        if (newEditorState !== editorState) {
          setEditorState(newEditorState);
        }
        return null;
      }
      default:
        return getDefaultKeyBinding(e);
    }
  }, [ editorState, setEditorState ]);


  // RENDER: TextEditor
  return (
    <div className={classNames(styles.textEditor, {
      [styles.empty]: empty && !focussed,
      [styles.disabled]: disabled,
      [styles.focussed]: focussed,
      [styles.hidePlaceholder]: hidePlaceholder,
    })}
    >
      { /* BOX */ }
      <div
        className={styles.box}
        role='presentation'
        onClick={focus}
      >
        { /* FORMATTING BAR */ }
        <div className={styles.formattingBar}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={(inlineStyle) => {
              const newState = RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle,
              );
              setEditorState(newState);
            }}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={(blockType) => {
              const newState = RichUtils.toggleBlockType(editorState, blockType);
              setEditorState(newState);
            }}
          />
        </div>

        { /* EDITOR CONTAINER */ }
        <div className={classNames(styles.editorContainer, {
          [styles.hidePlaceholder]: hidePlaceholder,
        })}
        >
          <Editor
            ref={editor}
            readOnly={disabled}
            placeholder={placeholder}
            editorState={editorState}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand} // allow nested lists
            onChange={handleChange}
          />
        </div>
      </div>

      { /* CHAR COUNTER */ }
      { isNatural(maxLength) && focussed && (
        <div className={styles.charCounter}>
          { `${editorState.getCurrentContent().getPlainText().length} / ${maxLength}` }
        </div>
      ) }

      { /* BUTTONS */ }
      { (onSave && !onChange) && (
        <div className={styles.buttons}>
          <Button
            size='S'
            looks='tertiary'
            onClick={cancel}
          >
            { translate('edp_button_cancel') }
          </Button>
          <Button
            size='S'
            looks='primary'
            onClick={save}
          >
            { translate('gen_settings_change_password_btn') }
          </Button>
        </div>
      ) }
    </div>
  );
};

export default TextEditor;
