import { get } from 'api';
import * as action from 'store/actions';
import { call, put, takeEvery } from 'redux-saga/effects';


export function* getAssessment({ payload }) {
  const { id, user } = payload;

  try {
    const { status, ok, data } = yield call(get, `/core/assessments/${id}`, user ? { user } : undefined);

    if (ok && status === 200) {
      yield put(action.getAssessmentFulfilled({ id, data }));
    } else {
      yield put(action.getAssessmentRejected({ id, error: data.error }));
    }
  } catch (error) {
    yield put(action.getAssessmentRejected({
      id,
      error: {
        errorMessage: error?.message,
      },
    }));
  }
}

export default function* watchGetAssessmentRequest() {
  yield takeEvery(action.GET_ASSESSMENT, getAssessment);
}
