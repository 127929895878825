/**
 * Given a blob, attempt to download it as file to the user's machine.
 *
 * @param {Blob} blob The file data
 * @param {String} fileName The file name (no extension required)
 */
export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
