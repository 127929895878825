// REACT, STYLE, STORIES & COMPONENT
import React, { useRef, useState } from 'react';
import styles from './HelpMenu.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// OTHER COMPONENTS
import { BluCSSTransition } from 'ui/basic';

// UTILS
import { handleRipple } from 'utils/ripple';
import { useTranslate } from 'utils/translator';
import { FEEDBACK_LINK, HELP_LINK, SUPPORT_LINK } from 'utils/configuration';

// CONFIG & DATA
// const Config = {};

const { HelpMenu: HelpMenuIcon } = IconsSvg;

// COMPONENT: HelpMenu
const HelpMenu = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  const menuRef = useRef();

  const [ menuIsOpen, setMenuIsOpen ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: HelpMenu
  return (
    <div
      className={styles.helpMenu}
      tabIndex='0'
      role='button'
      onBlur={(event) => {
        if (event.relatedTarget !== menuRef.current) {
          setMenuIsOpen(false);
        }
      }}
    >
      <HelpMenuIcon
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      />

      <BluCSSTransition
        nodeRef={menuRef}
        in={menuIsOpen}
        classNames={{ ...styles }}
      >
        <div
          ref={menuRef}
          className={styles.menu}
        >
          <div
            className={styles.menuItem}
            role='presentation'
            onClick={(event) => {
              handleRipple(event, styles.colorPrimary3);
              window.setTimeout(() => {
                setMenuIsOpen(false);
                window.open(FEEDBACK_LINK, '_blank');
              }, styles.animationDurationShortMs);
            }}
          >
            <span>
              { translate('survey_floating_menu_feedback') }
            </span>
          </div>

          <div
            className={styles.menuItem}
            role='presentation'
            onClick={(event) => {
              handleRipple(event, styles.colorPrimary3);
              window.setTimeout(() => {
                setMenuIsOpen(false);
                window.open(SUPPORT_LINK, '_blank');
              }, styles.animationDurationShortMs);
            }}
          >
            <span>
              { translate('survey_floating_menu_support') }
            </span>
          </div>

          <div
            className={styles.menuItem}
            role='presentation'
            onClick={(event) => {
              handleRipple(event, styles.colorPrimary3);
              window.setTimeout(() => {
                setMenuIsOpen(false);
                window.open(HELP_LINK, '_blank');
              }, styles.animationDurationShortMs);
            }}
          >
            <span>
              { translate('gen_settings_help_link') }
            </span>
          </div>
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default HelpMenu;
