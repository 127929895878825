import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import styles from './TeamSkillsResult.module.scss';

import { useSelector } from 'react-redux';

import { useTranslate } from 'utils/translator';
import { useWindowWidth, useDebounce, useBreakpoint } from 'utils/hooks';
import { markdown } from 'utils/textTools';
import { filterHiddenTeamResults } from 'utils/assessment';

import CompetenciesResultSkeleton from 'ui/molecules/CompetenciesResult/CompetenciesResultSkeleton';
import {
  Card, InfoTitle, Modal, PillGroup,
} from 'ui/basic';
import { SteppedScaleResultItem } from '../SteppedScaleResultItem';

import { LayoutContext } from 'features/framework/components/MainLayout/MainLayout';


const TeamSkillsResult = (props) => {
  const {
    assessmentType,
    assessmentName = '',
    assessmentDescription = '',
    profile = {},
    referenceProfile,
    showAverage = true,
  } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const resultsFiltered = useMemo(() => (
    filterHiddenTeamResults(profile?.results)
  ), [ profile?.results ]);

  const assessment = useSelector((state) => state.assessments.table[assessmentType]);

  const title = assessment?.title || assessmentName;
  const description = assessment?.description || assessmentDescription;

  const [ rowsNode, setRowsNode ] = useState();
  const [ rowsWidth, setRowsWidth ] = useState();

  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState(false);
  const [ infoPageTitle, setInfoPageTitle ] = useState('');
  const [ infoPageDescription, setInfoPageDescription ] = useState('');

  const profileResults = useMemo(() => (
    resultsFiltered?.filter((r) => r.average && r.values.every((valueItem) => typeof valueItem.value === 'number'))
  ), [ resultsFiltered ]);

  const [ currentDimension, setCurrentDimension ] = useState(
    { id: profileResults[0].id, name: profileResults[0].name },
  );

  const rowsRef = useCallback((node) => {
    if (node) {
      setRowsNode(node);
    }
  }, []);

  // EXTERNAL EFFECTS
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  useEffect(() => {
    if (rowsNode) {
      setTimeout(() => {
        const smallScreenWidth = rowsNode.clientWidth;
        const largeScreenWidth = rowsNode.clientWidth / 2;

        if (bp.isXs || bp.isS) {
          setRowsWidth(smallScreenWidth);
          return;
        }

        setRowsWidth(largeScreenWidth);
      }, 500);
    }
  }, [ debouncedWindowWidth, rowsNode, mainNavIsOpenDebounced, bp ]);

  const [ data, setData ] = useState();
  useEffect(() => {
    if (!currentDimension) {
      return;
    }

    const thisDimension = profileResults.find((r) => r.id === currentDimension.id);

    if (!thisDimension) {
      return;
    }

    const mappedData = thisDimension.values.map((valueItem) => {
      let dimensionReferenceValue;

      if (referenceProfile) {
        if (referenceProfile.results.find((r) => r.dimensionId === thisDimension.id)) {
          dimensionReferenceValue = referenceProfile.results.find((r) => r.dimensionId === thisDimension.id).minValue;
        } else {
          dimensionReferenceValue = referenceProfile.results
          .reduce((total, value) => total + value, 0) / referenceProfile.results.length;
        }
      }

      return {
        id: thisDimension.id,
        name: thisDimension.name,
        result: valueItem.value,
        referenceValue: dimensionReferenceValue,
        user: {
          id: valueItem.user.id,
          name: valueItem.user.name,
        },
        subDimensions: thisDimension.subDimensions.map((subDimension) => {
          let subDimensionResult;
          const subDimensionUser = subDimension.values.find((vItem) => (
            (vItem.user?.id || vItem.user) === valueItem.user.id
          ));
          if (subDimensionUser) {
            subDimensionResult = subDimensionUser.value;
          }

          let subDimensionReferenceValue;
          if (referenceProfile?.results) {
            subDimensionReferenceValue = referenceProfile.results.find((r) => r.dimensionId === subDimension.id);
          }

          return {
            id: subDimension.id,
            name: subDimension.name,
            description: subDimension.description,
            result: subDimensionResult,
            referenceValue: subDimensionReferenceValue && subDimensionReferenceValue.minValue,
          };
        }),
      };
    });

    setData(mappedData);
  }, [
    currentDimension,
    profileResults,
    referenceProfile,
  ]);

  if (!data || data.length === 0) {
    return <CompetenciesResultSkeleton />;
  }

  return (
    <Card hasNoPaddings>
      <div className={styles.teamSkillsResult}>
        <div className={styles.mainHeader}>
          <InfoTitle
            title={title}
            onClick={() => {
              setInfoPageTitle(title);
              setInfoPageDescription(description);
              setInfoPageIsVisible(true);
            }}
          />

          <div
            className={styles.dimensions}
            style={{ maxWidth: `${(rowsWidth || 0) * 2}px` }}
          >
            <PillGroup
              pillItems={profileResults.map((rs) => ({ id: rs.id, label: rs.name }))}
              onPillClick={(pillItem) => setCurrentDimension(pillItem)}
            />
          </div>
        </div>

        <div className={styles.rowsParent}>
          <div className={styles.rows} ref={rowsRef}>
            { data.map((dimensionResult, dimensionIndex) => (
              <SteppedScaleResultItem
                key={`dimension-${dimensionIndex}`}
                data={dimensionResult}
                segmentsCount={assessment && assessment.renderReferenceMax}
                onInfoClick={(subDimensionIndex, subDimension) => {
                  if (!subDimension) {
                    return;
                  }

                  setInfoPageTitle(subDimension.name);
                  setInfoPageDescription(subDimension.description || subDimension.name);
                  setInfoPageIsVisible(true);
                }}
              />
            )) }
          </div>
        </div>

        { showAverage && (
          <div className={styles.bottomText}>
            { translate('team_profile_average_title') }
            <span>
              { ` ${profileResults.find((r) => r.id === currentDimension.id)?.average || '0'}` }
            </span>
          </div>
        ) }
      </div>

      { infoPageIsVisible && (
        <Modal
          header={infoPageTitle}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setInfoPageIsVisible(false)}
        >
          { markdown(infoPageDescription) }
        </Modal>
      ) }
    </Card>
  );
};

export default TeamSkillsResult;
