// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useRef } from 'react';

// ASSETS
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/icn_info_blue.svg';


// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { BluCSSTransition } from 'ui/basic';
import styles from './CollapsibleNext.module.scss';

// UTILS

// CONFIG & DATA

// COMPONENT: CollapsibleNext
const CollapsibleNext = (props) => {
  // PROPS
  const {
    id,
    children,
    size = 'S',
    headerClassName,
    showTopBorder, // if CollapsibleNext is first child has same effect
    withBorders,
    noBorders,
    isHovered, isOpenOverride, isOpenInitially,
    isExpandable = true,
    headerSubtle, headerHighlight, header, headerRight,
    highlightOnOpen,
    extraDetail,
    label,
    onMouseEnter, onMouseLeave,
    hasInfoIcon,
    onInfoIconClick = () => {},
    noPaddings = false,
    noBackground = false,
    bottomless = false,
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ isExpanded, setIsExpanded ] = useState(isOpenInitially);
  const transitionRef = useRef();

  // STORE HOOKS

  // EFFECT HOOKS
  useEffect(() => {
    setIsExpanded(isOpenOverride);
  }, [ isOpenOverride ]);

  // OTHER HOOKS

  // METHODS
  const handleInfoIconClick = (event) => {
    if (hasInfoIcon) {
      event.stopPropagation(); // prevent toggle of Collapsible
      onInfoIconClick();
    }
  };

  // HELPERS, HANDLES, RENDERS

  // RENDER: CollapsibleNext
  return (
    <div
      id={id}
      className={classNames(styles.collapsibleNext, styles[`size${size.toUpperCase()}`], {
        [styles.isHovered]: isHovered,
        [styles.highlightOnOpen]: highlightOnOpen,
        [styles.showTopBorder]: showTopBorder,
        [styles.withBorders]: withBorders,
        [styles.noBorders]: noBorders,
        [styles.isExpandable]: isExpandable,
        [styles.isExpanded]: isExpanded,
        [styles.noBackground]: noBackground,
        [styles.bottomless]: bottomless,
      })}
      data-test='CollapsibleNext'
      data-hovering={isHovered}
    >
      <div
        className={classNames(styles.header, headerClassName, {
          [styles.bottomless]: bottomless,
          [styles.noBackground]: noBackground,
          [styles.noPaddings]: noPaddings,
        })}
        onClick={() => {
          if (!isExpandable) {
            return;
          }
          setIsExpanded(!isExpanded);
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role='presentation'
      >

        { /* label */ }
        { label && (
          <div className={styles.label}>
            { label }
          </div>
        ) }

        <div
          className={styles.headers}
          data-test='CollapsibleNextHeaders'
        >
          { /* headerSubtle */ }
          { headerSubtle && (
            <div className={styles.headerSubtle}>
              { headerSubtle }
            </div>
          ) }

          { /* headerHighlight */ }
          { headerHighlight && (
            <div className={classNames(styles.headerHighlight)}>
              { headerHighlight }
            </div>
          ) }

          { /* header */ }
          <div className={styles.headerNormal}>
            { header }
          </div>

          { /* headerRight */ }
          { (headerRight || hasInfoIcon) && (
            <div
              className={classNames(styles.headerRight, {
                [styles.hasInfoIcon]: hasInfoIcon,
              })}
              onClick={handleInfoIconClick}
              role='presentation'
            >
              <div className={styles.label}>{ headerRight }</div>
              { hasInfoIcon && (
                <div
                  className={styles.infoIcon}
                  onClick={handleInfoIconClick}
                  role='presentation'
                >
                  <InfoIcon />
                </div>
              ) }
            </div>
          ) }

          { isExpandable && (
            <div className={styles.arrow}>
              <ArrowDown />
            </div>
          ) }
        </div>

        { /* extraDetail */ }
        { extraDetail && (
          <div className={styles.extraDetail}>
            { extraDetail }
          </div>
        ) }

      </div>
      <BluCSSTransition
        nodeRef={transitionRef}
        in={isExpanded}
        classNames={{ ...styles }}
      >
        <div
          ref={transitionRef}
          className={classNames(styles.content, {
            [styles.noPaddings]: noPaddings,
          })}
          data-test='CollapsibleNextContent'
        >
          { children }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default CollapsibleNext;
