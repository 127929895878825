// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Subdimension.module.scss';

// OTHER COMPONENTS
import { IstIndicator } from 'ui/basic';


// COMPONENT: Subdimension
const Subdimension = ({
  id = '',
  title = '',
  description = '',
  score = 0,
  vulnerable = false,
  children,
}) => (
  <div className={styles.subdimension} id={id}>
    <div className={styles.intro}>
      <div>
        <h3>{ title }</h3>
        { description }
      </div>
      <div className={styles.indicator}>
        <IstIndicator
          color={vulnerable ? 'red' : 'light'}
        />
        <div className={styles.scoreWrapper}>
          <span className={styles.score}>{ Math.round(score) }</span>
          <span className={styles.smallRatio}>{ ' / 100' }</span>
        </div>
      </div>
    </div>
    { children }
  </div>
);


export default Subdimension;
