// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './BipolarDiagram.module.scss';
import { isValid } from 'utils/numbers';
import { setDecimalSeparator } from 'utils/userpreference';

// CONFIG
const Config = {
  defaultDiagramRange: [ -2, 2 ],
  defaultMarkers: [ -1.7, -0.84, 0.84, 1.7 ],
  defaultCenter: 0,
  defaultSize: 15,
  rangeMinWidth: 0.025,
  heightDistanceRatio: 0.66,
};

// COMPONENT: BipolarDiagram
const BipolarDiagram = (props) => {
  const {
    diagramRange = Config.defaultDiagramRange,
    center = Config.defaultCenter,
    score = null,
    range,
    markers = Config.defaultMarkers,
    showMarkers = false, // if set, displays the numeric labels
    size = Config.defaultSize,
  } = props;

  const [ min, max ] = diagramRange;
  let diagramHeight = size;
  if (showMarkers) {
    diagramHeight += 24;
  }

  // Prevent knob from being hidden near edges
  let knobPosTransform = 0;
  if (score === min) {
    knobPosTransform = size / 2;
  }
  if (score === max) {
    knobPosTransform = -size / 2;
  }

  // Prevent marker from being hidden near edges
  const getTextAnchor = (n) => {
    if (n > 1 - Config.rangeMinWidth) {
      return 'end';
    }
    if (n < Config.rangeMinWidth) {
      return 'start';
    }
    return 'middle';
  };


  // RENDER range
  const renderRange = () => {
    if (!range?.length) {
      return null;
    }

    let [ rangeMin, rangeMax ] = range;
    if (rangeMin > rangeMax) {
      return null;
    }

    // A range must have a min width. Also adjust position near edges if overflowing.
    const diff = rangeMax - rangeMin;
    if (diff < Config.rangeMinWidth) {
      rangeMin -= (Config.rangeMinWidth - diff) / 2;
      rangeMax += (Config.rangeMinWidth - diff) / 2;
      if (rangeMax > max) {
        rangeMin = max - Config.rangeMinWidth;
        rangeMax = max;
      }
      if (rangeMin < min) {
        rangeMin = min;
        rangeMax = min + Config.rangeMinWidth;
      }
    }

    return (
      <rect
        x={`${((rangeMin - min) / (max - min)) * 100}%`}
        width={`${((rangeMax - rangeMin) / (max - min)) * 100}%`}
        height={size}
        rx={size / 2}
        fill={styles.colorPrimary3}
        className={styles.animate}
      />
    );
  };

  // RENDER: Bipolar Diagram
  return (
    <div className={styles.bipolarDiagram}>
      <svg width='100%' height={diagramHeight}>
        { /* SCORE KNOB TRAIL */ }
        <rect
          x={`${((Math.min(score, center) - min) / (max - min)) * 100}%`}
          width={`${(Math.abs(score - center) / (max - min)) * 100}%`}
          height={size}
          fill={styles.colorPrimary4}
          className={styles.animate}
        />

        { /* RANGE */ }
        { renderRange() }

        { /* LINE */ }
        <rect
          y={Math.floor(size / 2)}
          width='100%'
          height={1}
          fill={styles.colorGrey3}
        />

        { /* MARKERS */ }
        { [ center, ...markers ].map((value) => {
          const fraction = (value - min) / (max - min);
          const distanceFromCenter = Math.abs(center - value) / (max - min);
          const height = (1 - distanceFromCenter * Config.heightDistanceRatio) * size;
          return (
            <React.Fragment key={value}>
              <rect
                width={1}
                x={`${fraction * 100}%`}
                height={height}
                y={(size - height) / 2}
                fill={styles.colorGrey3}
              />
              { showMarkers && (
                <text
                  x={`${fraction * 100}%`}
                  y={diagramHeight}
                  className={styles.text}
                  textAnchor={getTextAnchor(fraction)}
                >
                  { setDecimalSeparator(value, 2, { minimumFractionDigits: null }) }
                </text>
              ) }
            </React.Fragment>
          );
        }) }

        { /* SCORE KNOB */ }
        { isValid(score) && (
          <circle
            cx={`${((score - min) / (max - min)) * 100}%`}
            cy={size / 2}
            r={size / 2}
            fill={styles.colorPrimary1}
            transform={`translate(${knobPosTransform}, 0)`}
            className={styles.animate}
          />
        ) }
      </svg>
    </div>
  );
};

export default BipolarDiagram;
