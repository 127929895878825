// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionPolicy.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Checkbox } from 'ui/basic';
import { Legal } from 'features/framework/pages';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionPolicy
const QuestionPolicy = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
    setRenderOutside = () => {},
    extras = { hasExternalLegalAgreements: false },
  } = props;

  const translate = useTranslate();

  // TODO MODALS: put modals in DialogSequence and pass in setters here
  // const {
  //   setTosModal
  // } = extras;

  // hasExternalAgreements, retrieve from extras
  const [ hasExternalLegalAgreements, setHasExternalAgreements ] = useState(false);
  useEffect(() => {
    if (extras.hasExternalLegalAgreements !== hasExternalLegalAgreements) {
      setHasExternalAgreements(extras.hasExternalLegalAgreements);
    }
  }, [ extras, hasExternalLegalAgreements ]);

  const [ tosChecked, setTosChecked ] = useState(false);
  const [ privacyChecked, setPrivacyChecked ] = useState(false);

  // READ ANSWER
  useEffect(() => {
    if (answer.value) {
      setTosChecked(answer.value.tosChecked);
      setPrivacyChecked(answer.value.privacyChecked);
    }
  }, [ answer ]);

  // VALIDATE
  useEffect(() => {
    let isValid;
    if (hasExternalLegalAgreements) {
      isValid = true;
    } else {
      isValid = tosChecked && privacyChecked;
    }

    const newAnswer = {
      value: {
        tosChecked,
        privacyChecked,
      },
      name: 'policy',
      isValid,
    };

    onAnswer(newAnswer);
  }, [ tosChecked, privacyChecked, hasExternalLegalAgreements, onAnswer ]);


  // MODALS
  const TERMS_OF_USE = translate('cp_cm_label_tos');
  const PRIVACY_POLICY = translate('cp_cm_label_privacy');

  const termsDialog = <Legal propSubPage='terms-of-use' onClose={() => setRenderOutside()} />;
  const privacyDialog = <Legal propSubPage='privacy-agreement' onClose={() => setRenderOutside()} />;

  const getParsedText = (text, content = 'terms') => {
    const joinedText = [];
    const part1 = text.split(/{{link./);
    if (part1.length > 1) {
      joinedText.push(part1[0]);
      joinedText.push(
        <span
          key={2}
          role='presentation'
          className={styles.policy}
          onClick={() => {
            if (content === 'terms') {
              setRenderOutside(termsDialog);
            } else {
              setRenderOutside(privacyDialog);
            }
          }}
        >
          { part1[1].split('}}')[0] }
        </span>,
      );
      joinedText.push(part1[1].split('}}')[1]);
    } else {
      joinedText.push(text);
    }
    return joinedText;
  };

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionPolicy
  return (
    <div className={classNames(styles.questionPolicy)}>

      { /* CHECKBOXES: WITHOUT EXTERNAL AGREEMENTS */ }
      { !hasExternalLegalAgreements && (
        <>
          <div className={styles.checkBox}>
            <Checkbox
              name={getParsedText(TERMS_OF_USE, 'terms')}
              checked={tosChecked}
              onChange={(value) => setTosChecked(value)}
            />
          </div>
          <div className={styles.checkBox}>
            <Checkbox
              name={getParsedText(PRIVACY_POLICY, 'privacyPolicy')}
              checked={privacyChecked}
              onChange={(value) => setPrivacyChecked(value)}
            />
          </div>
        </>
      ) }

      { /* LINKS: WITH EXTERNAL AGREEMENTS */ }
      { hasExternalLegalAgreements && (
        <>
          <div className={classNames(styles.formRow, styles.checkBox, styles.center)}>
            <span
              role='presentation'
              className='bluLink'
              onClick={() => {
                setRenderOutside(termsDialog);
              }}
            >
              { translate('legal_terms_of_use_title') }
            </span>
          </div>
          <div className={classNames(styles.formRow, styles.checkBox, styles.center)}>
            <span
              role='presentation'
              className='bluLink'
              onClick={() => {
                setRenderOutside(privacyDialog);
              }}
            >
              { translate('legal_privacy_agreement_title') }
            </span>
          </div>
        </>
      ) }
    </div>
  );
};

export default QuestionPolicy;
