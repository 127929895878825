/*
important:
  - the element will get position: relative, overflow: hidden;
  - children
    - make sure all children who should IN FRONT of the ripple are acutal HTML Elements and not just text nodes
    - the children will get position: relative, z-index:1;
  - currently there is no style cleanup done for all elements
improvements:
  - implement in a more "react-way"
  - make better use of css that isn't handled throught JS/DOM
*/
import styles from 'styles-imports/shared.scss';

const animationDurationMs = styles.animationDurationLongMs;

// intial size of rippleDiv in viewport M, L, XL
const baseDimension = 15; // in pxs
// the width of a menuItem in viewport M, L, XL
const baseDimensionBase = 244; // in px


const elStyle = {
  position: 'relative',
  overflow: 'hidden',
};
const elChildStyle = {
  position: 'relative',
  // needs position for zIndex in rippleDiv to work
  zIndex: 1,
};
const rippleStyle = {
  position: 'absolute',
  width: `${baseDimension}px`,
  height: `${baseDimension}px`,
  borderRadius: '50%',
  animation: `ripple ${animationDurationMs}ms 1`,
  backgroundColor: styles.colorPrimary3,
  zIndex: 0,
};

export const handleRipple = (event, color) => {
  const element = event.currentTarget;
  // calculate proper positions of ripple
  const elBounding = element.getBoundingClientRect();
  const X = event.clientX - elBounding.left;
  const Y = event.clientY - elBounding.top;
  const elWidth = elBounding.width;

  // rippleStyle: apply offsets to rippleStyle based on mouse position
  Object.assign(rippleStyle, {
    top: `${Y}px`,
    left: `${X}px`,
    backgroundColor: color || rippleStyle.backgroundColor,
  });

  // rippleStyle: viewport-based size to make ripple cover full width
  // - since we can't scale() in css via pixel values
  // - we are going to influence scale by the base size (width & height)
  // - which then scales to calculate full width
  const rippleDimension = baseDimension / baseDimensionBase * elWidth;
  Object.assign(rippleStyle, {
    width: `${rippleDimension}px`,
    height: `${rippleDimension}px`,
  });

  // prepare existing elements for ripple
  Object.assign(element.style, elStyle);
  // make sure all children are in front of rippleDiv
  for (let i = 0; i < element.children.length; i++) {
    // needs position for z-index to work
    const child = element.children[i];
    if (child.className !== 'ripple') {
      Object.assign(child.style, elChildStyle);
    }
  }

  // prepare & append rippleDiv
  const rippleDiv = document.createElement('div');
  rippleDiv.className = 'ripple';
  Object.assign(rippleDiv.style, rippleStyle);
  element.appendChild(rippleDiv);

  // remove rippleDiv after timeout
  setTimeout(() => {
    element && element.removeChild(rippleDiv);
    // TODO: could do a better job cleaning up styles
  }, animationDurationMs);
};

export const setRipple = (element, color = '#FFFFFF') => {
  element.addEventListener('click', (event) => {
    handleRipple(event, color);
  });
};
