// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './AssessmentsAccessModal.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Modal, Toggle } from 'ui/basic';

// OTHER COMPONENTS

// UTILS
import { apiNext } from 'apiNext';
import { markdown } from 'utils/textTools';
import { useTranslate } from 'utils/translator';
import { COMPANY_ID } from 'utils/configuration';
import { getFullName } from 'utils/users';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE
import { useSelector } from 'react-redux';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';

// CONFIG & DATA
// const Config = {};

// COMPONENT: AssessmentsAccessModal
const AssessmentsAccessModal = (props) => {
  // PROPS
  const {
    user = {},
    assessmentsIds = [],
    onClose = () => {},
  } = props;

  const translate = useTranslate();

  const assessmentsFromStore = useSelector((state) => fromAssessmentsSelectors.selectAssessments(state, [
    fromAssessmentsSelectors.ASSESSMENT_FILTERS.noClifton,
    fromAssessmentsSelectors.ASSESSMENT_FILTERS.noAsPeer,
  ]));

  // Include paid assessments if missing (trial case, showoff only)
  if (!assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.RMP)) {
    assessmentsFromStore.push({ id: ASSESSMENT_TYPES.RMP, premium: true });
  }
  if (!assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.NINE_LEVELS)) {
    assessmentsFromStore.push({ id: ASSESSMENT_TYPES.NINE_LEVELS, premium: true });
  }

  const assessmentsIdsInternal = [ ...assessmentsIds ];
  const [ processing, setProcessing ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleSave = () => {
    setProcessing(true);
    apiNext.post('core/access/assessment', {
      user: user.id,
      company: COMPANY_ID,
      assessments: assessmentsIdsInternal,
    })
    .then(() => {
      onClose(true);
    })
    .catch((error) => {
      console.error(error.message);
      setErrorMessage(error.message);
    })
    .finally(() => {
      setProcessing(false);
    });
  };

  // RENDER: AssessmentsAccessModal
  return (
    <Modal
      header={translate('assessment_access_manage')}
      primaryButtonTitle={translate('save_lbl')}
      primaryButtonDisabled={processing}
      secondaryButtonTitle={translate('cancel_lbl')}
      closeOnConfirm={false}
      onClose={() => onClose(false)}
      onConfirm={handleSave}
    >
      <>
        <div className='bluTypeCopy'>
          { translate('assessment_access_manage_copy', [
            '{{username}}', getFullName(user),
          ]) }
        </div>

        { errorMessage && (
          <div className={classNames('error', 'marginTopXs')}>{ errorMessage }</div>
        ) }

        <div className={styles.assessmentsList}>
          { assessmentsFromStore.map((a) => (
            <div className={styles.listItem} key={a.id}>
              <div className={styles.left}>
                <div>{ a.title }</div>
                <div>{ markdown(a.description) }</div>
              </div>
              <div className={styles.right}>
                <Toggle
                  id={a.id}
                  checked={assessmentsIdsInternal.includes(a.id)}
                  disabled={processing}
                  onChange={(value) => {
                    if (value && !assessmentsIdsInternal.includes(a.id)) {
                      assessmentsIdsInternal.push(a.id);
                    } else {
                      const thisAssessmentIndex = assessmentsIdsInternal.findIndex((aId) => aId === a.id);
                      if (thisAssessmentIndex !== -1) {
                        assessmentsIdsInternal.splice(thisAssessmentIndex, 1);
                      }
                    }
                  }}
                />
              </div>
            </div>
          )) }
        </div>
      </>
    </Modal>
  );
};

export default AssessmentsAccessModal;
