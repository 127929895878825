import { post } from 'api';
import Queue from 'api/queue';
import * as action from 'store/actions';
import {
  call, put, take, takeEvery,
} from 'redux-saga/effects';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import * as fromActionTypes from 'store/actionTypes/assessments';
import * as fromActions from 'store/actions/saveAssessmentAnswers';

function* requestSaveAssessmentAnswers(payload) {
  const { assessmentId, stage = 1, answers } = payload;

  try {
    yield put(action.loading());
    const { status, data } = yield call(post, `/core/assessments/${assessmentId}/${stage}/answers`, { answers });

    if (status === 200) {
      yield put(action.saveAssessmentAnswersFulfilled());

      if (assessmentId === ASSESSMENT_TYPES.NINE_LEVELS && stage < 4) {
        yield put(action.getAssessmentQuestions(assessmentId, stage + 1));
      }
    } else {
      yield put(action.saveAssessmentAnswersRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(action.saveAssessmentAnswersRejected(error));
  } finally {
    yield put(action.loadingFulFilled());
  }
}

async function putInQueue(url, data) {
  return Queue.enqueue(() => post(url, data));
}

function* saveAssessmentAnswer({ payload }) {
  try {
    const { assessmentId, stage, answer } = payload;
    const { ok, status, data } = yield call(putInQueue, `/core/assessments/${assessmentId}/${stage}/answers`, { answers: [ { ...answer } ] });

    if (ok && status === 200) {
      yield put(fromActions.saveAssessmentAnswerFulfilled({}));
    } else {
      yield put(fromActions.saveAssessmentAnswerRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.saveAssessmentAnswerRejected({}));
  }
}

export default function* watchSaveAssessmentAnswersRequest() {
  while (true) {
    try {
      const { payload } = yield take(action.SAVE_ASSESSMENT_ANSWERS);
      yield call(requestSaveAssessmentAnswers, payload);
    } catch (error) {
      yield put(action.saveAssessmentAnswersRejected(error));
    }
  }
}

export function* watchSaveAssessmentAnswer() {
  yield takeEvery(fromActionTypes.SAVE_ASSESSMENT_ANSWER, saveAssessmentAnswer);
}
