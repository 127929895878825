// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './AssessmentManagerUserGroup.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  DropDownSubtle,
  ImgCircle, InputNext, Pagination, SkeletonFlexible,
} from 'ui/basic';
import { AssessmentsAccessModal } from './AssessmentsAccessModal';

// UTILS
import * as api from 'api';
import { MIN_SEARCH_LENGTH } from 'api';
import { useTranslate } from 'utils/translator';
import { COMPANY_ID } from 'utils/configuration';
import { useDebounce, useLatestCall } from 'utils/hooks';
import { getFullName } from 'utils/users';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  paginationLimit: 10,
};

// COMPONENT: AssessmentManagerUserGroup
const AssessmentManagerUserGroup = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ pageIndex, setPageIndex ] = useState(0);
  const [ sortBy, setSortBy ] = useState('fullname');
  const [ sortDirection, setSortDirection ] = useState('asc');
  const [ sortIndex, setSortIndex ] = useState(0);
  const [ searchString, setSearchString ] = useState('');
  const debouncedSearchString = useDebounce(searchString, 300);

  const sortOptions = [
    {
      value: 'name_asc',
      label: translate('assessment_manager_sort_name_asc'),
      sortBy: 'fullname',
      sortDirection: 'asc',
    },
    {
      value: 'name_desc',
      label: translate('assessment_manager_sort_name_desc'),
      sortBy: 'fullname',
      sortDirection: 'desc',
    },
  ];

  const params = new URLSearchParams();
  params.append('company', COMPANY_ID);
  params.append('expand', 'user');
  params.append('expand', 'assessment');
  params.append('limit', Config.paginationLimit);
  params.append('offset', pageIndex * Config.paginationLimit);
  params.append('search', debouncedSearchString);
  params.append('sort', sortBy);
  params.append('sortOrder', sortDirection);

  const [ toggle, setToggle ] = useState(false);
  const [
    assessmentManagersSearchLoading,
    assessmentManagersSearchResults,
    setAssessmentManagersSearchString,
    assessmentManagersCount,
  ] = useLatestCall(
    '/core/access/assessment',
    useCallback(({ users }) => users, []),
    params,
    false,
    toggle,
  );

  useEffect(() => {
    if (debouncedSearchString.length >= MIN_SEARCH_LENGTH || debouncedSearchString === '') {
      setAssessmentManagersSearchString(debouncedSearchString);
      setPageIndex(0);
    }
  }, [
    debouncedSearchString,
    setAssessmentManagersSearchString,
  ]);

  const [ showAssessmentsAccessModal, setShowAssessmentsAccessModal ] = useState(false);
  const [ user, setUser ] = useState({});
  const [ assessmentsIds, setAssessmentsIds ] = useState();

  const handleManageClick = (resultItem) => {
    setUser(resultItem.user);
    setAssessmentsIds(resultItem.assessments?.map((a) => a.id));
    setShowAssessmentsAccessModal(true);
  };

  // RENDER: AssessmentManagerUserGroup
  return (
    <div className={classNames(styles.assessmentManagerUserGroup)}>
      <div className={styles.mainHalf}>
        <span className='bluTypeXs'>{ translate('platform_roles_assessment_manager') }</span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('assessment_manager_permissions') }
        </div>

        <div className={styles.profileSettings}>
          <span className='bluTypeLabelL'>{ translate('assessment_manager_profile_settings') }</span>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('assessment_manager_profile_settings_copy') }
          </div>
        </div>
      </div>

      <div className={styles.mainFull}>
        <div className='bluTypeXs'>
          { translate('assessment_manager_active') }
        </div>

        <div className={styles.controls}>
          <InputNext
            placeholder={translate('search_lbl')}
            icon='SearchBig'
            onChange={setSearchString}
          />

          <DropDownSubtle
            className='alignRight'
            options={sortOptions}
            activeIndex={sortIndex}
            fullWidthForXs={false}
            onChange={(newIndex) => {
              const sortItem = sortOptions[newIndex];
              setSortBy(sortItem.sortBy);
              setSortDirection(sortItem.sortDirection);
              setSortIndex(newIndex);
            }}
          >
            { translate('table_sort_by') }
          </DropDownSubtle>
        </div>

        { assessmentManagersSearchLoading && (
          <div className='marginTopS'>
            <SkeletonFlexible noHeader repeat={9} />
          </div>
        ) }

        { !assessmentManagersSearchLoading
          && (!assessmentManagersSearchResults || assessmentManagersSearchResults?.length === 0)
          && (
            <div className={classNames('bluTypeCopy', 'marginTopS')}>
              { translate('assessment_manager_search_no_results') }
            </div>
          ) }

        { assessmentManagersSearchResults?.length > 0 && (
          <div className={styles.list}>
            { assessmentManagersSearchResults.map((resultItem) => (
              <div className={styles.listItem} key={resultItem.memberId}>
                <div className={styles.left}>
                  <ImgCircle
                    fullLabel={getFullName(resultItem.user)}
                    src={api.getUserImageUrl(resultItem.user.id)}
                    size='S'
                  />
                  <div className={styles.textContent}>
                    <div>{ getFullName(resultItem.user) }</div>
                    <div>
                      { resultItem.assessments
                      ?.map((a) => a.title)
                      .filter(Boolean).join(', ') }
                    </div>
                  </div>
                </div>
                <div
                  tabIndex='0'
                  role='button'
                  className={styles.right}
                  onClick={() => handleManageClick(resultItem)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleManageClick(resultItem);
                    }
                  }}
                >
                  <IconsSvg.Edit />
                  <div>{ translate('assessment_access_manage') }</div>
                </div>
              </div>
            )) }
          </div>
        ) }

        { /* PAGINATION */ }
        { assessmentManagersCount > Config.paginationLimit && (
          <Pagination
            pageCount={Math.ceil(assessmentManagersCount / Config.paginationLimit)}
            pagePosition={pageIndex}
            showPagerButtons
            showNumbers
            onPagePositionUpdate={setPageIndex}
          />
        ) }
      </div>

      { showAssessmentsAccessModal && (
        <AssessmentsAccessModal
          user={user}
          assessmentsIds={assessmentsIds}
          onClose={(refresh = false) => {
            setUser({});
            setAssessmentsIds([]);
            setShowAssessmentsAccessModal(false);

            if (refresh) {
              setToggle(!toggle);
            }
          }}
        />
      ) }
    </div>
  );
};

export default AssessmentManagerUserGroup;
