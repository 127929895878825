// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './PillGroup.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// STORE

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Pill, Scrollable } from 'ui/basic';

// UTILS
import { isValid } from 'utils/numbers';
import { useTranslate } from 'utils/translator';

// CONFIG & DATA

// COMPONENT: PillGroup
const PillGroup = (props) => {
  // PROPS
  const {
    pillItems = [],
    looks,
    fadeWhite = false,
    wrap = false,
    activeIndex,
    onPillClick = () => {},
  } = props;

  // COMPONENT/UI STATE and REFS
  const [ activeIndexInternal, setActiveIndexInternal ] = useState(0);

  // SPECIAL HOOKS
  const translate = useTranslate();

  // EFFECT HOOKS
  useEffect(() => {
    if (isValid(activeIndex)) {
      setActiveIndexInternal(activeIndex);
    }
  }, [ activeIndex ]);

  // OTHER HOOKS

  // METHODS
  const handleClick = (index, pillItem) => {
    // console.log('PillGroup click', index);
    setActiveIndexInternal(index);
    onPillClick(pillItem, index);
  };

  // HELPERS, HANDLES, RENDERS
  // RENDER: Pills, with active
  const pillItemsJsx = pillItems.map((pillItem, index) => {
    const pillProps = {
      // return index onClick
      onClick: () => { handleClick(index, pillItem); },
      active: index === activeIndexInternal,
      looks,
      color: pillItem.color,
    };
    return (
      <Pill key={index} {...pillProps}>
        { translate(pillItem.label) }
      </Pill>
    );
  });


  // RENDER: PillGroup
  if (wrap) {
    return (
      <div className={classNames(styles.pillGroup, styles.wrap)}>
        { pillItemsJsx }
      </div>
    );
  }

  return (
    <div className={classNames(styles.pillGroup, {
      [styles.fadeWhite]: fadeWhite,
    })}
    >
      { /* Pills */ }
      <Scrollable
        drag
        scroll
        scrollOnClick
        fade
        fadeWhite={fadeWhite}
      >
        <div className={styles.pillContainer}>
          { pillItemsJsx }
        </div>
      </Scrollable>
    </div>
  );
};

export default PillGroup;
