import React, { memo } from 'react';
import styles from './RmpResult.module.scss';

import { useTranslate } from 'utils/translator';

import { ReactComponent as InfoBlue } from 'assets/icons/icn_info_blue.svg';
import { BipolarDiagram } from 'ui/basic';

const RMP_FIRST_MARKER_POSITION = 0.84;


const RmpResultItem = memo((props) => {
  const {
    dimension, dimensionId, score, range, exportView, onInfoClick,
  } = props;

  const translate = useTranslate();

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        { translate(`rmp_results_label_${dimensionId?.toLowerCase()}`) || dimension }

        { !exportView && <InfoBlue onClick={onInfoClick} /> }
      </div>
      <BipolarDiagram
        score={score}
        range={range}
      />
      <div className={styles.titles}>
        <div className={score < -RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
          { translate('rmp_results_scale_min') }
        </div>
        <div className={score > RMP_FIRST_MARKER_POSITION ? styles.blue : null}>
          { translate('rmp_results_scale_max') }
        </div>
      </div>
    </div>
  );
});

export default RmpResultItem;
