import * as fromActionTypes from 'store/actionTypes/profile';

const initialState = {
  profile: null,
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case fromActionTypes.INIT_PROFILE: {
      return {
        ...state,
        loading: false,
        profile: null,
      };
    }
    case fromActionTypes.INIT_PROFILE_RESULTS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          assessmentResults: null,
        },
      };
    }
    case fromActionTypes.GET_PROFILE: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromActionTypes.GET_PROFILE_RESULT_FROM_REPORT_FULFILLED: {
      const { id, data = {} } = action.payload;
      const { userId, results } = data;

      const newResults = JSON.parse(JSON.stringify(state.profile.assessmentResults || []));
      const assessment = newResults.find(({ assessment: aId }) => aId === id);
      if (assessment) {
        assessment.result.results = results;
        if (assessment.history) {
          const result = assessment.history.results.find(({ id: rId }) => rId === data.resultId);
          if (result) {
            result.results = results;
          }
        }
      }

      return {
        ...state,
        profile: {
          userId,
          assessmentResults: newResults,
        },
      };
    }

    case fromActionTypes.GET_PROFILE_REPORT_NOTES_FULFILLED: {
      const { id, userId, reportNotes = {} } = action.payload;

      const newResults = JSON.parse(JSON.stringify(state.profile.assessmentResults || []));
      const assessment = newResults.find(({ assessment: aId }) => aId === id);
      if (assessment) {
        assessment.reportNotes = reportNotes;
      }

      return {
        ...state,
        profile: {
          userId,
          assessmentResults: newResults,
        },
      };
    }

    case fromActionTypes.GET_PROFILE_HISTORY_FULFILLED: {
      const {
        id, history, userId, offset,
      } = action.payload;

      const newAssessmentResults = JSON.parse(JSON.stringify(state.profile.assessmentResults || []));
      const assessment = newAssessmentResults.find(({ assessment: aId }) => aId === id);
      const newResults = history.results;
      const oldResults = assessment?.history?.results ?? [];
      const results = newResults.concat(oldResults.slice(0, offset)).sort((itemA, itemB) => itemB.date - itemA.date);

      if (assessment) {
        assessment.history = { ...history, results };
      }

      return {
        ...state,
        profile: {
          userId,
          assessmentResults: newAssessmentResults,
        },
      };
    }

    case fromActionTypes.GET_PROFILE_FULFILLED: {
      const { profile } = action.payload;
      if (state.profile?.assessmentResults && profile?.assessmentResults) {
        profile.assessmentResults = profile.assessmentResults.map((el) => ({
          ...state.profile.assessmentResults.find((item) => item.assessment === el.assessment),
          ...el,
        }));
      }
      return {
        ...state,
        profile,
        loading: false,
        error: null,
      };
    }
    case fromActionTypes.GET_PROFILE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default: {
      return state;
    }
  }
};
