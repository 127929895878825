// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './GarminBarDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// CONFIG & DATA
const Config = {
  HEIGHT: 16,
};


// COMPONENT: GarminBarDiagram
const GarminBarDiagram = (props) => {
  // PROPS
  const {
    value,
    looks = 'blue',
    footerText = null,
  } = props;

  // RENDER: GarminBar
  const renderGarminBar = (percentage, fill) => (
    <g
      key={fill}
      className={classNames(
        styles.diagram,
        styles[fill],
        { [styles.animate]: fill !== 'grey' },
      )}
    >
      <rect
        width={`${percentage}%`}
        height={Config.HEIGHT}
        x={-Config.HEIGHT / 2}
      />
      <circle
        r={Config.HEIGHT / 2}
        cy={Config.HEIGHT / 2}
        cx={`${percentage}%`}
        transform={`translate(-${Config.HEIGHT / 2}, 0)`}
      />
    </g>
  );

  // RENDER: GarminBarDiagram
  return (
    <div className={styles.garminBarDiagram}>
      <svg width='100%' height={Config.HEIGHT}>
        { renderGarminBar(100, 'grey') }
        { renderGarminBar(value, looks) }
      </svg>

      { footerText && <div className={styles.footer}>{ footerText }</div> }
    </div>
  );
};

export default GarminBarDiagram;
