import * as fromActionTypes from 'store/actionTypes/wellBeing';

export const initSubmitWellBeingIndex = () => ({
  type: fromActionTypes.INIT_SUBMIT_WELLBEING_INDEX,
  payload: {},
});

export const submitWellBeingIndex = (dto) => ({
  type: fromActionTypes.SUBMIT_WELLBEING_INDEX,
  payload: { dto },
});

export const submitWellBeingIndexFulfilled = () => ({
  type: fromActionTypes.SUBMIT_WELLBEING_INDEX_FULFILLED,
  payload: {},
});

export const submitWellBeingIndexRejected = ({ error }) => ({
  type: fromActionTypes.SUBMIT_WELLBEING_INDEX_REJECTED,
  payload: { error },
});

// GET WELL-BEING HISTORY OF INDIVIDUALS
export const getWellBeingHistory = () => ({
  type: fromActionTypes.GET_WELLBEING_HISTORY,
  payload: {},
});

export const getWellBeingHistoryFulfilled = ({ wellBeingHistory }) => ({
  type: fromActionTypes.GET_WELLBEING_HISTORY_FULFILLED,
  payload: { wellBeingHistory },
});

export const getWellBeingHistoryRejected = ({ error }) => ({
  type: fromActionTypes.GET_WELLBEING_HISTORY_REJECTED,
  payload: { error },
});

// GET WELL-BEING INDEX HISTORY OF INDIVIDUALS
export const initGetWellBeingIndexHistory = () => ({
  type: fromActionTypes.INIT_GET_WELLBEING_INDEX_HISTORY,
  payload: {},
});

export const getWellBeingIndexHistory = (mode) => ({
  type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY,
  payload: { mode },
});

export const getWellBeingIndexHistoryFulfilled = ({ wellBeingHistory }) => ({
  type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY_FULFILLED,
  payload: { wellBeingHistory },
});

export const getWellBeingIndexHistoryRejected = ({ error }) => ({
  type: fromActionTypes.GET_WELLBEING_INDEX_HISTORY_REJECTED,
  payload: { error },
});

// GET WELL-BEING INDEX OF INDIVIDUALS
export const getWellBeingIndex = () => ({
  type: fromActionTypes.GET_WELLBEING_INDEX,
  payload: {},
});

export const getWellBeingIndexFulfilled = ({ wellBeing }) => ({
  type: fromActionTypes.GET_WELLBEING_INDEX_FULFILLED,
  payload: { wellBeing },
});

export const getWellBeingIndexRejected = ({ error }) => ({
  type: fromActionTypes.GET_WELLBEING_INDEX_REJECTED,
  payload: { error },
});

// GET WELL-BEING INDEX OF ORGANIZATION
export const getOrgWellBeingIndex = () => ({
  type: fromActionTypes.GET_ORG_WELLBEING_INDEX,
  payload: {},
});

export const getOrgWellBeingIndexFulfilled = ({ wellbeing, users, notEnoughData }) => ({
  type: fromActionTypes.GET_ORG_WELLBEING_INDEX_FULFILLED,
  payload: { wellbeing, users, notEnoughData },
});

export const getOrgWellBeingIndexRejected = ({ error }) => ({
  type: fromActionTypes.GET_ORG_WELLBEING_INDEX_REJECTED,
  payload: { error },
});

// GET WELL-BEING INDEX HISTORY OF ORGANIZATION
export const initGetOrgWellBeingHistory = () => ({
  type: fromActionTypes.INIT_GET_ORG_WELLBEING_HISTORY,
  payload: {},
});

export const getOrgWellBeingHistory = (mode) => ({
  type: fromActionTypes.GET_ORG_WELLBEING_HISTORY,
  payload: { mode },
});

export const getOrgWellBeingHistoryFulfilled = ({ wellBeingHistory }) => ({
  type: fromActionTypes.GET_ORG_WELLBEING_HISTORY_FULFILLED,
  payload: { wellBeingHistory },
});

export const getOrgWellBeingHistoryRejected = ({ error }) => ({
  type: fromActionTypes.GET_ORG_WELLBEING_HISTORY_REJECTED,
  payload: { error },
});


