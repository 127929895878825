import * as fromActionTypes from 'store/actionTypes/staffing';

const initialState = {
  employees: null,
  employeesTotalCount: 0,
  employeesByRole: [],
  employeesByRoleTotalCount: 0,
  employee: null,
  employeeOrgRole: null,
  processing: false,

  deleteUserRoleProcessing: false,
  deleteUserRoleError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromActionTypes.INIT_STAFFING_EMPLOYEE: {
      return {
        ...state,
        processing: false,
        employee: null,
      };
    }
    case fromActionTypes.GET_STAFFING_EMPLOYEE: {
      return {
        ...state,
        processing: true,
      };
    }
    case fromActionTypes.GET_STAFFING_EMPLOYEE_FULFILLED: {
      const { employee } = action.payload;
      return {
        ...state,
        employee,
        processing: false,
        error: null,
      };
    }
    case fromActionTypes.GET_STAFFING_EMPLOYEE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        processing: false,
        error,
      };
    }
    case fromActionTypes.INIT_DELETE_USER_ROLE: {
      return {
        ...state,
        deleteUserRoleError: null,
      };
    }
    case fromActionTypes.DELETE_USER_ROLE: {
      return {
        ...state,
        deleteUserRoleProcessing: true,
      };
    }
    case fromActionTypes.DELETE_USER_ROLE_FULFILLED: {
      return {
        ...state,
        deleteUserRoleProcessing: false,
        deleteUserRoleError: null,
        error: null,
      };
    }
    case fromActionTypes.DELETE_USER_ROLE_REJECTED: {
      const { error } = action.payload;
      return {
        ...state,
        deleteUserRoleProcessing: false,
        deleteUserRoleError: error,
        error,
      };
    }
    default: {
      return state;
    }
  }
};
